import {useContext, useState} from 'react'

import './SandboxUnlock.css'

import ClaimContext from 'components/ClaimContext'

import Context from 'components/Context/Context'

import ExperienceContext from 'components/Context/ExperienceContext'

import {MainContext} from 'components/Context/Context'

import UnlockXP from './Pages/UnlockXP'

import styled, { keyframes } from 'styled-components';

import { motion } from "framer-motion";


const PageBackground = styled.div`
      width: 100vw;
      height: 100vh;
      background: linear-gradient(196.44deg, #495569 11.39%, #22252B 153.96%);
`

const increaseWidth = keyframes`
  from {
    width: 100px;
  }
  to {
    width: 375px;
  }
`;



const SideBar = styled(motion.div)`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(186deg, #303847 -15.07%, #282A2F 111.3%);
  box-shadow: 0px 4px 5px 2px rgba(44, 46, 50, 0.25);
  border-radius: 0px 20px 20px 0px;
  overflow: hidden;
  z-index: 100;
`;


const ConnectButton = styled.div`

box-sizing: border-box;

position: absolute;
width: 329.16px;
height: 73px;
right: 50px;
top: 36px;

background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
border: 1px solid rgba(207, 207, 207, 0.5);
box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);
border-radius: 13.27px;

`



const BackButton = styled.div`
  /* Btn-Back */

  position: absolute;
  width: 173px;
  height: 55px;
  right: 50px;
  bottom: 36px;
  background-color: #00000080;
  border-radius: 10px;

`


const WalletSelection = styled.div`
    position: relative;
    width: 533px;
    height: 865px;

    top: 0px;
    left: 25%;

    background: #2C2E32;
    box-shadow: 0px 0px 35px -4px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
`


const Card = styled.div`
  /* Card */

  box-sizing: border-box;

  position: absolute;
  width: 245.71px;
  height: 316.62px;
  left: 318px;
  top: 202px;

  background: #2C2E32;
  border-radius: 10.9936px;

`



const CardImage = styled.div`
position: absolute;
width: 195.14px;
height: 195.14px;
left: 26.38px;
top: 21.44px;

background-color: #BDB1B1;
`


const CardDesc = styled.div`
/* Rectangle 22 */

box-sizing: border-box;

position: absolute;
width: 195.14px;
height: 76.96px;
left: 26.38px;
top: 216.57px;

border: 0.54968px solid #BDB1B1;
border-radius: 0px 0px 5.4968px 5.4968px;

`


function Layout() {

  const [isOpen, setIsOpen] = useState(true)

  const handleSidebarClick = () => {
    if (isOpen){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
    
  }



  return (
    <PageBackground>


    <div style={{position:'absolute', top:'0', width:'100%', height:'100px', backgroundColor:''}}>



      <ConnectButton>

      </ConnectButton>

    </div>


    <SideBar
        initial={{ width: 0 }} // Initial state when closed
        animate={{ width: isOpen ? 375 : 103 }} // Animation to open/close
        transition={{ duration: 0.6 }} // Smooth transition over 0.5s

        onClick={handleSidebarClick}
    >



    </SideBar>


    <div style={{width:'100%', height:'100%', backgroundColor:'', zIndex:'1'}}>

      <Card>


        <CardImage>
        </CardImage>


        <CardDesc>
        </CardDesc>

      </Card>

    </div>



    {/* WALLET POP UP */}
    {/*<div style={{
      position:'absolute', top:'0%', left:'0%', width:'100%', height:'100%', backdropFilter: 'blur(10px)', zIndex:'1000'
    }}>

       <WalletSelection>

       </WalletSelection>


    </div>*/}



    <div style={{position:'absolute', bottom:'0', width:'100%', height:'100px', backgroundColor:'', zIndex:'1'}}>

      <BackButton>

      </BackButton>

    </div>



        

    </PageBackground>
  );
}

export default Layout;