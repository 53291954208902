import styled from "styled-components";
import background from '../Images/background.png';
import cursorvoxelised from '../Images/cursor32.png';

export const updates = [
    {url: './update-1.png'},
    {url: './update-2.png'},
    {url: './update-3.png'},
    {url: './update-3.png'},
   
    ]

export const HomeWrapper = styled.div`
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100vh;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    `;


    export const Button = styled.button`
    background: var(--clayblue-secondary);
    font-family: ButtonFont;
    border: none;
    padding: 1rem 2rem;
    font-size: clamp(0.5rem, 4vw, 3rem);
    text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
    letter-spacing: 0.2rem;
    text-align: center;
    color: white;
    border-radius: 20px;
    cursor: url(${cursorvoxelised}), auto;
    transition: 0.2s ease-in-out;
    &:hover {
        background: var(--claypurple-primary);
    }

    `;
    
    export const SkipButton = styled(Button)`
    position: fixed;
    right: 2%;
    top: 2%;  
    cursor: url(${cursorvoxelised}), auto;
    `;


