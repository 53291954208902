import styled from "styled-components";


export const PanelWrapper = styled.div`
background: var(--panel-background);
border-radius: 0.7rem;
box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3) inset;
align-items: center;
justify-content: center;
text-align: center;
height: 68%;
width: 80%;
position: fixed;
bottom: 0;
right: 0;
overflow-y: scroll;
@media screen and (max-width: 768px) {
    width: 90%;
    height: 58%;
    right: 50%;
    bottom: 14%;
    transform: translate(50%, 0%);
`;
