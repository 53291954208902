import {useEffect, useState} from 'react'

import TransactionProgress from './TransactionProgress'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'


const CLAY_POLICY = '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535'
const CLAY_HEX = '434c4159'

const SNEK_POLICY = '279c909f348e533da5808898f87f9a14bb2c3dfbbacccd631d927a3f'
const SNEK_HEX = '534e454b'


function BuildTransaction(props){

	const {activeWallet, items, setItems, subtotal, discount, total, setSubtotal, setDiscount, setTotal, 
	setCartItems, setUpdate, cartItems, chosenOption, exchangeRate, snekExchangeRate, claimCode} = props

	const [txStatus, setTxStatus] = useState('')
	const [statusMessage, setStatusMessage] = useState('')

	// const paymentAddresses = [
	// 	'addr1qy7qhd8zqv9ecqeluvnwktly9eh5zw4e4vslr7qg5vy42kqp8ustrwvsn6vflsw2245cfgj2c8m3y3x4jjz85w90h2xs2ydn2t',
	// 	'addr1q9x3pe96n83wsl6knnvjnhnt924h7sahq4eunv4yeq7m7f8w0ngqyad6mfyfndk7qnraccs0e6t4rnthk2t5tcj0yy2s2xjy6n'
	// ]

	//const paymentAddress = 'addr1v9ts954p0lc63xla57f5ffwejh4zmsec2295tlw8ts00n4cxcqv9x'
	//const paymentAddress = 'addr1v8qqwjc36q8lwz2j68y6tpxu7zz63txcv86qgu7jgz5jyych0426h'

	const paymentAddress = 'addr1v85kekz0wkd6rzn9vvm2v9dq8n2ymc0lwzq9g0wjqd44d7cetle62';

	useEffect(() => {
		
		buildTx()

	},[])


	  async function submitTransaction(cardano, signedTxHash){

		    const submittedTx = await cardano.submitTx(signedTxHash)

		    return submittedTx
		}


	  async function get_txBuilder(protocolParams){
  
		   //console.log(protocolParams)

		   const min_fee_a = String(protocolParams.min_fee_a)
		   const min_fee_b = String(protocolParams.min_fee_b)
		   const pool_deposit = String(protocolParams.pool_deposit)
		   const key_deposit = String(protocolParams.key_deposit)
		   const max_value_size = protocolParams.max_val_size
		   const max_tx_size = protocolParams.max_tx_size
		   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

		   const linearFee = serLib.LinearFee.new(
		       serLib.BigNum.from_str(min_fee_a),
		       serLib.BigNum.from_str(min_fee_b)
		   );


		   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
		       .fee_algo(linearFee)
		       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
		       .key_deposit(serLib.BigNum.from_str(key_deposit))
		       .max_value_size(max_value_size)
		       .max_tx_size(max_tx_size)
		       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
		       .prefer_pure_change(false)
		       .build();

		   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
		   return txBuilder
		}


	async function getUtxos(cardano){
      const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
        const utxos = (await cardano.getUtxos()).map((utxo) =>
            txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
         );
        return txUnspentOutputs
	}


function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


	async function resetCartItems(){

		try{
			let temp_cartItems = [...cartItems]

			let temp_items = {...items}

			for (let cartItem of temp_cartItems){
				
				temp_items[cartItem].quantity = 0

			}
		   
		  setCartItems([])
		  setItems({...temp_items})
		  setUpdate(true)	

		  //resetShopping()
								                		             
			return


		}catch{
			//resetShopping()
		}
	}


	function getTxMeta(){
		
		let tx_meta_items = {}

		for (let key in items){
			let cur_item = items[key]
			let cur_name = cur_item.name
			let cur_quantity = cur_item.quantity

			tx_meta_items[key] = cur_quantity
		}

		//claim code here
		tx_meta_items[claimCode] = 1

		return tx_meta_items
	}

	async function getParameters(){
	  const protocolParams = await fetch("https://claymarket.io/api/protocol/parameters/")
	  const jsonBuildTransaction_params = await protocolParams.json()
	  return jsonBuildTransaction_params
	}


	async function getCurrentSlot(){
	    let slot = await fetch('https://claymarket.io/api/current/slot/').then(response => response.json())
	                                                                  .then(data => data)

	    slot = parseInt(slot.slot)

	    return slot
	}


	async function buildTx(){

		console.log('build tx')
		setTxStatus('building')

		setStatusMessage('')

		let cardano = await window.cardano[activeWallet]

		cardano = await cardano.enable()


	  let address_encoded = await cardano.getUsedAddresses()
	  address_encoded = address_encoded[0]

	  let address = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()


	  let buyer = address 
    let buyer_encoded = serLib.Address.from_bech32(buyer)

	  console.log(address)

	  let receiver = paymentAddress
	  let receiver_encoded = serLib.Address.from_bech32(receiver)

	  const protocolParams = await getParameters()
   	const currentSlot = await getCurrentSlot()


   	const ttl = parseInt(currentSlot) + 7200
    const utxos = await getUtxos(cardano)

    console.log(utxos)

    let asset_counter = 0
    for (let key in items){
    asset_counter += items[key].quantity
  }

    console.log('ASSET COUNTER')
    console.log(asset_counter)

    let lovelaceToSend = 4500000 + asset_counter * (500000 + 2500000)

    	//let payment_output;

    	

    	

    	let txBuilder = await get_txBuilder(protocolParams)




    	if (chosenOption == 'ada' || chosenOption == 'clay' || chosenOption == 'snek'){
    		//lovelaceToSend += (total/exchangeRate).toFixed(0) * 1000000


    		let payment_output = serLib.TransactionOutput.new(
              receiver_encoded,
              serLib.Value.new(
                serLib.BigNum.from_str(String(lovelaceToSend))
              )
          )


    		txBuilder.add_output(payment_output);
    	}




    	let multiAsset = serLib.MultiAsset.new()
      let assets = serLib.Assets.new()


      // if (chosenOption == 'clay'){

      // 	const clayToSend = String(total*10000)

	    //   assets.insert(
	    //   	serLib.AssetName.new(
	    //   		Buffer.from(CLAY_HEX, 'hex')
		  //     ),
		  //     serLib.BigNum.from_str(clayToSend)
		  //   )
		      
	    //   multiAsset.insert(
	    //   	serLib.ScriptHash.from_bytes(Buffer.from(CLAY_POLICY, "hex")), 
			//   assets
			//  );


	    //   let script_hash = serLib.ScriptHash.from_bytes(Buffer.from(CLAY_POLICY, "hex"))
      // 	console.log(script_hash)

      // 	console.log(script_hash.to_hex())


      // 	let payment_output = serLib.TransactionOutput.new(
      //         receiver_encoded,
      //         serLib.Value.new_with_assets(
      //           serLib.BigNum.from_str(String(lovelaceToSend)),
      //           multiAsset
      //         )
      //     )


      // 	txBuilder.add_output(payment_output);

      // }








      // if (chosenOption == 'snek'){

      // 	const snekToSend = String((total/snekExchangeRate).toFixed(0))

	    //   assets.insert(
	    //   	serLib.AssetName.new(
	    //   		Buffer.from(SNEK_HEX, 'hex')
		  //     ),
		  //     serLib.BigNum.from_str(snekToSend)
		  //   )
		      
	    //   multiAsset.insert(
	    //   	serLib.ScriptHash.from_bytes(Buffer.from(SNEK_POLICY, "hex")), 
			//   assets
			//  );


	    //   let script_hash = serLib.ScriptHash.from_bytes(Buffer.from(SNEK_POLICY, "hex"))
      	
      // 	console.log(script_hash)
      // 	console.log(script_hash.to_hex())

      // 	let payment_output = serLib.TransactionOutput.new(
      //         receiver_encoded,
      //         serLib.Value.new_with_assets(
      //           serLib.BigNum.from_str(String(lovelaceToSend)),
      //           multiAsset
      //         )
      //     )


      // 	txBuilder.add_output(payment_output);

      // }

      


     


      	


        // let payment_output = serLib.TransactionOutput.new(
        //       receiver_encoded,
        //       serLib.Value.new(
        //         serLib.BigNum.from_str(String(lovelaceToSend))
        //       )
        //   )
      
     
      


      let tx_metadata = getTxMeta()

      txBuilder.add_json_metadatum(
                serLib.BigNum.from_str("6179"),
                JSON.stringify(tx_metadata)
               );

      try{
	      txBuilder.add_inputs_from(utxos, 2) 
	    }catch (err){
	      setTxStatus('error')
	      setStatusMessage('balance is not sufficient')
	      console.log('err')
	      console.log(err)

	      return
	    }


	    try{
	      txBuilder.add_change_if_needed(buyer_encoded)
	    }catch(err){
	      setTxStatus('error')
	      setStatusMessage('could not add change')

	      return
	    }


	    let unsignedTx;

	    try{
	       unsignedTx = txBuilder.build_tx()
	    }catch(err){
	      unsignedTx = ''
	      setTxStatus('error')
	      setStatusMessage('could not build transaction')

	      return
	    }


	    const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')
	    
	    setTxStatus('signing')
	    let tx_witness;
	    try{
	    	tx_witness = await cardano.signTx(unsignedTx_hash,true)
	    }catch{
	    	setTxStatus('error')
	      	setStatusMessage('declined to sign')
	    }

	    const txBody = unsignedTx.body()


	    let wallet_witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))
	    const totalVkeys = serLib.Vkeywitnesses.new();

	    for (let i = 0; i < wallet_witness_set.vkeys().len(); i++){
	      let holder_vkey = wallet_witness_set.vkeys().get(i)
	        totalVkeys.add(holder_vkey)
	    }


	    const totalWitnesses = serLib.TransactionWitnessSet.new();
	    totalWitnesses.set_vkeys(totalVkeys);


	    let tx_auxiliary_data = unsignedTx.auxiliary_data()

	    const signedTx = serLib.Transaction.new(
	                txBody,
	                totalWitnesses,
	                tx_auxiliary_data
	        )

	    const signedTxHash = Buffer.from(
	                signedTx.to_bytes()
	        ).toString('hex')

	    console.log(signedTxHash)


	    setTxStatus('submitting')
	    try{

	      let status = await submitTransaction(cardano, signedTxHash)
	      setTxStatus('success')


	      resetCartItems()

	      //setItems({})
	      // setSubtotal(0)
	      // setDiscount(0)
	      // setTotal(0)
	      // setCartItems([])
	      // setUpdate(true)

	      


	      
	    }catch(err){
	      setTxStatus('fail')
	      setStatusMessage('failed to submit transaction')
	      console.log(err.message)
	    }

	}



	return(
		<>

			<TransactionProgress 
				txStatus={txStatus} 
				statusMessage={statusMessage}
			/>

		</>
	)
}

export default BuildTransaction;