import Mint from './Mint'

// components/Card.js
import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  border: 6px solid transparent;
  border-radius: 35px;
  padding: 10px;
  margin: 10px;
  width: 350px;
  height: 480px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  background: rgba(1,1,1,0.3);
  backdrop-filter: blur(6px);

  &:hover{
  	 border: 6px solid #F15822;
  }
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
`;

const Content = styled.p`
  font-size: 1rem;
`;


const CardGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  justify-items: center;
`;


const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/'

//const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/'

const Card = ({img_url, title, price, token_name, setMintAsset, 
		       isAlertOpen, setIsAlertOpen, submittedTx,setSubmittedTx, 
		       cartItems, setCartItems, matchingPairs}) => {
	//console.log(img_url)
  return (
    <CardWrapper>
      {/*<Title>{title}</Title>
      <Content>{content}</Content>*/}

      {/*<div style={{width:'100%', height:'300px'}}>
      	<img src={""} style={{width:'100%', objectFit:'cover'}} />
      </div>*/}

      {/*<div style={{width:'300px', height:'300px', borderRadius:'10px'}}>
      	<img src={root_url + img_url + '.png'} 
      		style={{ width: '100%', height: '100%', objectFit: 'cover', 
      				borderRadius: '10px' }}
      	/>
      </div>*/}

    	<div style={{height:'100%', width:'100%', backgroundColor:'', 
    		display:'flex', flexDirection:'column'}}>

    		<div style={{height:'4%', width:'100%', backgroundColor:''}}>

    		</div>

    		<div style={{width:'100%', height:'76%', backgroundColor:'',
    					display:'flex', justifyContent:'center', alignItems:'center'
    		}}>

    			<div style={{width:'350px', height:'350px', backgroundColor:'green', borderRadius:'5px',
    						display:'flex', justifyContent:'center', alignItems:'center',

    						background: 'transparent',
  							
    			}}>

    				<div style={{width:'335px', height:'335px', backgroundColor:''}}>
    					<img src={root_url + img_url + '.png'} style={{width:'335px', height:'335px',
    						objectFit:'cover'
    					}} />
    				</div>

    			</div>

    		</div>

    		<div style={{width:'100%', height:'20%', backgroundColor:'', 
    			display:'flex', justifyContent:'center', alignItems:'center'}}>


    			<div style={{width:'55%', height:'100%', backgroundColor:'', 
    				display:'flex', justifyContent:'center', alignItems:'center'}}>

    				<div style={{height:'80%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

	    				<div style={{width:'100%', height:'40%', backgroundColor:'',
	    					display:'flex', justifyContent:'start', alignItems:'center'
	    				}}>
	    					<div style={{marginLeft:'10px', fontSize:'18px', color:'white',
	    						fontFamily:'ShortStack', color: '#F15822', fontWeight:'bold'
	    					}}>
	    						{title.toUpperCase()}
	    					</div>

	    				</div>

	    				<div style={{width:'100%', height:'40%', backgroundColor:'',
	    					display:'flex', justifyContent:'start', alignItems:'center',
	    					marginTop:'15px'
	    				}}>


	    				{matchingPairs !== undefined && matchingPairs.includes(token_name) ?

	    					<div style={{marginLeft:'10px', fontWeight:'bold', fontSize:'20px',
	    						fontFamily:'ShortStack', color: '#ed1a70', marginTop:'15px',

	    						color:'#FFA500', fontWeight:'bold'
	    					}}>

	    						2000 $CLAY

	    					</div>

	    				:
	    					<div style={{marginLeft:'10px', fontWeight:'bold', fontSize:'20px',
	    						fontFamily:'ShortStack', color: '#ed1a70', marginTop:'15px'
	    					}}>

	    						{price} $CLAY
	    					</div>

	    				}
	    				</div>

    				</div>

    			</div>

    			<div style={{width:'45%', height:'100%', backgroundColor:'', 
    				display:'flex', justifyContent:'center', alignItems:'center'}}>

    				<div style={{height:'80%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

	    				
	    				<Mint 
	    					price={price} 
	    					token_name={token_name} 

	    					isAlertOpen={isAlertOpen} 
			                setIsAlertOpen={setIsAlertOpen} 
			                submittedTx={submittedTx} 
			                setSubmittedTx={setSubmittedTx}


			                cartItems={cartItems}
			                setCartItems={setCartItems}
	    				/>

    				</div>

    			</div>

    		</div>

    	</div>


    </CardWrapper>
  );
};

export default Card;