import './style.css';

import { useState, useEffect, useContext } from 'react'

import trousersInfo from '../Trousers/json/trousers_info.json'

//import {MainClaimContext} from 'components/Context/ClaimContext'

import BuildTransaction from './BuildTransaction'

//const EXCHANGE_RATE = 

const RemoveFromCart = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/ClayPay/06_Remove%20From%20Cart.wav'
const AddToCart = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/ClayPay/08_Add%20to%20Cart%20Sound%20Effect_V3.wav'
const SelectTrousers = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/ClayPay/05_Select%20trousers.wav'
const Click = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/ClayPay/03_Click%20(Continue%20Shopping%20+%20Edit).wav'
const Close = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/ClayPay/01_Closing%20Clay%20Pay%20Chime.wav'

const OnOff = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/ClayPay/02_On%20_%20Off%20Button_V2.wav'



function DisplayFooter(props){

	const {token, subtotal, discount, matchingPairs, total, play, submit, setSubmit, proceed, setProceed, items} = props


	const subtotalStyle = {
    color: '#d3eef564',
    fontFamily: 'ClayPay',
    fontSize: '2.4rem',
    textShadow: '0px 0px 3px #68daf4a6',
    letterSpacing: '0.1rem',
    fontWeight: 100,
    marginLeft: '10px',
    transition: 'ease-in-out all 0.3s',

    margin: '5px 0',

    marginTop:'0px',
    marginBottom:'0px'
  };


  const totalStyle = {
    color: '#d3eef564',
    fontFamily: 'ClayPay',
    fontSize: '2.8rem',
    textShadow: '0px 0px 3px #68daf4a6',
    letterSpacing: '0.1rem',
    fontWeight: 100,
    marginLeft: '10px',
    transition: 'ease-in-out all 0.3s',

    margin: '5px 0',

    marginTop:'0px',
    marginBottom:'0px'
  };

  const discountStyle = {
    color: '#d3eef564',
    fontFamily: 'ClayPay',
    fontSize: '1.8rem',
    fontWeight: 100,
    margin: '5px 0',

    marginTop:'0px',
    marginBottom:'0px'
  };

	useEffect(() => {

		console.log('change in totals')
		console.log('total', total)
		console.log('subtotal', subtotal)
		console.log('discount', discount)

	}, [total, subtotal, discount] )


	const [priceReady, setPriceReady] = useState(false)


	useEffect(() => {

		if (matchingPairs == undefined){
			setPriceReady(false)
		}else{
			setPriceReady(true)
		}

	},[matchingPairs])

	return(
		<>
			<div className='subtotalSection' style={{backgroundColor:'', height:'180px'}}>



		{!submit && !proceed ?

		<div style={{height:'150px', display:'flex', justifyContent:'space-between', alignItems:'space-between', backgroundColor:'', flexDirection:'column', transform:'scale(0.9)',}}>
      


		{/*
      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'0px', marginBottom:'0px'}}> 
					<p className='subtotalTxt' style={{fontSize:'36px', marginTop:'0px', marginBottom:'0px'}}>Subtotal:</p> 
					
					<div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
						<img src={token} className="tokenImgSubtotal" />
						<p className='subtotal' style={{fontSize:'36px', marginTop:'0px', marginBottom:'0px'}}>{priceReady ? subtotal : '-'}</p>
					</div>

				</div>

      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'0px', marginBottom:'0px'}}> 
					<p className='subtotalTxt' style={discountStyle}>Discount:</p> 
					
					<div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
						<img src={token} className="tokenImgSubtotal" style={{marginRight:'10px'}} />
						<p className='subtotal' style={discountStyle}>{priceReady ? discount : '-'}</p>
					</div>
				</div>
			*/}


		<div style={{height:'80px'}}>

		</div>


      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'0px', marginBottom:'0px'}}> 
					<p className='subtotalTxt' style={{marginTop:'0px', marginBottom:'0px'}}>Total:</p> 
					
					<div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
						<img src={token} className="tokenImgSubtotal" />
						<p className='subtotal' style={{marginTop:'0px', marginBottom:'0px'}}>{priceReady ? total : '-'}</p>
					</div>
				
				</div>


		<div style={{height:'80px'}}>

		</div>



    </div>

    : null}


			{/*<div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'120px'}}>
				
				<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'0px', marginBottom:'0px'}}> 
					<p className='subtotalTxt' style={{fontSize:'38px', marginTop:'0px', marginBottom:'0px'}}>Subtotal:</p> 
					<img src={token} className="tokenImgSubtotal" />
					<p className='subtotal' style={{fontSize:'38px', marginTop:'0px', marginBottom:'0px'}}>{priceReady ? subtotal : '-'}</p>
				</div>

				<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'0px', marginBottom:'0px'}}> 
					<p className='subtotalTxt' style={{fontSize:'28px', marginTop:'0px', marginBottom:'0px'}}>Discount:</p> 
					<img src={token} className="tokenImgSubtotal" />
					<p className='subtotal' style={{fontSize:'28px', marginTop:'0px', marginBottom:'0px'}}>{priceReady ? discount : '-'}</p>
				</div>

				<div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'0px', marginBottom:'0px'}}> 
					<p className='subtotalTxt' style={{marginTop:'0px', marginBottom:'0px'}}>Total:</p> 
					<img src={token} className="tokenImgSubtotal" />
					<p className='subtotal' style={{marginTop:'0px', marginBottom:'0px'}}>{priceReady ? total : '-'}</p>
				</div>

			</div>*/}


			



			{!proceed ?
			<div className='submit'>
				<button className={total > 0 && priceReady ? "buttonSubmit" : 'buttonSubmitDisabled' }

				onClick={()=>{
					//const startTime = Math.floor(Math.random()*3) + 1

					if (total > 0){
						play(Click, 0.5, 1)
						setProceed(true)
					}				
					
				}} 

					style={{cursor:'pointer'}}
				>
					NEXT
				</button>
			</div>

			: 

			<div style={{height:'200px', backgroundColor:''}}>
				
			</div>
		}





		{proceed & !submit ?
			<div className='submit'>
				<button className={total > 0 && priceReady ? "buttonSubmit" : 'buttonSubmitDisabled' }

				onClick={()=>{
					//const startTime = Math.floor(Math.random()*3) + 1

					if (total > 0){
						play(Click, 0.5, 1)
						setSubmit(true)
					}				
					
				}} 

					style={{cursor:'pointer'}}
				>
					SUBMIT
				</button>
			</div>

			: 

			<div style={{height:'200px', backgroundColor:''}}>
				
			</div>
		}



	


		</div>		
		</>
	)
}



function DisplayHeader(props){

	const {play, submit, setSubmit, setLocation, setPrevLocation, clayPayOpen, setClayPayOpen, proceed, setProceed} = props





	return(
		<>
			<div style={{width:'100%', display:'flex', justifyContent:'center', backgroundColor:''}}>

				<div style={{height:'80px', width:'80%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
					
					{!submit && !proceed ?
			 		<div className='itemsTitle'>
				        <p>
				          Your items:
				        </p>
			        </div>

			        : submit ?

			         <div
			         	onClick={()=>{
			         		play(Click, 1.4, 1)
			         		setSubmit(false)
			         	}}

			         	style={{cursor:'pointer'}}
			         >

			         	<img src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/ArrowBack.png"} style={{width:'105px', height:'105px'}} />

			         </div>

			         : proceed ?



			         <div
			         	onClick={()=>{
			         		play(Click, 1.4, 1)
			         		setSubmit(false)
			         		setProceed(false)
			         	}}

			         	style={{cursor:'pointer'}}
			         >

			         	<img src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/ArrowBack.png"} style={{width:'105px', height:'105px'}} />

			         </div>


			         : null



			    	}

			        <div className='continueShopping' onClick={() => {
			        	
			        	//play(Click)

			        	play(OnOff)

			        	//play(Click, 2.5, 1)

			        	play(Close, 0.5)

			        	setClayPayOpen(false)

			        	//setInputBlocked(false)


			        	// setLocation('Inside')
    					  // setPrevLocation('ClayPay')

			        	// let temp_activeInterfaces = activeInterfaces
			        	// delete temp_activeInterfaces['clayPay']
			        	// setActiveInterfaces(temp_activeInterfaces)
			        	//console.log(activeInterfaces)

			        }}>
				    	<a style={{color:'#F15822', textDecoration: 'underline',textDecorationColor:'#F15822'}}>Continue Shopping</a> 
			 		</div>

		 		</div>

		 		</div>
		</>
	)
}


function DisplayPaymentMethods(props){

	const {clayTotal, play, chosenOption, setChosenOption, exchangeRate, setExchangeRate, snekExchangeRate} = props

	const [adaTotal, setAdaTotal] = useState()
 
 	const [snekTotal, setSnekTotal] = useState()
	

	


	useEffect(() => {

		setSnekTotal((clayTotal/snekExchangeRate).toFixed(0))

	}, [])

	useEffect(() => {
		setAdaTotal((clayTotal/exchangeRate).toFixed(0))
	}, [])

	return(
		<div style={{width:'500px', height:'100%', backgroundColor:''}}>

			<div style={{width:'100%', height:'50%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>


				<div className={chosenOption === 'clay' ? 'paymentOptionSelected' : 'paymentOption'} style={{borderRadius: '50px', width:'90%', height:'90%', backgroundColor:''}}
					onClick={()=>{
									setChosenOption('clay')

								 	const startTime = Math.floor(Math.random() * 3)
									
									play(SelectTrousers, startTime, 1)

								}}
				>

					<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>

						<div className="totalPaymentTxt">
							{clayTotal} $CLAY
						</div>

						 <div className="methodTypeTxt">
							PAY WITH CLAY TOKEN
						 </div>

					</div>

				</div>

			</div>

			<div style={{width:'100%', height:'50%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
				<div className={chosenOption === 'ada' ? 'paymentOptionSelected' : 'paymentOption'} style={{borderRadius: '50px', width:'90%', height:'90%', backgroundColor:''}}
					onClick={()=>{

									setChosenOption('ada')

								 	const startTime = Math.floor(Math.random()*3)
									
									play(SelectTrousers, startTime, 1)

								}}
				>
					
					<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>

						<div className="totalPaymentTxt">
							{adaTotal} ADA
						</div>

						<div className="methodTypeTxt">
							PAY WITH ADA
						</div>

						<div style={{color:'white', marginTop:'20px'}}>

							October-November fixed exchange rate:

							<div>
								1 ADA = 160 $CLAY
							</div>

						</div>

					</div>

				</div>
			</div>


			




			{/*<div style={{width:'100%', height:'50%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
				<div className={chosenOption === 'snek' ? 'paymentOptionSelected' : 'paymentOption'} style={{borderRadius: '50px', width:'90%', height:'90%', backgroundColor:''}}
					onClick={()=>{

									setChosenOption('snek')

								 	const startTime = Math.floor(Math.random()*3)
									
									play(SelectTrousers, startTime, 1)

								}}
				>
					
					<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>

						<div className="totalPaymentTxt">
							{snekTotal} SNEK
						</div>

						<div className="methodTypeTxt">
							PAY WITH SNEK
						</div>

						<div style={{color:'white', marginTop:'20px'}}>

							May fixed exchange rate:

							<div>
							
								1 $SNEK = 0.21 $CLAY

							</div>
						</div>
					</div>
				</div>
			</div>
*/}







		</div>
	)
}


function DisplayItems(props){
	const {items, setItems, itemsList, setItemsList, play, token, cartItems, setCartItems, setUpdate} = props 

	return(
		<>
			<div style={{marginLeft:'30px', width:'90%', display:'grid', gridTemplateColumns: '1fr 2fr', gridGap:'120px', marginTop:'20px', marginBottom:'20px'}}>


			{
				//Object.keys(items).map((item, i) => {

				itemsList.map((item) => {


					return(


						items.hasOwnProperty(item) ? 

						<div key={item}>
							<div style={{width:'250px', height:'420px', backgroundColor:''}}>

								<div className='itemPlaceholderB' onClick={()=>{

								 	const startTime = Math.floor(Math.random()*3)
									
									play(SelectTrousers, startTime, 1)

								}} >
									<img src={items[item].image} className="trousersB" style={{width:'220px', height:'auto', marginTop:'3px', borderRadius:'5px'}} />
								</div>


								<div className="itemAInfo" style={{width:'100%', display:'flex', flexDirection:'column',
									justifyContent: 'center', backgroundColor:'',
									backgroundColor:'rgba(233,233,233,0.1)',
									borderRadius: '10px',
									marginTop:'5px', marginBottom:'20px'
								}}>

								<div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center', backgroundColor:'',
									height:'80px',lineHeight: '1.5', textAlign:'center'
								}}>
								
									<p style={{fontSize:'22px'}}>
										{items[item].name}
									</p>

									</div>


									<p style={{fontSize:'20px'}}>
									<span style={{display:'flex', alignItems:'center', justifyContent:'center'}} >	
										
										<img src={token} style={{width:'25px', marginTop:'4px'}}/>
											{items[item].price}
									</span>
									</p>





									<div style={{width:'100%', backgroundColor:''}}>

									<div style={{display:'flex',width:'100%', alignItems:'center', justifyContent:'center', backgroundColor:''}}>
										<button

										style={{cursor:'pointer', color: '#192025', fontSize:'20px', borderRadius:'10px', width:'30px', height:'30px', border: 'none'}}
										onClick={()=>{

												let prevTotal = 0

												for (let item in items){
													let cur_item = items[item]
													prevTotal += parseInt(cur_item['quantity'], 10)
												}

												if (prevTotal >= 40){
													return
												}

												play(AddToCart)
												//console.log(cartItems)
												let temp_cart_items = cartItems
												temp_cart_items.push(item)
												setCartItems(temp_cart_items)
												let temp_items = {...items}
												temp_items[item].quantity = temp_items[item].quantity + 1
												setItems(temp_items)

												setUpdate(true)
											}}

										>+</button>

											<span style={{marginLeft:'10px', marginRight:'10px', fontSize:'20px'}}>
												{items[item].quantity}
											</span>

										<button	

											style={{cursor:'pointer', color: '#192025', fontSize:'20px', borderRadius:'10px', width:'30px', height:'30px', border: 'none'}}


											onClick={()=>{
												
												play(RemoveFromCart)

												//console.log(cartItems)

												let temp_cartItems = cartItems
								                let index = temp_cartItems.indexOf(item)
								                
								                if (index > -1) {
								                  temp_cartItems.splice(index, 1);
								                  setCartItems(temp_cartItems)
								                }

								                let temp_items = {...items}
								                temp_items[item].quantity = Math.max(temp_items[item].quantity - 1, 0)
								                setItems(temp_items)

								               setUpdate(true)

											}}

										>-</button>

										</div>

										<p style={{fontSize:'20px'}}>
											<span style={{display:'flex', alignItems:'center', justifyContent:'center'}} >	
												Total: 
												<img src={token} style={{width:'30px', marginTop:'4px'}}/>
												{items[item].price * items[item].quantity}
											</span>
										</p>

									</div>
							</div>
							*/}

							</div>

							

						</div>

						 : null
					)
				})}

				</div>
		</>
	)
}




function ClayPayUI(props){

	  const {stake_address, activeWallet, resetShopping, clayPayOpen, setClayPayOpen} = props

	 //const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)
	 //const {cartItems, setCartItems, activeInterfaces, setActiveInterfaces, setInputBlocked, subtotal, setLocation, setPrevLocation} = props

	  const {cartItems, setCartItems, setActiveInterfaces, subtotal, setLocation, setPrevLocation} = props


	  useEffect(() => {
	  	console.log(cartItems)
	  },[cartItems])


	 //const Click = 'Interfaces/ClayPay/audio/click.mp3';


	 const claypaylogo = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/claypaylogo.png'
	 const trousersA = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/trousersA.png'
	 const trousersB = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/trousersB.png'
	 const token = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/token.svg'
	 const [items, setItems] = useState({})

	 const [itemsList, setItemsList] = useState([])

	 const cur_image_path = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/'

	 const [localCart, setLocalCart] = useState(cartItems)
	 const [update, setUpdate] = useState(true)

	 //const [subtotal, setSubtotal] = useState
	 const [discount, setDiscount] = useState(0)

	 const [beforeDiscount, setBeforeDiscount] = useState(subtotal)
	 const [total, setTotal] = useState(subtotal)

	 const [curSubtotal, setCurSubtotal] = useState(subtotal)

	 const [submit, setSubmit] = useState(false)

	 const [proceed, setProceed] = useState(false)


	 const [audio, setAudio] = useState(null)

	 const [matchingPairs, setMatchingPairs] = useState()




	 const [chosenOption, setChosenOption] = useState('clay')


	 const [exchangeRate, setExchangeRate] = useState(160)

	 const [snekExchangeRate, setSnekExchangeRate] = useState(0.210)




	 useEffect(() => {
	 	console.log('CLAY PAY UI')
	 	console.log(stake_address)
	 	console.log(activeWallet)
	 }, [stake_address, activeWallet])



	 function formatClothes(clothes){
   if (clothes === '' || clothes === 'None' || clothes === 'Nude'){
      clothes = 'GC Nude'
    }
      
    

    clothes = clothes.replaceAll(' ', '_')


    return clothes
}

	

	async function fetchMarketData(){

  let collection = 'gc'

  let all_queried_assets = []

  let page = 1
  
  let cur_stake_address = stake_address
  //let cur_stake_address = 'stake1u8y7rgpa0phufsnnuka0eue6wxne8rgty86a9nprerzfhwqdf2hgn'
  //let cur_stake_address = 'stake1u8k5lx90tc7030vvnxs3u9yvm7uwpqndca7jxgvexhc40eq5hd4tt'
  //let cur_stake_address = 'stake1u8spkj4az28qnxar0fqhvl2s9htpwytxdkpz07amgx56ues3xnzxh'

  let hasMore = true
  
  while (hasMore){
    let query_url = 'https://claymarkets.com/api/filter/listings/?collection=' + collection + '&page=' + String(page)
    query_url += '&stake_address=' + cur_stake_address

    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                    .then(data => data)

    if (asset_listing_info.hasOwnProperty('listings')){
      let cur_assets = asset_listing_info['listings']

      if (cur_assets.length > 0){

        page = page + 1

        for (let cur_asset of cur_assets){
          all_queried_assets.push(cur_asset)
        }

      }else{
        hasMore = false
      }

    }else{
      hasMore = false
    }
  }

 // console.log(asset_listing_info)   

 return all_queried_assets                   
}


function getClothes(eligibleAssets){

  let clothes_list = []

  for (let eligibleAsset of eligibleAssets){
    //console.log(eligibleAsset)
    let cur_meta = eligibleAsset['meta']
    let clothes = cur_meta['clothes']
    clothes = formatClothes(clothes)
    //console.log(clothes)
    clothes_list.push(clothes)
  }

  return clothes_list

}


function getMatchingTrousers(clothes_list){

  let matching_pairs = []

  for (let clothes of clothes_list){
    let matching_pair = clothes + '_Trousers'
    matching_pairs.push(matching_pair)
  }

  return matching_pairs

}



async function determineDiscounts(){

  // let fetchedAssets = await fetchMarketData()

  // //console.log(fetchedAssets)

  // //findMatchingPairs(fetchedAssets)

  // let clothes_list = getClothes(fetchedAssets)

  // let matching_pairs = getMatchingTrousers(clothes_list)

  // console.log(clothes_list)
  // console.log(matching_pairs)


  // setMatchingPairs(matching_pairs)

  setMatchingPairs([])

}




useEffect(() => {

  console.log('\n\n\nFIND MATCHING PAIRS\n\n\n')

  

  determineDiscounts()


  //let matching_pairs = findMatchingPairs()

  //console.log(matching_pairs)

  console.log('\n\n\nEND\n\n\n')

},[])




useEffect(() => {

	console.log('matching pairs:')
	console.log(matchingPairs)

},[matchingPairs])




	
	 useEffect(() => {

	 	let temp_items = {}
	 	for (let i = 0; i < cartItems.length; i++){
	 		let cur_item = cartItems[i]

	 		//console.log(cur_item)

	 		if (cur_item in trousersInfo){
	 			let cur_info = trousersInfo[cur_item]
	 			
	 			//let cur_name = cur_info['name']

	 			let cur_name = cur_item.replaceAll('_Trousers', '_Pants')
	 			cur_name = cur_name.replaceAll('_', ' ')
	 			
	 			//let cur_image = cur_image_path + cur_info['image']

	 			let cur_image = cur_image_path + cur_item + '.png'
	 			
	 			let cur_price = cur_info['price']
	 			let cur_discount_price = cur_info['discountPrice']

	 			/*
	 			temp_items.push({
	 				name: cur_name,
	 				image: cur_image,
	 				price: cur_price,
	 				discountPrice: cur_discount_price,
	 				quantity: 1
	 			})
	 			*/

	 			if (cur_item in temp_items){
	 				temp_items[cur_item].quantity = temp_items[cur_item].quantity + 1
	 			}else{
	 				temp_items[cur_item] = {
	 					name: cur_name,
		 				image: cur_image,
		 				price: cur_price,
		 				discountPrice: cur_discount_price,
		 				quantity: 1
	 				}
	 			}
	 		}
	 	}


	 	/*
	 	for (let key in items){
	 		if (!temp_items.hasOwnProperty(key)){
	 			let cur_item = {...items[key]}
	 			cur_item.quantity = 0

	 			temp_items[key] = cur_item
	 		}
	 	}
	 	*/



	 	const cur_total = getTotal(temp_items)

	 	setTotal(cur_total)
	 	//console.log(temp_items)
	 	setItems(temp_items)

	 	setItemsList(Object.keys(temp_items))

	 	setUpdate(false)

	 }, [cartItems, update, matchingPairs])


	 useEffect(() => {

	 	// console.log('TOTAL CHANGED')
	 	// console.log(total)

	 },[total, matchingPairs])


	 function getTotal(items){
	 		let amount = 0

	 		let cur_discount = 0

	 		let cur_subtotal = 0

	 		console.log('items:')
		 	for (let key in items){
		 		console.log(key)
		 		let isDiscount = false

		 		if (matchingPairs !== undefined && matchingPairs.includes(key)){
		 			isDiscount = true
		 		}

		 		cur_subtotal += items[key].quantity * items[key].price

		 		if (isDiscount){
		 			amount += items[key].quantity * items[key].discountPrice
		 			cur_discount += items[key].quantity * items[key].price - items[key].quantity * items[key].discountPrice
		 		}else{
		 			amount += items[key].quantity * items[key].price
		 		}
		 		
		 	}


		 	setDiscount(cur_discount)
		 	setCurSubtotal(cur_subtotal)

		 	//console.log(amount)
		 	//setTotal(amount)
		 	return amount
	 }


	 /*
	 useEffect(() => {

	 	if (update){
		 	console.log('ITEMS CHANGED')
		 	console.log(items)
		 	//updateTotal(items)

		 	const cur_total = getTotal(items)

		 	setTotal(cur_total)

		 	setUpdate(false)
	 	}
	 	
	 }, [items, cartItems, update])
	*/
	
	function isPlaying(audioElement) {
	  if (audioElement === null){
	  	return false
	  }else{
	  	return !audioElement.paused;
	  }
	  
	}


	function pauseAtEndTime(endTime) {
	  if (audio.currentTime >= endTime) {
	    audio.pause();
	    audio.removeEventListener('ended', pauseAtEndTime);
	  }
	}


	function play(sound, startTime = 0, duration = null) {

	 	//audio = new Audio(sound)

	 	if (audio !== null) {
		    audio.pause();
		 }
		    
		 let temp_audio = new Audio(sound);
		 temp_audio.currentTime = startTime;
		 temp_audio.play();



		 if (duration !== null) {
		    setTimeout(function() {
		      temp_audio.pause();
		    }, duration * 1000);
		  }


		 setAudio(temp_audio)

        //new Audio(sound).play()
     }

	

	return(

		<>


		<div className="ClayPayApp">
			<div style={{height:'100%', width:'100%', backgroundColor:'', position:'absolute'}}>
				<div style={{width:'100%', height:'160px', backgroundColor:'', display:'flex',
											justifyContent:'center', alignItems:'center'
										}}>
					<img className={'imageLogo'} src={claypaylogo} style={{height:'120px', width:'auto', marginTop:'25px'}} />
				</div>


				<DisplayHeader 
					play={play}
					submit={submit}
					setSubmit={setSubmit}

					proceed={proceed}
					setProceed={setProceed}
					//setInputBlocked={setInputBlocked}
					//activeInterfaces={activeInterfaces}
					setActiveInterfaces={setActiveInterfaces}

					clayPayOpen={clayPayOpen}
					setClayPayOpen={setClayPayOpen}

					
					setLocation={setLocation}
					setPrevLocation={setPrevLocation}
				/>


		 		<hr className='hrline'/>
				<div style={{width:'100%', height:'480px', backgroundColor:'', overflow:'scroll', display:'flex', justifyContent:'center'}}>
					
				 		{!submit & !proceed ?

								<DisplayItems 
									items={items}
									setItems={setItems}

									itemsList={itemsList}
									setItemsList={setItemsList}

									play={play}
									token={token}
									cartItems={cartItems}
									setCartItems={setCartItems}
									setUpdate={setUpdate}
								/>
						

							: submit && proceed ?


							<BuildTransaction 

								activeWallet={activeWallet}

								items={items}
								setItems={setItems}
								
								subtotal={curSubtotal}
								discount={discount}
								total={total}


								setSubtotal={setCurSubtotal}
								setDiscount={setDiscount}
								setTotal={setTotal}

								cartItems={cartItems}

								setCartItems={setCartItems}

								setUpdate={setUpdate}

								resetShopping={resetShopping}



								chosenOption={chosenOption}
								setChosenOption={setChosenOption}

								exchangeRate={exchangeRate}
								setExchangeRate={setExchangeRate}

								snekExchangeRate={snekExchangeRate}
								setSnekExchangeRate={setSnekExchangeRate}

								//stakeAddress={stake_address}

							/>


							: proceed ?

							

							<DisplayPaymentMethods
								clayTotal={total}
								play={play}

								chosenOption={chosenOption}
								setChosenOption={setChosenOption}


								exchangeRate={exchangeRate}
								setExchangeRate={setExchangeRate}

								snekExchangeRate={snekExchangeRate}
								setSnekExchangeRate={setSnekExchangeRate}


							/>



							: null




						}
					
				</div>

				<div style={{width:'100%', height:'200px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
				<hr className='hrline'/>


				<DisplayFooter 
					token={token}
					subtotal={curSubtotal}
					discount={discount}
					total={total}
					play={play}
					submit={submit}
					setSubmit={setSubmit}

					proceed={proceed}
					setProceed={setProceed}
					

					matchingPairs={matchingPairs}
				/>
						
				</div>
			</div>
		</div>


		</>

	)
}

export default ClayPayUI;