import { useEffect, useState, useRef, useMemo, useContext, Suspense } from 'react';


import { Html, ContactShadows, Float, Environment, useGLTF, OrbitControls, PresentationControls, useBoxProjectedEnv, CubeCamera } from '@react-three/drei'


import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'


//import ClayPayUI from './ClayPayUI'


function ClayTablet(props) {

  const {root_url} = props
  const { nodes, materials } = useGLTF(root_url + 'clay_pay_2.glb')
  const tablet = useRef()
  const tablet2 = useRef()


return(
  
  <>



    <Suspense fallback={null}>

       <group {...props} dispose={null}>
                <mesh ref={tablet} geometry={nodes.ClayPay_low.geometry} material={materials.ClayPay_low} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
                <mesh ref={tablet2} geometry={nodes.ClayPay_Glass_low.geometry} material={materials.ClayPay_Glass_low} rotation={[Math.PI / 2, 0, 0]} scale={0.02} />
        </group>

    </Suspense>

 

  </>
  
  )
}

export default ClayTablet;
