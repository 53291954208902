import React from 'react'
import { Vortex } from 'react-loader-spinner';
import { PopUp, H1, H2, Line, CloseButton,  } from './TransactionProgress.styled';


function TransactionPopUp (props) {

    return (props.trigger) ? (
        <PopUp>
            <H1 style={{fontSize:'20px'}}>TRANSACTION PROGRESS</H1>
            <Line></Line>
            <br></br>
        
                <Vortex
                    height={120}
                    width={120}
                    colors={['#00AEEF', '#1d4ff2', '#B2FE80', '#FFEB6C', '#DA836F', '#BE93DE']}
                    visible={true}
                    wrapperStyle={{}}
                />
         <div className="progress-label">
            <br></br>
            <br></br>
                <H2 style={{fontSize:'20px'}}>{props.transactionStatus}</H2>
                <CloseButton type="button" onClick={() => props.setTrigger(false)}>X</CloseButton> 

            </div>
            {props.children}
          
        </PopUp>
    ) : "";

}

export default TransactionPopUp;