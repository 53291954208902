import Mint from './Mint'

// components/Card.js
import React from 'react';
import styled from 'styled-components';

import {useState} from 'react'

// const CardWrapper = styled.div`
//   border: 6px solid transparent;
//   border-radius: 35px;
//   padding: 10px;
//   margin: 10px;
//   width: 350px;
//   height: 480px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

//   cursor: pointer;

//   background: rgba(1,1,1,0.4);
//   backdrop-filter: blur(6px);

//   &:hover{
//   	 border: 6px solid #48B35A;
//   }

//   @media (max-width: 625px) {
// 	  background: rgba(45, 55, 87, 0.98);
// 	  backdrop-filter: blur(6px);
// 	}
// `;


const CardWrapper = styled.div`

  padding: 10px;
  margin: 10px;
  width: 350px;
  height: 480px;
  
  cursor: pointer;

`



const CardWrapperOmen = styled.div`

  padding: 10px;
  margin: 10px;

  width: 500px;
  height: 500px;
  
  cursor: pointer;

`




const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
`;

const Content = styled.p`
  font-size: 1rem;
`;


const CardGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  justify-items: center;
`;


const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/'

//const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/'

const Card = ({img_url, title, price, token_name, setMintAsset, 
		       isAlertOpen, setIsAlertOpen, submittedTx,setSubmittedTx, 
		       cartItems, setCartItems, matchingPairs}) => {
	//console.log(img_url)


	const hoverImageStyle = {width:'270px', height:'270px', objectFit:'cover', filter: `drop-shadow(0px 0px 30px #5870F6)`,
		marginLeft:'30px', marginTop:'30px'
	}

	const hoverOmenImageStyle = {width:'240px', height:'240px', objectFit:'cover', filter: `drop-shadow(0px 0px 30px #ED1A70)`,
		marginLeft:'25%', marginTop:'18%'
	}

	const normalImageStyle = {width:'270px', height:'270px', objectFit:'cover',
		marginLeft:'30px', marginTop:'30px'
	}


	const normalOmenImageStyle = {width:'240px', height:'240px', objectFit:'cover',
		marginLeft:'25%', marginTop:'18%'
	}

	const [cardImageStyle, setCardImageStyle] = useState(normalImageStyle)


	const [cardOmenImageStyle, setCardOmenImageStyle] = useState(normalOmenImageStyle)


	



  return (



  	<>




  	{!img_url.startsWith('Omen') ?


    <CardWrapper>
      {/*<Title>{title}</Title>
      <Content>{content}</Content>*/}

      {/*<div style={{width:'100%', height:'300px'}}>
      	<img src={""} style={{width:'100%', objectFit:'cover'}} />
      </div>*/}

      {/*<div style={{width:'300px', height:'300px', borderRadius:'10px'}}>
      	<img src={root_url + img_url + '.png'} 
      		style={{ width: '100%', height: '100%', objectFit: 'cover', 
      				borderRadius: '10px' }}
      	/>
      </div>*/}

    	<div style={{height:'100%', width:'100%', backgroundColor:'', 
    		display:'flex', flexDirection:'column'}}>

    		<div style={{height:'4%', width:'100%', backgroundColor:''}}>

    		</div>

    		<div style={{width:'100%', height:'76%', backgroundColor:'',
    					display:'flex', justifyContent:'center', alignItems:'center'
    		}}>

    			<div style={{width:'350px', height:'350px', borderRadius:'5px',
    						display:'flex', justifyContent:'center', alignItems:'center'

    						
  							
    			}}>

    				
    			

    				<div style={{width:'335px', height:'335px', borderRadius: '1rem',
    					boxShadow: `inset 3px 3px 1px 0px rgba(0,0,0,0.35)`, backgroundColor:'rgba(0,0,0,0.45)'
    				}}>
    					
    					<img src={root_url + img_url + '.png'}  

    						style={img_url && img_url.startsWith('Omen') ? normalOmenImageStyle : cardImageStyle}

    						onMouseEnter={()=>{

    							if (img_url && img_url.startsWith('Omen')){
    								setCardImageStyle(hoverOmenImageStyle)
    							}else{
    								setCardImageStyle(hoverImageStyle)
    							}
    							
    						}}

    						onMouseOut={()=>{
    							

    							if (img_url && img_url.startsWith('Omen')){
    								setCardImageStyle(normalImageStyle)
    							}else{
    								setCardImageStyle(normalImageStyle)
    							}

    						}}

    					/>
    				</div>





    			</div>

    		</div>

    		<div style={{width:'100%', height:'20%', backgroundColor:'', 
    			display:'flex', justifyContent:'center', alignItems:'center'}}>


    			<div style={{width:'55%', height:'100%', backgroundColor:'', 
    				display:'flex', justifyContent:'center', alignItems:'center'}}>

    				<div style={{height:'80%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

	    				<div style={{width:'100%', height:'40%', backgroundColor:'',
	    					display:'flex', justifyContent:'start', alignItems:'center'
	    				}}>
	    					<div style={{marginLeft:'10px', fontSize:'26px', color:'beige',
	    						fontFamily:'ShortStack', color: 'beige', fontWeight:'bold', fontFamily:'ClayFont'
	    					}}>
	    						{title.toUpperCase()}
	    					</div>

	    				</div>

	    				<div style={{width:'100%', height:'40%', backgroundColor:'',
	    					display:'flex', justifyContent:'start', alignItems:'center',
	    					marginTop:'15px'
	    				}}>


	    				{matchingPairs !== undefined && matchingPairs.includes(token_name) ?

	    					<div style={{marginLeft:'10px', fontWeight:'bold', fontSize:'28px',
	    						fontFamily:'ShortStack', color: 'beige', marginTop:'15px',

	    						color:'beige', fontWeight:'bold', fontFamily:'ClayNationFont'
	    					}}>

	    						{title !== 'Santa Hat-' ? <div style={{height:'50px', display:'flex', justifyContent:'center', alignItems:'center'}}> {'2000 '} <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/token.svg" style={{width:'40px'}} /> </div> : 'unavailable'}


	    					</div>

	    				:
	    					<div style={{marginLeft:'10px', fontWeight:'bold', fontSize:'28px',
	    						fontFamily:'ShortStack', color: 'beige', marginTop:'15px', fontFamily:'ClayNationFont'
	    					}}>

	    							{title !== 'Santa Hat-' ? <div style={{height:'50px', display:'flex', justifyContent:'center', alignItems:'center'}}> {price + ' '} <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/token.svg" style={{width:'40px'}} />  </div> : 'unavailable'}
	    	
	    					</div>

	    				}
	    				</div>

    				</div>

    			</div>

    			<div style={{width:'45%', height:'100%', backgroundColor:'', 
    				display:'flex', justifyContent:'center', alignItems:'center'}}
    				>

    				<div style={{height:'80%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}



    				>

	    				{title !== 'Santa Hat-' ? 
	    				<Mint 
	    					price={price} 
	    					token_name={token_name} 

	    					isAlertOpen={isAlertOpen} 
			                setIsAlertOpen={setIsAlertOpen} 
			                submittedTx={submittedTx} 
			                setSubmittedTx={setSubmittedTx}


			                cartItems={cartItems}
			                setCartItems={setCartItems}

			                setCardImageStyle={setCardImageStyle}

			                hoverImageStyle={hoverImageStyle}

			                normalImageStyle={normalImageStyle}


			                hoverOmenImageStyle={hoverOmenImageStyle}

			                

	    				/>

	    				: null}

    				</div>

    			</div>

    		</div>

    	</div>


    </CardWrapper>




    :



    <CardWrapperOmen>
      {/*<Title>{title}</Title>
      <Content>{content}</Content>*/}

      {/*<div style={{width:'100%', height:'300px'}}>
      	<img src={""} style={{width:'100%', objectFit:'cover'}} />
      </div>*/}

      {/*<div style={{width:'300px', height:'300px', borderRadius:'10px'}}>
      	<img src={root_url + img_url + '.png'} 
      		style={{ width: '100%', height: '100%', objectFit: 'cover', 
      				borderRadius: '10px' }}
      	/>
      </div>*/}

    	<div style={{height:'100%', width:'100%', backgroundColor:'', 
    		display:'flex', flexDirection:'column'}}>

    		<div style={{height:'4%', width:'100%', backgroundColor:''}}>

    		</div>

    		<div style={{width:'100%', height:'76%', backgroundColor:'',
    					display:'flex', justifyContent:'center', alignItems:'center'
    		}}>

    			<div style={{width:'500px', height:'500px', borderRadius:'5px',
    						display:'flex', justifyContent:'center', alignItems:'center'

    						
  							
    			}}>

    				
    			

    				<div style={{width:'500px', height:'500px',

    					backgroundImage: `url('https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/heart.png')`,

    					objectFit:'contain', backgroundRepeat: 'no-repeat', backgroundSize:'100%'

    				}}>
    					
    					<img src={root_url + img_url + '.png'}  

    						style={cardOmenImageStyle}

    						onMouseEnter={()=>{

    							if (img_url && img_url.startsWith('Omen')){
    								setCardOmenImageStyle(hoverOmenImageStyle)
    							}else{
    								setCardImageStyle(hoverImageStyle)
    							}
    							
    						}}

    						onMouseOut={()=>{

    							if (img_url && img_url.startsWith('Omen')){
    								setCardOmenImageStyle(normalOmenImageStyle)
    							}else{
    								setCardImageStyle(normalImageStyle)
    							}

    						}}

    					/>
    				</div>





    			</div>

    		</div>

    		<div style={{width:'100%', height:'20%', backgroundColor:'', 
    			display:'flex', justifyContent:'center', alignItems:'center'}}>


    			<div style={{width:'55%', height:'100%', backgroundColor:'', 
    				display:'flex', justifyContent:'center', alignItems:'center'}}>

    				<div style={{height:'80%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

	    				<div style={{width:'100%', height:'40%', backgroundColor:'',
	    					display:'flex', justifyContent:'start', alignItems:'center'
	    				}}>
	    					<div style={{marginLeft:'10px', fontSize:'26px', color:'beige',
	    						fontFamily:'ShortStack', color: 'beige', fontWeight:'bold', fontFamily:'ClayFont'
	    					}}>
	    						{title.toUpperCase()}
	    					</div>

	    				</div>

	    				<div style={{width:'100%', height:'40%', backgroundColor:'',
	    					display:'flex', justifyContent:'start', alignItems:'center',
	    					marginTop:'15px'
	    				}}>


	    				{matchingPairs !== undefined && matchingPairs.includes(token_name) ?

	    					<div style={{marginLeft:'10px', fontWeight:'bold', fontSize:'28px',
	    						fontFamily:'ShortStack', color: 'beige', marginTop:'15px',

	    						color:'beige', fontWeight:'bold', fontFamily:'ClayNationFont'
	    					}}>

	    						{title !== 'Santa Hat-' ? <div style={{height:'50px', display:'flex', justifyContent:'center', alignItems:'center'}}> {'2000 '} <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/token.svg" style={{width:'40px'}} /> </div> : 'unavailable'}

	    					</div>

	    				:
	    					<div style={{marginLeft:'10px', fontWeight:'bold', fontSize:'28px',
	    						fontFamily:'ShortStack', color: 'beige', marginTop:'15px', fontFamily:'ClayNationFont'
	    					}}>

	    						{title !== 'Santa Hat-' ? <div style={{height:'50px', display:'flex', justifyContent:'center', alignItems:'center'}}> {price + ' '} <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/ClayPay/images/token.svg" style={{width:'40px'}} />  </div> : 'unavailable'}
	    					</div>

	    				}
	    				</div>

    				</div>

    			</div>

    			<div style={{width:'45%', height:'100%', backgroundColor:'', 
    				display:'flex', justifyContent:'center', alignItems:'center'}}
    				>

    				<div style={{height:'80%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}



    				>

	    				{title !== 'Santa Hat-' ? 
	    				<Mint 
	    					price={price} 
	    					token_name={token_name} 

	    					isAlertOpen={isAlertOpen} 
			                setIsAlertOpen={setIsAlertOpen} 
			                submittedTx={submittedTx} 
			                setSubmittedTx={setSubmittedTx}


			                cartItems={cartItems}
			                setCartItems={setCartItems}



			                setCardImageStyle={setCardOmenImageStyle}


			                hoverImageStyle={hoverImageStyle}
			                normalImageStyle={normalImageStyle}


			                hoverOmenImageStyle={hoverOmenImageStyle}
			                normalOmenImageStyle={normalOmenImageStyle}


			                img_url={img_url}

			                

	    				/>

	    				: null}

    				</div>

    			</div>

    		</div>

    	</div>


    </CardWrapperOmen>


  }



    </>
  );
};

export default Card;