import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import styledComp from "styled-components";

import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";


const ClayStepper = styled(Stepper) ({
   marginTop: '7%',
})




const StepIcon = styledComp.img`
  width: 35px;
  height: auto;
  backgroundColor: #fff;
`;

const ColorlibConnector = styled(StepConnector) ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, #2A71D0 0%, #8247E5 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
         'linear-gradient(90deg, #2A71D0 0%, #8247E5 100%)' },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 0,
    borderRadius: 1,
    backgroundColor:'rgba(0, 0, 0, 0.3)'
  },
});

const ColorlibStepIconRoot = styled('div')(({ ownerState}) => ({
  backgroundColor: 'rgba(27, 27, 27, 1)',
  zIndex: 1,
  color: '#fff',
  width: 60,
  height: 60,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active &&  {
    backgroundImage:
    'linear-gradient(90deg, #1756B5  0%,  #2A71D0 100%)',  
  }),
  ...(ownerState.completed &&  {
    backgroundImage:
    'linear-gradient(90deg, #2A71D0 0%, #9630CF 97.92%)',    
 
  }),


}));



function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  function FinalStep(props) {
    const { completed } = props;
    return <>{!completed ? <LinkIcon/> : <Check/>}</>;
  }
  
  const icons = {
    1: <CardanoIcon />,
    2: <PolygonIcon />,
    3: <FinalStep completed={completed} />,
  };

  return (
    <ColorlibStepIconRoot 
        ownerState={{ completed, active }} 
        className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};



const ColorlibStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      fontFamily: 'ShortStack',
      color: 'rgba(255, 255, 255, 1)',
    },
    [`&.${stepLabelClasses.active}`]: {
      fontFamily: 'ShortStack',
        color: 'rgba(255, 255, 255, 1)'
    },

    fontFamily: 'ShortStack',
    fontSize: '0.5em',
    color: 'rgba(255, 255, 255, 0.55)',
    textAlign: 'center',
    alignItems: 'center',
  }
}));

const steps = ['CONNECT CARDANO WALLET', 'CONNECT POLYGON WALLET', 'LINK WALLETS'];





const img_path = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'
function CardanoIcon() {

  return (
    <>
      <StepIcon src={img_path+"cardanoIcon.svg"} alt="Cardano icon" />
    </>
  );
}

function PolygonIcon() {
  return (
    <>
      <StepIcon src={img_path+"polygonIcon.svg"} alt="Polygon icon" />
    </>
  );
}

function LinkIcon() {
  return (
    <>
      <StepIcon src={img_path+"linkIcon.svg"} alt="Polygon icon" />
    </>
  );
}

function CheckIcon() {
  return (
    <>
      <StepIcon src={img_path+"checkIcon.svg"} alt="Polygon icon" />
    </>
  );
}

function FinalStep(props) {
  const { completed } = props;
  return <>{!completed ? <LinkIcon /> : <CheckIcon />}</>;
}



export default function CustomizedSteppers(props) {
  const { activeStep, setActiveStep, steps } = props;
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      {/* <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}



     <ClayStepper 
      alternativeLabel 
      activeStep={activeStep} 
      connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={index} >
            <ColorlibStepLabel  StepIconComponent={ColorlibStepIcon}>{label}</ColorlibStepLabel>
          </Step>   
        ))}
      </ClayStepper>



      {/*<div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",

          position:'absolute',
          top:'0px'
        }}
      >
        <button className='PreviousNextbtn'
          onClick={() => {
            setActiveStep((prev) => Math.max(prev - 1, 0));
          }}
          style={{ width: "50px" }}
        >
          -
        </button>
        <button
          onClick={() => {
            setActiveStep((prev) => Math.min(prev + 1, 3));
          }}
          style={{ width: "50px" }}
        >
          +
        </button>
      </div>*/}

      
    </Stack>
  );
}
