import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import logo from "./Images/logo.png";
import { motion } from "framer-motion";


const GlobalStyle = createGlobalStyle`

body {
    // margin: 0;
    // padding: 0;

    background: radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%);
    

    background-blend-mode: overlay, normal;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    height: 100svh;

    // font-family: 'ClayNationFont', 'ClayFontJika';
    // letter-spacing: 0.15rem;
    // font-weight: 100;
    // color: var(--claytext-primary);
    

    --clayblue-primary: #5870F6;
    --clayblue-secondary: #4FC8EF;
    --claypink: #ED1A70;
    --claypurple-primary: #593895;
    --claypurple-secondary: #A15EE4;
    --clayyellow: #FEBD12;
    --claygreen: #ABCD59;   
    --clayorange: #EC6134;
    --claytext-primary: rgb(255,255,240);
    --claytext-secondary: rgba(0, 0, 0, 0.42);
    --claybutton-secondary: rgba(0, 0, 0, 0.3);
    --background: rgba(26, 47, 73, 0.7);
    --panel-background: rgba(0, 0, 0, 0.25);
    --tabs-background: rgba(47, 69, 96, 1);

}

`;
export default GlobalStyle;



export const Logo = styled(motion.div)`
width: 10vw;
height: 11rem;

background-image: url(${logo});
background-size: contain;
background-repeat: no-repeat;
background-position: center;

margin-top: 4rem;
`;


const theme = {
    blue: {
        default: 'var(--clayblue-primary)',
        hover: 'var(--clayblue-primary)',
    }, 
    pink: {
        default: 'var(--claypink)',
        hover: "#ED1A70",
    },
    blueInactive: {
        default: 'var(--claybutton-secondary)',
        hover: 'var(--clayblue-primary)',
    },
    pinkInactive: {
        default: 'var(--claybutton-secondary)',
        hover: 'var(--claypink)',
    }
};


export const Button = styled(motion.button)`
    background-color: ${props => theme[props.theme].default};
    color: var(--claytext-primary);
    border: none;
    border-radius: 0.7rem;
    font-family: 'ClayNationFont', 'ClayFontJika';
    letter-transform: uppercase;
    padding: 1rem 2.5rem;
    font-size: ${props => props.fontSize};

    letter-spacing: 0.2rem;
    text-wrap: nowrap;
    text-align: center;
    cursor: pointer;
    font-weight: 100;
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
    transition: 0.2s ease-in-out;

    &:hover {
        background-color: ${(props) => theme[props.theme].hover};
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);      
    }
    &:disabled {
        background-color: rgba(0, 0, 0, 0.2);
    }
    @media screen and (max-width: 600px) {
        font-size: clamp(0.5rem, 3.5vw, 4rem);
        margin: 0.2rem 1rem;
        border-radius: 0.5rem;
    }

    @media screen and (max-width: 400px) {
        font-size: clamp(0.5rem, 2.8vw, 4rem);
        margin: 0.2rem 1rem; 
    }

    @media screen and (max-width: 768px) and (orientation: landscape) {
        font-size: clamp(1rem, 2vw, 4rem);
    }
    `;

    Button.defaultProps = {
        theme: "blue"
      };


export const ButtonSecondary = styled(Button)`
    font-size: clamp(0.5rem, 1vw, 3rem);
    padding: 0.7rem 2rem;
    font-family: 'ClayFont';
    
    @media screen and (max-width: 768px) {
        font-size: clamp(0.5rem, 2.8vw, 4rem);
        margin: 0.2rem 1rem; 
    } 
    @media screen and (max-width: 768px) and (orientation: landscape) {
        font-size: clamp(0.5rem, 1.8vw, 4rem);
    }
 `;


 export const PageContainer = styled.div`
    height: 100vh;
    width: 80vw;
    right: 0;
    top: 0;
    position: absolute;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'ClayNationFont', 'ClayFontJika';
    overflow-y: scroll;

    `;


    export const H1 = styled.h1`
    font-size: clamp(0.5vw, 1.8vw, 3vw);
    color: var(--claytext-primary);
    font-family: 'ClayNationFont';
    letter-spacing: 0.15rem;
    font-weight: 100;   
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);

    `;

