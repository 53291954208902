import styled from "styled-components";


export const FormWrapper = styled.div`
height: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
@media screen and (max-width: 768px) and (orientation: landscape) {
    height: 150%;
} 
`;

export const FormLabel = styled.label`
font-size: clamp(0.5rem, 1.2vw, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
width: 15rem;
font-family: 'ClayFont';
text-align: left;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem); 
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.2vw, 3rem);
}
`;

export const FormInput = styled.input`
box-sizing: border-box;
background-color: var(--claybutton-secondary);
color: var(--claytext-primary);
font-family: 'ShortStack';
border: none;
border-radius: 0.5em;
box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
height: 2rem;
margin-top: 0.5rem;
margin-bottom: 1.5rem;
width: 15rem;
padding: 0.5rem;
&:focus {
    outline: 2px solid var(--clayblue-primary);
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    margin-bottom: 0.5rem;
    height: 1.5rem;
}
`;

export const Alert = styled.p`
color: var(--claypink);
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem;
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
line-height: 0.2rem;
font-family: 'ClayFont';
@media screen and (max-width: 768px) and (orientation: landscape) {
    line-height: 0rem;
}
`;


export const H1 = styled.h1`
font-size: clamp(0.5rem, 2.2vw, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 4.5vw, 3rem);
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
    padding: 0;
}   
`;

export const H2 = styled(H1)`
font-size: clamp(0.5rem, 1.4vw, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
padding-bottom: 1rem;
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem);
    padding: 1rem 1rem; 
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.2vw, 3rem);
    padding: 0;
}
    
`;

export const H3 = styled(H1)`
font-size: clamp(0.5rem, 1.7vw, 3rem);
letter-spacing: 0rem;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem);
} 
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
} 
`;

export const H4= styled(H1)`
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem;
color: var(--claypink);
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3vw, 3rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
}  
`;

export const PromptContainer = styled.div`
display: inline-flex;
width: 100%;
justify-content: space-between;
padding-top: 1rem;
`;


export const Prompt = styled.div`
align-self: flex-end;
display: flex;
flex-direction: column;
align-items: center;
margin-right: 2rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2.5vw, 3rem);
    align-self: center;
    margin: 0 auto;

} 
`;

export const PromptPasswordReset = styled.div`
align-self: flex-start;
display: flex;
flex-direction: column;
align-items: center;
margin-left: 2rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2.5vw, 3rem);
    align-self: center;
    margin: 0 auto;
  
} 
`;

