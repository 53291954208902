import { useState, useEffect, useContext } from 'react'

import { useSearchParams } from "react-router-dom";

import Drawer from '@mui/material/Drawer';

import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';


import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';


import Attributes from './Attributes'

import Filters from './Filters'
import PitchesFilters from './PitchesFilters'

import MarketOverview from './MarketOverview'

import './SideBar.css'
import './StakingSideBar.css'
import './KilnTransition.css'

import './ClaimClay.css'

import './BakingSideBar.css'
import './ResetAllButton.css'
import './Filters.css'
import './TotalBaked.css'
import './SearchBar.css'


import {MainClaimContext} from './ClaimContext'

import styled from "styled-components";



const KilnTransition = styled.div`

    position: absolute;
    
    animation: ${props => props.animation};



    @keyframes appear {
        0%   { opacity: 1}
        100% { opacity: 0}
    }

    @keyframes disappear {
        0%   { opacity: 0}
        100% { opacity: 1}
    }

    @keyframes off {
        0%   { opacity: 1}
        100% { opacity: 1}
    }

    @keyframes on {
        0%   { opacity: 0}
        100% { opacity: 0}
    }

`

const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});






function SideBar(props) {

  let [searchParams, setSearchParams] = useSearchParams();

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const [totalClay, setTotalClay] = useState(0) 

  const isOpen = props.isOpen
  const setIsOpen = props.setIsOpen

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [activeTab, setActiveTab] = useState(props.sideBarTab)


   const txsBaking = props.txsBaking
   const txsCompleted = props.txsCompleted
   const totalBaked = props.totalBaked
   const totalUnbaked = props.totalUnbaked
   const setTotalUnbaked = props.setTotalUnbaked
   const stakeADAAmount = props.stakeADAAmount
   const setStakeADAAmount = props.setStakeADAAmount

   const stakedWithClay = props.stakedWithClay
   const setStakedWithClay = props.setStakedWithClay

   const formattedClayAmount = props.formattedClayAmount
   const setFormattedClayAmount = props.setFormattedClayAmount

   const setCurrentSlot = props.setCurrentSlot


   const fetchingFilteredAssets = props.fetchingFilteredAssets
   const setFetchingFilteredAssets = props.setFetchingFilteredAssets


  const setSideBarTab = props.setSideBarTab

  const sideBarTab = props.sideBarTab

  const assetsOwned = props.assetsOwned

  const currentSlot = props.currentSlot

  const isAccountView = props.isAccountView

  const hasBaking = props.hasBaking


  const isAlertOpen = props.isAlertOpen 
  const setIsAlertOpen = props.setIsAlertOpen

  const submittedTx = props.submittedTx
  const setSubmittedTx = props.setSubmittedTx

  const searchBarMode = props.searchBarMode
  const setSearchBarMode = props.setSearchBarMode

  const matchesXS = props.matchesXS
  const matchesS = props.matchesS
  const matchesM = props.matchesM
  const matchesL = props.matchesL

  const setLeaderboardData = props.setLeaderboardData

  const isAnalytics = props.isAnalytics

  const setChosenCollection = props.setChosenCollection
  const chosenCollection = props.chosenCollection


  const appliedFilters = props.appliedFilters 
  const setAppliedFilters = props.setAppliedFilters


  const [selectedToBake, setSelectedToBake] = useState("0")

  const [selectedCategory, setSelectedCategory] = useState()
  const [openCategories, setOpenCategories] = useState([])

  const [checkedAttributes, setCheckedAttributes] = useState([])

  const recordsPage = props.recordsPage
  const setRecordsPage = props.setRecordsPage

  const pitchesFilters = props.pitchesFilters


  const sortOrder = props.sortOrder 
  const setSortOrder = props.setSortOrder

  const yourAssetsFilters = props.yourAssetsFilters


  const setSearchQuery = props.setSearchQuery


  const selectedPlot = props.selectedPlot 
  const setSelectedPlot = props.setSelectedPlot

  const updateCollection = props.updateCollection




  const [includeCn, setIncludeCn] = useState(true)
  const [includeGc, setIncludeGc] = useState(true)

  const [openCategoryTabs, setOpenCategoryTabs] = useState([])

  const leaderboardPage = props.leaderboardPage
  const setLeaderboardPage = props.setLeaderboardPage

  const [tempFilteredAssets, setTempFilteredAssets] = useState({"assets":[],"filters":[]})

  const [lastUpdateSlot, setLastUpdateSlot] = useState(0)


  const [kilnAnimation, setKilnAnimation] = useState('flames 2s linear infinite alternate-reverse both 0.2s')

  const [flamesVisible, setFlamesVisible] = useState(false)








  


  

 
  const selectedStyle = {
    width:"7vw",
    minWidth: "75px",
    backgroundColor: "#f28a80",
    boxShadow: "3px 0px 2px rgba(0, 0, 0, 0.11), inset -6px 4px 6px rgba(255, 156, 131, 0.71), inset -6px -5px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "200px",
    overflow: "hidden"
  }


  const notSelectedStyle = {
    width:"7vw",
    minWidth: "75px",
    height: "5vh",
    minHeight:'40px',
    background: "rgba(201, 76, 76, 0)",
    borderRadius: "200px"
  }


  /*

  const categories = [
    {body:[]},
    {clothes:[]},
    {hat:[]},
    {hair:[]},
    {eyes:[]},
    {eyebrows:[]},
    {mouth:[]},
    {accessories:[]},
    {background:[]}
    {wings:[]},
    {extra:[]}
  ]

*/


  const categories = {
    body: {cn:[],gc:[]},
    clothes: {cn:[],gc:[]},
    hat:{cn:[],gc:[]},
    hair:{cn:[],gc:[]},
    eyes:{cn:[],gc:[]},
    eyebrows:{cn:[],gc:[]},
    mouth:{cn:[],gc:[]},
    accessories:{cn:[],gc:[]},
    background:{cn:[],gc:[]},
    wings:{cn:[],gc:[]},
    extra:{cn:[],gc:[]}
  }

  const pants_categories = {
    type: {pants:[]},
    background: {pants:[]}
  }


  const pitches_categories = {
    zone: {pitches:[]},
    size: {pitches:[]}
  }


  useEffect(() => {
  if (isOpen == false && matchesM){
    setIsOpen(true)
  }
},[matchesM, matchesL])
  
/*

     function updateSideBarTab(sideBarTabName){
        //console.log('update sideBarTab')
        //console.log(sideBarTabName)
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('sideBarTab') + " = " + String(sideBarTabName) + '; ' + expires + "; path=/"
  }
*/



{/*
  const getAmount = (clayInfo) => {
  if (clayInfo && currentSlot){
    if (clayInfo.processing_tx == null){
        let rate_per_slot = clayInfo.rate / 86400
        let slots_passed = parseInt(currentSlot) - parseInt(clayInfo.slot) - 1209600
        let total = slots_passed * rate_per_slot
        total = total.toFixed(0)

        if (total < 0){
          total = 0
        }

        return total
    }

    else{
      return 0
    }
  }else{
    return 0
  }
 }


  useEffect(() => {
    props.setSideBarTab(activeTab);
  }, [activeTab]);


     function updateSideBarTab(sideBarTabName){

        //console.log('update sideBarTab')
        //console.log(sideBarTabName)
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('sideBarTab') + " = " + String(sideBarTabName) + '; ' + expires + "; path=/"

  }


   function checkSideBarTab(){
        let cookie = document.cookie
        let cookies = cookie.split(';')
        //console.log('SIDE BAR COOKIES')
        //console.log(cookies)
        let cookies_dict = {}
        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')
            cookies_dict[entry[0]] = entry[1]
        }
       return cookies_dict['sideBarTab']
  }




  useEffect(() => {
    let sideBarTabName = activeTab
    //console.log('SIDE BAR TAB')
    if (typeof(sideBarTabName) != 'undefined'){
        //console.log('UPDATING')
        //console.log(sideBarTabName)
        updateSideBarTab(sideBarTabName)
    }else{
        let cur_sideTab = checkSideBarTab()

        //console.log('undefined')
        //console.log(cur_sideTab)

        if (typeof(cur_sideTab) == 'string'){
                setActiveTab(cur_sideTab)
            }else{
                updateSideBarTab('Baking')
                setActiveTab('Baking')
        }
    }

  }, [activeTab])



   useEffect(() => {

    if (currentSlot && assetsOwned){
      let tempTotalUnbaked = 0
      let clays_info = assetsOwned['clays_info']
      for (let i = 0; i < assetsOwned['clays'].length; i++){

        let cur_asset = assetsOwned['clays'][i]
        //console.log(cur_asset)
        let clay_info = clays_info[cur_asset]
        let cur_asset_amount = getAmount(clay_info)
        tempTotalUnbaked = tempTotalUnbaked + parseInt(cur_asset_amount)

      }

      setTotalClay(assetsOwned['clayToken'])
      //console.log(assetsOwned['clayToken'])

      setTotalUnbaked(String(tempTotalUnbaked))
    }

  }, [assetsOwned])



  useEffect(() => {
     if (currentSlot && assetsOwned){

      let tempTotalToBake = 0

      let clays_info = assetsOwned['clays_info']

      for (let i = 0; i < selectedTokens.length; i++){
        let cur_asset = selectedTokens[i]
        let clay_info = clays_info[cur_asset]

         let cur_asset_amount = getAmount(clay_info)

         tempTotalToBake = tempTotalToBake + parseInt(cur_asset_amount)

      }

      setSelectedToBake(String(tempTotalToBake))

    }

  }, [selectedTokens])



useEffect(() => {
  if (isOpen == false && matchesM){
    setIsOpen(true)
  }
},[matchesM, matchesL])


function resetFilters() {
    setSearchBarMode(false)
    setRecordsPage(1)
    setLeaderboardPage(1)
    setCheckedAttributes([])
    setOpenCategories([])
    setIncludeCn(true)
    setIncludeGc(true)
    setOpenCategoryTabs([])
}


async function getCurrentSlot(){
        let slot = await fetch('https://claymarket.io/api/current/slot/').then(response => response.json())
                                                                  .then(data => data)
        slot = parseInt(slot.slot)
        setCurrentSlot(slot) 
}


useEffect(() => {

    setRecordsPage(1)
    setLeaderboardPage(1)

}, [checkedAttributes])




async function performFetch(){
    setFetchingFilteredAssets(true)
    await fetchFilteredAssets(checkedAttributes, leaderboardPage)
    setFetchingFilteredAssets(false)
}


useEffect(() => {
    if(currentSlot){
        if (currentSlot - lastUpdateSlot >= 90){
        
         if (!searchBarMode){
            setLastUpdateSlot(currentSlot)
            performFetch()
         }
     }else{
        //do nothing...
     }

    }else{
        getCurrentSlot()
    }


}, [currentSlot])



useEffect(() => {

    if(currentSlot){
        if (!searchBarMode){
            performFetch()
         }
    }else{
        
        getCurrentSlot()
    }

},[checkedAttributes, leaderboardPage, includeGc, includeCn, isAccountView])



useEffect(() => {
    setRecordsPage(1)
    setLeaderboardPage(1)

},[includeGc, includeCn, checkedAttributes])




  async function fetchFilteredAssets(filter_list, page){
    if (isAccountView == true){
        return
    }

    let filtered_results;

    let initial_string = '?slot=' + currentSlot + '&page=' + page

    let query_string = ''


    for (let i = 0; i < filter_list.length; i++){
        if (filter_list[i].split('_')[0] == 'cn' && includeCn || filter_list[i].split('_')[0] == 'gc' && includeGc){
            query_string = query_string + '&f' + String(i) +'=' + filter_list[i]
        }
    }



    if (filter_list.length == 0 || query_string == ''){
        if (includeCn){
            query_string = query_string + '&cn=cn'
        }

        if (includeGc){
            query_string = query_string + '&gc=gc'
        }
    }

    query_string = initial_string + query_string

    let url_string = 'https://clayapi.com/filtering/' + query_string
    //let url_string = 'http://198.199.79.4/filtering/leaderboard/' + query_string

    //console.log(url_string)


    filtered_results = await fetch(url_string).then(response => response.json())
                                                             .then(data => data)

    if (!('error' in filtered_results)){
         let filtered_assets = filtered_results['assets']
         //console.log(filtered_assets)

         setTempFilteredAssets({"assets":filtered_assets, "filters":filter_list})
         //setLeaderboardData(filtered_assets)
         //return filtered_results
     }else{
        //console.log('ERROR')

        setTempFilteredAssets([])
        //setLeaderboardData([])

        //return []
     }
  }


  useEffect(() => {
    if (tempFilteredAssets['filters'] == checkedAttributes){
        setLeaderboardData(tempFilteredAssets['assets'])
    }
  },[tempFilteredAssets])

*/}


  return (

       <ThemeProvider theme={theme}>
        <Drawer 
            anchor='left'
            variant={matchesS ? 'persistent' : 'temporary'}
            ModalProps={{
                keepMounted: true,
              }}
            open={isOpen}
            onClose={()=>{
              if (!matchesM){
                setIsOpen(false)
              }
            }}

            className='drawer'

            PaperProps={{
            sx: {
              width:'20vw', 
              height: '99%',
              minWidth:'340px',
              borderRadius: '0px 74px 74px 0px',
              backdropFilter: 'blur(85px)',
              border: 'double 3px transparent',
              borderLeft: '0px',
              backgroundImage: `linear-gradient(180deg, rgba(69, 62, 112, 0.95) 0%, #1D3A3D 100%), 
                    linear-gradient(rgba(239, 132, 99, 0.78) 4.03%, #CCA7B5 44.47%, rgba(96, 157, 183, 0.82) 100%)`,
              backgroundOrigin: `border-box`,
              backgroundClip: `content-box, border-box`
            }
          }} >

          {!matchesL ?
          <div style={{position: 'absolute', top:'30px', right:'20px', zIndex:'5'}}>
            <IconButton onClick={()=>{setIsOpen(false)}} style={{color:'white', height:'50px',width:'50px', fontFamily:'shortstack'}}>X</IconButton>
          </div>

          :null}

          <div  style={{position:'relative', display:'flex',justifyContent:'center',marginTop:'35px', backgroundColor:''}}>
              <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png' style={{height:'24vh', minHeight:'150px',width:'auto',zIndex:'1'}}/>
          </div>


           <Grid container spacing={2} style={{position:'relative',top:'1vh'}} >

 <Grid item xs={12} style={{position:'relative', width:'100%', display:'flex',justifyContent:'center', marginBottom:'5vh'}}>
        {isAccountView ?   
           <div className="triStateToggle">
              <Button style={activeTab=='Baking' ? selectedStyle : notSelectedStyle} onClick={() => setActiveTab('Baking')}><Typography variant="p" style={{color:'white'}}>Holdings</Typography></Button>
              <Button style={activeTab=='Activity' ? selectedStyle : notSelectedStyle} onClick={() => setActiveTab('Activity')}><Typography variant="p" style={{color:'white'}}>Activity</Typography></Button>
              <Button style={activeTab=='Staking' ? selectedStyle : notSelectedStyle} onClick={() => setActiveTab('Staking')}><Typography variant="p" style={{color:'white'}}>Listings</Typography></Button>
          </div>
          : null}

   </Grid>
     
     {isAccountView && activeTab == 'Baking' ?

            <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
              Holdings
            </div>

            : 

            isAccountView && activeTab == 'Activity' ?

            <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
              Activity
            </div>

            :

            isAccountView && activeTab == 'Staking' ?

      
            <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
              Listings
            </div>


            :


            !isAccountView && !isAnalytics ? 

            
            <div style={{display:'flex', width:'100%', flexDirection:'column', justifyContent:'center'}}>

                {chosenCollection ?

                
                <div style={{width:'100%', marginBottom:'5px', marginTop:'-80px', zIndex:'2'}}>

                     <div style={{display:'flex', width:'100%', justifyContent:'center'}}>

                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',
                            borderRadius:'20px', marginBottom:'20px',
                            color:'bisque', fontFamily:'ShortStack'
                            }}>

                    <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', 
                        justifyContent:'center', alignItems:'center', width:'250px', 
                        backgroundColor:'rgba(1,1,1,0.08)', borderRadius:'20px',
                        paddingTop:'10px', paddingBottom:'10px', boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'
                        }}>
                        

                        <div style={{fontSize:'20px'}}>
                            PRESS
                        </div>

                        <button className="classicBtn" style={{display:'flex', justifyContent:'center', alignItems:'center', 
                                    width:'60px', height:'60px', backgroundColor:'#75ada5',
                                    borderRadius:'50%', cursor:'pointer', 
                                    marginTop:'5px', marginBottom:'5px'
                                    }}

                            onClick={()=>{
                                setChosenCollection('')
                                setAppliedFilters({})
                                setOpenCategories([])
                                setCheckedAttributes([])
                                yourAssetsFilters.setYourAssets(false)

                                setSearchQuery('')

                                setSortOrder('price_asc')

                                setSelectedPlot(null)

                                updateCollection('')

                                setSearchParams({})


                            }}
                            > 

                            <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ArrowBack.png' style={{width:'140px', marginTop:'0px', height:'auto'}} />
                        </button>

                            
                            <div style={{width:'200px', textAlign:'center'}}> 
                                TO <span>SELECT</span> ANOTHER <span style={{fontWeight:'bold'}}>COLLECTION</span>
                            </div>
                 

                        </div>

                        </div>

                     </div>
               
                </div>
                
                :

                
                <MarketOverview />


                


                }



            <div style={{display:'flex', width:'100%', justifyContent:'center'}}>


                {chosenCollection && chosenCollection != 'pitches' ? 

                    <div>   
                        
                        <Filters
                            categories={chosenCollection == 'pants' ? pants_categories : categories}
                            openCategories={openCategories}
                            setOpenCategories={setOpenCategories}
                            setSelectedCategory={setSelectedCategory}
                            checkedAttributes={checkedAttributes}
                            setCheckedAttributes={setCheckedAttributes}
                            openCategoryTabs={openCategoryTabs}
                            setSearchBarMode={setSearchBarMode}
                            setOpenCategoryTabs={setOpenCategoryTabs}
                            collection = {chosenCollection}

                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}

                            sortOrder={sortOrder} setSortOrder={setSortOrder}

                            yourAssetsFilters={yourAssetsFilters}

                            setSearchQuery={setSearchQuery}
                        />

                    </div>


                : chosenCollection && chosenCollection == 'pitches' ? 

                    <div>

                        <PitchesFilters 
                            categories={pitches_categories}
                            openCategories={openCategories}
                            setOpenCategories={setOpenCategories}
                            setSelectedCategory={setSelectedCategory}
                            checkedAttributes={checkedAttributes}
                            setCheckedAttributes={setCheckedAttributes}
                            openCategoryTabs={openCategoryTabs}
                            setSearchBarMode={setSearchBarMode}
                            setOpenCategoryTabs={setOpenCategoryTabs}
                            collection = {chosenCollection}

                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}

                            pitchesFilters={pitchesFilters}
                            yourAssetsFilters={yourAssetsFilters}

                            sortOrder={sortOrder} setSortOrder={setSortOrder}


                            setSearchQuery={setSearchQuery}


                        />

                    </div>

                : null



            }

               
            </div>

            </div>

            : null}

           </Grid>          

          </Drawer>

             </ThemeProvider>
  )
}

export default SideBar