import './App.css';
import ClaimContext from './components/BAKING/ClaimContext'

function Claim() {
  return (
    <div className="App">


     {/* <div style={{background:'url(/Background.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '100vh'}}>
       */}

        <div style={{background:'url(/Background.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '100vh'}}>
       
         <ClaimContext />

      </div>

    </div>
  );
}

export default Claim;