import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {
    remotePlayers: [],
    userSocketId: '',
    remotePlayersDict: {},
}


const actions = {
    SET_REMOTE_PLAYERS: "SET_REMOTE_PLAYERS",
    SET_USER_SOCKET_ID: "SET_USER_SOCKET_ID",

    SET_REMOTE_PLAYERS_DICT: "SET_REMOTE_PLAYERS_DICT",
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_REMOTE_PLAYERS:
            //console.log('SET REMOTE PLAYERS')
            return {...state, remotePlayers: action.value}; 

        case actions.SET_USER_SOCKET_ID:
            //console.log('SET REMOTE PLAYERS')
            return {...state, userSocketId: action.value}; 

        case actions.SET_REMOTE_PLAYERS_DICT:
            //console.log('SET REMOTE PLAYERS')
            return {...state, remotePlayersDict: action.value}; 

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainMultiplayerContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        remotePlayers: state.remotePlayers,

        userSocketId: state.userSocketId,

        remotePlayersDict: state.remotePlayersDict,
        
        
        setRemotePlayers: value => {
            dispatch({type: actions.SET_REMOTE_PLAYERS, value})
        },

        setUserSocketId: value => {
            dispatch({type: actions.SET_USER_SOCKET_ID, value})
        },

        setRemotePlayersDict: value => {
            dispatch({type: actions.SET_REMOTE_PLAYERS_DICT, value})
        },


        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainMultiplayerContext.Provider value={value}>
            {children}
        </MainMultiplayerContext.Provider>
        )
}


function MultiplayerContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default MultiplayerContext;


