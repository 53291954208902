import { useState, useEffect, useContext } from 'react'

import Grid from '@mui/material/Grid';

import Stack from '@mui/material/Stack';


import Slider from '@mui/material/Slider';

import Switch from '@mui/material/Switch';

import './Filters.css'
import Attributes from './Attributes'



import './SideBar.css'
import './StakingSideBar.css'
import './KilnTransition.css'

import './BakingSideBar.css'
import './ResetAllButton.css'
import './Filters.css'
import './TotalBaked.css'
import './SearchBar.css'


import styled from 'styled-components'
import './InputStyle.css'


const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`


function PitchesFilters(props) {

  const categories = props.categories
  const openCategories = props.openCategories
  const setOpenCategories = props.setOpenCategories
  const setSelectedCategory = props.setSelectedCategory
  const checkedAttributes = props.checkedAttributes
  const setCheckedAttributes = props.setCheckedAttributes
  const openCategoryTabs = props.openCategoryTabs
  const setSearchBarMode = props.setSearchBarMode
  const setOpenCategoryTabs = props.setOpenCategoryTabs

  const collection = props.collection  
  
  const appliedFilters = props.appliedFilters
  const setAppliedFilters = props.setAppliedFilters

  const pitchesFilters = props.pitchesFilters

  const sortOrder = props.sortOrder
  const setSortOrder = props.setSortOrder


  const yourAssetsFilters = props.yourAssetsFilters


  const yourAssets = yourAssetsFilters.yourAssets
  const setYourAssets = yourAssetsFilters.setYourAssets

  const listedOnly = yourAssetsFilters.listedOnly
  const setListedOnly = yourAssetsFilters.setListedOnly

  const notListedOnly = yourAssetsFilters.notListedOnly
  const setNotListedOnly = yourAssetsFilters.setNotListedOnly


  const setSearchQuery = props.setSearchQuery

  
  
  const [clayOnly, setClayOnly] = useState(false)
  const [canApply, setCanApply] = useState(false)
  
  const [absMin, setAbsMin] = useState(260)
  const [absMax, setAbsMax] = useState(2500000)
  
  const [minPrice, setMinPrice] = useState('0')
  const [maxPrice, setMaxPrice] = useState('1000000')
  
  const [priceValue, setPriceValue] = useState([0,100])
  const [priceLock, setPriceLock] = useState(false)


  const [traitFilters, setTraitFilters] = useState({})
  const [otherFilters, setOtherFilters] = useState({})    

  const [searchClassName, setSearchClassName] = useState('inputField') 
  //const [yourPitches, setYourPitches] = useState(true)
  //const [stickPointers, setStickPointers] = useState(true)
  //const [circlePointers, setCirclePointers] = useState(true)



  
  
  const priceMark = [
    {value:0,scaledValue:0},
    {value:25,scaledValue:0.5},
    {value:50,scaledValue:2},
    {value:75,scaledValue:10},
    {value:100,scaledValue:100}
  ]




  useEffect(()=>{
    setAbsPriceValues()
  },[])



  function resetFilters() {

    //console.log('RESET FILTERS')

    setOtherFilters({})
    setTraitFilters({})
    setCheckedAttributes([])
    setOpenCategories([])
    setOpenCategoryTabs([])
    setSearchBarMode(false)
    setClayOnly(false)

    setPriceValue([unscaleValue(absMin), unscaleValue(absMax)])

    setYourAssets(false)


    pitchesFilters.setStickPointers(true)
    pitchesFilters.setCirclePointers(true)


    setSearchQuery('')

    setSortOrder('price_asc')
  }




useEffect(()=>{

    let temp_otherFitlers = {...otherFilters}

    temp_otherFitlers['clay_only'] = clayOnly

    setOtherFilters(temp_otherFitlers)

}, [clayOnly])



  useEffect(()=>{
    setAppliedFilters({...traitFilters, ...otherFilters})
  },[traitFilters, otherFilters])






useEffect(() => {

    let attribute_dict = {}
    
    for (let i = 0; i < checkedAttributes.length; i++){
        let cur_attribute = checkedAttributes[i].split('_')
        let cur_category = cur_attribute[1]
        let cur_trait = cur_attribute.slice(2,).join(' ')

        if (cur_category in attribute_dict){
            attribute_dict[cur_category] = [...attribute_dict[cur_category],cur_trait]
        }else{
            attribute_dict[cur_category] = [cur_trait]
        }
    }

    setTraitFilters(attribute_dict)

},[checkedAttributes])




  useEffect(()=>{

    //console.log(priceValue)

    if (!priceLock){
        setMinPrice(scaleValue(priceValue[0]))
        setMaxPrice(scaleValue(priceValue[1]))
    }else{
        setPriceLock(false)
    }

    setCanApply(true)
    
},[priceValue])


  function priceRangeChange(event, newValue){
    //console.log(event)
    setPriceValue(newValue)
  }



  function scaleValue(value){

    if (value < 1){
        return absMin
    }

    else if ( value == 100){
        return absMax
    }

    let i = Math.floor(value/25)

    if (value % 25 == 0){
        return absMin + priceMark[i].scaledValue / 100 * absMax
    }else{
        return absMin + priceMark[i].scaledValue / 100 * absMax + (priceMark[i+1].scaledValue/ 100 * absMax - priceMark[i].scaledValue/ 100 * absMax) / 25 * (value % 25) 
    }

    return 1

}


function unscaleValue(scaledValue){
    
    if (scaledValue / absMax >= 1){
        return 100  
    }else if (scaledValue / absMin <= 1){
        return 0
    }

    let scaled_percentage = scaledValue / absMax * 100

    for (let i = 0; i < priceMark.length; i++){
        
        if (scaled_percentage <= priceMark[i].scaledValue){

            //absMin + priceMark[i].scaledValue / 100 * absMax + (priceMark[i+1].scaledValue/ 100 * absMax - priceMark[i].scaledValue/ 100 * absMax) / 25 * (value % 25) 

            let temp = 0
            if (i - 1 >= 0){

                let stepValue = (priceMark[i].scaledValue * absMax/100 - priceMark[i-1].scaledValue * absMax/100) / 25
                let numSteps = (scaledValue - absMin - priceMark[i-1].scaledValue * absMax/100) / stepValue

                /*
                console.log('SCALED VALUE')
                console.log(scaledValue)
                console.log('LOWER BOUND')
                console.log(priceMark[i-1].scaledValue * absMax/100)
                console.log('UPPER BOUND')
                console.log(priceMark[i].scaledValue * absMax/100)
                console.log(stepValue)      
                console.log(numSteps)
                console.log('LOW VALUE')
                console.log(priceMark[i-1].value)
                */

                return priceMark[i-1].value + numSteps
             }

            return temp
            break
        }

    }

}


function priceValueText(value){
    const scaledValue = scaleValue(value)

    const numFor = Intl.NumberFormat('en-US')

    const formattedValue = numFor.format(scaledValue)

    return formattedValue + ' ADA'
}




useEffect(()=>{

    if (!listedOnly && !notListedOnly && yourAssets){
         setYourAssets(false)
    }

}, [listedOnly, notListedOnly])




async function getClayTokenPrice(){

    let query_url = 'https://clayapi.com/claystats/current/'

    let temp = await fetch(query_url).then(response => response.json())
                                   .then(data => data)

    let temp_exchange_rate = 0                           
    if ('clay_price' in temp){
        temp_exchange_rate = parseFloat(temp['clay_price'])
    }

    return temp_exchange_rate
  }



async function getCollectionStats(){
    let collection_stats = await fetch('https://claymarkets.com/api/stats/collection/' + collection + '/').then(response => response.json())
                                                                  .then(data => data)

    return collection_stats
}


async function setAbsPriceValues(){

    let collection_stats = await getCollectionStats()

    let ada_floor_price = parseInt(collection_stats['ada_floor'], 10) ? parseInt(collection_stats['ada_floor'], 10) : 0
    let ada_ceil_price = parseInt(collection_stats['ada_ceil'], 10) ? parseInt(collection_stats['ada_ceil'], 10) : 0

    let clay_floor_price = parseInt(collection_stats['clay_floor'], 10) ? parseInt(collection_stats['clay_floor'], 10) : 0
    let clay_ceil_price = parseInt(collection_stats['clay_ceil'], 10) ? parseInt(collection_stats['clay_ceil'], 10) : 0

    let clay_token_price = await getClayTokenPrice()


    let min_price
    if (clay_floor_price > 0){
        min_price = Math.min(Math.round(ada_floor_price/1000000), Math.round(clay_floor_price/10000 * parseFloat(clay_token_price)))
    }else{
        min_price = Math.round(ada_floor_price/1000000)
    }
    

    let max_price = Math.max(Math.round(ada_ceil_price/1000000), Math.round(clay_ceil_price/10000 * parseFloat(clay_token_price)))



    /*
    console.log('ABS VALUES')
    console.log(min_price)
    console.log(max_price)
    */

    setAbsMin(min_price)
    setAbsMax(max_price)

    setMinPrice(String(min_price))
    setMaxPrice(String(max_price))

}


  return (
    <div>
       

        <div style={{marginTop:'0px', marginBottom:'30px', display:'flex', 
            flexDirection:'column',justifyContent:'center', alignItems:'center',
            paddingTop:'15px', paddingBottom:'10px',
            backgroundColor: true ? 'rgba(1,1,1,0.08)' : 'rgba(53, 73, 91, 0.5)', borderRadius:'20px',
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'
        }}>

        <div style={{display:'flex', width:'80%',justifyContent:'end', alignItems:'center', color:'bisque'}}>
          <div style={{fontFamily:'ShortStack', color:'bisque', marginRight:'10px', fontSize:'18px'}}>
            YOUR PITCHES
          </div>

            <div style={{transform:'scale(0.7)'}}>
                    <div className={yourAssets ? "gc-toggle-on" : "cn-toggle-off"} onClick={()=>{

                        if (!yourAssets){
                            setListedOnly(true)
                            setNotListedOnly(true)
                        }else{
                            setListedOnly(false)
                            setNotListedOnly(false)
                        }

                        setYourAssets(!yourAssets)

                    }}>
                         <div className={yourAssets ? "notch-GC-on" : "notch-CN-off"}></div>
                    </div>
                </div>

        </div>


        <div style={{display:'flex', flexDirection:'column', alignItems:'center', width:'70%', marginBottom:'5px',
                     marginTop:'10px', color:'bisque', fontFamily:'ShortStack', backgroundColor:''}}>
            
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', 
                         backgroundColor:'', width:'90%', marginBottom:'10px'}}>

                <div>
                    LISTED
                </div>

                <div style={{transform:'scale(0.55)'}}>
                    <div className={yourAssets && listedOnly ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                        
                        if (yourAssets){
                            setListedOnly(!listedOnly)
                        }
                        
                    }}>
                         <div className={yourAssets && listedOnly ? "notch-CN-on" : "notch-CN-off"}></div>
                    </div>
                </div>

            </div>


            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:'', width:'90%'}}>

                <div style={{marginRight:''}}>
                    NOT LISTED
                </div>

                <div style={{transform:'scale(0.55)'}}>
                    <div className={yourAssets && notListedOnly ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                        
                        if (yourAssets){
                            setNotListedOnly(!notListedOnly)
                        }

                        
                    }}>
                         <div className={yourAssets && notListedOnly ? "notch-CN-on" : "notch-CN-off"}></div>
                    </div>
                </div>

            </div>
        </div>
    </div>











      <div style={{marginTop:'0px', marginBottom:'30px', display:'flex', 
            flexDirection:'column',justifyContent:'center', alignItems:'center',
            paddingTop:'15px', paddingBottom:'10px',
            backgroundColor: true ? 'rgba(1,1,1,0.08)' : 'rgba(53, 73, 91, 0.5)', borderRadius:'20px',
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'
        }}>

        <div style={{fontSize:'20px', color:'bisque', fontFamily:'ShortStack', marginBottom:'10px', fontWeight:'bold'}}>
          VISIBILITY HELPERS
        </div>

        <div style={{display:'flex', width:'85%', justifyContent:'end', alignItems:'center'}}>
          <div style={{color:'bisque', fontFamily:'ShortStack', marginRight:'5px'}}>
            STICK POINTERS</div>

           <div style={{transform:'scale(0.7)'}}>
                    <div className={pitchesFilters.stickPointers ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                        
                        pitchesFilters.setStickPointers(!pitchesFilters.stickPointers) 
                        
                    }}>
                         <div className={pitchesFilters.stickPointers ? "notch-CN-on" : "notch-CN-off"}></div>
                    </div>
                </div>
        </div>

        <div style={{display:'flex', width:'85%', justifyContent:'end', alignItems:'center', marginTop:'5px'}}>
          <div style={{color:'bisque', fontFamily:'ShortStack', marginRight:'5px'}}>
            CIRCLE POINTERS
          </div>

           <div style={{transform:'scale(0.7)'}}>
                    <div className={pitchesFilters.circlePointers ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                         
                        pitchesFilters.setCirclePointers(!pitchesFilters.circlePointers)

                    }}>
                         <div className={pitchesFilters.circlePointers ? "notch-CN-on" : "notch-CN-off"}></div>
                    </div>
                </div>
        </div>



        {/*
        <div style={{display:'flex', width:'85%', justifyContent:'end', alignItems:'center', marginTop:'5px'}}>
          <div style={{color:'bisque', fontFamily:'ShortStack', marginRight:'5px'}}>
            Opacity Filter
          </div>

           <div style={{transform:'scale(0.7)'}}>
                    <div className={pitchesFilters.opacityFilter ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                         
                        pitchesFilters.setOpacityFilter(!pitchesFilters.opacityFilter)

                    }}>
                         <div className={pitchesFilters.opacityFilter ? "notch-CN-on" : "notch-CN-off"}></div>
                    </div>
                </div>
        </div>
        */}

       </div>




<div style={{marginTop:'0px', marginBottom:'30px', display:'flex', 
            flexDirection:'column',justifyContent:'center', alignItems:'center',
            paddingTop:'15px', paddingBottom:'10px',
            backgroundColor: true ? 'rgba(1,1,1,0.08)' : 'rgba(53, 73, 91, 0.5)', borderRadius:'20px',
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'
        }}>

            <div style={{backgroundColor:'', display:'flex', alignItems:'center', 
                         justifyContent:'center',width:'80%', fontFamily:'ShortStack',
                         color:'bisque', fontSize:'18px'}}
            
            onClick={()=>{
                setClayOnly(!clayOnly)


            }}
            >
                $CLAY LISTINGS ONLY

                <div style={{marginTop:'-5px', marginLeft:'10px', cursor:'pointer'}}>
                    <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png" style={{width:'40px', height:'auto', filter: clayOnly ? 'opacity(100%)' : 'opacity(30%)'}} />
                </div>
            
            </div>

            </div>


            <div style={{marginTop:'0px', marginBottom:'30px', display:'flex', 
            flexDirection:'column',justifyContent:'center', alignItems:'center',
            paddingTop:'10px', paddingBottom:'10px',
            backgroundColor: true ? 'rgba(1,1,1,0.08)' : 'rgba(53, 73, 91, 0.5)', borderRadius:'20px',
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'
            }}

            >



            <div style={{backgroundColor:'', width:'80%', fontFamily:'ShortStack', color:'bisque', fontSize:'18px'}}>
              
            <div style={{marginTop:'10px', marginBottom:'10px'}}>  
              <div style={{fontSize:'20px'}}>
                PRICE RANGE
              </div>

              <div style={{fontSize:'14px'}}>
                (ADA EQUIVALENT)
              </div>
            </div>


                <Slider
                    style={{color:'#00988f'}}
                    getAriaLabel={() => 'Price range'}
                    value={priceValue}
                    onChange={priceRangeChange}
                  />



                  <div style={{display:'flex', justifyContent:'space-between', maxWidth:'200px'}}>

                      <div style={{width:'50%', overflowWrap:'break-word'}}>
                        <label>MIN:</label>


                     <div className={searchClassName} style={{marginTop:'0px', marginBottom:'20px', marginLeft: '0px', width:'95px'}}>
                      <SearchInput>
                      <input  type="text" value={minPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                        style={{width:'90px', height:'35px', borderRadius:'10px', textAlign:'start', fontSize:'16px'
                        }}



                        onChange={(event)=>{
                            //console.log(event.target.value)

                            let cur_value = event.target.value
                            cur_value = cur_value.replaceAll(",", "")

                            const changed_value = parseInt(cur_value, 10)

                            //console.log(changed_value)
                            //console.log(typeof(changed_value))

                            if (isNaN(changed_value)){
                                setMinPrice('')
                                return
                            }


                            if (changed_value >= 0){

                                setMinPrice(String(changed_value))

                                const unscaledValue = unscaleValue(changed_value)

                                if (unscaledValue < priceValue[1]){
                                    setPriceValue([unscaledValue, priceValue[1]])
                                    setPriceLock(true)
                                }

                            }

                        }}


                        />


                          </SearchInput>

                          </div>






                      </div>

                      <div style={{width:'50%', overflowWrap:'break-word'}}>
                        <label>MAX:</label>
                    
                    <div className={searchClassName} style={{marginTop:'0px', marginBottom:'20px', marginLeft: '0px', width:'95px'}}>
                      <SearchInput>
                      <input  type="text"value={maxPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 
                        style={{width:'90px', height:'35px', borderRadius:'10px', textAlign:'left', fontSize:'16px'}}
                        
                        onChange={(event)=>{

                            //console.log(event.target.value)

                            let cur_value = event.target.value
                            cur_value = cur_value.replaceAll(",", "")

                            const changed_value = parseInt(cur_value, 10)



                             if (isNaN(changed_value)){
                                setMaxPrice('')
                                return
                            }

                            if (changed_value >= 0){


                                if (changed_value > absMax){
                                    setPriceValue([priceValue[0], absMax])
                                    setPriceLock(true)

                                    setMaxPrice(absMax)
                                    return 
                                }

                                setMaxPrice(String(changed_value))

                                //console.log(priceValue)

                                const unscaledValue = unscaleValue(changed_value)

                                if (unscaledValue > priceValue[0]){
                                    setPriceValue([priceValue[0], unscaledValue])
                                    setPriceLock(true)

                                }

                            }
                            
                        }}

                        />

                        </SearchInput>
                    </div>

                      </div>


                  



                  </div>

                  <div style={{marginTop:'5px', marginBottom:'10px', width:'100%', display:'flex', justifyContent:'center'}}>
                      <div className="classicBtn" style={{backgroundColor:'#55a200', width:'100px', height:'50px', 
                      display:'flex', alignItems:'center', justifyContent:'center',
                      borderRadius:'20px', filter: canApply ? 'opacity(100%)' : 'opacity(50%)', cursor: canApply ? 'pointer' : 'default'
                        }}

                        onClick={()=>{
                            setCanApply(false)

                            let temp_otherFilters = {...otherFilters}

                            temp_otherFilters['max_price'] = parseInt(maxPrice,10)*1000000
                            temp_otherFilters['min_price'] = parseInt(minPrice,10)*1000000 

                            setOtherFilters(temp_otherFilters)

                        }}
                        >
                            APPLY
                      </div>
                  </div>
                </div>
            </div>


      
       {Object.keys(categories).map((category)=>{
                    return(
                   

                    <Grid item xs={9} key={category} style={{marginBottom:'10px'}}>

                         <button className="filter-btn" style={{animationDelay:String((String(Object.keys(categories).indexOf(category))*0.15))+ "s"}}  onClick={() => {
                            let temp;
                            if (!openCategories.includes(category)){
                                temp = [...openCategories, category]
                                setOpenCategories(temp)
                            }else{
                                let indexToRemove = openCategories.indexOf(category)
                                temp = openCategories.splice(indexToRemove, 1)
                                setSelectedCategory(temp)
                            }
                        }

                        }>
                         <Stack direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}>
                             <span className="filter-txt" style={{fontFamily:'shortstack'}}>{category}</span>
                             <span style={{marginRight:'10px'}}><img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/arrowdown.svg' style={{ transform: openCategories.includes(category) ?  'rotate(0deg)' : 'rotate(90deg)'}}/></span>
                         </Stack>

                         </button>

                         <div style={{width:'245px', backgroundColor:'', display:'flex', justifyContent:'end'}}>

                             <Attributes collection = {collection} 
                                   key={"drop"+category} 
                                   setSearchBarMode={setSearchBarMode} 
                                   setOpenCategoryTabs={setOpenCategoryTabs} 
                                   openCategoryTabs={openCategoryTabs} 
                                   openCategories={openCategories} 
                                   category={category} 
                                   checkedAttributes={checkedAttributes} 
                                   setCheckedAttributes={setCheckedAttributes} 
                             />

                         </div>

                    </Grid>
                 
                    )
                })

                }


        <div style={{marginBottom:'20px', marginTop:'20px'}}>
              <button className="clear-btn" onClick={()=>{
                resetFilters()
              }}><span className="clear-txt" style={{fontFamily:'shortstack'}}>Reset all</span></button>
        </div>




    </div>
  );
}

export default PitchesFilters;