
import {useState, useEffect, useContext, useReducer} from 'react'

import './Menu.css'

import { MainExperienceContext } from 'components/Context/ExperienceContext'
import { MainPlayerContext } from 'components/Context/PlayerContext'


import {MainQualityContext} from 'components/Context/QualityContext'


function QualityMenu(props){

  const {setMenuOpen, setMenuName} = props

  const {quality, setQuality} = useContext(MainQualityContext)


  const [, forceUpdate] = useReducer(x => x + 1, 0);

  function handleClick() {
    forceUpdate();
  }


  return(
    <>
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>


              <button className={quality === 'High' ? 'optionA_selected' : 'optionA'}
                onClick={() => {
                  console.log(quality)
                  //setActiveScene('SelectAvatar')
                  setQuality('High')
                  setMenuOpen(false)
                  setMenuName('Menu')
                  forceUpdate()
                }}
              > <span id="buttontitle">High</span>
              </button>
              

               <button className={quality === 'Medium' ? 'optionA_selected' : 'optionA'}
                onClick={() => {
                   console.log(quality)
                  //setActiveScene('SelectAvatar')
                  setQuality('Medium')
                  setMenuOpen(false)
                  setMenuName('Menu')
                  forceUpdate()
                }}
              > <span id="buttontitle">Medium</span>
              </button>


               <button className={quality === 'Low' ? 'optionA_selected' : 'optionA'}
                onClick={() => {
                   console.log(quality)
                  //setActiveScene('SelectAvatar')
                  setQuality('Low')
                  setMenuOpen(false)
                  setMenuName('Menu')
                  forceUpdate()
                }}
              > <span id="buttontitle">Low</span>
              </button>

            </div>
    </>
  )
}



function Menu(props){

  const {inputBlocked, setInputBlocked} = useContext(MainPlayerContext)
  const {activeScene, setActiveScene} = useContext(MainExperienceContext)

  const [escapePressed, setEscapePressed] = useState(false)

  const [menuOpen, setMenuOpen] = useState(false)


  const [menuName, setMenuName] = useState('Menu')


  useEffect(() => {
  
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);

      }
      //

  }, []);



  function handleKeyDown(event){

      let pressedKey = event.key
      pressedKey = pressedKey.toLowerCase()

      //console.log(pressedKey)
      
      if (pressedKey=='escape'){
        setEscapePressed(true)
      }
  }


  function handleKeyUp(event){ 
      let pressedKey = event.key
      pressedKey = pressedKey.toLowerCase()
      
      if (pressedKey=='escape'){
        setEscapePressed(false)
      }  
  }


  useEffect(() => {

    if (escapePressed){
      setMenuOpen(!menuOpen)
      //console.log('MENU OPEN: ' + String(menuOpen))
    }
    
  },[escapePressed])


  useEffect(() => {

    setInputBlocked(menuOpen)

  },[menuOpen])


  return(
    <>

      {menuOpen ?
        
        <div style={{position:'absolute', top:'0px',width:'100%', height:'100%', 
                     backgroundColor:'', zIndex:'40000', display:'flex', 
                     justifyContent:'center', alignItems:'center',
                     backdropFilter:'blur(10px)', 
                   }}>

          <div className='menuContainer' id="menucontain">

            <div className='menuTitle'><span>MENU</span>



            <div style={{width:'100%', height:'250px', display:'flex',
                        justifyContent:'center', alignItems:'center',
                        backgroundColor:'', flexDirection:'column'
                        }}>
             


            {menuName === 'Menu' ?
              <>

              <button className='optionA'
                onClick={() => {
                  //setActiveScene('SelectAvatar')
                  setMenuName('Quality')
                }}
              > <span id="buttontitle">Quality</span>
              </button>



              <button className='optionA'
                onClick={() => {
                  setActiveScene('SelectAvatar')
                }}
              > <span id="buttontitle">Quit</span>
              </button>

              </>

          : menuName == 'Quality' ?

            <QualityMenu setMenuOpen={setMenuOpen} setMenuName={setMenuName} />

          : null}

            
            </div>

              <button  className='close' onClick={() => {
                setMenuName('Menu')
                setMenuOpen(false)}} ><span>X</span> </button>

            </div>

          </div>

        </div>

      : null}

    </>
  )
}
export default Menu;