import {useReducer, useState, createContext, useEffect, useRef} from 'react'


const initialState = {
    activeWallet: '',
    address: '',
    stake_address: '',
    lovelace: null,
    tokens: [],
    selectedTokens: [],
    isVoteOpen: false,
    voteOption: null,
    voteProposal: null,
}


const actions = {
    SET_ACTIVE_WALLET: "SET_ACTIVE_WALLET",
    SET_ADDRESS: "SET_ADDRESS",
    SET_STAKE_ADDRESS: "SET_STAKE_ADDRESS",
    SET_LOVELACE: "SET_LOVELACE",
    SET_TOKENS: "SET_TOKENS",
    SET_SELECTED_TOKENS: "SET_SELECTED_TOKENS",
    SET_IS_VOTE_OPEN: "SET_IS_VOTE_OPEN",
    SET_VOTE_OPTION: "SET_VOTE_OPTION",
    SET_VOTE_PROPOSAL: "SET_VOTE_PROPOSAL",
    RESET: "RESET"
}

function reducer(state, action){
    switch (action.type){
        case actions.SET_ACTIVE_WALLET:
            return {...state, activeWallet: action.value};        
        case actions.SET_ADDRESS:
            return {...state, address: action.value};
        case actions.SET_STAKE_ADDRESS:
            return {...state, stake_address: action.value};
        case actions.SET_LOVELACE:
            return {...state, lovelace: action.value};
        case actions.SET_TOKENS:
            return {...state, tokens: action.value};
        case actions.SET_SELECTED_TOKENS:
            return {...state, selectedTokens: action.value};
        case actions.SET_IS_VOTE_OPEN:
            return {...state, isVoteOpen: action.value};
        case actions.SET_VOTE_OPTION:
            return {...state, voteOption: action.value};
        case actions.SET_VOTE_PROPOSAL:
            return {...state, voteProposal: action.value};
        case actions.RESET:
            return {...state, ...initialState}
        default:
            return state;
    }
}

export const MainClaimContext = createContext()

export function ClaimContext({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        activeWallet: state.activeWallet,
        address: state.address,
        stake_address: state.stake_address,
        lovelace: state.lovelace,
        tokens: state.tokens,
        selectedTokens: state.selectedTokens,
        isVoteOpen: state.isVoteOpen,
        voteOption: state.voteOption,
        voteProposal: state.voteProposal,
        
        setActiveWallet: value => {
            dispatch({type: actions.SET_ACTIVE_WALLET, value})
        },
        setAddress: value => {
            dispatch({type: actions.SET_ADDRESS, value})
        },
        setStakeAddress: value => {
            dispatch({type: actions.SET_STAKE_ADDRESS, value})
        },
        setLovelace: value => {
            dispatch({type: actions.SET_LOVELACE, value})
        },
        setTokens: value => {
            dispatch({type: actions.SET_TOKENS, value})
        },
        setSelectedTokens: value => {
            dispatch({type: actions.SET_SELECTED_TOKENS, value})
        },
        setIsVoteOpen: value => {
            dispatch({type: actions.SET_IS_VOTE_OPEN, value})
        },
        setVoteOption: value => {
            dispatch({type: actions.SET_VOTE_OPTION, value})
        },
        setVoteProposal: value => {
            dispatch({type: actions.SET_VOTE_PROPOSAL, value})
        },
        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainClaimContext.Provider value={value}>
            {children}
        </MainClaimContext.Provider>
        )
}

export default ClaimContext;