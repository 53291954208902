import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = { 
    currentUnitQuadrant: '',
    activeUnitQuadrants: [],

    activeScenes: {},

    closestScene: {},
}


const actions = {

    SET_CURRENT_UNIT_QUADRANT: "SET_CURRENT_UNIT_QUADRANTS",
    SET_ACTIVE_UNIT_QUADRANTS: "SET_ACTIVE_UNIT_QUADRANTS",

    SET_ACTIVE_SCENES: "SET_ACTIVE_SCENES",

    SET_CLOSEST_SCENE: "SET_CLOSEST_SCENE",

}

function reducer(state, action){
    switch (action.type){

        case actions.SET_CURRENT_UNIT_QUADRANT:
            return {...state, currentUnitQuadrant: action.value};

        case actions.SET_ACTIVE_UNIT_QUADRANTS:
            return {...state, activeUnitQuadrants: action.value};

        case actions.SET_ACTIVE_SCENES:
            return {...state, activeScenes: action.value};

        case actions.SET_CLOSEST_SCENE:
            return {...state, closestScene: action.value};

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainWorldContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        currentUnitQuadrant: state.currentUnitQuadrant,
        activeUnitQuadrants: state.activeUnitQuadrants,

        activeScenes: state.activeScenes,

        closestScene: state.closestScene,
        
        setCurrentUnitQuadrant: value => {
            dispatch({type: actions.SET_CURRENT_UNIT_QUADRANT, value})
        },


        setActiveUnitQuadrants: value => {
            dispatch({type: actions.SET_ACTIVE_UNIT_QUADRANTS, value})
        },

        setActiveScenes: value => {
            dispatch({type: actions.SET_ACTIVE_SCENES, value})
        },

        setClosestScene: value => {
            dispatch({type: actions.SET_CLOSEST_SCENE, value})
        },

        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainWorldContext.Provider value={value}>
            {children}
        </MainWorldContext.Provider>
        )
}


function WorldContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default WorldContext;


