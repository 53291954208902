import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import * as THREE from "three";

import {MainExperienceContext} from 'components/Context/ExperienceContext'


function AudioListener(){

    const {location, setLocation, prevLocation, setPrevLocation, audioListener, setAudioListener} = useContext(MainExperienceContext)

    const {camera} = useThree()


    const outsideAmbientSound = "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TheStand_Sounds/Exterior/01_Outside Ambience (Less bustle).wav"
    const insideAmbientSound = "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TheStand_Sounds/Interior/10_The Stand Soundtrack.wav"
    const clayPayAmbientSound = "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TheStand_Sounds/ClayPay/07_Clay Pay Soundtrack.wav"
    const enteringSpace = "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TheStand_Sounds/Interior/01_Entering the space.wav"


    const [locationSounds, setLocationSounds] = useState({

      "Outside": {path:outsideAmbientSound, sound: null, volume:0.8},
      "Inside": {path:insideAmbientSound, sound: null, volume:0.2},
      "ClayPay": {path: clayPayAmbientSound, sound: null, volume:1}

    })

    const [ambientAudio, setAmbientAudio] = useState()


    // useEffect(() => {

    //   console.log('AUDIO LISTENER')
    //   console.log(audioListener)

    // }, [audioListener])


    useEffect(() => {

      const audioListener = new THREE.AudioListener();

      camera.add( audioListener );

      setAudioListener(audioListener)

      if (location !== '' && location in locationSounds){

        const audioLoader = new THREE.AudioLoader();

        let locationSound = locationSounds[location]

        // Load two audio files
        audioLoader.load( locationSound.path, function( buffer ) {
          const audio1 = new THREE.Audio( audioListener );
          audio1.setBuffer( buffer );
          audio1.setLoop( true );

          if ('volume' in locationSound){
            audio1.setVolume( locationSound['volume'] );
          }else{
            audio1.setVolume( 0.5 );
          }
          
          audio1.play();

          let temp_locationSounds = locationSounds
          temp_locationSounds[location].sound = audio1
          setLocationSounds(temp_locationSounds)
          setAmbientAudio(audio1)

        });

      }


    },[])



    // useEffect(() => {

    //   if (location === 'Inside' && prevLocation === 'Outside'){
    //     console.log('CHIME IN')

    //     try{
    //       const audioLoader = new THREE.AudioLoader();

    //       // Load two audio files
    //       audioLoader.load( enteringSpace, function( buffer ) {
    //         const audio1 = new THREE.Audio( audioListener );
    //         audio1.setBuffer( buffer );
    //         audio1.setLoop( false );
    //         audio1.setVolume( 0.5 );
    //         audio1.play();

    //     })
    //   }catch{}
     
    // }

    // }, [location, prevLocation])




    // useEffect(() => {

    //   console.log('LOCATION')
    //   console.log(location)


    //   console.log('location')
    //   if (location !== '' && location in locationSounds){
    //     console.log('STEP 1')
    //     try{


    //       console.log('STEP 2')
    //       ambientAudio.setVolume( 0 );
    //       console.log('STEP 3')

    //       const audioLoader = new THREE.AudioLoader();


    //       console.log(locationSounds)

          
         
    //       if (locationSounds[location].sound != null){
    //         console.log('STEP 4a')
    //         let locationSound = locationSounds[location]
    //         const audio1 = locationSound.sound 
    //         if ('volume' in locationSound){
    //           audio1.setVolume( locationSound['volume'] );
    //         }else{
    //           audio1.setVolume( 0.5 );
    //         }
    //         setAmbientAudio(audio1)
    //         audio1.play()
    //       }else{

    //         console.log('STEP 4b')

    //         let locationSound = locationSounds[location]
    //           // Load two audio files
    //           audioLoader.load( locationSound.path, function( buffer ) {
    //             const audio1 = new THREE.Audio( audioListener );
    //             audio1.setBuffer( buffer );
    //             audio1.setLoop( true );
    //             if ('volume' in locationSound){
    //               audio1.setVolume( locationSound['volume'] );
    //             }else{
    //               audio1.setVolume( 0.5 );
    //             }
    //             audio1.play();

    //             let temp_locationSounds = locationSounds
    //             temp_locationSounds[location].sound = audio1
    //             setLocationSounds(temp_locationSounds)
    //             setAmbientAudio(audio1)
    //           });

    //       }

          

    //   }catch{
      
    //   }
    // }


    // },[location])



    return(

        null

      )

}
export default AudioListener;