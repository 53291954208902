import { useState, useEffect, useRef, useContext } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'





function DustCloud(props){

	const {position} = props


	const [dustCloud, setDustCloud] = useState()


	useEffect(() => {



	},[])


	return(

		<>


			<mesh position={position}>

				<sphereGeometry args={[0.25,16,16]} />
				<meshStandardMaterial color={'blue'} />

			</mesh>

		</>

	)
}


function WalkCloud(props){

	const {isWalking, position, prevPosition, newPosition} = props

	const [walkCloud, setWalkCloud] = useState()

	const [needsUpdate, setNeedsUpdate] = useState(false)


	function createCloud(){
		const particleCount = 3;
		const particleSize = 0.1;

		// Create material for particles
		const particleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });


		let prevPositionVec = new THREE.Vector3(prevPosition[0], prevPosition[1], prevPosition[2])
		let newPositionVec = new THREE.Vector3(newPosition[0], newPosition[1], newPosition[2])

		let directionVec = newPositionVec.clone().sub(prevPositionVec.clone())

		// console.log(directionVec)


		const particles = new THREE.Group();

		for (let i = 0; i < particleCount; i++) {
			const cur_size = particleSize * Math.random()/2
			const particleGeometry = new THREE.SphereGeometry(cur_size, 16, 16);
			const particle = new THREE.Mesh(particleGeometry, particleMaterial);

			particle.position.set(
				prevPosition[0] + (Math.random() - 0.5)/2,
			  	prevPosition[1] + Math.random()/10,
			  	prevPosition[2] + (Math.random() - 0.5)/2
			  );
			  particles.add(particle);
		}


		// for (let i = 0; i < particleCount; i++) {
		// 	const cur_size = particleSize * Math.random()
		// 	const particleGeometry = new THREE.SphereGeometry(cur_size, 16, 16);
		// 	const particle = new THREE.Mesh(particleGeometry, particleMaterial);

		// 	particle.position.set(
		// 		position[0] + Math.random() - 0.5,
		// 	  	position[1] + Math.random()/10,
		// 	  	position[2] + Math.random() - 0.5
		// 	  );
		// 	  particles.add(particle);
		// }

		setWalkCloud(particles)

	}


	useEffect(() => {

		if (isWalking){
		   createCloud()
		   setNeedsUpdate(false)

		   const timer2 = setTimeout(() => {
		   		setWalkCloud()
		   }, 350)

		}

		const timer = setTimeout(() => {
			setNeedsUpdate(true)
			setWalkCloud()
		}, 450);
		return () => clearTimeout(timer);


		// const timer2 = setTimeout(() => {
		// 	setNeedsUpdate(true)
		// 	setWalkCloud()
		// }, 450);
		// return () => clearTimeout(timer2);




		
	},[isWalking, needsUpdate])


	return(
		<>

			{walkCloud ?

				<primitive
					object={walkCloud}
				/>

				: null
			}

		</>
	)
}



function RunCloud(props){

	const {isRunning, position, prevPosition, newPosition} = props

	const [runCloud, setRunCloud] = useState()

	const [needsUpdate, setNeedsUpdate] = useState(false)



	function createCloud(){
		const particleCount = 3;
		const particleSize = 0.12;

		// Create geometry for particles
			

		// Create material for particles
		const particleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

		const particles = new THREE.Group();
		for (let i = 0; i < particleCount; i++) {
			const cur_size = particleSize * Math.random()/2
			const particleGeometry = new THREE.SphereGeometry(cur_size, 16, 16);
			const particle = new THREE.Mesh(particleGeometry, particleMaterial);
			particle.position.set(
				prevPosition[0] + Math.random() - 0.5,
			  	prevPosition[1] + Math.random()/10,
			  	prevPosition[2] + Math.random() - 0.5
			  );
			  particles.add(particle);
		}


		setRunCloud(particles)

	}


	useEffect(() => {
		if (isRunning){
			createCloud()
			setNeedsUpdate(false)
		}

		const timer = setTimeout(() => {
			setNeedsUpdate(true)
			setRunCloud()
		}, 250);
		return () => clearTimeout(timer);
		
	},[isRunning, needsUpdate])


	return(
		<>

			{runCloud ?

				<primitive
					object={runCloud}
				/>

				: null
			}

		</>
	)
}



function JumpCloud(props){

	const {justJumped, setJustJumped, position, prevPosition, newPosition} = props

	const [jumpCloud, setJumpCloud] = useState()

	function createCloud(){

		const particleCount = 5;
		const particleSize = 0.15;

		// Create geometry for particles
		

		// Create material for particles
		const particleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

		const particles = new THREE.Group();
		for (let i = 0; i < particleCount; i++) {
		  const cur_size = particleSize * Math.random()
		  const particleGeometry = new THREE.SphereGeometry(cur_size, 16, 16);
		  const particle = new THREE.Mesh(particleGeometry, particleMaterial);
		  particle.position.set(
		  	prevPosition[0] + (Math.random() - 0.5)/2,
		  	prevPosition[1] + Math.random()/2,
		  	prevPosition[2] + (Math.random() - 0.5)/2

		    // Math.random() * 20 - 10,
		    // Math.random() * 20 - 10,
		    // Math.random() * 20 - 10
		  );
		  particles.add(particle);
		}


		setJumpCloud(particles)
	}


	useEffect(() => {
		
		if (justJumped){
			createCloud()
		}
		

		const timer = setTimeout(() => {
		    setJustJumped(false)
		    setJumpCloud()
		}, 250);
		return () => clearTimeout(timer);

	},[justJumped])



	return(
		<>

			{
				justJumped && jumpCloud ?

				<primitive
					object={jumpCloud}
				/>

				: null
			}

		</>
	)
}



function LandedCloud(props){
	const {justLanded, setJustLanded, position} = props

	const [landedCloud, setLandedCloud] = useState()


	function createCloud(){
		// Set up landing effect parameters
		// const maxSpheres = 50; // Maximum number of spheres in the effect
		// const sphereSizeRange = [1, 2]; // Range of sizes for the spheres
		// const sphereLifetime = 2; // Duration of each sphere's lifetime in seconds

		// // Create geometry for a single sphere
		// const sphereGeometry = new THREE.SphereGeometry(1, 16, 16);

		// // Create geometry for all the spheres
		// const spheresGeometry = new THREE.InstancedBufferGeometry();
		// spheresGeometry.index = sphereGeometry.index;
		// spheresGeometry.attributes.position = sphereGeometry.attributes.position;


		// // Define the sphere positions as an InstancedBufferAttribute
		// const spherePositions = new Float32Array(maxSpheres * 3);
		// for (let i = 0; i < maxSpheres; i++) {
		//   spherePositions[i * 3] = 0;
		//   spherePositions[i * 3 + 1] = 0;
		//   spherePositions[i * 3 + 2] = 0;
		// }
		// spheresGeometry.setAttribute('position', new THREE.InstancedBufferAttribute(spherePositions, 3));


		// // Define the sphere sizes as an InstancedBufferAttribute
		// const sphereSizes = new Float32Array(maxSpheres);
		// for (let i = 0; i < maxSpheres; i++) {
		//   sphereSizes[i] = THREE.MathUtils.randFloat(sphereSizeRange[0], sphereSizeRange[1]);
		// }
		// spheresGeometry.setAttribute('size', new THREE.InstancedBufferAttribute(sphereSizes, 1));



		// // Create material for the spheres
		// const sphereMaterial = new THREE.ShaderMaterial({
		//   uniforms: {
		//     time: { value: 0 },
		//     lifetime: { value: sphereLifetime }
		//   },
		//   vertexShader: `
		//     attribute vec3 position;
		//     attribute float size;
		//     uniform float time;
		//     uniform float lifetime;
		//     varying float alpha;
		//     void main() {
		//       vec3 spherePosition = position;
		//       float progress = min(time / lifetime, 1.0);
		//       float scale = mix(size, 0.0, progress);
		//       alpha = 1.0 - progress;
		//       gl_Position = projectionMatrix * modelViewMatrix * vec4(spherePosition, 1.0);
		//       gl_PointSize = scale * (1.0 / -gl_Position.z);
		//     }
		//   `,
		//   fragmentShader: `
		//     varying float alpha;
		//     void main() {
		//       gl_FragColor = vec4(1.0, 1.0, 1.0, alpha);
		//     }
		//   `,
		//   transparent: true
		// });

		// // Create spheres and add them to the scene
		// const spheres = new THREE.Points(spheresGeometry, sphereMaterial);


		// const landingPosition = new THREE.Vector3(position[0], position[1], position[2]); // Replace "player.position" with the actual position of the player

		//   // Generate random positions for the spheres within a radius
		//   const radius = 2;
		//   for (let i = 0; i < maxSpheres; i++) {
		//     const cur_position = new THREE.Vector3().copy(landingPosition);
		//     cur_position.x += THREE.MathUtils.randFloat(-radius, radius);
		//     cur_position.y += THREE.MathUtils.randFloat(-radius, radius);
		//     cur_position.z += THREE.MathUtils.randFloat(-radius, radius);
		//     spherePositions[i * 3] = cur_position.x;
		//     spherePositions[i * 3 + 1] = cur_position.y;
		//     spherePositions[i * 3 + 2] = cur_position.z;
		//   }
		//   spheresGeometry.attributes.position.needsUpdate = true;

		//   // Reset the lifetime of the spheres
		//   sphereMaterial.uniforms.time.value = 0;



		//setLandedCloud({object: spheres, position: position})

		// const sphere = new THREE.SphereGeometry(0.1,16,16)
		// const material = new THREE.MeshStandardMaterial()

		// material.color = new THREE.Color('skyblue')

		// const sphereMesh = new THREE.Mesh(sphere, material)

		// setLandedCloud({object: sphereMesh, position: position})

		const particleCount = 8;
		const particleSize = 0.15;

		// Create geometry for particles
		

		// Create material for particles
		const particleMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

		const particles = new THREE.Group();
		for (let i = 0; i < particleCount; i++) {
		  const cur_size = particleSize * Math.random()
		  const particleGeometry = new THREE.SphereGeometry(cur_size, 16, 16);
		  const particle = new THREE.Mesh(particleGeometry, particleMaterial);
		  particle.position.set(
		  	position[0] + Math.random() - 0.5,
		  	position[1] + Math.random()/20 - 0.05,
		  	position[2] + Math.random() - 0.5

		    // Math.random() * 20 - 10,
		    // Math.random() * 20 - 10,
		    // Math.random() * 20 - 10
		  );
		  particles.add(particle);
		}


		setLandedCloud({object: particles})



	}


	useEffect(() => {
		//setLandedCloud({position:position})

		if (justLanded){
			createCloud()
		}
		


		const timer = setTimeout(() => {
		    setJustLanded(false)
		    setLandedCloud()
		}, 250);
		return () => clearTimeout(timer);

	},[justLanded])




	return(
		<>

			{
				justLanded && landedCloud ? 

				<>
				<primitive
					object={landedCloud.object}
					//position={landedCloud.position}

				/>
				</>

				: null
			}

		</>
	)
}





function MovementParticles(props){

	const {characterPosition, currentAction} = props

	const [dustClouds, setDustClouds] = useState([])

	const [isFalling, setIsFalling] = useState(false)

	const [prevAction, setPrevAction] = useState('')

	const [justLanded, setJustLanded] = useState(false)

	const [justJumped, setJustJumped] = useState(false)

	const [isWalking, setIsWalking] = useState(false)

	const [isRunning, setIsRunning] = useState(false)

	const [prevPosition, setPrevPosition] = useState(characterPosition)

	const [newPosition, setNewPosition] = useState(characterPosition)


	useEffect(() => {

		// console.log(prevPosition)
		// console.log(newPosition)
		//console.log(characterPosition)

		setPrevPosition(newPosition)
		setNewPosition(characterPosition)

	},[characterPosition])


	useEffect(() => {

		if (currentAction === 'Walk' && isFalling === false){
			setIsWalking(true)
		}else{
			setIsWalking(false)
		}

	},[isFalling, currentAction])


	useEffect(() => {

		if (currentAction === 'Run' && isFalling === false){
			setIsRunning(true)
		}else{
			setIsRunning(false)
		}

	},[isFalling, currentAction])



	useEffect(() => {

		// console.log(currentAction)

		if (currentAction === 'Fall' && isFalling !== true){
			setIsFalling(true)
			setJustJumped(true)
		}

		if (isFalling && currentAction !== 'Fall'){
			setIsFalling(false)
			// console.log('YAY, Landed')
			setJustLanded(true)
		}

	},[currentAction])



	useEffect(() => {


		//setDustClouds([{position:characterPosition}])

		let temp_dustClouds = dustClouds

		temp_dustClouds.unshift({position: characterPosition, color: "tomato"})

		if (temp_dustClouds.length > 10) temp_dustClouds.pop()


		setDustClouds(temp_dustClouds)



	},[currentAction])


	return(

		<>

			{/*{dustClouds ?

				dustClouds.map((dustCloud) => {
					return(

						<DustCloud position={dustCloud.position} />
					)
				})

			: null}*/}


			{/*<JumpCloud justJumped={justJumped} setJustJumped={setJustJumped} position={characterPosition} prevPosition={prevPosition} newPosition={newPosition} />
			<LandedCloud justLanded={justLanded} setJustLanded={setJustLanded} position={characterPosition} />
*/}



			{/*<WalkCloud isWalking={isWalking} position={characterPosition} prevPosition={prevPosition} newPosition={newPosition} />*/}

			{/*<RunCloud isRunning={isRunning} position={characterPosition} prevPosition={prevPosition} newPosition={newPosition} />*/}

		</>

	)
}

export default MovementParticles;


