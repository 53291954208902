import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import argy from "../Images/argy.png";
import link from '../Images/link_symbol.png';
import padlock from '../Images/padlock.png';
import NamiIcon from '../Images/nami.svg';
import EternlIcon from '../Images/eternl.svg';
import YoroiIcon from '../Images/yoroi.svg';
import GeroIcon from '../Images/gero.svg';
import FlintIcon from '../Images/flint.svg';
import LaceIcon from '../Images/lace.svg';
import { motion } from "framer-motion";
import { NextButton, Heading, HeadingSlideTwo, HeadingSlideThree, HeadingSlideFour, Text, WalletButton, WalletContainer, SlidesWrapper } from './Slides.styled';

const Steppers = ({ updates }) => {

    let navigate = useNavigate(); 

    const timerRef = useRef(null);

    const[currentIndex, setCurrentIndex] = useState(0);

    const updatesStyles = {
        backgroundImage: `url(${updates[currentIndex].url})`,
    };


    const previous = () => {
        const firstupdate = currentIndex === 0; 
        const newIndex = firstupdate ? updates.length - 1 : currentIndex -1;
        setCurrentIndex(newIndex);
    };


    const next = useCallback(() => {
        const lastupdate = currentIndex === updates.length -1;
        const newIndex = lastupdate ? 0 : currentIndex + 1;

        if (lastupdate){
            navigate('/sandbox-unlock');
        }else{
            setCurrentIndex(newIndex); 
        }

        
    },  [currentIndex, updates]  );


    useEffect(() => {
    if (timerRef.current) {
        clearTimeout(timerRef.current)  
    }

return () => clearTimeout(timerRef.current);}, [next]);




const displayButton = () =>  {
    if (currentIndex === 0) {
      return (
        <div>
        <Heading>WELCOME TO<br></br>THE SANDBOX x CLAY NATION  <br></br> COMPATIBILITY PORTAL</Heading>     
          <motion.img 
            src={argy} 
            alt="argy" 
            className="argy"
            initial={{ y:-500, opacity:0 }}
            animate={{ opacity:1, y:0, type: "spring" }}
            transition={{ duration: 1.2, delay: 1.4 }}
            ></motion.img>
        </div>
    )} 

    if (currentIndex === 1) {
        return (
            <div>
                <HeadingSlideTwo>INTRODUCING VOXEL-CLAYS, THE CARDANO NFT WITH UNLOCKABLE POLYGON UTILITY.</HeadingSlideTwo>
            <motion.img 
                src={padlock} 
                alt="padlock" 
                className="padlock"
                initial={{ opacity:0, scale: 0.5 }}
                animate={{ opacity:1, scale: 1 }}
                transition={{ duration: 1.5, delay: 0.7 }}
                ></motion.img>
            </div>
      )} 

      if (currentIndex === 2) {
        return (
            <div>
                <HeadingSlideThree>STEP 1:</HeadingSlideThree>
                <Text>Connect your Cardano wallet to the VOXEL Portal</Text>
                <HeadingSlideThree>STEP 2:</HeadingSlideThree>
                <Text>Choose the Clay Nation NFT(s) you wish to VOXELISE</Text>
                <HeadingSlideThree>STEP 3:</HeadingSlideThree>
                <Text>CONFIRM THE TRANSACTION</Text>
                <HeadingSlideThree>STEP 4:</HeadingSlideThree>
                <Text>FOLLOW THE INSTRUCTIONS TO VIEW AND USE YOUR VOX-CLAY IN THE SANDBOX</Text>
                <br></br>
                <br></br>
                <br></br>
            </div>
      )} 

      if (currentIndex === 3) {
        return (
            <div className="SlideFour">
             <HeadingSlideFour>SOME EXTRA IMPORTANT STUFF</HeadingSlideFour>
             <Text>• VOXEL UPGRADES ARE NON-REFUNDABLE AND NON-TRANSFERABLE BETWEEN NFTS. </Text>
             <Text>• THE COMPATIBILITY OF THE NFT WILL REMAIN WITH THE NFT, EVEN IF THE NFT IS SOLD OR LOANED.</Text>
            <Text>• THE VOX-CLAY AVATAR IS NOT ITS OWN NFT.</Text>
            <Text>• THE METADATA OF THE ORIGINAL CLAY NATION NFT WILL REMAIN THE SAME.</Text>
            <motion.img 
                src={link} 
                alt="link" 
                className="link-symbol"
                initial={{ opacity:0, scale: 0.5 }}
                animate={{ opacity:1, scale: 1 }}
                transition={{ duration: 1.5, delay: 0.7 }}
                ></motion.img>
                <br></br>
                <br></br>
                <br></br>
            </div>
      )} 

      if (currentIndex === 4) {
        return (
            <WalletContainer>
             <Heading>CONNECT WALLET</Heading>
             <div>
           <WalletButton><img src={NamiIcon} className="walletImg" />NAMI</WalletButton>
           <WalletButton><img src={EternlIcon} className="walletImg" />ETERNL</WalletButton>
           <WalletButton><img src={YoroiIcon} className="walletImg" />YOROI</WalletButton>
           <WalletButton><img src={GeroIcon} className="walletImg" />GERO</WalletButton>
           <WalletButton><img src={FlintIcon} className="walletImg" />FLINT</WalletButton>
           <WalletButton><img src={LaceIcon} className="walletImg" />LACE</WalletButton>
           </div>        
            </WalletContainer>
      )} 

    
    else {
      return (
      null
    )}
    
}



return (
<SlidesWrapper
initial={{ y:500, opacity:0 }}
animate={{ opacity:1, y:0, type: "spring" }}
transition={{ duration: 1.5, delay: 0.7 }} >
    
    

    <NextButton type="button" onClick={next}></NextButton>
    
    <div>
        {displayButton()} 
    </div>   

</SlidesWrapper>

)
};


export default Steppers;