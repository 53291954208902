import { useState, useContext, useEffect } from 'react'

import {Buffer} from 'buffer'

import {MainClaimContext} from './ClaimContext'


function Balance(props) {


const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)



const cn_info = {policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name: 'ClayNation'}
const gc_info = {policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', base_name: "ClayCharlotte"}

const clay_token_id = '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535434c4159'

const isCompact = props.isCompact
const currentSlot = props.currentSlot
const setCurrentSlot = props.setCurrentSlot

const assetsOwned = props.assetsOwned
const setAssetsOwned = props.setAssetsOwned

const isWalletLoading = props.isWalletLoading

const assetsLoaded = props.assetsLoaded

const setAssetsLoaded = props.setAssetsLoaded





async function fetch_assets_info(){

    if (stake_address){
        let assets_info = await fetch('https://clayapi.com/baking/wallet/' + stake_address + '/')
            .then(response => response.json())
            .then(data => data);

        //console.log(assets_info)

        let slot = await fetch('https://clayapi.com/baking/slot/').then(response => response.json())
                                                                  .then(data => data)
        slot = parseInt(slot.slot)
        setCurrentSlot(slot)

        return {assets_info: assets_info.assets, slot: slot}

    }else{
        return {assets_info:{}, slot:0}
    }
}


useEffect(() => {

    if (stake_address){
        //console.log('assetsLoaded - false')
        //console.log(assetsLoaded)
        setAssetsLoaded(false)
        assetsOwnedSearch()

        let interval = setInterval(() => {
            //console.log('REFRESHING...')
            //console.log(stake_address)
            assetsOwnedSearch()
        }, 30000)

        return () => clearInterval(interval)
    }
    
}, [stake_address])


useEffect(() => {
    if (assetsLoaded == false){
        //console.log('assetsLoaded - true')
        setAssetsLoaded(true)
    }

},[assetsOwned])

/*
useEffect(() => {

    //setAssetsOwned()

},[isWalletLoading])
*/


async function assetsOwnedSearch(){

      let fetched_info = await fetch_assets_info() 

      //console.log('\n\n\nFETCHED INFO\n\n\n')
      //console.log(stake_address)

      //console.log(fetched_info)

      let clayTokenAmount = 0

      let assets_info = fetched_info.assets_info
      let slot = parseInt(fetched_info.slot)

      const assets = tokens

      //FOR TESTING ONLY
      // assets.push({
      //   {'unit':'','quantity':'1'}
      // })


      const cn_owned = []
      const gc_owned = []

      const clay_owned = []

      let clays_info = {}

      for (let i = 0; i < assets.length; i++){
        let asset_id = assets[i]["unit"]
        let policy_id = asset_id.slice(0,56)

        if (policy_id == cn_info.policy_id){

          let hex_tokname = asset_id.slice(56,)

          let tokname = Buffer.from(hex_tokname,'hex').toString()

          let serial = tokname.split(cn_info.base_name)[1]

          cn_owned.push(serial)

          if (asset_id in assets_info){
            clays_info['cn' + String(serial)] = assets_info[asset_id]
            clay_owned.push('cn' + String(serial))
          }

        } else if (policy_id == gc_info.policy_id) {
          let hex_tokname = asset_id.slice(56,)

          let tokname = Buffer.from(hex_tokname,'hex').toString()

          let serial = tokname.split(gc_info.base_name)[1]

          gc_owned.push(serial)

          if (asset_id in assets_info){
            clays_info['gc' + String(serial)] = assets_info[asset_id]

            clay_owned.push('gc' + String(serial))
            }

        }else if(asset_id == clay_token_id){
            clayTokenAmount = parseInt(assets[i]["quantity"])
            //console.log('CLAY TOKEN AMOUNT')
            //console.log(clayTokenAmount)
        }else{
          // do nothing...
        }
        
      }

     
       let sorted_clays = clay_owned.sort(function(a, b) { return ( slot - parseInt(clays_info[b]['slot']) ) * parseFloat(clays_info[b]['rate']) - (slot - parseInt(clays_info[a]['slot']) ) * parseFloat(clays_info[a]['rate'])  })

       /*
       console.log(clays_info)
       console.log('SORTED CLAYS')
       console.log(sorted_clays)
       console.log({clays: sorted_clays, clays_info: clays_info, clayToken:clayTokenAmount})
        */

       setAssetsOwned({clays: sorted_clays, clays_info: clays_info, clayToken:clayTokenAmount})

}






  return (
     <div></div>
  );
}

export default Balance;