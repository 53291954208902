import { Button, ButtonSecondary } from '../GlobalStyles.jsx';
import { H1, H2, H3, P, MessageWrapper, ModalDefault}  from './SignMessage.styled.jsx'
import {  PromptLogIn } from './Modal.styled.jsx';
import { useState } from "react";
import check from '../Images/check.png';


import { useNavigate } from "react-router-dom";


function SignMessage({ handleDefault, mainAction, username, setUsername, isLoggedIn, setIsLoggedIn, accessToken, setAccessToken }) {

    const [messageContent, setMessageContent] = useState("signed");

    //const [username, setUsername] = useState("");

    const navigate = useNavigate();

    const handleSigning = () => {
        setMessageContent("signed");
    }

    const renderMessageContent = () => {
        if (messageContent === "default") {
            return (
                <MessageWrapper>
                    <P>9ZZ7ziS34fOq3tyEpijm2xeTU7XcYn10rGaAv3J2wSPJXXQHs7</P>
                    <Button theme="blue" type="button" onClick={handleSigning}>SIGN</Button>
                </MessageWrapper>
        );
            } else if (messageContent === "signed") {
                return (

                    <MessageWrapper>
                        <H3>Success! You’re all set!</H3>
                        <img src={check} alt="check" style={{paddingBottom:'1.5rem'}} />
                        <Button theme="blue" type="button" onClick={()=>{
                           
                           navigate('/dashboard', {'state':{'access_token':accessToken}});
                            
                        }}>DASHBOARD</Button>
                    </MessageWrapper>
                    
                );
            }    
        };

return (
    <ModalDefault>
        {messageContent !== "signed" ?
        <>
            <H1>Signature required</H1>
            <H2>This is just to make sure the wallet is really yours.</H2>
        </> 

        : isLoggedIn && username !== '' ?

        <>

            <H1>Welcome back, {username}!</H1>
            <H2></H2>

        </>

        :

         <>
            <H1>READY TO USE CLAY.DASH!</H1>
            <H2></H2>
            {/* <H2>Verification is complete</H2> */}
        </> 

        }
        {renderMessageContent()}  
        <PromptLogIn>
            <ButtonSecondary theme="pinkInactive" type="button" onClick={handleDefault} >CANCEL</ButtonSecondary>
        </PromptLogIn>
    </ModalDefault>

);

}

export default SignMessage;