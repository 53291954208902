import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {
    chatMessages: [],
}


const actions = {
    SET_CHAT_MESSAGES: "SET_CHAT_MESSAGES",
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_CHAT_MESSAGES:
            //console.log('SET REMOTE PLAYERS')
            return {...state, chatMessages: action.value}; 

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainChatContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        chatMessages: state.chatMessages,
        
        
        setChatMessages: value => {
            dispatch({type: actions.SET_CHAT_MESSAGES, value})
        },


        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainChatContext.Provider value={value}>
            {children}
        </MainChatContext.Provider>
        )
}


function ChatContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default ChatContext;


