import { MainPannel }  from "./MainGrid.styled";
import { motion } from "framer-motion";
import argie from "../Images/argie.gif";
import { GIF, H1, MainContainer } from "./NoAssets.style.jsx";



function NoAssets () {

    return (


        <MainContainer as={motion.div}
        initial={{ x:1500, opacity:0 }} 
        whileInView="visible"
        viewport={{ once: true }}
        animate={{ opacity:1, x:0, type: "spring" }}
        transition={{ duration: 1.2, delay: 1.5}}
        >
            <H1>THIS WALLET IS EMPTY</H1>

            <GIF src={argie} />
            <H1>NO ASSETS TO VOXELISE</H1>
        </MainContainer>


    );



}

export default NoAssets;

