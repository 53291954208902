import './App.css';
import Wallet from './components/Wallet'
import SideBar from './components/SideBar'
import Connect from './components/Connect'
import ClaimContext from './components/ClaimContext'


import MyRoutes from './Routes'

function App() {
  return (
      <MyRoutes />
  );
}

export default App;
