import React, { useState } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';


import styled from 'styled-components';



// import underworld from '/Users/cyber/ada-handles/src/Undeworld.png';
// import underwater from '/Users/cyber/ada-handles/src/Sonic_village.png';
// import sonic from '/Users/cyber/ada-handles/src/Sonic_underwater.png';
// import claynation from '/Users/cyber/ada-handles/src/Claynation_1.png';
// import treehouse from '/Users/cyber/ada-handles/src/Treehouse_3.png';
// import underworldillustration from '/Users/cyber/ada-handles/src/Underworld_1.png';
// import bakednation from '/Users/cyber/ada-handles/src/baked_nation_1.png';


const underworld = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'
const underwater = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'
const sonic = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'
const claynation = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'
const treehouse = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'
const underworldillustration = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'
const bakednation = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/handle_examples/QmQB24x3XyWVofvbd3qYXNB4icJTcBgPmtYXEcuEpMF1Fs.png'


const CarouselContainer = styled.div`

  height: 70%;
  width: 80%;
  text-align: center;
  z-index: 1; 
  max-width: 124rem;
  padding: 1rem 2rem;
  margin: 0 auto;
  display: block;
  /**background-color: var(--background);**/
  font-size: 30px;
  color: #a0fac88f;
  font-size: 40px;
  text-shadow: 2px 2px 0px black;
  z-index: 0;
`

const SlidesContainer = styled.div`
  height: 95%;
  width: 100%;
  position: relative;
  /**background-color: var(--background);**/
  border-radius: 20px;
  position: fixed;
  top:0px;
  color: rgba(255, 255, 255, 0.661);
  z-index: 0;
`

const SlideContainer = styled.div`
  width: 25%;
  height: 65%;
  border-radius: 45px;
  border: 2px solid rgba(0, 0, 0, 0.30);
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  position: fixed;
  top: 25%;
  left: 37.5%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

export default function Carousel () {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
   };
    const handleMouseLeave = () => {
      setIsHover(false);
   };

   const swiperStyle = {
    height: '95%',
    width: '100%',
    position: 'relative',
    /**background-color: var(--background);**/
    borderRadius: '20px',
    position: 'fixed',
    top: '0px',
    color: 'rgba(255, 255, 255, 0.661)',
    zIndex: '0'
   }

    const mintButtonStyle = {
        backgroundColor: isHover ? 'var(--background-hover)' : 'var(--background)',
        maxWidth: '120px',
        maxHeight: '60px',
        width: '100%', 
        height: '100%',
        borderRadius: '23px',
        outline: '2px solid var(--claymint-primary)',    
        border: 'none',
        boxShadow: isHover ? '0px 0px 15px var(--claymint-primary)' : '0px 0px 10px var(--claymint-primary)',
        fontFamily: 'ButtonFont',
        fontSize: '2rem ',
        textAlign: 'center',
        color:  'var(--claymint-primary)',
        textShadow: '2px 2px 0px black, 0px 0px 7px var(--claymint-primary)',
        cursor: 'pointer',
        position: 'absolute',   
        left: '3%',
        top: '-25%',
        letterSpacing: '0.15rem',
        zIndex: '1',

    }

    const descriptionStyle = {
        fontFamily:'ShortStack', 
        fontSize: '0.5em', 
        position: 'relative', 
        top: '7%', 
        left: '-20%'
    }


    const priceStyle = {
        fontFamily:'ShortStack', 
        color: 'var(--claymint-primary)',
        fontSize: '0.5em', 
        position: 'relative', 
        top: '-10%', 
        left: '-24%'

    }
        return (

        <>

       {/* <CarouselContainer>
              
        <Swiper style={{}}
            modules={[Autoplay, Navigation]}
            autoplay={false}
            navigation={true}
            effect='coverflow'
            grabCursor={true}
            loop={true}
            slidesPerView={3}
            coverflowEffect={
                {
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
            }}
            spaceBetween={0}
            observer={true}
            observeParents={true}
       
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            >  
            
                <div className='item-description'>
                <button style={mintButtonStyle} 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>MINT</button></div>


                <SwiperSlide>
                    <img src={''}/>
                    <p style={descriptionStyle}>Sonic Village </p>
                    <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>
               
             
                <SwiperSlide>
                    <img src={sonic}/>
                    <p style={descriptionStyle}>Sonic Village </p>
                    <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>


                <SwiperSlide>
                    <img src={underworld} />
                    <p style={descriptionStyle}>Underworld</p>
                    <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>


                <SwiperSlide>
                <img src={underwater} />
                <p style={descriptionStyle}>Sonic Village</p> 
                <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>


                <SwiperSlide>
                <img src={claynation} />
                <p style={descriptionStyle}>Shopping Dist</p>
                <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>


                <SwiperSlide>
                <img src={treehouse} />
                <p style={descriptionStyle}>Treehouse</p>
                <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>


                <SwiperSlide>
                <img src={underworldillustration} />
                <p style={descriptionStyle}>Underworld</p>
                <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>


                <SwiperSlide>
                <img src={bakednation} />
                <p style={descriptionStyle}>Baked Nation</p>
                <p style={priceStyle}>500 ADA</p>
                </SwiperSlide>
                 

            <div className='carousel-controler'>

            </div>
            </Swiper>  

            </CarouselContainer>
*/}
            </>

        );


}


