

function PrivacyPolicy(props){




  const containerStyles = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
  };

    const headingStyles = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color:'black'
  };

  const paragraphStyles = {
    marginBottom: "10px",
  };
		
	return (
<div style={{background:`radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '100vh', overflow:'scroll', color:'bisque'}}>
  		
		<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', textAlign:'center'}}>
			<div style={containerStyles}>	

			<div>	
			
			<p class="c7"><span class="c0">Last Updated: 28.03.23</span></p>
			</div>
			<p class="c12"><span class="c16 c32" style={headingStyles}>C Space Limited: Privacy Policy</span><span class="c0"><br /><br />This privacy notice for C Space Limited (company, we, us or our), describes how and why we might collect, store, use and/or share (process) your information when you use our services (services), such as when you:</span></p>
			<ul class="c1 lst-kix_list_5-0 start">
			<li class="c7 c15 li-bullet-0"><span class="c2">Visit our website at&nbsp;</span><span class="c2 c13"><a class="c27" href="https://www.google.com/url?q=https://clayspace.io/&amp;sa=D&amp;source=editors&amp;ust=1677668718558669&amp;usg=AOvVaw17R8RQJAMz9ZSAunR5gDbC">https://clayspace.io/</a></span><span class="c0">&nbsp;or any website of ours that links to this privacy notice.</span></li>
			<li class="c26 c15 li-bullet-0"><span class="c0">Engage with us in other related ways, including any sales, marketing or events. &nbsp;</span></li>
			</ul>
			<p class="c26"><span class="c2">By using our website you hereby consent to this privacy policy, and agree to the following terms. If you do not agree with our policies and practices, do not use our services. If you have questions or concerns, please contact us at&nbsp;</span><span class="c2 c20"><a class="c27" href="mailto:team@claymates.org">community.</a></span><span class="c20 c2">cspace@gmail.com</span><span class="c0">.<br /><br />Summary of key points from our privacy notice:</span></p>
			<p class="c7 c24">&nbsp;</p>
			<ul class="c1 lst-kix_list_8-0 start">
			<li class="c7 c15 li-bullet-0"><span class="c0">What personal information do we process? When you visit, use or navigate our services, we may process personal information depending on how you interact with C Space LTD. and the services, the choices you make, and the products and features you use.</span></li>
			<li class="c10 li-bullet-0"><span class="c0">How we get the personal information and why we have it. We process information provided by you to provide, improve and administer our services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</span></li>
			<li class="c10 li-bullet-0"><span class="c0">In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties.</span></li>
			<li class="c10 li-bullet-0"><span class="c0">How do we keep your information safe? We have organisational and technical processes and procedures in place to protect your personal information. However no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cyber criminals or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</span></li>
			<li class="c10 li-bullet-0"><span class="c0">What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">How do you exercise your rights? The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.<br /></span></li>
			</ul>
			<p class="c12"><span class="c0">TABLE OF CONTENTS</span></p>
			<ol class="c1 lst-kix_list_2-0 start" start="1">
			<li class="c10 li-bullet-0"><span class="c0">What information do we collect?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">How we process personal information and why we have it.</span></li>
			<li class="c10 li-bullet-0"><span class="c0">What legal basis do we rely on to process your personal information?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">When and with whom do we share your personal information?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">How long do we keep your information?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">How do we keep your information safe?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">Do we collect information from minors?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">What are your privacy rights?</span></li>
			<li class="c10 li-bullet-0"><span class="c0">How can you contact us about this notice or make a complaint?</span></li>
			</ol>
			<p class="c12"><span class="c16" style={{fontWeight:'bold'}}>1. What information do we collect?</span><span class="c2"><br /></span><span class="c2 c4">In short</span><span class="c2">:&nbsp;</span><span class="c8 c2 c4">we collect personal information that you provide to us.</span></p>
			<p class="c12"><span class="c0">We collect personal information that you voluntarily provide to us when you register for our services, express an interest in obtaining information about us or our products and services, when you participate in activities or otherwise when you contact us.<br /><br />The personal information that we collect depends on the context of your interactions with us and the services, the choices you make and the products and features you use. The personal information we collect may include the following:</span></p>
			<ul class="c1 lst-kix_list_1-0 start">
			<li class="c21 c22 li-bullet-0"><span class="c0">Personal identifiers, contacts and characteristics including name and contact details.</span></li>
			<li class="c21 c22 li-bullet-0"><span class="c0">Crypto Wallet Address, including related data from any wallet you may connect to the site, related holdings and transaction history.</span></li>
			<li class="c21 c22 li-bullet-0"><span class="c0">Information logging: we may log information about your use of our website, including browser information, IP address, access times, pages viewed.</span></li>
			<li class="c21 c22 li-bullet-0"><span class="c0">Social Media ID, usernames and profile information.</span></li>
			</ul>
			<p class="c21 c24">&nbsp;</p>
			<p class="c12"><span class="c2">Sensitive information. We do not process sensitive information.<br /><br /></span><span class="c16">Information automatically collected</span><span class="c2"><br /></span><span class="c2 c4">Some information - such as your Internet Protocol (IP) address and/or browser and device characteristics - is collected automatically when you visit our services.</span></p>
			<p class="c33"><span class="c2">We automatically collect certain information when you visit, use or navigate our services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our services, under the technical information. This information is primarily needed to maintain the security and operation of our services and for our internal analytics and reporting purposes.</span></p>
			<p class="c23"><span class="c0">The information we collect includes:</span></p>
			<ul class="c1 lst-kix_list_6-0 start">
			<li class="c5 c15 li-bullet-0"><span class="c2 c4">Log and usage data.&nbsp;</span><span class="c2">Log and usage data is service related, diagnostic, usage and performance information that our servers automatically collect when you access or use our services and which we may record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in our services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use)</span></li>
			<li class="c5 c15 li-bullet-0"><span class="c2 c4">Location Data.</span><span class="c2">&nbsp;Collect location data such as information about your device&rsquo;s location, which can either be precise or imprecise. how much information we collect depends on the type and settings of the device you use to access the services. For example, we may use GPS and other technologies to collect Geo location data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your location&nbsp;</span><span class="c0">setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the services.</span></li>
			<li class="c3 li-bullet-0"><span class="c2">Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</span></li>
			</ul>
			<p class="c23"><span class="c16" style={{fontWeight:'bold'}}>2</span><span class="c2">.</span><span class="c16" style={{fontWeight:'bold'}}>&nbsp;How we process your information and why we have it.</span><span class="c2 c4"><br />We process your information to provide, improve and administer our services, to communicate with you, for security and fraud prevention and to comply with law</span><span class="c2">.&nbsp;</span><span class="c8 c2 c4">We will not share your information for other purposes without your explicit consent.</span></p>
			<p class="c23"><span class="c2">Most of the personal information we process is provided to us directly by you for one of the&nbsp;</span><span class="c0">following reasons:</span></p>
			<ul class="c1 lst-kix_list_1-0">
			<li class="c9 li-bullet-0"><span class="c0">To provide and maintain our Service, including to monitor the usage of our Service and attend to or manage your requests to us.</span></li>
			<li class="c9 li-bullet-0"><span class="c0">To request feedback. We may process your information when necessary to request feedback and contact you about your use of our services</span></li>
			<li class="c21 c22 li-bullet-0"><span class="c2">To protect our services. We may process your information as part of our efforts to keep our services safe and secure, including fra</span><span class="c0">ud monitoring and prevention.</span></li>
			</ul>
			<p class="c11">&nbsp;</p>
			<ul class="c1 lst-kix_list_1-0">
			<li class="c21 c22 li-bullet-0"><span class="c0">To identify usage trends. We may process information about how you use our services to better understand how they're being used so we can improve them.</span></li>
			</ul>
			<p class="c11">&nbsp;</p>
			<ul class="c1 lst-kix_list_1-0">
			<li class="c9 li-bullet-0"><span class="c0">To save or protect an individual&rsquo;s vital interest. We may process your information to save or protect an individual&rsquo;s vital interest such as to prevent harm.</span></li>
			</ul>
			<p class="c6">&nbsp;</p>
			<p class="c12"><span class="c16" style={{fontWeight:'bold'}}>3. What legal basis do we rely on to process your personal information?</span><span class="c2"><br /><br /></span><span class="c0">As a BVI company, we rely on different lawful bases for processing personal data depending on the circumstances of the processing activity. The lawful bases for processing personal data that we rely on are:</span></p>
			<ol class="c1 lst-kix_rk7u13219eze-0 start" start="1">
			<li class="c3 li-bullet-0"><span class="c0">Consent: Where we process personal data on the basis of consent, we will obtain the individual's clear, specific, and informed consent to the processing of their personal data for a specific purpose. The consent must be freely given, and the individual must be able to withdraw their consent at any time.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Contractual necessity: Where we process personal data on the basis of contractual necessity, we will do so because the processing of personal data is necessary for the performance of a contract to which the individual is a party, or in order to take steps at the request of the individual before entering into a contract.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Legal obligation: Where we process personal data on the basis of legal obligation, we will do so because the processing of personal data is necessary for compliance with a legal obligation to which we are subject.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Vital interests: Where we process personal data on the basis of vital interests, we will do so because the processing of personal data is necessary to protect the vital interests of the individual, or of another person.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Legitimate interests: Where we process personal data on the basis of legitimate interests, we will do so because the processing of personal data is necessary for the legitimate interests pursued by us or by a third party, except where such interests are overridden by the fundamental rights and freedoms of the individual. When relying on the legitimate interests basis, we will carry out a balancing test to ensure that our legitimate interests are not outweighed by the rights and freedoms of the individual. We will also provide clear information to the individual about the processing and the legitimate interests pursued.</span></li>
			</ol>
			<p class="c12"><span class="c0">It is important to note that the specific lawful basis for processing personal data will depend on the circumstances of the processing activity. Therefore, we will carefully consider the lawful basis for processing personal data in each case and document the basis for processing in accordance with the Data Protection Act, 2021 (DPA).</span></p>
			<p class="c6">&nbsp;</p>
			<p class="c21"><span class="c8 c16 c17" style={{fontWeight:'bold'}}>4. When and with whom do we share your personal information?</span></p>
			<p class="c21"><span class="c8 c2 c4">We may share information in specific situations described in this section with the following third parties:<br /></span></p>
			<ul class="c1 lst-kix_list_4-0 start">
			<li class="c3 li-bullet-0"><span class="c0">Business transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></li>
			<li class="c3 li-bullet-0"><span class="c2">Law enforcement. Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</span></li>
			</ul>
			<p class="c21 c24 c30">&nbsp;</p>
			<p class="c7"><span class="c8 c16 c17" style={{fontWeight:'bold'}}>5. How long do we keep your information?</span></p>
			<p class="c14"><span class="c2 c4">Your information is securely stored and kept for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</span><span class="c0">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
			<p class="c14"><span class="c0">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose will require us keeping your personal information for longer than 12 months past the termination of your account.</span></p>
			<p class="c14"><span class="c0">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
			<p class="c7"><span class="c8 c16 c17" style={{fontWeight:'bold'}}>6. How do we keep your information safe?</span></p>
			<p class="c14"><span class="c2 c4">We aim to protect your personal information through a system of organisational and technical security measures.</span></p>
			<p class="c14"><span class="c0">We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our services is at your own risk. You should only access the services within a secure environment.</span></p>
			<p class="c14"><span class="c16" style={{fontWeight:'bold'}}>7. Do we collect information from minors?</span><span class="c2"><br /></span><span class="c2 c4">We do not knowingly collect data from or market to children under 18 years of age.<br /></span><span class="c2">By using our services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. if you become aware of any data we may have collected from children under age of 18 please contact us at community.cspace@gmail.com</span><span class="c0"><br /></span></p>
			<p class="c7"><span class="c16" style={{fontWeight:'bold'}}>8. What are your privacy rights?</span><span class="c2 c4"><br /><br /></span><span class="c0">Under the Data Protection Act, 2021 (DPA) in the British Virgin Islands (BVI), website users have certain data protection rights. Here are the key rights that users have:<br /></span></p>
			<ol class="c1 lst-kix_euy5yp93oz8i-0 start" start="1">
			<li class="c3 li-bullet-0"><span class="c0">Right to access: Users have the right to request access to their personal data that is held by the website. This includes the right to be informed of what personal data is being processed, the purposes for which it is being processed, and who it is being disclosed to.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Right to rectification: Users have the right to request that inaccurate or incomplete personal data be corrected or completed.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Right to erasure: Users have the right to request that their personal data be erased in certain circumstances, such as when the data is no longer necessary for the purposes for which it was collected or when the user withdraws their consent.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Right to object: Users have the right to object to the processing of their personal data in certain circumstances, such as when the data is being processed for direct marketing purposes.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Right to restriction of processing: Users have the right to request that the processing of their personal data be restricted in certain circumstances, such as when the accuracy of the data is contested.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Right to data portability: Users have the right to request that their personal data be provided to them in a structured, commonly used, and machine-readable format so that they can transfer it to another controller.</span></li>
			<li class="c3 li-bullet-0"><span class="c0">Right to withdraw consent: Users have the right to withdraw their consent to the processing of their personal data at any time.</span></li>
			</ol>
			<p class="c12"><span class="c2">To exercise these data protection rights, users can contact the&nbsp;</span><span class="c20 c2 c28">community.cspace@gmail.com</span><span class="c20 c2">&nbsp;</span><span class="c0">and make a request in writing.</span></p>
			<p class="c12"><span class="c0">If the website operator fails to comply with a user's data protection rights, the user has the right to lodge a complaint with the BVI Information Commissioner's Office.</span></p>
			<p class="c6">&nbsp;</p>
			<p class="c12"><span class="c8 c16 c17" style={{fontWeight:'bold'}}>9. How can you contact us about this notice?</span></p>
			<p class="c12"><span class="c2">Please contact us at&nbsp;</span><span class="c20 c2">community.cspace@gmail.com</span><span class="c0">&nbsp;if you wish to make a request, or if you have any questions or comments about this notice.</span></p>
			<p class="c5"><span class="c0">How to complain:</span></p>
			<p class="c12"><span class="c2">If you have any concerns about our use of your personal information, you can make a complaint to us at&nbsp;</span><span class="c20 c2">community.cspace@gmail.com</span><span class="c2 c31">&nbsp;<br /></span><span class="c0"><br /></span></p>
			<p class="c6">&nbsp;</p>
			<div>
			<p class="c25"><span class="c8 c19 c17">Date:&nbsp;</span>28.02.2023<span class="c8 c17 c19">&nbsp;</span></p>
			</div>
			</div>

			</div>

		</div>

	)

}

export default PrivacyPolicy;