import styled, { createGlobalStyle } from 'styled-components';


 
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #495569;
    font-family: PixelFont, ClayFont, ShortStack;
    
    --background: rgba(0, 0, 0, 0.5);
    --background-mobile: rgba(0, 0, 0, 0.7);
    --background-hover:  #272e2ed6;
    --background-pannel: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%);
    --background-pannel-mobile: linear-gradient(180deg, rgba(217, 217, 217, 0.70) 0%, rgba(217, 217, 217, 0.90) 100%);
    --background-popup: radial-gradient(50% 50.00% at 50% 50.00%, rgba(43, 41, 41, 0.90) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(50, 73, 73, 0.80);
    --background-popup-mobile: #4B5C60;
    --background-progress: rgba(29, 36, 54, 1);
  

    --clayblue-primary: #4FC8EF;
    --clayblue-secondary: #5870F6;
    --claypurple-primary: #7155A4;
    --claypurple-secondary: #593895;
    --clayyellow-secondary: #FEBD12;
    --claygreen-primary: #B2FE80;
    --claycoral-primary: #DA836F;
    --claytext-white: #F2F2F2;
  }
`;


 
export default GlobalStyle;