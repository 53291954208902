import { H1, H2, ImageCard, Container } from "../Main Panel Components/ChoosePfp.styled.jsx";
import avatar01 from "../../Images/Avatars/avatar01.png";
import avatar02 from "../../Images/Avatars/avatar02.png";
import avatar03 from "../../Images/Avatars/avatar03.png";
import { Grid } from "@mui/material";
import { Button } from '../../GlobalStyles.jsx';
import { useState } from "react";
import Pfp from "../Pfp.jsx";


function ChoosePfp () {

const images = [
    { id: 1, title: "#3425", img: avatar01 },
    { id: 2, title: "#2211", img: avatar02 },
    { id: 3, title: "#3355", img: avatar03 },
 ];

 const [selectedAvatar, setSelectedAvatar] = useState(images[0].img);

 const handleImageClick = (image) => {
        setSelectedAvatar(image);
    }


return (
    <Container>
        <H1>Choose Avatar</H1>
        <Grid container spacing={1} rowSpacing={4} >
            {images.map((image, index) => (
                <Grid item xs={4} key={image.id} onClick={() => handleImageClick(image.img)} >
                    <ImageCard key={index} >
                    <img src={image.img}  alt="avatar" style={{ width: '50%', height: 'auto', margin: '0 auto'}} />
                    <H2>CLAY NATION</H2>
                    <H2>{image.title}</H2>
                    </ImageCard>
                </Grid>
            ))}
        </Grid>
        <H1>Or</H1>
        <Button theme="blueInactive">UPLOAD</Button>

    </Container>
);

}


export default ChoosePfp;