import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {
    dialogueContext: {},

    npcStates: {},
}


const actions = {
    SET_DIALOGUE_CONTEXT: "SET_DIALOGUE_CONTEXT",

    SET_NPC_STATES: "SET_NPC_STATES",
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_DIALOGUE_CONTEXT:
            //console.log('SET REMOTE PLAYERS')
            return {...state, dialogueContext: action.value}; 

        case actions.SET_NPC_STATES:
            //console.log('SET REMOTE PLAYERS')
            return {...state, npcStates: action.value}; 

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainNPCContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        dialogueContext: state.dialogueContext,
        npcStates: state.npcStates,
        
        
        setDialogueContext: value => {
            dispatch({type: actions.SET_DIALOGUE_CONTEXT, value})
        },

        setNpcStates: value => {
            dispatch({type: actions.SET_NPC_STATES, value})
        },


        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainNPCContext.Provider value={value}>
            {children}
        </MainNPCContext.Provider>
        )
}


function NPCContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default NPCContext;


