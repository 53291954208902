import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import AttributeLoader from './AttributeLoader'


function ModelAttributes(props){

  const attributes = props.attributes

  const loadedAttributes = props.loadedAttributes 

  const setLoadedAttributes = props.setLoadedAttributes

  const bodyTextures = props.bodyTextures


  useEffect(() => {

    // console.log(attributes)

  },[attributes])

return(
  
   <>


   {/*
      Object.keys(attributes).map((attribute) => {
        return(
          <Suspense key={attribute} fallback={null}>
            <AttributeLoader attribute={attributes[attribute]} 
              loadedAttributes={loadedAttributes} setLoadedAttributes={setLoadedAttributes}  />
          </Suspense>
        )
      }) 
    */}



    {
      attributes.map((attribute, i) => {
        return(
          <Suspense key={i} fallback={null}>
            <AttributeLoader attribute={attribute} 
              loadedAttributes={loadedAttributes} 
              setLoadedAttributes={setLoadedAttributes} 
              bodyTextures={bodyTextures}
            />
          </Suspense>
        )
      }) 
    }
   </>
  
  )
}

export default ModelAttributes;