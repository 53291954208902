import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


function BodyTextures(props){

	const bodyTextures = props.bodyTextures
	const setBodyTextures = props.setBodyTextures 
	const clayColor = props.clayColor

	const url_path = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/CN/GLB2/body_textures/'

	const baseColor = useLoader(TextureLoader, url_path + 'Clay_' + clayColor + '_BaseColor.png')
	const normalMap = useLoader(TextureLoader, url_path + 'Clay_Normal.png')
	const roughnessMap = useLoader(TextureLoader, url_path + 'Clay_Roughness.png')
	const displacementMap = useLoader(TextureLoader, url_path + 'Clay_Height.png')
	const metalnessMap = useLoader(TextureLoader, url_path + 'Clay_Metallic.png')


	useEffect(() => {

		let temp_textures = []

		temp_textures.push({name: 'baseColor', 'texture': baseColor})
		temp_textures.push({name: 'normalMap', 'texture': normalMap})
		temp_textures.push({name: 'roughnessMap', 'texture': roughnessMap})
		temp_textures.push({name: 'displacementMap', 'texture': displacementMap})
		temp_textures.push({name: 'metalnessMap', 'texture': metalnessMap})


		let temp_bodyTexturesDict = bodyTextures

		temp_bodyTexturesDict[clayColor] = temp_textures

		setBodyTextures(temp_bodyTexturesDict);


		// console.log('\n\n\nBODY TEXTURES\n\n\n')
		// console.log(temp_bodyTexturesDict)



		return () => {
			baseColor.dispose()
			normalMap.dispose()
			roughnessMap.dispose()
			displacementMap.dispose()
			metalnessMap.dispose()
		}


	},[clayColor])


	
	return(

		<>

		</>

	)

}

export default BodyTextures;