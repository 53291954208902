import { useEffect, useState } from "react";
import { FormWrapper, FormLabel, TermsLabel, FormInput, FormGrid, Alert, H1, H4 } from "./SignUpForm.styled";
import { ModalDefault, PromptLogIn } from "./Modal.styled.jsx";
import { Button, ButtonSecondary } from '../GlobalStyles.jsx';

function SignUpForm({ handleConnectWallet, handleLogIn, isLoggedIn, setIsLoggedIn, accessToken, setAccessToken, ...props }) {


    const {username, setUsername, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword} = props

    // const [username, setUsername] = useState("");
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");

    const [usernameTaken, setUsernameTaken] = useState(false)

    const [isFormValid, setIsFormValid] = useState(false);
    const [error, setError] = useState("");

  
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {

        setUsername('')
        setEmail('')
        setPassword('')
        setConfirmPassword('')

        setIsLoggedIn(false)

    },[])


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleUsernameChange = (e) => {   


        let temp_username = e.target.value

        const usernameRegex = /^[A-Za-z0-9][A-Za-z0-9_-]{0,19}$/


        // const isValidUsername = usernameRegex.test(temp_username);

        let isValidUsername = usernameRegex.test(temp_username);

        if (temp_username.length > 19){
            isValidUsername = false
        }else if (temp_username.length == 0){
            isValidUsername = true
        }

        if (isValidUsername) {
          //console.log('Username is valid.');
          setUsername(e.target.value);
        } else {
          //console.log('Username is invalid.');
        }

        
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleSignUp = (e) => {
        e.preventDefault();  
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
    };

    useEffect(() => {   
        setIsFormValid(username !== '' && email !== '' && password === confirmPassword);
        if (username === 'cyberclay') {
            setError('Oops! Looks like that username is already taken.');
        } else if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
            setError('');   
        }
        }, [username, email, password, confirmPassword]);


    useEffect(() => {

        setUsernameTaken(false)

    },[username])


    async function checkUsernameAvailable(data){
       const response = await fetch('https://claymarket.io/claydash/check-username-available/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }



    async function processSetUp(){

        let usernameAvailable = false

        try{

            let username_status = await checkUsernameAvailable({"username":username})

            username_status = username_status["status"]

            if (username_status === 'available'){
                usernameAvailable = true
            }

        }catch{
            //do nothing
        }
        

        if (usernameAvailable){
            setUsernameTaken(false)

            if (password.length < 8){
                setError('Password is too short')
            }else if (username.length < 3){
                setError('Username is too short')
            }else{
                handleConnectWallet()
            }

            
        }else{
            setUsernameTaken(true)
            setError('Username already taken')
        }
    }
        


    return (
        <FormWrapper onSubmit={handleSignUp}>
              <H1>Set up your Clay ID</H1>
              {error ? <Alert>{error}</Alert> : null}
              <FormGrid>
            <FormLabel>
                USERNAME
                <br />
                

                <FormInput type="text" value={username} onChange={handleUsernameChange} />

                {/*<div style={{color:'red', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                 {
                    usernameTaken ?

                    <>
                    * * * user name taken * * *
                    </>
                    : null
                }
                </div>*/}
            </FormLabel>
            <FormLabel>
                EMAIL
                <br />
                <FormInput type="email" value={email} onChange={handleEmailChange} />       
            </FormLabel>
            <FormLabel>
                PASSWORD
                <br />
                <FormInput type="password" value={password} onChange={handlePasswordChange} />
            </FormLabel>
            <FormLabel>
                CONFIRM PASSWORD
                <br />
                <FormInput type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
            </FormLabel>
            </FormGrid>
            <TermsLabel>
                <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                I agree to the <a href="/claydash/terms-and-conditions" target="blank" style={{ color: 'var(--claytext-primary)', opacity: '0.5'}}>Terms and Conditions</a>.
            </TermsLabel>
            <Button theme="blue" type="submit" disabled={!isFormValid || error || !isChecked || password.length < 1} onClick={() => {
                //check if username available
                processSetUp()
            }}>LET'S GO!</Button>
            <PromptLogIn>
                <H4>ALREADY HAVE CLAY ID?</H4>
                 <ButtonSecondary theme="pinkInactive" type="button" onClick={handleLogIn} >LOG IN</ButtonSecondary>
            </PromptLogIn>
        </FormWrapper>
        
    );
};


export default SignUpForm;