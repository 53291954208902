import { Grid, Modal } from '@mui/material';
import { H1, H2, H3, H4, P, WalletsContainer, AddWalletButton, StyledTooltip, WalletCard, ConnectWalletContainer, WalletButtonsContainer, WalletIcon, ModalContainer, MessageWrapper, TabTag, WalletBtnIcon, RemoveButton } from './WalletsTab.styled.jsx';
import { Button, ButtonWallet } from '../../GlobalStyles.jsx';
import { useState } from 'react';
import nami from  '../../Images/nami.png';
import yoroi from '../../Images/yoroi.png';
import gero from '../../Images/gero.png';
import eternl from '../../Images/eternl.png';
import lace from '../../Images/lace.png';
import flint from '../../Images/flint.png';
import verified from '../../Images/verified.svg';

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs'
import {Buffer} from 'buffer'
import * as msgSign from '@emurgo/cardano-message-signing-asmjs'

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const walletOptions = [
    { 
    name: 'nami', 
    image: nami,
    amount: '350.75848' },
    {
    name: 'yoroi',
    image: yoroi,
    amount: '220.00000' },
    {
    name: 'gerowallet',
    image: gero,
    amount: '560.00000' },
    {
    name: 'eternl',
    image: eternl,
    amount: '0.00000' },
    {
    name: 'lace',
    image: lace,
    amount: '0.00000' },
    {
    name: 'flint',
    image: flint,
    amount: '220.00000' },
];


const NETWORK_ID = 1

const WalletsTab = (props) => {

    const {wallets, setWallets, accessToken, setAccessToken, toastMessages} = props

    const [selectedWallet, setSelectedWallet] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [gridItems, setGridItems] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);

    const [selectedIndex, setSelectedIndex] = useState(0)

    const [isVerifying, setIsVerifying] = useState(false)

    const [hwMode, setHwMode] = useState(false)

    const [cardanoAddress, setCardanoAddress] = useState('')
    const [cardanoStakeAddress, setCardanoStakeAddress] = useState('')
    const [activeStep, setActiveStep] = useState(0)

    const [cardanoSecret_1, setCardanoSecret_1] = useState('')
    const [cardanoSecret_2, setCardanoSecret_2] = useState('')

    const HWSwitch = styled((props) => (
      <Switch focusVisibleClassName=".Mui-focusVisible" {...props} /> ))(({ theme }) => ({
    }))


    const handleAddWallet = () => {
        if (selectedWallet) {
            setGridItems([...gridItems, selectedWallet]);
            setSelectedWallet(null);
            setIsModalOpen(false);
        }
    };


    const handleConfirmModal = () => {
        if (selectedWallet) {
            setGridItems([...gridItems, selectedWallet]);
            setConfirmModal(false);
            setSelectedWallet(null);
            setIsModalOpen(false);
        }
    };

    const handleRemoveWallet = (index) => {
        // const updatedItems = [...gridItems];
        // updatedItems.splice(index, 1);
        // setGridItems(updatedItems);
        setConfirmModal(false);

        console.log({"authToken_account": accessToken, "stake_address": wallets[selectedIndex]})

        processRemoveWallet({"authToken_account": accessToken, "stake_address": wallets[selectedIndex]})
    };

    async function processRemoveWallet(data){
        let removeStatus = await removeWallet(data)
        console.log(removeStatus)

        getWallets()
    }


    async function fetchWallets(data){
        const response = await fetch('https://claymarket.io/claydash/get-account-wallets/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }

    async function getWallets(){
       let fetched_wallets = await fetchWallets({"authToken_account":accessToken})

       console.log(fetched_wallets)

       let temp_wallets = fetched_wallets["wallets"]

       if (Array.isArray(temp_wallets)){
         setWallets(temp_wallets)
       }
       
    }



    async function removeWallet(data){
        const response = await fetch('https://claymarket.io/claydash/remove-account-wallet/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }



    async function addWallet(data){
        const response = await fetch('https://claymarket.io/claydash/add-account-wallet/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }


    async function processAddWallet(data){
        let addStatus = await addWallet(data)
        console.log(addStatus)

        getWallets()
    }




    async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}



  async function getUtxos(cardano){
  const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
  const utxos = (await cardano.getUtxos()).map((utxo) =>
    txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
  );
  return txUnspentOutputs
}

  
async function getParameters(){
  const protocolParams = await fetch('https://claymarket.io/minting/protocolparams/mainnet/', {
        method: 'GET',
       })
  const json_params = await protocolParams.json()
  return json_params
}



async function getCurrentSlot(){
    let slot = await fetch('https://claymarket.io/minting/currentslot/mainnet/', {
        method: 'GET',
        }).then(response => response.json()).then(data => data)

    slot = parseInt(slot.slot, 10)

    return slot
}



  async function signTransaction(cardano, address, message){

    const address_encoded = serLib.Address.from_bech32(address)
    const protocolParams = await getParameters()
    const utxos = await getUtxos(cardano)

    let txBuilder = await get_txBuilder(protocolParams)

    let lovelacePayment = 2000000

    let payment_output = serLib.TransactionOutput.new(
              address_encoded,
              serLib.Value.new(
                serLib.BigNum.from_str(String(lovelacePayment)),
              )
    )


    let required_signer = Buffer.from(serLib.BaseAddress.from_address(address_encoded).payment_cred().to_keyhash().to_bytes()).toString('hex')
    txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signer,'hex')))
    //txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signer_2,'hex')))

    txBuilder.add_output(payment_output);

    let before_message = 'Proving ownership of the wallet by signing this message: '

    try{
      txBuilder.add_json_metadatum(
                  serLib.BigNum.from_str("6679"),
                  JSON.stringify({
                    "message": [before_message, message]
                  })
                 );
    }catch(err){
      //console.log(err)
    }

    
    try{
          txBuilder.add_inputs_from(utxos, 2) 
        }catch (err){
          // console.log('err')
          // console.log(err)

          // notifyWarn('balance is not sufficient')
          return
        }

      

      try{
        txBuilder.add_change_if_needed(address_encoded)
      }catch(err){
        //console.log(err)
        // setTransactionStatus('')
        // notifyWarn('could not add change')
        return
      }


      let unsignedTx;

      try{
         unsignedTx = txBuilder.build_tx()
      }catch(err){
        unsignedTx = ''
        // console.log(err)
        return
      }
      

      // console.log(unsignedTx.to_json())

      const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')

      let tx_witness;

      try{
        // setTransactionStatus('Signing Transaction...')
        tx_witness = await cardano.signTx(unsignedTx_hash, true)
      }catch{
        return
      }


      //let witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))

      //console.log(tx_witness)
     
      return {"tx":unsignedTx_hash, "witness":tx_witness}

  }




    async function  signMessage(cardano, address, message){
        let encoded_message_string = Buffer.from(message).toString('hex')
        let addr_ptr = serLib.Address.from_bech32(address)
        let address_encoded = Buffer.from(addr_ptr.to_bytes()).toString('hex')
        let access_signature = await cardano.signData(address_encoded, encoded_message_string)
        return access_signature
      }



    async function enableWallet(wallet){

        try{

            let cardano = window.cardano[wallet]
            let walletAPI = await cardano.enable()
            return walletAPI
            
        }catch{
          //could not connect
          toastMessages.notifyWarn('Could not connect')
        }

      }


      async function getStakeAddress(address){
        let temp = serLib.Address.from_bech32(address)
        temp = serLib.BaseAddress.from_address(temp)
        let stake_cred = temp.stake_cred()
        let reward_addr_bytes = new Uint8Array(29)
        reward_addr_bytes.set([0xe1], 0)
        reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
        let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
        let cur_stake_address = reward_addr.to_address().to_bech32()

        return cur_stake_address
      }


      async function fetchSecret(address){

        let response = await fetch(`https://clayapi.io/cardano/secret/${address}/`).then(response => response.json())
                                                                      .then(data => data)
        //console.log(response)
        return response                                                             
      }



    async function postSignature(signature){

  const data = {'signature':signature}
    const response = await fetch('https://clayapi.io/cardano/link/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()

 }

  async function postTransaction(data){

  //const data = {'tx':tx, 'witness':witness}

    const response = await fetch('https://clayapi.io/cardano/link-hw/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()

 }



    async function verifyOwnership(cardano, address, stake_address){

    let secretResponse = await fetchSecret(address)
    
    if (secretResponse.hasOwnProperty('secret') && secretResponse.hasOwnProperty('time')){
      let secret_1 = secretResponse.secret
      let time_1 = secretResponse.time   


      let message = 'Proving ownership of the wallet by signing this message: ' + secret_1                                                     
      


      let signedResponse = {}


      if (!hwMode){
        setIsVerifying(true)
        let signedMsg = await signMessage(cardano, address, message)
        let signature = signedMsg.signature
      
        signedResponse = await postSignature(signature)

      }else{
        setIsVerifying(true)
        let signedTx_dict = await signTransaction(cardano, address, secret_1)

        

        try{
          //console.log(signedTx_dict)
          signedResponse = await postTransaction(signedTx_dict)
          //console.log(signedResponse)

          //console.log('try')
        }catch{
          //console.log('catch')
          signedResponse = {}
        }
      }

      //console.log(signedResponse)

      

      if (signedResponse.hasOwnProperty('secret') && signedResponse.hasOwnProperty('time')){
        let secret_2 = signedResponse.secret
        let time_2 = signedResponse.time 
        
        //if we are here then success!
        setCardanoSecret_1(secret_1)
        setCardanoSecret_2(secret_2)

        setActiveStep(1)

        //spinner off
        //setIsVerifying(false)

        console.log('verifying')
        console.log(secret_2)

        //setWasSigned(true)

        if (true){

            let login_wallet_connect_data = {
              authToken_wallet: secret_2,
              authToken_account: accessToken,
              stake_address: stake_address
            }

            console.log(login_wallet_connect_data)


            let login_wallet_connect_response = {}

            try{

              await processAddWallet(login_wallet_connect_data)

              setIsModalOpen(false)

              setIsVerifying(false)


            }catch{
              //do nothing
            }

       
      }else{
        //handle error 2
        toastMessages.notifyError('Could not verify ownership')
        //spinner off
        setIsVerifying(false)
      }

    }else{
      //handle error 1
       toastMessages.notifyError('Could not verify ownership')
       //spinner off
       setIsVerifying(false)
    }

  }
}





    async function walletConnect(wallet){

    try{

      let cardano = await enableWallet(wallet)

      if (typeof(cardano) === 'undefined'){
        return
      }

      //check network
      let cur_network_id = await cardano.getNetworkId()

      // console.log('CUR NETWORK_ID')
      // console.log(cur_network_id)

      if (cur_network_id !== NETWORK_ID){
        //handle wrong network
        toastMessages.notifyWarn('Wrong Network!')
        return
      }




      let address_encoded;

      try{
       //console.log('here 1')
       address_encoded  = await cardano.getUsedAddresses()
       address_encoded = address_encoded[0]

       if (address_encoded === undefined){
        throw 'error'
       }
      }catch{

        try{
          //console.log('here 2')
          address_encoded  = await cardano.getUnusedAddresses()
          address_encoded = address_encoded[0]
          if (address_encoded === undefined){
            throw 'error'
          }
        }catch{
          //console.log('here 3')
          address_encoded = await cardano.getRewardAddress()
          //console.log(address_encoded)
        }
      }
         

      //console.log(address_encoded)

      let address_decoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()
      setCardanoAddress(address_decoded)

      console.log(address_decoded)

      let stake_address = await getStakeAddress(address_decoded)
      setCardanoStakeAddress(stake_address)

      console.log(stake_address)

      try{



        //VERIFY OWNERSHIP HERE!!!
        let signature = await verifyOwnership(cardano, address_decoded, stake_address)
        //!!!!!!!!!!!!!!!!!!!


      }catch{
        //handle error
        toastMessages.notifyError('Could not verify ownership')
        setIsVerifying(false)
      }
    }catch{
      //hanle error
      toastMessages.notifyWarn('Could not connect')
    }
  }




    return (
        <WalletsContainer>
            <Grid container rowSpacing={{ xs: 4, sm: 4, md: 8 }} columnSpacing={{ xs: 2, sm: 4, md: 6 }} style={{position:'absolute', top: '4rem', left: '2rem', width:'85%', paddingBottom: '4rem'}} >
                

                {wallets.map((wallet, index) => (
                    <Grid item key={index} xs={8} sm={4} md={4} lg={4}>
                        <WalletCard>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                        <StyledTooltip title="WALLET VERIFIED" placement="top">
                        <img src={verified} alt="verified" style={{ width: '12%' }}/>
                        </StyledTooltip>
                        </div>
                        <WalletIcon style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                            {/*<div style={{display:'flex', justifyContent:'center', alignItems:'center', 
                                        width:'20px', height:'100%', backgroundColor:'blue', textWrap:'wrap', textAlign:'center'}}>
                                <p style={{}}>{wallet}</p>
                            </div>*/}
                        {/*<img src={wallet.image} alt={wallet.name} style={{ height: '30px', width: '30px', padding: '1rem 0rem'  }}/>*/}

                         <p style={{padding:'8px',textWrap:'wrap', textAlign:'center', wordBreak:'break-word'}}>{wallet}</p>
                        </WalletIcon>

                        {/*
                        <H1>{wallet.name}</H1>
                        <H2>{wallet.amount} ₳</H2>*/}
                        </WalletCard>
                        <RemoveButton onClick={() => {
                            setConfirmModal(true)
                            setSelectedIndex(index)
                        }}>REMOVE</RemoveButton>
                    </Grid>
                ))} 


                   </Grid>
                <TabTag>WALLETS</TabTag>
               
                <div>
                    <StyledTooltip title="ADD WALLET" placement="top">
                        <AddWalletButton variant="contained" onClick={() => setIsModalOpen(true)}>+</AddWalletButton>
                    </StyledTooltip>
                </div>


                <Modal open={confirmModal} onClose={() => setConfirmModal(false)}>
                    <ModalContainer>
                        <ConnectWalletContainer>
                        <H1>ARE YOU SURE YOU WANT TO REMOVE WALLET?</H1>
                        <br/>
                        <Button theme="pinkInactive" type="button" onClick={handleRemoveWallet} >REMOVE WALLET</Button>
                        </ConnectWalletContainer>
                    </ModalContainer>
                    </Modal>
         
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <ModalContainer>
                        
                            <ConnectWalletContainer>
                                <H3>Connect Wallet</H3>

                                
                                    <div style={{width:'80%', height:'50px', marginBottom:'20px', marginTop:'-20px', backgroundColor:'', fontSize:'15px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            
                                            <div style={{width:'50px', height:'50px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                              <HWSwitch
                                                checked={hwMode}
                                                onChange={()=>{
                                                  setHwMode(!hwMode)
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                              />
                                            </div>

                                            <div style={{marginLeft:'10px'}}>
                                              Sign transaction (hardware wallets)
                                            </div>

                                        </div>
                                

                                {!isVerifying ?  
                                <WalletButtonsContainer>

                                {walletOptions.map((wallet, index) => (
                                    <ButtonWallet theme="blueInactive" type="button" key={index} onClick={() => {walletConnect(wallet.name)}}>
                                        <WalletBtnIcon src={wallet.image} alt={wallet.name}/>
                                        {wallet.name}
                                    </ButtonWallet>
                                ))}

                            </WalletButtonsContainer>

                            : 


                             <div style={{height:'200px', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                
                  <div style={{backgroundColor:'', height:'150px', width:'120px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <div 
                      id="spinner" 
                      className="spinnerCardano"
                      style={{backgroundColor:''}}
                    >  
                    </div>
                  </div>

                </div>}
                            </ConnectWalletContainer>
                        
                    </ModalContainer>
                    </Modal>
                   
                </WalletsContainer>
    );
};

export default WalletsTab;
