import styled from "styled-components";
import walleticon from '../Images/wallet.png';
import cursorvoxelised from '../Images/cursor32.png';


export const WalletButton = styled.button`
   background: var(--clayblue-secondary);
   font-family: PixelFont;
   font-weight: 100;
   text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
   border: none;
   max-width: 100%;
   max-height: 100%;
   width: fit-content;
   heightL: fit-content;
   padding: 1rem 2rem;
   font-size: clamp(0.5rem, 4vw, 2rem);
   letter-spacing: 0.2rem;
   text-align: center;
   color: white;
   border-radius: 1rem;
   cursor: url(${cursorvoxelised}), auto;
   white-space: nowrap;
   position: absolute;
    right: 2%;
    top: 5%;  
    display: inline-flex;
   &:hover {
       outline: 2px solid var(--clayblue-primary); ;
   }
   @media screen and (max-width: 768px) {
    display: none;
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 2rem);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: inline-flex;
    align-items: center;
   }
   `;
   
   export const WalletMobile = styled.button`
   position: fixed;
   top: 3%;
   right: 3%;
   display: flex;
   background: var(--clayblue-secondary);
   border-radius: 2em;
   border: none;
   padding: 3em 3em;
   background-image: url(${walleticon});
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
 
   @media screen and (min-width: 768px) {
     display: none;
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
   }
   `;



     export const WalletMobileBusy = styled.button`
   position: fixed;
   top: 3%;
   right: 3%;
   display: flex;
   background: var(--clayblue-secondary);
   border-radius: 2em;
   border: none;
   padding: 3em 3em;
   
 
   @media screen and (min-width: 768px) {
     display: none;
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
   }
   `;



