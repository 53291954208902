import {useState, useEffect, useContext} from 'react'

import './Ledger.css'

import { motion } from "framer-motion";

import {MainClaimContext} from 'components/ClaimContext'

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Buy from './Buy'


function Raffle(props){

	const {stake_address} = useContext(MainClaimContext)

	const {isAlertOpen, setIsAlertOpen, submittedTx, setSubmittedTx, isWinner, setIsClaiming} = props

	const raffleAddress = 'addr1qx3g7c06lxqfdea9mxkfu8gvtujd2zjmyzj5h9e9gcwfmg6zxmhhg5fqzhp8685wc2pqc3t5y0zpj3pct833f55j5qdse7uqlm'

   const [smallScreen, setSmallScreen] = useState(false)
   const [mediumScreen, setMediumScreen] = useState(false)

   const [largeScreen, setLargeScreen] = useState(false)


   const [yourEntries, setYourEntries] = useState('-')
   const [currentEntries, setCurrentEntries] = useState('-')

   const [timeStatus, setTimeStatus] = useState('-')

   const [startTime, setStartTime] = useState(1692822016)

   //1692821391+604970+3600*5
   const [endTime, setEndTime] = useState(1693444361)

   const [price, setPrice] = useState(15)

   const [tickets, setTickets] = useState(1)

   const [totalAda, setTotalAda] = useState(15)


   const [ended, setEnded] = useState(false)
   const [started, setStarted] = useState(false)

   const [winnersDrawn, setWinnersDrawn] = useState(true)


   const countdownStyle = {
        fontSize: '1.8rem',
        color: 'var(--clayyellow-secondary)',
        marginTop: '-40px',
        opacity: '0.8',

    }



    function getEpochEnd(time_end){


    if (!time_end){
      return
    }

    let now = Date.now()
    let time_dif = time_end - (now/1000)
    if (time_dif <= 60*60){
      if (time_dif <= 60){
        if (time_dif > 0){
           return '<1 minute'
         }else{
          return '0 minutes'
         }
       
      }else{
       return String(Math.floor(time_dif/60+1))+' minutes'
      }
    }else if (time_dif <= 60*60*24){
      if (time_dif <= 60*60){
        return '<1 hour'
      }else{
        let full_hours = Math.floor(time_dif/60/60)
        return String(full_hours) +'h ' + String(Math.floor((time_dif-full_hours*60*60)/60+1))+'m'
      }
    }else{
      let full_days = Math.floor(time_dif/60/60/24)
      let full_hours = Math.floor((time_dif - full_days * 60*60*24)/60/60)
      let full_minutes = Math.floor((time_dif - full_days * 60*60*24 - full_hours * 60 * 60)/60+1)
      return String(full_days) + 'd ' + String(full_hours) + 'h ' + String(full_minutes) + 'm'
    }

  }


  useEffect(() => {

  	setTotalAda(tickets * price)

  },[tickets])



    async function fetchRaffleEntries(){

	    let temp_stake_address;

	    if (stake_address == ''){
	      temp_stake_address = 'empty'
	    }else{
	      temp_stake_address = stake_address
	    }

	    let query_url = "https://claymarket.io/raffles/" + raffleAddress + '/' + temp_stake_address +'/'

	    const raffleEntriesInfo = await fetch(query_url).then(response => response.json())
	                                                                  .then(data => data)

	    if ('user_purchased' in raffleEntriesInfo){

	      setYourEntries(parseInt(raffleEntriesInfo['user_purchased'], 10))

	    }else{
	    	setYourEntries(0)
	    }                                                    

	    if ('total_purchased' in raffleEntriesInfo){
	      setCurrentEntries(parseInt(raffleEntriesInfo['total_purchased'], 10) )
	    }else{
	    	setCurrentEntries(0)
	    }

	  }


    useEffect(() => {

    	fetchRaffleEntries()

    	const interval = setInterval(() => {
            fetchRaffleEntries()
        }, 30000);
  
        //Clearing the interval
        return () => clearInterval(interval);

    },[stake_address])



    function updateTimeStatus(){

    	//console.log('update time status')

	  let now = Math.floor(Date.now() / 1000)

	  // console.log(now)
	  // console.log(endTime)
	  // console.log(startTime)
	      let time_str = ''

	      if (startTime && endTime){
	      	//console.log('here')

	        if (now > startTime){
	        	//console.log('a')

	          if (now < endTime){
	          	//console.log('b')


	            let end_str = getEpochEnd(endTime)

	            time_str = 'ENDS IN: ' + end_str
	            setTimeStatus(time_str)

	            setEnded(false)

	          }else{  

	            setEnded(true)

	            if (winnersDrawn){
	            	setTimeStatus('Winners Drawn')
	            }else{
	            	setTimeStatus('Raffle Ended')
	            }
	            

	          }

	        }else{

	        	//console.log('here 2')

	          let start_str = getEpochEnd(startTime)

	            time_str = 'STARTS IN: ' + start_str
	            setTimeStatus(time_str)

	            //console.log(time_str)


	            setStarted(false)

	            setEnded(false)
	        }
	    }
	}




useEffect(()=>{


  updateTimeStatus()

  const interval = setInterval(() => {
            updateTimeStatus()
   }, 10000);
  
   //Clearing the interval
   return () => clearInterval(interval);


},[startTime, endTime])


   function handleResize(){
      //console.log(window.innerWidth)

      let cur_width = window.innerWidth

      if (cur_width > 992){
         setLargeScreen(true)
         setMediumScreen(false)
         setSmallScreen(false)
      }else if (cur_width > 600){
         setLargeScreen(false)
         setMediumScreen(true)
         setSmallScreen(false)
      }else{
         setLargeScreen(false)
         setMediumScreen(false)
         setSmallScreen(true)
      }
   }


   useEffect(() => {
     window.addEventListener("resize", handleResize)

     return () => {
      window.removeEventListener("resize", handleResize)
     }
   })


   useEffect(() => {

      handleResize()

   },[])

	return(
		<>

			{
				largeScreen ?


				<div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>


					<div style={{width:'30%', height:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>

						<motion.div 
						    className="SidePannel"
						    transition={{ duration: 1.2, delay: 1.2  }}
						    initial={{ x:-1000, opacity:0 }}
						    animate={{ opacity:0.95, x:0 }}
						>
							<div style={{height:'100%', width:'100%',
								borderRadius: `00px 50px 50px 0px`,
		  						background: `linear-gradient(30deg, rgba(23, 57, 85, 0.66) 0%, rgba(70, 113, 160, 0.4) 100%)`,
		  						overflow:'auto',


		  						display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								contain: 'content',

								textAlign:'center'

							}}>

								<div className="raffle-ticket-bio" style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
							            <p>As part of our ongoing partnership with Ledger, a leader in crypto security, we’re excited to announce a raffle featuring exclusive prizes aimed at enhancing the security of your assets.</p>
							            <h1 style={{fontFamily: 'ButtonFont', color: 'var(--clayblue-primary)',letterSpacing: '0.1em', fontSize: 'calc(5px + 2.5vmin)'}}>HOW TO ENTER:</h1>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.5vmin)'}}>If you’ve completed the Clay Nation x Ledger Quest and secured a POK NFT, you’re automatically entered into the raffle, similar to holding a 1 x 15 ADA raffle ticket.</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.5vmin)'}}>For those seeking an even better chance at winning, or if you prefer an alternative approach, you can purchase raffle tickets for 15 ADA each.</p>
							            <p style={{marginTop: '7%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)'}}>The prizes are coveted items including:</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}} >1 x Deathrow Record x Ledger Nano X</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}}> 1 x The Sandbox Ledger Nano X</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}}> 2 x Clay Nation x Ledger Nano X</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)'}}>Winners should be comfortable sharing their name and address to enable us to deliver the prizes securely.</p>
							            <h1 style={{fontFamily: 'ButtonFont', color: 'var(  --clayblue-primary)',fontSize: 'calc(5px + 3vmin)', letterSpacing: '0.1em'}}>GOOD LUCK!</h1>

							    </div>


							</div>

						</motion.div>

						{/*<motion.div 
						    className="SidePannel"
						    transition={{ duration: 1.2, delay: 1.2  }}
						    initial={{ x:-1000, opacity:0 }}
						    animate={{ opacity:0.95, x:0 }}
						    >
						        <div className="SidePannelInner">
						        <div className="raffle-ticket-bio">
						            <p>As part of our ongoing partnership with Ledger, a leader in crypto security, we’re excited to announce a raffle featuring exclusive prizes aimed at enhancing the security of your assets.</p>
						            <h1 style={{fontFamily: 'ButtonFont', color: 'var(--clayblue-primary)',letterSpacing: '0.1em',fontSize: 'calc(5px + 2.5vmin)'}}>HOW TO ENTER:</h1>
						            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.5vmin)'}}>If you’ve completed the Clay Nation x Ledger Quest and secured a POK NFT, you’re automatically entered into the raffle, similar to holding a 1 x 15 ADA raffle ticket.</p>
						            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.5vmin)'}}>For those seeking an even better chance at winning, or if you prefer an alternative approach, you can purchase raffle tickets for 15 ADA each.</p>
						            <p style={{marginTop: '7%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)'}}>The prizes are coveted items including:</p>
						            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}} >1 x Deathrow Record x Ledger Nano X</p>
						            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}}> 1 x The Sandbox Ledger Nano X</p>
						            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}}> 2 x Clay Nation x Ledger Nano X</p>
						            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)'}}>Winners should be comfortable sharing their name and address to enable us to deliver the prizes securely.</p>
						            <h1 style={{fontFamily: 'ButtonFont', color: 'var(  --clayblue-primary)',fontSize: 'calc(5px + 3vmin)', letterSpacing: '0.1em'}}>GOOD LUCK!</h1>

						        </div>
						        </div>
						        
						    </motion.div>*/}

					</div>


					<div style={{width:'70%', height:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>

						 <motion.div className="form-container"
					        transition={{ duration: 1.2, delay: 0.7  }}
					        initial={{ x:1000, opacity:0 }}
					        animate={{ opacity:0.95, x:0 }}>

					       
					               <div className="raffle-pannel">
					                    
					                    <h1 style={{color: 'var(  --clayblue-primary)'}}>[LEDGER] RAFFLE</h1>
					                    <div className="raffle-ticket-display" style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
					                      
					                      <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/raffleticket.png'} style={{width: '88%', maxWidth:'300px', height: 'auto', filter: 'drop-shadow(0px 0px 45px rgba(69, 152, 255, 0.4))'}} alt="ticket" />
					                      

					                      <div style={{height:'120px'}}>
						                      <p>Your tickets: {yourEntries} </p>
						                      <p>Current tickets: {currentEntries}</p>
						                      <p style={{marginTop: '10%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 2vmin)', color: 'var(--claygreen-primary)'}}>{timeStatus}</p>
					                      </div>

					                    </div>


					                    {!winnersDrawn ?

					                    <div style={ended ? {visibility:'hidden'} : {}}>
					                    <Buy totalAda={totalAda} raffleAddress={raffleAddress} 
					                    	isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
					                    />



					                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px', paddingBottom:'100px'}}>

					                    	<button className="ticket-quantity-button" onClick={()=>{
					                    		if (tickets < 40){
					                    			setTickets(tickets + 1)
					                    		}
					                    	}}>
					                    	 <AddIcon sx={{fontSize:'30px'}} />
					                    	</button>

					                    	<div style={{marginLeft:'5px', marginRight:'5px', fontSize:'28px'}}>
					                    	{tickets}
					                    	</div>

					                    	<button className="ticket-quantity-button" onClick={()=>{
					                    		if (tickets > 1){
					                    			setTickets(tickets - 1)
					                    		}
					                    	}}>
					                    	 <RemoveIcon sx={{fontSize:'30px'}} />
					                    	</button>


					                    </div>

					                    </div>

					                    : 

					                    isWinner ?

					                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >You are the WINNER!</p>
					                    	
					                    	<button className="raffle-ticket-button" 
																	style={{marginTop:'0px'}}

																	onClick={()=>{setIsClaiming(true)}}

																>
																	CLAIM PRIZE

																</button> 

					                    </div>

					                    : 

					                   <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'30px'}}>
					                    	
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >No winning entries found</p>
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >Better luck next time!</p>
					                    	
					                   </div>


					                  }


					               </div>


					              
					                <div className="form-images">
					                	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					                </div>
					              

					        <div style={{width:'20%', height:'0px', backgroundColor:'yellow',
					                	position:'absolute', top:'-2%', right:'10%', zIndex:'-1'
					            	}}>




					            	<motion.img
						    transition={{ duration: 0.5, delay: 3 }}
						    initial={{ y:100, opacity:0 }}
						    animate={{ opacity:0.95, y:0 }}
						    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
						    alt="logo" className="logo" style={{width:'100%', height: 'auto', marginTop:'-250px', zIndex:''}}/>

					        </div>

					        

					        </motion.div>



					        {/*<motion.img
						    transition={{ duration: 0.5, delay: 3 }}
						    initial={{ y:100, opacity:0 }}
						    animate={{ opacity:0.95, y:0 }}
						    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
						    alt="logo" className="logo" style={{width:'10%', height: 'auto', position: 'relative', left: '18%'}} /> 
							*/}

					</div>



					

				</div>

				


				: mediumScreen || smallScreen ?

				<div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
				
				<div style={{width:'90%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', backgroundColor:''}}>

					<div style={{width:'100%', height:'100%', backgroundColor:'', marginTop: '40px'}}>
						




						{mediumScreen ?

						<motion.div className="form-container" style={{width:'100%'}} 
					        transition={{ duration: 1.2, delay: 0.7  }}
					        initial={{ x:1000, opacity:0 }}
					        animate={{ opacity:0.95, x:0 }}>

					       


					               <div className="raffle-pannel">
					                    <h1 style={{color: 'var(  --clayblue-primary)'}}>[LEDGER] RAFFLE</h1>
					                    <div className="raffle-ticket-display" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
					                      
					                      <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/raffleticket.png'} style={{width: '88%', height: 'auto', filter: 'drop-shadow(0px 0px 45px rgba(69, 152, 255, 0.4))'}} alt="ticket" />
					                      
					                       <div style={{height:'120px'}}>
						                      <p>Your tickets: {yourEntries} </p>
						                      <p>Current tickets: {currentEntries}</p>
						                      <p style={{marginTop: '10%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 2vmin)', color: 'var(--claygreen-primary)'}}>{timeStatus}</p>
					                      </div>					                   
					                    </div>


					                    {!winnersDrawn ?

					                    <div style={ended ? {visibility:'hidden'} : {}}>
						                    <Buy totalAda={totalAda} raffleAddress={raffleAddress} 
						                    	isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
						                    />



						                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px', paddingBottom:'50px'}}>

						                    	<button className="ticket-quantity-button" onClick={()=>{
						                    		if (tickets < 40){
						                    			setTickets(tickets + 1)
						                    		}
						                    	}}>
						                    	 <AddIcon sx={{fontSize:'30px'}} />
						                    	</button>

						                    	<div style={{marginLeft:'5px', marginRight:'5px', fontSize:'28px'}}>
						                    	{tickets}
						                    	</div>

						                    	<button className="ticket-quantity-button" onClick={()=>{
						                    		if (tickets > 1){
						                    			setTickets(tickets - 1)
						                    		}
						                    	}}>
						                    	 <RemoveIcon sx={{fontSize:'30px'}} />
						                    	</button>
 
					                    	</div>


					                    </div>

					                    : 

					                    isWinner ?

					                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >You are the WINNER!</p>
					                    	
					                    	<button className="raffle-ticket-button" 
																	style={{marginTop:'0px'}}

																	onClick={()=>{setIsClaiming(true)}}

																>
																	CLAIM PRIZE

																</button> 

					                    </div>

					                    : 

					                   <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'30px'}}>
					                    	
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >No winning entries found</p>
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >Better luck next time!</p>
					                    	
					                   </div>


					                  }


					               </div>


					              
					                <div className="form-images">
					                	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					                </div>
					              

					        <div style={{width:'20%', height:'0px', backgroundColor:'',
					                	position:'absolute', top:'-2%', right:'10%', zIndex:'-1'
					            	}}>




					            	<motion.img
								    transition={{ duration: 0.5, delay: 3 }}
								    initial={{ y:100, opacity:0 }}
								    animate={{ opacity:0.95, y:0 }}
								    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
								    alt="logo" className="logo" style={{width:'100%', height: 'auto', marginTop:'-250px', zIndex:''}}/>

							        </div>

					        

					        </motion.div>







					        : 

     						/*SMALL*/

					        <motion.div className="form-container-sm" style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} 
					        transition={{ duration: 1.2, delay: 0.7  }}
					        initial={{ x:1000, opacity:0 }}
					        animate={{ opacity:0.95, x:0 }}>




					        	<div className="form-images-sm">
					               	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					            </div>
					              

					        	<div style={{width:'20%', height:'0px', backgroundColor:'',
					                	position:'absolute', top:'1%', right:'10%', zIndex:'-1'
					            }}>


					            <motion.img
								    transition={{ duration: 0.5, delay: 3 }}
								    initial={{ y:100, opacity:0 }}
								    animate={{ opacity:0.95, y:0 }}
								    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
								    alt="logo" className="logo" style={{width:'100%', height: 'auto', marginTop:'-250px', zIndex:''}}/>

							    </div>


							     <div className="raffle-pannel-sm" style={{minHeight:'500px'}}>
					                    <h1 style={{color: 'var(  --clayblue-primary)'}}>[LEDGER] RAFFLE</h1>
					                    <div className="raffle-ticket-display" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
					                      
					                      <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/raffleticket.png'} style={{width: '88%', height: 'auto', filter: 'drop-shadow(0px 0px 45px rgba(69, 152, 255, 0.4))'}} alt="ticket" />
					                      
					                       <div style={{height:'120px'}}>
						                      <p>Your tickets: {yourEntries} </p>
						                      <p>Current tickets: {currentEntries}</p>
						                      <p style={{marginTop: '10%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 2vmin)', color: 'var(--claygreen-primary)'}}>{timeStatus}</p>
					                      </div>					                   
					                    </div>



					                    {!winnersDrawn ?

					                    <div style={ended ? {visibility:'hidden'} : {}}>

					                    <Buy totalAda={totalAda} raffleAddress={raffleAddress} 
					                    	isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
					                    />



					                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px', paddingBottom:'50px'}}>

					                    	<button className="ticket-quantity-button" onClick={()=>{
					                    		if (tickets < 40){
					                    			setTickets(tickets + 1)
					                    		}
					                    	}}>
					                    	 <AddIcon sx={{fontSize:'30px'}} />
					                    	</button>

					                    	<div style={{marginLeft:'5px', marginRight:'5px', fontSize:'28px'}}>
					                    	{tickets}
					                    	</div>

					                    	<button className="ticket-quantity-button" onClick={()=>{
					                    		if (tickets > 1){
					                    			setTickets(tickets - 1)
					                    		}
					                    	}}>
					                    	 <RemoveIcon sx={{fontSize:'30px'}} />
					                    	</button>


					                    </div>

					                    </div>

					                    : 

					                    isWinner ?

					                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >You are the WINNER!</p>
					                    	
					                    	<button className="raffle-ticket-button" 
																	style={{marginTop:'0px'}}

																	onClick={()=>{setIsClaiming(true)}}

																>
																	CLAIM PRIZE

																</button> 

					                    </div>

					                    : 

					                   <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'30px'}}>
					                    	
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >No winning entries found</p>
					                    	<p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: '20px', fontSize: 'calc(2px + 2.2vmin)'}} >Better luck next time!</p>
					                    	
					                   </div>


					                  }


					               </div>




					       
					        		{/*

					               <div className="raffle-pannel">
					                    <h1 style={{color: 'var(  --clayblue-primary)'}}>[LEDGER] RAFFLE</h1>
					                    <div className="raffle-ticket-display" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
					                      
					                      <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/raffleticket.png'} style={{width: '88%', height: 'auto', filter: 'drop-shadow(0px 0px 45px rgba(69, 152, 255, 0.4))'}} alt="ticket" />
					                      
					                       <div style={{height:'120px'}}>
						                      <p>Your entries: {yourEntries} </p>
						                      <p>Current entries: {currentEntries}</p>
						                      <p style={{marginTop: '10%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 2vmin)', color: 'var(--claygreen-primary)'}}>{timeStatus}</p>
					                      </div>					                   
					                    </div>

					                    <Buy totalAda={totalAda} raffleAddress={raffleAddress} 
					                    	isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
					                    />



					                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'20px', paddingBottom:'50px'}}>

					                    	<button className="ticket-quantity-button" onClick={()=>{
					                    		if (tickets < 40){
					                    			setTickets(tickets + 1)
					                    		}
					                    	}}>
					                    	 <AddIcon sx={{fontSize:'30px'}} />
					                    	</button>

					                    	<div style={{marginLeft:'5px', marginRight:'5px', fontSize:'28px'}}>
					                    	{tickets}
					                    	</div>

					                    	<button className="ticket-quantity-button" onClick={()=>{
					                    		if (tickets > 1){
					                    			setTickets(tickets - 1)
					                    		}
					                    	}}>
					                    	 <RemoveIcon sx={{fontSize:'30px'}} />
					                    	</button>


					                    </div>


					               </div>


					              
					                <div className="form-images">
					                	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					                </div>
					              

					        <div style={{width:'20%', height:'0px', backgroundColor:'',
					                	position:'absolute', top:'-2%', right:'10%', zIndex:'-1'
					            	}}>




					            	<motion.img
								    transition={{ duration: 0.5, delay: 3 }}
								    initial={{ y:100, opacity:0 }}
								    animate={{ opacity:0.95, y:0 }}
								    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
								    alt="logo" className="logo" style={{width:'100%', height: 'auto', marginTop:'-250px', zIndex:''}}/>

							        </div>*/}

					        

					        </motion.div>





					        /*SMALL END*/
					    }





					</div>

					<div style={{width:'100%', height:'100%', backgroundColor:''}}>
						<motion.div 
						    className="SidePannel"
						    transition={{ duration: 1.2, delay: 1.2  }}
						    initial={{ x:-1000, opacity:0 }}
						    animate={{ opacity:0.95, x:0 }}
						>
							<div style={{height:'100%', width:'100%',
								borderRadius: `50px 50px 50px 50px`,
		  						background: `linear-gradient(30deg, rgba(23, 57, 85, 0.66) 0%, rgba(70, 113, 160, 0.4) 100%)`,
		  						overflow:'auto',

		  						marginTop: '30px',

		  						display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								contain: 'content',

								textAlign:'center'

							}}>

								<div className="raffle-ticket-bio" style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
							            <p>As part of our ongoing partnership with Ledger, a leader in crypto security, we’re excited to announce a raffle featuring exclusive prizes aimed at enhancing the security of your assets.</p>
							            <h1 style={{fontFamily: 'ButtonFont', color: 'var(--clayblue-primary)',letterSpacing: '0.1em',fontSize: 'calc(5px + 2.5vmin)'}}>HOW TO ENTER:</h1>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.5vmin)'}}>If you’ve completed the Clay Nation x Ledger Quest and secured a POK NFT, you’re automatically entered into the raffle, similar to holding a 1 x 15 ADA raffle ticket.</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.5vmin)'}}>For those seeking an even better chance at winning, or if you prefer an alternative approach, you can purchase raffle tickets for 15 ADA each.</p>
							            <p style={{marginTop: '7%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)'}}>The prizes are coveted items including:</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}} >1 x Deathrow Record x Ledger Nano X</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}}> 1 x The Sandbox Ledger Nano X</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)',color: 'var(--clayblue-primary)'}}> 2 x Clay Nation x Ledger Nano X</p>
							            <p style={{marginTop: '0%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 1.7vmin)'}}>Winners should be comfortable sharing their name and address to enable us to deliver the prizes securely.</p>
							            <h1 style={{fontFamily: 'ButtonFont', color: 'var(  --clayblue-primary)',fontSize: 'calc(5px + 3vmin)', letterSpacing: '0.1em'}}>GOOD LUCK!</h1>

							    </div>


							</div>

						</motion.div>
					</div>

				</div>

				</div>



				: null

			}

		</>
	)
}

export default Raffle;