import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {

    nickname: '',

    clayBalance: '135000000',

    avatarMeta: {
    

        "model":

        {
          "head": "Head",
          "trousers": "None",
          "clothes": "Nude",
        },

        "image": "",

        "clayColor": "Tan"
    
  },
    avatarNeedsUpdate: false,
    activeObjects: [],
    bottomActiveObjects: [],

    messageContent: '',

    inputBlocked: false,

    playerAction: "",
    playerPrevAction: "",

    voiceType: "type1",

    bounce: 0,
    dirBounce: {x:0, y:0, z:0},

    playerPosition: [0,0,0],
}


const actions = {

    SET_NICKNAME: 'SET_NICKNAME',

    SET_CLAY_BALANCE: 'SET_CLAY_BALANCE',

    SET_AVATAR_META: "SET_AVATAR_META",

    SET_AVATAR_NEEDS_UPDATE: "SET_AVATAR_NEEDS_UPDATE",

    SET_ACTIVE_OBJECTS: "SET_ACTIVE_OBJECTS",
    SET_BOTTOM_ACTIVE_OBJECTS: "SET_BOTTOM_ACTIVE_OBJECTS",

    SET_INPUT_BLOCKED: "SET_INPUT_BLOCKED",

    SET_MESSAGE_CONTENT: "SET_MESSAGE_CONTENT",

    SET_PLAYER_ACTION: "SET_PLAYER_ACTION",
    SET_PLAYER_PREV_ACTION: "SET_PLAYER_PREV_ACTION",

    SET_VOICE_TYPE: "SET_VOICE_TYPE",

    SET_BOUNCE: "SET_BOUNCE",
    SET_DIR_BOUNCE: "SET_DIR_BOUNCE",

    SET_PLAYER_POSITION: "SET_PLAYER_POSITION",
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_NICKNAME:
            return {...state, nickname: action.value}

        case actions.SET_CLAY_BALANCE:
            return {...state, clayBalance: action.value}

        case actions.SET_AVATAR_META:
            return {...state, avatarMeta: action.value}

        case actions.SET_AVATAR_NEEDS_UPDATE:
            return {...state, avatarNeedsUpdate: action.value}

        case actions.SET_ACTIVE_OBJECTS:
            return {...state, activeObjects: action.value}; 

        case actions.SET_BOTTOM_ACTIVE_OBJECTS:
            return {...state, bottomActiveObjects: action.value}; 

        case actions.SET_INPUT_BLOCKED:
            return {...state, inputBlocked: action.value}; 

        case actions.SET_MESSAGE_CONTENT:
            return {...state, messageContent: action.value}; 


        case actions.SET_PLAYER_ACTION:
            return {...state, playerAction: action.value}; 

        case actions.SET_PLAYER_PREV_ACTION:
            return {...state, playerPrevAction: action.value}; 

        case actions.SET_VOICE_TYPE:
            return {...state, voiceType: action.value}; 

        case actions.SET_BOUNCE:
            return {...state, bounce: action.value}; 

        case actions.SET_PLAYER_POSITION:
            return {...state, playerPosition: action.value}; 

        case actions.SET_DIR_BOUNCE:
            return {...state, dirBounce: action.value}; 

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainPlayerContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        nickname: state.nickname,
        clayBalance: state.clayBalance,
        avatarMeta: state.avatarMeta,
        avatarNeedsUpdate: state.avatarNeedsUpdate,
        activeObjects: state.activeObjects,
        bottomActiveObjects: state.bottomActiveObjects,

        inputBlocked: state.inputBlocked,
        messageContent: state.messageContent,

        playerAction: state.playerAction,
        playerPrevAction: state.playerPrevAction,

        voiceType: state.voiceType,

        bounce: state.bounce,
        dirBounce: state.dirBounce,

        playerPosition: state.playerPosition,

        setNickname: value => {
            dispatch({type: actions.SET_NICKNAME, value})
        },

        setClayBalance: value => {
            dispatch({type: actions.SET_CLAY_BALANCE, value})
        },

        setAvatarMeta: value => {
            dispatch({type: actions.SET_AVATAR_META, value})
        },

        setAvatarNeedsUpdate: value => {
            dispatch({type: actions.SET_AVATAR_NEEDS_UPDATE, value})
        },
        
        setActiveObjects: value => {
            dispatch({type: actions.SET_ACTIVE_OBJECTS, value})
        },

        setBottomActiveObjects: value => {
            dispatch({type: actions.SET_BOTTOM_ACTIVE_OBJECTS, value})
        },


        setInputBlocked: value => {
            dispatch({type: actions.SET_INPUT_BLOCKED, value})
        },

        setMessageContent: value => {
            dispatch({type: actions.SET_MESSAGE_CONTENT, value})
        },


        setPlayerAction: value => {
            dispatch({type: actions.SET_PLAYER_ACTION, value})
        },

        setPlayerPrevAction: value => {
            dispatch({type: actions.SET_PLAYER_PREV_ACTION, value})
        },

        setVoiceType: value => {
            dispatch({type: actions.SET_VOICE_TYPE, value})
        },

        setBounce: value => {
            dispatch({type: actions.SET_BOUNCE, value})
        },

        setDirBounce: value => {
            dispatch({type: actions.SET_DIR_BOUNCE, value})
        },

        setPlayerPosition: value => {
            dispatch({type: actions.SET_PLAYER_POSITION, value})
        },

        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainPlayerContext.Provider value={value}>
            {children}
        </MainPlayerContext.Provider>
        )
}


function PlayerContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default PlayerContext;


