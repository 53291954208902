import './CollectionCard.css'

import './Assets.css'

import { useState, useEffect, useContext } from 'react'

import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import styled, { keyframes } from 'styled-components';
import claydash from './Images/claydash.png';

import {MainContext} from './Context/Context'

const Card = styled.div`
    position: absolute;
    text-align: center;
    border-radius: 10px;
    width: 272px;
    height: 384px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    color: black;
    display: block;
    background-color: transparent;
    text-align: center;

    &:hover {
      box-shadow: 0 0px 28px ${props => props.boxShadow || "#FF9C83"};
      cursor: pointer;
    }
  `

const CardText = styled.div`
    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color: ${props => props.color || "#FF9C83"};
    opacity: 0.9;
`

const cardCollections = {
    "cn":"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
    "gc":"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
    "pitches":"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e"
  }


const validCollections = {
    
    claynation: 'cn',
    goodcharlotte: 'gc',
    pitches: 'pitches'

}



const validCollectionsAbr = {

    cn: 'claynation',
    gc: 'goodcharlotte',
    pitches: 'pitches'

}


function AssetCard(props){

	const {currentApp, setCurrentApp} = useContext(MainContext)

	const {collection, setChosenCollection, title, url, theme, disabled, matchesS, matchesL} = props


	
	return(
		<>


			<div className={theme.toUpperCase()} style={
				matchesL ? 
					{transform: 'scale(1.8)', marginTop:'-50px', marginLeft:'-10px'} : 
				matchesS ?
					{transform: 'scale(1.6)', marginBottom:'150px', marginLeft:'-10px'}
				:
					{transform: 'scale(1.4)', marginBottom:'200px', marginLeft:'-10px'}
				} 
				onClick={()=>{
					if (!disabled){
						setChosenCollection(collection)
					}
				}}
			>
				

				 <Grid container spacing={0}  align = "center" justify = "center" alignItems = "center"  >
		            <Grid item xs style={{top: "-40%", position: "relative"}} >
		              <img src={claydash} style={{width: '33%', height: 'auto', cursor: "pointer"}} />
		            </Grid>
		          </Grid>
       


			</div>
		</>
	)
}





function Assets3D(props){

	const {isSideBarOpen, setChosenCollection, matchesS, matchesL} = props

	const navigate = useNavigate();




	return(
		<>
			<div style={{display:'flex', justifyContent: isSideBarOpen && matchesS ? 'space-between' : 'center', minHeight:'100px',
		      width:'80%', flexWrap: 'wrap', backgroundColor:'', alignItems:'center', marginTop:'0px', backgroundColor: '', height:'100%',
		      marginLeft: isSideBarOpen && matchesS ? '320px' : '0px', marginTop:'', marginTop: matchesL ? '0px' : '40px'}}>

			     <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'wrap', backgroundColor:'', width:'100%'}}>

			        <div style={{width:'275px', height:'200px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
			                    display:'flex', justifyContent:'center', alignItems:'center'
			      }}

			      	onClick={()=>{
			      		navigate('/login');
			      	}}

			      >
							<AssetCard 
								collection={'pants'} 
								setChosenCollection={setChosenCollection}
								disabled={true}
								matchesS={matchesS}
								matchesL={matchesL}
								title={
									<div>
										<div>
											CLAY.DASH
										</div>
										{/*<div style={{fontSize:'10px', color:'bisque'}}>
											Available Soon
										</div>*/}
									</div>
								} 

								theme={'wearables'}

								url={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayDash.png"} 

								// styles={{
								// 	background:{
								// 		background: `radial-gradient(68% 60% at 50% 5.01%, #2774e7 30%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.06) 100%)`
								// 	}}}
							/>

					</div>


				</div>
			</div>
		</>
	)
}

export default Assets3D;


