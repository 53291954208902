import {useReducer , useState, createContext, useEffect} from 'react'


const initialState = {

    quality: 'Medium',

}


const actions = {

    SET_QUALITY: 'SET_QUALITY',
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_QUALITY:
            return {...state, quality: action.value}

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;
    }
}

export const MainQualityContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        quality: state.quality,

        setQuality: value => {
            dispatch({type: actions.SET_QUALITY, value})
        },


        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainQualityContext.Provider value={value}>
            {children}
        </MainQualityContext.Provider>
        )
}


function QualityContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default QualityContext;


