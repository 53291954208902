import { useState } from 'react';
import { motion } from "framer-motion";
import LaceIcon from '../Images/lace.svg'; 
import { WalletButton } from './WalletDisplay.styled';

function Wallet() {

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
   };
    const handleMouseLeave = () => {
      setIsHover(false);
   };

   const [buttonPopup, setButtonPopup] = useState(false);
   const Toggle = () => setButtonPopup(!buttonPopup);

   

    return (
        <WalletButton as={motion.button}
        initial={{ opacity: 0  }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.5 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => Toggle()}><img src={LaceIcon} style={{width:'30px', height:'auto', paddingRight: '0.5em',  lineHeight: "0", alignSelf: "center"}}/>6400₳</WalletButton>
      
    );

}

export default Wallet;




