import {useContext} from 'react'

import './SandboxUnlock.css'

import ClaimContext from 'components/ClaimContext'

import Context from 'components/Context/Context'

import ExperienceContext from 'components/Context/ExperienceContext'

import {MainContext} from 'components/Context/Context'

import UnlockXP from './Pages/UnlockXP'

import Layout from './LayoutPage'

function SandboxUnlock() {



  return (
    <>
      <UnlockXP />
    {/*<Layout />*/}
    </>
  );
}

export default SandboxUnlock;