import {useContext} from 'react'
import styled from "styled-components";

import { motion } from "framer-motion";
import background_video from './Images/BakedVideo.mp4';
import logo from './Images/BakedNation.png';
import end from './Images/MintPhase.svg'

import { UnlockXPWrapper, TransactionButton, SkipButton, Logo } from './Pages/UnlockXP.styled';

  // Styled components
const VideoBackground = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Ensure it stays behind the content */
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  color: white; /* Text color to contrast with the video */
  text-align: center;
  padding: 100px; /* Add padding for space around content */
`;


const ComingSoon = styled.img`
  position: absolute;
  width: 80vw; /* 80% of the viewport width */
  max-width: 1141px; /* Ensures the image doesn’t exceed original size */
  height: auto; /* Automatically adjusts height to maintain aspect ratio */
  left: 10vw; /* 10% of the viewport width from the left */
  top: 35vh; /* 35% of the viewport height from the top */

  /* Media queries for different screen sizes */
  @media (max-width: 768px) {
    width: 90vw; /* On smaller screens, make the image take more width */
    left: 5vw; /* Adjust the left position */
    top: 30vh; /* Adjust the top position */
  }

  @media (max-width: 480px) {
    width: 95vw; /* On mobile screens, almost full width */
    left: 2.5vw; /* Center the image more */
    top: 25vh; /* Adjust top position further */
  }
`;

const VideoBackgroundComponent = () => {
  return (
    <div>
      <VideoBackground autoPlay muted loop>
        <source src={background_video} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
      
    </div>
  );
};



function SandboxUnlock() {



  return (
    <div style={{

      height:'100vh',
      width:'100vw',
      background:`linear-gradient(to right, #FFA500, #FF4500)`,
      zIndex: '0'

    }}>



      <VideoBackgroundComponent />


      <a href="/" target="_blank">
        <Logo as={motion.img} 
          src={logo}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1.7, delay: 2 }}
          variants={{
            visible: { opacity: 1},
            hidden: { opacity: 0 }
          }}

          style={{zIndex:'2'}}
           ></Logo></a>
         

        <div>

          <ComingSoon src={end} alt="Locking Phase Complete" style={{zIndex:'2'}} />

        </div>

    </div>
  );
}

export default SandboxUnlock;