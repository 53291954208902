import {useContext} from 'react'

import './SandboxUnlock.css'

import ClaimContext from 'components/ClaimContext'

import Context from 'components/Context/Context'

import ExperienceContext from 'components/Context/ExperienceContext'

import {MainContext} from 'components/Context/Context'

import UnlockXP from './Pages/UnlockXP'


function SandboxUnlock() {



  return (
    <>
      <UnlockXP />
    </>
  );
}

export default SandboxUnlock;