
import styled from 'styled-components';


const FooterContent = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 25px;
	align-items: center; 

	@media screen and (max-width: 680px) {
    	flex-direction: column;
    }
    @media screen and (min-width: 680px) {
    	flex-direction: row;
    }

`


function Footer(props){

	const matchesS = props.matchesS
		
	return (
		
                 


                    <div style={{height:'100%', width:'100%', backgroundColor:'blue', 
                                borderRadius:'50px', display:'flex', justifyContent:'center', 
                                alignItems:'center', flexDirection:'column',
                            	background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)`,
                            	color:'rgba(255, 255, 240, 0.844)', fontFamily:'ShortStack'
                            }}>



	                    <FooterContent>
	                        


	                    	<div style={{display:'flex'}}>

		                        <div style={{marginRight:'5px'}}>
		                            <a href="https://twitter.com/claymates" target="_blank"> 
		                                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/socials/twitter.png" style={{width:'50px', height:'50px'}} /> 
		                            </a>
		                        </div>

		                        <div style={{marginRight:'10px'}}>
		                            <a href="https://discord.com/channels/831753699667607604/831755145938993193" target="_blank"> 
		                                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/socials/discord.png" style={{width:'50px', height:'50px'}} /> 
		                            </a>
		                        </div>

		                        
		                        <div style={{marginRight:'10px'}}>
		                            <a href="https://www.instagram.com/the_claymates/" target="_blank"> 
		                                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/socials/instagram.png" style={{width:'50px', height:'50px'}} /> 
		                            </a>
		                        </div>
		                    </div>


		                    <div style={{display:'flex'}}>
		                        <div style={{marginRight:'10px'}}>
		                            <a href="https://open.spotify.com/artist/1K0eNqY4OYa1WkxdBabO0k?si=lS-A_YzQSq2O-N1OKN2-Gw&nd=1" target="_blank"> 
		                                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/socials/spotify.png" style={{width:'50px', height:'50px'}} /> 
		                            </a>
		                        </div>


		                        <div style={{marginRight:'0px'}}>
		                            <a href="https://www.youtube.com/channel/UCE2hX-yGE7ejMcjdUXxAIJQ" target="_blank"> 
		                                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/socials/youtube.png" style={{width:'50px', height:'50px'}} /> 
		                            </a>
		                        </div>

		                        <div style={{marginRight:'10px'}}>
		                            <a href="https://music.apple.com/be/album/clay-nation-tapes-vol-i-single/1645776274" target="_blank"> 
		                                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/socials/apple.png" style={{width:'50px', height:'50px'}} /> 
		                            </a>
		                        </div>
		                    </div>


	                     </FooterContent>


	                    <div style={{marginTop:'20px', marginBottom:'25px'}}>
	                        Powered by C Space Limited
	                    </div>



	                    <div style={{display:'flex', fontSize:'14px', marginLeft:'25px', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px', paddingBottom:'20px'}}>
	                    	
	                    	 <div style={{marginRight:'5px', borderRight:'2px solid bisque'}}>
	                    	 	<div style={{marginRight:'5px', cursor:'pointer'}}

	                    	 		onClick={() => {

	                    	 			let newWnd = window.open('/privacypolicy', '_blank').focus();
	                    	 				
	                    	 		}}

	                    	 	>
	                    			Privacy Policy
	                    		</div>
	                    	</div>

	                    	<div style={{marginRight:'5px', borderRight:'2px solid bisque'}}>
	                    	 	<div style={{marginRight:'5px', cursor:'pointer'}}

	                    	 		onClick={() => {

	                    	 			let newWnd = window.open('/termsandconditions', '_blank').focus();
	                    	 				
	                    	 		}}

	                    	 	>
	                    			Terms and Conditions
	                    		</div>
	                    	</div>

	                    	
	                    	<div>
	                    		<div style={{marginRight:'5px', cursor:'pointer'}}

	                    			onClick={() => {

	                    	 			let newWnd = window.open('/cookiepolicy', '_blank').focus();
									
										
	                    	 				
	                    	 		}}

	                    		>
	                    			Cookie Policy
	                    		</div>
	                    	</div>

	                    </div>

                    </div>



               
	)

}

export default Footer;