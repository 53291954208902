

import { Link } from "react-router-dom";
import GlobalStyle from '../GlobalStyles';
import MainGrid from "../Components/MainGrid.jsx";
import logo from '../Images/BakedNation.png';
import { motion } from "framer-motion";

import {Buffer} from 'buffer'

import { Grid } from '@mui/material';

//import transition from "../Components/Transition";

import { useState, useContext, useEffect } from 'react';
import Wallet from "../Components/WalletDisplay";
import { WalletMobile } from "../Components/WalletDisplay.styled.jsx";
import walleticon from '../Images/wallet.png';
import TransactionPannel from "../Components/TransactionPannel";
import { UnlockXPWrapper, TransactionButton, SkipButton, Logo } from './UnlockXP.styled';

import ConnectPopUp from '../ConnectPopUp'

import AssetPopUp from '../AssetPopUp'

import Connect from '../Connect'

import cursorvoxelised from '../Images/cursor32.png';

import {Provider} from 'components/ClaimContext'

import {MainClaimContext} from 'components/ClaimContext'

import CompletedPopup from "../Components/CompletedPopup";

import Mint from './Mint'

import cn_meta from 'cn_meta.json'
import gc_meta from 'gc_meta.json'
import pitches_meta from 'pitches_meta.json'

import Tooltip from '@mui/material/Tooltip';
import { CardMedia } from '@mui/material';
import { StyledTooltip, Pin, MainPannel, GridContainer, ClayCard, TypographyID} from '../Components/MainGrid.styled';

import unlockedpin from '../Images/unlocked_pin.png';
import lockedpin from '../Images/locked_pin.png';


import NoAssets from '../Components/NoAssets'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from "styled-components";

import AlertPopUp from '../AlertPopUp'

export const MainGridPannel = styled.div`
padding: 2em 2.5em;
border-radius: 1em;
contain: content;
max-width: 70vw;
max-height: 100vh;
overflow-x: auto;
margin-left: ${(props) => props.isSideOpen ? '80px' : '-60px'};
transition: margin-left 0.3s ease-in-out;

@media screen and (max-width: 768px) {
       
        margin-top: 5em;
        overflow-y: auto;
        max-height: 100vh;
        padding: 2em;
        margin-left: 0px;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        max-height: 100vh;
        overflow-y: hidden;
        
    }

`;


const PageBackground = styled.div`
      width: 100vw;
      height: 100vh;
      background: linear-gradient(196.44deg, #495569 11.39%, #22252B 153.96%);

      min-height: 100vh;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: hidden;
        @media screen and (max-width: 768px) {
          background-attachment: scroll;
          background-size: fit;
          background-repeat: repeat;
          background-position: center;
        }
`






const CardImage = styled.div`
position: absolute;
width: 195.14px;
height: 195.14px;
left: 26.38px;
top: 21.44px;

background-color: #BDB1B1;
`


const CardDesc = styled.div`
/* Rectangle 22 */

box-sizing: border-box;

position: absolute;
width: 195.14px;
height: 76.96px;
left: 26.38px;
top: 216.57px;

border: 0.54968px solid #BDB1B1;
border-radius: 0px 0px 5.4968px 5.4968px;



`

const CardDescContainer = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 80%;

  flex-direction: column;

`


// Styled components
const ZoomContainer = styled.div`
  overflow: hidden;
 

  width: 195.14px;
  height: 195.14px;

  minWidth="320px"
`;

const ZoomImage = styled.img`
  width: 195.14px;
  height: 195.14px;
  transition: transform 0.5s ease;

  ${ClayCard}:hover & {
    transform: scale(1.1); /* Adjust the scale factor for zoom effect */
  }
`;



const BackButton = styled.div`

  position: fixed;
  right: 2%;
  bottom: 2%; 


  width: 173px;
  height: 55px;


  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;



&:hover {



}


`

const BackButtonText = styled.div`

  /* Back */

position: absolute;
width: 53px;
height: 34px;
left: 60px;
top: 11px;

font-family: 'Lato';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 140%;
/* identical to box height, or 34px */
text-align: center;

color: #FFFFFF;



`


const CardTitleText = styled.div`



font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 17.5898px;
line-height: 21px;
letter-spacing: 0.1em;

color: #FFFFFF;

pointer-events: none;

-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */


`

const CardSerialText = styled.div`




font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 17.5898px;
line-height: 21px;
text-transform: uppercase;

color: #FFFFFF;

pointer-events: none;

-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */



`


const ExtraSmallSpacer = styled.div`
    height: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
`




//https://claymarkets.com/api/baked/locked/assets/stake1u8hksgd2etj3wexftx60gxmlqnevwrfhtsj0fv89nh8k63qtc2ss2/
//https://claymarkets.com/api/baked/owned/assets/stake1u8hksgd2etj3wexftx60gxmlqnevwrfhtsj0fv89nh8k63qtc2ss2/


function ProcessEligibleNFTS(props){

  const {tokens, stake_address} = useContext(MainClaimContext)


  const {eligibleNfts, setEligibleNfts, selectedNfts,setSelectedNfts, setAll_num, setOpened_num, setFinal_num} = props


  //lockedNfts, setLockedNfts, ownedNfts, setOwnedNFTs}

  const [lockedNfts, setLockedNfts] = useState([])
  const [ownedNfts, setOwnedNFTs] = useState([])

  // console.log('TOKENS')
  // console.log(tokens)




   async function getLockedAssets(stake){
    console.log('FETCHING LOCKED ASSETS')
    let temp_assetStatus = await fetch('https://claymarkets.com/api/baked/locked/assets/' + stake + '/2', {
          method: 'GET',
         })
    temp_assetStatus = await temp_assetStatus.json()

    if (temp_assetStatus.hasOwnProperty(stake)){
      return temp_assetStatus[stake]
    }else{
      return {}
    }
    
  }


  async function getOwnedAssets(stake){
    console.log('FETCHING OWNED ASSETS')
    let temp_assetStatus = await fetch('https://claymarkets.com/api/baked/owned/assets/' + stake + '/2', {
          method: 'GET',
         })
    temp_assetStatus = await temp_assetStatus.json()

    if (temp_assetStatus.hasOwnProperty(stake)){
      return temp_assetStatus[stake]
    }else{
      return {}
    }
    
  }



  async function getAndUpdateLockedAssets(){

    let temp = await getLockedAssets(stake_address)

    setLockedNfts(temp)

  }

  async function getAndUpdateOwnedAssets(){

    let temp = await getOwnedAssets(stake_address)

    setOwnedNFTs(temp)

  }




  // useEffect(() => {

  //   console.log(assetStatus)

  //   updateStatus()

  // },[assetStatus])


  // useEffect(() => {

  //   getAndUpdateLockedAssets()
  //   getAndUpdateOwnedAssets()

  //   const interval = setInterval(() => {
  //           getAndUpdateLockedAssets()
  //           getAndUpdateOwnedAssets()
  //       }, 5000);

  //   return () => clearInterval(interval);

  // },[]) 




  useEffect(() => {

    // getAndUpdateLockedAssets()
    // getAndUpdateOwnedAssets()

    checkAndUpdateTokens()

    const interval = setInterval(() => {
            // getAndUpdateLockedAssets()
            // getAndUpdateOwnedAssets()

           checkAndUpdateTokens()

          //console.log('every 5 sec')
        }, 15000);

    return () => clearInterval(interval);

  },[stake_address, tokens])




  async function checkAndUpdateTokens(){

      let lockedAssets = await getLockedAssets(stake_address)
      let ownedAssets = await getOwnedAssets(stake_address)

      //lockedAssets = []

      console.log('OWNED ASSETS')
      console.log(ownedAssets)


      // console.log(lockedAssets)
      // console.log(ownedAssets)

      let temp_eligible = []


      let opened_tokens = []
      let unopened_tokens = []
      let final_tokens = []

      let added_tokens = []

      for (let token in lockedAssets) {

          if (token.slice(0,56) === '9e59c2dea85a6e6b70269d838c923b78badc868b13432abc0b0ba107'){

            let hex_name = token.slice(56,)
            hex_name = hex_name.slice('000de140'.length,)
            let name = Buffer.from(hex_name, 'hex').toString('utf8')
            let serial = name.slice('BakedNation'.length,)

            //console.log(serial)



            let temp = lockedAssets[token]

            //console.log(temp)


            let ipfs = 'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV' 
            let isFinal = false

            if (temp.hasOwnProperty('reveal_ipfs')){

              let reveal_ipfs = temp['reveal_ipfs']
               let first_ipfs = temp['first_ipfs']
                let second_ipfs = temp['second_ipfs']

               

              if (second_ipfs != ''){
                 ipfs = second_ipfs
                }else if (first_ipfs != ''){
                 ipfs = first_ipfs
                }else{
                 ipfs = reveal_ipfs
                }



                if (temp['final'] == 'yes'){
                  isFinal = true
                }

            }

            if (ipfs != 'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV'){
              if (isFinal){
                final_tokens.push([token, serial, ipfs, 'BAKED'])
              }else{
                opened_tokens.push([token, serial, ipfs, 'BAKED'])
              }
              
            }else{
              unopened_tokens.push([token, serial, ipfs, 'BAKED'])
            }

          }


          added_tokens.push(token)

      }




        tokens.map((token) => {

          if (added_tokens.includes(token)){
            return
          }
  
        if (token.slice(0,56) === '9e59c2dea85a6e6b70269d838c923b78badc868b13432abc0b0ba107'){
          let hex_name = token.slice(56,)
          hex_name = hex_name.slice('000de140'.length,)
          let name = Buffer.from(hex_name, 'hex').toString('utf8')
          let serial = name.slice('BakedNation'.length,)


          // console.log(serial)

          if (!(ownedAssets.hasOwnProperty(token))){
            return
          }

          let temp = ownedAssets[token]
          let ipfs = 'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV' 
          let isFinal = false

           if (temp.hasOwnProperty('reveal_ipfs')){

              let reveal_ipfs = temp['reveal_ipfs']
               let first_ipfs = temp['first_ipfs']
                let second_ipfs = temp['second_ipfs']

               

              if (second_ipfs != ''){
                 ipfs = second_ipfs
                }else if (first_ipfs != ''){
                 ipfs = first_ipfs
                }else{
                 ipfs = reveal_ipfs
                }



                if (temp['final'] == 'yes'){
                  isFinal = true
                }

            }

            if (ipfs != 'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV'){
              if (isFinal){
                final_tokens.push([token, serial, ipfs, 'BAKED'])
              }else{
                opened_tokens.push([token, serial, ipfs, 'BAKED'])
              }
              
            }else{
              unopened_tokens.push([token, serial, ipfs, 'BAKED'])
            }



        }
      })



    //   for (let token in lockedAssets) {

    //     console.log(token)


    //     if (token.slice(0,56) === '9e59c2dea85a6e6b70269d838c923b78badc868b13432abc0b0ba107'){

    //         let hex_name = token.slice(56,)
    //         hex_name = hex_name.slice('000de140'.length,)
    //         let name = Buffer.from(hex_name, 'hex').toString('utf8')
    //         let serial = name.slice('BakedNation'.length,)


    //         let temp = lockedAssets[token]

    //         if (temp.hasOwnProperty('reveal_ipfs')){

    //            let reveal_ipfs = temp['reveal_ipfs']
    //            let first_ipfs = temp['first_ipfs']
    //            let second_ipfs = temp['second_ipfs']

    //            let ipfs = ''

    //            if (second_ipfs != ''){
    //             ipfs = second_ipfs
    //            }else if (first_ipfs != ''){
    //             ipfs = first_ipfs
    //            }else{
    //             ipfs = reveal_ipfs
    //            }

    //           if (temp['final'] == 'yes'){
    //               final_tokens.push([
    //                 [token, serial,ipfs, 'BAKED']
    //               ])
    //           }else{
    //             opened_tokens.push([
    //               [token, serial,ipfs, 'BAKED']
    //             ])
    //           }

    //         }else{
    //           unopened_tokens.push([token, serial,'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV', 'BAKED'])
    //         }


    //     }

    //   }






    //   tokens.map((token) => {
  
    //     if (token.slice(0,56) === '9e59c2dea85a6e6b70269d838c923b78badc868b13432abc0b0ba107'){
    //       let hex_name = token.slice(56,)
    //       hex_name = hex_name.slice('000de140'.length,)
    //       let name = Buffer.from(hex_name, 'hex').toString('utf8')
    //       let serial = name.slice('BakedNation'.length,)


    //       if (ownedAssets.hasOwnProperty(token)){
    //         let temp = ownedAssets[token]

    //         if (temp.hasOwnProperty('reveal_ipfs')){

    //            let reveal_ipfs = temp['reveal_ipfs']
    //            let first_ipfs = temp['first_ipfs']
    //            let second_ipfs = temp['second_ipfs']


    //            let ipfs = ''

    //            if (second_ipfs != ''){
    //             ipfs = second_ipfs
    //            }else if (first_ipfs != ''){
    //             ipfs = first_ipfs
    //            }else{
    //             ipfs = reveal_ipfs
    //            }

    //           if (temp['final'] == 'yes'){
    //               final_tokens.push([
    //                 [token, serial,ipfs, 'BAKED']
    //               ])
    //           }else{
    //             opened_tokens.push([
    //               [token, serial,ipfs, 'BAKED']
    //             ])
    //           }

    //         }else{
    //           unopened_tokens.push([token, serial,'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV', 'BAKED'])
    //         }

    //     }
    //   }
    // })

        //final_tokens = [...Set(final_tokens)]

        setAll_num(String(unopened_tokens.length + opened_tokens.length + final_tokens.length))
        setOpened_num(String(opened_tokens.length))
        setFinal_num(String(final_tokens.length))

        temp_eligible = opened_tokens.concat(unopened_tokens, final_tokens)

        setEligibleNfts(temp_eligible)



      // let actualSelected = []

      // selectedNfts.forEach((temp) => {
      //   if (tokens.includes(temp)){
      //     actualSelected.push(temp)
      //   }
      // })

      // setSelectedNfts(actualSelected)


    //   temp_eligible = opened_tokens.concat(unopened_tokens, final_tokens)

    //   setEligibleNfts(temp_eligible)




  }





  // useEffect(() => {

  //   let temp_eligible = []


  //   console.log(tokens)

  //   let cn_tokens = []
  //   let gc_tokens = []
  //   let pitches_tokens = []

  //   let baked_tokens_held = []


  //   let opened_tokens = []

  //   let unopened_tokens = []

  //   let final_tokens = []


  //   console.log('LOCKED')
  //   console.log(lockedNfts)

  //   console.log('OWNED')
  //   console.log(ownedNfts)

  //   console.log(stake_address)



    
  //     tokens.map((token) => {
  //       //console.log(token)

        

  //       if (token.slice(0,56) === '9e59c2dea85a6e6b70269d838c923b78badc868b13432abc0b0ba107'){
  //         let hex_name = token.slice(56,)
  //         hex_name = hex_name.slice('000de140'.length,)
  //         let name = Buffer.from(hex_name, 'hex').toString('utf8')
  //         let serial = name.slice('BakedNation'.length,)


  //         if (lockedNfts.hasOwnProperty(token)){
  //           let temp = lockedNfts[token]

  //           if (temp.hasOwnProperty('reveal_ipfs')){

  //              let reveal_ipfs = temp['reveal_ipfs']
  //              let first_ipfs = temp['first_ipfs']
  //              let second_ipfs = temp['second_ipfs']

  //              let ipfs = ''

  //              if (second_ipfs != ''){
  //               ipfs = second_ipfs
  //              }else if (first_ipfs != ''){
  //               ipfs = first_ipfs
  //              }else{
  //               ipfs = reveal_ipfs
  //              }

  //             if (temp['final'] == 'yes'){
  //                 final_tokens.push([
  //                   [token, serial,ipfs, 'BAKED']
  //                 ])
  //             }else{
  //               opened_tokens.push([
  //                 [token, serial,ipfs, 'BAKED']
  //               ])
  //             }

  //           }else{
  //             unopened_tokens.push([token, serial,'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV', 'BAKED'])
  //           }

  //         }

  //         else if (ownedNfts.hasOwnProperty(token)){
  //           let temp = ownedNfts[token]

  //           if (temp.hasOwnProperty('reveal_ipfs')){

  //              let reveal_ipfs = temp['reveal_ipfs']
  //              let first_ipfs = temp['first_ipfs']
  //              let second_ipfs = temp['second_ipfs']


  //              let ipfs = ''

  //              if (second_ipfs != ''){
  //               ipfs = second_ipfs
  //              }else if (first_ipfs != ''){
  //               ipfs = first_ipfs
  //              }else{
  //               ipfs = reveal_ipfs
  //              }

  //             if (temp['final'] == 'yes'){
  //                 final_tokens.push([
  //                   [token, serial,ipfs, 'BAKED']
  //                 ])
  //             }else{
  //               opened_tokens.push([
  //                 [token, serial,ipfs, 'BAKED']
  //               ])
  //             }

  //           }else{
  //             unopened_tokens.push([token, serial,'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV', 'BAKED'])
  //           }

  //         }else{
  //           baked_tokens_held.push([token, serial,'QmegAgSKuyJVLZSkkGcELDBXjpny9fMYoArNVL7NFdj3FV', 'BAKED'])
  //         } 

  //       }

       
  //     })
    

  //   // console.log('eligible Nfts')
  //   // console.log(temp_eligible)

  //   //temp_eligible = cn_tokens.concat(gc_tokens, pitches_tokens)
  //   //temp_eligible = baked_tokens_held

  //   //temp_eligible = opened_tokens.concat(unopened_tokens, baked_tokens_held, final_tokens)

  //   //temp_eligible = final_tokens

  //   //console.log('TEMP ELIGIBLE')
  //   // console.log(cn_tokens)

  //   //setEligibleNfts(temp_eligible)
  //   //setSelectedNfts([])

  //   let actualSelected = []

  //   selectedNfts.forEach((temp) => {
  //     if (tokens.includes(temp)){
  //       actualSelected.push(temp)
  //     }
  //   })

  //   setSelectedNfts(actualSelected)

  // },[tokens])



  return(null)


}




function ProcessUpgradedNfts(props){

  const {stake_address} = useContext(MainClaimContext)

  const {upgradedNfts, setUpgradedNfts, notUpgradedNfts, setNotUpgradedNfts, eligibleNfts} = props


  //https://claymarket.io/minting/vox-status/

  // async function get_vox_status(){
  //   let json_vox_status = await fetch('https://claymarket.io/minting/vox-status/'+stake_address + '/', {
  //       method: 'GET',
  //      })
  //   json_vox_status = await json_vox_status.json()

  //   console.log(json_vox_status)

  //   let temp_unlocked = []
  //   let temp_locked = []

  //   try{
  //     temp_unlocked = json_vox_status.unlocked
  //     temp_locked = json_vox_status.locked
  //   }catch{
  //     //ignore
  //   }

  //   setUpgradedNfts(temp_unlocked)
  //   setNotUpgradedNfts(temp_locked)

  //   // setUpgradedNfts([])
  //   // setNotUpgradedNfts(eligibleNfts)

  // }


  // useEffect(() => {
  //   get_vox_status()
  //   const interval = setInterval(() => {
  //           get_vox_status()
  //       }, 20000);
  
  //   return () => clearInterval(interval);


  // },[eligibleNfts])

  return(null)
}



function UnlockXP() {

  const [buttonPopup, setButtonPopup] = useState(true);
  const Toggle = () => setButtonPopup(!buttonPopup);

  const [isWalletLoading,setIsWalletLoading] = useState(false)

  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const [isAssetPopUpOpen, setIsAssetPopUpOpen] = useState(false)

  const [activeWallet, setActiveWallet] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [walletToEnable, setWalletToEnable] = useState('')
  const [updateWallet, setUpdateWallet] = useState(false)
 

  const [selectedNfts, setSelectedNfts] = useState([])
  const [eligibleNfts, setEligibleNfts] = useState([])

  const [lockedNfts, setLockedNfts] = useState([])
  const [ownedNfts, setOwnedNFTs] = useState([])

  const [notUpgradedNfts, setNotUpgradedNfts] = useState([])

  const [upgradedNfts, setUpgradedNfts] = useState([])


  const [upgradeFee, setUpgradeFee] = useState(0)


  const [isAlertOpen, setIsAlertOpen] = useState(false) 
  const [submittedTx,setSubmittedTx] = useState('')



  const [isSideOpen, setIsSideOpen] = useState(true)


  const [all_num, setAll_num] = useState('-')
  const [opened_num, setOpened_num] = useState('-')
  const [final_num, setFinal_num] = useState('-')



    return (
      <PageBackground>



      
                        
                   

      <Provider>

        <ProcessEligibleNFTS eligibleNfts={eligibleNfts} setEligibleNfts={setEligibleNfts} 
          selectedNfts={selectedNfts} setSelectedNfts={setSelectedNfts}
          lockedNfts={lockedNfts} setLockedNfts={setLockedNfts}
          ownedNfts={ownedNfts} setOwnedNFTs={setOwnedNFTs}



          setAll_num={setAll_num}
          setOpened_num={setOpened_num}
          setFinal_num={setFinal_num}

        />

       {/* <ProcessUpgradedNfts 
          upgradedNfts={upgradedNfts}
          setUpgradedNfts={setUpgradedNfts} 
          notUpgradedNfts={notUpgradedNfts} 
          setNotUpgradedNfts={setNotUpgradedNfts}
          eligibleNfts={eligibleNfts}
        /> */}
        
       
          {/*<Wallet as={motion.button}
               initial="hidden"
               whileInView="visible"
               viewport={{ once: true }}
               transition={{ duration: 1.7, delay: 2 }}
               variants={{
                 visible: { opacity: 1},
                 hidden: { opacity: 0 }
               }} />

          <WalletMobile />*/}
        

        <div style={{position:'absolute', top:'35px', right:'40px'}}>
        <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}
                />

        </div>


        <a href="/" target="_blank">
        <Logo as={motion.img} 
          src={logo}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1.2, delay: 0.2 }}
          variants={{
            visible: { opacity: 1},
            hidden: { opacity: 0 }
          }}
           ></Logo></a>
        <GlobalStyle />


          {/*<MainGrid 
            eligibleNfts={eligibleNfts} 
            selectedNfts={selectedNfts} 
            setSelectedNfts={setSelectedNfts} 
          />*/}


        {
         eligibleNfts.length > 0 ? 

      <MainGridPannel isSideOpen={isSideOpen} style={{position:'relative', top:'50px', width:'60%', 
            height:'70%', overflow:'scroll', backgroundColor:'',
            display: 'flex', justifyContent:'center', alignItems:'center', minHeight:'500px', minWidth:'320px',
        }}>



         <div style={{width:'100%', display:'flex', visibility: false ? 'hidden' : 'visible', alignItems:'center', height:'100%'}}>
            
                <div style={{display:'flex', alignItems:'center', height:'100%', 
                width:'100%',flexDirection:'column'}}>

         

         <Grid container spacing={3} 
                          alignItems="center"
                          justifyContent="center" style={{backgroundColor:'', marginLeft:'0px', marginTop:'0px'}}
                    

                as={motion.div}
                initial={{ x:0, opacity:0 }} 
                whileInView="visible"
                viewport={{ once: true }}
                animate={{ opacity:1, x:0, type: "spring" }}
                transition={{ duration: 1.2, delay: 0.5}}
          >



          {eligibleNfts.map((eligibleNft)=>{

            return(
              <div key={eligibleNft[0]} style={{width:'240px', height:'320px', backgroundColor:'', marginRight:'10px', marginBottom:'10px', marginLeft:'20px'}} 
                onClick={()=>{
                  // console.log('click')
                  // console.log(selectedNfts)

                 

                  if (selectedNfts.includes(eligibleNft[0])){

                    //if (notUpgradedNfts.includes(eligibleNft[0])){
                      let temp_selectedNtfs = selectedNfts.filter( item => item != eligibleNft[0]);
                      setSelectedNfts([])
                    //}

                  }else{

                    //if (notUpgradedNfts.includes(eligibleNft[0])){

                    if (selectedNfts.length < 20){
                      setSelectedNfts([eligibleNft[0]])
                    }
                    //}

                  }
                }}
              >


              {/*<ClayCard
                  selected={selectedNfts.includes(eligibleNft[0])}>*/}


              <ClayCard style={{backgroundColor:'#2C2E32', borderRadius:'10px'}} selected={selectedNfts.includes(eligibleNft[0])}>


                  <CardImage>


                    <ZoomContainer>
                      <ZoomImage src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" 
                        + eligibleNft[2] 
                        + '.png'} alt="Zoomable Image" />
                    </ZoomContainer>

                    {/*<CardMedia
                    component="img"
                    width="70%"
                    height="auto"

                    minWidth="320px"

                    image={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" 
                        + eligibleNft[2] 
                        + '.png'}
                    alt="NFT1"
                    />*/}

                  </CardImage>

                  {/*<CardMedia
                    component="img"
                    width="70%"
                    height="auto"

                    minWidth="320px"

                    image={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" 
                        + eligibleNft[2] 
                        + '.png'}
                    alt="NFT1"
                    />*/}



                    <div style={{padding: "1em 0", width: "100%"}}>

                      <CardDesc>

                        <CardDescContainer>

                          <ExtraSmallSpacer />

                          <CardTitleText>
                            {eligibleNft[3] == 'CN'? 'Clay Nation' : eligibleNft[3] == 'GC' ? 'Good Charlotte':
                              eligibleNft[3] == 'PITCH'? 'Clay Pitch' : eligibleNft[3] == 'BAKED' ? 'Baked Nation' : null
                            }
                          </CardTitleText>

                          <ExtraSmallSpacer />


                          <CardSerialText>
                            {'#' + eligibleNft[1]}
                          </CardSerialText>

                        </CardDescContainer>

                      </CardDesc>

                    </div>


              </ClayCard>


              </div>
            )

          })}
        

        

        </Grid>
        

        </div>
        </div>
        </MainGridPannel>


        : 

        <NoAssets />
      }


        {isPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', backdropFilter: 'blur(10px)', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>

       : null}




       {isAssetPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', backdropFilter: 'blur(10px)', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <AssetPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isAssetPopUpOpen={isAssetPopUpOpen}
                    setIsAssetPopUpOpen={setIsAssetPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    selectedNfts={selectedNfts}
                />

           </div>

       : null}





      <TransactionButton as={motion.button} 
        initial="hidden"
        whileInView={(buttonPopup && !isAlertOpen) ?'hidden':'visible'}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0 }}
        variants={{
          visible: { opacity: 1},
          hidden: { opacity: 0 }
        }}
        type="button" 
        onClick={() => {
          if (isAlertOpen){
            setIsAlertOpen(false)
          }else{
            Toggle()
          }
        }}

        

        >></TransactionButton>

      


        <TransactionPannel trigger={(buttonPopup && !isAlertOpen)} setTrigger={setButtonPopup}
          eligibleNfts={eligibleNfts}
          selectedNfts={selectedNfts}
          setSelectedNfts={setSelectedNfts}

          upgradeFee={upgradeFee}
          setUpgradeFee={setUpgradeFee}

          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}

          submittedTx={submittedTx}
          setSubmittedTx={setSubmittedTx}

          isOpen = {isSideOpen}
          setIsOpen = {setIsSideOpen}


          isAssetPopUpOpen={isAssetPopUpOpen}
          setIsAssetPopUpOpen={setIsAssetPopUpOpen}


          all_num={all_num}
          opened_num={opened_num}
          final_num={final_num}

        />

      {/*<Link to="/baked-lock/info"><BackButton type="button"><BackButtonText>Back</BackButtonText></BackButton></Link>*/}

      {/*<div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
      </div>*/}

      {isAlertOpen ?
      <CompletedPopup setIsAlertOpen={setIsAlertOpen} />
      // <div style={{position:'absolute', width:'100%', height:'100%', backdropFilter:'blur(10px)', display:'flex', justifyContent:'center', alignItems:'center'}}>
      //   <CompletedPopup setTrigger={setButtonPopup} />
      // </div>
      : null}

      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />

      </Provider>
      </PageBackground>


    );
  }
  
  export default UnlockXP; 
  