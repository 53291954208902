import { useState, useContext, useEffect } from 'react'
import {Buffer} from 'buffer'

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import styled,  { keyframes } from 'styled-components';

import Avatar from '@mui/material/Avatar';

import ButtonBase from '@mui/material/ButtonBase';

import Stack from '@mui/material/Stack';

import Skeleton from '@mui/material/Skeleton'

import {MainClaimContext} from './ClaimContext'

import './FluidProgress.css'


import cn_rarity from '../cn_rarity.json'
import gc_rarity from '../gc_rarity.json'


import unlockedpin from './SandboxUnlock/Images/unlocked_pin.png';
import lockedpin from './SandboxUnlock/Images/locked_pin.png';

import { StyledTooltip, Pin} from './SandboxUnlock/Components/MainGrid.styled';

/*
import './CN-CARD.css'
import './GC-CARD.css'
import './PITCHES-CARD.css'
import './OTHER-CARD.css'
*/

import './PITCHES-CARD.css'
import './PANTS-CARD.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});


const collection_dict={
  cn: "Clay Nation",
  gc: "Clay Nation x Good Charlotte"
}


  const ButtonText = styled.h1`
    position: relative;
    font-size: 1.8em;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `


  const cardThemes = {
    "cn":{title: "Clay Nation", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"},
    "gc":{title: "CN x Good Charlotte", image: 'GC_CARD.svg', boxShadow: "#53C9EE", color: "bisque"},
    "pitches":{title: "Pitches at Clay Nation", image: 'PITCHES_CARD.svg', boxShadow: "#657389", color: "bisque"},
    "pants": {title: "Pants", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"},
    "other":{title: "Clay Nation", image: 'CN_CARD.svg', boxShadow: "#FF9C83", color:"bisque"}
  }


  const cardCollections = {
    "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"cn",
    "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"gc",
    "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"pitches",
    "90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771":"pants"
  }

    const assetBaseName = {
    "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"ClayNation",
    "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"ClayCharlotte",
    "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"ClayNationPitch",
    "90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771":"ClayPants"
  }


function DisplayAssetType(props){
  

  const name = props.name

  const [isPants, setIsPants] = useState(false)

  useEffect(() => {

    try{
      let tempIsPants = name?.includes('Pants')

      setIsPants(tempIsPants)
    }catch{
      setIsPants(false)
    }

  },[name])

  return(
    <div>
      {isPants? 'PANTS' : ''}
    </div>
  )
}



function AssetCard(props) {


  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)
  
  const asset_id = props.asset_id
  const asset_info = props.asset_info

  const setSellAsset = props.setSellAsset
  const sellAsset = props.sellAsset

  const prop_assetStatus = props.assetStatus

  const [assetStatus, setAssetStatus] = useState(prop_assetStatus)

  const tx_hash = props.tx_hash

  const assetPrice = props.assetPrice

  const [cardFlipped, setCardFlipped] = useState(false)

  const [priceCurrency, setPriceCurrency] = useState('')


  let cardCollection;

  let policy_id = asset_id.slice(0,56)

  if (policy_id in cardCollections){
    cardCollection = cardCollections[policy_id]
  }else{
    cardCollection = 'other'
  }

  if (cardCollection === 'pants'){
    cardCollection = 'cn'
  }


  console.log(asset_info)




  useEffect(() => {

    try{

      let cur_price = asset_info?.assetPrice
      let cur_clay_price = cur_price.clay_price
      let cur_ada_price = cur_price.ada_price

      if (cur_ada_price > 5000000 && cur_clay_price == 0){
        setPriceCurrency('ada')
      }

      if (cur_clay_price > 0 && cur_ada_price <= 5000000){
        setPriceCurrency('clay')
      }

    }catch{

    }

  },[asset_info?.assetPrice])



  useEffect(()=>{

    //console.log(stake_address)
    //console.log(asset_info.owner_stake_address)

    if (stake_address == asset_info.owner_stake_address){

      //console.log('SAME')

      if ( (parseInt(asset_info.assetPrice['ada_price'],10) > 0) || (parseInt(asset_info.assetPrice['clay_price'], 10) > 0) ){
        setAssetStatus('updatable')
      }
      else{
        setAssetStatus('sellable')
      }
    
    }else{

      //console.log('NOT SAME')

      if ( (parseInt(asset_info.assetPrice['ada_price'],10) > 0) || (parseInt(asset_info.assetPrice['clay_price'], 10) > 0) ){
        setAssetStatus('listed')
      }
      else{
        setAssetStatus('not listed')
      }
    }



  },[stake_address, asset_info, assetStatus])


  /*
  const image listing= props.image 
  const image_url = "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/"+image+".png"
  const serial = props.serial
  const collection_abbr = props.collection_abbr
  const collection = collection_dict[collection_abbr]
  const [isSellOpen, setIsSellOpen] = useState(false)
  const [isAssetOpen, setIsAssetOpen] = useState(false)

  const [flipCard, setFlipCard] = useState(false)



  const asset_id = props.asset_id

  const activeWallet = props.activeWallet

  const assetMeta = props.assetMeta

  const rarities = props.rarities
  const raritiesTotal = props.raritiesTotal

  

  const assetPrice = props.assetPrice



  const cardCollection = collection_abbr.toUpperCase()
  const meta = getMeta()




  function getMeta(){
    let temp_meta = []

    for (let key in assetMeta){
      if (key == 'image' || key == 'collection'){
        //pass
      }else{
        if (assetMeta[key]){
          const rarity = (rarities[collection_abbr][key][assetMeta[key]] / raritiesTotal[collection_abbr] * 100).toFixed(2)
          temp_meta.push({category:(key.charAt(0).toUpperCase() + key.slice(1)), trait:assetMeta[key], rarity:'(' + rarity + '%)'})
        }else if(key != 'extra' && key != 'wings'){
          const rarity = (rarities[collection_abbr][key]['None'] / raritiesTotal[collection_abbr] * 100).toFixed(2)
          temp_meta.push({category:(key.charAt(0).toUpperCase() + key.slice(1)), trait:'None', rarity:'(' + rarity + '%)'})
        }
      }
    }

    console.log(assetMeta)
    return temp_meta
  }


 

//const test_image = "QmQV1R5511nD89eJ597aVSgN4g8Xs9j9dVHCsv3e3C8gTn"
const test_image = "QmfPaCg9kEffcNqxmqk4HfXQzhU5hE2tEEW1fKBWX297UC"
const test_serial = "5150"


*/

function displayName(asset_id){

  if (!asset_id){
    return
  }
  let cur_policy = asset_id.slice(0,56)

  let baseName = assetBaseName[cur_policy]

  let asset_name_part = asset_id.slice(56,)

  let serial;

  if (asset_name_part.startsWith('000643b0') || asset_name_part.startsWith('000de140')){
    serial = ''
  }else{
    serial = Buffer.from(asset_name_part,'hex').toString('utf8').slice(baseName.length,)
  }

  

  return serial

}


function displayPrice(price){
  let ada_price = price['ada_price']
  let clay_price = price['clay_price']

  if (ada_price > 5000000){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
      <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Cardano_white.svg' style={{width:'25px', zIndex:'0'}} />
        {displayAmount((ada_price/1000000), 2)}
      </div>
    )
  }else if(clay_price > 0){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
        <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'25px', zIndex:'0'}} />
        {displayAmount((clay_price/10000), 2)}
      </div>
      )
  }
}


  function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
}


useEffect(()=>{

},[])


function openSellPopUp(assetInfo){
  setSellAsset(assetInfo)
}


function get_image_url(ipfs){
  //return "https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/"+ipfs+".png"
  try{
    ipfs = ipfs.split('ipfs://').join('')
    return "https://claynation.nyc3.digitaloceanspaces.com/IPFS/360x360/"+ipfs+".png"
  }catch{

  }
  
}



function ImageDisplay(props){

  const image_url = props.image_url 

  const [imageLoaded, setImageLoaded] = useState(false)


  return(

    <div >
    {!imageLoaded ? <Skeleton style={{
    width:'50%',
    height:'440px',
    marginTop:'-95px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
     
    }}>

    <div className={cardCollection.toUpperCase() + '-clay-nft'}>

    </div>


      </Skeleton> 


    : null}


      <div>
          <img className={ imageLoaded ? cardCollection.toUpperCase() + '-clay-nft' : ''} style={!imageLoaded ? { display: 'none' } : {}} src={get_image_url(asset_info.meta.image)} onLoad={()=>{setImageLoaded(true)}} />
      </div>

    </div>



  )
}


function displayPantsName(){
   try{
        return asset_info.meta.type
   }catch{
    return ''
  }
                      
  return
}


function SandboxPin(){

  const [upgradeStatus, setUpgradeStatus] = useState('')
  

  useEffect(() => {

    

    let upgraded = false

    try{
      upgraded = asset_info.upgraded 
    }catch{
      //ignore
    }

    if (upgraded){
      setUpgradeStatus('upgraded')
    }else{
      setUpgradeStatus('locked')
    }
    
    // 
    // setUpgradeStatus('undefined')

  },[])


  return(
    <>
    {upgradeStatus !== 'undefined' ?
      <div style={{position:'absolute', top:'0px', right:'0px'}}>
        <StyledTooltip title={<span style={{ fontFamily: "ShortStack" }}>{upgradeStatus === 'upgraded' ? 'NFT UPGRADED' : 'NOT UPGRADED'}</span>} placement="top">

        <img 
                    src={
                      upgradeStatus === 'upgraded' ? unlockedpin
                      : lockedpin
                    }

                    style={{width:'50px', height:'auto'}}

                    alt="token"/>
         </StyledTooltip>
      </div>
    : null}
    </>
  )
}




function AssetProperties(props){

    let collection = props.collection

    if (collection === 'pants'){
      collection = 'cn'
    }

    let meta = props.meta


    function displayProp(category, trait){

        let rarity = ''
        let totalAssets = 1
        let rarity_dict = {}

        if (collection == 'cn'){ 
            rarity_dict = cn_rarity
            totalAssets = 10000
        }else if (collection == 'gc'){
            rarity_dict = gc_rarity
            totalAssets = 9427
        }

        if (category in rarity_dict && trait in rarity_dict[category]){
            rarity = rarity_dict[category][trait]
        }

        if (rarity && totalAssets > 1){
            rarity = Math.round( (rarity / totalAssets) * 10000 ) / 100
            rarity = '(' + rarity + '%)'
        }


        return(
            <span style={{overflowWrap: 'break-word',textAlign:'center', width:'200px'}}>

                <span>
                    {trait + ' ' + rarity} 
                </span>

            </span>
        )

    }


    return(


                <div className={cardCollection.toUpperCase() + "-properties"} style={{overflow:'scroll',  maxHeight:'125px', marginTop:'0px',backgroundColor:'', paddingTop:'6px', paddingBottom:'6px', backgroundColor:''}}>
                             
                        {Object.keys(meta).map(element => (
                            element == 'image' || element == 'collection' ? null :

                            <div key={asset_id + element + meta[element]} style={{marginTop:'2px', marginBottom:'4px'}}>
                                <span className={collection.toUpperCase()+ "-clay-properties"}>{element}</span> 
                                <span className={collection == 'pitches' ? "CN-clay-percentage" :  collection.toUpperCase() + "-clay-percentage"}>{displayProp(element, meta[element] ? meta[element] : 'None')}</span>
                            </div>
                                        
                           ))}
                                             
                            

                </div>
       
    )

}




  return (
   <ThemeProvider theme={theme}>
    <div className={cardCollection.toUpperCase() + "-flip-card"} style={{backgroundColor:'', 
    transform: 'scale(0.92)', marginTop:'0px', marginBottom:'-35px'}}>
      

      {/*
      <Card boxShadow={cardThemes[collection].boxShadow}>
        <img src={'./'+ collection + '_CARD.svg'} style={{height:'446px', marginTop:'-31px', marginLeft:'-31px'}} />

        <div style={{position:'absolute', top:'0px', display:'flex', flexDirection:'column', alignElements:'center', width:'100%', height:'10px'}}>

          <div>
              <img className={'clay-nft'} src={get_image_url(asset_info.image)} />
          </div>

        </div>

      </Card>
    */}

      
<div className={ cardFlipped || sellAsset && sellAsset.asset_id == asset_id ? cardCollection.toUpperCase() + "-flippedCard" : cardCollection.toUpperCase() +"-flip-card-inner"}
    

        onClick={(event)=>{
          event.stopPropagation()

          //console.log('CLICK...')

          setCardFlipped(!cardFlipped)
          
           
          

            /*
           openSellPopUp({
                         asset_id : asset_id,
                         image: get_image_url(asset_info.meta.image),
                         meta: asset_info.meta,
                         collection: cardCollection,
                         assetStatus: assetStatus,
                         tx_hash: tx_hash
                       })
            */

        }}>

          <div className={cardCollection.toUpperCase() + "-flip-card-front"}>
            <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+ cardCollection.toUpperCase() + '_CARD.svg'} style={{height:'446px', marginTop:'-31px', marginLeft:'-31px'}} />

            <div style={{position:'absolute', top:'0px', display:'flex', flexDirection:'column',alignElements:'center', width:'100%', height:'10px'}}>
        

             
              <div>
                <img className={cardCollection.toUpperCase() + '-clay-nft'} src={get_image_url(asset_info.meta.image)} />

                {cardCollection === 'cn' && cardCollections[policy_id] !== 'pants' ?

                  <SandboxPin />
                  
                : null}
              </div>
              



              {/*
              <ImageDisplay image_url={get_image_url(asset_info.meta.image)} />
              */}




              <div style={{position:'relative'}} style={{backgroundColor:''}}>

              {cardCollections[policy_id] !== 'pants'?
                <div className={cardCollection.toUpperCase() + "-clay-id"}>
                  {cardCollection.toUpperCase()}#{displayName(asset_id)}
                </div>
                : 

                <div className={cardCollection.toUpperCase() + "-clay-id"}>


                  {/*<div>
                    {() => {

                      try{
                        return asset_info.meta.name.split('Trousers').join(' ').split(' ').slice(0, 3).join(' ')
                      }catch{
                        return ''
                      }
                      
                    }}
                  </div>*/}

                <div>
                  {displayPantsName()}
                </div>

                  <DisplayAssetType name={asset_info.meta.name} />

                </div>

              }
              </div>
            </div>


          </div>


           <div className={cardCollection.toUpperCase()+"-flip-card-back"} style={{overflow:'scroll', display:'flex', flexDirection:'column', 
                                                      justifyContent:'start'}}>

              <div style={{backgroundColor:''}}>
               <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'5px', backgroundColor:''}}>
                
               {cardCollections[policy_id] !== 'pants'?
                <div>

                  
                  <div className={cardCollection.toUpperCase() + "-collection-back"}> {cardCollection.toUpperCase()} </div>
                    <div className={cardCollection.toUpperCase() + "-clay-id-back"}>#{displayName(asset_id)}</div>
  
                  </div>

                  :
                  <div>
                    <div className={cardCollection.toUpperCase() + "-collection-back"}> {asset_info.meta.name?.includes('Pants') ? asset_info.meta.name : asset_info.meta.name } </div>
                    <div className={cardCollection.toUpperCase() + "-clay-id-back"}></div>
                  </div>
                  
                  }
                </div>



              <img className={cardCollection.toUpperCase() + '-clay-nft-back'} src={get_image_url(asset_info.meta.image)} />
             </div>



           {/* PROPERTIES */}

           {asset_info && asset_info.meta ?
           <AssetProperties meta={asset_info.meta} collection={cardCollection} />
           : null}


           {/*
             <div className={cardCollection.toUpperCase() + "-properties"} style={{overflow:'scroll',  maxHeight:'125px', marginTop:'0px',backgroundColor:'', paddingTop:'6px', paddingBottom:'6px', backgroundColor:''}}>
                
                {asset_info && asset_info.meta ? (
                  Object.keys(asset_info.meta).map(element => (

                element == 'image' ? null :

                <div key={asset_id + element + asset_info.meta[element]} style={{marginTop:'2px'}}>
                  <span className={cardCollection.toUpperCase() + "-clay-properties"}>{element}</span> 
                  <span className={cardCollection.toUpperCase() + "-clay-percentage"}>{asset_info.meta[element]}</span>
                </div>
                
                ))
                     
                  ) : null}

            </div>*/}


            {/*<div>-----------------------------------</div>*/}

            </div>
    

                {/*BOTTOM PART*/}

                <div className="cardLow" style={{position:'relative',top:'300px'}}>

                {/* ACCUMULATED TOKEN */}
                {asset_info.accumulatedToken != 0 && cardCollections[policy_id] !== 'pants' ? 
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2px', height:'30px', backgroundColor:''}}>
                  <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/TokenSpace.png' style={{position:'absolute', width:'110px', marginLeft:'0px'}} />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'20px', marginRight:'2px', zIndex:'100'}} />
                     <span className={cardCollection.toUpperCase() + "-tokens"}>{asset_info.accumulatedToken > 0 ? asset_info.accumulatedToken : 'baking'}</span>
                    </div>
                </div>

                : 


                <div style={{height:'30px'}}></div>}


                {/* SELL BUTTON / PRICE */}
                {assetStatus == 'sellable' ?
                  <div style={{ position:'relative',display:'flex', justifyContent:'center', marginTop:'0px', marginBottom:'0px', width:'100%', backgroundColor:'', zIndex:'100'}}>
          
                    <button className={cardCollection.toUpperCase()+"-SellBtn"} style={{transform: 'scale(0.75)', zIndex:'100'}} onClick={(event)=>{
                      event.stopPropagation()


                      
                      openSellPopUp({
                         asset_id : asset_id,
                         image: get_image_url(asset_info.meta.image),
                         meta: asset_info.meta,
                         collection: cardCollection,
                         assetStatus: assetStatus,
                         address:asset_info.address,
                         owner_stake_address:asset_info.owner_stake_address,
                         accumulatedToken: asset_info.accumulatedToken
                       })
                      

                    }}>

                      <ButtonText style={{fontSize:'18px'}}>
                        SELL 
                      </ButtonText>
                    </button>
                  </div>


                  
                    : assetStatus == 'listed'  ?

                    <div onClick={(event)=>{

                      openSellPopUp({
                         asset_id : asset_id,
                         image: get_image_url(asset_info.meta.image),
                         meta: asset_info.meta,
                         collection: cardCollection,
                         assetStatus: assetStatus,
                         tx_hash: tx_hash,
                         assetPrice: asset_info.assetPrice,
                         address:asset_info.address,
                         owner_stake_address:asset_info.owner_stake_address,
                         accumulatedToken: asset_info.accumulatedToken
                       })

                    }}>
                     <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={priceCurrency === 'clay' ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+cardCollection.toUpperCase()+'_PRICE_TAG.svg' : priceCurrency ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/price_tag_cardano.png' : ''} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px', 
                                      width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            
                            <div className={"CN-ADA-back"}>
                              <div style={{color:'white'}}>{displayPrice(asset_info.assetPrice)}</div> 
                            </div>

                            <div className={"BUY-NFT"}>
                              BUY NFT
                            </div>
                          </div>
                      </div>
                      </div>

                    </div>


                    :  assetStatus == 'not listed' ?

      
                    <div onClick={(event)=>{

                      {/*
                      openSellPopUp({
                         asset_id : asset_id,
                         image: get_image_url(asset_info.meta.image),
                         meta: asset_info.meta,
                         collection: cardCollection,
                         assetStatus: assetStatus
                       })
                     */}

                    }}>



                    {/*
                    <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                       

                        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'20px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"BUY-NFT"}>
                              MAKE OFFER
                            </div>
                        </div>


                      </div>

                    </div>
                  */}


                  <div style={{display:'flex', justifyContent:'center',width:'100%'}}>

                    <div style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px', marginRight:'160px'}}>
                         <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                    </div>

                  </div>

                    </div>


                    :


                    /* UPDATE | DELIST */
                    assetStatus == 'updatable' ?

                    <div onClick={(event)=>{

                      event.stopPropagation()
                      

          
                      openSellPopUp({
                         asset_id : asset_id,
                         image: get_image_url(asset_info.meta.image),
                         meta: asset_info.meta,
                         collection: cardCollection,
                         assetStatus: assetStatus,
                         tx_hash: tx_hash,
                         assetPrice: asset_info.assetPrice,
                         address:asset_info.address,
                         owner_stake_address:asset_info.owner_stake_address,
                         accumulatedToken: asset_info.accumulatedToken
                       })

       

                    }}>
                     <div style={{display:'flex', justifyContent:'center',width:'100%'}}>
                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={priceCurrency === 'clay' ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+cardCollection.toUpperCase()+'_PRICE_TAG.svg' : priceCurrency ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/price_tag_cardano.png' : ''} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <span style={{color:'white'}}>{displayPrice(asset_info.assetPrice)}</span> 
                            </div>

                            <div className={"BUY-NFT"}>
                              UPDATE | DELIST
                            </div>

                          </div>
                      </div>

                    </div>
                    </div>

                   : null}

                  </div>

         </div>

          {/*
          

           <div className={cardCollection + "-flip-card-back"} style={{overflow:'scroll', display:'flex', flexDirection:'column', 
                                                      justifyContent:'space-between'}}>

           <div style={{backgroundColor:''}}>
           <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'5px', backgroundColor:''}}>
            <div>
              <div className={cardCollection + "-collection-back"}> {collection.toUpperCase()} </div>
              <div className={cardCollection + "-clay-id-back"}>#4020</div>
            </div>
          </div>

          <img className={cardCollection + '-clay-nft-back'} src={image_url} />
          </div>

              <div className={cardCollection + "-properties"} style={{overflow:'scroll',  maxHeight:'200px', marginTop:'0px',backgroundColor:'', paddingTop:'6px', paddingBottom:'6px', backgroundColor:''}}>
                {meta ? (
                  meta.map(element => (
                <div key={collection_abbr + serial + element.category + meta.indexOf(element)} style={{marginTop:'2px'}}>
                  <span className={cardCollection + "-clay-properties"}>{element.category}:</span> 
                  <span className={cardCollection + "-clay-percentage"}>{element.trait} {element.rarity}</span>
                </div>
                ))
                     
                  ) : null}

              </div>

              <div style={{position:'relative', display:'flex', flexDirection:'column', justifyContent:'end', alignItems:'center',marginTop:'0px', width:'100%', backgroundColor:''}}>
                  
                  <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'8px', height:'30px'}}>
                  <img src='./TokenSpace.png' style={{position:'absolute', width:'110px', marginLeft:'0px', backgroundColor:''}} />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     <img src='./CLAY_TOKEN_220x220.png' style={{width:'20px', marginRight:'2px', zIndex:'100'}} />
                     <span className={cardCollection + "-tokens"}> 6582 </span>
                    </div>
                  </div>



                {assetStatus == 'sellable' ?
                  <div style={{ position:'relative',display:'flex', justifyContent:'center', marginTop:'0px', marginBottom:'0px', width:'100%', backgroundColor:'', zIndex:'100'}}>
          
                    <button className={cardCollection+"-SellBtn"} style={{transform: 'scale(0.75)', zIndex:'100'}} onClick={(event)=>{
                      event.stopPropagation()

                      openSellPopUp({
                         asset_id : asset_id,
                         image: image_url,
                         meta: meta,
                         collection: collection_abbr,
                         assetStatus: assetStatus
                       })

                    }}>

                      <ButtonText style={{fontSize:'18px'}}>
                        SELL 
                      </ButtonText>
                    </button>
                  </div>


                  
                    : assetStatus == 'listed'  ?

                    <div onClick={(event)=>{

                      event.stopPropagation()
                      console.log('BUY')

                    }}>
                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./'+cardCollection+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <img src='./CLAY_TOKEN_220x220.png' style={{width:'28px', zIndex:'100'}} />
                              <span style={{color:'white'}}>{assetPrice}</span> 
                            </div>

                            <div className={"BUY-NFT"}>
                              BUY NFT
                            </div>
                          </div>
                      </div>

                    </div>



                    :  assetStatus == 'not listed' ?

      
                    <div onClick={(event)=>{

                      event.stopPropagation()
                      console.log('MAKE OFFER')

                    }}>

                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                               <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'20px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"BUY-NFT"}>
                              MAKE OFFER
                            </div>
                          </div>
                      </div>

                    </div>


                    :

                    assetStatus == 'updatable' ?

                    <div onClick={(event)=>{

                      event.stopPropagation()
                      console.log('BUY')

                    }}>
                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./'+cardCollection+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <img src='./CLAY_TOKEN_220x220.png' style={{width:'28px', zIndex:'100'}} />
                              <span style={{color:'white'}}>{assetPrice}</span> 
                            </div>

                            <div className={"BUY-NFT"}>
                              UPDATE | DELIST
                            </div>
                          </div>
                      </div>

                    </div>


                   : null}


              </div>
           </div>
        </div>
    

    */}





    {/*

     <div className={cardCollection + "-flip-card"} style={{backgroundColor:''}}>
        <div className={sellAsset && sellAsset.asset_id == asset_id ? cardCollection + "-flippedCard" : cardCollection +"-flip-card-inner"} style={{backgroundColor:''}} onClick={(event)=>{
          event.stopPropagation()


          openSellPopUp({
                         asset_id : asset_id,
                         image: image_url,
                         meta: meta,
                         collection: collection_abbr,
                         assetStatus: assetStatus
                       })
        }}>
          <div className={cardCollection + "-flip-card-front"}>

            <img src={'./'+ cardCollection + '_CARD.svg'} style={{height:'446px', marginTop:'-31px', marginLeft:'-31px'}} />


            <div style={{position:'absolute', top:'0px', display:'flex', flexDirection:'column',alignElements:'center', width:'100%', height:'10px'}}>
        
              <div>
                <img className={cardCollection + '-clay-nft'} src={image_url} />
              </div>


              <div style={{position:'relative'}} style={{backgroundColor:''}}>

                  <div className={cardCollection + "-clay-id"}>{collection_abbr.toUpperCase()}#{serial}</div>

                  <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2px', height:'30px', backgroundColor:''}}>

                  <img src='./TokenSpace.png' style={{position:'absolute', width:'110px', marginLeft:'0px'}} />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     <img src='./CLAY_TOKEN_220x220.png' style={{width:'20px', marginRight:'2px', zIndex:'100'}} />
                     <span className={cardCollection + "-tokens"}> 6582 </span>
                    </div>
                  </div>

                  
                
                  {assetStatus == 'sellable' ?
                  <div className="buttonFront" style={{display:'flex', justifyContent:'center', marginTop:'0px', marginBottom:'0px', width:'100%'}}>
                    <div className={cardCollection+"-SellBtn"} style={{transform: 'scale(0.75)'}}>
                      <ButtonText style={{fontSize:'18px'}}>
                        SELL
                      </ButtonText>
                    </div>
                    </div>

                    : assetStatus == 'listed' || assetStatus == 'updatable' ?

                    
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2px', height:'30px', backgroundColor:''}}>
                    
                     <img src={'./'+cardCollection+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px'}} />
                      <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'22px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                        
                        <img src='./CLAY_TOKEN_220x220.png' style={{width:'28px', marginLeft:'5px', zIndex:'100'}} />
     
                        <div style={{color:'white', fontSize: '1.1rem', fontFamily:'ShortStack'}}>{assetPrice}</div> 

                      </div>
                   
                    </div>

                    :  assetStatus == 'not listed' ?

                     <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2px', height:'54px', backgroundColor:'', marginTop:'0px'}}>
                      <img src={'./NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                    </div> 

                    :

                    null}
                
                  </div>
              </div>
            </div>
          

           <div className={cardCollection + "-flip-card-back"} style={{overflow:'scroll', display:'flex', flexDirection:'column', 
                                                      justifyContent:'space-between'}}>

           <div style={{backgroundColor:''}}>
           <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'5px', backgroundColor:''}}>
            <div>
              <div className={cardCollection + "-collection-back"}> {collection.toUpperCase()} </div>
              <div className={cardCollection + "-clay-id-back"}>#4020</div>
            </div>
          </div>

          <img className={cardCollection + '-clay-nft-back'} src={image_url} />
          </div>

              <div className={cardCollection + "-properties"} style={{overflow:'scroll',  maxHeight:'200px', marginTop:'0px',backgroundColor:'', paddingTop:'6px', paddingBottom:'6px', backgroundColor:''}}>
                {meta ? (
                  meta.map(element => (
                <div key={collection_abbr + serial + element.category + meta.indexOf(element)} style={{marginTop:'2px'}}>
                  <span className={cardCollection + "-clay-properties"}>{element.category}:</span> 
                  <span className={cardCollection + "-clay-percentage"}>{element.trait} {element.rarity}</span>
                </div>
                ))
                     
                  ) : null}

              </div>

              <div style={{position:'relative', display:'flex', flexDirection:'column', justifyContent:'end', alignItems:'center',marginTop:'0px', width:'100%', backgroundColor:''}}>
                  
                  <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'8px', height:'30px'}}>
                  <img src='./TokenSpace.png' style={{position:'absolute', width:'110px', marginLeft:'0px', backgroundColor:''}} />
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', marginLeft:'-5px'}}>
                     <img src='./CLAY_TOKEN_220x220.png' style={{width:'20px', marginRight:'2px', zIndex:'100'}} />
                     <span className={cardCollection + "-tokens"}> 6582 </span>
                    </div>
                  </div>



                {assetStatus == 'sellable' ?
                  <div style={{ position:'relative',display:'flex', justifyContent:'center', marginTop:'0px', marginBottom:'0px', width:'100%', backgroundColor:'', zIndex:'100'}}>
          
                    <button className={cardCollection+"-SellBtn"} style={{transform: 'scale(0.75)', zIndex:'100'}} onClick={(event)=>{
                      event.stopPropagation()

                      openSellPopUp({
                         asset_id : asset_id,
                         image: image_url,
                         meta: meta,
                         collection: collection_abbr,
                         assetStatus: assetStatus
                       })

                    }}>

                      <ButtonText style={{fontSize:'18px'}}>
                        SELL 
                      </ButtonText>
                    </button>
                  </div>


                  
                    : assetStatus == 'listed'  ?

                    <div onClick={(event)=>{

                      event.stopPropagation()
                      console.log('BUY')

                    }}>
                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./'+cardCollection+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <img src='./CLAY_TOKEN_220x220.png' style={{width:'28px', zIndex:'100'}} />
                              <span style={{color:'white'}}>{assetPrice}</span> 
                            </div>

                            <div className={"BUY-NFT"}>
                              BUY NFT
                            </div>
                          </div>
                      </div>

                    </div>



                    :  assetStatus == 'not listed' ?

      
                    <div onClick={(event)=>{

                      event.stopPropagation()
                      console.log('MAKE OFFER')

                    }}>

                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./NOT_FOR_SALE.svg'} style={{position:'absolute', width:'160px', marginLeft:'0px', marginTop:'0px'}} />
                               <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'20px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"BUY-NFT"}>
                              MAKE OFFER
                            </div>
                          </div>
                      </div>

                    </div>


                    :

                    assetStatus == 'updatable' ?

                    <div onClick={(event)=>{

                      event.stopPropagation()
                      console.log('BUY')

                    }}>
                      <div className={"CN-price-tag-back"} style={{backgroundColor:'', width:'160px', height:'40px', marginTop:'5px', marginBottom:'5px'}}>
                         <img src={'./'+cardCollection+'_PRICE_TAG.svg'} style={{position:'absolute', width:'200px', marginLeft:'-40px', marginTop:'-20px', backgroundColor:''}} />
                          <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'60px', marginTop:'0px',width:'100%',marginLeft:'-5px', zIndex:'100', backgroundColor:''}}>
                            <div className={"CN-ADA-back"}>
                              <img src='./CLAY_TOKEN_220x220.png' style={{width:'28px', zIndex:'100'}} />
                              <span style={{color:'white'}}>{assetPrice}</span> 
                            </div>

                            <div className={"BUY-NFT"}>
                              UPDATE | DELIST
                            </div>
                          </div>
                      </div>

                    </div>


                   : null}


              </div>
           </div>
        </div>
     </div>

   */}


     
    </div>
     </ThemeProvider>
  )
}

export default AssetCard




