import styled from "styled-components";
import { Tooltip } from '@mui/material';

export const MenuWrapper = styled.div`
background: var(--panel-background);
box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25) inset;
border-radius: 0.7rem;
box-sizing: border-box;
align-items: center;
justify-content: center;
height: 68%;
width: 17%;
position: fixed;
bottom: 0;
@media screen and (max-width: 768px) {
    width: 100%;
    height: 12%;
    position: fixed;
    bottom: 0;
    background-color: rgba(26, 47, 73, 1);
    z-index: 10;
    border-radius: 4rem 4rem 0rem 0rem;
    outline: 7px solid rgba(0, 0, 0, 0.13);
    box-shadow: none;
}

`;


export const UL = styled.ul`
font-size: clamp(0.5rem, 1.4vw, 3rem);
list-style: none;
text-align: left;
display: flex;
flex-direction: column;
gap: 1.5rem;
padding: 1.5em 2rem;
text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
font-weight: 200;
font-family: 'ClayFont';
letter-spacing: 0.1rem;
@media (min-width: 769px) and (max-width: 1023px) {
    font-size: clamp(0.5rem, 1.2vw, 3rem);
    gap: 1.2rem;
    padding: 1.2rem 1rem;
    br {
        display: none;
    }
  }
@media screen and (max-width: 768px) {
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;    
    justify-content: center;
    img {
        width: 2rem;
        height: 2rem;
    }
    span {
        display: none;
    }
    br {
        display: none;
    }
@media screen and (max-width: 768px) and (orientation: landscape) {
    align-items: center; 
    justify-content: center;
    gap: 3rem;
}
`;

export const LI = styled.li`
position: relative;
display: inline-block;
cursor: pointer;

/* INACTIVE LINKS */
&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(8) {

    color: rgba(0, 0, 0, 0.4);
    text-shadow: none;
    font-weight: 600;
}

/*
&:nth-child(1):hover::before,
&:nth-child(2):hover::before,
&:nth-child(3):hover::before,
&:nth-child(4):hover::before,
&:nth-child(5):hover::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 33px;
    background-color: var(--claypink);
    border-radius: 5px;
    z-index: -1;
}
*/
&:nth-child(7)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 33px;
    background-color: var(--clayblue-primary);
    border-radius: 5px;
    z-index: -1;
}

@media screen and (max-width: 768px) {
    &:nth-child(1):hover::before,
    &:nth-child(2):hover::before,
    &:nth-child(3):hover::before,
    &:nth-child(4):hover::before,
    &:nth-child(5):hover::before,
    &:nth-child(7):hover::before,
    &:nth-child(8):hover::before  {
       background-color: transparent;
}
&:nth-child(7)::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120%;
    height: 33px;
    background-color: transparent;
    border-radius: 5px;
    z-index: -1;
}

}
`;

export const MenuIcon = styled.img`
padding-right: 0.5rem;
vertical-align: middle;
width: 1rem;
img:hover {
    filter: brightness(0.8) sepia(1) hue-rotate(200deg);
}
@media (min-width: 768px) and (max-width: 1023px) {
    width: 0.7rem;
  }
@media screen and (max-width: 768px) and (orientation: landscape){
    padding-right: 0rem;
}
`;


export const StyledTooltip = styled(props => (
    <Tooltip classes={{ popper: props.className }} {...props} />
  ))`
    & .MuiTooltip-tooltip {
      background-color: var(--claypink);
      font-family: 'ClayFont';
      font-size: clamp(0.5rem, 1vw, 3rem);
      font-weight: 400;
      padding: 0.3rem 0.6rem;
      border-radius: 0.5rem;
    }
  `;
