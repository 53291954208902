import {useState, useEffect, useContext} from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import * as msgSign from '@emurgo/cardano-message-signing-asmjs'

import {Buffer} from 'buffer'

import './Ledger.css'

import './Winner.css'

import { motion } from "framer-motion";

import {MainClaimContext} from 'components/ClaimContext'

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Buy from './Buy'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Raffle(props){


	const {winners, winnersStakeAddr, winnersStakeToAddr} = props

	const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


	const {stake_address} = useContext(MainClaimContext)

	const {activeWallet, address} = useContext(MainClaimContext)

	const {isAlertOpen, setIsAlertOpen, submittedTx, setSubmittedTx} = props

	const raffleAddress = 'addr1qx3g7c06lxqfdea9mxkfu8gvtujd2zjmyzj5h9e9gcwfmg6zxmhhg5fqzhp8685wc2pqc3t5y0zpj3pct833f55j5qdse7uqlm'

   const [smallScreen, setSmallScreen] = useState(false)
   const [mediumScreen, setMediumScreen] = useState(false)

   const [largeScreen, setLargeScreen] = useState(false)


   const [yourEntries, setYourEntries] = useState('-')
   const [currentEntries, setCurrentEntries] = useState('-')

   const [timeStatus, setTimeStatus] = useState('-')

   const [startTime, setStartTime] = useState(1692822016)

   //1692821391+604970+3600*5
   const [endTime, setEndTime] = useState(1693444361)

   const [price, setPrice] = useState(15)

   const [tickets, setTickets] = useState(1)

   const [totalAda, setTotalAda] = useState(15)


   const [ended, setEnded] = useState(false)
   const [started, setStarted] = useState(false)



   const [fullName, setFullName] = useState('')
   const [email, setEmail] = useState('')
   const [streetAddress, setStreetAddress] = useState('')
   const [province, setProvince] = useState('')
   const [country, setCountry] = useState('')
   const [postCode, setPostCode] = useState('')
   const [city, setCity] = useState('')

   const [phone, setPhone] = useState('')

   const [inputWrong, setInputWrong] = useState(false)

   const [isSubmitted, setIsSubmitted] = useState(false)



   const countdownStyle = {
        fontSize: '1.8rem',
        color: 'var(--clayyellow-secondary)',
        marginTop: '-40px',
        opacity: '0.8',

    }



    function getEpochEnd(time_end){


    if (!time_end){
      return
    }

    let now = Date.now()
    let time_dif = time_end - (now/1000)
    if (time_dif <= 60*60){
      if (time_dif <= 60){
        if (time_dif > 0){
           return '<1 minute'
         }else{
          return '0 minutes'
         }
       
      }else{
       return String(Math.floor(time_dif/60+1))+' minutes'
      }
    }else if (time_dif <= 60*60*24){
      if (time_dif <= 60*60){
        return '<1 hour'
      }else{
        let full_hours = Math.floor(time_dif/60/60)
        return String(full_hours) +'h ' + String(Math.floor((time_dif-full_hours*60*60)/60+1))+'m'
      }
    }else{
      let full_days = Math.floor(time_dif/60/60/24)
      let full_hours = Math.floor((time_dif - full_days * 60*60*24)/60/60)
      let full_minutes = Math.floor((time_dif - full_days * 60*60*24 - full_hours * 60 * 60)/60+1)
      return String(full_days) + 'd ' + String(full_hours) + 'h ' + String(full_minutes) + 'm'
    }

  }


  useEffect(() => {

  	setTotalAda(tickets * price)

  },[tickets])



    async function fetchRaffleEntries(){

	    let temp_stake_address;

	    if (stake_address == ''){
	      temp_stake_address = 'empty'
	    }else{
	      temp_stake_address = stake_address
	    }

	    let query_url = "https://claymarket.io/raffles/" + raffleAddress + '/' + temp_stake_address +'/'

	    const raffleEntriesInfo = await fetch(query_url).then(response => response.json())
	                                                                  .then(data => data)

	    if ('user_purchased' in raffleEntriesInfo){

	      setYourEntries(parseInt(raffleEntriesInfo['user_purchased'], 10))

	    }else{
	    	setYourEntries(0)
	    }                                                    

	    if ('total_purchased' in raffleEntriesInfo){
	      setCurrentEntries(parseInt(raffleEntriesInfo['total_purchased'], 10) )
	    }else{
	    	setCurrentEntries(0)
	    }

	  }


    useEffect(() => {

    	fetchRaffleEntries()

    	const interval = setInterval(() => {
            fetchRaffleEntries()
        }, 30000);
  
        //Clearing the interval
        return () => clearInterval(interval);

    },[stake_address])



    function updateTimeStatus(){

    	//console.log('update time status')

	  let now = Math.floor(Date.now() / 1000)

	  // console.log(now)
	  // console.log(endTime)
	  // console.log(startTime)
	      let time_str = ''

	      if (startTime && endTime){
	      	//console.log('here')

	        if (now > startTime){
	        	//console.log('a')

	          if (now < endTime){
	          	//console.log('b')


	            let end_str = getEpochEnd(endTime)

	            time_str = 'ENDS IN: ' + end_str
	            setTimeStatus(time_str)

	            setEnded(false)

	          }else{  

	            setEnded(true)
	            setTimeStatus('Raffle Ended')

	          }

	        }else{

	        	//console.log('here 2')

	          let start_str = getEpochEnd(startTime)

	            time_str = 'STARTS IN: ' + start_str
	            setTimeStatus(time_str)

	            //console.log(time_str)


	            setStarted(false)

	            setEnded(false)
	        }
	    }
	}




useEffect(()=>{


  updateTimeStatus()

  const interval = setInterval(() => {
            updateTimeStatus()
   }, 10000);
  
   //Clearing the interval
   return () => clearInterval(interval);


},[startTime, endTime])


   function handleResize(){
      //console.log(window.innerWidth)

      let cur_width = window.innerWidth

      if (cur_width > 992){
         setLargeScreen(true)
         setMediumScreen(false)
         setSmallScreen(false)
      }else if (cur_width > 600){
         setLargeScreen(false)
         setMediumScreen(true)
         setSmallScreen(false)
      }else{
         setLargeScreen(false)
         setMediumScreen(false)
         setSmallScreen(true)
      }
   }


   useEffect(() => {
     window.addEventListener("resize", handleResize)

     return () => {
      window.removeEventListener("resize", handleResize)
     }
   })


   useEffect(() => {

      handleResize()

   },[])



  async function signMessageCardano(cardano, address, message){
  	let encoded_message_string = Buffer.from(message).toString('hex')
    let addr_ptr = serLib.Address.from_bech32(address)
    let address_encoded = Buffer.from(addr_ptr.to_bytes()).toString('hex')
    let access_signature = await cardano.signData(address_encoded, encoded_message_string)
    
    return access_signature
  }


   async function signMessagePolygon(polygon, address, message){
     const from = address
      const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
      const sign = await polygon.request({
        method: 'personal_sign',
        params: [msg, from],
      });

      return sign
  }



  async function postSignedData(
  	address,
		signature,
		message
	){

    const data = {'address':address, 'signature':signature, 'message': message}

   	const response = await fetch('https://claymarket.io/raffles/prize/claim/', {
   		method: 'POST',
      mode: 'cors',
      headers: {
      	'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    
    return response.json()

  }






   async function submitAddressForm(){
   	console.log('submit address form')

   	if (fullName === '' || email === '' || streetAddress === '' || country === '' || city === '' || phone === ''){
   		setInputWrong(true)
   		return
   	}else{
   		setInputWrong(false)
   	}

   	let address_data = {
   		fullName: fullName,
   		email: email,
   		streetAddress: streetAddress,
   		country: country,
   		province: province,
   		postCode: postCode,
   		city: city,
   		phone: phone
   	}

   	//console.log(address_data)

   	let address_data_str = JSON.stringify(address_data)

   	let signature = ''

   	let sign_address = address

   	if (activeWallet === 'metamask'){

   		let polygon = window.ethereum
          let requestedAcoounts = await polygon.request({ method: "eth_requestAccounts" });
          let cur_networkVersion = parseInt(polygon.networkVersion, 10)

          //console.log(cur_networkVersion)

        if (cur_networkVersion !== 137){
        	notifyWarn('Wrong Network!')
          return
        }

        let signed_message = await signMessagePolygon(polygon, sign_address, address_data_str)

        //console.log(signed_message)

        signature = String(signed_message)

   	}else{
   		let cardano = window.cardano[activeWallet]
   		let walletAPI = await cardano.enable()

   		if (!winners.includes(address) && winnersStakeAddr.includes(stake_address)
   			&& winnersStakeToAddr.hasOwnProperty(stake_address)
   		){

   			sign_address = winnersStakeToAddr[stake_address]

   			console.log('update sign address')
   			console.log(sign_address)

   		}

   		let signed_message = await signMessageCardano(walletAPI, sign_address, address_data_str)

   		signature = signed_message.signature

   		//console.log(signature)
   	}

   	//console.log(address_data_str)


		let submitStatus = await postSignedData(
			sign_address,
			signature,
			address_data_str
		)   

		console.log(submitStatus)	

		if (!submitStatus.hasOwnProperty('error')){
			setIsSubmitted(true)
		}


   	//address_data_hash = Buffer.from(serLib.hash_auxiliary_data(aux_data).to_bytes()).toString('hex')
   	//let aux_data = serLib.AuxiliaryData.from_json(address_data)
   	//console.log(address_data_hash)
   	//need to confirm this data belongs to the winner

  }



	return(
		<>

			{
				largeScreen ?


				<div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>


					


					<div style={{width:'100%', height:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>

						 <motion.div className="form-container"
					        transition={{ duration: 1.2, delay: 0.7  }}
					        initial={{ x:1000, opacity:0 }}
					        animate={{ opacity:0.95, x:0 }}

					        style={{minHeight:'750px'}}

					        >


					        				
					                 <div className="form-inputs">

					                   {!isSubmitted ?

					                   <>
						                    <h1>CLAIM YOUR PRIZE!</h1>
						                    <form className='winner-form'>
						                    <label>FULL NAME
						                    <input type="text" id="fname" name="firstname" required placeholder=""
						                    	value={fullName} onChange={(event)=>{setFullName(event.target.value)}}

						                    	style={inputWrong && fullName === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>EMAIL
						                    <input type="text" 
						                    	value={email} onChange={(event)=>{setEmail(event.target.value)}}

						                    	style={inputWrong && email === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>ADDRESS
						                    <input type="text" id="faddress" name="address" required  placeholder=""
						                     value={streetAddress} onChange={(event)=>{setStreetAddress(event.target.value)}}

						                     style={inputWrong && streetAddress === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>


						                    <form className='address-form'>
						                    <label>COUNTRY
						                    <input type="text" id="fcountry" name="country" required  placeholder=""
						                    	value={country} onChange={(event)=>{setCountry(event.target.value)}}

						                    	style={inputWrong && country === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>Province/State
						                    <input type="text" id="fstate" name="state" required  placeholder=""
						                    	value={province} onChange={(event)=>{setProvince(event.target.value)}}
						                    />
						                    </label>
						                    </form>


						                    <form className='address-form'>
						                    <label>POSTCODE
						                    <input type="text" id="fpostcode" name="postcode" required  placeholder=""
						                    	value={postCode} onChange={(event)=>{setPostCode(event.target.value)}}
						                    />
						                    </label>
						                    <label>CITY
						                    <input type="text" id="fcity" name="city" required  placeholder=""
						                    	value={city} onChange={(event)=>{setCity(event.target.value)}}

						                    	style={inputWrong && city === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>

						                    <form className='address-form'>
						                    
						                    <label>PHONE
						                    <input type="text" id="fphone" name="phone" required  placeholder=""
						                    	value={phone} onChange={(event)=>{setPhone(event.target.value)}}

						                    	style={inputWrong && phone === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>

						                    {/*<form className='submit-btn'>*/}
						                    <input type="submit" value="SUBMIT"
						                    	onClick={()=>{
						                    		submitAddressForm()
						                    	}}
						                    ></input>
						                    {/*</form>*/}
						                    <p style={{ fontStyle: 'italic', fontFamily: 'ShortStack', fontSize:'calc(4px + 1vmin)', paddingLeft: '10%', color: 'rgba(255, 255, 255, 0.6)', paddingTop: '1%', }}>All personal data collected will be deleted once prizes have been fulfilled. </p>   
						                </> 
						                

						                : 

						                	<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
						                		<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
							            				<h1 style={{fontSize:'55px'}}>Submitted!</h1>
							            				<img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{width:'130px', height:'auto'}} />
						                 		</div>
						                  </div>

						                }

						                </div>

						                
					               


					              
					                <div className="form-images">
					                	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					                </div>
					              

					        <div style={{width:'20%', height:'0px', backgroundColor:'yellow',
					                	position:'absolute', top:'-2%', right:'10%', zIndex:'-1'
					            	}}>




					            	<motion.img
						    transition={{ duration: 0.5, delay: 3 }}
						    initial={{ y:100, opacity:0 }}
						    animate={{ opacity:0.95, y:0 }}
						    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
						    alt="logo" className="logo" style={{width:'100%', height: 'auto', marginTop:'-250px', zIndex:''}}/>

					        </div>

					        

					        </motion.div>


					</div>



					

				</div>

				


				: mediumScreen || smallScreen ?

				<div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
				
				<div style={{width:'90%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', backgroundColor:''}}>

					<div style={{width:'100%', height:'100%', backgroundColor:'', marginTop: '40px'}}>
						




						{mediumScreen ?

						<motion.div className="form-container" style={{width:'100%'}} 
					        transition={{ duration: 1.2, delay: 0.7  }}
					        initial={{ x:1000, opacity:0 }}
					        animate={{ opacity:0.95, x:0 }}

					        style={{minHeight: '720px', width:'100%'}}
					        >

					                 <div className="form-inputs">

					                 		{!isSubmitted ?
					                 			<>
						                    <h1>CLAIM YOUR PRIZE!</h1>
						                    <form className='winner-form'>
						                    <label>FULL NAME
						                    <input type="text" id="fname" name="firstname" required placeholder=""
						                    	value={fullName} onChange={(event)=>{setFullName(event.target.value)}}

						                    	style={inputWrong && fullName === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>EMAIL
						                    <input type="text" 
						                    	value={email} onChange={(event)=>{setEmail(event.target.value)}}

						                    	style={inputWrong && email === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>ADDRESS
						                    <input type="text" id="faddress" name="address" required  placeholder=""
						                     value={streetAddress} onChange={(event)=>{setStreetAddress(event.target.value)}}

						                     style={inputWrong && streetAddress === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>


						                    <form className='address-form'>
						                    <label>COUNTRY
						                    <input type="text" id="fcountry" name="country" required  placeholder=""
						                    	value={country} onChange={(event)=>{setCountry(event.target.value)}}

						                    	style={inputWrong && country === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>Province/State
						                    <input type="text" id="fstate" name="state" required  placeholder=""
						                    	value={province} onChange={(event)=>{setProvince(event.target.value)}}
						                    />
						                    </label>
						                    </form>


						                    <form className='address-form'>
						                    <label>POSTCODE
						                    <input type="text" id="fpostcode" name="postcode" required  placeholder=""
						                    	value={postCode} onChange={(event)=>{setPostCode(event.target.value)}}
						                    />
						                    </label>
						                    <label>CITY
						                    <input type="text" id="fcity" name="city" required  placeholder=""
						                    	value={city} onChange={(event)=>{setCity(event.target.value)}}

						                    	style={inputWrong && city === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>

						                    <form className='address-form'>
						                    
						                    <label>PHONE
						                    <input type="text" id="fphone" name="phone" required  placeholder=""
						                    	value={phone} onChange={(event)=>{setPhone(event.target.value)}}

						                    	style={inputWrong && phone === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>

						                    {/*<form className='submit-btn'>*/}
						                    <input type="submit" value="SUBMIT"
						                    	onClick={()=>{
						                    		submitAddressForm()
						                    	}}
						                    ></input>
						                    {/*</form>*/}
						                    <p style={{ fontStyle: 'italic', fontFamily: 'ShortStack', fontSize:'calc(4px + 1vmin)', paddingLeft: '10%', color: 'rgba(255, 255, 255, 0.6)', paddingTop: '1%', }}>All personal data collected will be deleted once prizes have been fulfilled. </p>   
						                    
						                    </>

						                    : 

							                   <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
							                		<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
								            				<h1 style={{fontSize:'50px'}}>Submitted!</h1>
								            				<img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{width:'120px', height:'auto'}} />
							                 		</div>
							                  </div>

						                  }

						                </div>

						                


					              
					                <div className="form-images">
					                	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					                </div>
					              

					        <div style={{width:'20%', height:'0px', backgroundColor:'',
					                	position:'absolute', top:'-2%', right:'10%', zIndex:'-1'
					            	}}>




					            	<motion.img
								    transition={{ duration: 0.5, delay: 3 }}
								    initial={{ y:100, opacity:0 }}
								    animate={{ opacity:0.95, y:0 }}
								    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} 
								    alt="logo" className="logo" style={{width:'100%', height: 'auto', marginTop:'-250px', zIndex:''}}/>

							        </div>

					        

					        </motion.div>

					        : 

     						/*SMALL*/

					        <motion.div className="form-container-sm" style={{width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} 
					        transition={{ duration: 1.2, delay: 0.7  }}
					        initial={{ x:1000, opacity:0 }}
					        animate={{ opacity:0.95, x:0 }}>




					        	<div className="form-images-sm">
					               	<img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
					            </div>
					              

					        	<div style={{width:'20%', height:'0px', backgroundColor:'',
					                	position:'absolute', top:'1%', right:'10%', zIndex:'-1'
					            }}>



							    </div>


							     <div className="form-container" style={{width:'100%', borderRadius:'0px', minHeight: '640px'}}>
					           



					           
							     	 <div className="form-inputs" style={{ minHeight: '640px', borderRadius:'0px', 
							     	 borderBottomLeftRadius:'40px', borderBottomRightRadius:'40px', height:'530px'}}>
						                  
						                  {!isSubmitted ?    
						                    <>
						                    <h1>CLAIM YOUR PRIZE!</h1>
						                    <form className='winner-form'>
						                    <label>FULL NAME
						                    <input type="text" id="fname" name="firstname" required placeholder=""
						                    	value={fullName} onChange={(event)=>{setFullName(event.target.value)}}

						                    	style={inputWrong && fullName === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>EMAIL
						                    <input type="text" 
						                    	value={email} onChange={(event)=>{setEmail(event.target.value)}}

						                    	style={inputWrong && email === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>ADDRESS
						                    <input type="text" id="faddress" name="address" required  placeholder=""
						                     value={streetAddress} onChange={(event)=>{setStreetAddress(event.target.value)}}

						                     style={inputWrong && streetAddress === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>


						                    <form className='address-form'>
						                    <label>COUNTRY
						                    <input type="text" id="fcountry" name="country" required  placeholder=""
						                    	value={country} onChange={(event)=>{setCountry(event.target.value)}}

						                    	style={inputWrong && country === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    <label>Province/State
						                    <input type="text" id="fstate" name="state" required  placeholder=""
						                    	value={province} onChange={(event)=>{setProvince(event.target.value)}}
						                    />
						                    </label>
						                    </form>


						                    <form className='address-form'>
						                    <label>POSTCODE
						                    <input type="text" id="fpostcode" name="postcode" required  placeholder=""
						                    	value={postCode} onChange={(event)=>{setPostCode(event.target.value)}}
						                    />
						                    </label>
						                    <label>CITY
						                    <input type="text" id="fcity" name="city" required  placeholder=""
						                    	value={city} onChange={(event)=>{setCity(event.target.value)}}

						                    	style={inputWrong && city === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>

						                    <form className='address-form'>
						                    
						                    <label>PHONE
						                    <input type="text" id="fphone" name="phone" required  placeholder=""
						                    	value={phone} onChange={(event)=>{setPhone(event.target.value)}}

						                    	style={inputWrong && phone === '' ? {outline:  `3px solid red`} : {}}
						                    />
						                    </label>
						                    </form>

						                    {/*<form className='submit-btn'>*/}
						                    <input type="submit" value="SUBMIT"
						                    	onClick={()=>{
						                    		submitAddressForm()
						                    	}}
						                    ></input>
						                    {/*</form>*/}
						                    <p style={{ fontStyle: 'italic', fontFamily: 'ShortStack', fontSize:'calc(4px + 1vmin)', paddingLeft: '10%', color: 'rgba(255, 255, 255, 0.6)', paddingTop: '1%', }}>All personal data collected will be deleted once prizes have been fulfilled. </p>   
						                   </>  
						                	: 

						                	<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>
						                		<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
							            				<h1 style={{fontSize:'50px'}}>Submitted!</h1>
							            				<img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{width:'120px', height:'auto'}} />
						                 		</div>
						                  </div>
						                }

						                </div>

						                

					          </div>

					       
					        </motion.div>


					        /*SMALL END*/
					    }





					</div>

					<div style={{width:'100%', height:'100%', backgroundColor:''}}>
						


					</div>

				</div>

				</div>



				: null

			}

		</>
	)
}

export default Raffle;