import styled from "styled-components";
import { motion } from "framer-motion";


export const ModalWrapper = styled(motion.div)`
    width: 50%;
    height: 66%;
    background: var(--background);
    outline: 9px solid rgba(0, 0, 0, 0.2);
    border-radius: 3.5rem;
    contain: content;
    position: absolute;
    top: 25%;
    @media screen and (max-width: 768px) {
        width: 80%;
        height: 70%;
        border-radius: 2.5rem;
        overflow-y: scroll;
        top: 22%;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        border-radius: 2rem;
        top: 12%;
        right: 7%;
        width: 70%;
        height: 80%;
    }
`;

export const ModalDefault = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0.5rem;
    @media screen and (max-width: 768px) and (orientation: landscape) {
        padding: 0;
    } 
    @media screen and (max-width: 768px) and (orientation: landscape) {
        height: 140%;
    }  
`;


export const H1 = styled.h1`
font-size: clamp(0.5rem, 2.2vw, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 5vw, 4rem);
   }  
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.5vw, 3rem);
    padding: 0;
}  
`;

export const H2 = styled(H1)`
font-size: clamp(0.5rem, 1.4vw, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
padding-bottom: 1rem;
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem);
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.2vw, 3rem);
    padding: 0;
}  
`;

export const H3 = styled(H1)`
font-size: clamp(0.5rem, 1.7vw, 3rem);
letter-spacing: 0rem;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem);
} 
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
} 
`;

export const H4= styled(H1)`
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem;
color: var(--claypink);
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3vw, 3rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
} 
`;

export const PromptSignUp = styled.div`
align-self: flex-end;
display: flex;
flex-direction: column;
align-items: center;
margin-right: 2rem;
margin-top: 2rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2.5vw, 3rem);
    align-self: center;
    margin: 0 auto;
    padding-top: 2rem;
}

`;

export const PromptLogIn = styled.div`
align-self: flex-end;
display: flex;
flex-direction: column;
align-items: center;
margin: 2rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2.5vw, 3rem);
    align-self: center;
    margin: 0 auto;
    padding-top: 2rem;
} 
@media screen and (max-width: 768px) and (orientation: landscape) {
    padding:1rem;
} 

`;
