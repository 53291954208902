
import { useState, useEffect, useContext } from 'react';
import TransactionPopUp from './TransactionProgress';
import { motion } from "framer-motion";
import Countdown from "react-countdown";
import { SidePannel, H1, H2, H3, Line, CloseButton, VoxeliseButton, Row, Number, Separator } from './TransactionPannel.styled';
import { CompletedPannel, P1, P2, INFO, GIF, InfoWindow, Button } from "./CompletedPopup.styled";

import {MainClaimContext} from 'components/ClaimContext'

import cursorvoxelised from '../Images/cursor32.png';

import Mint from '../Pages/Mint'

import styled, { keyframes } from 'styled-components';

import pitches_meta from 'pitches_meta.json'

import hamburger from '../Images/Hamburger.svg'

import next from '../Images/next.png'

import close from '../Images/Close.svg'


const cn_policy = '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728'
const gc_policy = '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768'
const pitches_policy = '13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e'


const SideBar = styled(motion.div)`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(186deg, #303847 -15.07%, #282A2F 111.3%);
  box-shadow: 0px 4px 5px 2px rgba(44, 46, 50, 0.25);
  border-radius: 0px 20px 20px 0px;
  overflow: hidden;
  z-index: 100;
`;



const NumberDisplay = styled.div`
   
    width: 42.13px;
    height: 42.13px;
   

    background: linear-gradient(188.34deg, #495569 -16.81%, #22252B 164.01%);
    box-shadow: -2.21739px -1.1087px 4.43478px #323945, 2.21739px 3.32609px 4.43478px #292E37;
    border-radius: 8.7931px;
`

const NumberDisplayFee = styled.div`
   
    width: 222.13px;
    height: 42.13px;
   

    background: linear-gradient(188.34deg, #495569 -16.81%, #22252B 164.01%);
    box-shadow: -2.21739px -1.1087px 4.43478px #323945, 2.21739px 3.32609px 4.43478px #292E37;
    border-radius: 8.7931px;
`

const StyledDivider = styled.div`
  height: 4px; // Adjust thickness of the divider
  width: 80%; // Takes full width of the container
  background: linear-gradient(to right, transparent, #ffffff, transparent);
  margin-top: 10px;
`;

const Spacer = styled.div`
    height: 0px;
    margin-top: 25px;
    margin-bottom: 25px;
`

const SmallSpacer = styled.div`
    height: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
`

const LineContainer = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    width: 80%;

`

const SideBarTitle = styled.div`

/* Heading-SideBar */

width: 280px;
height: 78px;


font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 39px;
text-transform: uppercase;

background: linear-gradient(281.61deg, #6EC3D4 35.6%, #D34D91 104.12%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

margin-left: 30px;


-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */


`


const TextCategory = styled.div`


width: 176px;
height: 24px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;

color: #FFFFFF;

-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

`


const TextCategorySmall = styled.div`

width: 53px;
height: 19px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-align: center;

color: #FFFFFF;

-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

`

const TextNumber = styled.div`

width: 100%;
height: 100%;


font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 24px;
line-height: 29px;
/* identical to box height */
text-align: center;

color: #FFFFFF;

display: flex;
justify-content: center;
align-items: center;

-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

`


const OpenBarContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    
`


const ClosedBarContainer = styled.div`

`



function TransactionPannel(props) {

    const {isOpen, setIsOpen, mintPasses, setMintPasses, mintFee} = props

    const {selectedNfts, setSelectedNfts, eligibleNfts, upgradeFee, setUpgradeFee, isAlertOpen, setIsAlertOpen, submittedTx, setSubmittedTx} = props

    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, reset} = useContext(MainClaimContext)

    // const [selectedNfts, setSelectedNfts] = useState([])
    // const [eligibleNfts, setEligibleNfts] = useState([])

    const [priceToUpgrade, setPriceToUpgrade] = useState(-1)

    const [transactionStatus, setTransactionStatus] = useState('')

    const [points, setPoints] = useState(0)
    const [passes, setPasses] = useState(0)

    

    const [timeUntiPriceIncrease, setTimeUntiPriceIncrease] = useState(-1)

    const [buttonPopup, setButtonPopup] = useState(false);
    const Toggle = () => setButtonPopup(!buttonPopup);

    const Completionist = () => <span style={{color:'white'}}>Early bird access has ended!</span>;
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <H3>{days}d:{hours}h:{minutes}m:{seconds}s</H3>;
    }
  };


  //const [isOpen, setIsOpen] = useState(true)

  const handleSidebarClick = () => {
    if (isOpen){
      setIsOpen(false)
    }else{
      setIsOpen(true)
    }
    
  }


  useEffect(() => {

    let temp_points = 0
    let temp_passes = 0

    selectedNfts.forEach((e) => {
        if (e.slice(0,56) == cn_policy){
            temp_passes += 1
        }else if (e.slice(0,56) == gc_policy){
            temp_passes += 0.5
        }else if (e.slice(0,56) == pitches_policy){

            let size = pitches_meta[e]['size']

            if (size == 'Small'){
                temp_points += 2
            }else if (size == 'Medium'){
                temp_points += 3
            }else if (size == 'Large'){
                temp_points += 6
            }
        }

    })


    temp_passes = Math.floor(temp_passes)

    temp_passes += Math.floor(temp_points/12)

    setPasses(temp_passes)
    setPoints(temp_points)

  },[selectedNfts])


  // useEffect(() => {
    
  //   let date_now = Date.now()/1000
    
  //   if (date_now < 1696348800){
  //       setPriceToUpgrade(85)
  //   }else{
  //       setPriceToUpgrade(120)
  //   }
    
  // })


  // useEffect(() => {
  //   //get total upgrade fee here
  //   if (priceToUpgrade > 0){
  //       setUpgradeFee(priceToUpgrade * selectedNfts.length)
  //   }
  // },[priceToUpgrade, selectedNfts])



  return (props.trigger) ? (




<>



    <SideBar
        initial={{ width: 0 }} // Initial state when closed
        animate={{ width: isOpen ? 375 : 103 }} // Animation to open/close
        transition={{ duration: 0.6 }} // Smooth transition over 0.5s

        //onClick={handleSidebarClick}
    >

    {/*<CloseButton type="button" onClick={() => handleSidebarClick()}>X</CloseButton> */}


        {
            isOpen ?

            <OpenBarContainer
                as={motion.div}
                initial={{ opacity:0 }} 
                whileInView="visible"
                viewport={{ once: true }}
                animate={{ opacity:1, x:0, type: "spring" }}
                transition={{ duration: 1.2, delay: 0.2}}
            >

                <div>
                    <CloseButton type="button" onClick={() => handleSidebarClick()}><img src={close} style={{width:'50', height:'auto', zIndex:'10'}} /></CloseButton> 
                </div>
                

                <div style={{marginTop:'100px', width:'100%', height:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <div style={{display:'flex', width:'80%', justifyContent:'center', alignItems:'center', marginBottom:'51px'}}>
                    <SideBarTitle>
                    FAIR PUBLIC MINT
                    </SideBarTitle>



                </div>


                

                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'80%', flexDirection:'column'}}>
                    <LineContainer>
                        <TextCategory>
                        Mint Passes:
                        </TextCategory>

                        <NumberDisplay>
                            <TextNumber>
                                {mintPasses}
                            </TextNumber>
                        </NumberDisplay>
                    </LineContainer>

                    <StyledDivider />
                    <Spacer />

                    {/*<LineContainer>
                        <TextCategory>
                            Pitches Points:
                        </TextCategory>
                            <NumberDisplay>
                                <TextNumber>
                                    {points}
                                </TextNumber>
                            </NumberDisplay>
                    </LineContainer>

                     <StyledDivider />
                     <Spacer />*/}

                    <LineContainer>
                        <TextCategory>
                            Mint Fee:
                        </TextCategory>
                            <NumberDisplayFee>
                                <TextNumber>
                                    {mintPasses*mintFee} ADA
                                </TextNumber>
                            </NumberDisplayFee>
                    </LineContainer>


                     <StyledDivider />
                     <Spacer />


                    <div style={{height:'120px', backgroundColor:'', 
                            display:'flex', justifyContent:'center', alignItems:'start'
                    }}>

                    <div style={{display:'flex', justifyContent:'cetner', alignItems:'center', flexDirection:'column'}}>
                        <Mint 
                            price={upgradeFee} selectedNfts={selectedNfts} 
                            isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen}
                            submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
                            selectedTokens={selectedNfts}
                            setSelectedTokens={setSelectedNfts}
                            setTransactionStatus={setTransactionStatus}
                            setButtonPopup={props.setButtonPopup}
                            
                            mintPasses={mintPasses}
                            setMintPasses={setMintPasses}

                            mintFee={mintFee*mintPasses}
                           

                           />

                       </div>

                    </div>

                    <div style={{width:'100%', color:'white', fontFamily:'Inter', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'90%'}}>
                      * It's a Fair Mint:
                      If more than 806 slots are claimed, 806 lucky minters will be selected at random (If you don’t get selected, your ADA will be refunded).
                      If fewer than 806 mint, it’s first come, first served.

                      Passes will be distributed after the public mint phase is over.
                    </div>
                  </div>

                    </div>


                </div>


            </OpenBarContainer>

            :

            <ClosedBarContainer
                as={motion.div}
                initial={{ opacity:0 }} 
                whileInView="visible"
                viewport={{ once: true }}
                animate={{ opacity:1, x:0, type: "spring" }}
                transition={{ duration: 1.2, delay: 0.2}}
            >
                <div>
                    <CloseButton type="button" onClick={() => handleSidebarClick()}><img src={hamburger} style={{width:'50', height:'auto', zIndex:'10'}} /> </CloseButton> 
                </div>

                <div style={{marginTop:'345px', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                   

                    {/*<div>
                        <TextCategorySmall>
                            Assets
                        </TextCategorySmall>

                        <SmallSpacer />

                            <NumberDisplay>
                                <TextNumber>
                                    {selectedNfts.length}
                                </TextNumber>
                            </NumberDisplay>
                    </div>

                    <SmallSpacer /> */}

                    <div>
                        <TextCategorySmall>
                            Passes
                        </TextCategorySmall>

                         <SmallSpacer />

                            <NumberDisplay>
                                <TextNumber>
                                    {mintPasses}
                                </TextNumber>
                            </NumberDisplay>
                    </div>

                    <SmallSpacer />

                    <div>
                        <TextCategorySmall>
                            Fee
                        </TextCategorySmall>

                         <SmallSpacer />

                            <NumberDisplay style={{width:'55px'}}>
                                <TextNumber>
                                    {(mintPasses*3)/10}K
                                </TextNumber>
                            </NumberDisplay>
                    </div>
                </div>
            </ClosedBarContainer>
        }



    </SideBar>
    





    {/*<SidePannel as={motion.div}
    transition={{ duration: 1 }}
    initial={{ x:-1000}}
    whileInView="visible"
    viewport={{ once: true }}
    animate={{ opacity:0.95, x:0 }}
    >   
    

    <div style={{height:'100%', width:'100%', backgroundColor:'', display:'flex', flexDirection:'column', justifyContent:'space-between', overflow:'scroll'}}>

    <div style={{display:'flex', flexDirection:'column', justifyContent:'start', backgroundColor:''}}>


        <H1>UPGRADE</H1>

        <CloseButton type="button" onClick={() => props.setTrigger(false)}>X</CloseButton> 


        <Line />
        <P2 style={{fontFamily:'ShortStack', fontSize:'15px'}}>view and use your VOX-CLAY avatar in  <a href="https://www.sandbox.game/en/me/avatar/avatars/my/" style={{color:'var(--clayblue-secondary)', textDecoration:'none', fontSize: 'clamp(1rem, 1.3vw, 3rem)', zIndex:'1000', cursor: `url(${cursorvoxelised}), auto`}}>The Sandbox</a>.</P2>


        <div>
        <Row>
        <H2 style={{fontSize:'20px'}}>ELIGIBLE NFTS</H2>
        <Number style={{fontSize:'20px'}}>{eligibleNfts.length}</Number>
        </Row>
        <Separator />
        <Row>
        <H2 style={{fontSize:'20px'}}>SELECTED NFTS</H2>
        <Number style={{fontSize:'20px'}}>{selectedNfts.length}</Number>
        </Row>
        <Separator />
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1em 0'}}>
        
        
       
        

        
        <Separator />

     

        <div style={{width:'100%', height:'20%', minHeight:'100px', minWidth:'200px',
        display:'flex', justifyContent:'center', alignItems:'center',
        position:'absolute', bottom:'2%'
        }}>

        </div>

        </div>


        <div style={{height:'200px', backgroundColor:'', 
                display:'flex', justifyContent:'center', alignItems:'start'
        }}>

        <div style={{display:'flex', justifyContent:'cetner', alignItems:'center', flexDirection:'column'}}>
            <Mint 
                price={upgradeFee} selectedNfts={selectedNfts} 
                isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen}
                submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
                selectedTokens={selectedNfts}
                setSelectedTokens={setSelectedNfts}
                setTransactionStatus={setTransactionStatus}
                setButtonPopup={props.setButtonPopup}
               />

           </div>

        </div>


        


        </div>

        </div>
        
        </div>

    <TransactionPopUp
         trigger={transactionStatus !== ''} 
         setTrigger={setButtonPopup} 
         transactionStatus={transactionStatus}
     />

    </SidePannel>*/}

    </>
  ): "";
}

export default TransactionPannel;