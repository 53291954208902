import { useState, useEffect } from 'react'
import { ModalDefault, PromptLogIn, } from "./Modal.styled.jsx";
import { Button, ButtonSecondary, ButtonWallet } from '../GlobalStyles.jsx';
import { WalletContainer, WalletIcon, H1, H2, H4 } from "./ConnectWallet.styled.jsx";
import nami from '../Images/nami.png'
import eternl from '../Images/eternl.png'
import gero from '../Images/gero.png'
import lace from '../Images/lace.png'
import yoroi from '../Images/yoroi.png'
import flint from '../Images/flint.png'

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs'
import {Buffer} from 'buffer'
import * as msgSign from '@emurgo/cardano-message-signing-asmjs'

const NETWORK_ID = 1


function ConnectWallet({ handleLogIn, handleSignMessage, toastMessages, accessToken, setAccessToken, mainAction, setMainAction, isLoggedIn, setIsLoggedIn, ...props}) {

    const {username, setUsername, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword} = props

    const [isVerifying, setIsVerifying] = useState(false)
    
    const [hwMode, setHwMode] = useState(false)

    const [cardanoAddress, setCardanoAddress] = useState('')
    const [cardanoStakeAddress, setCardanoStakeAddress] = useState('')
    const [activeStep, setActiveStep] = useState(0)

    const [cardanoSecret_1, setCardanoSecret_1] = useState('')
    const [cardanoSecret_2, setCardanoSecret_2] = useState('')

    //const [wasSigned, setWasSigned] = useState(false)

    const HWSwitch = styled((props) => (
      <Switch focusVisibleClassName=".Mui-focusVisible" {...props} /> ))(({ theme }) => ({
    }))




    useEffect(() => {
      setIsLoggedIn(false)
    },[])



    // useEffect(() => {

    //   setAuthToken(cardanoSecret_2)
    //   // console.log('auth token')
    //   // console.log(authToken)

    // },[cardanoSecret_2])



  async function  signMessage(cardano, address, message){
    let encoded_message_string = Buffer.from(message).toString('hex')
    let addr_ptr = serLib.Address.from_bech32(address)
    let address_encoded = Buffer.from(addr_ptr.to_bytes()).toString('hex')
    let access_signature = await cardano.signData(address_encoded, encoded_message_string)
    return access_signature
  }


  async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}



  async function getUtxos(cardano){
  const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
  const utxos = (await cardano.getUtxos()).map((utxo) =>
    txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
  );
  return txUnspentOutputs
}

  
async function getParameters(){
  const protocolParams = await fetch('https://claymarket.io/minting/protocolparams/mainnet/', {
        method: 'GET',
       })
  const json_params = await protocolParams.json()
  return json_params
}



async function getCurrentSlot(){
    let slot = await fetch('https://claymarket.io/minting/currentslot/mainnet/', {
        method: 'GET',
        }).then(response => response.json()).then(data => data)

    slot = parseInt(slot.slot, 10)

    return slot
}



  async function signTransaction(cardano, address, message){

    const address_encoded = serLib.Address.from_bech32(address)
    const protocolParams = await getParameters()
    const utxos = await getUtxos(cardano)

    let txBuilder = await get_txBuilder(protocolParams)

    let lovelacePayment = 2000000

    let payment_output = serLib.TransactionOutput.new(
              address_encoded,
              serLib.Value.new(
                serLib.BigNum.from_str(String(lovelacePayment)),
              )
    )


    let required_signer = Buffer.from(serLib.BaseAddress.from_address(address_encoded).payment_cred().to_keyhash().to_bytes()).toString('hex')
    txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signer,'hex')))
    //txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signer_2,'hex')))

    txBuilder.add_output(payment_output);

    let before_message = 'Proving ownership of the wallet by signing this message: '

    try{
      txBuilder.add_json_metadatum(
                  serLib.BigNum.from_str("6679"),
                  JSON.stringify({
                    "message": [before_message, message]
                  })
                 );
    }catch(err){
      //console.log(err)
    }

    
    try{
          txBuilder.add_inputs_from(utxos, 2) 
        }catch (err){
          // console.log('err')
          // console.log(err)

          // notifyWarn('balance is not sufficient')
          return
        }

      

      try{
        txBuilder.add_change_if_needed(address_encoded)
      }catch(err){
        //console.log(err)
        // setTransactionStatus('')
        // notifyWarn('could not add change')
        return
      }


      let unsignedTx;

      try{
         unsignedTx = txBuilder.build_tx()
      }catch(err){
        unsignedTx = ''
        // console.log(err)
        return
      }
      

      // console.log(unsignedTx.to_json())

      const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')

      let tx_witness;

      try{
        // setTransactionStatus('Signing Transaction...')
        tx_witness = await cardano.signTx(unsignedTx_hash, true)
      }catch{
        return
      }


      //let witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))

      //console.log(tx_witness)
     
      return {"tx":unsignedTx_hash, "witness":tx_witness}

  }





  async function checkSignature(signature){
    const message = msgSign.COSESign1.from_bytes(Buffer.from(Buffer.from(signature, 'hex'), 'hex'));
    let headermap = message.headers().protected().deserialized_headers();
    let signer_address = serLib.Address.from_bytes(headermap.header(msgSign.Label.new_text('address')).as_bytes());
    signer_address = signer_address.to_bech32()
    let payload = message.payload();
    payload = Buffer.from(payload, 'hex').toString("utf8")
    const data = {'signer':signer_address, 'message':payload}

    return data
  }


  async function enableWallet(wallet){

    try{

        let cardano = window.cardano[wallet]
        let walletAPI = await cardano.enable()
        return walletAPI
        
    }catch{
      //could not connect
      toastMessages.notifyWarn('Could not connect')
    }

  }


  async function getStakeAddress(address){
    let temp = serLib.Address.from_bech32(address)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()

    return cur_stake_address
  }


  async function fetchSecret(address){

    let response = await fetch(`https://clayapis.com/cardano/secret/${address}/`).then(response => response.json())
                                                                  .then(data => data)
    //console.log(response)
    return response                                                             
  }


async function postCreateUser(data){
   const response = await fetch('https://claymarket.io/claydash/create-user/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}


async function postWalletConnectUser(data){
   const response = await fetch('https://claymarket.io/claydash/login-wallet-connect/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}


async function postLoginUser(data){
   const response = await fetch('https://claymarket.io/claydash/login-id-connect/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}


 async function postSignature(signature){

  const data = {'signature':signature}
    const response = await fetch('https://clayapis.com/cardano/link/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()

 }

  async function postTransaction(data){

  //const data = {'tx':tx, 'witness':witness}

    const response = await fetch('https://clayapis.com/cardano/link-hw/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()

 }


 async function handleSkipConnect(){
  console.log('skip connect')



  let create_account_data = {
            username: username,
            email: email,
            password: password,
            authToken: '',
            stake_address: ''
          }

          //console.log(create_account_data)

          //POST request here
          //https://claymarket.io/claydash/create-user/

          //console.log(create_account_data)

          let create_user_response = {}

          try{
            create_user_response = await postCreateUser(create_account_data)
          }catch{
            //do nothing
          }


          console.log(create_user_response)
          

          //console.log(create_user_response)

          try{
            if (create_user_response.hasOwnProperty("status")){
              let create_user_status = create_user_response["status"]
              let access_token = create_user_response["access_token"]
              setAccessToken(access_token)
              if (create_user_status == 'success'){
                setUsername(username)
                handleSignMessage()
                setIsVerifying(false)
              }else{
                //notify of error
                toastMessages.notifyError('Could not create new user')
                setIsVerifying(false)
              }
            }else{
              toastMessages.notifyError('Could not create new user')
              setIsVerifying(false)
            }
          }catch{
            //notify of error
            toastMessages.notifyError('Could not create new user')
            setIsVerifying(false)
          }






 }


  async function verifyOwnership(cardano, address, stake_address){

    let secretResponse = await fetchSecret(address)
    
    if (secretResponse.hasOwnProperty('secret') && secretResponse.hasOwnProperty('time')){
      let secret_1 = secretResponse.secret
      let time_1 = secretResponse.time   


      let message = 'Proving ownership of the wallet by signing this message: ' + secret_1                                                     
      

      /*
      HERE DEPENDING ON THE SIGNATURE TYPE
      */

      let signedResponse = {}


      if (!hwMode){
        setIsVerifying(true)
        let signedMsg = await signMessage(cardano, address, message)
        let signature = signedMsg.signature
      
        signedResponse = await postSignature(signature)

      }else{
        setIsVerifying(true)
        let signedTx_dict = await signTransaction(cardano, address, secret_1)

        

        try{
          //console.log(signedTx_dict)
          signedResponse = await postTransaction(signedTx_dict)
          //console.log(signedResponse)

          //console.log('try')
        }catch{
          //console.log('catch')
          signedResponse = {}
        }
      }

      //console.log(signedResponse)

      

      if (signedResponse.hasOwnProperty('secret') && signedResponse.hasOwnProperty('time')){
        let secret_2 = signedResponse.secret
        let time_2 = signedResponse.time 
        
        //if we are here then success!
        setCardanoSecret_1(secret_1)
        setCardanoSecret_2(secret_2)

        setActiveStep(1)

        //spinner off
        //setIsVerifying(false)

        console.log('verifying')
        console.log(secret_2)

        //setWasSigned(true)

        if (mainAction == 'loginWalletConnect'){

            let login_wallet_connect_data = {
              authToken: secret_2,
              stake_address: stake_address
            }

            console.log(login_wallet_connect_data)


            let login_wallet_connect_response = {}

            try{

              login_wallet_connect_response = await postWalletConnectUser(login_wallet_connect_data)

            }catch{
              //do nothing
            }

            console.log(login_wallet_connect_response)

            try{

              let logged_userName = login_wallet_connect_response["username"]
              let login_status = login_wallet_connect_response["status"]

              if (login_status == 'success'){
                setUsername(logged_userName)
                let access_token = login_wallet_connect_response['access_token']
                setAccessToken(access_token)
                setIsLoggedIn(true)
                handleSignMessage()
                setIsVerifying(false)
              }else{
                toastMessages.notifyError('Could not log in')
                setIsVerifying(false)
              }

            }catch{
              toastMessages.notifyError('Could not log in')
              setIsVerifying(false)
            }

           //handleSignMessage()

        }else if (mainAction == 'signUp'){

          // console.log('sign up with these credentials')
          // console.log(username)
          // console.log(email)
          // console.log(password)
          // console.log(confirmPassword)
          // console.log(secret_2)

          let create_account_data = {
            username: username,
            email: email,
            password: password,
            authToken: secret_2,
            stake_address: stake_address
          }


          console.log(create_account_data)

          //POST request here
          //https://claymarket.io/claydash/create-user/

          //console.log(create_account_data)

          let create_user_response = {}

          try{
            create_user_response = await postCreateUser(create_account_data)
          }catch{
            //do nothing
          }


          console.log(create_user_response)
          

          //console.log(create_user_response)

          try{
            if (create_user_response.hasOwnProperty("status")){
              let create_user_status = create_user_response["status"]
              if (create_user_status == 'success'){
                setUsername(username)

                let access_token = create_user_response["access_token"]
                setAccessToken(access_token)

                handleSignMessage()
                setIsVerifying(false)
              }else{
                //notify of error
                toastMessages.notifyError('Could not create new user')
                setIsVerifying(false)
              }
            }else{
              toastMessages.notifyError('Could not create new user')
              setIsVerifying(false)
            }
          }catch{
            //notify of error
            toastMessages.notifyError('Could not create new user')
            setIsVerifying(false)
          }

          //handleSignMessage()

        }else if (mainAction == 'resetPassword'){
          handleSignMessage()
          setIsVerifying(false)
        }
       
      }else{
        //handle error 2
        toastMessages.notifyError('Could not verify ownership')
        //spinner off
        setIsVerifying(false)
      }

    }else{
      //handle error 1
       toastMessages.notifyError('Could not verify ownership')
       //spinner off
       setIsVerifying(false)
    }

  }



    async function walletConnect(wallet){

    try{

      let cardano = await enableWallet(wallet)

      if (typeof(cardano) === 'undefined'){
        return
      }

      //check network
      let cur_network_id = await cardano.getNetworkId()

      // console.log('CUR NETWORK_ID')
      // console.log(cur_network_id)

      if (cur_network_id !== NETWORK_ID){
        //handle wrong network
        toastMessages.notifyWarn('Wrong Network!')
        return
      }




      let address_encoded;

      try{
       //console.log('here 1')
       address_encoded  = await cardano.getUsedAddresses()
       address_encoded = address_encoded[0]

       if (address_encoded === undefined){
        throw 'error'
       }
      }catch{

        try{
          //console.log('here 2')
          address_encoded  = await cardano.getUnusedAddresses()
          address_encoded = address_encoded[0]
          if (address_encoded === undefined){
            throw 'error'
          }
        }catch{
          //console.log('here 3')
          address_encoded = await cardano.getRewardAddress()
          //console.log(address_encoded)
        }
      }
         

      //console.log(address_encoded)

      let address_decoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()
      setCardanoAddress(address_decoded)

      console.log(address_decoded)

      let stake_address = await getStakeAddress(address_decoded)
      setCardanoStakeAddress(stake_address)

      console.log(stake_address)

      try{
        let signature = await verifyOwnership(cardano, address_decoded, stake_address)
      }catch{
        //handle error
        toastMessages.notifyError('Could not verify ownership')
        setIsVerifying(false)
      }
    }catch{
      //hanle error
      toastMessages.notifyWarn('Could not connect')
    }
  }




    // function enableWallet(walletName){

    //     //

    //      console.log('hehe')
    //      toastMessages.notifyInfo(walletName)

    //      walletConnect(walletName)
    //     // handleSignMessage
    // }

return (
    <ModalDefault>
       <H1>Connect Wallet</H1>

       {mainAction !== 'signUp'?
       
       <H2>Remember, it must be associated with your Clay ID account.</H2>

       : 

        <H2>It will be associated with your Clay ID account.</H2>

      }

        {!isVerifying ?
        <div style={{width:'80%', height:'50px', backgroundColor:'', fontSize:'15px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                
                <div style={{width:'50px', height:'50px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <HWSwitch
                    checked={hwMode}
                    onChange={()=>{
                      setHwMode(!hwMode)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>

                <div style={{marginLeft:'10px'}}>
                  Sign transaction (hardware wallets)
                </div>

            </div>
            : null}

           {/* <button onClick={()=>{
              handleSignMessage()
            }}>test</button>*/}

        {!isVerifying ?

           <WalletContainer>
                <ButtonWallet theme="blueInactive" type="button" onClick={()=>{walletConnect('nami')}}><WalletIcon src={nami}/>NAMI</ButtonWallet>
                <ButtonWallet theme="blueInactive" type="button" onClick={()=>{walletConnect('eternl')}}><WalletIcon src={eternl}/>ETERNL</ButtonWallet>
                <ButtonWallet theme="blueInactive" type="button" onClick={()=>{walletConnect('gerowallet')}}><WalletIcon src={gero}/>GERO</ButtonWallet>
                <ButtonWallet theme="blueInactive" type="button" onClick={()=>{walletConnect('lace')}}><WalletIcon src={lace}/>LACE</ButtonWallet>
                <ButtonWallet theme="blueInactive" type="button" onClick={()=>{walletConnect('yoroi')}}><WalletIcon src={yoroi}/>YOROI</ButtonWallet>
                <ButtonWallet theme="blueInactive" type="button" onClick={()=>{walletConnect('flint')}}><WalletIcon src={flint}/>FLINT</ButtonWallet>
            </WalletContainer>
        
        : 

        <>

            <div style={{height:'200px', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                
                  <div style={{backgroundColor:'', height:'150px', width:'120px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <div 
                      id="spinner" 
                      className="spinnerCardano"
                      style={{backgroundColor:''}}
                    >  
                    </div>
                  </div>

                </div>
            
        </>

        }

        {mainAction === 'signUp'?

          <>
          
             

              <ButtonWallet theme="blueInactive" type="button" onClick={()=>{
              handleSkipConnect()
            }}
            style={{marginTop:'50px', marginBottom:'-30px', width:'180px', minHeight:'40px'}}
            >ADD LATER</ButtonWallet>
            
          </>

        : null}

        <PromptLogIn>
            <H4>USE CLAY ID INSTEAD?</H4>
            <ButtonSecondary theme="pinkInactive" type="button" onClick={handleLogIn}>LOG IN</ButtonSecondary>
        </PromptLogIn>
    </ModalDefault>
);


}

export default ConnectWallet;