
import avatar1 from '../Images/CN1234.png';
import avatar2 from '../Images/CN4817.png';
import avatar3 from '../Images/CN6709.png';
import avatar4 from '../Images/CN4211.png';
import unlockedpin from '../Images/unlocked_pin.png';
import lockedpin from '../Images/locked_pin.png';
import { motion } from "framer-motion";
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CardMedia } from '@mui/material';
import { StyledTooltip, Pin, MainPannel, GridContainer, ClayCard, TypographyID} from './MainGrid.styled';


function MainGrid () {


return (

<MainPannel as={motion.div}
initial={{ x:1500, opacity:0 }} 
whileInView="visible"
viewport={{ once: true }}
animate={{ opacity:1, x:0, type: "spring" }}
transition={{ duration: 1.2, delay: 1.5}}
>
<GridContainer container spacing={3} >
    <Grid item xs={12} sm={3} md={3}>
        <ClayCard elevation={2} sx={{ padding: "1.5em 1em",backgroundColor: "var(--background-pannel)", borderRadius:"1rem"}}>
            <CardMedia
        component="img"
        width="70%"
        height="auto"
        image={avatar1}
        alt="NFT1"
        />
        <div style={{padding: "1em 0", width: "100%"}}>
        <TypographyID>CN#1234</TypographyID>
        <StyledTooltip title={<span style={{ fontFamily: "ShortStack" }}>NFT UPGRADED</span>} placement="top">
        <Pin src={unlockedpin} alt="token"/>
        </StyledTooltip>
        </div>
        </ClayCard>
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
    <ClayCard elevation={2} sx={{ padding: "1.5em 1em",backgroundColor: "var(--background-pannel)", borderRadius:"1rem"}}>
            <CardMedia
        component="img"
        width="80%"
        height="auto"
        image={avatar2}
        alt="NFT1"
        />
        <div style={{padding: "1em 0", width: "100%"}}>
        <TypographyID>CN#1234</TypographyID>
        <StyledTooltip title={<span style={{ fontFamily: "ShortStack" }}>NFT NOT UPGRADED</span>} placement="top">
        <Pin src={lockedpin} alt="token"/>
        </StyledTooltip>
        </div>
        </ClayCard>
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
    <ClayCard elevation={2} sx={{ padding: "1.5em 1em",backgroundColor: "var(--background-pannel)", borderRadius:"1rem"}}>
            <CardMedia
        component="img"
        width="80%"
        height="auto"
        image={avatar3}
        alt="NFT1"
        />
        <div style={{padding: "1em 0", width: "100%"}}>
        <TypographyID>CN#6709</TypographyID>
        <StyledTooltip title={<span style={{ fontFamily: "ShortStack" }}>NFT UPGRADED</span>} placement="top">
        <Pin src={unlockedpin} alt="token"/>
        </StyledTooltip>
        </div>
        </ClayCard>
    </Grid>
    <Grid item xs={12} sm={3} md={3}>
    <ClayCard elevation={2} sx={{ padding: "1.5em 1em",backgroundColor: "var(--background-pannel)", borderRadius:"1rem"}}>
            <CardMedia
        component="img"
        width="80%"
        height="auto"
        image={avatar4}
        alt="NFT1"
        />
        <div style={{padding: "1em 0", width: "100%"}}>
        <TypographyID>CN#4211</TypographyID>
        <StyledTooltip title={<span style={{ fontFamily: "ShortStack" }}>NFT UPGRADED</span>} placement="top">
        <Pin src={unlockedpin} alt="token"/>
        </StyledTooltip>
        </div>
        </ClayCard>
    </Grid>


</GridContainer>
</MainPannel>

);


}


export default MainGrid;