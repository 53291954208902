import { useState, useEffect, useRef } from 'react'

import { Instances, Instance } from '@react-three/drei'

import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { RigidBody} from '@react-three/rapier'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


import { ErrorBoundary } from "react-error-boundary";

import alea from 'alea';
import { createNoise2D } from 'simplex-noise';

import * as THREE from 'three'

import roses from './json/grass.json'

// import { shaderMaterial } from "@react-three/drei"

function LoadModelGLTF(props){

  const {file_url, position, rotation, plantsReferences, setScene} = props
  const gltf = useLoader(GLTFLoader, file_url)

  useEffect(() => {

    let model = gltf.scene

    model.traverse(function (object){
      
      if (object.isMesh){
        object.receiveShadow = true
        object.castShadow = true
      }

    })

    setScene(model.clone())

  },[gltf])


  return(

    <>

    </>

  )

}



function Roses(props) {

 const {asset_info, url} = props

 const [scene, setScene] = useState()
 const [isVisible, setIsVisible] = useState(false)
 const [plantsCount, setPlantsCount] = useState(0)
 const [plantsReferences, setPlantsReferences] = useState([])
 const [instances, setInstances] = useState()
 const instancesRef = useRef()


useEffect(() => {

  if (typeof(asset_info) === 'undefined'){
    return
  }

  // console.log('PLANTS')
  // console.log(plants)
  //let plats_info = roses['grass']

  let plats_info = asset_info
  //console.log(plats_info)
  let temp_plants_references = []
  for (let i = 0; i < plats_info.length; i++){
    let cur_info = plats_info[i]
    // console.log(cur_info)
    let cur_position = cur_info['position']
    // let cur_rotation = cur_info['rotation']
    // let cur_scale = cur_info['scale']
    let z_dimension = cur_info['dimensions']
   
    z_dimension = z_dimension[2] + Math.random()/3
    z_dimension /= 100

    let cur_rotation = [0, Math.PI, Math.PI*Math.random()]

    let cur_scale = [z_dimension,z_dimension,z_dimension]

    //let cur_scale = [0.01,0.01,0.01]





    //rotation: [cur_rotation[0] + Math.PI/2, - cur_rotation[1], cur_rotation[2]],

    temp_plants_references.push(
      {
        position: [cur_position[0], cur_position[1], cur_position[2] - z_dimension*10 - 0.03],
        rotation: cur_rotation,
        scale: [cur_scale[0], cur_scale[1], cur_scale[2]]
      }
    )
  }

  //console.log(temp_plants_references)

  setPlantsReferences(temp_plants_references)

},[asset_info])


useEffect(() => {

  // console.log('plantsReferences')
  // console.log(plantsReferences)

},[plantsReferences])


useEffect(() => {

  try{

    // console.log('SCENE')
    // console.log(scene)

    let temp_instances = []

    scene.traverse(function (object){
      
      // console.log('object')
      // console.log(object)

      let cur_geometry = object.geometry
      let cur_material = object.material


      if (typeof(cur_geometry) !== 'undefined' && typeof(cur_material) !== 'undefined' ){

        //cur_geometry.scale(2,2,1)

        temp_instances.push({
          geometry: cur_geometry,
          material: cur_material
        })

      }

    })

    // console.log('instances')
    // console.log(temp_instances)
    setInstances(temp_instances)

  }catch{


  }


},[scene])



useEffect(() => {

  try{
    for (let i = 0; i < plantsReferences.length; i++){

      //console.log(i)

      const matrix = new THREE.Matrix4()

      let cur_plant_ref = plantsReferences[i]

      let cur_position = cur_plant_ref.position

      let cur_rotation = cur_plant_ref.rotation

      let cur_scale = cur_plant_ref.scale

      matrix.compose(
        new THREE.Vector3(cur_position[0], cur_position[1], cur_position[2] - 0.055),
        new THREE.Quaternion().setFromEuler(new THREE.Euler(cur_rotation[0], cur_rotation[1], cur_rotation[2])),
        new THREE.Vector3(cur_scale[0], cur_scale[1], cur_scale[2])
      )

      instancesRef.current.setMatrixAt(i, matrix)

      //instancesRef.current.needsUpdate = true

      // console.log(matrix)

    }

    instancesRef.current.instanceMatrix.needsUpdate = true;

    setIsVisible(true)

}catch{

}

},[instances, plantsReferences, scene])
 

return(
  
  <>

  <ErrorBoundary fallback={<></>}>
    <LoadModelGLTF 
      file_url={url} 
      setScene={setScene}
      plantsReferences={plantsReferences}
    />

  </ErrorBoundary>

    <group rotation={[-Math.PI/2,0,0]} >

    {instances?.map((instance, i) => {

      return(

         <instancedMesh visible={isVisible} key={i} ref={instancesRef} args={ [instance.geometry, instance.material, plantsReferences.length] }>

         </instancedMesh>
         
      )

    })}
       
    
    </group>

  </>
  
  )
}




function Grass(){

  const [grass1, setGrass1] = useState()
  const [grass2, setGrass2] = useState()
  const [grass3, setGrass3] = useState()

  useEffect(() => {
    let plants_info = roses['grass']

    // create a new random function based on the seed
    const prng = alea('clay');
    // use the seeded random function to initialize the noise function
    const noise2D = createNoise2D(prng);
    

    let temp_grass1 = []
    let temp_grass2 = []
    let temp_grass3 = []
    for (let i = 0; i < plants_info.length; i++){

      let cur_info = plants_info[i]

      let cur_position = cur_info.position 

      let noise_value = noise2D(cur_position[0]/2, cur_position[1]/2)


      if (noise_value < 0.1){
        temp_grass1.push(plants_info[i])
      }else if (noise_value < 0.2){
         temp_grass3.push(plants_info[i])
      }else if (noise_value < 0.3){
        temp_grass3.push(plants_info[i])
      }else if (noise_value < 0.4){
        temp_grass2.push(plants_info[i])
      }else if (noise_value < 0.5){
        temp_grass1.push(plants_info[i])
      }else{
        temp_grass1.push(plants_info[i])
      }
    }

    setGrass1(temp_grass1)
    setGrass2(temp_grass2)
    setGrass3(temp_grass3)

  },[])

  return(
    <>
      <Roses key='grass_1' asset_info={grass1} url={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/AvatarSelectionScene/grass/grass_2.glb'}  />
      <Roses key='grass_2' asset_info={grass2} url={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/AvatarSelectionScene/grass/grass_1.glb'} />
      <Roses key='grass3' asset_info={grass3} url={'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/AvatarSelection/Grass12.glb'} />
    </>
  )
}
export default Grass;








