import { useState, useEffect, useContext, useRef } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'
import { socket } from '../socket';
import { MainMultiplayerContext } from 'components/Context/MultiplayerContext'
import { MainExperienceContext } from 'components/Context/ExperienceContext'


import Avatar from '../Avatar/Avatar'
import DisplayAvatar from '../Avatar/DisplayAvatar'
import DisplayMessage from './DisplayMessage'
import DisplayNickname from './DisplayNickname'
import PositionalSoundLoader from '../Sound/PositionalSoundLoader'
import MovementParticles from '../LocalPlayer/MovementParticles'



function PlayerSounds(props){

  const {isLoaded, setIsLoaded} = props

  const playerAction = props.playerAction 
  const setPlayerAction = props.setPlayerAction

  const currentAction = props.currentAction

  const meta = props.meta

  const characterPosition = props.characterPosition

  //const {voiceType} = useContext(MainPlayerContext)
  const {audioListener} = useContext(MainExperienceContext)

  const [cartSoundsReady, setCartSoundsReady] = useState(false)


  const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/'


const [tryOnTrousersSounds] = useState({

    "try_on_trousers_button": {url: root_url + "Interior/06_Trying on trousers (with button).wav", volume: 1},
    "try_on_trousers_clasp": {url: root_url + "Interior/06_Trying on trousers (with clasp).wav", volume: 1},
    "try_on_trousers_zip": {url: root_url + "Interior/06_Trying on trousers (with zip).wav", volume: 1}

  })

  const [loadedTryOnTrousersSounds, setLoadedTryOnTrousersSounds] = useState({})


  const [hmmSounds] = useState({
    "type1":{
      "type1&Hmm_1": {url: root_url + "Interior/User/01_Hmm 1.wav", sound: null, volume: 3},
      "type1&Hmm_2": {url: root_url + "Interior/User/01_Hmm 2.wav", sound: null, volume: 3},
      "type1&Hmm_3": {url: root_url + "Interior/User/01_Hmm 3.wav", sound: null, volume: 3},
    },

    "type2": {
      "type2&Hmm_1": {url: root_url + "Interior/User/01_Hmm 4.wav", sound: null, volume: 3},
      "type2&Hmm_2": {url: root_url + "Interior/User/01_Hmm 5.wav", sound: null, volume: 3},
      "type2&Hmm_3": {url: root_url + "Interior/User/01_Hmm 6.wav", sound: null, volume: 3}
    }
    

  })

  const [hmmLoadedSounds, setHmmLoadedSounds] = useState({})


  const [playerStateSounds] = useState({
    "Walk": {url: root_url + "Interior/02_Footsteps 3.wav", volume: 2, loop: true},
    "Run": {url: root_url + "Interior/02_Footsteps 2.wav", volume: 2, loop: true},
    "Landed": {url: root_url + "Jump-1.m4a", volume: 10}
  })
  
  const [loadedSounds, setLoadedSounds] = useState({})




  const positionSoundObject = useRef()


  const [currentSound, setCurrentSound] = useState()

  const [currentStateSound, setCurrentStateSound] = useState()

  const [currentVoiceSound, setCurrentVoiceSound] = useState()

  const [hmmSoundReady, setHmmSoundReady] = useState(true)

  const [justLanded, setJustLanded] = useState(false)

  const [isFalling, setIsFalling] = useState(false)

  

  useEffect(() => {

    if (currentAction == 'Fall'){
      // console.log('\n\n\nFALLING\n\n\n')
      
      if (!isFalling){
        setIsFalling(true)

        //Just jumped
      }
      
    }else{
      if (isFalling){
        // console.log('\n\n\nLanded\n\n\n')
        setIsFalling(false)

        //Play landing sound here
        try{
          currentStateSound.stop()
        }catch{

        }

        if (loadedSounds.hasOwnProperty('Landed')){

          /*
          try{
            currentSound.stop()
          }catch{

          }
          */

          let sound = loadedSounds['Landed']

          if (!sound.isPlaying){
            sound.offset = 0.7
            //sound.play()
            positionSoundObject.current.add(sound)
          }

         

          //setCurrentStateSound(sound)
        }

      }
    }

  },[currentAction])



  useEffect(() => {

    if (playerAction === 'try_on_trousers' && isLoaded && hmmSoundReady){
      setHmmSoundReady(false)
      
      // console.log('\n\n\nLOADED TROUSERS !!!\n\n\n')
      // console.log(hmmLoadedSounds)  

      let hmmSoundsList = Object.keys(hmmLoadedSounds)
      let soundIndex =  Math.floor(Math.random() * hmmSoundsList.length)
      let cur_sound = hmmLoadedSounds[hmmSoundsList[soundIndex]]
      

      setTimeout(()=>{
        if (Math.random() > 0.2){
          cur_sound.play()
        }
        
        setTimeout(()=>{
          setHmmSoundReady(true)
        }, 10000)

      }, 1850)

      // console.log('CURRENT SOUND')
      // console.log(cur_sound)
    }


    //setPlayerAction('')
  },[playerAction, isLoaded])




  useEffect(() => {

    

    if (playerAction === 'try_on_trousers' && !isLoaded){

      try{
          currentSound.stop()
      }catch{

      }


      let tryOnTrousersSoundsList = Object.keys(loadedTryOnTrousersSounds)
      let soundIndex =  Math.floor(Math.random() * tryOnTrousersSoundsList.length)
      let cur_sound = loadedTryOnTrousersSounds[tryOnTrousersSoundsList[soundIndex]]


      if (cur_sound.isPlaying){
        cur_sound.stop()  
        cur_sound.play()
      }else{
        cur_sound.play()
      }

      positionSoundObject.current.add(cur_sound)

      setCurrentSound(cur_sound)

    }

  },[playerAction, isLoaded])


useEffect(() => {

  // console.log('CURRENT ACTION')
  // console.log(loadedSounds)

  try{
    currentStateSound.stop()
  }catch{

  }

  if (loadedSounds.hasOwnProperty(currentAction)){
    let sound = loadedSounds[currentAction]
    
    if (!sound.isPlaying){

      sound.play()

    }
    //sound.play()
    positionSoundObject.current.add(sound)

    setCurrentStateSound(sound)
  }

  // console.log(positionSoundObject.current.children)

},[currentAction])




  return(
    <>


    <PositionalSoundLoader 

      key={'playerStateSounds'}

      audioListener={audioListener}
      soundsToLoad={playerStateSounds}
      loadedSounds={loadedSounds}
      setLoadedSounds={setLoadedSounds}
    />


    

    <PositionalSoundLoader 

      key={'tryOnTrousersSounds'}

      audioListener={audioListener}
      soundsToLoad={tryOnTrousersSounds}
      loadedSounds={loadedTryOnTrousersSounds}
      setLoadedSounds={setLoadedTryOnTrousersSounds}
    />



    <mesh visible={false} ref={positionSoundObject} position={[characterPosition[0], characterPosition[1]+0.25, characterPosition[2]]}>
      <sphereGeometry args={[0.1,12,12]} />
      <meshStandardMaterial color={'blue'}  />
    </mesh>


    </>

  )
}



function RemotePlayer(props) {

  const socket_id = props.socket_id

  const position = props.position

  const rotation = props.rotation

  const action = props.action

  const playerAction = props.playerAction

  //const meta = props.meta

  const tempMeta = props.meta
  const [meta, setMeta] = useState(props.meta)

  const nickname = props.nickname

  const messageContent = props.messageContent 
  const setMessageContent = props.setMessageContent

  const recentMessages = props.recentMessages


  const charGroupRef = useRef()

  const avatarRef = useRef()

  
  const [animationDict, setAnimationDict] = useState({})
  const [mixer, setMixer] = useState()

  const [isLoaded, setIsLoaded] = useState(false)

  const [ currentAction, setCurrentAction ] = useState(() => 'Idle')


  const [animations, setAnimations] = useState(()=>{})

  const nicknameDisplay = useRef()
  const messageDisplay = useRef()


  const {camera} = useThree()

  const groupRef = useRef()


  const [needsUpdate, setNeedsUpdate] = useState(props.needsUpdate)


  const [positionQueue, setPositionQueue] = useState([])
  const [rotationQueue, setRotationQueue] = useState([])

  const [lastUpdateTime, setLastUpdateTime] = useState(0)

  

  const animationPaths = [

    {
      name: "Idle",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Idle.glb"
    },

    {
      name: "Walk",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Walking.glb"
    },

    {
      name: "Run",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Running.glb"
    },

    {
      name: "Jump",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Jumping.glb"
    },

    {
      name: "Fall",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Falling.glb"
    }
   
  ] 




  const lerp = (x, y, a) => x * (1 - a) + y * a;

  
  
  function updateAnimation(newState, t){

    // let t = delta * 10 * 1.6

    if (typeof(animations) === 'undefined' ||  (animations.constructor === Object &&  !newState in animations) ){
      return
    }

 
    if (currentAction != newState){

      if (newState == 'Fall'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }


        else if ( currentAction == 'Fall' && newState == 'Idle'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }

        else if ( currentAction == 'Fall' && newState == 'Walk'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }


         else if ( currentAction == 'Fall' && newState == 'Run'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }




      else if (currentAction == 'Idle' && newState == 'Walk'){
          //console.log('IDLE TO WALK')

           animations[newState].enabled = true
           animations[newState].crossFadeFrom(animations[currentAction], 0.25 + t, true)
           animations[newState].play()
        }


       
        //walk to idle
        else if (currentAction == 'Walk' && newState == 'Idle'){
          //console.log('WALK TO IDLE')

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.25 + t, true)
          animations[newState].play()

        }

        //walk to run
        else if (currentAction == 'Walk' && newState == 'Run'){
          //console.log('WALK TO RUN')

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }

        //run to walk
        else if (currentAction == 'Run' && newState == 'Walk'){
          //console.log('RUN TO WALK')

          animations[newState].enabled = true

          let ratio = animations[newState].getClip().duration / animations[currentAction].getClip().duration

          animations[newState].time = animations[currentAction].time * ratio

          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }

        //ide to run

        else if (currentAction == 'Idle' && newState == 'Run'){
          //console.log('IDLE TO RUN')

         
            animations[newState].enabled = true

            animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
            animations[newState].play()
          

          
        }

        //run to idle

        else if (currentAction == 'Run' && newState == 'Idle'){

          //console.log('RUN TO IDLE')

          animations[newState].enabled = true

          animations[newState].crossFadeFrom(animations[currentAction], 0.2 + t, true)
          animations[newState].play()

        }

        else{

          if (newState == 'Run'){
            //console.log('Run')

            animations[newState].enabled = true
            animations[newState].crossFadeFrom(animations[currentAction], 0.5 + t, true)
            animations[newState].play()

          }else{
           // console.log('WTF')

            //console.log(currentAction)
            //console.log(newState)

          }


        }
         

        setCurrentAction(newState)
  }else{
    //do nothing
  }


}


  useEffect(() => {

    if (JSON.stringify(meta) !== JSON.stringify(tempMeta) ){
      setMeta(tempMeta)
    }

  },[tempMeta])


 useFrame((state, delta) => {


  if (typeof(avatarRef) != 'undefined' && typeof(avatarRef.current) != 'undefined'){

    const t = Math.max(Math.min(delta * 10, 0.1), 0.0)

    avatarRef.current.position.x = lerp(avatarRef.current.position.x,  position[0], t*1.6)
    //avatarRef.current.position.y = lerp(avatarRef.current.position.y,  position[1], t*1.6)
    avatarRef.current.position.y = lerp(avatarRef.current.position.y,  position[1], t*1.6)
    avatarRef.current.position.z = lerp(avatarRef.current.position.z,  position[2], t*1.6)


    let quaternion = new THREE.Quaternion( 
      avatarRef.current.quaternion._x,
      avatarRef.current.quaternion._y,
      avatarRef.current.quaternion._z,
      avatarRef.current.quaternion._w
    )


    let new_quaternion = new THREE.Quaternion(rotation._x, rotation._y, rotation._z, rotation._w)

    quaternion.slerp(new_quaternion, t * 1.6)

    avatarRef.current.rotation.setFromQuaternion( quaternion )

    mixer?.update(delta)

    updateAnimation(action, t * 1.2)

  }


  try{

    nicknameDisplay.current.lookAt(camera.position);
    messageDisplay.current.lookAt(camera.position);

  }catch{

  }

})


return(
  
  <>
 
  <group ref={avatarRef} /*position={position}*/ >
    <DisplayAvatar 
      key={socket_id}
      identifier={socket_id}
      needsUpdate={needsUpdate}
      setNeedsUpdate={setNeedsUpdate}
      meta={meta}
      clayColor={meta.clayColor}
      groupRef={groupRef} 
      animationDict={animationDict} 
      setAnimationDict={setAnimationDict}
      mixer={mixer}
      setMixer={setMixer}
      setAnimations={setAnimations}
      animationPaths={animationPaths}
      isLoaded={isLoaded}
      setIsLoaded={setIsLoaded}
    />

    <group position={[0,2.2,0]}>

      <DisplayNickname nicknameDisplay={nicknameDisplay} nickname={nickname} />
    
    {socket_id in recentMessages && recentMessages[socket_id] !== ''  ?

      <DisplayMessage messageDisplay={messageDisplay} messageContent={recentMessages[socket_id]} />
    : null}

    </group>

    </group>


     {/*<MovementParticles characterPosition={position} currentAction={currentAction} />*/}


    <PlayerSounds 
      isLoaded={isLoaded}
      setIsLoaded={setIsLoaded}
      characterPosition={position} 
      playerAction={playerAction} 
      //setPlayerAction={setPlayerAction} 
      currentAction={currentAction} 
   />

  

  </>
  
  )
}

export default RemotePlayer;
