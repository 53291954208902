import styled from "styled-components";


export const PopUp = styled.div`
box-sizing: border-box;
background: var(--background-progress);
border-radius: 00px 50px 50px 0px;
max-height: 92vh;
min-height: 100px;
max-width: 22vw;
min-width: 100px;
width: 100%;
height: 100%;
left: 0;
top: 0;
z-index: 2;
backdrop-filter: blur(22px);
position: absolute;
padding: 2em 2em;
display: block;
align-items: center;
justify-content: center;
text-align: center;

min-width: 300px;

@media screen and (max-width: 768px) {
    max-width: 70vw;
    max-height: 92vh;
}

z-index:100;

`;

export const H1 = styled.h1`
font-family: PixelFont;
font-size: clamp(1rem, 3vw, 7rem);
font-weight: 50;
letter-spacing: 0.2rem;
color: var(--clayblue-primary);
text-align: center;
margin: 0 auto;
padding-top: 2em;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 3vw, 10rem);
    padding: 0em 0em;
    
}
`;

export const H2 = styled(H1)`
color: var(--claygreen-primary);
font-size: clamp(1rem, 1.2vw, 7rem);
padding: 0.5em;
text-align: center;
width: fit-content;
background: rgba(0, 0, 0, 0.25);
border-radius: 0.5em;
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 1.2vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 2vw, 10rem);
    margin-top: -2em;
}
`;

export const Line = styled.hr`
border: 1px solid var(--clayblue-primary);
width: 80%;
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 50%;
    border: 0.5px solid var(--clayblue-primary);
}
`;

export const CloseButton = styled.button`
background: transparent;
border: none;
color: var(--claytext-white);
font-size: clamp(1rem, 3vw, 3rem);
font-weight: 100;
font-family: PixelFont;
position: absolute;
right: 0.5em;
top: 0.5em;
margin: 0;
padding: 0;
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
    right: 1em;
    top: 1em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 4vw, 10rem);
}

`;