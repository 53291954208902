import {useState, useContext} from 'react'
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs'
import {Buffer} from 'buffer'

import '../WalletLink.css'

import {MainLinkingContext} from './Context/LinkingContext'

function Linking(props) {

  const {setActiveStep, toastMessages} = props

  const {cardanoAddress, cardanoStakeAddress, polygonAddress, cardanoSecret_1, cardanoSecret_2, polygonSecret_1, polygonSecret_2, reset} = useContext(MainLinkingContext)

  async function postLinking(walletLinkInfo){

    const data = {'wallet_link_info':walletLinkInfo}
      const response = await fetch('https://clayapis.com/wallets/link/', {
          method: 'POST',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      })
      return response.json()
  }


  async function linkWallets(){
    //console.log('link wallets...')

    let walletLinkInfo = {
      'cardano_address': cardanoAddress, 
      'cardano_stake_address': cardanoStakeAddress,
      'polygon_address': polygonAddress,
      'cardano_secret_1': cardanoSecret_1,
      'cardano_secret_2': cardanoSecret_2,
      'polygon_secret_1': polygonSecret_1,
      'polygon_secret_2': polygonSecret_2
    }

    //console.log(walletLinkInfo)

    let linkingStatus = await postLinking(walletLinkInfo)
    //console.log(linkingStatus)

    if (linkingStatus.hasOwnProperty('verified') && linkingStatus['verified'] === 'true'){
      //console.log('SUCCESSFULLY LINKED WALLETS')
      setActiveStep(3)
    }else{
      toastMessages.notifyError('Failed to link wallets')
      //console.log('FAILED TO LINK WALLETS')
    }

  }

  return (
   <>
   

      <h2 className="connect-heading heading-cardano-wallets">
           <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
              <div style={{width:'80%', fontSize:'30px'}}>
                 LINK YOUR WALLETS
              </div>
            </div>
      </h2>


      <div style={{ width: "100%", height: "180px", backgroundColor: "", display:'flex', justifyContent:'center', alignItems:'center' }}>
       <div  className='CardanoWallets' style={{width:'100%'}}>
           <button className='Linkbtn' onClick={() => {
            linkWallets()
          }}>LINK WALLETS</button>
        </div>
      </div>


   </>
  );
}

export default Linking;
