import {useState, useEffect, useContext} from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs'
import {Buffer} from 'buffer'
import * as msgSign from '@emurgo/cardano-message-signing-asmjs'

import {MainLinkingContext} from './Context/LinkingContext'

import AnimatedCheckmark from './AnimatedCheckmark'

function Success(props) {

  return (
   <>
       {/*<h2 className="connect-heading heading-cardano-wallets">
           <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
              <div style={{width:'80%'}}>
                 WALLETS SUCCESFULLY LINKED!
              </div>
            </div>
      </h2>*/}


      <h2 className="connect-heading heading-cardano-wallets">
           <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
              <div style={{width:'80%', fontSize:'30px'}}>
                 WALLETS SUCCESSFULLY LINKED!
              </div>
            </div>
      </h2>

      <div style={{height:'200px', display:'flex', justifyContent:'center', alignItems:'center', transform:'scale(1.3)'}}>
        <AnimatedCheckmark />
      </div>

   </>
  );
}

export default Success;
