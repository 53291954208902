import { useState, useEffect, useRef } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'

import { Text } from '@react-three/drei';


import { TextureLoader } from 'three/src/loaders/TextureLoader'

function DisplayNickname(props){


	const nickname = props.nickname

	const {characterRef, characterDirection} = props

	const {camera} = useThree()


	const [nicknameObject, setNicknameObject] = useState()

	const [nicknameBackground, setNicknameBackground] = useState()
	
	const nicknameDisplay = props.nicknameDisplay

	const nicknamePlate = useLoader(TextureLoader, 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/Account/arrow.svg')

	const nicknamePlateBackground = useLoader(TextureLoader, 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/Account/nicknameBackground.svg')

	

	useEffect(() => {

		const planeGeometry = new THREE.PlaneGeometry(0.1, 0.1)
		const planeMaterial = new THREE.MeshStandardMaterial()

		const nicknameMesh = new THREE.Mesh(planeGeometry, planeMaterial)

		nicknameMesh.material.map = nicknamePlate


		nicknameMesh.material.alphaTest = 0.5

		//nicknameMesh.material.depthTest = false;
        nicknameMesh.material.depthWrite = true;

		nicknameMesh.material.transparent = true;
		nicknameMesh.material.needsUpdate = true; 

		setNicknameObject(nicknameMesh)

	},[])


	useEffect(() => {
		const planeGeometry = new THREE.PlaneGeometry(0.5, 0.12)
		const planeMaterial = new THREE.MeshStandardMaterial()
		const backgroundMesh = new THREE.Mesh(planeGeometry, planeMaterial)

		backgroundMesh.material.map = nicknamePlateBackground

		backgroundMesh.material.alphaTest = 0.5

		//backgroundMesh.material.depthTest = false;
        backgroundMesh.material.depthWrite = true;

		//backgroundMesh.material.color = 0xffffff

		backgroundMesh.material.transparent = true;
		backgroundMesh.material.needsUpdate = true; 

		setNicknameBackground(backgroundMesh)
	},[])


	useFrame((state) => {
		try{    
			
			if (nicknameObject && nicknameBackground){
				nicknameDisplay.current.lookAt(camera.position);
			}
			
			
		}catch{
		
		}
	})
	


	return(

		<>

		<group position={[0,1.15,0]} ref={nicknameDisplay}>

		{nicknameObject && nicknameBackground ?


		<group position={[0,-0.025,0]}>
			
			<primitive
				position={[0,-0.05,0]}
				object={nicknameObject}
			/>


			<primitive
				position={[0,0.1,0]}
				object={nicknameBackground}
			/>
			


			<Text

				font={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/Chat/SingleDay.ttf'}
		        fontSize={0.1}
		        color="#B1F2B7"
		        anchorX="center"
		        anchorY="middle"
		        position={[0,0.1,0.01]}

		      >

		        {nickname.toLowerCase()}

		      </Text>

	      </group>

	      : null}

		</group>

		</>

	)
}

export default DisplayNickname;
