import {useState, useEffect, useRef, useMemo} from 'react'
import { OrbitControls, Sparkles, Stars } from '@react-three/drei'
import { useLoader, useFrame } from '@react-three/fiber'
import { ContactShadows, Float,  PresentationControls } from '@react-three/drei'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import * as THREE from 'three'

import {vertexShader, fragmentShader} from './shaders/ParticlesUpShaders'

export default function Experience(props)
{

  const count = 100
  // This reference gives us direct access to our points
  const points = useRef();

  // Generate our positions attributes array
  const particlesPosition = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const distance = 1;
    
    for (let i = 0; i < count; i++) {
      // const theta = THREE.MathUtils.randFloatSpread(360); 
      // const phi = THREE.MathUtils.randFloatSpread(360); 

      // let x = distance * Math.sin(theta) * Math.cos(phi)
      // let y = distance * Math.sin(theta) * Math.sin(phi);
      // let z = distance * Math.cos(theta);

      // positions.set([x, y, z], i * 3);

      let x = 0
      let y = 1
      let z = 0


      positions.set([x, y, z], i * 3);
    }
    

    return positions;

  }, [count]);



  const particlesRotation = useMemo(() => {
    const rotations = new Float32Array(count * 3);
    const distance = 1;
    
    for (let i = 0; i < count; i++) {
      // const theta = THREE.MathUtils.randFloatSpread(360); 
      // const phi = THREE.MathUtils.randFloatSpread(360); 

      // let x = distance * Math.sin(theta) * Math.cos(phi)
      // let y = distance * Math.sin(theta) * Math.sin(phi);
      // let z = distance * Math.cos(theta);

      // positions.set([x, y, z], i * 3);

      let x = 0
      let y = 0
      let z = 0


      rotations.set([x, y, z], i * 3);
    }
    
    return rotations;

  }, [count]);





  useFrame((state) => {
    const { clock } = state;
    
    for (let i = 0; i < count; i++) {
      const i3 = i * 3;

      let x = (Math.random() - 0.5)/10
      let y = (Math.random() - 0.5)/10
      let z = (Math.random() - 0.5)/10

      // let rot_x = (Math.random() - 0.5)/10
      // let rot_y = (Math.random() - 0.5)/10
      // let rot_z = (Math.random() - 0.5)/10


      points.current.geometry.attributes.position.array[i3] += x
      points.current.geometry.attributes.position.array[i3 + 1] += y
      points.current.geometry.attributes.position.array[i3 + 2] += z


      // points.current.geometry.attributes.rotation.array[i3] += 0
      // points.current.geometry.attributes.rotation.array[i3 + 1] += 0
      // points.current.geometry.attributes.rotation.array[i3 + 2] += 0

      //console.log(points.current.geometry.attributes.rotation)


      // points.current.geometry.attributes.position.array[i3] += Math.sin(clock.elapsedTime + Math.random() * 10) * 0.01;
      // points.current.geometry.attributes.position.array[i3 + 1] += Math.cos(clock.elapsedTime + Math.random() * 10) * 0.01;
      // points.current.geometry.attributes.position.array[i3 + 2] += Math.sin(clock.elapsedTime + Math.random() * 10) * 0.01;


    }

    points.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={particlesPosition.length / 3}
          array={particlesPosition}
          itemSize={3}
        />

        <bufferAttribute
          attach="attributes-rotation"
          count={particlesRotation.length / 3}
          array={particlesRotation}
          itemSize={3}
        />

      </bufferGeometry>

      <pointsMaterial size={1.012} color="#5786F5" sizeAttenuation depthWrite={false} />

     {/* <shaderMaterial 
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={{}}
      />*/}

    </points>
  );
};