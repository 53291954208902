import { useState, useEffect, useContext } from 'react'
import { css, jsx } from '@emotion/react'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AvatarGroup from '@mui/material/AvatarGroup'

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import cn from '../../cn_meta.json'
import gc from '../../gc_meta.json'

import {Buffer} from 'buffer'
import AvatarStack from './AvatarStack'
import Unbake from './Unbake'
import {MainClaimContext} from './ClaimContext'


const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});

function Activity(props) {

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const clay_info = {'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728':{collection:'cn',base_name:'ClayNation'}, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768':{collection:'gc',base_name:'ClayCharlotte'}}
  

  const setTxsBaking = props.setTxsBaking
  const setTxsCompleted = props.setTxsCompleted
  const setTotalBaked = props.setTotalBaked


  const [accountTransactions, setAccountTransactions] = useState({})
  const [txsAssets, setTxsAssets] = useState([])
  const [activityInfo, setActivityInfo] = useState({})
  const [currentSlot, setCurrentSlot] = useState()
  const [rows, setRows] = useState()

  const [unbakingTxs, setUnbakingTxs] = useState({})




  const columns = [
    { id: 'txid', label: 'Transaction ID', minWidth: 10 },
    { id: 'status', label: 'Status', minWidth: 10 },
    { id: 'amount', label: 'Amount', minWidth: 10 },
    { id: 'assets', label: 'Assets', minWidth: 10 },
    { id: 'progress', label: 'Progress', minWidth: 10 },
  ];


  function getAssetImage(asset_id){
    let policy_id = asset_id.slice(0,56)
    let asset_name = asset_id.slice(56,)
    asset_name = Buffer.from(asset_name,'hex').toString('utf-8')
    let cur_collection = clay_info[policy_id].collection
    let cur_base_name = clay_info[policy_id].base_name
    let cur_serial = asset_name.slice(cur_base_name.length,)
    let cur_image = ''
    if (cur_collection == 'cn'){
      cur_image = cn[cur_serial]
    }else if (cur_collection == 'gc'){
      cur_image = gc[cur_serial]
    }
    return cur_image
  }


  async function fetch_current_slot(){
    let slot = await fetch('https://clayapi.com/baking/slot/').then(response => response.json())
                                                               .then(data => data)
    slot = parseInt(slot.slot)
    return slot

 }


   async function fetch_unbaking_txs(stake_address){
    if (stake_address){
      let unbaking_txs = await fetch('https://clayapi.com/unbaking/txs/'+stake_address+'/').then(response => response.json())
                                                             .then(data => data)
      //console.log(unbaking_txs)

      let txs_dict = {}
      if (stake_address == unbaking_txs['account_id']){
        let txs_list = unbaking_txs['unbaking_txs']

        for (let i = 0; i < txs_list.length; i++){
          let tx_info = txs_list[i]

          if (tx_info['unbaked_baking_tx_id'] != null){
            if (tx_info['unbaked_slot'] != null) {
              txs_dict[tx_info['unbaked_baking_tx_id']] = {status:'Unbaked',amount:tx_info['amount'], slot:tx_info['unbaked_slot']}
            }else{
              txs_dict[tx_info['unbaked_baking_tx_id']] = {status:'Unbaked',amount:tx_info['amount'], slot:'?'}
            }

          }else{

            if (tx_info['unbaking_slot'] != null) {
              txs_dict[tx_info['baking_tx_id']] = {status:'Unbaking', slot:tx_info['unbaking_slot']}
            }else{
              txs_dict[tx_info['baking_tx_id']] = {status:'Unbaking', slot:'?'}
            }


            
          }
        }
      }
      return txs_dict
    }else{
      return {}
    }
 }



  useEffect(() => {

    async function fetchActivity(stake_address){


        let tempTotalBaked = 0
        let tempTxsCompleted = 0
        let tempTxsBaking = 0


      if (!stake_address){
                
      let cur_rows = []

      for (let i = cur_rows.length; i < 6; i++){
      
              cur_rows.push({
                txid: "-",
                status: "",
                amount: "",
                assets: [],
                progress: "",
                temp_id: 'empty'+String(i)
              })
            }
          

          //console.log(cur_rows)
          setRows(cur_rows)

          return cur_rows
      }

      let slot = await fetch_current_slot()

      setCurrentSlot(slot)

      let txs = await fetch('https://clayapi.com/baking/transactions/'+stake_address+'/').then(response => response.json()).then(data => data)
      txs = txs[stake_address]

     //console.log(txs)
      //setAccountTransactions(txs)
      let assets = await fetch('https://clayapi.com/baking/transactions/assets/'+stake_address+'/').then(response => response.json()).then(data => data)
      //setAccountTransactions(assets)

      let unbaking_txs;
      try{
        unbaking_txs = await fetch_unbaking_txs(stake_address)
        //console.log('UNBAKING TXS')
        //console.log(unbaking_txs)
      }catch{
        unbaking_txs = {}
      }

      let cur_rows = []
      for (let i = 0; i < txs.length; i++){
        
        let cur_tx_id = txs[i]['tx_id']
        let cur_assets = assets[cur_tx_id]

        let asset_images = []
        for (let j = 0; j < cur_assets.length; j++){
           let cur_avatar = getAssetImage(cur_assets[j])
           asset_images.push(cur_avatar)
        }

        let cur_tx = txs[i]
        let tx_status = ''

        let tx_slot = -1

        if (cur_tx['processing'] != null){
          tx_status = 'processing'
          tx_slot = parseInt(cur_tx['processing'])

        }
        else if (cur_tx['failed'] != null){
          tx_status = 'failed'
          tx_slot = parseInt(cur_tx['failed'])
        }
        else if (cur_tx['baking'] != null){
          tx_status = 'baking'
          tx_slot = parseInt(cur_tx['baking'])

          if ((parseInt(slot) - tx_slot) >= (1209600 + 21600)){
              tx_status = 'completed'
              //console.log('COMPLETING...')
          }
        }
        else if (cur_tx['invalid'] != null){
          tx_status = 'invalid'
          tx_slot = parseInt(cur_tx['invalid'])
        }else{
          tx_status = '?'
        } 

        let tx_amount = '-'
        if (cur_tx['amount'] != null){
            tx_amount = cur_tx['amount']
        }


        if (tx_status == 'baking'){
          //console.log('FOUND BAKING')

          if (unbaking_txs && txs[i]['tx_id'] in unbaking_txs && unbaking_txs[txs[i]['tx_id']].status == 'Unbaked'){
            tempTxsCompleted = tempTxsCompleted + 1
          }else{
            tempTxsBaking = tempTxsBaking + 1
          }
        }



        if (tx_status == 'completed'){
            //console.log('FOUND COMPLETED')
            tempTxsCompleted = tempTxsCompleted + 1

            //console.log('COMPLETED')

            //console.log(unbaking_txs && txs[i]['tx_id'] in unbaking_txs)

            //console.log(txs[i])

            if (unbaking_txs && txs[i]['tx_id'] in unbaking_txs && unbaking_txs[txs[i]['tx_id']].status == 'Unbaked'){
              //console.log('HERE I AM')
              try{

                 let temp_amount = parseInt(unbaking_txs[txs[i]['tx_id']].amount)

                 if (temp_amount == 1){
                  temp_amount = 0
                 }

                 tempTotalBaked = tempTotalBaked + temp_amount
               }catch{
                tempTotalBaked = tempTotalBaked
               }
              
            }else{ 

            if (tx_amount){
              tempTotalBaked = tempTotalBaked + parseInt(tx_amount)
            }
          }

        } else if (unbaking_txs && txs[i]['tx_id'] in unbaking_txs) {

          if (unbaking_txs[txs[i]['tx_id']].status == 'Unbaked'){
             tx_status = 'unbaked'

             tx_slot = unbaking_txs[txs[i]['tx_id']].slot

             try{
                 let temp_amount = parseInt(unbaking_txs[txs[i]['tx_id']].amount)

                 if (temp_amount == 1){
                  temp_amount = 0
                 }

                 tempTotalBaked = tempTotalBaked + temp_amount
               }catch{
                tempTotalBaked = tempTotalBaked
               }
          }else if (unbaking_txs[txs[i]['tx_id']].status == 'Unbaking'){
             tx_status = 'unbaking'
             tx_slot = unbaking_txs[txs[i]['tx_id']].slot
          }

        }




        cur_rows.push({
          txid: cur_tx_id,
          status: tx_status,
          amount: tx_amount,
          assets: asset_images,
          progress: tx_slot
        })

      }



      setTxsBaking(tempTxsBaking)
      setTxsCompleted(tempTxsCompleted)
      setTotalBaked(tempTotalBaked)
      setUnbakingTxs(unbaking_txs)


      //console.log('SORTING')
      //console.log(cur_rows)
      cur_rows.sort((a, b) => parseInt(b.progress) - parseInt(a.progress));
      //console.log(cur_rows)

      if (cur_rows.length < 4){
        let temp = 4
        if (cur_rows.length == 0){
          temp = 6
        }




        for (let i = cur_rows.length; i < temp; i++){
        

          cur_rows.push({
            txid: "-",
            status: "",
            amount: "",
            assets: [],
            progress: "",
            temp_id: 'empty'+String(i)
          })
          }
      }

      //console.log(cur_rows)
      setRows(cur_rows)

      }

    fetchActivity(stake_address)
    fetch_unbaking_txs(stake_address)

        let interval = setInterval(() => {
            //console.log('REFRESHING...')
            //console.log(stake_address)
            fetchActivity(stake_address)
            fetch_unbaking_txs(stake_address)
        }, 30000)

        return () => clearInterval(interval)

  },[stake_address])

  function displayProgress(tx_status, tx_progress){
    if (tx_status == '?' && parseInt(tx_progress) == -1){
      return '?'
    }
    
    if (typeof(currentSlot) == 'undefined'){
      return ''
    }

    let slotDiff = parseInt(currentSlot) - parseInt(tx_progress)

    
    
    let baking_prefix = ''
    if (tx_status == 'baking' || tx_status == 'completed' ){
      if (slotDiff >= 1209600){
         if (slotDiff >= (1209600 + 21600) ){
         
          slotDiff = slotDiff - (1209600 + 21600)
          baking_prefix = 'finished '



        }else{
          return 'completing...'
        }
      }      else{
        return String(Math.floor(slotDiff / 86400)) + ' / 14 days' 
      }
    }

    if (tx_status == 'invalid' || tx_status == 'failed' || tx_status == 'processing' || tx_status == 'baking' || tx_status == 'completed' || tx_status == 'unbaked' || tx_status == 'unbaking'){

      if (slotDiff < 60){
        return baking_prefix + '< 1 minute ago'
      }

      else if (slotDiff < 3600){
        if (slotDiff < 120){
          return baking_prefix + String(Math.floor(slotDiff / 60)) + ' minute ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 60)) + ' minutes ago'
        }
      }

      else if (slotDiff < 86400){
        if (slotDiff < 7200){
          return baking_prefix + String(Math.floor(slotDiff / 3600)) + ' hour ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 3600)) + ' hours ago'
        }
      }

      else if (slotDiff < 604800){
        if (slotDiff < 172800){
          return baking_prefix + String(Math.floor(slotDiff / 86400)) + ' day ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 86400)) + ' days ago'
        }
      }

      else if (slotDiff < 2419200){
        if (slotDiff < 1209600){
          return baking_prefix + String(Math.floor(slotDiff / 604800)) + ' week ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 604800)) + ' weeks ago'
        }
      }

      else if (slotDiff < 31536000){
        if (slotDiff < 4838400){
          return baking_prefix + String(Math.floor(slotDiff / 2419200)) + ' month ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 2419200)) + ' months ago'
        }
      }

      else{
        if (slotDiff < 63072000){
         return baking_prefix + String(Math.floor(slotDiff / 31536000)) + ' year ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 31536000)) + ' years ago'
        }
      }
    }

    return tx_progress
  }

  return (
     <ThemeProvider theme={theme}>
     <Paper sx={{  width: '100%', overflow: 'hidden', background:'linear-gradient(0deg, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29)), linear-gradient(333.44deg, rgba(0, 0, 0, 0.1184) 0.07%, rgba(64, 47, 85, 0.32) 100%)' }}>
      <TableContainer sx={{ maxHeight: 600, zIndex: 1 }}>
        <Table stickyHeader aria-label="sticky table">
           <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={'center'}
                  style={{ minWidth: column.minWidth, backgroundColor: '#182139', borderBottom: '2px solid #2e3142', color:'bisque', fontSize: '15px'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

          {rows ? rows.map((row) => {
            return(
            <TableRow key={row.txid == "-" ? row.temp_id  : row.txid} >
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col1'+row.txid}> <Link style={{color:'bisque'}} target="_blank" underline="none" href={"https://cardanoscan.io/transaction/" + row.txid}>{row.txid == "-" ? null : row.txid.slice(0,10) +"....."+row.txid.slice(-10,)} </Link></TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col2'+row.txid}>
              {row.status == 'invalid' || row.status == 'failed' || row.status == 'processing' 
              || row.status == 'unbaking' || row.status == 'unbaked' ?  
              <Box component="img" sx={{height:'100%', width:'100px'}} src={"/" + row.status[0].toUpperCase() + row.status.slice(1) + ".svg"} /> 
              : unbakingTxs && (row.status == 'completed' || row.status == 'baking') && (row.txid in unbakingTxs)  ? 

               <Box component="img" sx={{height:'100%', width:'100px'}} src={"/" + unbakingTxs[row.txid].status + ".svg"} /> 

              :

              row.status == 'baking' ?


                      /*
                     <Box component="img" sx={{height:'100%', width:'100px'}} src={"/" + row.status[0].toUpperCase() + row.status.slice(1) + ".svg"} /> 
                      */
                     

                     
                    <Unbake txid={row.txid} amount={row.amount} />
                    
                  

               : 

               row.status == 'completed' ? 

               <Box component="img" sx={{height:'100%', width:'100px'}} src={"/" + row.status[0].toUpperCase() + row.status.slice(1) + ".svg"} /> 

               :null }</TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col3'+row.txid}> 
              {row.amount == '' ? null : row.amount == '-' ? row.amount : <Stack justifyContent="center" alignItems="center" direction="row" spacing={2} > 
              {unbakingTxs && row.txid in unbakingTxs && unbakingTxs[row.txid].status == 'Unbaked' ? 

              <div> 
                <span style={{color:'red', fontSize:'18px'}}> {parseInt(unbakingTxs[row.txid].amount) == 1 ? 0 : unbakingTxs[row.txid].amount} </span> 
                <span>/</span>
                <span> {row.amount} </span> 
              </div>  


              : row.amount} 

              <Avatar sx={{width:'20px',height:'20px', marginLeft:'5px'}} src="/CLAY_TOKEN_220x220.png" /> </Stack> } </TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col4'+row.txid}>
                  <AvatarGroup max={6} key={'gr'+row.txid}>
                    {row.assets.map((asset)=>{
                      return(
    
                      <AvatarStack asset={asset} key={row.txid + asset} width={40} height={40} />
                  
                      )
                    })
                    
                    }
                  </AvatarGroup>

              </TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col5'+row.txid}>{displayProgress(row.status, row.progress)}</TableCell>
            </TableRow>)
          })
          :  null

          }
          </TableBody>
        </Table>
      </TableContainer>
     </Paper>
     </ThemeProvider>
  );
}

export default Activity;
