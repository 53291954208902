import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'


function ChosenPair(props){

  const model = props.model
  const setAttributes = props.setAttributes

  useEffect(() => {
    

    if (typeof(model) != 'undefined'){
     
      let temp_attributes = []
      for (let key in model){
       
        if (model[key] != 'None'){
          temp_attributes.push(model[key])
        }
        
      }
      
      console.log(temp_attributes)
      setAttributes(temp_attributes)
    }

  }, [model])

return(
  
   <>

   </>
  
  )
}

export default ChosenPair;