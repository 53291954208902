import './CollectionCard.css'

import './TheStand.css'

import { useState, useEffect, useContext } from 'react'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import styled, { keyframes } from 'styled-components';

import {MainContext} from './Context/Context'

const Card = styled.div`
    position: absolute;
    text-align: center;
    border-radius: 10px;
    width: 272px;
    height: 384px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    color: black;
    display: block;
    background-color: transparent;
    text-align: center;

    &:hover {
      box-shadow: 0 0px 28px ${props => props.boxShadow || "#FF9C83"};
      cursor: pointer;
    }
  `

const CardText = styled.div`
    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color: ${props => props.color || "#FF9C83"};
    opacity: 0.9;
`

const cardCollections = {
    "cn":"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
    "gc":"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
    "pitches":"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e"
  }

const validCollections = {
    
    claynation: 'cn',
    goodcharlotte: 'gc',
    pitches: 'pitches'

}

const validCollectionsAbr = {

    cn: 'claynation',
    gc: 'goodcharlotte',
    pitches: 'pitches'

}


function ExperienceCard(props){

	const {currentApp, setCurrentApp} = useContext(MainContext)

	const {collection} = props

	const cn_image_url = 'collections/CN.svg'
  	const gc_image_url = 'collections/GC.svg'

  	const cardThemes = {
	    "cn":{title: "Clay Nation", image: cn_image_url, boxShadow: "#FF9C83", color:"bisque"},
	    "gc":{title: "Good Charlotte", image: gc_image_url, boxShadow: "#53C9EE", color: "bisque"},
	  }

	return(
		<>


			<div className="the-stand" style={{fontFamily:'ShortStack', transform:'scale(1.8)', marginTop:'-60px'}}>
				<div className="THESTAND-background">

					<div style={{width: '150px',
							    height: '178px',
							    backgroundColor: 'rgba(0, 0, 0, 0.273)',
							    alignItems: 'center',
							    borderRadius: '26px',
							    backdropFilter: 'blur(3px)',
							    backgroundImage: 'url(https://claynation.nyc3.cdn.digitaloceanspaces.com/clayspace/images/experiences/TheStand.jpg)',
							    backgroundSize: 'cover',
							    backgroundPosition: 'center'}}>
					</div>
				</div>


				<div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center'}}>
				<div style={{display:'flex', flexDirection:'column', 
							height:'60px',justifyContent:'space-between', 
							marginTop:'0px', alignItems:'center', 
							backgroundColor:''}}>

					<div className="THESTAND-title" style={{backgroundColor:'', width:'100%'}}>
						The Stand
					</div>

					<div style={{height:'30px', width:'100%', backgroundColor:''}}>
						<button className="enter-thestand-btn"
							onClick={() => {



								// console.log('Enter the Stand')
								setCurrentApp('theStand')
							}}
						>
							Closed
						</button>
						<div style={{color:'bisque', fontSize:'10px'}}>
							*Temporarily
						</div>
					</div>

				</div>
				</div>


			</div>


			{/*<div className={collection.toUpperCase()}
				style={{fontFamily:'ShortStack', transform:'scale(1.8)'}}

				onClick={() => {
					setCurrentApp('avatars3D')
				}}
			>
				<div className={collection.toUpperCase() + '-background'}>

					<img className={collection.toUpperCase() + '-img'} src={cardThemes[collection].image} alt={collection.toUpperCase() + "-image"} />
        			<div className={collection.toUpperCase() + '-title'}> {cardThemes[collection].title.toUpperCase()} </div>

				</div>
			</div>*/}
		</>
	)
}




function Experiences(props){

	const {isSideBarOpen, matchesS, matchesL} = props

	return(
		<>
			<div style={{display:'flex', justifyContent: isSideBarOpen && matchesS ? 'space-between' : 'center', minHeight:'500px',
		      width:'80%', flexWrap: 'wrap', backgroundColor:'', alignItems:'center', marginTop:'0px', backgroundColor: '', height:'100%',
		      marginLeft: isSideBarOpen && matchesS ? '320px' : '0px', marginTop:'', marginTop: matchesL ? '0px' : '40px', backgroundColor:''}}>

			     <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'wrap', backgroundColor:'', width:'100%'}}>

			        <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
			                    display:'flex', justifyContent:'center', alignItems:'center'
			      }}>
							<ExperienceCard collection={'cn'} />

					</div>
				</div>
			</div>
		</>
	)
}

export default Experiences;

