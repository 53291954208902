import {useReducer, useState, createContext, useEffect, useRef} from 'react'

import { useSearchParams } from "react-router-dom";

import GlobalStyle from './GlobalStyles';

import Wallet from './Wallet'
import EmptyWallet from './EmptyWallet'
import Activity from './Activity'
import Connect from './Connect'
import SideBar from './SideBar'

import Snow from './Snow/Snow'

import Footer from './FooterNew'

import Raffles from './Raffles'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';

import AppBar from '@mui/material/AppBar';

import Divider from '@mui/material/Divider';

import Chip from '@mui/material/Chip';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';



import './CnButton.css'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



import Carousel from './Carousel'


import Balance from './Balance'

import Switch from '@mui/material/Switch';


import AlertPopUp from './AlertPopUp'

import Loading from './Loading'

import SellPopUp from './SellPopUp'
import RafflePopUp from './RafflePopUp'

import ChooseCollection from './ChooseCollection'

import CollectionHandler from './CollectionHandler'

import WalletListings from './WalletListings'

import './ViewSwitch.css'

import './SideBar.css'

import './FrontPage.css'

import styled,  { keyframes } from 'styled-components';


import Avatars3D from './Avatars3D'
import Assets3D from './Assets3D'
import Experiences from './Experiences'

import MintsPortal from './Mints'
import ClayDashPortal from './ClayDash'



const validCollections = {
    
    claynation: 'cn',
    goodcharlotte: 'gc',
    pitches: 'pitches'

}



const validCollectionsAbr = {

    cn: 'claynation',
    gc: 'goodcharlotte',
    pitches: 'pitches'

}




const CategoryDivider = styled.div`
  width: 60%;
  min-width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.isSideBarOpen && props.matchesS ? '320px' : '0px'};
  color: bisque;
  font-family: shortstack;
  font-size: 24px;
  margin-top: 30px;
  background-color: ${props => props.backgroundColor ? 'var(--divider-bg-color-light)' : 'var(--divider-bg-color-dark)'};
  border-radius: 20px;
  box-shadow: inset 4px 4px 8px rgba(117, 151, 191, 0.3), inset -4px -4px 6px rgba(0, 0, 0, 0.3);
`;



const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;




const CategoryDivider2 = styled.div`
  width: 60%;
  min-width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.isSideBarOpen && props.matchesS ? '320px' : '0px'};
  color: bisque;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  margin-top: 30px;
  background: linear-gradient(to right, #f36d9d, #eb8c62);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  animation: ${fadeIn} 0.5s ease-in-out;

  &:hover {
    background: linear-gradient(to right, #eb8c62, #f36d9d);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
`;




const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});



const initialState = {
    activeWallet: '',
    address: '',
    stake_address: '',
    lovelace: null,
    tokens: [],
    selectedTokens: [],
    isVoteOpen: false,
    voteOption: null,
    voteProposal: null,
}


const actions = {
    SET_ACTIVE_WALLET: "SET_ACTIVE_WALLET",
    SET_ADDRESS: "SET_ADDRESS",
    SET_STAKE_ADDRESS: "SET_STAKE_ADDRESS",
    SET_LOVELACE: "SET_LOVELACE",
    SET_TOKENS: "SET_TOKENS",
    SET_SELECTED_TOKENS: "SET_SELECTED_TOKENS",
    SET_IS_VOTE_OPEN: "SET_IS_VOTE_OPEN",
    SET_VOTE_OPTION: "SET_VOTE_OPTION",
    SET_VOTE_PROPOSAL: "SET_VOTE_PROPOSAL",
    RESET: "RESET"
}

function reducer(state, action){
    switch (action.type){
        case actions.SET_ACTIVE_WALLET:
            return {...state, activeWallet: action.value};        
        case actions.SET_ADDRESS:
            return {...state, address: action.value};
        case actions.SET_STAKE_ADDRESS:
            return {...state, stake_address: action.value};
        case actions.SET_LOVELACE:
            return {...state, lovelace: action.value};
        case actions.SET_TOKENS:
            return {...state, tokens: action.value};
        case actions.SET_SELECTED_TOKENS:
            return {...state, selectedTokens: action.value};
        case actions.SET_IS_VOTE_OPEN:
            return {...state, isVoteOpen: action.value};
        case actions.SET_VOTE_OPTION:
            return {...state, voteOption: action.value};
        case actions.SET_VOTE_PROPOSAL:
            return {...state, voteProposal: action.value};
        case actions.RESET:
            return {...state, ...initialState}
        default:
            return state;
    }
}

export const MainClaimContext = createContext()

export function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        activeWallet: state.activeWallet,
        address: state.address,
        stake_address: state.stake_address,
        lovelace: state.lovelace,
        tokens: state.tokens,
        selectedTokens: state.selectedTokens,
        isVoteOpen: state.isVoteOpen,
        voteOption: state.voteOption,
        voteProposal: state.voteProposal,
        
        setActiveWallet: value => {
            dispatch({type: actions.SET_ACTIVE_WALLET, value})
        },
        setAddress: value => {
            dispatch({type: actions.SET_ADDRESS, value})
        },
        setStakeAddress: value => {
            dispatch({type: actions.SET_STAKE_ADDRESS, value})
        },
        setLovelace: value => {
            dispatch({type: actions.SET_LOVELACE, value})
        },
        setTokens: value => {
            dispatch({type: actions.SET_TOKENS, value})
        },
        setSelectedTokens: value => {
            dispatch({type: actions.SET_SELECTED_TOKENS, value})
        },
        setIsVoteOpen: value => {
            dispatch({type: actions.SET_IS_VOTE_OPEN, value})
        },
        setVoteOption: value => {
            dispatch({type: actions.SET_VOTE_OPTION, value})
        },
        setVoteProposal: value => {
            dispatch({type: actions.SET_VOTE_PROPOSAL, value})
        },
        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainClaimContext.Provider value={value}>
            {children}
        </MainClaimContext.Provider>
        )
}


export default function ClaimContext(props){

    /*
    const { params } = props.match;
    const { pathParameter } = params;
    */

    let [searchParams, setSearchParams] = useSearchParams();


    const [txsBaking, setTxsBaking] = useState(0)
    const [txsCompleted, setTxsCompleted] = useState(0)
    const [totalBaked, setTotalBaked] = useState(0)
    const [totalUnbaked, setTotalUnbaked] = useState("0")
    const [formattedClayAmount, setFormattedClayAmount] = useState()
    const [stakeADAAmount, setStakeADAAmount] = useState()
    const [stakedWithClay, setStakedWithClay] = useState()
    const [sideBarTab, setSideBarTab] = useState()
    const [isConnected, setIsConnected] = useState(false)
    const [isAccountView, setIsAccountView] = useState(false)
    const [assetsOwned, setAssetsOwned] = useState()
    const [currentSlot, setCurrentSlot] = useState()
    const [isSideBarOpen, setIsSideBarOpen] = useState(true)
    const [sideBarVariant, setSideBarVariant] = useState()
    const [searchBarMode, setSearchBarMode] = useState(false)
    const [leaderboardPage, setLeaderboardPage] = useState(1)
    const [assetsLoaded, setAssetsLoaded] = useState(false)
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [submittedTx, setSubmittedTx] = useState('')
    const [hasBaking, setHasBaking] = useState(false)
    const theme = useTheme();
    const [isWalletLoading, setIsWalletLoading] = useState(false)
    const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesM = useMediaQuery(theme.breakpoints.up('md'));
    const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));
    const [leaderboardData, setLeaderboardData] = useState([])
    const [recordsPage, setRecordsPage] = useState(1)
    const [fetchingFilteredAssets, setFetchingFilteredAssets] = useState(true)
    const [isAnalytics, setIsAnalytics] = useState(false)
    const [isSellOpen, setIsSellOpen] = useState(false)
    const [sellAsset, setSellAsset] = useState(null)
    const [curActiveWallet, setCurActiveWallet] = useState('')
    const [chosenCollection, setChosenCollection] = useState('')
    const [appliedFilters, setAppliedFilters] = useState({})
    const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(true)
    const [yourPitches, setYourPitches] = useState(false)
    const [stickPointers, setStickPointers] = useState(true)
    const [circlePointers, setCirclePointers] = useState(true)
    const [opacityFilter, setOpacityFilter] = useState(false)

    const pitchesFilters = {
                                yourPitches:yourPitches,
                                setYourPitches: setYourPitches,

                                stickPointers: stickPointers,
                                setStickPointers: setStickPointers,

                                circlePointers: circlePointers,
                                setCirclePointers: setCirclePointers,

                                opacityFilter: opacityFilter,
                                setOpacityFilter: setOpacityFilter
                            }


    const [selectedPlot, setSelectedPlot] = useState(null)
    const [sortOrder, setSortOrder] = useState('price_asc')
    const [yourAssets, setYourAssets] = useState(false)
    const [listedOnly, setListedOnly] = useState(false)
    const [notListedOnly, setNotListedOnly] = useState(false)
    const [raffleAsset, setRaffleAsset] = useState(null)

    const yourAssetsFilters = {

        yourAssets: yourAssets,
        setYourAssets: setYourAssets,

        listedOnly: listedOnly,
        setListedOnly: setListedOnly,

        notListedOnly: notListedOnly,
        setNotListedOnly: setNotListedOnly

    }


    const [searchQuery, setSearchQuery] = useState('')
    const [featuredRaffle, setFeaturedRaffle] = useState(null)



    const pageTop = useRef(null)

    useEffect(() => {
        
        try{
            pageTop.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
             });
        }catch{

        }

    }, [chosenCollection]);




    useEffect(() => {
        if (isSideBarOpen && !matchesM){
            setIsSideBarOpen(false)
        }

    }, [matchesM])


    async function updateView(viewName){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('view') + " = " + String(viewName) + '; ' + expires + "; path=/"
   }




    function checkView(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }

       return cookies_dict['view']
  }




    async function updateCollection(collectionName){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('collection') + " = " + String(collectionName) + '; ' + expires + "; path=/"
   }




    function checkCollection(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }

       return cookies_dict['collection']
  }




  {/*
  useEffect(() => {

        setTimeout(function checkViewInCookies(){
     
            let currentView = checkView()

            //console.log('CURRENT View')

            //console.log(currentView)

            if (typeof(currentView) == 'string'){
                    
                //console.log(currentView == 'accountView')

                setIsAccountView(currentView == 'accountView')
                
            }

          }, 100)


  },[])
*/}


/*
useEffect(() => {


    console.log(chosenCollection)


},[chosenCollection])
*/





useEffect(() => {

        let collection = searchParams.get('collection')

        console.log(collection)

        if (collection != null){

            if (collection in validCollections){
                setChosenCollection(validCollections[collection])
                //setSearchParams({});
            }

        }else{



        setTimeout(function checkCollectionInCookies(){
     
            let currentCollection = checkCollection()

            //console.log('CURRENT View')

            //console.log(currentView)

            if (typeof(currentCollection) == 'string'){
                    
                //console.log(currentView == 'accountView')

                setChosenCollection(currentCollection)
                
            }

          }, 100)
        }


  },[])




    return(
        <Provider>

        <GlobalStyle />

        <Balance assetsLoaded={assetsLoaded} setAssetsLoaded={setAssetsLoaded} assetsOwned = {assetsOwned} setAssetsOwned = {setAssetsOwned} currentSlot = {currentSlot} setCurrentSlot = {setCurrentSlot} isCompact={isSideBarOpen} isWalletLoading={isWalletLoading} /> 
    
        <Grid container style={{position:'absolute', overflow:'auto'}} >

          <Grid item xs={12}>



            {matchesXS ?
            <AppBar style={{backgroundColor:'transparent', boxShadow:'transparent',boxShadow: 'none', backdropFilter:'blur(1px)'}}>

             <Stack direction="row" spacing={2} style={{display:'flex', justifyContent:'flex-end', backgroundColor:'transparent'}}>


                



                {/*
                <div className='explorerToggle' onClick={()=>{

                     //console.log('View changed')
                            if (isAccountView){
                                updateView('explorerView')
                                setSearchBarMode(false)
                            }else{
                                updateView('accountView')
                            }
                            setIsAccountView(!isAccountView)

                }}>

                  {isAccountView ? 
                  <div className='notch2'>

                  <img className='accountImg' src='/account.png' />

                  </div>
                  :

                   <div className='notch'>

                  <img className='explorerImg' src='/explorer.png' />

                  </div>

              }

                    
             </div>
         */}




        


               
                <div style={{marginRight:'25px'}}>
                    <Connect  setCurActiveWallet={setCurActiveWallet} assetsOwned={assetsOwned} setIsWalletLoading={setIsWalletLoading} />
                </div>
               

            </Stack>
              
            </AppBar>


            : 



           <AppBar style={{backgroundColor:'transparent', boxShadow:'transparent',boxShadow: 'none', backdropFilter:'blur(1px)', display:'flex', justifyContent:'center', alignItems:'flex-end'}}>

             <Stack direction="column" spacing={2} style={{display:'flex', justifyContent:'flex-center', backgroundColor:'transparent'}}>



                {/*

                <div className='explorerToggle' onClick={()=>{

                     //console.log('View changed')
                            if (isAccountView){
                                updateView('explorerView')
                                setSearchBarMode(false)
                            }else{
                                updateView('accountView')
                            }
                            setIsAccountView(!isAccountView)

                }}>

                  {isAccountView ? 
                  <div className='notch2'>

                  <img className='accountImg' src='/account.png' />

                  </div>
                  :

                   <div className='notch'>

                  <img className='explorerImg' src='/explorer.png' />

                  </div>

              }
   
             </div>
         */}





               
                <div style={{marginRight:'25px'}}>
                    <Connect setCurActiveWallet={setCurActiveWallet} matchesS={matchesS} assetsOwned={assetsOwned} setIsWalletLoading={setIsWalletLoading} pushDown={true} />
                </div>

            </Stack>
              
            </AppBar>

        }


           
          </Grid>

          <Grid container justifyContent='center' alignItems='center' style={{height:'100vh'}} >



         {isAccountView && (isWalletLoading || !assetsLoaded) && sideBarTab  == 'Baking' || isAccountView && (isWalletLoading || !assetsLoaded) && sideBarTab == 'Activity' ? 


                <Grid container justifyContent='center' alignItems='center' style={{height:'70%'}}>
                <Grid item sm={isSideBarOpen ? 5 : 0} md={isSideBarOpen ? 3 : 1} lg={2} >
                      
                  </Grid>

                     <Grid item xs={6} sm={isSideBarOpen ? 5 : 9} md={isSideBarOpen ? 7 : 10} lg={8} xl={9} style={{display:'flex', justifyContent:'center', alignItems:'center', minWidth: matchesS && !isSideBarOpen ? '550px' : '220px'}}>
                   
                        <div>
                            <Loading matchesS={matchesS} />
                        </div>
            </Grid>

            </Grid>


            :



            sideBarTab && isAccountView  && sideBarTab == 'Baking' ?

          <Grid container justifyContent='center' alignItems='center' style={{height:'70%'}}>
        <Grid item sm={isSideBarOpen ? 5 : 0} md={isSideBarOpen ? 3 : 1} lg={2} >
              
          </Grid>

             <Grid item xs={6} sm={isSideBarOpen ? 5 : 9} md={isSideBarOpen ? 7 : 10} lg={7} xl={9} style={{display:'flex', justifyContent:'center', alignItems:'center', minWidth: matchesS && !isSideBarOpen ? '550px' : '220px'}}>
                
           
                <div>
                    <Wallet 

                    sellAsset={sellAsset}
                    setSellAsset={setSellAsset}

                    setHasBaking={setHasBaking} assetsOwned = {assetsOwned} setAssetsOwned = {setAssetsOwned} currentSlot = {currentSlot} setCurrentSlot = {setCurrentSlot} isCompact={isSideBarOpen} isWalletLoading={isWalletLoading} setIsWalletLoading={setIsWalletLoading} /> 
            </div>
    </Grid>

    </Grid>

        

    : sideBarTab && isAccountView  && sideBarTab  == 'Activity' ? 


    <div>
        <Activity />
    </div>


    /*
     <Grid container justifyContent='center' alignItems='center' style={{height:'100%'}}>
     
      <Grid item xs={0} sm={isSideBarOpen ? 5 : 0} md={isSideBarOpen ? 4 : 0} lg={2} xl={0} >
      </Grid>

         <Grid item xs={11} sm={isSideBarOpen ? 6 : 9} md={isSideBarOpen ? 7 : 10} lg={7} xl={8} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
           
           
            <Activity setTxsBaking={setTxsBaking} setTxsCompleted={setTxsCompleted} setTotalBaked={setTotalBaked} />
          
    </Grid>
    </Grid>
    */

            : sideBarTab && isAccountView && sideBarTab  == 'Staking' ? 


                <div>
                    <WalletListings sellAsset={sellAsset}
                    setSellAsset={setSellAsset}  />
                </div>
            

            : !isAccountView ? 


            <div style={{width:'100%'}}>
           

           {/*
           {chosenCollection == '' && matchesL ?
            <div style={{position:'absolute', marginBottom: matchesL || (matchesM && !isSideBarOpen)  ? '0px' : '70px', width:'100%', height:'200px', backgroundColor:'', top:'50px'}}>
                    <div style={{position:'absolute', display:'flex', top:'0px', width:'100%', backgroundColor:'', justifyContent:'flex-end', marginLeft:'-5px'}}>
                        <img src="/Marketplace.png" className="leaderboard-img" style={{width:'260px'}} />
                    </div>
            </div>

            : null}
            */}



        {/*CAROUSEL*/}


        {chosenCollection == '' ?



        <div 
            style={{position:'absolute', top: matchesS ? '120px' : '10px', left:'0px', width:'100%',  height:'350px',
            fontFamily:'ShortStack', marginBottom:'0px', display:'flex', justifyContent:'center', 
            backgroundColor:''}}
        >


        <div  ref={pageTop} style={{marginTop:'-100px'}}>

        </div>

      
        {/*
        style={{display:'flex', justifyContent: isSideBarOpen && matchesS ? 'space-between' : 'center', minHeight:'500px',width:'80%', 
        flexWrap: 'wrap', backgroundColor:'', alignItems:'center', marginTop:'0px',
        marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}
        */}

        <div style={{transform: !matchesS ? 'scale(0.8)' : 'scale(1.1)', marginLeft: isSideBarOpen && matchesS ? '300px' : '0px'}}>
            
            <Carousel featuredRaffle={featuredRaffle} />

        </div>
        
        </div>

       

        :null}



            {/*
             <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', fontFamily:'ShortStack', marginBottom:'100px'}}>

                


                <img src={'tree_banner.png'} style={{marginTop:'0px', objectFit:'cover',width:'100vw', height:'250px'}} />

             

             </div>

             */}



            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center'}}>

                {chosenCollection ? 

                   <CollectionHandler 

                   //path={'/collections/' + chosenCollection}

                   pitchesFilters={pitchesFilters} appliedFilters={appliedFilters} 

                   selectedPlot={selectedPlot} setSelectedPlot={setSelectedPlot}

                   setAppliedFilters={setAppliedFilters} currentSlot={currentSlot} 
                   matchesL={matchesL} matchesM={matchesM} matchesS={matchesS} matchesXS={matchesXS}
                   isSideBarOpen={isSideBarOpen} chosenCollection={chosenCollection} 
                   sellAsset={sellAsset} setSellAsset={setSellAsset} 

                   sortOrder={sortOrder} setSortOrder={setSortOrder}

                   yourAssetsFilters={yourAssetsFilters}

                   searchQuery={searchQuery}
                   setSearchQuery={setSearchQuery}

                   />


                :


            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                    position:'absolute', top: matchesS ?'480px' : '320px', backgroundColor: ''
                    }}>
            
            


            
          
                {/*
                <CategoryDivider isSideBarOpen={isSideBarOpen} matchesS={matchesS}>
                   Collections
                </CategoryDivider>
                */}


                <div style={{marginLeft: isSideBarOpen && matchesS ? '320px' : '0px', marginTop:'20px'}}>
                    <div className="line-left"></div>

                        <div className='title' style={{marginLeft:'-5px', fontFamily:'ClayNationFont', color:'white', fontSize:'35px'}}> COLLECTIONS </div>

                    <div className="line-right"></div>
                </div>
           
            

                   <ChooseCollection matchesS={matchesS} matchesM={matchesM} matchesL={matchesL} isSideBarOpen={isSideBarOpen} setChosenCollection={setChosenCollection} updateCollection={updateCollection} />








           
             <div style={{marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}>
                    <div className="line-left"></div>

                        <div className='title' style={{marginLeft:'-5px', fontFamily:'ClayNationFont', color:'white', fontSize:'35px'}}> ASSETS </div>

                    <div className="line-right"></div>
                </div>

                    <Assets3D
                        isSideBarOpen={isSideBarOpen}
                        matchesS={matchesS}
                        matchesL={matchesL}

                        setChosenCollection={setChosenCollection}
                    />




               




            {/*
                <div style={{marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}>
                    <div className="line-left"></div>

                        <div className='title' style={{marginLeft:'-5px'}}> MINTS </div>

                    <div className="line-right"></div>
                </div>



                  <MintsPortal
                        isSideBarOpen={isSideBarOpen}
                        matchesS={matchesS}
                        matchesL={matchesL}

                        setChosenCollection={setChosenCollection}
                    />
            */}

            
              <div style={{marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}>
                    <div className="line-left"></div>

                        <div className='title' style={{marginLeft:'-5px', fontFamily:'ClayNationFont', color:'white', fontSize:'35px'}}> AVATARS 3D </div>

                    <div className="line-right"></div>
                </div>


                    <Avatars3D 
                        isSideBarOpen={isSideBarOpen}
                        matchesS={matchesS}
                        matchesL={matchesL}
                    />
            


                 <div style={{marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}>
                    <div className="line-left"></div>

                        <div className='title' style={{marginLeft:'-5px', fontFamily:'ClayNationFont', color:'white', fontSize:'35px'}}> CLAY.DASH </div>

                    <div className="line-right"></div>
                </div>



                
                  <ClayDashPortal
                        isSideBarOpen={isSideBarOpen}
                        matchesS={matchesS}
                        matchesL={matchesL}

                        setChosenCollection={setChosenCollection}
                    />
               

                    <div style={
                        matchesL ? 
                            {marginBottom:'50px'} : 
                        matchesS ?
                            {marginBottom:'-50px'}
                        :
                            {marginBottom:'-100px'}
                    }>

                    </div>
                


               {/*
                <div style={{marginLeft: isSideBarOpen && matchesS ? '320px' : '0px'}}>
                    <div className="line-left"></div>

                        <div className='title' style={{marginLeft:'-5px'}}> Experiences </div>

                    <div className="line-right"></div>
                </div>
               
               
                     <Experiences
                        isSideBarOpen={isSideBarOpen}
                        matchesS={matchesS}
                        matchesL={matchesL}
                    />

                */}
               
                    


                 
                 <div style={{backgroundColor:'', width:'60%', minWidth:'300px', borderRadius:'50px',
                     marginLeft: isSideBarOpen && matchesS ? '320px' : '0px',
                     color:'bisque', fontFamily:'shortstack', fontSize:'24px',  marginTop:'30px', marginBottom:'50px'
                    }}>




                    <div style={{width:'100%', height:'50px'}}>

                    </div>



                    <Footer matchesS={matchesS} />

                </div>

            </div>


                }



            </div>



        </div>

            : null
        }



          </Grid>



           <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />

           <div className="hideScrollBar">
            <SideBar appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} 
                setChosenCollection={setChosenCollection} chosenCollection={chosenCollection} 
                stakedWithClay={stakedWithClay} setStakedWithClay={setStakedWithClay} 
                stakeADAAmount={stakeADAAmount} setStakeADAAmount={setStakeADAAmount} 
                totalUnbaked={totalUnbaked} setTotalUnbaked={setTotalUnbaked} 
                formattedClayAmount={formattedClayAmount} setFormattedClayAmount={setFormattedClayAmount} 
                isAnalytics={isAnalytics} hasBaking={hasBaking} submittedTx={submittedTx} 
                setSubmittedTx={setSubmittedTx} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} 
                fetchingFilteredAssets={fetchingFilteredAssets} setFetchingFilteredAssets={setFetchingFilteredAssets} 
                setSearchBarMode={setSearchBarMode} searchBarMode={searchBarMode} setCurrentSlot={setCurrentSlot} 
                isAccountView={isAccountView} setLeaderboardData={setLeaderboardData} txsBaking={txsBaking} 
                txsCompleted={txsCompleted} totalBaked={totalBaked} isAccountView={isAccountView} matchesXS={matchesXS} 
                matchesS={matchesS} matchesM={matchesM} matchesL={matchesL} isOpen={isSideBarOpen} setIsOpen={setIsSideBarOpen} 
                sideBarTab={sideBarTab} setSideBarTab={setSideBarTab}  assetsOwned={assetsOwned} currentSlot={currentSlot} 
                leaderboardPage={leaderboardPage} setLeaderboardPage={setLeaderboardPage} recordsPage={recordsPage} 
                setRecordsPage={setRecordsPage} 
                pitchesFilters={pitchesFilters}
                sortOrder={sortOrder} setSortOrder={setSortOrder}
                yourAssetsFilters={yourAssetsFilters}
                setSearchQuery={setSearchQuery}
                selectedPlot={selectedPlot} setSelectedPlot={setSelectedPlot}
                updateCollection={updateCollection}
            />
          </div>


        </Grid>



        {!matchesL ?
        <Grid container style={{position:'sticky', top:'90%', width:'25vw'}} >
            <Grid item xs={1}>
            </Grid>

            <Grid item xs={11} sm={8} md={8} style={{display:'flex', justifyContent:'center'}}>
                <button className='CN-btn' onClick={()=>{setIsSideBarOpen(true)}}><span className="CN-text" style={{fontFamily:'shortstack'}}>></span></button>
            </Grid>

        </Grid>

        : null}

        <Grid container justifyContent='center' alignItems='center' style={{height:'70%'}}>
          

          {sellAsset ?
          <SellPopUp curActiveWallet={curActiveWallet} setSellAsset={setSellAsset} sellAsset={sellAsset}  setIsAlertOpen={setIsAlertOpen} setSubmittedTx={setSubmittedTx} /> 
            : null
          }

         {raffleAsset ?
            <RafflePopUp raffleAsset={raffleAsset} setRaffleAsset={setRaffleAsset} />
         : null}


          <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
        
        </Grid>


        {/*<Snow />*/}

        </Provider>

        )
}


