import {useState, useEffect, useContext, useRef} from 'react'

//import './BuyButton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import {MainClaimContext} from 'components/ClaimContext'

import styled from 'styled-components';


import chest from '../Images/chest.gif'


import { VoxeliseButton, VoxeliseButton_Disabled } from '../Components/TransactionPannel.styled';


const MintButton = styled.button`
 background-color: var(--background);
 max-width: 120px;
 max-height: 60px;
 width: 100%; 
 height: 100%;
 border-radius: 23px;
 outline: 2px solid var(--claymint-primary); 
 border: none;
 box-shadow: 0px 0px 10px var(--claymint-primary);
 font-family: ButtonFont;
 font-size: 2rem;
 text-align: center;
 color: var(--claymint-primary);
 text-shadow: 2px 2px 0px black, 0px 0px 7px var(--claymint-primary);
 cursor: pointer;
 
 letter-spacing: 0.15rem;
 z-index: 1;

 &:hover{
 	 background-color: var(--background-hover);
 	 box-shadow: 0px 0px 15px var(--claymint-primary);
 }
`


const ButtonText = styled.div`

  position: relative;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  color: #FFFFFF;
  pointer-events: none; /* This makes the text ignore cursor events */


  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

`




// Styled components
const Container = styled.div`
  text-align: center;
  margin-top: 20px;
  position: relative;
`;

const Button = styled.button`
  display: block;
  margin: 20px auto;
  transition: opacity 1s ease-out;
`;

const Star = styled.svg`
  position: absolute;
  transition: opacity 1s ease-out, transform 1s ease-out;
  opacity: 0; /* Initially hidden */
  transform: scale(1); /* Initially normal size */
`;

const GifContainer = styled.div`
  text-align: center;
  margin-top: -95px;
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 1s ease-out, visibility 1s ease-out;

  img {
    width: 50%;
    min-width: '200px'
    max-width: 300px;
  }
`;






function Mint(props){



	const {
			 token_name, price,
		   isAlertOpen, setIsAlertOpen, 
		   submittedTx,setSubmittedTx,

		   selectedTokens,
		   setSelectedTokens,

		   setTransactionStatus,

		   setButtonPopup,

		   mintPasses,

		   setMintPasses,

       mintFee

		 } = props

	const {activeWallet} = useContext(MainClaimContext)

	const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)




  const [starsVisible, setStarsVisible] = useState(false);
  const [gifVisible, setGifVisible] = useState(false);
  const gifRef = useRef(null);



  const showStars = () => {
  	console.log('SHOW STARS')
    setStarsVisible(true);
    // Reset the stars animation after showing
    setTimeout(() => {
      setStarsVisible(false);
    }, 1500); // Adjust this time as necessary
  };

  const showGif = () => {
    const gif = gifRef.current;
    if (gif) {
      // Show the GIF
      setGifVisible(true);
      // Reload the GIF by resetting its src
      gif.src = gif.src;

      // Hide the GIF after it finishes playing
      setTimeout(() => {
        setGifVisible(false);


        notifySuccess('Transaction submitted!')
			  setSelectedTokens([])
			  setTransactionStatus('')

      }, 2500); // Adjust timeout to match GIF duration
    }
  };



  function keyhashFromAddress(address){
    
    let temp;
    let stake_vkey = ""

    try{
      temp = serLib.BaseAddress.from_address(serLib.Address.from_bech32(address)).payment_cred().to_keyhash()
    }catch{
      temp = serLib.EnterpriseAddress.from_address(serLib.Address.from_bech32(address)).payment_cred().to_keyhash()
    }

    let pubkeyhash = Buffer.from(temp.to_bytes()).toString('hex')

    return pubkeyhash
  }


  async function postTransaction(signedTx, stake_address){
    const data = {'tx':signedTx,'stake_address':stake_address}
    const response = await fetch('https://claymarket.io/api/submit/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}



   async function submitTransaction(unsignedTx_hash, witness){

   	let submit_url = 'https://claymarket.io/minting/vox_unlock/'

   	const data = {tx:unsignedTx_hash, witness: witness}

    const response = await fetch(submit_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })



    return response.json()

}


  function pubkeyFromAddress(address){
    let paymentKeyPub = serLib.BaseAddress.from_address(serLib.Address.from_bech32(address)).payment_cred()

    return paymentKeyPub
  }


  function createPolicy(vkey_pubkey, expiry_slot){
    const nativeScripts = serLib.NativeScripts.new();
    const nativeScript = serLib.NativeScript.new_script_pubkey(vkey_pubkey);

    nativeScripts.add(nativeScript);


    nativeScripts.add(
      serLib.NativeScript.new_timelock_expiry(
        serLib.TimelockExpiry.new_timelockexpiry(
          serLib.BigNum.from_str(String(expiry_slot))
        )
      )
    )

    const finalScript = serLib.NativeScript.new_script_all(
      serLib.ScriptAll.new(nativeScripts)
    );

    const finalScriptHash = Buffer.from(finalScript.to_bytes()).toString("hex")

    const policy_id = Buffer.from(
      serLib.ScriptHash.from_bytes(
        finalScript.hash().to_bytes()
        ).to_bytes(), "hex").toString("hex");

    const policy_info = {id:policy_id, script: finalScript, scriptHash: finalScriptHash}

    return policy_info
  }

  async function setInitialFunction(protocolParams, receiver_encoded){

  let txBuilder_1 = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder_1.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+500000)))
      ),
  );

  return txBuilder_1

}

  async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_byte = String(protocolParams.coins_per_utxo_size)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_byte(serLib.BigNum.from_str(coins_per_utxo_byte))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}


async function getUtxos(cardano){
  const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
  const utxos = (await cardano.getUtxos()).map((utxo) =>
    txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
  );
  return txUnspentOutputs
}

  
async function getParameters(){
  const protocolParams = await fetch('https://claymarket.io/minting/protocolparams/mainnet/', {
        method: 'GET',
       })
  const json_params = await protocolParams.json()
  return json_params
}


async function getCurrentSlot(){
    let slot = await fetch('https://claymarket.io/minting/currentslot/mainnet/', {
        method: 'GET',
        }).then(response => response.json()).then(data => data)

    slot = parseInt(slot.slot, 10)

    return slot
}


function get_required_signers(utxos){
    let required_keys = []

    for (let i = 0; i < utxos.length; i ++){
        let utxo = utxos[i]
        let utxo_output_address = utxo.output().address()
        //console.log(utxo_output_address.to_bech32())

        let utxo_key_hash = Buffer.from(serLib.BaseAddress.from_address(utxo_output_address).payment_cred().to_keyhash().to_bytes()).toString('hex')

        if (!required_keys.includes(utxo_key_hash)){
            required_keys.push(utxo_key_hash)
        }

    }
    return required_keys
}


function compare_inputs(input_1, input_2){
    let tx_hash_1 = Buffer.from(input_1.transaction_id().to_bytes()).toString('hex')
    let tx_index_1 = input_1.index()

    let tx_hash_2 = Buffer.from(input_2.transaction_id().to_bytes()).toString('hex')
    let tx_index_2 = input_2.index()

    //console.log(tx_hash_1 + '#' + tx_index_1)
    //console.log(tx_hash_2 + '#' + tx_index_2)

    return (tx_hash_1 == tx_hash_2 && tx_index_1 == tx_index_2)
}


async function enableWallet(){


	
	let cardano = await window.cardano[activeWallet]
    cardano = await cardano.enable()

    return cardano
	

}


async function getMintAddresses(){

  return [
    'addr1vxrzszmzvxn2qmyyj9qde64vfdk0lfkfdvztlak3fapedpgtpdklm', 
    'addr1vypk00grg93g7j59meffmkn82kqq2zvkqy76p38ty87ydhq5xzsqf', 
    'addr1v9uhggslkk9x6992upmr5xr8s9z3kauf6q5dqanuwjturycmjr779', 
    'addr1vxyx03jj5dq8kv9lgspfp2gz7e8umgr9q50pca9s068whfg3n7ajv', 
    'addr1v89v9uale4tkwsezwsfr2taqtxccdlcfg308suvtcrc0a8c5ykkw7', 
    'addr1vxufru9f5a0q42ft576x8v68duys2vc7y4krvkzpwdwe9xqh83uxp', 
    'addr1vycaxkhjjhga7y6v7nxn5qu42x69y4q544h6jv8pzyf5e5g6lrwxh', 
    'addr1vx4cqh6tyyqnzvczvnca4zgpl6gqydwhwyy5n0rpp28cuccq2mdet', 
    'addr1v95dn2tyth09qaycucvaagn8e4y9g9444ah7v2lvam4hynqlgqtpg', 
    'addr1vxx7g2tkgx5u0heqtg8kp83dzy6prek6p9u7u8ar3f9qyaccl9rhs', 
    'addr1v97wxntwa044zgjmdndchxwurae948uyv95nj5rekzrlhwqy7yerq', 
    'addr1v8gqcmx9nyk6qxprmfc8mzhjmgqqqr7a5lyn4lt6f86cgcqfrezfw', 
    'addr1vx9ghgr4chu55cr3cjg0u7vpng64ptprumj4n8de2ws4glqv6cc7r', 
    'addr1vxeqp65x7uz8j2yu9cm8ksf9ddc2vafc8cv6kmny0jj0vfq6njrg3', 
    'addr1vxmpcm38qm9lfqw5uyw36ujau58kgwxz7wegdkq7pqgq4zc4t7thk', 
    'addr1v9tkv73x6vqk4lwp74lrv723g9rnnwcmkfpwy64ns2f9mustv86hv', 
    'addr1vykxpxr6nzqldf8mg0ed4r07seh85nkvqqvls2x4cmvmgkg73vllj', 
    'addr1v9y4k9f9kyfgwsjleum072qm9mzvhpcspwpnhuue3a905ss3wynwm', 
    'addr1v8kp9fyxkg8p0aehnxc5gtcxp2hguerfxdx2eerqm8g6e7q9vtyvh', 
    'addr1v88wec5lzftnpha2endp5pqlqr6f7tazgjgtjf6vx34jeac52kq97', 
    'addr1vygyshle80zf8ch7en9f8p5c0c5cpe0ke9zwvs05ckcxh3crfhank', 
    'addr1vyyjcf3w8cdj78r0gpv6a42gye7gnzaw7gf4vz4ggl7p3mcr9l8zv', 
    'addr1v9pnhmc8u9432jtj274jq430l9vz4ntcpsjg4wq7h3zcx8cvht0dj', 
    'addr1vywdhk2unuxhx828vv574gwmvl5pg3q9673zfkaydeq686gyj3pv4', 
    'addr1vx9mlt5e4s43t0h58jyqzn0wxu6fp3au0dxwk20ncjjzxashmsvtq', 
    'addr1v8ycyys4zv7aryveal9v2yfac96td6r0xm5lxk0fvnudm8stz2q65', 
    'addr1v83vqnec8clndglpfcwwkfsly5vsvy4j3r4znmksh0xxn7g2e8vzn', 
    'addr1v9nat8smkf8en5mqvnt6677tdpl80rv3ns46s3ccvezykwgwfc8un', 
    'addr1vx000us2fkymls9chpy5gqg0zx8ql3ft3vfy034sh0s86hgqqp8ne', 
    'addr1vyezypdkv6kr84kdvgfc0wunjh6zxs2vgat2a07tgfjkqts6l65ry']
}


	async function mint(mintFee, mintPasses){

		// console.log(tokensToUpgrade)

		// const VAULT_ADDRESS = 'addr1z8h83n06fe9mtujvteru4ht5ys3thstu7j6rcv3f9w56mw90fm4l4jflstl7hz7ucs5793gzr297g67psdx8dssdf68s0uyv7y'
		

    // const LOCK_ADA_ADDRESS = 'addr1zyua2ur4muk2xmqqq6k49x3c3enfrfzereqxqufvl543q4a0fm4l4jflstl7hz7ucs5793gzr297g67psdx8dssdf68suf9tl8'

    const mint_addresses = await getMintAddresses()

    if (mint_addresses.length < 1){
      notifyError('Something went wrong...')

      return
    }

    const LOCK_ADA_ADDRESS = mint_addresses[Math.floor(Math.random() * mint_addresses.length)]



		setTransactionStatus('Building Transaction...')
		// console.log('\n\n\n')
		// console.log('Mint here...')
		// console.log(token_name)
		// console.log(price)
		// console.log('\n\n\n')

		//notifyInfo('Building transaction...')

		const paymentAddress = LOCK_ADA_ADDRESS
		//const lock_address = 'addr1q8yzpm3qgjjkrcljfjfktpuxgkndmthx4s5pkelpy2klkwvknghq34nkduscez4es79w2rpu8gkawtc096uppp7kmupqyrh79q'

	

		let lovelacePayment = mintFee*1000000 + mintPasses*5000000

		//const paymentAddress = 'addr1qxpwwrpushzsfzhmgen8tjqwd7l26vfrsv9scf9xpaftx8gr7dxpjc0fjweud2rszzr3kmdxgxx8czl6pa3zcgwt98cstzrjs0'

		//const BACKGROUNDS_POLICY = ''

		let cardano;

		try{
			cardano = await enableWallet()
		}catch{
			setTransactionStatus('')
			notifyWarn('could not connect...')
			return
		} 
		
    	let address_encoded;

    	let address;

    	try{
    		address_encoded = await cardano.getUsedAddresses()
	    	address_encoded = address_encoded[0]
	    	address = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()
			}catch{
				setTransactionStatus('')
				notifyWarn('could not fetch address...')
				return
			}


	    
	    let buyer = address 
	    let buyer_encoded = serLib.Address.from_bech32(buyer)

	    let seller = paymentAddress
	    let seller_encoded = serLib.Address.from_bech32(seller)

	    const protocolParams = await getParameters()
   		const currentSlot = await getCurrentSlot()

   		const ttl = parseInt(currentSlot) + 7200
    	const utxos = await getUtxos(cardano)
    	//const lovelacePayment = mintPasses * 5000000 + 1500000


    	let txBuilder = await get_txBuilder(protocolParams)



      //policy
	    // let policy_signer_addr = 'addr1q8r3898h0wdll4ze34kzffxtavsat394mh2gcguwq5k7nlpfy9rt9uyx8nvvtjgpvnk4scf27ljn3h0f3elvk6e37hfs96ra5q'

	    // const policy_signer_keyhash = keyhashFromAddress(policy_signer_addr)
	    // let addr_keyhash = serLib.BaseAddress.from_address(serLib.Address.from_bech32(policy_signer_addr)).payment_cred().to_keyhash()
	    // let vkey_pubkey = serLib.ScriptPubkey.new(addr_keyhash) //Ed25519KeyHash
	    
	    // let policy_slot = 99798689 + 432000 * 6 * 12 * 10
    	// let policy = createPolicy(vkey_pubkey, policy_slot)


	    // const policyScript = policy.script
	    // const policyScriptHash = policy.scriptHash

	    // const policy_id = policy.id

	    // console.log(policy)

	    //console.log(policyScriptHash)

      //let script_hash = serLib.ScriptHash.from_bytes(Buffer.from(BACKGROUNDS_POLICY, "hex"))

      // let mintBuilder = serLib.MintBuilder.new()

      // 	//222
	    // mintBuilder.add_asset(
	    //   serLib.MintWitness.new_native_script(policyScript),
	    //   serLib.AssetName.new(
	    //     Buffer.from('000de140' + Buffer.from(token_name,'utf8').toString('hex'), 'hex')
	    //   ),
	    //   serLib.Int.from_str('1')
	    // )

	    // txBuilder.set_mint_builder(mintBuilder)








    	





	    // //Mint, NativeScripts
	    // let mintObject = serLib.Mint.new()


	    // let assets_to_mint = serLib.MintAssets.new()

	    // tokensToUpgrade.map((tokenToUpgrade) => {
	    // 	assets_to_mint.insert(
	    // 		serLib.AssetName.new(
		  //       Buffer.from(tokenToUpgrade.slice(56,), 'hex')
		  //     ),
	    //   serLib.Int.from_str('1')
	    // 	)
	    // })


	    // //ScriptHash, MintAssets
	    // mintObject.insert(
	    // 	policyScript.hash(),
	    // 	assets_to_mint
	    // )

	    // let mint_scripts = serLib.NativeScripts.new()

	    // mint_scripts.add(
	    // 	policyScript
	    // )

	    // txBuilder.set_mint(mintObject, mint_scripts)


	    // let multiAsset = serLib.MultiAsset.new()
      // 	let assets = serLib.Assets.new()


      // tokensToUpgrade.map((tokenToUpgrade) => {

      // 	lock_lovelace += 1500000

      // 	assets.insert(
	    //   serLib.AssetName.new(
	    //      Buffer.from(tokenToUpgrade.slice(56,), 'hex')
	    //   ),
	    //   serLib.BigNum.from_str('1')
	    // )
      // })

      


	    // multiAsset.insert(
	    //   policyScript.hash(),
	    //   assets
	    // )


	    // let token_output = serLib.TransactionOutput.new(
	    //         lock_address_encoded,
	    //         serLib.Value.new_with_assets(
	    //           serLib.BigNum.from_str(String(lock_lovelace)),
	    //           multiAsset
	    //         )
	    //     )


	    // txBuilder.add_output(token_output);







	    let payment_output = serLib.TransactionOutput.new(
	            seller_encoded,
	            serLib.Value.new(
	              serLib.BigNum.from_str(String(lovelacePayment)),
	            )
	        )



	    txBuilder.add_output(payment_output);

	    txBuilder.set_ttl_bignum(serLib.BigNum.from_str(String(ttl)));





	     try{
		      txBuilder.add_inputs_from(utxos, 2) 
		    }catch (err){
		      // console.log('err')
		      // console.log(err)

		      notifyWarn('balance is not sufficient')
		      return
		    }

    	

	   	try{
	      txBuilder.add_change_if_needed(buyer_encoded)
	    }catch(err){
	      //console.log(err)
	    	setTransactionStatus('')
	      notifyWarn('could not add change')
	      return
	    }


	    let unsignedTx;

	    try{
	       unsignedTx = txBuilder.build_tx()
	    }catch(err){
	      unsignedTx = ''
	      // console.log(err)
	      return
	    }
	    

	    // console.log(unsignedTx.to_json())

	    const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')

	    let tx_witness;

	    try{
	    	setTransactionStatus('Signing Transaction...')
	    	tx_witness = await cardano.signTx(unsignedTx_hash, true)
	    }catch{
	    	setTransactionStatus('')
	    	notifyWarn('declined to sign')
	    	return
	    }
	    
	    //notifyInfo('submitting...')
	    
	    console.log(unsignedTx_hash)
	    console.log(tx_witness)

	    //return


	    setTransactionStatus('Submitting Transaction...')

	    //let status = await submitTransaction(unsignedTx_hash, tx_witness)

	    const txBody = unsignedTx.body()


	    let wallet_witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))


	    const totalVkeys = serLib.Vkeywitnesses.new();


	    for (let i = 0; i < wallet_witness_set.vkeys().len(); i++){
	                let holder_vkey = wallet_witness_set.vkeys().get(i)
	                totalVkeys.add(holder_vkey)
	        }


	    const totalWitnesses = serLib.TransactionWitnessSet.new();
	    totalWitnesses.set_vkeys(totalVkeys);


	    let tx_auxiliary_data = unsignedTx.auxiliary_data()


	    const signedTx = serLib.Transaction.new(
	                txBody,
	                totalWitnesses,
	                tx_auxiliary_data
	        )

	    const signedTxHash = Buffer.from(
	                signedTx.to_bytes()
	        ).toString('hex')

	    try{
	    	let status = await cardano.submitTx(signedTxHash)

	    	//showGif()

	    	console.log('STATUS')
	    	console.log(status)

	    	if (typeof status === 'string' || status instanceof String){
          notifySuccess('Transaction submitted!')
	    		//showGif()


	    		

			    //showGif()

          setMintPasses(0)

	    	}else{
	    		notifyError('Failed to submit...')
		    	setTransactionStatus('')
		    	return
	    	}

	    	




	    	//setIsAlertOpen(true)
		    //setSubmittedTx(status['TXID'])

		    

	    }catch{

	    	notifyError('Failed to submit...')
	    	setTransactionStatus('')
	    	return

	    }
	    

	    // let status = {}
	    // try{
	    // 	let status = await cardano.submitTx(signedTxHash)
	    // }catch{
	    // 	notifyError('Failed to submit...')
	    // 	setTransactionStatus('')
	    // }
	    


	    //const status = await postTransaction(signedTxHash, 'lock_for_baked')

	    //console.log(status)

	    // console.log('SIGNED TX HASH')
	    // console.log(signedTxHash)


	    // try{
	    // 	let status = ''
	    // }catch{

	    // }
	    



	    // if (status.hasOwnProperty('TXID')){
	    // 	setIsAlertOpen(true)
	    // 	setSubmittedTx(status['TXID'])
	    // 	setSelectedTokens([])

	    // 	setTransactionStatus('')

	    // }else{
	    // 	notifyError('Failed to submit...')
	    // 	setTransactionStatus('')
	    // 	return
	    // }	



	}


	return(
		<>

		{mintFee <= 0 ?
			

			<Container>

			<VoxeliseButton_Disabled type="button"
				style={{ opacity: gifVisible ? '0' : '1' }}
			><ButtonText>Mint</ButtonText></VoxeliseButton_Disabled>


			</Container>

			:

			<>

			<Container>
				<VoxeliseButton type="button" id="myButton"
            onClick={() => {
            	if (mintFee >= 0){
            		// console.log('LOCK')
            		// console.log('Mint Passes')
            		// console.log(mintPasses)
            		

                mint(mintFee, mintPasses)

            		showStars()

            		 //showGif()
            	}
            }} 
            style={{ opacity: gifVisible ? '0' : '1' }}
            ><ButtonText>Mint</ButtonText></VoxeliseButton>



      


            {/* Star SVGs */}
      <Star
        className="star star1"
        style={{ left: '30%', top: '30%', pointerEvents:'none', transform: starsVisible ? 'scale(3)' : 'scale(1)', opacity: starsVisible ? '1' : '0' }}
        width="50"
        height="50"
        viewBox="0 0 925 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M62.523 359.646C66.1702 347.18 83.8298 347.18 87.477 359.646L93.0198 378.592C94.1024 382.292 96.7717 385.321 100.307 386.861L122.629 396.581C133.042 401.115 133.042 415.885 122.629 420.419L100.307 430.139C96.7717 431.679 94.1024 434.708 93.0198 438.408L87.477 457.354C83.8298 469.82 66.1702 469.82 62.523 457.354L56.9802 438.408C55.8976 434.708 53.2283 431.679 49.6934 430.139L27.3711 420.419C16.9581 415.885 16.9581 401.115 27.3711 396.581L49.6934 386.861C53.2283 385.321 55.8976 382.292 56.9802 378.592L62.523 359.646Z"
          fill="#F2BC23"
        />
      </Star>

      <Star
        className="star star2"
        style={{ left: '50%', top: '0%', pointerEvents:'none', transform: starsVisible ? 'scale(3)' : 'scale(1)', opacity: starsVisible ? '1' : '0' }}
        width="50"
        height="50"
        viewBox="0 0 925 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M788.523 42.6464C792.17 30.1803 809.83 30.1803 813.477 42.6465L819.02 61.592C820.102 65.2924 822.772 68.3213 826.307 69.8606L848.629 79.581C859.042 84.1155 859.042 98.8845 848.629 103.419L826.307 113.139C822.772 114.679 820.102 117.708 819.02 121.408L813.477 140.354C809.83 152.82 792.17 152.82 788.523 140.354L782.98 121.408C781.898 117.708 779.228 114.679 775.693 113.139L753.371 103.419C742.958 98.8845 742.958 84.1155 753.371 79.581L775.693 69.8606C779.228 68.3213 781.898 65.2924 782.98 61.592L788.523 42.6464Z"
          fill="#F2BC23"
        />
      </Star>



       <Star
        className="star star2"
        style={{ left: '45%', top: '-35%', pointerEvents:'none', transform: starsVisible ? 'scale(2)' : 'scale(1)', opacity: starsVisible ? '1' : '0' }}
        width="50"
        height="50"
        viewBox="0 0 925 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M788.523 42.6464C792.17 30.1803 809.83 30.1803 813.477 42.6465L819.02 61.592C820.102 65.2924 822.772 68.3213 826.307 69.8606L848.629 79.581C859.042 84.1155 859.042 98.8845 848.629 103.419L826.307 113.139C822.772 114.679 820.102 117.708 819.02 121.408L813.477 140.354C809.83 152.82 792.17 152.82 788.523 140.354L782.98 121.408C781.898 117.708 779.228 114.679 775.693 113.139L753.371 103.419C742.958 98.8845 742.958 84.1155 753.371 79.581L775.693 69.8606C779.228 68.3213 781.898 65.2924 782.98 61.592L788.523 42.6464Z"
          fill="#F2BC23"
        />
      </Star>



       </Container>




        <GifContainer id="gifContainer" isVisible={gifVisible}>
        {/*<img
          ref={gifRef}
          src={chest}
          alt="GIF"
        />*/}
     	 </GifContainer>



       </>
		}
			  
        

			{/*<div style={{width:'100%', height:'80%', backgroundColor:'',
	    							display:'flex', justifyContent:'center', alignItems:'center'
	    				}}

	    				onClick={()=>{
	    					mint(price, selectedTokens)
	    				}}

	    				>

	    				<MintButton>

	    					MINT

	    				</MintButton>

	    			</div>*/}

		</>
	)
}
export default Mint;




