import {useState, useEffect, useContext} from 'react'

import './BuyButton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import {MainClaimContext} from '../ClaimContext'

import bg_meta from './json/meta.json'

import styled from 'styled-components';


const MintButton = styled.button`
 background-color: #ED1A70;
 max-width: 120px;
 max-height: 60px;
 width: 100%; 
 height: 100%;
 border-radius: 1rem;

 border: none;

 font-family: ButtonFont;
 font-size: 2rem;
 text-align: center;
 
 color: beige;

 cursor: pointer;
 
 letter-spacing: 0.15rem;
 z-index: 1;

 font-family: ClayNationFont;

 &:hover{
 	 background-color: #5870F6;
 }
`


function Mint(props){

	const {token_name, price,
			   isAlertOpen, setIsAlertOpen, 
			   submittedTx,setSubmittedTx, cartItems, setCartItems,
			   hoverImageStyle, normalImageStyle, hoverOmenImageStyle, 
			   normalOmenImageStyle, img_url, setCardImageStyle} = props



	const {activeWallet} = useContext(MainClaimContext)

	const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  function add_to_cart(token_name){
  	console.log('add to cart...')
  	console.log(token_name)

  	setCartItems([...cartItems, token_name])


  }


  function keyhashFromAddress(address){
    
    let temp;
    let stake_vkey = ""

    try{
      temp = serLib.BaseAddress.from_address(serLib.Address.from_bech32(address)).payment_cred().to_keyhash()
    }catch{
      temp = serLib.EnterpriseAddress.from_address(serLib.Address.from_bech32(address)).payment_cred().to_keyhash()
    }

    let pubkeyhash = Buffer.from(temp.to_bytes()).toString('hex')

    return pubkeyhash
  }



   async function submitTransaction(unsignedTx_hash, witness){

   	let submit_url = 'https://claymarket.io/minting/ada_handles/'

   	const data = {tx:unsignedTx_hash, witness: witness}

    const response = await fetch(submit_url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })



    return response.json()

}


  function pubkeyFromAddress(address){
    let paymentKeyPub = serLib.BaseAddress.from_address(serLib.Address.from_bech32(address)).payment_cred()

    return paymentKeyPub
  }


  function createPolicy(vkey_pubkey, expiry_slot){
    const nativeScripts = serLib.NativeScripts.new();
    const nativeScript = serLib.NativeScript.new_script_pubkey(vkey_pubkey);

    nativeScripts.add(nativeScript);


    nativeScripts.add(
      serLib.NativeScript.new_timelock_expiry(
        serLib.TimelockExpiry.new_timelockexpiry(
          serLib.BigNum.from_str(String(expiry_slot))
        )
      )
    )

    const finalScript = serLib.NativeScript.new_script_all(
      serLib.ScriptAll.new(nativeScripts)
    );

    const finalScriptHash = Buffer.from(finalScript.to_bytes()).toString("hex")

    const policy_id = Buffer.from(
      serLib.ScriptHash.from_bytes(
        finalScript.hash().to_bytes()
        ).to_bytes(), "hex").toString("hex");

    const policy_info = {id:policy_id, script: finalScript, scriptHash: finalScriptHash}

    return policy_info
  }

  async function setInitialFunction(protocolParams, receiver_encoded){

  let txBuilder_1 = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder_1.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+500000)))
      ),
  );

  return txBuilder_1

}

  async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}


async function getUtxos(cardano){
  const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
  const utxos = (await cardano.getUtxos()).map((utxo) =>
    txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
  );
  return txUnspentOutputs
}

  
async function getParameters(){
  const protocolParams = await fetch('https://claymarket.io/minting/protocolparams/mainnet/', {
        method: 'GET',
       })
  const json_params = await protocolParams.json()
  return json_params
}


async function getCurrentSlot(){
    let slot = await fetch('https://claymarket.io/minting/currentslot/mainnet/', {
        method: 'GET',
        }).then(response => response.json()).then(data => data)

    slot = parseInt(slot.slot, 10)

    return slot
}


function get_required_signers(utxos){
    let required_keys = []

    for (let i = 0; i < utxos.length; i ++){
        let utxo = utxos[i]
        let utxo_output_address = utxo.output().address()
        //console.log(utxo_output_address.to_bech32())

        let utxo_key_hash = Buffer.from(serLib.BaseAddress.from_address(utxo_output_address).payment_cred().to_keyhash().to_bytes()).toString('hex')

        if (!required_keys.includes(utxo_key_hash)){
            required_keys.push(utxo_key_hash)
        }

    }
    return required_keys
}


function compare_inputs(input_1, input_2){
    let tx_hash_1 = Buffer.from(input_1.transaction_id().to_bytes()).toString('hex')
    let tx_index_1 = input_1.index()

    let tx_hash_2 = Buffer.from(input_2.transaction_id().to_bytes()).toString('hex')
    let tx_index_2 = input_2.index()

    //console.log(tx_hash_1 + '#' + tx_index_1)
    //console.log(tx_hash_2 + '#' + tx_index_2)

    return (tx_hash_1 == tx_hash_2 && tx_index_1 == tx_index_2)
}


async function enableWallet(){


	
	let cardano = await window.cardano[activeWallet]
    cardano = await cardano.enable()

    return cardano
	

}


	async function mint(price){
		// console.log('\n\n\n')
		// console.log('Mint here...')
		// console.log(token_name)
		// console.log(price)
		// console.log('\n\n\n')

		notifyInfo('Building transaction...')

		//const paymentAddress = 'addr1qy9hdsqhlkymh5qsdtpzsxdv2kw95cyyuskwdzw66h5ufz80dqs64jh9zajvjkd57sdh7p8jcuxnwhpy7jcwt8w0d4zqadvtnl'

		const paymentAddress = 'addr1qxpwwrpushzsfzhmgen8tjqwd7l26vfrsv9scf9xpaftx8gr7dxpjc0fjweud2rszzr3kmdxgxx8czl6pa3zcgwt98cstzrjs0'

		//const BACKGROUNDS_POLICY = ''

		let cardano;

		try{
			cardano = await enableWallet()
		}catch{
			notifyWarn('could not connect...')
			return
		} 
		
    	let address_encoded;

    	let address;

    	try{
    		address_encoded = await cardano.getUsedAddresses()
	    	address_encoded = address_encoded[0]
	    	address = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()
			}catch{
				notifyWarn('count not fetch address...')
				return
			}

	    
	    let buyer = address 
	    let buyer_encoded = serLib.Address.from_bech32(buyer)

	    let seller = paymentAddress
	    let seller_encoded = serLib.Address.from_bech32(seller)

	    const protocolParams = await getParameters()
   		const currentSlot = await getCurrentSlot()

   		const ttl = parseInt(currentSlot) + 7200
    	const utxos = await getUtxos(cardano)
    	const lovelacePayment = price * 1000000


    	let txBuilder = await get_txBuilder(protocolParams)


      //policy
	    let policy_signer_addr = 'addr1q8r3898h0wdll4ze34kzffxtavsat394mh2gcguwq5k7nlpfy9rt9uyx8nvvtjgpvnk4scf27ljn3h0f3elvk6e37hfs96ra5q'

	    const policy_signer_keyhash = keyhashFromAddress(policy_signer_addr)
	    let addr_keyhash = serLib.BaseAddress.from_address(serLib.Address.from_bech32(policy_signer_addr)).payment_cred().to_keyhash()
	    let vkey_pubkey = serLib.ScriptPubkey.new(addr_keyhash) //Ed25519KeyHash
	    
	    let policy_slot = 99798689 + 432000 * 6 * 12
    	let policy = createPolicy(vkey_pubkey, policy_slot)


	    const policyScript = policy.script
	    const policyScriptHash = policy.scriptHash

	    const BACKGROUNDS_POLICY = 'b8842fe3a0eae5011252c16566995d2c8f1bae85d380410cd21ea91f'

	    //console.log(policyScriptHash)

      //let script_hash = serLib.ScriptHash.from_bytes(Buffer.from(BACKGROUNDS_POLICY, "hex"))

      // let mintBuilder = serLib.MintBuilder.new()

      // 	//222
	    // mintBuilder.add_asset(
	    //   serLib.MintWitness.new_native_script(policyScript),
	    //   serLib.AssetName.new(
	    //     Buffer.from('000de140' + Buffer.from(token_name,'utf8').toString('hex'), 'hex')
	    //   ),
	    //   serLib.Int.from_str('1')
	    // )

	    // txBuilder.set_mint_builder(mintBuilder)


	    //Mint, NativeScripts
	    let mintObject = serLib.Mint.new()


	    //ScriptHash, MintAssets
	    mintObject.insert(
	    	policyScript.hash(),
	    	serLib.MintAssets.new_from_entry(
	    		serLib.AssetName.new(
		        Buffer.from('000de140' + Buffer.from(token_name,'utf8').toString('hex'), 'hex')
		      ),
	      serLib.Int.from_str('1')
	    	)
	    )

	    let mint_scripts = serLib.NativeScripts.new()

	    mint_scripts.add(
	    	policyScript
	    )

	    txBuilder.set_mint(mintObject, mint_scripts)


	    let multiAsset = serLib.MultiAsset.new()
      	let assets = serLib.Assets.new()


      assets.insert(
	      serLib.AssetName.new(
	        Buffer.from('000de140' + Buffer.from(token_name,'utf8').toString('hex'), 'hex')
	      ),
	      serLib.BigNum.from_str('1')
	    )


	    multiAsset.insert(
	      policyScript.hash(),
	      assets
	    )


	    let token_output = serLib.TransactionOutput.new(
	            buyer_encoded,
	            serLib.Value.new_with_assets(
	              serLib.BigNum.from_str(String('2000000')),
	              multiAsset
	            )
	        )

	    txBuilder.add_output(token_output);


	    let payment_output = serLib.TransactionOutput.new(
	            seller_encoded,
	            serLib.Value.new(
	              serLib.BigNum.from_str(String(lovelacePayment)),
	            )
	        )


	    txBuilder.add_output(payment_output);

	    txBuilder.set_ttl_bignum(serLib.BigNum.from_str(String(policy_slot)));


	     try{
		      txBuilder.add_inputs_from(utxos, 2) 
		    }catch (err){
		      // console.log('err')
		      // console.log(err)

		      notifyWarn('balance is not sufficient')
		      return
		    }

    	

	   	try{
	      txBuilder.add_change_if_needed(buyer_encoded)
	    }catch(err){
	      //console.log(err)

	      notifyWarn('could not add change')
	      return
	    }


	    let unsignedTx;

	    try{
	       unsignedTx = txBuilder.build_tx()
	    }catch(err){
	      unsignedTx = ''
	      // console.log(err)
	      return
	    }
	    

	    // console.log(unsignedTx.to_json())

	    const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')

	    let tx_witness;

	    try{
	    	tx_witness = await cardano.signTx(unsignedTx_hash, true)
	    }catch{
	    	notifyWarn('declined to sign')
	    	return
	    }
	    
	    notifyInfo('submitting...')
	    
	    // console.log(unsignedTx_hash)
	    // console.log(tx_witness)

	    let status = await submitTransaction(unsignedTx_hash, tx_witness)


	    if (status.hasOwnProperty('TXID')){
	    	setIsAlertOpen(true)
	    	setSubmittedTx(status['TXID'])
	    }else{
	    	notifyError('Failed to submit...')
	    	return
	    }	

	}


	return(
		<>

			<div style={{width:'100%', height:'80%', backgroundColor:'',
	    							display:'flex', justifyContent:'center', alignItems:'center',
	    							cursor:'pointer'
	    				}}

	    				onClick={()=>{
	    					//mint(price)

	    					add_to_cart(token_name)

	    				}}

	    				onMouseEnter={()=>{
	    					if (img_url && img_url.startsWith('Omen')){
    								setCardImageStyle(hoverOmenImageStyle)
    							}else{
    								setCardImageStyle(hoverImageStyle)
    							}
	    				}}


	    				onMouseLeave={()=>{
	    					if (img_url && img_url.startsWith('Omen')){
    								setCardImageStyle(normalOmenImageStyle)
    							}else{
    								setCardImageStyle(normalImageStyle)
    							}
	    				}}


	    				>

	    				<MintButton>

	    					ADD

	    				</MintButton>

	    			</div>

		</>
	)
}
export default Mint;




