import { useState, useEffect, useContext, useRef } from 'react'

import ReactEcharts from "echarts-for-react"; 

import './Analytics.css';



function Analytics(props) {

  const setIsAnalytics = props.setIsAnalytics
  const isAnalytics = props.isAnalytics

  const matchesXL = props.matchesXL
  const matchesS = props.matchesS
  const matchesM = props.mathesM


	const [currentStats, setCurrentStats] = useState({
		
	})



	const [historicStats, setHistoricStats] = useState({
		    epochs: [],
    		assets_baked: [],
    		clay_baked: [],
    		alltime_baked: []
	})



	useEffect(()=>{
		getCurrentStats()
		getHistoricStats()
	},[])


  function displayNumber(num){
    try{
      let temp = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return temp
    }
    catch{
      return ''
    }

  }


  function displayBoosterPool(num){
    if (num < 1000){
      return  Math.round(num)
    }else if (num < 1000000){
      return (num / 1000).toFixed(2) + 'K'
    }else if (num < 1000000000){
      return (num / 1000000).toFixed(2) + 'M'
    }else{
      return (num / 1000000000).toFixed(2) + 'B'
    }
  }
	

  async function getCurrentStats(){

  	 let fetched_current_stats = await fetch('https://clayapi.com/claystats/current/').then(response => response.json())
                                                                  .then(data => data)

     fetched_current_stats['clay_price'] = fetched_current_stats['clay_price'].toFixed(6)                          
     setCurrentStats(fetched_current_stats)

     //console.log(fetched_current_stats)
  } 

  async function getHistoricStats(){
  	let fetched_historic_stats = await fetch('https://clayapi.com/claystats/historic/').then(response => response.json())
                                                                  .then(data => data)
    

    fetched_historic_stats = fetched_historic_stats['historic_stats']  
                                                           
    let epochs_list = []
    let assets_baked_list = []
    let clay_baked_list = []
    let alltime_baked_list = []

    for (let i = 0; i < fetched_historic_stats.length; i++){
    	let cur_record = fetched_historic_stats[i]
    	//console.log(cur_record)
    	epochs_list.push(cur_record['epoch'])
    	assets_baked_list.push(cur_record['assets_baked'])
    	clay_baked_list.push(cur_record['clay_baked'])
    	alltime_baked_list.push(cur_record['alltime_baked'])
    }




    setHistoricStats(
    	{
    		epochs:epochs_list,
    		assets_baked:assets_baked_list,
    		clay_baked:clay_baked_list,
    		alltime_baked:alltime_baked_list
    	})


    //console.log(historicStats.epochs)

    /*console.log({
    		epochs:epochs_list,
    		assets_baked:assets_baked_list,
    		clay_baked:clay_baked_list,
    		alltime_baked:alltime_baked_list
    	})
      */

  } 





  const option2 = {
    color: [
      '#8cb7e5',
      '#FF9C83',
      '#5593d6'
    ],

    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(37, 37, 37, 0.7)',
    shadowBlur: 30,
    shadowColor: 'rgba(145, 186, 231, 0.4)',
    borderColor: "rgba(204, 204, 204, 0.6)",

    textStyle: {
      color: '#ccc',
    fontFamily: 'shortstack',
    
    },
      axisPointer: {

        type: 'cross'
      }
    },
    grid: {
      right: '30%'
    },
    toolbox: {
      feature: {
        dataView: { show: false, readOnly: false },
        restore: { show: false },
        saveAsImage: { show: false }
      }
    },
    legend: {
      data: ['$CLAY BAKED', 'ASSETS BAKED', 'TOTAL $CLAY BAKED'],
      bottom: '5%',
      left: '20%',
      orient: "horizontal",

      textStyle: {
        color: '#ccc',
        fontFamily: 'shortstack',
        fontSize: '12',
      }
    },


    xAxis: [
      {
        
        type: 'category',

        
        axisTick: {
          alignWithLabel: true
        },

        axisLabel: {
          color: '#7A9CC1'
        },

        // prettier-ignore
        data: historicStats.epochs
      },

     
      
    ],
    yAxis: [
      {
        type: 'value',
        name: '$CLAY baking',
        position: 'right',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7A9CC1'
          }
        },
        axisLabel: {
          formatter: '{value} '
        }
      },
      {
        type: 'value',
        name: 'Assets baking',
        position: 'right',
        alignTicks: true,
        offset: 80,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7A9CC1'
          }
        },
        axisLabel: {
          formatter: '{value} '
        }
      },
      {
        type: 'value',
        name: '$CLAY',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#7A9CC1'
          }
        },
        axisLabel: {
          formatter: '{value} '
        }
      }
    ],
    series: [
      {
        name: '$CLAY BAKED',
        type: 'bar',
        data: historicStats.clay_baked
      },
      {
        name: 'ASSETS BAKED',
        type: 'bar',
        yAxisIndex: 1,
        data: 
          historicStats.assets_baked
        
      },
      {
        name: 'TOTAL $CLAY BAKED',
        type: 'line',
        yAxisIndex: 2,
        data: historicStats.alltime_baked
      }
    ]
  };








const option = {



  color: [
    '#344D68',
    '#7A9CC1',
  ],

  title: {
    text: '$CLAY CIRCULATION \n(2,500,000,000)',
    subtext: '',
    left: 'center',
    top: '0%',


    textStyle: {
      color: '#ccc',
    fontFamily: 'shortstack',
    fontWeight: '100',
    fontSize: '15'
    
    }

  },


  tooltip: {
    trigger: 'item',
    backgroundColor: 'rgba(37, 37, 37, 0.7)',
    shadowBlur: 30,
    shadowColor: 'rgba(145, 186, 231, 0.4)',

    textStyle: {
      color: '#ccc',
    fontFamily: 'shortstack'
    }
  },


  legend: {
    top: '12%',
    left: '20%',
    orient: "horizontal",
  itemWidth: 17,
  itemGap: 50,



  itemStyle: {
  


  },
    

    textStyle: {
      color: '#ccc',
    fontFamily: 'shortstack',
   fontSize: '12',

    }
   
  },


  icon: 'roundRect'


  ,
  series: [
    {
      name: '$CLAY',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: true,
      top: '0%',
      width: '60%',
      left: '20%',
      
      itemStyle: {
        borderRadius: 0,
        borderColor: 'transparent',
        borderWidth: 2,
       
      
      },
      label: {
        show: false,
        position: 'left',
        textColor: '#fff',


      },
      
   
      labelLine: {
        show: false
      },
      data: [
        { value: 2500000000-currentStats.circulating_supply, name: 'LOCKED' },
        { value: currentStats.circulating_supply, name: 'CIRCULATING' },
        
      ]
    }
  ]
};





return (

<div className="Analytics-table">

<div style={{width:'100%', position:'relative', top:'25px', display:'flex', zIndex:'250',justifyContent:'right', alignItems:'center', marginLeft:'-20px', marginTop:'-10px'}}>

  <div className={isAnalytics ? "Leaderboard-link" : "Leaderboard-link-selected"}><a onClick={()=>{setIsAnalytics(false)}}> LEADERBOARD </a></div>

  <div className={isAnalytics ? "Analytics-link-selected" : "Analytics-link"}><a onClick={()=>{setIsAnalytics(true)}}> ANALYTICS  </a></div>

</div>



<div style={{position:'relative',top:'50px', backgroundColor:'', width:'100%'}}>


<div style={{backgroundColor:'',width:'100%', minHeight:'100px', position:'relative', display:'flex', justifyContent:'center'}}>
    
    <div className='summary'>


    <div style={{display:'flex', flexDirection:'row', width:'100%', display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
      
      <div style={{minWidth:'200px', minHeight:'50px', backgroundColor:'', margin:'10px'}}>
         <div className="clay-baking">
          <div style={{display:'flex', alignItems:'center'}}>
            <img  className='baking-png' src={'flame2.png'} style={{marginRight:'5px'}} />
            <span className="clay-baking-number"> {displayNumber(currentStats.clay_baking)} </span>
          </div>
          <div className="clay-baking-txt" style={{display:'flex', justifyContent:'center', textAlign:'center'}}> TOTAL $CLAY BAKING </div>
        </div>

      </div>


      <div style={{minWidth:'200px', minHeight:'50px', backgroundColor:'',margin:'10px'}}>
        
        <div className="clay-baking">
          <div style={{display:'flex', alignItems:'center'}}>
            <img  className='baking-png' src={'flame2.png'} style={{marginRight:'5px'}} />
            <span className="assets-baking-number"> {displayNumber(currentStats.assets_baking)} </span>
          </div>
          <div className="clay-baking-txt" style={{display:'flex', justifyContent:'center', textAlign:'center'}}> ASSETS BAKING </div>
        </div>

      </div>

      <div style={{minWidth:'200px', minHeight:'50px', backgroundColor:'', margin:'10px'}}>
        
        <div className="clay-baking">
          <div style={{display:'flex', alignItems:'center'}}>
            <img  className='token1-png' src={'CLAY_TOKEN_220x220.png'} />
            <span className="clay-baked"> {displayNumber(currentStats.alltime_baked)} </span>
          </div>
          <div className="clay-baked-txt" style={{display:'flex', justifyContent:'center', textAlign:'center'}}> TOTAL $CLAY <br></br> BAKED & DISTRIBUTED </div>
        </div>

      </div>


      <div style={{minWidth:'200px', minHeight:'50px', backgroundColor:'', margin:'10px'}}>
        

       <div className="clay-baking">
          <div style={{display:'flex', alignItems:'center', textAlign:'center'}}>
            <span className="accounts-clay">  {displayNumber(currentStats.num_holders)} </span>
          </div>
        <span className="accounts-txt"> ACCOUNTS <br></br> HOLDING $CLAY </span>

       </div>



      </div>


    </div>



      </div>
</div>





<div style={{backgroundColor:'',width:'100%', minHeight:'100px', position:'relative', display:'flex', justifyContent:'center', marginTop:'20px'}}>

<div style={{position:'relative',width:'90%', height:'300px', marginTop:'10px',backgroundColor:'', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between'}}>

  <div style={ matchesXL ? 
    {width:'250px', height:'165px',backgroundColor:'', display:'flex',  flexDirection:'column', justifyContent:'space-between'} 
    : 
    {backgroundColor:'', width:'100%', display:'flex', flexDirection: 'row', flexWrap:'wrap', justifyContent:'center'}
     }>

  <div className='token-price' style={{marginBottom:'10px', marginRight:'10px'}}>

    <img  className='token-price-img' src={'CLAY_TOKEN_220x220.png'} />

    <span className="clay-price-tag"> PRICE </span>

    <span className="clay-price-number"> {currentStats.clay_price} ₳</span>

  </div>


  <div className='market-cap' style={{marginBottom:'10px', marginRight:'10px'}}>

    <span className="market-cap-tag"> MARKET CAP </span>

    <span className="market-cap-number">{displayNumber(currentStats.market_cap)} ₳</span>

  </div>


  <div className='dilluted-market-cap' style={{marginBottom:'10px', marginRight:'10px'}}>

    <span className="dilluted-market-cap-tag"> FULLY DILLUTED<br></br> MARKET CAP </span>

    <span className="dilluted-market-cap-number"> {displayNumber(currentStats.diluted_market_cap)} ₳</span>

  </div>


  </div>



  
<div style={ !matchesXL && matchesS ?  {width:'100%', display:'flex', justifyContent:'center', backgroundColor:'',marginTop:'20px'} : {}}>

  <div style={{backgroundColor:'', display:'flex', justifyContent:'start', height:'250px'}}>

    <div className='token-circulation'><ReactEcharts option={option} />
    <img className='clay-token' src={'CLAY_TOKEN_220x220.png'} style={{position:'relative', top:'-150px', width:'40px'}} /></div>


    <div className='boosting-pool' style={{backgroundColor:''}}>
      <img  className='boosting-pool-png' src={'boosting_pool2.png'} />

      <div style={{position:'relative', top:'-170px', left:'138px', fontSize:'32px', color:'#344D68', backgroundColor:'',
      backgroundColor: 'rgba(255, 255, 255, .15)',  boxShadow: '0px 0px 30px #b3a6fc2c', backdropFilter: 'blur(5px)', 
      width:'100px', height:'100px', display: 'flex', justifyContent:'center', alignItems:'center',borderRadius:'50px', textAlign:'center', justifyContent:'center'}}>{displayBoosterPool(currentStats.booster_pool)}</div>
       <span className="accounts-txt" style={{position:'relative', top:'-200px', left:'34px',color:'black'}}> BOOSTER <br/>POOL</span>
    </div>



  </div>


  </div>








{/*

<div style={{backgroundColor:'',width:'100%', minHeight:'100px', position:'relative', display:'flex', justifyContent:'center'}}>

<div style={{position:'relative',width:'90%', height:'300px', marginTop:'10px',backgroundColor:'blue', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between'}}>


  <div style={{width:'250px', height:'165px',backgroundColor:'green', display:'flex',  flexDirection:'column',justifyContent:'space-between'}}>

  <div className='token-price'>

    <img  className='token-price-img' src={'CLAY_TOKEN_220x220.png'} />

    <span className="clay-price-tag"> PRICE </span>

    <span className="clay-price-number"> {currentStats.clay_price} ₳</span>

  </div>


  <div className='market-cap'>

    <span className="market-cap-tag"> MARKET CAP </span>

    <span className="market-cap-number">{displayNumber(currentStats.market_cap)} ₳</span>

  </div>


  <div className='dilluted-market-cap'>

    <span className="dilluted-market-cap-tag"> FULLY DILLUTED<br></br> MARKET CAP </span>

    <span className="dilluted-market-cap-number"> {displayNumber(currentStats.diluted_market_cap)} ₳</span>

  </div>


  </div>


  <div style={{backgroundColor:'red', display:'flex', justifyContent:'start'}}>

    <div className='token-circulation'><ReactEcharts option={option} />
    <img className='clay-token' src={'CLAY_TOKEN_220x220.png'} style={{position:'relative', top:'-150px'}} /></div>


    <div className='boosting-pool'>
      <img  className='boosting-pool-png' src={'boosting_pool.png'} />
    </div>

  </div>
*/}




<div style={{backgroundColor:'', width:'90%', minWidth: matchesXL ? '1000px' : !matchesS ? '700px' : '850px', display:'flex', marginBottom:'80px'}}>

  <div className='baking-activity'><ReactEcharts option={option2} /></div>

</div>




</div>




</div>



</div>


{/*

<div className='token-circulation'><ReactEcharts option={option} /><img className='clay-token' src={'CLAY_TOKEN_220x220.png'} /></div>

<div className='baking-activity'><ReactEcharts option={option2} /></div>

<div className='token-price'>

<img  className='token-price-img' src={'CLAY_TOKEN_220x220.png'} />

<span className="clay-price-tag"> PRICE </span>

<span className="clay-price-number"> {currentStats.clay_price} ₳</span>

</div>



<div className='market-cap'>


<span className="market-cap-tag"> MARKET CAP </span>

<span className="market-cap-number">{displayNumber(currentStats.market_cap)} ₳</span>

</div>


<div className='dilluted-market-cap'>


<span className="dilluted-market-cap-tag"> FULLY DILLUTED<br></br> MARKET CAP </span>

<span className="dilluted-market-cap-number"> {displayNumber(currentStats.diluted_market_cap)} ₳</span>

</div>


<div className='summary'>

<div className="clay-baking">

<img  className='baking-png' src={'flame.png'} />
<span className="clay-baking-number"> {displayNumber(currentStats.clay_baking)} </span>

<span className="clay-baking-txt"> TOTAL $CLAY BAKING </span>
    

</div>

  <div className="assets-baking">

  <img  className='baking2-png' src={'flame.png'} />
<span className="assets-baking-number"> {displayNumber(currentStats.assets_baking)} </span>

<span className="assets-baking-txt"> ASSETS BAKING </span>



  </div>
  <div className="total-clay-baked">
  <img  className='token1-png' src={'CLAY_TOKEN_220x220.png'} />
<span className="clay-baked"> {displayNumber(currentStats.alltime_baked)} </span>

<span className="clay-baked-txt"> TOTAL $CLAY<br></br> BAKED & DISTRIBUTED </span>

  </div>


  <div className="accounts">

  <span className="accounts-clay"> {displayNumber(currentStats.num_holders)} </span>

<span className="accounts-txt"> ACCOUNTS <br></br> HOLDING $CLAY </span>


 
  </div>
</div>


<div className='boosting-pool'>

<img  className='boosting-pool-png' src={'boosting_pool.png'} />


</div>

*/}


</div>
)
}




export default Analytics;

