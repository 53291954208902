import styled from "styled-components";


export const H1 = styled.h1`
font-size: clamp(0.5rem, 1.5rem, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
margin-top: 1rem;
margin-bottom: 0.5rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 5vw, 4rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.7vw, 3rem);
}   
`;

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;
flex-direction: column;
width: 80%;
margin: 0 auto;
padding-top: 1rem;
justify-content: space-between;
gap: 1rem;
`;

export const IMG = styled.img`
cursor: pointer;
&:hover {
    outline: 3px solid  var(--clayblue-primary);

}
`;
