
import { PanelWrapper } from "./MainPanel.styled";
import WalletsTab from "./Main Panel Components/WalletsTab";
import ChoosePfp from "./Main Panel Components/ChoosePfp";
import ChooseBanner from "./Main Panel Components/ChooseBanner";


function MainPanel ({ activeLink, handleMenuLink, wallets, setWallets, accessToken, setAccessToken, toastMessages}) {

    return (

        <PanelWrapper>

            {activeLink === 'dashboard' && <h1>Dashboard Content</h1>}
            {activeLink === 'assets' && <h1>Assets Content</h1>}
            {activeLink === 'game stats' && <h1>Game Stats Content</h1>}
            {activeLink === 'rewards' && <h1>Rewards Content</h1>}
            {activeLink === 'staking' && <h1>Staking Content</h1>}
            
            {activeLink === 'wallets' 
            && <WalletsTab wallets={wallets} setWallets={setWallets} 
                accessToken={accessToken} setAccessToken={setAccessToken} 
                toastMessages={toastMessages}
                />}

            {activeLink === 'settings' && <h1>Settings Content</h1>}

            
            {activeLink === 'pfp' && <ChoosePfp />}
            {activeLink === 'banner' && <ChooseBanner />}
          
        </PanelWrapper>


    );



}

export default MainPanel;