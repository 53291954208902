import { lazy, useState, useEffect, useContext, Suspense } from 'react'
import { MainInterfacesContext } from 'components/Context/InterfacesContext'
import { MainPlayerContext } from 'components/Context/PlayerContext'

import {MainWorldContext} from 'components/Context/WorldContext'
import {MainExperienceContext} from 'components/Context/ExperienceContext'


import ChatInterface from 'components/Virtual/Interface/Chat/ChatInterface'
import AccountInterface from 'components/Virtual/Interface/Account/AccountInterface'
import Menu from 'components/Virtual/Interface/Menu/Menu'


function SceneInterfaces(props){

  const {name, position, lod} = props

  //console.log(name)

  const import_str = './World/Scenes/' + name + '/' + 'Interfaces'

  const [NewInterfaces] = useState(lazy(() => import(`${import_str}`)))

  //console.log(import_str)

  return(

    <>
      <Suspense fallback={null} >
        <NewInterfaces key={name} position={position} lod={lod} />
      </Suspense>
    </>

  )
}



function Interfaces(props) {

   const {activeObjects, setActiveObjects, bottomActiveObjects, setBottomActiveObjects} = useContext(MainPlayerContext)
   const {activeInterfaces, setActiveInterfaces} = useContext(MainInterfacesContext)

   const {activeScenes, closestScene} = useContext(MainWorldContext)

   const {activeScene, setActiveScene} = useContext(MainExperienceContext)



   const [chatOpen, setChatOpen] = useState(true)
   


   const interactiveObjects = [
    {identifier: '_Trousers', interfaceName: 'trousersInterface'},
    {identifier: 'cashier', interfaceName: 'cashierInterface'},
    {identifier: 'cashier', interfaceName: 'cashierNPCInterface'}
   ]



   useEffect(() => {

    //console.log(closestScene)

   }, [closestScene])



  
return(
  
  <>


  { activeScene === 'StoreScene' ?
    
    <>
     <AccountInterface chatOpen={chatOpen} setChatOpen={setChatOpen} />
     <ChatInterface chatOpen={chatOpen} setChatOpen={setChatOpen} />
     <Menu />
    </>

   : null
 }



 {activeScene === 'StoreScene' ?
  Object.keys(closestScene)?.map((key) => {
    return(

      <SceneInterfaces 
        key={key} 
        name={key} 
        position={activeScenes[key].position} 
      />

    )
  })
 : null}


  {/*
    {'trousersInterface' in activeInterfaces  ?

    <TrousersInterface 


      interfaceData={activeInterfaces['trousersInterface']}

    />

    : null}



    
    
    {'cashierInterface' in activeInterfaces  ?
      

    <CashierInterface 


      interfaceData={activeInterfaces['cashierInterface']}


    />

    : null}





    <ClayPayScene />*/}



  </>
  
  )
}

export default Interfaces;
