import { useRef, useEffect, useState, useContext } from 'react';
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import {MainQualityContext} from 'components/Context/QualityContext'

function Lights(props) {

  const {playerRef} = props

  const light = useRef()

  const { camera } = useThree();

  const lightGroupRef = useRef()


  const {quality} = useContext(MainQualityContext)

  const [lightsQuality, setLightsQuality] = useState(quality)


  useEffect(() => {
    setLightsQuality(quality)
  },[quality])


  useFrame((state) => { 


    if (playerRef === null || typeof(playerRef.current) === 'undefined'){
      return
    }

    try{
      let cur_postion = playerRef.current.translation()


      light.current.position.x = cur_postion.x + 1.8 + 10
      light.current.position.z = cur_postion.z + 10.6 + 10
      light.current.position.y = cur_postion.y + 7

      
      light.current.target.position.x = cur_postion.x + 10
      light.current.target.position.z = cur_postion.z + 10
      light.current.target.position.y = cur_postion.y
      
    
      light.current.target.updateMatrixWorld()
    }catch{
      
    }

  })



  return (
    <>
      

      <group ref={lightGroupRef}>

       {/* <directionalLight

          ref={light}

          color={'#fcf2d9'}
          castShadow
          position={[1.8, 7, 10.5]} 
          intensity={1}
          shadow-mapSize={ [2048*2, 2048*2] }
          shadow-camera-near={ 0.01 }
          shadow-camera-far={ 50 }
          shadow-camera-top={ 50 }
          shadow-camera-right={ 50 }
          shadow-camera-bottom={ -50 }
          shadow-camera-left={ -50 }
          shadow-bias={-0.005}

        />*/}

        <directionalLight

          ref={light}

          color={'#fcf2d9'}
          castShadow={lightsQuality !== 'Low'}
          position={[1.8, 7, 10.5]} 
          intensity={1}
          shadow-mapSize={ lightsQuality === 'High' ? [2048*2, 2048*2] : lightsQuality === 'Medium' ? [2048, 2048] : [2048, 2048]  }
          shadow-camera-near={ 0.01 }
          shadow-camera-far={ 50 }
          shadow-camera-top={ 50 }
          shadow-camera-right={ 50 }
          shadow-camera-bottom={ -50 }
          shadow-camera-left={ -50 }
          shadow-bias={-0.005}

        />


      </group>


      <ambientLight

        intensity={0.6} 

      />

      
    </>
  );
}

export default Lights;
