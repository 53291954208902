import { useState, useEffect, useContext, Suspense } from 'react'

import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import * as THREE from 'three'


import { socket } from '../socket';


import { MainMultiplayerContext } from 'components/Context/MultiplayerContext'

import {MainChatContext} from 'components/Context/ChatContext'


import RemotePlayer from './RemotePlayer'


function RemotePlayers(props) {

  const {localRef} = props

  const {remotePlayers, setRemotePlayers, userSocketId, remotePlayersDict, setRemotePlayersDict} = useContext(MainMultiplayerContext)

  const {chatMessages, setChatMessages} = useContext(MainChatContext)

  //const [remotePlayers, setRemotePlayers] = useState({})

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  const [messageContent, setMessageContent] = useState('')

  const [recentMessages, setRecentMessages] = useState({})

  const [currentRemotePlayers, setCurrentRemotePlayers] = useState({})


  const [needUpdate, setNeedUpdate] = useState(false)



  useEffect(() => {

        if (socket){
          socket.on('chat message', onChatMessage)

          return () => socket.off('chat message', onChatMessage)
        }

      },[socket, chatMessages, remotePlayersDict])



  function onChatMessage(msg){

        // console.log('REMOTE MESSAGE RECEIVED')
        // console.log(msg)

        let temp_chatMessages = [...chatMessages]

        //remotePlayersDict[msg.socket_id]


        let temp_message = {}

        temp_message['socket_id'] = msg.socket_id
        temp_message['msg'] = msg.msg

        if (remotePlayersDict.hasOwnProperty(msg.socket_id) && 'meta' in remotePlayersDict[msg.socket_id] &&
                  'image' in remotePlayersDict[msg.socket_id]['meta']){
          temp_message['avatar_url'] = remotePlayersDict[msg.socket_id]['meta']['image']
        }


        if (remotePlayersDict.hasOwnProperty(msg.socket_id)
            && 'nickname' in remotePlayersDict[msg.socket_id]){
          temp_message['nickname'] = remotePlayersDict[msg.socket_id].nickname
        }



        temp_chatMessages.push({...temp_message})

         setChatMessages(
          [...temp_chatMessages]
        )

        let cur_socket_id = msg.socket_id
        let cur_msg = msg.msg

        let temp_recentMessages = recentMessages

        temp_recentMessages[cur_socket_id] = cur_msg

        setRecentMessages(temp_recentMessages)

        // console.log('RECENT MESSAGES')
        // console.log(recentMessages)
        
      }


function onRemovePlayer(socket_id){

  let temp_remotePlayersDict = remotePlayersDict

  delete temp_remotePlayersDict[socket_id.socket_id]

  setRemotePlayersDict(temp_remotePlayersDict)

}


useEffect(() => {
  
  
    socket.on('removePlayer', onRemovePlayer)
    return () => socket.off('removePlayer', onRemovePlayer)
  

},[])

  
useEffect(() => {

  if (needUpdate){
    socket.emit('getPlayers')
    const timer = setTimeout(() => {
      setNeedUpdate(false)
    }, 1000)

    return () => clearTimeout(timer)

  }

},[needUpdate])



function onGetPlayers(players){
    // console.log('SET HERE')
        //console.log(players)
    setRemotePlayersDict(players)
    
    }


function onUpdatePlayers(players){ 

  //console.log('on updatePlayers') 

  let updated_remotePlayersDict = {}
  for (let player in players){
    if (!remotePlayersDict.hasOwnProperty(player)){
      //console.log('oh no')
      //socket.emit('getPlayers')
      if (!needUpdate){
        setNeedUpdate(true)

        //console.log('needs update!!!')
      }
      return
    }else{
      updated_remotePlayersDict[player] = remotePlayersDict[player]
      let cur_playerState = players[player]
      for (let key in cur_playerState){
         updated_remotePlayersDict[player][key] = cur_playerState[key]
      }
    }
  }

  setRemotePlayersDict(updated_remotePlayersDict)

}


  useEffect(() => {

      //console.log('REMOTE PLAYERS')

     
      function onConnect(socket) {
        //console.log('On connect')
        setIsConnected(true);
      }

      function onDisconnect() {
        setIsConnected(false);
      }

      function onFooEvent(value) {
        setFooEvents(previous => [...previous, value]);
      }

  
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('foo', onFooEvent);
      socket.on('updatePlayers', onUpdatePlayers)
      socket.on('getPlayers', onGetPlayers)

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('foo', onFooEvent);
        socket.off('updatePlayers', onUpdatePlayers)
        socket.off('getPlayers', onGetPlayers)
       
      };

    }, [remotePlayersDict]);
  



useEffect(() => {

  let count = 0
  let temp_dict = {}

  let total_players = Object.keys(remotePlayersDict).length

  for (let key in remotePlayersDict){

    let suitable = false

    let cur_player = remotePlayersDict[key]

    let cur_player_position = new THREE.Vector3()

    if (!cur_player.hasOwnProperty('position')){
      continue
    }else{
      cur_player_position.x = cur_player.position[0]
      cur_player_position.y = cur_player.position[1]
      cur_player_position.z = cur_player.position[2]
    }

    let cur_char_position = new THREE.Vector3(0,0,0)

    if (localRef !== null && typeof(localRef.current) !== 'undefined'){
       try{
        let temp = localRef.current.translation()
        cur_char_position = new THREE.Vector3(temp.x, temp.y, temp.z)
       }catch{
        //
       }
       
    }
      

    let cur_distance = cur_char_position.distanceTo(cur_player_position)

    suitable = total_players < 20 || cur_distance < 10


    if (suitable){
      temp_dict[key] = remotePlayersDict[key]
      count += 1
    }
    
  }

  setCurrentRemotePlayers(temp_dict)

},[remotePlayersDict, localRef])


return(
  
  <>
    
    <group>
    {
      Object.keys(currentRemotePlayers).map((remotePlayer)=>{

        return(

          socket.id !== remotePlayer

          && 'position' in currentRemotePlayers[remotePlayer]
          && 'rotation' in currentRemotePlayers[remotePlayer]
          && 'action' in currentRemotePlayers[remotePlayer]
          && 'meta' in currentRemotePlayers[remotePlayer]
          && 'nickname' in currentRemotePlayers[remotePlayer]

           ?
          
          <Suspense fallback={null}>
            <RemotePlayer 
              key={remotePlayer} 
              socket_id={remotePlayer} 
              position={currentRemotePlayers[remotePlayer].position} 
              rotation={currentRemotePlayers[remotePlayer].rotation}
              action={currentRemotePlayers[remotePlayer].action} 
              meta={currentRemotePlayers[remotePlayer].meta}

              nickname={currentRemotePlayers[remotePlayer].nickname}
              
              messageContent={messageContent}
              setMessageContent={setMessageContent}

              needsUpdate={true}

              playerAction={currentRemotePlayers[remotePlayer].playerAction}

              recentMessages={recentMessages}
            />
          </Suspense>

          : null

        )

      })
    }
    </group>
  
  </>
  
  )
}

export default RemotePlayers;
