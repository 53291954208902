
import { MenuWrapper, UL, LI, MenuIcon, StyledTooltip } from "./Menu.styled";
import home from "../Images/Menu Icons/home-inactive.png"
import assets from "../Images/Menu Icons/assets-inactive.png"
import stats from "../Images/Menu Icons/gamestats-inactive.png"
import rewards from "../Images/Menu Icons/rewards-inactive.png"
import staking from "../Images/Menu Icons/staking-inactive.png"
import wallets from "../Images/Menu Icons/wallet.png"
import settings from "../Images/Menu Icons/settings-inactive.png"

const Menu = ({ activeLink, handleMenuLink }) => {

    return (

   <MenuWrapper>
            <UL>
                <LI theme="pink" >
                    <StyledTooltip title="Coming soon" placement="left">
                    <a onClick={() => handleMenuLink("dashboard")} className={activeLink === "dashboard" ? "active" : ""}><MenuIcon src={home} alt="home-icon"/><span>Dashboard</span></a>
                    </StyledTooltip>
                </LI>
                <LI theme="pink">
                    <StyledTooltip title="Coming soon" placement="left">
                    <a onClick={() => handleMenuLink("assets")} className={activeLink === "assets" ? "active" : ""}><MenuIcon src={assets} alt="assets-icon" /><span>Assets</span></a>
                    </StyledTooltip>
                </LI>
                <LI theme="pink">
                    <StyledTooltip title="Coming soon" placement="left">
                    <a onClick={() => handleMenuLink("game stats")} className={activeLink === "game stats" ? "active" : ""}><MenuIcon src={stats} alt="stats-icon" /><span>Game Stats</span></a>
                    </StyledTooltip>
                </LI >
                <LI theme="pink">
                    <StyledTooltip title="Coming soon" placement="left">
                    <a onClick={() => handleMenuLink("rewards")} className={activeLink === "rewards" ? "active" : ""}><MenuIcon src={rewards} alt="rewards-icon" /><span>Rewards</span></a>
                    </StyledTooltip>
                </LI>
                <LI theme="pink">
                    <StyledTooltip title="Coming soon" placement="left">
                    <a onClick={() => handleMenuLink("staking")} className={activeLink === "staking" ? "active" : ""}><MenuIcon src={staking} alt="staking-icon" /><span>Staking</span></a>
                    </StyledTooltip>
                </LI>
                <br/>
                <LI theme="blue">
                    <a onClick={() => handleMenuLink("wallets")} className={activeLink === "wallets" ? "active" : ""}><MenuIcon src={wallets} alt="wallets-icon" /><span>Wallets</span></a>
                </LI>
                <LI theme="blue">
                    <StyledTooltip title="Coming soon" placement="left">
                    <a onClick={() => handleMenuLink("settings")} className={activeLink === "settings" ? "active" : ""}><MenuIcon src={settings} alt="wallets-icon" /><span>Settings</span></a>
                    </StyledTooltip>
                </LI>
            </UL>
         
        </MenuWrapper>



    );

    


}

const DashboardContent = () => {
    return <div>Dashboard Content</div>;
  };

export default Menu;