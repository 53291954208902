import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import AnimationLoader from './AnimationLoader'

import TopTrouserPairs from './TopTrouserPairs'


function DisplayPairs(props){

  const groupRef = useRef()


  useEffect(() => {

    // console.log(groupRef)

  },[groupRef.current])

return(
  
   <>

    <TopTrouserPairs groupRef={groupRef} />

   </>
  
  )
}

export default DisplayPairs;