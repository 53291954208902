import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box, useProgress } from '@react-three/drei'

import * as THREE from "three";

import {MainQualityContext} from 'components/Context/QualityContext'

function Sun(props){

  const {quality} = useContext(MainQualityContext)

  const [sunVisible, setSunVisible] = useState(quality !== 'Low')

  useEffect(() => {

    if (quality === 'Low'){
      setSunVisible(false)
    }else{
      setSunVisible(true)
    }

  },[quality])

  const sunRef = props.sunRef

  const size = props.size

  const position = props.position //[10, 10, -50]


  useFrame((state) => { 

    sunRef.current.position.x = state.camera.position.x + 10

    sunRef.current.position.y = state.camera.position.y + 40

    sunRef.current.position.z = state.camera.position.z + 200

    let camera_quaternion = state.camera.quaternion

    camera_quaternion = new THREE.Quaternion(camera_quaternion.w, camera_quaternion.x, camera_quaternion.y, camera_quaternion.z)

    let current_quaternion = sunRef.current.quaternion

    current_quaternion = new THREE.Quaternion(current_quaternion.w, current_quaternion.x, current_quaternion.y, current_quaternion.z)


    let towards_quaternion = current_quaternion.rotateTowards(camera_quaternion, 1)



  })


  return(

    <mesh visible={sunVisible} position={position} ref={sunRef} rotation={[0,0,0]}>

      <sphereGeometry args={[size, 128, 128]} />
      <meshBasicMaterial color={"#FFFFFF"} opacity={0.1} />

    </mesh>

  )

}

export default Sun;