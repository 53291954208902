import { lazy, useState, useEffect, useContext, Suspense } from 'react'
import { MainInterfacesContext } from 'components/Context/InterfacesContext'
import { MainPlayerContext } from 'components/Context/PlayerContext'
import {MainWorldContext} from 'components/Context/WorldContext'
import {MainExperienceContext} from 'components/Context/ExperienceContext'




function SceneAudio(props){

  const {name, position, lod} = props

  console.log(name)

  const import_str = './World/Scenes/' + name + '/' + 'Audio'

  const [NewAudio] = useState(lazy(() => import(`${import_str}`)))

  console.log(import_str)

  return(

    <>
      <Suspense fallback={null} >
        <NewAudio key={name} position={position} lod={lod} />
      </Suspense>
    </>

  )
}



function Interfaces(props) {
   

  const {activeScenes, closestScene} = useContext(MainWorldContext)

  
  
return(
  
  <>



{/* {
  Object.keys(closestScene)?.map((key) => {
    return(

      <SceneAudio 
        key={key} 
        name={key} 
        position={activeScenes[key].position} 
      />

    )
  })
 }*/}



  </>
  
  )
}

export default Interfaces;
