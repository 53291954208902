


import styled,  { keyframes } from 'styled-components';

const ButtonText = styled.h1`
    position: relative;
    font-size: 15px;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `




function RaffleButton(props){
		
	const started = props.started
	const ended = props.ended

	const totalTickets = props.totalTickets
	const purchasedTickets = props.purchasedTickets

	const ticketPrice = props.ticketPrice

	const yourTickets = props.yourTickets

	const endTime = props.endTime

	const timeStatus = props.timeStatus

	const winner = props.winner

	const stake_address = props.stake_address

	const setRaffleAsset = props.setRaffleAsset

	const raffleInfo = props.raffleInfo

	//const featuredRaffle = props.featuredRaffle 


	return(

		<>


			{!started ?




                  <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                

                  <div className="raffleBtn2">

                  <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              
                    <ButtonText style={{fontSize:'20px'}}>
                          SOON
                    </ButtonText>

                  </div>
                </div>


                </div>




                 : !ended ?




                  <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                

                  <div className={purchasedTickets < totalTickets ? "raffleBtn" : "raffleBtn_dis"}

                    onClick={() => {


                      if (purchasedTickets < totalTickets){
                      setRaffleAsset(
                      	{


                       ...raffleInfo,

                       ticketPrice: ticketPrice,
                       purchasedTickets: purchasedTickets,
                       totalTickets: totalTickets,
                       yourTickets: yourTickets,
                       endTime: endTime,
                       winner: winner,
                       timeStatus: timeStatus

                      }
                      	)
                    }}
                  }

                  >

                  <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div>
                            <img src='./Cardano_white.svg' style={{width:'30px', marginLeft:'-20px', marginRight:'5px',zIndex:'100', marginTop: '5px'}} />
                        </div>
                        

                        
                        <ButtonText style={{fontSize:'20px'}}>
                          {ticketPrice}
                        </ButtonText>

                       
                  </div>
                </div>


                </div>


                : !winner || winner == '' ?

            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                <div className="raffleBtn2">

                 <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonText style={{fontSize:'16px'}}>
                      DRAWING
                    </ButtonText>
                   </div>

                </div>

                </div>



                :


                <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                <div className="raffleBtn2">

                 <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonText style={{fontSize:'16px'}}>
                     {stake_address == winner ? 'WINNER' : 'DRAWN'}
                    </ButtonText>
                   </div>

                </div>

                </div>



              }


        </>
	)
}

export default RaffleButton;


