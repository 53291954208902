import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import ValidPairs from './ValidPairs'

import ChosenPair from './ChosenPair'

import ModelAttributes from './ModelAttributes'

import ClayAnimations from './ClayAnimations'




function TopTrouserPairs(props){

  const groupRef = props.groupRef
  const [models, setModels] = useState([])
  const [currentModel, setCurrentModel] = useState()
  const [attributes, setAttributes] = useState([])
  const [loadedAttributes, setLoadedAttributes] = useState({})
  const [assembledModel, setAssembledModel] = useState()
  const [mixer, setMixer] = useState()
  const [time, setTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);
  const [curIndex, setCurIndex] = useState(0)
  const [animationDict, setAnimationDict] = useState({})
  const [ currentAction, setCurrentAction ] = useState()
  const [needsUpdate, setNeedsUpdate] = useState(false)


  useEffect(() => {
    //groupRef.current = undefined
    setCurrentModel()
    setCurrentModel(models)
    setMixer()

  },[models])



  function prepareMixer(){

    console.log('PREPARE MIXER')

  setNeedsUpdate(false)
  if (typeof(groupRef) != 'undefined' && typeof(groupRef.current) != 'undefined' && groupRef.current.children.length == Object.keys(models).length && typeof(animationDict) != 'undefined'){
    

      const animGroup = new THREE.AnimationObjectGroup()
    
      for (let i = 0; i < groupRef.current.children.length; i++ ){
        console.log('CHILDREN')
        animGroup.add(groupRef.current.children[i])
      }

      const tempMixer = new THREE.AnimationMixer(animGroup)

      for (let key in animationDict){

        let cur_animation = animationDict[key] 

        let temp_action = tempMixer.clipAction(cur_animation)

        if (key == 'Idle'){

          temp_action.play()

        }
        
      }

      setMixer(tempMixer)

    }
}




  useFrame((state, delta) => {

    mixer?.update(delta)

    if (typeof(mixer) == 'undefined'){
      console.log('MIXER IS UNDEFINED')
      prepareMixer()
    }

    console.log(groupRef)


  })


return(

    <>

      <ValidPairs setModels={setModels} />
      <ChosenPair model={currentModel} setAttributes={setAttributes} />

      {currentModel ?
      <Suspense fallback={null}>

        <group ref={groupRef} position={[0,-0.99,-2]}>
          <ModelAttributes attributes={attributes} loadedAttributes={loadedAttributes} setLoadedAttributes={setLoadedAttributes}/>
        </group>

      </Suspense>
     : null }
     
      <ClayAnimations animationDict={animationDict} setAnimationDict={setAnimationDict} mixer={mixer} setMixer={setMixer} />
      
    </>
  
  )
}

export default TopTrouserPairs;
