import React from 'react'
import { useState, useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

import './VotePopUp.css'

import {MainClaimContext} from './ClaimContext'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UnbakePopUp(props) {

  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

  const setIsOpen = props.setIsOpen
  const setEnterUnbake = props.setEnterUnbake
  const fullAmount = props.fullAmount
  const slashedAmount = props.slashedAmount
  const setIsSubmitted = props.setIsSubmitted


  const activeStyle = {
    background: 'linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%)',
    boxShadow: 'inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5)' ,
    marginTop:'20px',
    marginBottom:'0px'
}


function close() {

    setEnterUnbake(false)
    setIsOpen(false)

}


function submit() {

    setEnterUnbake(false)
    setIsOpen(false)

    setIsSubmitted(true)

}



  return (
  <>  
    {true ? (


   


<Grid container justifyContent="center" alignItems="center">


    <div className='popup' style={{width:'100%', height:'100%', zIndex:'1350', overflow:'scroll'}}  > 
 <Grid container justifyContent="center" alignItems="center" style={{display:'flex',position:'absolute', top:'0px', height:'100%'}}>
   
        <div className='popup-inner'>

        <button className='close-btn' onClick={() => {
            close()
        }}>X</button> 

        <Grid container justifyContent="center" alignItems="center" style={{height:'100%', width:'100%'}}>

            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}

              style={{height:'100%', width:'80%'}}
            >


           
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', objectFit: 'cover', minWidth:'200px', width:'40%', height:'40%'}}>
             <img src="/warning.png" style={{width:'100%', height:'auto', minWidth:'160px', maxWidth:'30vh'}}/>
            </div>
        
          
      
                <div className='Question' style={{color:'bisque'}}><h1 style={{fontSize:'25px'}}>Attention!</h1>

                

                <h3>
                    If you proceed with unbaking,  you will only receive
                </h3>


                <h3>
                 <span style={{color:'red', fontSize:'20px'}}>{slashedAmount}</span> / <span style={{color:'green'}}>{fullAmount}</span>
                </h3>


                 <h3>
                     of total rewards
                </h3>

                </div>


                <p>
                    You are agreeing to the conditions above by pressing the 'UNBAKE!' button
                </p>
          

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}

              style={{width:'80%'}}
            >





            </Stack>


            <div style={{display:'flex',justifyContent: 'center', alignItems:'center', width:'60%',height:'25%',minHeight:'90px'}}>

                    <button className='submit-btn' onClick={submit}>Unbake!</button>

            </div>
            </Stack>
        </Grid>
        </div>
 </Grid>
    </div>
   

</Grid>



    )
    :
    (null)}
    </>
  )
}

export default UnbakePopUp


