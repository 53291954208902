import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import AnimationLoader from './AnimationLoader'

import DisplayAvatar from './DisplayAvatar'


import { MainPlayerContext } from 'components/Context/PlayerContext'

function DisplayPairs(props){

  const {avatarMeta, avatarNeedsUpdate, setAvatarNeedsUpdate} = useContext(MainPlayerContext)



  
 const animationPaths = [

    {
      name: "Idle",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Idle.glb"
    },

    {
      name: "Walk",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Walking.glb"
    },

    {
      name: "Run",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Running.glb"
    },

    {
      name: "Jump",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Jumping.glb"
    },

    {
      name: "Fall",
      url: "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pairs/animations/glb/Falling.glb"
    }
   
  ] 
  


  //const [needsUpdate, setNeedsUpdate] = useState(avatarNeedsUpdate)


  //const [meta, setMeta] = useState({})

  //const meta = props.meta

  //const meta = avatarMeta

  
  const meta = props.meta
  
  

  /*
  const [meta, setMeta] = useState({
    
        "model":

        {
          "head": "Head",
          "trousers": "ClayNation_Shirt_Trousers",
          "clothes": "Shirt&ClayNation",
          "hat": "Beanie",
          "mouth": "Rose&White",
          "eyes": "Small",
          "brows": "Pierced",
          "accessories": "Mushroom_Earrings"
         
        },

        "clayColor": "White"
  })
  */

  //const [meta] = useState(avatarMeta)

  //const meta = props.meta


  const groupRef = props.charGroupRef

  const animationDict = props.animationDict 
  const setAnimationDict = props.setAnimationDict

  const mixer = props.mixer
  const setMixer = props.setMixer

  const setAnimations = props.setAnimations


  const identifier = props.identifier


  //const [isLoaded, setIsLoaded] = useState(false)

  const {isLoaded, setIsLoaded} = props


  /*
  const [test, setTest] = useState(avatarNeedsUpdate)

  useEffect(() => {


    if (avatarNeedsUpdate){
      console.log('NEEDS UPDATE...')
      setTest(avatarNeedsUpdate)
      setAvatarNeedsUpdate(false)
    }

    




  },[avatarNeedsUpdate])
  */


  /*
  useEffect(() => {

    console.log('NEW META\n\n\n')

  }, [meta])
  */

/*

  useEffect(() => {

    if (avatarNeedsUpdate){
      //console.log('AVATAR META')
      //console.log(avatarNeedsUpdate)
      setAvatarNeedsUpdate(false)

      //console.log(avatarMeta)

      setMeta(avatarMeta)
    }
    

  },[avatarNeedsUpdate])

  */



return(
  
   <>


   <Suspense fallback={null}>
  
    <DisplayAvatar 

      key={identifier}

      identifier={identifier}

      needsUpdate={avatarNeedsUpdate}
      setNeedsUpdate={setAvatarNeedsUpdate}

      meta={meta}
      clayColor={meta.clayColor}
      groupRef={groupRef} 
      animationDict={animationDict} 
      setAnimationDict={setAnimationDict} 

      mixer={mixer}
      setMixer={setMixer}

      animationPaths={animationPaths}

      setAnimations={setAnimations}

      isLoaded={isLoaded}
      setIsLoaded={setIsLoaded}

    />


    </Suspense>


   </>
  
  )
}

export default DisplayPairs;