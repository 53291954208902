import { useState, useEffect, useContext, useRef } from 'react'

import './Chat.css'

import { MainPlayerContext } from 'components/Context/PlayerContext'

import { MainMultiplayerContext } from 'components/Context/MultiplayerContext'

import {MainChatContext} from 'components/Context/ChatContext'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function Message(props){

  const {isUser, socket_id, handle, msg, avatar_url} = props



  return(
      <>

    {!isUser ?
    <div style={{display:'flex', justifyContent:'start',flexDirection:'row', width:'100%', marginTop:'10px', marginBottom:'10px', marginRight:'5px'}}>


      <div style={{marginLeft:'5px', marginRight:'5px'}}> 
             <img className="" src={avatar_url === '' ? '' : 'https://nyc3.digitaloceanspaces.com/claynation/IPFS/240x240/' + avatar_url + '.png'} />
      </div>

      <div style={{marginTop:'5px', marginBottom:'5px', maxWidth:'60%', display:'flex', backgroundColor:'rgba(0, 0, 0, 0.25)', color:'rgba(255, 255, 240, 0.744)',
      flexDirection:'column', borderRadius:'10px', justifyContent:'center', paddingLeft:'10px', paddingRight:'10px', textShadow: '0px 0px 4px rgba(255, 255, 255, 0.43)',
      paddingBottom:'5px', paddingTop:'5px'
      }}>
        <div style={{fontSize:'12px', color:'#B1F2B7', textShadow:'none'}}>
          {handle}
        </div>

        <div>
          {msg}
        </div>
      </div>

    </div>


    : 


    <div style={{display:'flex', justifyContent:'end', flexDirection:'row', width:'100%', marginTop:'10px', marginBottom:'10px'}}>

      

     <div style={{marginTop:'5px', marginBottom:'5px', maxWidth:'60%', display:'flex',backgroundColor:'rgba(175, 170, 170, 0.25)',
     textShadow: '0px 0px 4px rgba(255, 255, 255, 0.43)',
     flexDirection:'column', borderRadius:'10px', justifyContent:'center', paddingLeft:'10px', paddingRight:'10px', 
     paddingBottom:'5px', paddingTop:'5px'
      }}>        
        <div style={{fontSize:'12px', color:'#B1F2B7', textShadow:'none'}}>
          {handle}
        </div>

        <div style={{color:'rgba(255, 255, 255, 0.744)'}}>
          {msg}
        </div>
      </div>


      <div style={{marginLeft:'5px', marginRight:'5px'}}> 
        <img className="" src={avatar_url === '' ? '' : 'https://nyc3.digitaloceanspaces.com/claynation/IPFS/240x240/' + avatar_url + '.png'} />
      </div>

    </div>


    }
    
    {/*
    <div>
      <img src={avatar_url} className={isUser ? "avatarimgB" : "avatarimgA"} />
      <div className={isUser ? "lineA" : "lineB"}>{content}</div>
    </div>
  */}
  </>

  )

}



function Chat(props) {


  const {chatOpen, setChatOpen} = props


  const {messageContent, setMessageContent, inputBlocked, setInputBlocked} = useContext(MainPlayerContext)

  const {chatMessages, setChatMessages} = useContext(MainChatContext)


  const {remotePlayersDict, setRemotePlayersDict} = useContext(MainMultiplayerContext)

  //const [chatMessages, setChatMessages] = useState([])

  const [allMessages, setAllMessages] = useState(chatMessages)

  const {userSocketId} = useContext(MainMultiplayerContext)

  const [textValue, setTextValue] = useState('')

  const userSocket = userSocketId


   
  const [textMessages, setTextMessages] = useState(chatMessages)

  const [chatFolded, setChatFolded] = useState(false)


  const messageContainerRef = useRef(null)

  const lastMessageRef = useRef()


  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


  useEffect(() => {

      // console.log(chatMessages)

      // console.log('HERE UPDATE!!!')

      // console.log('NEW MESSAGE')


    //const containerIsFocused = document.activeElement === messageContainerRef.current
    
    //if (messageContainerRef.current && !containerIsFocused){
    if (messageContainerRef.current){
      //messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight - messageContainerRef.current.offsetHeight
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;

    }

  },[chatMessages, messageContainerRef.current, allMessages])


  useEffect(() => {

    //console.log('\n\n\nALL MESSAGES\n\n\n')

  },[allMessages])


  function pushMessage(socket_id, msg){

    if (msg === ''){
      return
    }
    
    
    setTextMessages(
      (prev) => [...prev, {
          socket_id: socket_id,
          msg: msg
        }]
      )

    // console.log('CHAT MESSAGES')
    // console.log(textMessages)

    // console.log('CHAT MESSAGES')
    // console.log(chatMessages)

    // console.log('REMOTE PLAYERS DICT')
    // console.log(remotePlayersDict)

  }


  useEffect(() => {

    // console.log('LAST MESSAGE REF')
    // console.log(lastMessageRef)

  },[lastMessageRef.current])



return(
 <>

{chatOpen ?

   <div className={!chatFolded? 'chat' : 'chat_folded'} style={matchesM ? {} :{transform: 'scale(0.8)', bottom:'-20px', left:'-20px'}}

    onClick={(event)=>{
     if (event.detail === 2){
        setChatFolded(!chatFolded)
      }
    }}

   >

    <div ref={messageContainerRef} style={{overflow:'scroll', height:'70%', width:'100%', marginTop:'10px', 
   justifyContent:'center', display: !chatFolded ? 'flex' : 'none'}}>
      <div  style={{width:'90%'}}>
        {
          chatMessages.map((textMessage, i) => {
            return(

                <Message 
                  key={i}
                  isUser={textMessage.socket_id === userSocket}  
                  //isUser={textMessage.sender.socket === userSocket}  
                  socket_id={textMessage.socket_id}
                  msg={textMessage.msg}

                  handle={

                    textMessage.hasOwnProperty('nickname') ? textMessage.nickname : 
                    textMessage.socket_id in remotePlayersDict && 'nickname' in remotePlayersDict[textMessage.socket_id] ?
                    remotePlayersDict[textMessage.socket_id].nickname : ''
                    //textMessage.handle
                  }
                  avatar_url={textMessage.hasOwnProperty('avatar_url') ? textMessage.avatar_url : textMessage.socket_id in remotePlayersDict && 'meta' in remotePlayersDict[textMessage.socket_id] &&
                  'image' in remotePlayersDict[textMessage.socket_id]['meta'] ?
                   remotePlayersDict[textMessage.socket_id].meta.image : ''}
                  /*avatar_url={textMessage.socket_id in multiplayerContextInfo && 'avatar_thumbnail_url' in multiplayerContextInfo[textMessage.socket_id] 
                    ?

                    multiplayerContextInfo[textMessage.socket_id].avatar_thumbnail_url : ''}

                  */
                />

            )
          })
        }
      </div>


    </div>

  
  <div>
      
      <input className='type'

        value={textValue}

        onClick={()=>{
          //console.log('FOCUS')
          //setInputBlocked(true)
        }}

        onChange={(event) => {
          //console.log(event.target.value)
          setTextValue(event.target.value)
        }}

        onKeyPress={(event) => {
          if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();

            pushMessage(textValue)
            setMessageContent(textValue)
            setTextValue('')
          }
        }}


        onInput={() =>
          {
            if (!inputBlocked){
            setInputBlocked(true)
          }
        }}

        onFocus={() => {

          if (!inputBlocked){
            setInputBlocked(true)
          }

        }}

        onBlur={() => {

          if (inputBlocked){
            setInputBlocked(false)
          }

        }}
        
      >

      </input>

    

    <button className='send'

      onClick={()=>{

        //setInputBlocked(false)
        pushMessage(textValue)
        setMessageContent(textValue)
        setTextValue('')

      }}

    >
    
      SEND

    </button>

  </div>



  </div>

  : null}

 </>
  
  )
}

export default Chat;
