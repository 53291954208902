import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {

    cardanoAddress: '',
    cardanoStakeAddress: '',

    cardanoSecret_1: '',
    cardanoSecret_2: '',

    polygonAddress: '',
    polygonSecret_1: '',
    polygonSecret_2: ''
    
}


const actions = {

    SET_CARDANO_ADDRESS: 'SET_CARDANO_ADDRESS',
    SET_CARDANO_STAKE_ADDRESS: 'SET_CARDANO_STAKE_ADDRESS',

    SET_CARDANO_SECRET_1: 'SET_CARDANO_SECRET_1',
    SET_CARDANO_SECRET_2: 'SET_CARDANO_SECRET_2',

    SET_POLYGON_ADDRESS: 'SET_POLYGON_ADDRESS',
    SET_POLYGON_SECRET_1: 'SET_POLYGON_SECRET_1',
    SET_POLYGON_SECRET_2: 'SET_POLYGON_SECRET_2',
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_CARDANO_ADDRESS:
            return {...state, cardanoAddress: action.value}

        case actions.SET_CARDANO_STAKE_ADDRESS:
            return {...state, cardanoStakeAddress: action.value}

        case actions.SET_CARDANO_SECRET_1:
            return {...state, cardanoSecret_1: action.value}

        case actions.SET_CARDANO_SECRET_2:
            return {...state, cardanoSecret_2: action.value}


        case actions.SET_POLYGON_ADDRESS:
            return {...state, polygonAddress: action.value}

        case actions.SET_POLYGON_SECRET_1:
            return {...state, polygonSecret_1: action.value}

        case actions.SET_POLYGON_SECRET_2:
            return {...state, polygonSecret_2: action.value}


        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainLinkingContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        
        cardanoAddress: state.cardanoAddress,
        cardanoStakeAddress: state.cardanoStakeAddress,

        cardanoSecret_1: state.cardanoSecret_1,
        cardanoSecret_2: state.cardanoSecret_2,

        polygonAddress: state.polygonAddress, 
        polygonSecret_1: state.polygonSecret_1,
        polygonSecret_2: state.polygonSecret_2,       

        setCardanoAddress: value => {
            dispatch({type: actions.SET_CARDANO_ADDRESS, value})
        },

        setCardanoStakeAddress: value => {
            dispatch({type: actions.SET_CARDANO_STAKE_ADDRESS, value})
        },


        setCardanoSecret_1: value => {
            dispatch({type: actions.SET_CARDANO_SECRET_1, value})
        },

        setCardanoSecret_2: value => {
            dispatch({type: actions.SET_CARDANO_SECRET_2, value})
        },


        setPolygonAddress: value => {
            dispatch({type: actions.SET_POLYGON_ADDRESS, value})
        },

        setPolygonSecret_1: value => {
            dispatch({type: actions.SET_POLYGON_SECRET_1, value})
        },

        setPolygonSecret_2: value => {
            dispatch({type: actions.SET_POLYGON_SECRET_2, value})
        },


        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainLinkingContext.Provider value={value}>
            {children}
        </MainLinkingContext.Provider>
        )
}


function LinkingContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default LinkingContext;


