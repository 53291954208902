import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {
    
    activeInterfaces: {},

    cursorType: ''
   
}


const actions = {
    SET_ACTIVE_INTERFACES: "SET_ACTIVE_INTERFACES", 
    SET_CURSOR_TYPE: "SET_CURSOR_TYPE"
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_ACTIVE_INTERFACES:
            return {...state, activeInterfaces: action.value}

        case actions.SET_CURSOR_TYPE:
            return {...state, cursorType: action.value}

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainInterfacesContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {

        activeInterfaces: state.activeInterfaces,

        cursorType: state.cursorType,

      
        setActiveInterfaces: value => {
             dispatch({type: actions.SET_ACTIVE_INTERFACES, value})
        },

        setCursorType: value => {
             dispatch({type: actions.SET_CURSOR_TYPE, value})
        },

        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainInterfacesContext.Provider value={value}>
            {children}
        </MainInterfacesContext.Provider>
        )
}


function InterfacesContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default InterfacesContext;


