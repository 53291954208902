import { useState, useEffect } from 'react'

import * as THREE from "three";
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';


import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

function HDRLoader(props) {

  const {hideBackground} = props

  const url = props.url

  const { camera, scene, gl} = useThree();

  const texture = useLoader(RGBELoader, url ? url : 'hdr/environment/water_quarter/forest_2.hdr')

  texture.mapping = THREE.EquirectangularReflectionMapping;
  

  
  useEffect(() => {

    if (typeof(texture) != 'undefined'){

      if (!hideBackground){
        scene.background = texture;
      }
      scene.environment = texture;

        /*
        console.log(gl)
        gl.toneMapping = THREE.ACESFilmicToneMapping;
        gl.toneMappingExposure = 1;
        gl.outputEncoding = THREE.sRGBEncoding;
        */
        /*
        renderer = new THREE.WebGLRenderer( { antialias: true } );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.outputEncoding = THREE.sRGBEncoding;
        */
    }

    },[texture])


  return (

    <>
   

    </>
  );
}

export default HDRLoader;
