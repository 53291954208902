import {useState, useEffect, useRef, Suspense, createContext, useContext} from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Sparkles, Stars, Float, Environment, Sky, Cloud } from '@react-three/drei'
import * as THREE from 'three'

import { EffectComposer, DepthOfField, Bloom, Noise, Vignette, GodRays, ToneMapping, SSAO, SMAA, Outline, SSR, BrightnessContrast, HueSaturation } from '@react-three/postprocessing'
import { BlurPass, Resizer, KernelSize, BlendFunction } from 'postprocessing'

import Plinth from './Scene/Plinth'

import SpawnPlinth from './Scene/SpawnPlinth'
import SpawnIsland from './Scene/SpawnIsland'

import SceneAvatarSelect from './Scene/Experience.jsx'

import UIAvatarSelect from  './UI/UIAvatarSelect.jsx'

//import './DotRing.css'

import Fog from '../Fog/Fog'
import Sun from '../Sun/Sun'
import Atmosphere from '../Atmosphere/Atmosphere'
import Clouds from '../Clouds/Clouds'

import Island from './Scene/Island'

//import VolumeClouds from '../VolumeClouds/VolumeClouds'

import {MainClaimContext} from '../../ClaimContext'

//import MouseContextProvider from "../../Context/MouseContext";

//import { MouseContext } from "../../Context/MouseContext";

import ParticlesUp from './Scene/ParticlesUp'

import { Perf } from 'r3f-perf'

function Effects(props){

  const {sunRef} = props


 
  return(
    <>


    <EffectComposer autoClear={true} multisampling={20}>
       
       <DepthOfField 
          focusDistance={0.01} 
          focalLength={0.4} 
          bokehScale={3} 
        />


     {/* {typeof(sunRef.current) != 'undefined' ?


       <GodRays
          sun={sunRef.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}*/}


{/*
      {typeof(streetLightRef1.current) != 'undefined' ?

       <GodRays
          sun={streetLightRef1.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}*/}




     {/* {typeof(streetLightRef2.current) != 'undefined' ?

       <GodRays
          sun={streetLightRef2.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}
*/}


       {/* <HueSaturation
          blendFunction={BlendFunction.NORMAL} // blend mode
          hue={0.0} // hue in radians
          saturation={0.05} // saturation in radians
        />*/}

        {/* <SMAA /> */}

      {/* <Bloom
          intensity={1.0} // The bloom intensity.
          blurPass={undefined} // A blur pass.
          width={Resizer.AUTO_SIZE} // render width
          height={Resizer.AUTO_SIZE} // render height
          kernelSize={KernelSize.LARGE} // blur kernel size
          luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.5} // smoothness of the luminance threshold. Range is [0, 1]
        />*/}

     </EffectComposer>
     </>
  )
}



function SelectAvatarDisplay(props){

  
  const {meta} = props
  
  const [newMeta, setNewMeta] = useState({})
  const [prevMeta, setPrevMeta] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPrevLoaded, setIsPrevLoaded] = useState(false)
  const [displaySparkles, setDisplaySparkles] = useState(false)
  const [displayAvatar, setDisplayAvatar] = useState(false)

  useEffect(() => {

    if (isLoaded){
      setDisplaySparkles(true)
    }

    const interval = setInterval(() => {
      setDisplaySparkles(false);
    }, 2000);

    return () => clearInterval(interval);
  }, [isLoaded]);


  useEffect(() => {

    setDisplayAvatar(false)
    setIsLoaded(false)

    setNewMeta({...meta})

    console.log('\n\n\nMETA CHANGED\n\n\n')

    console.log('META')
    console.log(meta)
    console.log('CURRENT META')
    console.log(newMeta)
    console.log('PREV META')
    console.log(prevMeta)


  },[meta])


  useEffect(() => {

    if (isLoaded){
      setPrevMeta({...meta})
      setIsPrevLoaded(false)
    }
    
  },[isLoaded])



  useEffect(() => {

    if (isLoaded){
      const interval = setInterval(() => {
        setDisplayAvatar(true);
      }, 1000);
      return () => clearInterval(interval);
  }

  }, [isLoaded]);




  return(
    <>

      <Suspense fallback={null}>
        <SceneAvatarSelect key={'currentAvatar'} isVisible={displayAvatar && isLoaded} meta={newMeta} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
      </Suspense>


      {/*<ParticlesUp />*/}

      {displaySparkles && isLoaded || !isLoaded ?
        <Sparkles count={50} scale={ 0.5 } size={5} speed={0.3} color='lime' opacity={0.6} position={[0,1,0]} />
      : null}

    </>
  )
}



//   const DotRing = () => {

//     const { cursorType, cursorChangeHandler } = useContext(MouseContext);

//     const { x, y } = useMousePosition();
    

//     function useMousePosition() {
//       const [mousePosition, setMousePosition] = useState({ x: null, y: null });

//       useEffect(() => {
//         const mouseMoveHandler = (event) => {
//           const { clientX, clientY } = event;
//           setMousePosition({ x: clientX, y: clientY });
//         };
//         document.addEventListener("mousemove", mouseMoveHandler);

//         return () => {
//           document.removeEventListener("mousemove", mouseMoveHandler);
//         };
//       }, []);

//       return mousePosition;
//     }

//   return (
//     <>

//       <div
//         style={{ left: `${x}px`, top: `${y}px` }}
//         className={"ring " + cursorType}
//       ></div>
      
//     </>
//   );
// }





function SelectAvatar(){

  //const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)
  const [meta, setMeta] = useState({})
  const sunRef = useRef()
  const streetLightRef1 = useRef()
  const streetLightRef2 = useRef()
  const [sunPosition, setSunPosition] = useState([10, 10, -50])


  return(
    <>

      {/*<MouseContextProvider>

        <div style={{cursor:'none'}}>
          <DotRing />

          <UIAvatarSelect 
            meta={meta} 
            setMeta={setMeta} 
          />

        </div>

      </MouseContextProvider>*/}

    <div style={{cursor:'none'}}>
      <UIAvatarSelect 
              meta={meta} 
              setMeta={setMeta} 
            />
    </div>


      
       <Canvas 
          shadows
          camera={{ 
            fov: 50,
            near: 0.1,
            far: 1000,
          }} 

          style={{cursor:'none',
                  background:`radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%)`
                  }}
        >

    {/*<Perf />*/}

      <Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/AvatarSelection/HDRI1.HDR" background />

      {/*<Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/test7.hdr" background blur={0.4}    />    */}
      {/*<Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/snow_field_puresky_4k.hdr" background={false} blur={0.8} />*/}
      {/*<Environment files="blockadelabs/1.hdr" background blur={0.4}    />    */}
      {/*<Environment files={'https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/TheStand_Outside_Blurred.HDR'} background={false} />     */}
      {/*<Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/snow_field_puresky_4k.hdr" background={false} blur={0.8} />*/}
      {/*<Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/belfast_sunset_puresky_4k.hdr" background={true} blur={0.8} />*/}
      {/*<Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/wasteland_clouds_puresky_4k.hdr" background={false} blur={0.8} />*/}
      {/*<Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/kiara_1_dawn_4k.hdr" background={false} blur={0.8} />*/}


      <ambientLight color={0x404040} intensity={10} />

      <pointLight shadow-bias={-0.005}  color="#ff9bc9" position={[10, 10, 0]} intensity={0.8} distance={12} />
      <pointLight shadow-bias={-0.005}  color="#b4d8e7" position={[0, 10, 10]} intensity={0.8} distance={12} />
      <pointLight shadow-bias={-0.005}  color="#ff9bc9" position={[0, 10, 0]} intensity={0.8} distance={12} />


        <directionalLight 
          color={'#fcf2d9'}
          castShadow
          position={[1.8, 7, 10.5]} 
          intensity={1.3}
          shadow-mapSize={ [2048*2, 2048*2] }
          shadow-camera-near={ 0.01 }
          shadow-camera-far={ 20 }

          shadow-camera-top={ 5 }
          shadow-camera-right={ 5 }
          shadow-camera-bottom={ -5 }
          shadow-camera-left={ -5 }
          shadow-bias={-0.001} 
        />
      
        <Fog color={"#FFCBA4"} density={0.01} />

        {/*<Sun sunRef={sunRef} position={sunPosition} size={5} />  */}

        

      {/* <Atmosphere 
          effectController={{
            turbidity: 0.1,
            rayleigh: 0.06,
            mieCoefficient: 0.0116,
            mieDirectionalG: 0.018,
            sunSize: 0
          }}


          skyColors= 
            {{
              skyBottom: new THREE.Vector3(1.0, 0.6, 0.6),
              skyTop: new THREE.Vector3(0.8, 0.6, 0.9)
            }}


          sunPosition={[15, 10, -80]} 
        />

        <Clouds />
      */}


      <group position={[0,0,0]}>

      <OrbitControls 
        maxDistance={5+20} 
        minDistance={2.6} 
        enableDamping={true}  
        dampingFactor={0.05} 
        enablePan={false} 
        target={[0,1,0]} 

        minPolarAngle={0.5}
        maxPolarAngle={1.7}
      />



      <Float rotationIntensity={ 0.05 }>

      <group>
        
        <SelectAvatarDisplay meta={meta} />

        <Island streetLightRef1={streetLightRef1} streetLightRef2={streetLightRef2} />

        {/*<Plinth position={[0,-0.4,0]} rotation={[0,0,0]} scale={[2,2,2]} />*/}
        {/*<SpawnPlinth position={[0,-0.18,0]} rotation={[0,0,0]} scale={[1,1,1]}  />*/}
        {/*<Stars radius={350} depth={1} count={3000} factor={17} saturation={0.8} fade speed={1} />*/}

      </group>

      </Float>

      </group>

      {/*<Effects sunRef={sunRef} />*/}

      </Canvas>

    </>
  )
}

export default SelectAvatar;