import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotateSpinner = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const AnimatedSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSvg = styled.svg`
  width: 100px;
  height: 100px;
  animation: ${rotateSpinner} 1s linear infinite;
`;

const StyledCircle = styled.circle`
  fill: transparent;
  stroke-width: 6;
  stroke: #2A71D0;
  stroke-dasharray: 251.2;
  stroke-dashoffset: 0;
  transform-origin: center;
`;

const AnimatedSpinner = () => {
  return (
    <AnimatedSpinnerWrapper>
      <StyledSvg>
        <StyledCircle
          cx="50"
          cy="50"
          r="40"
          transform="rotate(-90 50 50)" // Adjust the initial rotation for the desired start position
        />
      </StyledSvg>
    </AnimatedSpinnerWrapper>
  );
};

export default AnimatedSpinner;
