import styled from "styled-components";
import { motion } from "framer-motion";

export const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

 
`;

export const DashboardWrapper = styled(motion.div)`
    width: 85%;
    height: 90%;
    background: var(--background);
    outline: 9px solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    contain: content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translate(0, 0);
        background: none;
        outline: none;
        border-radius: 0;
    }
   
`;

export const InnerWrapper = styled.div`
    width: 95%;
    height: 90%;
    margin: 0 auto;
    box-sizing: border-box;
    contain: content;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
    
    `;
