import { useState, useContext, useEffect } from 'react'
import ClayCard from './ClayCard'
import cn from '../../cn_meta.json'
import gc from '../../gc_meta.json'
import {Buffer} from 'buffer'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {MainClaimContext} from './ClaimContext'
import EmptyWallet from './EmptyWallet'
import VotePopUp from './VotePopUp'
import Loading from './Loading'

function Wallet(props) {

const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)
const [refreshInterval, setRefreshInterval] = useState()

const clay_dict = {cn:cn, gc:gc}

const collection_styles = {cn:{borderColor:'#FF9C83', serialColor:'#FFB5B5'}, gc:{borderColor:'#53C9EE', serialColor:'#AEE8FB'}}

const cn_info = {policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name: 'ClayNation'}
const gc_info = {policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', base_name: "ClayCharlotte"}

const clay_token_id = '45d7c7ab88146bbce7be2f6c062bef63f62e26663155b7a82a8d48815465737454657374'

const startColor = {'cn':'rgba(255, 156, 131, 1)', 'gc':'rgba(127, 212, 239, 1)'}

const [selectedAssets, setSelectedAssets] = useState([])

const isCompact = props.isCompact
const currentSlot = props.currentSlot


const assetsOwned = props.assetsOwned


const isWalletLoading = props.isWalletLoading

const setIsWalletLoading = props.setIsWalletLoading

const setHasBaking = props.setHasBaking


/*
useEffect(() => {

    console.log(selectedTokens)

}, [selectedTokens])
*/

const cardClick = (clay) => {
    console.log(clay)
}




return (

 <div style={{maxHeight:'100vh'}}> {/*style={{width:"60vw",marginLeft:'200px'}}>*/}
    {assetsOwned && assetsOwned.clays.length != 0 ? (
     <Container>
      <Grid container spacing={6}>
        {assetsOwned.clays.map(clay => (

        <Grid item xs={12} sm={isCompact ? 12 : 6} md={6} lg={assetsOwned.clays.length % 3 == 0 ? 4 : assetsOwned.clays.length % 2 == 0 ? 6 : 4} xl={assetsOwned.clays.length % 4 == 0 ? 3 : assetsOwned.clays.length % 3 == 0 ? 4 : assetsOwned.clays.length % 2 == 0 ? 4 : assetsOwned.clays.length == 1 ? 12 :  3} key={clay}> 
                                                   
        <ClayCard setHasBaking={setHasBaking} image={'https://claynation.nyc3.digitaloceanspaces.com/IPFS/360x360/'+clay_dict[clay.slice(0,2)][clay.slice(2)]+'.png'} 
        serial={clay.slice(2)} clayInfo={assetsOwned.clays_info[clay]} currentSlot={currentSlot} amount={0} borderColor={collection_styles[clay.slice(0,2)].borderColor}  startColor={startColor[clay.slice(0,2)]}
        serialColor={collection_styles[clay.slice(0,2)].serialColor} clay_id={clay} selectedAssets={selectedAssets} setSelectedAssets={setSelectedAssets} collection={clay.slice(0,2)} slot={currentSlot} />
        </Grid>
          ))}
        </Grid>
     </Container>
     )

    :

    assetsOwned && assetsOwned.clays.length == 0 ?

    <Grid container spacing={6} justifyContent='center' alignItems='center' >
        <Grid item xs={12}>
            <EmptyWallet />
        </Grid>
    </Grid>

    : 
    <Grid container spacing={6} justifyContent='center' alignItems='center' >
        <Grid item xs={12}>
            <EmptyWallet />
        </Grid>
    </Grid>
     

    }

  </div>
  )
}

export default Wallet