import {useState, useEffect} from 'react'
import './LoadingDisplay.css'

import './NonSelectable.css'

import Typography from '@mui/material/Typography';

function LoadingDisplay(props){

  const {readyProgress, readyPlayer} = props

  //const [readyProgress] = useState(0.8)


  const [statusMessage, setStatusMessage] = useState('')


  const TypographyStyle ={
    fontFamily: 'ShortStack',
    color: 'rgba(174, 245, 184, 0.7)',
    fontSize: '1.3rem',
    textShadow: '0px 0px 2px rgba(255, 255, 255, 0.5)',
  }


  useEffect(() => {

    if (readyProgress < 0.1){
      setStatusMessage('Initializing World...')
    }else if (readyProgress < 0.5){
      setStatusMessage('Loading Scenes...')
    }else if (readyProgress < 0.6){
      setStatusMessage('Setting the Mood...')
    }
    else if(readyProgress < 0.9){
      setStatusMessage('Making Things Pop...')
    }else if (!readyPlayer){
      setStatusMessage('Loading Avatar...')
    }

  },[readyProgress])


  return(

    <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', zIndex:'4998'}}>

     <div style={{position:'absolute', width:'100%', height:'100%', backgroundColor: 'transparent',  background: `radial-gradient(77.66% 65.96% at 44.86% 48.39%,rgba(199, 0, 167, 0.2) 0%,rgba(0, 0, 0, 0) 100%),
    linear-gradient(145deg, #565f86 0%, #1b2126 100%)`, backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', zIndex:'4998'}}>

        <div style={{display:'flex', justifyContent:'end', alignItems:'center', width:'100%', height:'100%', flexDirection:'column'}}>

          <div style={{width:'500px', height:'600px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
             <img className="nonSelectable" src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Run+Jump.gif"} style={{width:'400px', marginTop:'-200px'}} />
          </div>



          

          <div className="nonSelectable" style={{width:'600px', height:'100px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', marginBottom:'50px'}}>
            <div className="progress-text">
              {statusMessage}
            </div>


            <div style={{marginLeft:'40px',width:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
             
             <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `${readyProgress*100}%` }}
              ></div>

            </div>


              <div className="nonSelectable" style={{width:'80px', height:'40px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
                
                <Typography variant="body2" style={TypographyStyle} >
                  {String(Math.round(readyProgress*100)) + '%'}
                </Typography>
              </div>

          </div>

          {/*<div style={{width:'100px', height:'40px', backgroundColor:'blue'}}>
            BLOB
          </div>*/}

          </div>



         
        </div>

       </div>

       </div>

  )
}


export default LoadingDisplay;