import styled from 'styled-components';

export const WalletContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
gap: 0.8rem;
justify-items: center;
@media screen and (max-width: 768px) {
    gap: 0.4rem;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    gap: 0.2rem;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
}  
`;

export const WalletIcon = styled.img`
width: 15%;
height: auto;
margin: 0 2rem;
@media screen and (max-width: 768px) {
    width: 20%;
    padding-right: 0.5rem;
    margin: 0;
} 
`;

export const H1 = styled.h1`
font-size: clamp(0.5rem, 2.2vw, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 5vw, 4rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.7vw, 3rem);
}   
`;

export const H2 = styled(H1)`
font-size: clamp(0.5rem, 1.4vw, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
padding-bottom: 1rem;
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem);
    text-align: center;
    padding: 1rem 2rem;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.2vw, 3rem);
    padding: 0;
}     
`;

export const H4= styled(H1)`
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem;
color: var(--claypink);
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3vw, 3rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
}  
`;
