import { Link } from "react-router-dom";
import GlobalStyle from '../GlobalStyles';
import transition from "../Components/Transition";
import logo from '../Images/logo_purple.png';
import { motion } from "framer-motion";
import Steppers from "../Components/Slides";
import { HomeWrapper, SkipButton, updates } from './Home.styled';
import { Logo } from "./UnlockXP.styled";



function Home() {

return (
   <HomeWrapper>
        <GlobalStyle />
        <a href="https://www.clayspace.io/" target="_blank">
        <Logo as={motion.img} src={logo} 
          alt="claynation-logo"
          className="claynation-logo"
          initial={{ opacity:0 }}
          animate={{ opacity:1, type: "spring" }}
          transition={{ duration: 1.7, delay: 2 }} ></Logo></a>

        <Steppers updates={updates} />
        
        <Link to="/sandbox-unlock"><SkipButton type="button">SKIP</SkipButton></Link>
    </HomeWrapper>

);
}

export default transition(Home);