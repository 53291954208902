import { useState } from 'react';
import { BannerWrapper, BannerImg, Logo, StyledTooltip } from "./Banner.styled";
import { motion } from "framer-motion";
import logo from "../Images/Logo.png";
import banner01 from '../Images/banner.png';



function Banner ({ activeLink, handleMenuLink }) {

  
    return (

        <>
        <BannerWrapper >
            <BannerImg src={banner01} alt="banner" onClick={() => {
            //handleMenuLink("banner")
            }} className={activeLink === "banner" ? "active" : ""} />
            <a href="https://www.clayspace.io/" target="_blank">
               <Logo as={motion.img} 
            src={logo}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.7, delay: 1 }}
            variants={{
                visible: { opacity: 1},
                hidden: { opacity: 0 }
            }}
             ></Logo></a>
        </BannerWrapper>
       

        {/*
        <StyledTooltip title="CHANGE BANNER" placement="right">
        <BannerWrapper >
            <BannerImg src={banner01} alt="banner" onClick={() => {
            //handleMenuLink("banner")
            }} className={activeLink === "banner" ? "active" : ""} />
            <a href="https://www.clayspace.io/" target="_blank">
               <Logo as={motion.img} 
            src={logo}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.7, delay: 1 }}
            variants={{
                visible: { opacity: 1},
                hidden: { opacity: 0 }
            }}
             ></Logo></a>
        </BannerWrapper>
        </StyledTooltip>
        */}
        </>

    );



}

export default Banner;