import styled from "styled-components";


export const FormWrapper = styled.div`
height: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding-top: 1rem;
@media screen and (max-width: 768px) {
line-height: 0.4rem;
font-family: 'ClayFont';
padding-top: 3rem;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  height: 165%;
} 
`;

export const FormGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: 1fr 1fr;
gap: 0.5rem 2rem;
@media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 0rem 1rem;
}
`;

export const FormLabel = styled.label`
font-size: clamp(0.5rem, 1.2vw, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
width: 15rem;
margin-top: 1rem;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
  font-size: clamp(0.5rem, 3.5vw, 8rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  font-size: clamp(0.5rem, 2.2vw, 3rem);
}
`;

export const TermsLabel = styled(FormLabel)`
width: fit-content;
margin: 0 auto;
padding: 1.5rem;
letter-spacing: 0.1rem;
@media screen and (max-width: 768px) {
  font-size: clamp(0.5rem, 2.5vw, 7rem);
  padding: 1rem;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  font-size: clamp(0.5rem, 1.3vw, 3rem);
} 
`;

export const FormInput = styled.input`
box-sizing: border-box;
background-color: var(--claybutton-secondary);
color: var(--claytext-primary);
font-family: 'ShortStack';
border: none;
border-radius: 0.5em;
box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
height: 2rem;
width: 100%;
padding: 0.5rem;
margin-top: 0.3rem;
&:focus {
    outline: 2px solid var(--clayblue-primary);
  }
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 4vw, 7rem);  
    margin-top: 0.6rem; 
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  margin-bottom: 0.5rem;
  height: 1.5rem;
  border-radius: 0.5em;
  font-size: clamp(0.5rem, 1.5vw, 7rem);  
}
`;


export const Alert = styled.p`
color: var(--claypink);
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem;
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
line-height: 0.2rem;
@media screen and (max-width: 768px) {
    line-height: 01rem;
    text-wrap: wrap;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  line-height: 0rem;
}
`;


export const H1 = styled.h1`
font-size: clamp(0.5rem, 2.2vw, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 5vw, 4rem);
    padding-bottom: 1rem;
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
    padding: 0;
}     
`;

export const H4= styled(H1)`
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem;
color: var(--claypink);
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3vw, 3rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  font-size: clamp(0.5rem, 2vw, 3rem);
} 
`;
