import { ModalWrapper, ModalDefault, H1, H2, H3, H4, PromptSignUp, PromptLogIn } from "./Modal.styled.jsx";
import { Button, ButtonSecondary } from '../GlobalStyles.jsx';
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ConnectWallet from "./ConnectWallet.jsx";
import LogInForm from "./LogInForm.jsx";
import SignUpForm from "./SignUpForm.jsx";
import SignMessage from "./SignMessage.jsx";    


function Modal(props) {

    const [accessToken, setAccessToken] = useState('')


    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const [isLoggedIn, setIsLoggedIn] = useState(false);


    const {toastMessages, mainAction, setMainAction} = props

    const [modalContent, setModalContent] = useState("default");


    useEffect(() => {

        console.log('\n\n\n')
        console.log('ACCESS TOKEN')
        console.log(accessToken)
        console.log('\n\n\n')

    },[accessToken])

    const handleDefault = () => {
        setModalContent("default");
    }

    const handleLogIn = () => {
        setMainAction("logIn")
        setModalContent("logIn");
    }

    const handleConnectWallet = () => {
        
        setModalContent("connectWallet");
    }

    const handleSignUp = () => {
        setModalContent("signUp");
    }

    const handleSignMessage = () => {
        setModalContent("signMessage");
    }

    const renderModalContent = () => {
        if (modalContent === "default") {
            return (
                <ModalDefault>
                    <H1>Welcome to clay.dash!</H1>
                    <H2>Log in here</H2>
                    <Button theme="blueInactive" type="button" onClick={() => {
                        setMainAction("loginWalletConnect")
                        handleConnectWallet()
                    }}>CONNECT WALLET</Button>
                    <H3>OR</H3>
                    <Button theme="blueInactive" type="button" onClick={()=>{
                        setMainAction('loginClayId')
                        handleLogIn()
                    }} >LOG IN WITH ID</Button>
                    <PromptSignUp>
                        <H4>DON'T HAVE A CLAY ID?</H4>
                        <ButtonSecondary theme="pinkInactive" type="button" onClick={()=>{
                            setMainAction('signUp')
                            handleSignUp()
                        }}>SET UP</ButtonSecondary>
                    </PromptSignUp>
                </ModalDefault>
            );
            } else if (modalContent === "connectWallet") {
                return (
                    <ConnectWallet 

                        handleSignMessage={handleSignMessage} 
                        handleLogIn={handleLogIn} 

                        accessToken={accessToken} setAccessToken={setAccessToken}

                        mainAction={mainAction} setMainAction={setMainAction}

                        username={username} setUsername={setUsername}
                        email={email} setEmail={setEmail}
                        password={password} setPassword={setPassword}
                        confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}

                        toastMessages={toastMessages}

                        isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}

                    />
                );
            } else if (modalContent === "logIn") {
                return (
                    <LogInForm 

                        handleSignUp={handleSignUp} 
                        handleConnectWallet={handleConnectWallet} 
                        
                        accessToken={accessToken} setAccessToken={setAccessToken}

                        mainAction={mainAction} setMainAction={setMainAction}
                        handleDefault={handleDefault}

                        toastMessages={toastMessages}

                        handleSignMessage={handleSignMessage}

                        isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}

                    />
                );
     
            } else if (modalContent === "signUp") {
                return (
                     <SignUpForm 

                        handleConnectWallet={handleConnectWallet} handleLogIn={handleLogIn}
                        
                        accessToken={accessToken} setAccessToken={setAccessToken}

                        mainAction={mainAction} setMainAction={setMainAction}

                        username={username} setUsername={setUsername}
                        email={email} setEmail={setEmail}
                        password={password} setPassword={setPassword}
                        confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}

                        toastMessages={toastMessages}

                        isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}



                     />
                );
            } else if (modalContent === "signMessage") {
                return (
                    <SignMessage 

                        handleDefault={handleDefault} 
                        
                        accessToken={accessToken} setAccessToken={setAccessToken}

                        mainAction={mainAction} setMainAction={setMainAction}

                        toastMessages={toastMessages}

                        username={username} setUsername={setUsername}

                        isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}

                    />
                );
            }
        };

    return (
        <ModalWrapper
        layout
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.7, delay: 2 }}
        variants={{
            visible: { opacity: 1},
            hidden: { opacity: 0 }
        }}>
        {renderModalContent()}
    </ModalWrapper>
    );
}

export default Modal;