import {useState, useEffect, useRef} from 'react'
import { OrbitControls, Sparkles, Stars } from '@react-three/drei'
import { useLoader, useFrame } from '@react-three/fiber'
import { ContactShadows, Float,  PresentationControls, useHelper } from '@react-three/drei'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import IslandMesh from './Meshes/IslandMesh'
import SpawnPlinth from './Meshes/SpawnPlinth'

import StreetLight from './Meshes/StreetLight'
import Rocks from './Meshes/Rocks'

import Mushrooms from './Meshes/Mushrooms'

import * as THREE from 'three'

import {SpotLightHelper, PointLightHelper } from "three"


import Grass from './Meshes/Grass'
//import Grass1 from './Meshes/Grass1'

import Dandelion from './Meshes/Dandelion'
import Lavender from './Meshes/Lavender'
import TallWeed from './Meshes/TallWeed'


import { RectAreaLightHelper }  from 'three/examples/jsm/helpers/RectAreaLightHelper.js'


export default function Experience(props)
{
  const {position, rotation, scale} = props

  const {streetLightRef1, streetLightRef2} = props

  const spotLight1 = useRef()
  const spotLight2 = useRef()

  const spotLightTarget1 = useRef()
  const spotLightTarget2 = useRef()

  const rectAreaLight1 = useRef()
  const rectAreaLight2 = useRef()
  const rectAreaLight3 = useRef()



  // useHelper(spotLight1, SpotLightHelper, "teal")

  // useHelper(spotLight2, SpotLightHelper, "red")


  // useHelper(rectAreaLight1, RectAreaLightHelper, "blue")

  // useHelper(rectAreaLight2, RectAreaLightHelper, "blue")

  // useHelper(rectAreaLight3, RectAreaLightHelper, "blue")


  useFrame(({ clock }) => {
    


  })



  return(
    <>
      
      
      <group position={[0,-0.31,0]}>

        {/*<SpawnPlinth position={[0,0,0]} rotation={[0,0,0]} scale={[1.75,1.75,1.75]}  />*/}

        <IslandMesh scale={[1.075,1.075,1.075]} rotation={[0,0,0]} position={[0,0,0]} />


        <rectAreaLight 
          ref={rectAreaLight1} 
          position={[0,-4,0]}  

          rotation={[Math.PI/2,0,0]}

          intensity={2}

          color={0xffdefe}

          width={12}
          height={4}
        />

        <rectAreaLight 
          ref={rectAreaLight2} 
          position={[-4,1.5,-5]}

          rotation={[0,Math.PI+1,0]}

          intensity={8}

          color={0xffdefe}

          width={1}
          height={1}  
        />

        <rectAreaLight 
          ref={rectAreaLight3} 
          position={[3,3,5]} 

          rotation={[-0.6,0.5,0]}

          intensity={10}

          color={0x00d8ff}

          width={1}
          height={1} 
        />


        <spotLight 
          ref={spotLight1} 
          position={[1,3.6,-1.3]}  
          angle={1}
          target={spotLightTarget1.current}

          distance={100}
          penumbra={1}

          decay={2}
        />


        <spotLight 
          ref={spotLight2} 
          position={[2.6,3.6,-1.1]} 
          angle={1}
          target={spotLightTarget2.current}

          distance={100}
          penumbra={1}

          decay={2}
        />


        <mesh visible={false} ref={spotLightTarget1} position={[1,0,-1.3]}>
          <sphereGeometry args={[1,12,12]} />
          <meshStandardMaterial color="blue" />
        </mesh>


        <mesh visible={false} ref={spotLightTarget2} position={[2.6,0,-1.1]}>
          <sphereGeometry args={[1,12,12]} />
          <meshStandardMaterial color="blue" />
        </mesh>
        

        <group>
          <StreetLight position={[2,0,-1.2]} rotation={[0,1,0]} scale={[0.65,0.65,0.65]} />

         {/* <mesh ref={streetLightRef1} scale={[0.1,0.1,0.1]} position={[1,1.5,-1.36]}>
            <sphereGeometry args={[1,12,12]} />
            <meshStandardMaterial color="orange" />
          </mesh>


          <mesh ref={streetLightRef2} scale={[0.1,0.1,0.1]} position={[2.75,1.65,-1.25]}>
            <sphereGeometry args={[1,12,12]} />
            <meshStandardMaterial color="orange" />
          </mesh>*/}

          <mesh ref={streetLightRef1} scale={[0.1,0.1,0.1]} position={[1.02,1.7,-1.36]}>
            <cylinderGeometry args={[1.5, 8, 40, 128, 2, false]} />
            <shaderMaterial 
              vertexShader = {`
                // Vertex shader
                varying vec3 vNormal;
                varying vec3 vWorldPosition;

                void main() {
                  vNormal = normalize(normalMatrix * normal);
                  vec4 worldPosition = modelMatrix * vec4(position, 1.0);
                  vWorldPosition = worldPosition.xyz;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }`
              }


              fragmentShader = {`
                // Fragment shader
                varying vec3 vNormal;
                varying vec3 vWorldPosition;

                uniform vec3 lightColor;
                uniform vec3 spotPosition;
                uniform float attenuation;
                uniform float anglePower;

                void main() {
                  float intensity;

                  // Distance attenuation
                  intensity = distance(vWorldPosition, spotPosition) / attenuation;
                  intensity = 1.0 - clamp(intensity, 0.0, 1.0);

                  // Intensity based on angle
                  vec3 normal = vec3(vNormal.x, vNormal.y, abs(vNormal.z));
                  float angleIntensity = pow(dot(normal, vec3(0.0, 0.0, 1.0)), anglePower);
                  intensity *= angleIntensity;

                  // Final color
                  gl_FragColor = vec4(lightColor, intensity);
                }
              `}

              uniforms={{
                  attenuation: {
                      type: "f",
                      value: 6.4
                  },
                  anglePower: {
                      type: "f",
                      value: 2.6
                  },
                  spotPosition: {
                      type: "v3",
                      value: new THREE.Vector3(0, 0, 0)
                  },
                  lightColor: {
                      type: "c",
                      value: new THREE.Color('0xfcf2d9')
                  },
              }}

              side={THREE.FrontSide}
              blending={THREE.AdditiveBlending}
              transparent={true}
              depthWrite={false}


            />  
          </mesh>

          <mesh ref={streetLightRef2} scale={[0.1,0.1,0.1]} position={[2.75,1.85,-1.25]}>
            <cylinderGeometry args={[1.5, 8, 38, 128, 2, false]} />
            <shaderMaterial 
              vertexShader = {[
                  'varying vec3 vNormal;',
                  'varying vec3 vWorldPosition;',
                  'void main(){',
                  '// compute intensity',
                  'vNormal        = normalize( normalMatrix * normal );',
                  'vec4 worldPosition = modelMatrix * vec4( position, 1.0 );',
                  'vWorldPosition     = worldPosition.xyz;',
                  '// set gl_Position',
                  'gl_Position    = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
                  '}',
              ].join('\n')}


              fragmentShader = {[
                  'varying vec3       vNormal;',
                  'varying vec3       vWorldPosition;',
                  'uniform vec3       lightColor;',
                  'uniform vec3       spotPosition;',
                  'uniform float      attenuation;',
                  'uniform float      anglePower;',
                  'void main(){',
                  'float intensity;',
                  //////////////////////////////////////////////////////////
                  // distance attenuation                 //
                  //////////////////////////////////////////////////////////
                  'intensity = distance(vWorldPosition, spotPosition)/attenuation;',
                  'intensity = 1.0 - clamp(intensity, 0.0, 1.0);',
                  //////////////////////////////////////////////////////////
                  // intensity on angle                   //
                  //////////////////////////////////////////////////////////
                  'vec3 normal = vec3(vNormal.x, vNormal.y, abs(vNormal.z));',
                  'float angleIntensity = pow( dot(normal, vec3(0.0, 0.0, 1.0)), anglePower );',
                  'intensity = intensity * angleIntensity;',
                  // 'gl_FragColor    = vec4( lightColor, intensity );',
                  //////////////////////////////////////////////////////////
                  // final color                      //
                  //////////////////////////////////////////////////////////
                  // set the final color
                  'gl_FragColor = vec4( lightColor, intensity);',
                  '}',
              ].join('\n')}

              uniforms={{
                  attenuation: {
                      type: "f",
                      value: 6.4
                  },
                  anglePower: {
                      type: "f",
                      value: 2.6
                  },
                  spotPosition: {
                      type: "v3",
                      value: new THREE.Vector3(0, 0, 0)
                  },
                  lightColor: {
                      type: "c",
                      value: new THREE.Color('0xfcf2d9')
                  },
              }}

              side={THREE.FrontSide}
              blending={THREE.AdditiveBlending}
              transparent={true}
              depthWrite={false}


            />  
          </mesh>

        </group>

        <Rocks position={[0,0,0]} rotation={[0,0,0]} scale={[1,1,1]} />
        <Mushrooms position={[0,0,0]} rotation={[0,0,0]} scale={[1,1,1]} />


        <Grass />

        <Dandelion />
        <Lavender />
        <TallWeed />


      </group>

    </>
  )
}