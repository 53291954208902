import styled from "styled-components";
import {Typography, Card, Grid, Tooltip} from '@mui/material';

export const MainPannel = styled.div`
background: var(--background-pannel);
padding: 2em 2.5em;
border-radius: 1em;
backdrop-filter: blur(22px);
contain: content;
max-width: 60vw;
max-height: 80vh;
overflow-x: auto;
    @media screen and (max-width: 768px) {
        background: var(--background-progress);
        margin-top: 5em;
        overflow-y: auto;
        max-height: 70vh;
        padding: 2em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        max-height: 50vh;
        overflow-y: hidden;
        
    }

`;

export const ClayCard = styled(Card)`
outline: ${props => (props.selected ? '0.5rem solid var(--clayblue-primary)' : '0.5rem solid rgba(0, 0, 0, 0.28)')}; 
border: none;
contain: content;
display: flex;
flex-wrap: wrap;
max-height: 80%;

background-color: ${props => (props.selected ? 'red' : 'none')};

&:hover {
    outline: 0.5rem solid var(--clayblue-primary);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(5, 18, 37, 0.50) 100%);
}
@media screen and (max-width: 768px) {
    max-height: 80%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(5, 18, 37, 0.50) 100%);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    max-height: 60%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(5, 18, 37, 0.50) 100%);
}
`;


export const TypographyID = styled(Typography)`
color: var(--claytext-white);
background: rgba(0, 0, 0, 0.2);
text-align: center;
border-radius: 0.5rem;
padding: 0 0.5em;
&& {
font-family: ShortStack;  
font-size: 24px;

`;


export const H1= styled.h1`
font-size: clamp(1em, 1vw, 3em);
font-family: ShortStack;  
color: var(--claytext-white);
     text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 1024px) {
        font-size: clamp(0.5em, 1.2vw, 3em);
    }
@media screen and (max-width: 768px) {
    font-size: clamp(1em, 4vw, 7em);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5em, 1.2vw, 3em);
   
}

`;

export const GridContainer = styled(Grid)`
&& {
flex-wrap: nowrap;
padding: 1em;
@media screen and (max-width: 600px) {
    flex-wrap: wrap;
}
@media screen and (max-width: 600px) and (orientation: landscape){
    flex-wrap: wrap;

}
}
`;


export const Pin = styled.img`
    width: 25%;
    height: auto;
    position: absolute;
    right: 0;
    top: 0;
    padding-left: 1em;
    `;


    export const Token = styled.img`
    width: 20%;
    height: auto;
    line-height: 0;
    align-self: center;
    `;


    export const StyledTooltip = styled(props => (
        <Tooltip classes={{ popper: props.className }} {...props} />
      ))`
        & .MuiTooltip-tooltip {
          background-color: var(--clayblue-secondary);
        }
      `;