import { useState, useEffect, useRef, useContext } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'
import { socket } from '../socket';


import { MainPlayerContext } from 'components/Context/PlayerContext'

import {MainExperienceContext} from 'components/Context/ExperienceContext'

import {MainChatContext} from 'components/Context/ChatContext'

import {MainMultiplayerContext} from 'components/Context/MultiplayerContext'

import DisplayMessage from './DisplayMessage'

import DisplayNickname from './DisplayNickname'

import PositionalSoundLoader from 'components/Virtual/Sound/PositionalSoundLoader'

import SoundLoader from 'components/Virtual/Sound/SoundLoader'

import MovementParticles from './MovementParticles'


function PlayerSounds(props){

  const {isLoaded, setIsLoaded} = props
  const playerAction = props.playerAction 
  const setPlayerAction = props.setPlayerAction
  const currentAction = props.currentAction
  const meta = props.meta
  const characterPosition = props.characterPosition
  const {voiceType} = useContext(MainPlayerContext)
  const {audioListener} = useContext(MainExperienceContext)
  const [cartSoundsReady, setCartSoundsReady] = useState(false)
  const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/audio/'

  const [tryOnTrousersSounds] = useState({

    "try_on_trousers_button": {url: root_url + "Interior/06_Trying on trousers (with button).wav", volume: 1},
    "try_on_trousers_clasp": {url: root_url + "Interior/06_Trying on trousers (with clasp).wav", volume: 1},
    "try_on_trousers_zip": {url: root_url + "Interior/06_Trying on trousers (with zip).wav", volume: 1}

  })

  const [loadedTryOnTrousersSounds, setLoadedTryOnTrousersSounds] = useState({})

  const [cartSounds] = useState({
    "add_to_cart": {url: root_url + "Interior/08_Add to Cart Sound Effect_V3.wav", volume: 0.5},
    "remove_from_cart": {url: root_url + "Interior/06_Remove From Cart.wav", volume: 0.5}
  })


  const [loadedCartSounds, setLoadedCartSounds] = useState({})


  const [hmmSounds] = useState({
    "type1":{
      "type1&Hmm_1": {url: root_url + "Interior/TheStand_Sounds/Interior/User/01_Hmm 1.wav", sound: null, volume: 3},
      "type1&Hmm_2": {url: root_url + "Interior/TheStand_Sounds/Interior/User/01_Hmm 2.wav", sound: null, volume: 3},
      "type1&Hmm_3": {url: root_url + "Interior/TheStand_Sounds/Interior/User/01_Hmm 3.wav", sound: null, volume: 3},
    },

    "type2": {
      "type2&Hmm_1": {url: root_url + "Interior/TheStand_Sounds/Interior/User/01_Hmm 4.wav", sound: null, volume: 3},
      "type2&Hmm_2": {url: root_url + "Interior/TheStand_Sounds/Interior/User/01_Hmm 5.wav", sound: null, volume: 3},
      "type2&Hmm_3": {url: root_url + "Interior/TheStand_Sounds/Interior/User/01_Hmm 6.wav", sound: null, volume: 3}
    }
    

  })

  const [hmmLoadedSounds, setHmmLoadedSounds] = useState({})


  const [playerStateSounds] = useState({
    "Walk": {url: root_url + "Interior/02_Footsteps 3.wav", volume: 2, loop: true},
    "Run": {url: root_url + "Interior/02_Footsteps 2.wav", volume: 2, loop: true},
    "Landed": {url: root_url + "Jump-1.m4a", volume: 10}
  })
  
  const [loadedSounds, setLoadedSounds] = useState({})




  const positionSoundObject = useRef()


  const [currentSound, setCurrentSound] = useState()

  const [currentStateSound, setCurrentStateSound] = useState()

  const [currentVoiceSound, setCurrentVoiceSound] = useState()

  const [hmmSoundReady, setHmmSoundReady] = useState(true)

  const [justLanded, setJustLanded] = useState(false)

  const [isFalling, setIsFalling] = useState(false)

  

  useEffect(() => {

    if (currentAction == 'Fall'){
      // console.log('\n\n\nFALLING\n\n\n')
      
      if (!isFalling){
        setIsFalling(true)

        //Just jumped
      }
      
    }else{
      if (isFalling){
        // console.log('\n\n\nLanded\n\n\n')
        setIsFalling(false)

        //Play landing sound here
        try{
          currentStateSound.stop()
        }catch{

        }

        if (loadedSounds.hasOwnProperty('Landed')){

          /*
          try{
            currentSound.stop()
          }catch{

          }
          */

          let sound = loadedSounds['Landed']

          if (!sound.isPlaying){
            sound.offset = 0.7
            //sound.play()
            positionSoundObject.current.add(sound)
          }

         

          //setCurrentStateSound(sound)
        }

      }
    }

  },[currentAction])



  useEffect(() => {

    if (playerAction === 'try_on_trousers' && isLoaded && hmmSoundReady){
      setHmmSoundReady(false)
      
      // console.log('\n\n\nLOADED TROUSERS !!!\n\n\n')
      // console.log(hmmLoadedSounds)  

      let hmmSoundsList = Object.keys(hmmLoadedSounds)
      let soundIndex =  Math.floor(Math.random() * hmmSoundsList.length)
      let cur_sound = hmmLoadedSounds[hmmSoundsList[soundIndex]]
      

      setTimeout(()=>{
        if (Math.random() > 0.2){
          cur_sound.play()
        }
        
        setTimeout(()=>{
          setHmmSoundReady(true)
        }, 10000)

      }, 1850)

      // console.log('CURRENT SOUND')
      // console.log(cur_sound)
    }


    //setPlayerAction('')
  },[playerAction, isLoaded])



  useEffect(() => {

    

    if (playerAction === 'try_on_trousers' && !isLoaded){

      try{
          currentSound.stop()
      }catch{

      }


      let tryOnTrousersSoundsList = Object.keys(loadedTryOnTrousersSounds)
      let soundIndex =  Math.floor(Math.random() * tryOnTrousersSoundsList.length)
      let cur_sound = loadedTryOnTrousersSounds[tryOnTrousersSoundsList[soundIndex]]


      if (cur_sound.isPlaying){
        cur_sound.stop()  
        cur_sound.play()
      }else{
        cur_sound.play()
      }

      positionSoundObject.current.add(cur_sound)

      setCurrentSound(cur_sound)

    }

  },[playerAction, isLoaded])



  useEffect(() => {

    //console.log(playerAction)



    if (playerAction === 'add_to_cart'){

      

      try{
        loadedCartSounds['add_to_cart'].stop()
        loadedCartSounds['remove_from_cart'].stop()
      }catch{

      }

      loadedCartSounds['add_to_cart'].play()

    }

    if (playerAction === 'remove_from_cart'){
      
      try{
        loadedCartSounds['remove_from_cart'].stop()
        loadedCartSounds['add_to_cart'].stop()
      }catch{
        
      }

      loadedCartSounds['remove_from_cart'].play()

    }

    //setCartSoundsReady(true)

    setPlayerAction('')

  },[playerAction])




useEffect(() => {

  // console.log('CURRENT ACTION')
  // console.log(loadedSounds)

  try{
    currentStateSound.stop()
  }catch{

  }

  if (loadedSounds.hasOwnProperty(currentAction)){
    let sound = loadedSounds[currentAction]
    
    if (!sound.isPlaying){

      sound.play()

    }
    //sound.play()
    positionSoundObject.current.add(sound)

    setCurrentStateSound(sound)
  }

  //console.log(positionSoundObject.current.children)

},[currentAction])




  return(
    <>


    <PositionalSoundLoader 

      key={'playerStateSounds'}

      audioListener={audioListener}
      soundsToLoad={playerStateSounds}
      loadedSounds={loadedSounds}
      setLoadedSounds={setLoadedSounds}
    />


    

    <PositionalSoundLoader 

      key={'tryOnTrousersSounds'}

      audioListener={audioListener}
      soundsToLoad={tryOnTrousersSounds}
      loadedSounds={loadedTryOnTrousersSounds}
      setLoadedSounds={setLoadedTryOnTrousersSounds}
    />


    <SoundLoader 
      key={'hmmSounds'}
      audioListener={audioListener}
      soundsToLoad={hmmSounds[voiceType]}
      loadedSounds={hmmLoadedSounds}
      setLoadedSounds={setHmmLoadedSounds}
    />


     <SoundLoader 
      key={'cartSounds'}
      audioListener={audioListener}
      soundsToLoad={cartSounds}
      loadedSounds={loadedCartSounds}
      setLoadedSounds={setLoadedCartSounds}
    />


    <mesh visible={false} ref={positionSoundObject} position={[0,0.25,0]} > 
      <sphereGeometry args={[0.1,12,12]} />
      <meshStandardMaterial color={'blue'}  />
    </mesh>


    </>

  )
}


function LocalPlayer(props) {

  const {characterRef, offsetY} = props

  const {nickname, messageContent, setMessageContent, playerAction, setPlayerAction, playerPosition, setPlayerPosition} = useContext(MainPlayerContext)

  const {audioListener} = useContext(MainExperienceContext)

  const {chatMessages, setChatMessages} = useContext(MainChatContext)

  const {userSocketId, setUserSocketId, setRemotePlayersDict} = useContext(MainMultiplayerContext)

  const {isLoaded, setIsLoaded} = props

  const meta = props.meta

  const characterPosition = props.characterPosition

  const characterRotation = props.characterRotation

  const characterDirection = props.characterDirection

  const currentAction = props.currentAction

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [fooEvents, setFooEvents] = useState([]);

  const [socketid, setSocketId] = useState('')

  const nicknameDisplay = useRef()

  const messageDisplay = useRef()

  //const [messageContent, setMessageContent] = useState('Wow!\nNice outfit!\nWhich pair\nare you buying...')

  //const [nickname, setNickname] = useState('shcheb')

  const {camera} = useThree()

  const localRef = useRef()


  const [adjustedPosition, setAdjustedPosition] = useState([0,0,0])


  useEffect(() => {

    setAdjustedPosition([characterPosition[0], characterPosition[1] + offsetY, characterPosition[2]])

  },[characterPosition])

  /*
  console.log('SOCKET ID')
  console.log(userSocketId)
  */


 

  //console.log('local')

  useEffect(() => {

      //console.log('LOCAL PLAYER')

      //console.log(characterPosition)
      //console.log(currentAction)


      function onConnect(socket) {
        //console.log('On connect')
        //console.log(socket)
        //setUserSocketId(socket)
        setIsConnected(true);
      }

      function onDisconnect() {
        setIsConnected(false);
      }

      function onFooEvent(value) {
        setFooEvents(previous => [...previous, value]);
      }

      function onRemotePlayer(player){

        //console.log(player)

        //console.log(player.position)

      }




      function onInit(gameState){

        let playersState = gameState['players']

        setRemotePlayersDict(playersState)

        // console.log('SETTING INIT PLAYER STATE')
        //console.log(player)

        //console.log(player.position)

      }


      
    
      /*)
      function onChatMessage(value){
        console.log(value)

        //updateMessages(value)
        
      }
      */

      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('foo', onFooEvent);

      socket.on('init', onInit)

      
      socket.on('remote player', onRemotePlayer)

      //socket.on('chat message', onChatMessage)

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('foo', onFooEvent);

        socket.off('init', onInit)

       

        socket.off('remote player', onRemotePlayer)

        //socket.off('chat message', onChatMessage);
      };
    }, []);



useEffect(() => {

  //console.log(characterPosition, currentAction)

  //console.log('emit')

 

  socket.emit('player update', {nickname: nickname, meta: meta, position: adjustedPosition, rotation: characterRotation, action: currentAction, playerAction: playerAction})


},[adjustedPosition, currentAction, playerAction, characterRotation])


useEffect(() => {



  //function onInit() {
      
      // console.log('\n\n\nINIT INIT INIT\n\n\n')

      socket.emit('init',
      {
        nickname: nickname,
        meta: meta, 
        position: adjustedPosition, 
        rotation: characterRotation, 
        action: currentAction,
        playerAction: playerAction

      })
  //}


  /*
  console.log('META AND NICKNAME')
  socket.on('init', onInit);


   return () => {

    socket.off('init', onInit);

   }
   */

},[meta, nickname])




useEffect(() => {
  
  if (messageContent !== '' ){
    socket.emit('chat message', {msg: messageContent})
  }

},[messageContent])




useEffect(() => {

  if (socket.id !== undefined){
    // console.log('\n\n\nSOCKET\n\n\n')
    // console.log(socket)
    setUserSocketId(socket.id)
  }
  
},[socket.id])




useEffect(() => {
  
  setPlayerPosition([adjustedPosition[0], adjustedPosition[1], adjustedPosition[2]])
  
},[adjustedPosition])



// useFrame(() => {



//   try{
   

    
   
//     nicknameDisplay.current.position.x = characterPosition[0] - characterDirection.x * 0.2
//     nicknameDisplay.current.position.y = characterPosition[1] + 2
//     nicknameDisplay.current.position.z = characterPosition[2] - characterDirection.z * 0.2

//     nicknameDisplay.current.lookAt(camera.position);

  
    
//     messageDisplay.current.position.x = characterPosition[0] - characterDirection.x * 0.2 
//     messageDisplay.current.position.y = characterPosition[1] + 2.4
//     messageDisplay.current.position.z = characterPosition[2] - characterDirection.z * 0.2 
    
//     messageDisplay.current.lookAt(camera.position);


//   }catch{

//   }

// },[])



// useFrame(() => {

//   if (typeof(localRef) !== 'undefined' && typeof(localRef.current) !== 'undefined'){
//     localRef.current.position.x = characterRef.current.position.x 
//     localRef.current.position.y = characterRef.current.position.y
//     localRef.current.position.z = characterRef.current.position.z
//   }
  

// })




return(
 
  <>



  <group position={[0,0,0]} ref={localRef}>

     <DisplayNickname characterDirection={characterDirection} characterRef={characterRef} nicknameDisplay={nicknameDisplay} nickname={nickname} />

     {messageContent !== '' ?
        <DisplayMessage characterDirection={characterDirection} characterRef={characterRef} messageDisplay={messageDisplay} messageContent={messageContent} setMessageContent={setMessageContent} />
     : null}

   </group>


   {/*<MovementParticles characterRef={characterRef} characterPosition={characterPosition} currentAction={currentAction} />*/}


   <PlayerSounds 
      isLoaded={isLoaded}
      setIsLoaded={setIsLoaded}
      characterPosition={characterPosition} 
      playerAction={playerAction} 
      setPlayerAction={setPlayerAction} 
      currentAction={currentAction} 
   />



  </>
  
  )
}

export default LocalPlayer;
