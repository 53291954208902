import styled from "styled-components";
import { Tooltip } from '@mui/material';


export const BannerWrapper = styled.div`
border-radius: 1.5rem;
background: rgba(26, 47, 73, 0.56);
box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.25) inset;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
box-sizing: border-box;
margin: 0 auto;
width: 100%;
height: 25%;
overflow: hidden;
&:hover {
    border: 7px solid  var(--clayblue-primary);
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    border-radius: 0rem 0rem 4rem 4rem;
    height: 22%;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    align-items: center;
}
`;

export const BannerImg = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
opacity: 0.5;
cursor: pointer;

@media screen and (max-width: 768px) {
    object-fit: cover;
    width: 100%;
}
`;


export const BannerSelectionWrapper = styled.div`
background: var(--background);
border-radius: 2rem;
width: 100%;
height: 100%;
`;

export const Logo = styled.img`
width: 10%;
height: auto;
position: absolute;
top: 7%;
left: 50%;
transform: translate(-50%, 0);
z-index: 1;

@media screen and (max-width: 768px) {
    width: 30%;
    top: 5%;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 10%;
}
`;


export const StyledTooltip = styled(props => (
    <Tooltip classes={{ popper: props.className }} {...props} />
  ))`
    & .MuiTooltip-tooltip {
      background-color: var(--clayblue-primary);
      font-family: 'ClayFont';
      font-size: clamp(0.5rem, 1vw, 3rem);
      font-weight: 400;
      padding: 0.3rem 0.6rem;
      border-radius: 0.5rem;
    }
  `;
