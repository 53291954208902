import { extend, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import React from "react";
import {useEffect, useRef} from "react";
import {MOUSE} from 'three';
import {TOUCH} from 'three';
import { useFrame } from '@react-three/fiber';

extend({ OrbitControls });

const Controls = (props) => {

  const cameraPositionZ = props.cameraPositionZ
  const setCameraPositionZ = props.setCameraPositionZ

  const { camera, gl } = useThree();

  const controlsRef = useRef();
  const deltaDistance = props.deltaDistance
  const zoomClicked = props.zoomClicked
  const setZoomClicked = props.setZoomClicked

  useFrame((state) => {
    controlsRef.current.update()

    //console.log(state)
  })

  useEffect(() => {


    controlsRef.current.addEventListener('change', function(){

      //console.log(this)

      

      let CONTROLS_LIMIT = 30
      if (this.target.y < -CONTROLS_LIMIT+CONTROLS_LIMIT/2){
        this.target.y = - CONTROLS_LIMIT+CONTROLS_LIMIT/2;
        camera.position.y = -CONTROLS_LIMIT+CONTROLS_LIMIT/2;
      }
      else if (this.target.y > CONTROLS_LIMIT){
        this.target.y = CONTROLS_LIMIT;
        camera.position.y = CONTROLS_LIMIT;
      }

      if (this.target.x < -CONTROLS_LIMIT+20){
        this.target.x = -CONTROLS_LIMIT+20;
        camera.position.x = -CONTROLS_LIMIT+20;
      }else if (this.target.x > CONTROLS_LIMIT){
        this.target.x = CONTROLS_LIMIT;
        camera.position.x = CONTROLS_LIMIT;
      }

      setCameraPositionZ(camera.position.z)
      
      //console.log(camera.position.z)
      //console.log(controlsRef)
      //console.log(this.dollyIn)

    })
  }, [])


  
  useEffect(() => {

    if (zoomClicked){
      camera.position.z = cameraPositionZ
      //console.log(cameraPositionZ)
      setZoomClicked(false)
    }

  }, [cameraPositionZ, zoomClicked])
  


  return (
    <orbitControls
      ref={controlsRef}
      target = {[10,8,0]}
      mouseButtons = {{LEFT: MOUSE.PAN, RIGHT: MOUSE.PAN, MIDDLE: MOUSE.DOLLY}}
      touches = {{ONE: TOUCH.PAN, TWO: TOUCH.DOLLY_PAN}}
      enableRotate = {false}
      minDistance = {0.8}
      maxDistance = {30}
      args={[camera, gl.domElement]}
    />
  );
};

export default Controls;
