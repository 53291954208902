import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Cloud, Sphere, Box, useProgress, MeshReflectorMaterial } from '@react-three/drei'

import * as THREE from "three";

import {vertexShader, fragmentShader} from './shaders/modifiedShader'

import {
  BackSide,
  BoxGeometry,
  Mesh,
  ShaderMaterial,
  UniformsUtils,
  Vector3
} from 'three';

/**
 * Based on "A Practical Analytic Model for Daylight"
 * aka The Preetham Model, the de facto standard analytic skydome model
 * https://www.researchgate.net/publication/220720443_A_Practical_Analytic_Model_for_Daylight
 *
 * First implemented by Simon Wallner
 * http://simonwallner.at/project/atmospheric-scattering/
 *
 * Improved by Martin Upitis
 * http://blenderartists.org/forum/showthread.php?245954-preethams-sky-impementation-HDR
 *
 * Three.js integration by zz85 http://twitter.com/blurspline
*/

class Sky extends Mesh {

  constructor() {

    const shader = Sky.SkyShader;

    const material = new ShaderMaterial( {
      name: 'SkyShader',
      fragmentShader: shader.fragmentShader,
      vertexShader: shader.vertexShader,
      uniforms: UniformsUtils.clone( shader.uniforms ),
      side: BackSide,
      depthWrite: false
    } );

    super( new BoxGeometry( 1, 1, 1 ), material );

    this.isSky = true;

  }

}

Sky.SkyShader = {

  uniforms: {
    'turbidity': { value: 2 },
    'rayleigh': { value: 1 },
    'mieCoefficient': { value: 0.005 },
    'mieDirectionalG': { value: 0.8 },
    'sunPosition': { value: new Vector3() },
    'up': { value: new Vector3( 0, 1, 0 ) },
    'sunSize': {value: 1},
    'skyTop': {value: new Vector3(0.78, 0.66, 0.9)},
    'skyBottom': {value: new Vector3(1.0, 0.6, 0.6)}
  },

  vertexShader: vertexShader,

  fragmentShader: fragmentShader
    

};

export { Sky };
