import styled, { keyframes, css } from "styled-components";
import { useState, useEffect} from "react";


const generateRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
};

const generateRandomOpacity = () => {
    return generateRandomNumber(0.1, 1);
}

const generateRandomSize = () => {
    return generateRandomNumber(1, 30);
}

const SnowAnimation = keyframes`
    0% {
        transform: translateY(-10%);
        webkit-transform: translateY(-10%);
    }
    100% {
        transform: translateY(5000%);
        webkit-transform: translateY(5000%);
    }
`;

const SnowOffsetAnimation = keyframes`
    0%, 100% {
        transform: translateX(0);
        webkit-transform: translateX(0);
    }
    50% {
        transform: translateX(20px);
        webkit-transform: translateX(20px);
    }
`;


const SnowFlake = styled.div`
    position: relative;
    top: -5px;
    background-color: transparent;
    width: ${({ size }) => size}px; 
    height: ${({ size }) => size}px;
    opacity: ${({ opacity }) => opacity};
    left: ${({ $left }) => $left}%;
    pointer-events: none;
    z-index: 1000;
    cursor: default;
    animation: ${SnowAnimation} ${({ speed }) => speed}s linear infinite;
    &::before {
        content: "❅";
        font-size: ${({ size }) => size}px;
        color: ivory;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0 0 5px rgba(255,255,240, 0.5);
        animation: ${SnowOffsetAnimation} 10s ease-in-out infinite alternate;
    }
`;

const Snow = () => {
    const [snowflakes, setSnowflakes] = useState([]);
    console.log(snowflakes);

    useEffect(() => {
        const snowInterval = setInterval(() => {
            setSnowflakes((snowflakes) => [
                ...snowflakes,
                {
                    $left: generateRandomNumber(0, 100),
                    size: generateRandomSize(),
                    opacity: generateRandomOpacity(),
                    speed: generateRandomNumber(50, 100),
                },
            ]);
        }, 800);

        return () => {
            clearInterval(snowInterval);
        };
    }, []);

    return (
        <>
            <div style={{width:'100%', height:'150%', position:'absolute', top:'-50%', overflow:'hidden', pointerEvents: 'none'}}>
                {snowflakes.map((snowflake, index) => (
                    <SnowFlake key={index} {...snowflake} />
                ))}
            </div>
        </>
    );
};

export default Snow;