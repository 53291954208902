
import styled from "styled-components";
import background from '../Images/background-5.png';
import cursorvoxelised from '../Images/cursor32.png';


export const UnlockXPWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100vh;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
    @media screen and (max-width: 768px) {
      background-attachment: scroll;
      background-size: fit;
      background-repeat: repeat;
      background-position: center;
    }
  `;

  export const Button = styled.button`
  background: var(--clayblue-secondary);
  font-family: ButtonFont;
  border: none;
  padding: 1rem 2rem;
  font-size: clamp(0.5rem, 4vw, 3rem);
  text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
  letter-spacing: 0.2rem;
  text-align: center;
  color: white;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  &:hover {
      background: var(--claypurple-primary);
  }
  `;
  
  export const SkipButton = styled(Button)`
  position: fixed;
  right: 2%;
  bottom: 2%;  

  `;

  export const TransactionButton = styled(Button)`
  position: fixed;
  left: 2%;
  bottom: 2%;  
  font-size: clamp(0.5rem, 4vw, 3rem);
  font-weight: 500;
  font-family: PixelFont;
  color: var(--claygreen-primary);
  background: var(--clayblue-secondary);
  text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
  cursor: url(${cursorvoxelised}), auto;
  @media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 8vw, 7rem);
    background: var(--clayblue-secondary);
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    color: var(--claygreen-primary);
    font-size: clamp(0.5rem, 4vw, 7rem);
    background: var(--clayblue-secondary);
  }
  &:hover {
    background: var(--clayblue-primary);
    outline: 2px solid var(--claygreen-primary) ;
}
  `;

  export const Logo = styled.img`
  width: 17%;
  height: auto;
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, 0);
  @media screen and (max-width: 1024px) {
    width: 25%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 35%;
    height: auto;
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    width: 17%;
    height: auto;
  }
  `;

