import CircularProgress from '@mui/material/CircularProgress';

function CustomCircularProgress() {
  

  return (
     <>

      <CircularProgress
        thickness={4}
        style={{zIndex:'4', color: "#53c8ee"}}
      />

    </>  
  );
}

export default CustomCircularProgress;
