import {useState, useEffect} from 'react'
import { OrbitControls, Sparkles, Stars } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { ContactShadows, Float,  PresentationControls } from '@react-three/drei'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


export default function Experience(props)
{

    const {position, rotation, scale} = props


      const gltf = useLoader(GLTFLoader, 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/AvatarSelectionScene/Mushrooms.glb')
    
      const [scene, setScene] = useState()


      useEffect(() => {

    let model = gltf.scene

    model.traverse(function (object){
      
      if (object.isMesh){
        object.receiveShadow = true
        object.castShadow = true
      }

    })

    //setLoaded(true)
    setScene(model)

},[gltf])

    return(
    <>
        {scene ?
        
          <primitive
            object={gltf.scene}

            position={position}
            rotation={rotation}
            scale={scale}
          
          />
      
       :null}
   </>
    )
}