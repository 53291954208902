import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box, useProgress } from '@react-three/drei'

import { RigidBody, CapsuleCollider, BallCollider, useRapier, interactionGroups } from '@react-three/rapier'

import {MainWorldContext} from 'components/Context/WorldContext'

import {MainMultiplayerContext}  from 'components/Context/MultiplayerContext'


import * as THREE from 'three'


import SceneParser from './SceneParser'


function WorldUnit(props){

  const {position, characterPosition} = props

  return(
    <>

    {/*  <RigidBody position={position} type={'fixed'}>

        <group position={[0,0.01,0]}>
          <mesh>
            <boxGeometry args={[13,0.2,13]} />
            <meshStandardMaterial color={'green'} />
          </mesh>
        </group>

      </RigidBody>*/}

    </>
  )
}



function WorldMediumUnit(props){

  const {currentUnitQuadrant, setCurrentUnitQuadrant, activeUnitQuadrants, setActiveUnitQuadrants} = useContext(MainWorldContext)


  const {position, characterPosition, activeQuads, setActiveQuads} = props


  const [worldUnits, setWorldUnits] = useState({})

  useEffect(() => {

    let x_upper = position[0] + 8
    let x_lower = position[0] - 8

    let y_upper = position[2] + 8
    let y_lower = position[2] - 8

    let x_upper_y_upper = '(' + x_upper + ',' + y_upper + ')'
    let x_upper_y_lower = '(' + x_upper + ',' + y_lower + ')'

    let x_lower_y_upper = '(' + x_lower + ',' + y_upper + ')'
    let x_lower_y_lower = '(' + x_lower + ',' + y_lower + ')'



    let new_largeWorldUnits = {}


    let characterPosition2D = new THREE.Vector2(characterPosition[0], characterPosition[2])

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_upper, y_upper)) < 32){
      new_largeWorldUnits[x_upper_y_upper] = {position: [x_upper, 0 ,y_upper]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_upper, y_lower)) < 32){
      new_largeWorldUnits[x_upper_y_lower] = {position: [x_upper, 0 ,y_lower]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_lower, y_upper)) < 32){
      new_largeWorldUnits[x_lower_y_upper] = {position: [x_lower, 0 ,y_upper]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_lower, y_lower)) < 32){
      new_largeWorldUnits[x_lower_y_lower] = {position: [x_lower, 0 ,y_lower]}
    }


    let temp_worldUnits = {...worldUnits, ...new_largeWorldUnits}

    
    for (let key in temp_worldUnits){

      let cur_unitPosition = temp_worldUnits[key].position

      let quadId = key + '_Small'

      let distanceToQuad = characterPosition2D.distanceTo(new THREE.Vector2(cur_unitPosition[0], cur_unitPosition[2]))

      
      

       if (distanceToQuad > 32){

        delete temp_worldUnits[key]
        
        setActiveQuads(prev => {
          return prev.filter(id => id !== quadId);
         });


        let temp_activeUnitQuadrants = activeUnitQuadrants.filter(id => id !== quadId);

        setActiveUnitQuadrants(temp_activeUnitQuadrants);




      }else{

        if (!activeQuads.includes(quadId)){
          setActiveQuads(prev => [...prev, quadId])

        }

        if (!activeUnitQuadrants.includes(quadId)){
           setActiveUnitQuadrants(
              [...activeUnitQuadrants, quadId]
            )
        }
        
      }

    }


    setWorldUnits({...temp_worldUnits})


  },[characterPosition])




  return(
    <>

      <group>

       <group position={[0,0.01,0]}>
      {
        Object.keys(worldUnits).map((key) => {

          return(
            <WorldUnit key={key} position={worldUnits[key].position} characterPosition={characterPosition} />
          )

        })
      }

      </group>


      </group>

    </>
  )
}






//DO NOT CONFUSE WITH LARGE PITCH
//WORLD LARGE UNIT = 2x2 Medium pitches
function WorldLargeUnit(props){


  const {position, characterPosition, activeQuads, setActiveQuads} = props


  const [mediumWorldUnits, setMediumWorldUnits] = useState({})



  useEffect(() => {

    let x_upper = position[0] + 16
    let x_lower = position[0] - 16

    let y_upper = position[2] + 16
    let y_lower = position[2] - 16

    let x_upper_y_upper = '(' + x_upper + ',' + y_upper + ')'
    let x_upper_y_lower = '(' + x_upper + ',' + y_lower + ')'

    let x_lower_y_upper = '(' + x_lower + ',' + y_upper + ')'
    let x_lower_y_lower = '(' + x_lower + ',' + y_lower + ')'



    let new_largeWorldUnits = {}


    let characterPosition2D = new THREE.Vector2(characterPosition[0], characterPosition[2])

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_upper, y_upper)) < 64){
      new_largeWorldUnits[x_upper_y_upper] = {position: [x_upper, 0 ,y_upper]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_upper, y_lower)) < 64){
      new_largeWorldUnits[x_upper_y_lower] = {position: [x_upper, 0 ,y_lower]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_lower, y_upper)) < 64){
      new_largeWorldUnits[x_lower_y_upper] = {position: [x_lower, 0 ,y_upper]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_lower, y_lower)) < 64){
      new_largeWorldUnits[x_lower_y_lower] = {position: [x_lower, 0 ,y_lower]}
    }


    let temp_worldUnits = {...mediumWorldUnits, ...new_largeWorldUnits}

    let quad_keys = []

    

    for (let key in temp_worldUnits){

      let quadId = key + '_Medium'
      let cur_unitPosition = temp_worldUnits[key].position

       if (characterPosition2D.distanceTo(new THREE.Vector2(cur_unitPosition[0], cur_unitPosition[2])) > 64 + 16){

        delete temp_worldUnits[key]
        
        setActiveQuads(prev => {
          return prev.filter(id => id !== quadId);
         });

      }else{

        if (!activeQuads.includes(quadId)){
          setActiveQuads(prev => [...prev, quadId])
        }
        
      }

    }


    setMediumWorldUnits({...temp_worldUnits})

  },[characterPosition])

 

  return(

    <>  
    <group>
    
     {/*<RigidBody position={position} type={'fixed'}>
        <mesh visible={true}>
          <boxGeometry args={[63,0.2,63]} />
          <meshStandardMaterial color={'blue'} />
        </mesh>
      </RigidBody>*/}


       <group position={[0,0.01,0]}>
      {
        Object.keys(mediumWorldUnits).map((key) => {

          return(
            <WorldMediumUnit 
              key={key} 
              position={mediumWorldUnits[key].position} 
              characterPosition={characterPosition} 

              activeQuads={activeQuads}
              setActiveQuads={setActiveQuads}
            />
          )

        })
      }

      </group>

      </group>

    </>

  )

}




function WorldExtraLargeUnit(props){


  const {position, characterPosition, activeQuads, setActiveQuads} = props

  const [largeWorldUnits, setLargeWorldUnits] = useState({})


  useEffect(() => {

    let x_upper = position[0] + 32
    let x_lower = position[0] - 32

    let y_upper = position[2] + 32
    let y_lower = position[2] - 32

    let x_upper_y_upper = '(' + x_upper + ',' + y_upper + ')'
    let x_upper_y_lower = '(' + x_upper + ',' + y_lower + ')'

    let x_lower_y_upper = '(' + x_lower + ',' + y_upper + ')'
    let x_lower_y_lower = '(' + x_lower + ',' + y_lower + ')'


    let new_largeWorldUnits = {}

    let characterPosition2D = new THREE.Vector2(characterPosition[0], characterPosition[2])

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_upper, y_upper)) < 128){
      new_largeWorldUnits[x_upper_y_upper] = {position: [x_upper, 0 ,y_upper]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_upper, y_lower)) < 128){
      new_largeWorldUnits[x_upper_y_lower] = {position: [x_upper, 0 ,y_lower]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_lower, y_upper)) < 128){
      new_largeWorldUnits[x_lower_y_upper] = {position: [x_lower, 0 ,y_upper]}
    }

    if (characterPosition2D.distanceTo(new THREE.Vector2(x_lower, y_lower)) < 128){
      new_largeWorldUnits[x_lower_y_lower] = {position: [x_lower, 0 ,y_lower]}
    }


    let temp_worldUnits = {...largeWorldUnits, ...new_largeWorldUnits}

    let quad_keys = []

    for (let key in temp_worldUnits){

      let cur_unitPosition = temp_worldUnits[key].position

      let quadId = key + '_Large'

       if (characterPosition2D.distanceTo(new THREE.Vector2(cur_unitPosition[0], cur_unitPosition[2])) > 128 + 32){

        delete temp_worldUnits[key]

        setActiveQuads(prev => {
          return prev.filter(id => id !== quadId);
         });

      }else{

        if (!activeQuads.includes(quadId)){
          setActiveQuads(prev => [...prev, quadId])
        }
        
      }

    }


    setLargeWorldUnits({...temp_worldUnits})


  },[characterPosition])


  return(

    <>  


    <group>

      <group position={[0,-0.2,0]}>
      {
        Object.keys(largeWorldUnits).map((key) => {

          return(
            <WorldLargeUnit 
              key={key} 
              position={largeWorldUnits[key].position} 
              characterPosition={characterPosition} 

              activeQuads={activeQuads}
              setActiveQuads={setActiveQuads}
            />
          )

        })
      }

      </group>

    </group>



    </>

  )

}




function boundHandler(x, y, size, bound_offset){

  if (size <= 0){
    return {}
  }

  let x_coordinate = Math.floor( (x + size/2) / size)
  let y_coordinate = Math.floor( (y + size/2) / size )

  let x_upper_coordinates_bound = x_coordinate * size + size/2
  let x_lower_coordinates_bound = x_coordinate * size - size/2

  let y_upper_coordinates_bound = y_coordinate * size + size/2
  let y_lower_coordinates_bound = y_coordinate * size - size/2


  let x_update = true
  let y_update = true


  let new_x_position = x_coordinate * size
  let new_y_position = y_coordinate * size
    
    if (x > x_upper_coordinates_bound - bound_offset){
      
      //console.log('X UPPER')
      new_x_position = (x_coordinate + 1) * size
  
    }else if (x < x_lower_coordinates_bound + bound_offset){
     
      //console.log('X LOWER')
      new_x_position = (x_coordinate - 1) * size
    
    }else{
      x_update = false
    }
    

    if(y > y_upper_coordinates_bound - bound_offset){
     
      //console.log('Y UPPER')
      new_y_position = (y_coordinate + 1) * size
     
    }else if (y < y_lower_coordinates_bound + bound_offset){
      
      //console.log('Y LOWER')
      new_y_position = (y_coordinate - 1) * size

    }else{
      y_update = false
    }


    let x_upper_corner_bound = x_coordinate * size + size/2
    let x_lower_corner_bound = x_coordinate * size - size/2

    let y_upper_corner_bound = y_coordinate * size + size/2
    let y_lower_corner_bound = y_coordinate * size - size/2


    let x_upper_corner = x > x_upper_corner_bound - bound_offset
    let x_lower_corner = x < x_lower_corner_bound + bound_offset

    let y_upper_corner = y > y_upper_corner_bound - bound_offset
    let y_lower_corner = y < y_lower_corner_bound + bound_offset

    //console.log(x_upper_corner, x_lower_corner, y_upper_corner, y_lower_corner)


    if (x > x_upper_coordinates_bound - bound_offset){
     
      //console.log('X UPPER')
      new_x_position = (x_coordinate + 1) * size
  
    }else if (x < x_lower_coordinates_bound + bound_offset){
     
      //console.log('X LOWER')
      new_x_position = (x_coordinate - 1) * size
    
    }else{
      x_update = false
    }


    let updateUnits = {}

    if (x_update){
      
      let new_unitPosition = [new_x_position, 0, y_coordinate * size]
      let new_key = '(' + String(Math.floor(new_unitPosition[0]/size)) + ',' + String(Math.floor(new_unitPosition[2]/size)) + ')'
      updateUnits[new_key] = {position: new_unitPosition}

    }


    if (y_update){
      
      let new_unitPosition = [x_coordinate * size, 0, new_y_position]
      let new_key = '(' + String(Math.floor(new_unitPosition[0]/size)) + ',' + String(Math.floor(new_unitPosition[2]/size)) + ')'
      updateUnits[new_key] = {position: new_unitPosition}

    }


    if (x_upper_corner && y_upper_corner){

      let new_unitPosition = [(x_coordinate + 1) * size, 0, (y_coordinate + 1) * size]
      let new_key = '(' + String(Math.floor(new_unitPosition[0]/size)) + ',' + String(Math.floor(new_unitPosition[2]/size)) + ')'
      updateUnits[new_key] = {position: new_unitPosition}

    }else if (x_upper_corner && y_lower_corner){

      let new_unitPosition = [(x_coordinate + 1) * size, 0, (y_coordinate - 1) * size]
      let new_key = '(' + String(Math.floor(new_unitPosition[0]/size)) + ',' + String(Math.floor(new_unitPosition[2]/size)) + ')'
      updateUnits[new_key] = {position: new_unitPosition}

    }else if (x_lower_corner && y_upper_corner){

      let new_unitPosition = [(x_coordinate - 1) * size, 0, (y_coordinate + 1) * size]
      let new_key = '(' + String(Math.floor(new_unitPosition[0]/size)) + ',' + String(Math.floor(new_unitPosition[2]/size)) + ')'
      updateUnits[new_key] = {position: new_unitPosition}

    }else if (x_lower_corner && y_lower_corner){

      let new_unitPosition = [(x_coordinate - 1) * size, 0, (y_coordinate - 1) * size]
      let new_key = '(' + String(Math.floor(new_unitPosition[0]/size)) + ',' + String(Math.floor(new_unitPosition[2]/size)) + ')'
      updateUnits[new_key] = {position: new_unitPosition}

    }


    return updateUnits

}


function WorldHandler(props){

  const {setReady, setReadyProgress} = props

  const {currentUnitQuadrant, setCurrentUnitQuadrant, activeUnitQuadrants, setActiveUnitQuadrants} = useContext(MainWorldContext)

  const {characterRigidRef} = props
  //const {activeQuads, setActiveQuads} = props

  const [activeQuads, setActiveQuads] = useState([])

  const [characterPosition, setCharacterPosition] = useState([0,0,0])

  const [currentCoordinates, setCurrentCoordinates] = useState([0,0])


  const [extraLargeWorldUnits, setExtraLargeWorldUnits] = useState({
    '(0,0)':{position: [0,0,0]}
  })



  useEffect(() => {

    //console.log('CURRENT UNIT QUADRANT: ', currentUnitQuadrant)

  },[currentUnitQuadrant])



  useEffect(() => {

    //console.log('ACTIVE UNIT QUADRANTS: ', activeUnitQuadrants)

    let characterPosition2D = new THREE.Vector2(characterPosition[0], characterPosition[2])

    let temp_curQuadrant = {}
    for (let i = 0; i < activeUnitQuadrants.length; i++){

      let quadId = activeUnitQuadrants[i]
      let cur_x = quadId.split(',')[0].split('(')[1]
      let cur_y = quadId.split(',')[1].split(')')[0]
      let curQuadCenter = new THREE.Vector2(cur_x, cur_y)
      let distanceToQuad = characterPosition2D.distanceTo(curQuadCenter)

      if (temp_curQuadrant.hasOwnProperty('distanceToQuad')){

        if( distanceToQuad < temp_curQuadrant['distanceToQuad'] ){
          temp_curQuadrant = {quadId: quadId, distanceToQuad: distanceToQuad}
        }

      }else{
        temp_curQuadrant = {quadId: quadId, distanceToQuad: distanceToQuad}
      }

    }


    if (temp_curQuadrant.hasOwnProperty('quadId')){
      setCurrentUnitQuadrant(temp_curQuadrant['quadId'])
    }

    //console.log(temp_curQuadrant)

  },[activeUnitQuadrants])



  useFrame(() => {

    let characterReady = false

    if (characterRigidRef !== null && typeof(characterRigidRef.current) !== 'undefined'){
      //console.log(characterRigidRef.current.translation())

      setCharacterPosition([
        characterRigidRef.current.translation().x, 
        characterRigidRef.current.translation().y, 
        characterRigidRef.current.translation().z
        ])

      characterReady = true
    }

    if (!characterReady){
      return
    }


    let x = characterRigidRef.current.translation().x
    let y = characterRigidRef.current.translation().z 

    let size = 128
    let bound_offset = 64

    let new_extraLargeWorldUnits = boundHandler(x, y, size, bound_offset)

    let temp_worldUnits = {...extraLargeWorldUnits, ...new_extraLargeWorldUnits}

    let characterPosition2D = new THREE.Vector2(characterPosition[0], characterPosition[2])

    //let quad_keys = []

    for (let key in temp_worldUnits){

       let cur_unitPosition = temp_worldUnits[key].position

       let quadId = key + '_ExtraLarge'


       if (characterPosition2D.distanceTo(new THREE.Vector2(cur_unitPosition[0], cur_unitPosition[2])) > 256){

        delete temp_worldUnits[key]

        setActiveQuads(prev => {
          return prev.filter(id => id !== quadId);
         });



      }else{

        if (!activeQuads.includes(quadId)){
          setActiveQuads(prev => [...prev, quadId])
        }
        
      }

    }

    setExtraLargeWorldUnits({...temp_worldUnits})

  })



return(
  <>

   
    <SceneParser activeQuads={activeQuads} setReady={setReady} setReadyProgress={setReadyProgress} />
   

    {
      Object.keys(extraLargeWorldUnits).map((key) => {

        return(

           <WorldExtraLargeUnit 

             key={key} 
             position={extraLargeWorldUnits[key].position} 
             characterPosition={characterPosition} 

             activeQuads={activeQuads}
             setActiveQuads={setActiveQuads}

           />

        )

      })
    }
   
  </>
  )
}

export default WorldHandler;