import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'


import adjustments from './attribute_adjustments.json'

//import pairs from './pairs.json';

import { MainPlayerContext } from 'components/Context/PlayerContext'


function ValidPairs(props){

  const {avatarMeta} = useContext(MainPlayerContext)

  //const [pairs] = useState(avatarMeta)

  const setModels = props.setModels
  const pairs = props.meta
  const [importedModels, setImportedModels] = useState([])
  const [currentModel, setCurrentModel] = useState()


  /*
  console.log('PAIRS')
  console.log(pairs)

  console.log('AVATAR META')
  console.log(avatarMeta)
  */
  


  useEffect(() => {

    let toImport = []
    let pairs_dict = pairs['model']
    let attribute_dict = {}


    Object.keys(pairs_dict).forEach((key) => {

      //console.log('GLB/' + key + '/' + pairs_dict[key] + '/' +  pairs_dict[key] + '.glb')

      if (key === 'hat_and_hair'){
        

        let hat_and_hair = pairs_dict[key]

        let hat = hat_and_hair.split('_and_')[0]
        let hair = hat_and_hair.split('_and_')[1]


        let folder = pairs_dict[key]
        let attribute =  pairs_dict[key]
        //hair.split('&')

        
        if (hair.includes('&')){
          folder = pairs_dict[key].split('&')[0]
          hair = hair.split('&')[1] + '_' + hair.split('&')[0]
        }


        if (hat.includes('&')){
          folder = pairs_dict[key].split('&')[0]
          hat = pairs_dict[key].split('&')[1] + '_' + pairs_dict[key].split('&')[0]
        }
        
        attribute_dict['hat'] = {url: 'GLB/' + key + '/' + folder + '/hat/' +  hat + '.glb'}

        attribute_dict['hair'] = {url: 'GLB/' + key + '/' + folder + '/hair/' +  hair + '.glb'}



      }else{

        //pairs_dict[key].split('&')

        let folder = pairs_dict[key]

        let attribute =  pairs_dict[key]

        if (attribute.includes('&')){
          folder = pairs_dict[key].split('&')[0]
          attribute = pairs_dict[key].split('&')[1] + '_' + pairs_dict[key].split('&')[0]
        }

        attribute_dict[key] = {url: 'GLB/' + key + '/' + folder + '/' +  attribute + '.glb'}
        
      }  

  });



      //check if any adjustments need to be made
      let traits = Object.keys(pairs_dict)

      

      traits.forEach((key) => {

        if (key in adjustments){
          console.log('adjustments needed')
          console.log(key)

          let eligible_traits = Object.keys(adjustments[key])

          console.log(eligible_traits)

          if (eligible_traits.includes(pairs_dict[key])){
            console.log(pairs_dict[key])

            let temp_traits_to_adjust = adjustments[key][pairs_dict[key]]


            for (let key2 in temp_traits_to_adjust){
              if (traits.includes(key2)){
                 console.log(temp_traits_to_adjust[key2])


                 let eligible_traits_2 = Object.keys(temp_traits_to_adjust[key2])

                 console.log(eligible_traits_2)

                 if (eligible_traits_2.includes(pairs_dict[key2])){
                  let cur_adjust_trait = pairs_dict[key2]
                  let cur_adjustment = temp_traits_to_adjust[key2][pairs_dict[key2]]

                  attribute_dict[key2].adjustments = cur_adjustment
                 }

              }
            }

          }

        }

      })





    //console.log(toImport)

    console.log(attribute_dict)

    setModels(attribute_dict)

  },[pairs])

return(
  
   <>

   </>
  
  )
}

export default ValidPairs;