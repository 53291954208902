import { useState, useEffect, useRef } from 'react'

import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { RigidBody} from '@react-three/rapier'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


function Exterior(props) {


  const {playerRef} = props


//const {setLoaded} = props

//const file_url = "STORE_GLB/meshes/temp/exterior.glb"

//const file_url = "STORE_GLB/meshes/exterior.glb"

const file_url = "https://claynation.nyc3.cdn.digitaloceanspaces.com/ClayWorld/Environments/Sky/CloudDome_Pink_fixed2.glb"

 const gltf = useLoader(GLTFLoader, file_url)

 const [scene, setScene] = useState()

 const cloudRef = useRef()

 useEffect(() => {

    let model = gltf.scene

    model.traverse(function (object){
      
      if (object.isMesh){
        object.receiveShadow = true
        object.castShadow = true

        object.material.depthWrite = true;
        object.material.alphaTest = 0
      }

    })

    //setLoaded(true)
    setScene(model)

},[gltf])


 useFrame(() => {

  try{
    cloudRef.current.rotation.y += 0.0005
  }catch{

  }
  

  try{
    cloudRef.current.position.x = playerRef.current.translation().x
    cloudRef.current.position.y = playerRef.current.translation().y
    cloudRef.current.position.z = playerRef.current.translation().z
  }catch{
    
  }

  

 })


/*
useEffect(() => {

  setLoaded(true)

},[])
*/
 

return(
  
  <>


  {scene ?
    
      <primitive
        ref={cloudRef}
        object={gltf.scene}

        scale={[11,11,11]}
      
      />
  
   :null}


  </>
  
  )
}

export default Exterior;
