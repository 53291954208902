import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'



function AnimationLoader(props){

   const animation = props.animation

   const animationDict = props.animationDict
   const setAnimationDict = props.setAnimationDict

   const mixer = props.mixer
   const setMixer = props.setMixer

     let gltf = useLoader(
           GLTFLoader,
           animation.url
       )


   useEffect(() => {

      let cur_animation = gltf.animations[0]

      let temp_animationDict = {...animationDict}

      animationDict[animation.name] = cur_animation


     },[])



return(
  
   <>

   </>
  
  )
}

export default AnimationLoader;