import styled, { keyframes } from "styled-components";
import walleticon from '../Images/wallet.png';
import cursorvoxelised from '../Images/cursor32.png';


// export const WalletButton = styled.button`
//    background: var(--clayblue-secondary);
//    font-family: PixelFont;
//    font-weight: 100;
//    text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
//    border: none;
//    max-width: 100%;
//    max-height: 100%;
//    width: fit-content;
//    heightL: fit-content;
//    padding: 1rem 2rem;
//    font-size: clamp(0.5rem, 4vw, 2rem);
//    letter-spacing: 0.2rem;
//    text-align: center;
//    color: white;
//    border-radius: 1rem;
//    cursor: url(${cursorvoxelised}), auto;
//    white-space: nowrap;
//    position: absolute;
//     right: 2%;
//     top: 5%;  
//     display: inline-flex;
//    &:hover {
//        outline: 2px solid var(--clayblue-primary); ;
//    }
//    @media screen and (max-width: 768px) {
//     display: none;
//    }
//    @media screen and (max-width: 768px) and (orientation: landscape) {
//     font-size: clamp(0.5rem, 2vw, 2rem);
//     border-radius: 0.5rem;
//     padding: 0.5rem 1rem;
//     display: inline-flex;
//     align-items: center;
//    }
//    `;



const pulsate = keyframes`
  0% {
    box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);
  }
  50% {
    box-shadow: 10px 2px 25px 6px rgba(218, 139, 155, 0.8), -10px 2px 10px 6px rgba(123, 103, 208, 0.8);
  }
  100% {
    box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);
  }
`;


export const WalletButton = styled.button`

/* Btn-ConnectWallet-new */

box-sizing: border-box;


width: 329.16px;
height: 73px;


background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
border: 1px solid rgba(207, 207, 207, 0.5);
box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);
border-radius: 13.27px;



/* CONNECT WALLET */



font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 22.8981px;
line-height: 140%;
/* identical to box height, or 39px */
text-align: center;
letter-spacing: 0.01em;

color: #FFFFFF;

cursor: pointer;


animation: ${pulsate} 1.5s infinite;  // Apply the animation here

transition: .3s ease-in-out 0s;

&:hover{
  animation: none;
  transform: scale(1.05);

  box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);

`
   
   export const WalletMobile = styled.button`
   position: fixed;
   top: 3%;
   right: 3%;
   display: flex;
   
   border-radius: 2em;
   border: none;
   padding: 3em 3em;



  background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
  border: 1px solid rgba(207, 207, 207, 0.5);
  box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);
  border-radius: 13.27px;


  background-image: url(${walleticon});
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center;
 
   @media screen and (min-width: 768px) {
     display: none;
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
   }
   `;



     export const WalletMobileBusy = styled.button`
   position: fixed;
   top: 3%;
   right: 3%;
   display: flex;
   background: var(--clayblue-secondary);
   border-radius: 2em;
   border: none;
   padding: 3em 3em;
   
 
   @media screen and (min-width: 768px) {
     display: none;
   }
   @media screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
   }
   `;



