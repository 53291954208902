import {useContext} from 'react'


import ClaimContext from 'components/ClaimContext'

import Context from 'components/Context/Context'

import ExperienceContext from 'components/Context/ExperienceContext'

import {MainContext} from 'components/Context/Context'

import WalletLinkApp from './Pages/WalletLinkApp.jsx'

import './WalletLink.css'


function WalletLink() {



  return (
    <>
     <div className="App3">
      <header className="App-header-3" style={{backgroundColor:'', overflow:'scroll'}}>
        <WalletLinkApp />  
      </header>
    </div>
    </>
  );
}

export default WalletLink;