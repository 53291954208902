import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = { 
    currentApp: 'market',
    
}


const actions = {

    SET_CURRENT_APP: "SET_CURRENT_APP",

}

function reducer(state, action){
    switch (action.type){

        case actions.SET_CURRENT_APP:
            return {...state, currentApp: action.value};

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        currentApp: state.currentApp,
                
        setCurrentApp: value => {
            dispatch({type: actions.SET_CURRENT_APP, value})
        },

        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainContext.Provider value={value}>
            {children}
        </MainContext.Provider>
        )
}


function Context({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default Context;


