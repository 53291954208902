import React from 'react'
import { useState, useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import './VotePopUp.css'

import {MainClaimContext} from './ClaimContext'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function VotePopUp(props) {

  const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


    const [buttonPopup, setButtonPopup] = useState(false);

    const {address, stake_address, lovelace, tokens, selectedTokens, isVoteOpen, voteOption, voteProposal, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, setIsVoteOpen, setVoteOption, setVoteProposal} = useContext(MainClaimContext)

    const [tempOption, setTempOption] = useState()

    const [selectedButton, setSelectedButton] = useState()

    const activeStyle = {
                background: 'linear-gradient(30deg, rgb(64, 47, 85) 0%, rgba(29,58,61,1) 100%)',
                boxShadow: 'inset -7px -7px 38px rgba(0, 0, 0), 0px 0px 28px rgba(139, 182, 224, 0.5)' ,
                marginTop:'20px',
                marginBottom:'0px'
    }

function clickMe() {

    if(tempOption && voteProposal.options.includes(tempOption.option) && tempOption.proposal_id == voteProposal.proposal_id){
          setIsVoteOpen(false)
          setVoteOption(tempOption)
          notifySuccess('Thank you for voting!');

    }else{
        notifyWarn('You MUST cast a vote!');
    }
}


useEffect(() => {

    if (typeof(voteOption) == 'undefined'){
        setSelectedButton()
    }


},[voteOption])

  return (
  <>  
    {isVoteOpen && typeof(voteProposal) != 'undefined' ? (


   


<Grid container justifyContent="center" alignItems="center">


    <div className='popup' style={{width:'100%', height:'100%', zIndex:'1350', overflow:'scroll'}}  > 
        <Grid container justifyContent="center" alignItems="center" style={{display:'flex',position:'absolute', top:'0px', height:'100%'}}>
   
        <div className='popup-inner'>

        <button className='close-btn' onClick={() => {
            notifyWarn('Voting window was closed!')
            setVoteOption(null)
            setSelectedButton()
            setIsVoteOpen(false)}}>X</button> 

        <Grid container justifyContent="center" alignItems="center" style={{height:'100%', width:'100%'}}>

            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}

              style={{height:'100%', width:'80%'}}
            >


           
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', objectFit: 'cover', minWidth:'200px', width:'40%', height:'40%'}}>
             <img src="/vote.png" style={{width:'100%', height:'auto', minWidth:'160px', maxWidth:'30vh'}}/>
            </div>
        
          
      
                <div className='Question' style={{color:'bisque'}}><h3>{voteProposal.proposal}</h3></div>
          

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}

              style={{width:'80%'}}
            >


         
           {voteProposal.options.map(option => (

           <button className={(Object.keys(option)[0]) == selectedButton ? 'selectedButton' : 'black-btn'} key={Object.keys(option)[0]} onClick={() => {
            setSelectedButton(Object.keys(option)[0])
            setTempOption({option:option,proposal_id:voteProposal.proposal_id})}}>{option[Object.keys(option)[0]]}</button>
           ))}



            </Stack>





            
 


            <div style={{display:'flex',justifyContent: 'center', alignItems:'center', width:'60%',height:'25%',minHeight:'90px'}}>

                    <button className='submit-btn' onClick={clickMe}>Submit!</button>

            </div>






            </Stack>




        </Grid>



        </div>


 </Grid>
    </div>
   

</Grid>





    /*
    <div className='popup'>
        <div className='popup-inner'>
           <button className='close-btn' onClick={() => {
            notifyWarn('Voting window was closed!')
            setVoteOption(null)
            setIsVoteOpen(false)}}>X</button> 

            <img src='/vote.png' style={{width:'100%', maxWidth:'250px'}}/>


           <div className='Question'><h3>{voteProposal.proposal}</h3></div>

           {voteProposal.options.map(option => (

           <button className='black-btn' style={tempOption == Object.keys(option)[0] ? {width:'1000px'} : { marginTop:'20px', marginBottom:'0px'}} key={Object.keys(option)[0]} onClick={() => {
            console.log('BUTTON')
            setTempOption({option:option,proposal_id:voteProposal.proposal_id})}}>{option[Object.keys(option)[0]]}</button>
           ))}

           <button className='submit-btn' onClick={clickMe}>Submit!</button>

           </div>
    </div>
*/





    )
    :
    (null)}
    </>
  )
}

export default VotePopUp