

function TermsAndConditions(props){



	 const containerStyles = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
  };


    const headingStyles = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color:'black'
  };

  const paragraphStyles = {
    marginBottom: "10px",
  };
		

		
	return (

		<div style={{background:'url(https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Background.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '100vh', overflow:'scroll', color:'bisque'}}>
  		
		<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', textAlign:'center'}}>
			<div style={containerStyles}>

		<p style={headingStyles}>Terms and Conditions of Use</p>

<p> Welcome to Clay.Dash, a service provided by Clay Labs Digital (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;, or &quot;Clay Labs Digital&quot;). By using our service, you agree to the following terms and conditions:</p>

<p style={{fontWeight:'bold'}}> 1. Introduction</p>

<p>1.1. Clay Dash is a platform provided by Clay Labs Digital that allows users to create user accounts with multiple wallet addresses, to link and store participation and progress in the clay ecosystem.</p>

<p style={{fontWeight:'bold'}}> 2. User Accounts</p>

<p>2.1. To use Clay Dash, you must create an account by providing a valid email address, username, and wallet addresses.</p>

<p>2.2. You are responsible for maintaining the confidentiality of your account information, including your password.</p>

<p style={{fontWeight:'bold'}}> 3. Data Collection and Usage</p>

<p>3.1. By using Clay Dash, you consent to the collection of your email address, username, and wallet addresses.</p>

<p>3.2. We may use this information for User Authentication: Verifying the identity of users to ensure secure access to their accounts and protect against unauthorized access. 1. Communication: Sending notifications, updates, and important information related to Clay Dash, including account activity, service announcements, and promotional offers. 2. Customer Support: Addressing user inquiries, providing assistance, and resolving issues or concerns raised by users. 3. Transaction Processing: Facilitating and confirming transactions made through Clay Dash, which may involve verifying wallet addresses and transaction details. 4. Account Management: Managing user accounts, including password resets, account recovery, and account-related settings. 5. Service Improvement: Analyzing user data to gain insights into how Clay Dash is used, identifying areas for improvement, and enhancing the overall user experience. 6. Legal Compliance: Fulfilling legal obligations, including anti-money laundering (AML) and know your customer (KYC) requirements, as mandated by applicable laws and regulations. 7. Security and Fraud Prevention: Monitoring user activity for suspicious or fraudulent behavior, and taking necessary measures to safeguard the platform and its users. 8. Research and Analytics: Conducting research, analysis, and reporting on user behavior and trends to make informed business decisions and improve Clay Dash's services.</p>

<p>3.3. We will not sell, share, or rent your personal information to any third parties without your consent.</p>

<p style={{fontWeight:'bold'}}> 4. Security</p>

<p>4.1. We implement industry-standard security measures to protect your personal information. However, no method of transmission over the internet is completely secure.</p>

<p style={{fontWeight:'bold'}}>5. Intellectual Property</p>

<p>5.1. The content provided on Clay Dash is protected by intellectual property laws.</p>

<p>5.2. You may not reproduce, distribute, or create derivative works based on our content without our prior written consent.</p>

<p style={{fontWeight:'bold'}}> 6. Termination</p>

<p>6.1. We reserve the right to terminate or suspend your account at any time, without prior notice, for any reason.</p>

<p style={{fontWeight:'bold'}}> 7. Disclaimer of Warranty</p>

<p>7.1. Clay Dash is provided &quot;as is&quot; without any warranty, express or implied.</p>

<p style={{fontWeight:'bold'}}> 8. Limitation of Liability</p>

<p>8.1. We shall not be liable for any indirect, special, incidental, or consequential damages.</p>

<p style={{fontWeight:'bold'}}> 9. Governing Law</p>

<p>9.1. These terms and conditions shall be governed by and construed in accordance with the laws of the United Kingdom.</p>

<p style={{fontWeight:'bold'}}> 10. Changes to Terms and Conditions</p>

<p>10.1. We reserve the right to update, modify, or replace these terms and conditions at any time.</p>

<p style={{fontWeight:'bold'}}> 11. Contact Us</p>

<p>11.1. If you have any questions or concerns about these terms and conditions, please contact us at contact@claymates.org.</p>

<p>By using Clay Dash, you agree to these terms and conditions. Please read them carefully!</p>

			</div>
		</div>
		</div>

	)

}

export default TermsAndConditions;