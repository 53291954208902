import styled from "styled-components";
import cursorvoxelised from '../Images/cursor32.png';


export const CompletedPannel = styled.div`
box-sizing: border-box;
background: var(--background);
padding: 2.2em 7em;
border-radius: 2em;
backdrop-filter: blur(22px);
contain: content;
max-width: 80vw;
width: 65vw;
max-height: 70vh;
position: absolute;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
margin-top: 5em;
@media screen and (max-width: 768px) {
    background: var(--background-progress);
    padding: 1em 2em;
    width: 80vw;
    height: 80vh;
    max-height: 80vh;
    gap: 0.5em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        gap: 0em;
    }
`;

export const H1= styled.h1`
font-family: PixelFont;
font-size: clamp(1.5rem, 3vw, 3rem);
color: var(--claygreen-primary);
text-align: center;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
text-transform: uppercase;
font-weight: 100;
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 6vw, 3rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 3vw, 3rem);
}
`;


export const P1= styled.p`
font-family: PixelFont;
font-size: clamp(1.5rem, 1.8vw, 3rem);
color: var(--claytext-white);
text-align: center;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
text-transform: uppercase;
font-weight: 200;
padding: 0em 1em;
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 4vw, 3rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 3rem);
}
`;

export const P2= styled.p`
font-family: PixelFont;
font-size: clamp(1rem, 1.2vw, 3rem);
color: var(--claytext-white);
text-align: center;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
text-transform: uppercase;
font-weight: 200;
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 3vw, 3rem);
    padding: 0em 1em;
    order: 1; 
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 3rem);
    order: 0; 
}
`;


export const InfoWindow = styled.div`
background: var(--background-progress);
padding: 1em;
border-radius: 1em;
backdrop-filter: blur(12px);
contain: content;
max-width: 20vw;
width: 20vw;
color: var(--claytext-white);
text-align: center;
position: absolute;
bottom: 1em;
right: 2em;
display: none;
@media screen and (max-width: 768px) {
    background: black;
    width: 72vw;
    max-width: 72vw;
    max-height: 30vh;
    bottom: 0em;
    right: 0em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    max-width: 27vw;
    width: 27vw;
    max-height: 72vh;
    bottom: 0em;
    right: 0em;
    font-size: clamp(0.5rem, 2vw, 3rem);
`;


export const INFO = styled.div`
color: var(--clayblue-secondary);
font-size: clamp(1rem, 1.2vw, 3rem);
position: absolute;
bottom: 2em;
right: 2em;
align-items: center;
justify-content: center;
display: flex;
cursor: url(${cursorvoxelised}), auto;
&:hover + ${InfoWindow} {
   display: flex;
}
@media screen and (max-width: 768px) {
    bottom: 1em;
    right: 1em;
}
`;

export const Button = styled.button`
font-family: PixelFont;
font-size: clamp(1.5rem, 2vw, 3rem);
color: var(--claygreen-primary);
text-align: center;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
text-transform: uppercase;
font-weight: 100;
border: none;
background: var(--clayblue-secondary);
border-radius: 0.5em;
padding: 0.35em 0.7em;
cursor: url(${cursorvoxelised}), auto;
&:hover {
    background: var(--clayblue-primary);
    transition: 0.33s ease-in-out;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 2.5vw, 3rem);
}

`;





export const GIF = styled.img`
width: 20%;
height: auto;
position: relative;
@media screen and (max-width: 768px) {
    width: 50%;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 20%;
}
`;
