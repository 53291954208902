import {useState, useEffect, us} from 'react'

import * as THREE from 'three'


function SoundLoader(props){

  const {audioListener, soundsToLoad, loadedSounds, setLoadedSounds} = props

  const [audioLoader] = useState(() => new THREE.AudioLoader())


   useEffect(() => {

      console.log(audioListener)
      if (audioListener === null){
        return
      }

      let temp_loadedSounds = {}
      for (let key in soundsToLoad){
        let cur_url = soundsToLoad[key].url
        const sound = new THREE.Audio( audioListener );

        audioLoader.load( cur_url, function( buffer ) {
          sound.setBuffer( buffer );


          if (soundsToLoad[key].hasOwnProperty('loop')){
            sound.setLoop( soundsToLoad[key].loop );
          }else{
            sound.setLoop( false );
          }
          
          if (soundsToLoad[key].hasOwnProperty('volume')){
            sound.setVolume(soundsToLoad[key].volume)
          }else{
            sound.setVolume( 0.5 );
          }
          //sound.play();
        });

        temp_loadedSounds[key] = sound

      }

      //setLoadedSounds({...loadedSounds, ...temp_loadedSounds})
      setLoadedSounds(temp_loadedSounds)


      return () => {
        for (let key in loadedSounds){
          loadedSounds[key].stop()
        }
      }

    },[audioListener])

  return(
    null
  )
}

export default SoundLoader;