
import styled from "styled-components";
import cursorvoxelised from '../Images/cursor32.png';


export const SidePannel = styled.div`
box-sizing: border-box;
background: var(--background);
border-radius: 00px 3em 3em 0px;
max-height: 92vh;
min-height: 100px;
max-width: 22vw;
min-width: 100px;
width: 100%;
height: 100%;
left: 0;
top: 6%;
z-index: 2;
backdrop-filter: blur(22px);
position: absolute;
padding: 2em 2em;

min-width: 300px;

@media screen and (max-width: 768px) {
    min-width: 200px;

    max-width: 70vw;
    max-height: 92vh;
    background: var(--background-progress);
}

@media screen and (max-width: 768px) and (orientation: landscape) {
    max-width: 70vw;
    max-height: 80vh;
    border-radius: 2em;
    contain: content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 15%;
    top: 20%;
    padding: 0;
}

z-index: 100

`;



export const H1 = styled.h1`
font-family: PixelFont;
font-size: clamp(1rem, 4vw, 7rem);
font-weight: 50;
letter-spacing: 0.2rem;
color: var(--clayblue-primary);
text-align: center;
margin: 0;
padding-top: 0.5em;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 8vw, 10rem);
    padding: 0.5em 0.7em;
    border-radius: 0.5em;
    left: 5%;
    position: absolute;
    top: 10%;
    display: inline-block;
    background: var(--background);
    
}

`;

export const H2 = styled.h1`
font-family: ShortStack;
font-size: clamp(1rem, 1.2vw, 7rem);
font-weight: 50;
color: var(--claytext-white);
text-align: center;
margin: 0;
padding: 0;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 1.2vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 7rem);
}

`;

export const H3 = styled(H2)`
font-size: clamp(0.5rem, 1vw, 7rem);
color: var(--claygreen-primary);   
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1vw, 7rem);
}

`;

export const Number = styled.div`
font-family: ShortStack;
font-size: clamp(1rem, 1.5vw, 7rem);
font-weight: 50;
color: var(--claytext-white);
text-align: center;
margin: 0;
padding: 0.5em;
width: fit-content;
background: rgba(0, 0, 0, 0.25);
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
border-radius: 0.5em;
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 5vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 7rem);
}

`;




export const CloseButton = styled.button`
background: transparent;
border: none;
color: var(--claytext-white);
font-size: 50px;
font-weight: 100;
position: absolute;
right: 0.6em;
top: 0.5em;
margin: 0;
padding: 0;

z-index: 2;

cursor: pointer;

`;





export const CloseButton_old = styled.button`
background: transparent;
border: none;
color: var(--claytext-white);
font-size: clamp(1rem, 4vw, 3rem);
font-weight: 100;
font-family: PixelFont;
position: absolute;
right: 0.6em;
top: 0.5em;
margin: 0;
padding: 0;

cursor: pointer;

z-index: 2;

@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
    right: 1em;
    top: 1em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 4vw, 10rem);
    right: 1em;
    top: 0.5em;
}

cursor: pointer;

`;


export const CloseButtonSmall = styled.button`
background: transparent;
border: none;
color: var(--claytext-white);
font-size: clamp(1rem, 4vw, 3rem);
font-weight: 100;
font-family: PixelFont;
position: absolute;
right: 0.6em;
top: 0.5em;
margin: 0;
padding: 0;

cursor: pointer;

z-index: 2;

@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
    right: 1em;
    top: 1em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 4vw, 10rem);
    right: 1em;
    top: 0.5em;
}

cursor: pointer;

`;

export const Line = styled.hr`
border: 1px solid var(--clayblue-primary);
width: 60%;
@media screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
}
`;


export const Separator = styled.hr`
border: 1px solid rgba(0, 0, 0, 0.22);
width: 90%;
margin: 0 auto;
`;

export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 2em 0;
gap: 1em;
@media screen and (max-width: 768px) {
    padding: 1.5em 0;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    padding: 0.3em 0;
}
`;


export const VoxeliseButton = styled.button`

width: 173px;
height: 54px;

padding: 10px 61px;
gap: 10px;


border: none;
outline: none;
position: relative;
border-radius: 10px;
background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
cursor: pointer;

color: white;

&:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;


   background: #495569;
   border-radius: 10px;

}


&:after {
  content: attr(data);
  font-size: 16px;
  color: white;
}


&:hover:before {

    background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
    border-radius: 10.5455px;

}


 transition: .3s ease-in-out 0s;

 &:hover{
    transform: scale(1.1);
 }







`






export const VoxeliseButton2 = styled.button`

width: 173px;
height: 54px;

padding: 10px 61px;
gap: 10px;

border: none;
outline: none;
position: relative;
border-radius: 10px;
background: linear-gradient(265.24deg, #A8508D -10.27%, #B33030 119.18%);
cursor: pointer;

color: white;
display: flex; /* Centering */
justify-content: center; /* Centering horizontally */
align-items: center; /* Centering vertically */

&:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;

  background: #D46EAA;
  border-radius: 10px;
}

&:after {
  content: attr(data);
  font-size: 16px;
  color: white;
}

&:hover:before {
    background: linear-gradient(265.24deg, #FF79D1 -10.27%, #FF4D4D 119.18%);
    border-radius: 10.5455px;
}

transition: .3s ease-in-out 0s;

&:hover {
    transform: scale(1.1);
}

cursor: pointer;

`;





export const VoxeliseButton4 = styled.button`

width: 273px;
height: 54px;



border: none;
outline: none;
position: relative;
border-radius: 10px;
background: linear-gradient(265.24deg, #A8508D -10.27%, #B33030 119.18%);
cursor: pointer;

color: white;
display: flex; /* Centering */
justify-content: center; /* Centering horizontally */
align-items: center; /* Centering vertically */

&:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;

  background: #D46EAA;
  border-radius: 10px;
}

&:after {
  content: attr(data);
  font-size: 16px;
  color: white;
}

&:hover:before {
    background: linear-gradient(265.24deg, #FF79D1 -10.27%, #FF4D4D 119.18%);
    border-radius: 10.5455px;
}

transition: .3s ease-in-out 0s;

&:hover {
    transform: scale(1.1);
}

cursor: pointer;



/* Responsive adjustments for smaller screens */
  @media (max-width: 1268px) {
    width: 180px;
    
  }


/* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    width: 150px;
    
  }

  @media (max-width: 480px) {
    width: 120px;
  }

`;



// Styled drop-up menu container
export const DropUpContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  background: linear-gradient(265.24deg, #A8508D -10.27%, #B33030 119.18%); /* Matching gradient */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  color: white;
`;

// Styled option buttons inside the drop-up menu
export const OptionButton = styled.button`
  background-color: rgba(255, 255, 255, 0.1); /* Transparent overlay */
  border: none;
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;


  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;

  font-size: 18px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Slightly brighter on hover */
  }
`;





export const VoxeliseButton3 = styled.button`


width: 173px;
height: 54px;

padding: 10px 61px;
gap: 10px;

border: none;
outline: none;
position: relative;
border-radius: 10px;
background: linear-gradient(265.24deg, #FF914D -10.27%, #FF4500 119.18%);
cursor: pointer;

color: white;
display: flex; /* Centering */
justify-content: center; /* Centering horizontally */
align-items: center; /* Centering vertically */

&:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;

  background: #FF7033; /* Bright inner orange background */
  border-radius: 10px;
}

&:after {
  content: attr(data);
  font-size: 16px;
  color: white;
}

&:hover:before {
    background: linear-gradient(265.24deg, #FF914D -10.27%, #FF4500 119.18%);
    border-radius: 10.5455px;
}

transition: .3s ease-in-out 0s;

&:hover {
    transform: scale(1.1);
}

cursor: pointer;



/* Responsive adjustments for smaller screens */
  @media (max-width: 1268px) {
    width: 180px;
    
  }


/* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    width: 150px;
    
  }

  @media (max-width: 480px) {
    width: 120px;
  }

`;





export const VoxeliseButton_Disabled = styled.button`

width: 173px;
height: 54px;

padding: 10px 61px;
gap: 10px;


border: none;
outline: none;
position: relative;
border-radius: 10px;
background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
cursor: pointer;

color: white;

&:before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;


   background: #495569;
   border-radius: 10px;

}


&:after {
  content: attr(data);
  font-size: 16px;
  color: white;
}


&:hover:before {

    

}


 transition: .3s ease-in-out 0s;

 &:hover{
    
 }






`

// export const VoxeliseButton = styled.button`

// box-sizing: border-box;

// /* Auto layout */
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// padding: 10px 61px;
// gap: 10px;

// width: 173px;
// height: 54px;


// background: rgba(138, 138, 138, 0.2);
// border-radius: 10px;


// font-family: 'Lato';
// font-style: normal;
// font-weight: 700;
// font-size: 24px;
// line-height: 140%;
// /* identical to box height, or 34px */
// text-align: center;

// color: #FFFFFF;


// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;


// cursor: pointer;


// overflow: hidden;



// &:hover {

//     background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
//     border-radius: 10.5455px;

// }



// z-index: 3;

// `;




export const VoxeliseButton_old = styled.button`
background: var(--background);
font-family: PixelFont;
border: none;
outline: 2px solid var(--clayblue-primary);
padding: 1rem 2rem;
font-size: clamp(0.5rem, 2vw, 3rem);
letter-spacing: 0.2rem;
text-align: center;
color: var(--clayblue-primary);
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
border-radius: 20px;

transition: 0.2s ease-in-out;

min-height: 60px;
min-width: 160px;

&:hover {
    background: var(--clayblue-primary);
    color: var(--claygreen-primary);
}

@media screen and (max-width: 1024px) {
    font-size: clamp(0.8em, 3.5vw, 10em);

    min-height: 60px;
    min-width: 160px;
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 7vw, 10rem);
    bottom: 2em;

    min-height: 60px;
    min-width: 160px;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.8rem, 4vw, 10rem);
    position: relative;
    border-radius: 1em;
    bottom: 0;
    padding: 1em 2em;
    margin-top: 1em;

    min-height: 60px;
    min-width: 160px;
}

z-index: 3;

`;