import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'

import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box } from '@react-three/drei'

import { RigidBody, CapsuleCollider, BallCollider, useRapier, interactionGroups } from '@react-three/rapier'

import * as THREE from 'three'

import Avatar from '../Avatar/Avatar'

import LocalPlayer from '../LocalPlayer/LocalPlayer'

import DustParticles from './DustParticles'

import { MainPlayerContext } from 'components/Context/PlayerContext'
import { MainExperienceContext } from 'components/Context/ExperienceContext'
import MovementParticles from '../LocalPlayer/MovementParticles'


function SimpleController(props){

  const {setReadyPlayer, initialPosition, initialRotation, setOutlineSelection} = props

  //console.log('SIMPLE CONTROLLER')

  const {avatarMeta, setAvatarMeta, activeObjects, setActiveObjects, bottomActiveObjects, setBottomActiveObjects, inputBlocked, setInputBlocked, playerAction, setPlayerAction} = useContext(MainPlayerContext)
  const {location, setLocation, prevLocation, setPrevLocation, audioListener} = useContext(MainExperienceContext)
  const [meta, setMeta] = useState(avatarMeta)
  const [isLoaded, setIsLoaded] = useState(false)
  const characterRef = useRef()
  const [cameraRayStart, setCameraRayStart] = useState([0,0,0])
  const [cameraRayEnd, setCameraRayEnd] = useState([0,1,0])
  const [cameraDistanceBefore, setCameraDistanceBefore] = useState(1)
  const [cameraColliding, setCameraColliding] = useState(false)


  const [spawn, setSpawn] = useState(true)


  useEffect(() => {

    if (isLoaded){
      setReadyPlayer(true)
    }

  }, [isLoaded])

  const { rapier, world } = useRapier()
  const [rapierWorld] = useState(world)

  //const [OFFSET_Y] = useState(-0.99)
  const [OFFSET_Y] = useState(-1.1)
  const [MAX_SLOPE_CLIMB_ANGLE] = useState(60)
  const [MIN_SLOPE_SLIDE_ANGLE] = useState(30.0)
  const [MAX_OBSTACLE_HEIGHT] = useState(0.25)
  const [MIN_OBSTACLE_WIDTH] = useState(0.2)
  const [SNAP_DISTANCE] = useState(0.5)
  const [OFFSET] = useState(0.1)
  const [inAir, setInAir] = useState(()=> false)
  const [velocityY, setVelocityY] = useState(() => 0)
  const controls = props.controls
  const cameraTarget = props.cameraTarget
  const setCameraTarget = props.setCameraTarget
  const charGroupRef = useRef()
  //const rigidRef = useRef()
  const {rigidRef} = props
  const colliderRef = useRef()
  const [ animations, setAnimations ] = useState(()=>{})
  const [animationDict, setAnimationDict] = useState({})
  const [mixer, setMixer] = useState()
  const { camera, gl, scene } = useThree();
  const [ currentAction, setCurrentAction ] = useState(()=>'Idle')


  const [ forward, setForward ] = useState(() => false)
  const [ backward, setBackward ] = useState(() => false)
  const [ left, setLeft ] = useState(() => false)
  const [ right, setRight ] = useState(() => false)
  const [ jump, setJump ] = useState(() => false)
  const [ run, setRun ] = useState(() => false)
  const [ interact, setInteract ] = useState(false)
  const [speedY, setSpeedY] = useState(1)
  const [maxFallSpeed, setMaxFallSpeed] = useState(15)
  const [allowedJump, setAllowedJump] = useState(true)
  const [lastGrounded, setLastGrounded] = useState(0)
  const [allowedCayote, setAllowedCayote] = useState(false)
  const [characterPosition, setCharacterPosition] = useState([0,0,0])
  const [characterRotation, setCharacterRotation] = useState()
  const [characterDirection, setCharacterDirection] = useState()
  const [movementCorrection, setMovementCorrection] = useState([0,0,0])
  const [cameraTargetPosition, setCameraTargetPosition] = useState([0,0,0])
  const [eulerRotation, setEulerRotation] = useState([0,0,0])
  const [prevCharacterPosition, setPrevCharacterPosition] = useState([0,0,0])



  const lerp = (x, y, a) => x * (1 - a) + y * a;


  //useEffect(() => {})


useEffect(() => {

    if (spawn){
      setInputBlocked(true)
    }else{
      setInputBlocked(false)
    }

    if (spawn && !inAir){
      //console.log('SPAWN')



      setSpawn(false)
      //setTimeout(setSpawn(false), 5000);
    }

  },[spawn, inAir])



const handleVisibilityChange = () => {
    setForward(false)
    setBackward(false)
    setLeft(false)
    setRight(false)
    setJump(false)
    setRun(false)
    setInteract(false)
  };


 
  useEffect(() => {
  
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);

      document.addEventListener('mousemove', handleMouseMove)


      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);

        document.removeEventListener('mousemove', handleMouseMove)

        document.removeEventListener('visibilitychange', handleVisibilityChange);

        setForward(false)
        setBackward(false)
        setLeft(false)
        setRight(false)
        setJump(false)
        setRun(false)
        setInteract(false)
      }
      //

  }, []);


  useEffect(() => {

    //console.log('\n\n\nMETA\n\n\n')
    //console.log(avatarMeta)

    setMeta(avatarMeta)

  },[avatarMeta.model])




  function handleMouseMove(event){
    
        
       
  }





   function handleKeyDown(event){

      let pressedKey = event.key
      pressedKey = pressedKey.toLowerCase()
      
      if (pressedKey=='w' || event.keyCode == '38'){
        setForward(true)
       
      }else if (pressedKey=='s' || event.keyCode == '40'){
      
        setBackward(true)
        
      }else if (pressedKey=='a' || event.keyCode == '37'){
      
        setLeft(true)
        
      }else if (pressedKey=='d' || event.keyCode == '39'){
      
        setRight(true)

      }else if (pressedKey==' '){
        
        setJump(true)
        
        
      }else if (pressedKey=='shift'){
        //console.log('run start')
       
        setRun(true)
      } else if (pressedKey == 'e'){
        setInteract(true)
      }



  }




  function handleKeyUp(event){ 

    

      let pressedKey = event.key
      pressedKey = pressedKey.toLowerCase()
      
      if (pressedKey=='w' || event.keyCode == '38'){
     
        setForward(false)

        
       }else if (pressedKey=='s' || event.keyCode == '40'){
       
        setBackward(false)
        
      }else if (pressedKey=='a' || event.keyCode == '37'){
       
        setLeft(false)

      }else if (pressedKey=='d' || event.keyCode == '39'){
       
        setRight(false)

      }else if (pressedKey==' '){
       
        setJump(false)

      }else if (pressedKey=='shift'){
      
        setRun(false)
      } else if (pressedKey=='e'){

        setInteract(false)
      }

  
  }




  function getDirectionOffset(){

    let directionOffset = Math.PI

    if (forward){

      
      if (left && !right){
        directionOffset = - Math.PI / 2 - Math.PI / 4 
      }else if(right && !left){
        directionOffset = Math.PI / 2 + Math.PI / 4
      }


    }else if (backward){

      
      if (left && !right){
        directionOffset = Math.PI / 4 - Math.PI / 2
      }else if (right && !left){
        directionOffset = - Math.PI / 4 + Math.PI / 2
      }else{
        directionOffset = 0
      }

    }else if (left && right){
        //do nothing
      }

    else if (left){
      directionOffset = - Math.PI / 2
    } else if (right){
      directionOffset = Math.PI / 2
    }

    return directionOffset

  }



  function updateAnimation(newState, isMoving){

    if (typeof(animations) === 'undefined' ||  (animations.constructor === Object &&  !newState in animations) ){
      return
    }

    
    //console.log(animations)

    

    if (currentAction != newState){


      if (newState == 'Fall'){

         
          //console.log('JUMP')

          //console.log(currentAction)

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

          //setInAir(true)

          //inAir = true

        }


        else if ( currentAction == 'Fall' && newState == 'Idle'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

        }

        else if ( currentAction == 'Fall' && newState == 'Walk'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

        }


         else if ( currentAction == 'Fall' && newState == 'Run'){

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

        }



      else if (currentAction == 'Idle' && newState == 'Walk'){
          //console.log('IDLE TO WALK')

           animations[newState].enabled = true
           animations[newState].crossFadeFrom(animations[currentAction], 0.25, true)
           animations[newState].play()
        }

       
        //walk to idle
        else if (currentAction == 'Walk' && newState == 'Idle'){
          //console.log('WALK TO IDLE')

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.25, true)
          animations[newState].play()

        }

        //walk to run
        else if (currentAction == 'Walk' && newState == 'Run'){
          //console.log('WALK TO RUN')

          animations[newState].enabled = true
          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

        }

        //run to walk
        else if (currentAction == 'Run' && newState == 'Walk'){
          //console.log('RUN TO WALK')

          animations[newState].enabled = true

          let ratio = animations[newState].getClip().duration / animations[currentAction].getClip().duration

          animations[newState].time = animations[currentAction].time * ratio

          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

        }

        //ide to run

        else if (currentAction == 'Idle' && newState == 'Run'){
          //console.log('IDLE TO RUN')

          if (isMoving){
            animations[newState].enabled = true

            animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
            animations[newState].play()
          }

          
        }

        //run to idle

        else if (currentAction == 'Run' && newState == 'Idle'){

          //console.log('RUN TO IDLE')

          animations[newState].enabled = true

          animations[newState].crossFadeFrom(animations[currentAction], 0.2, true)
          animations[newState].play()

        }

        else{

          //console.log('UNKNOWN CASE')

          //newState = 'Idle'

          if (newState == 'Run'){
            //console.log('Run')

            animations[newState].enabled = true
            animations[newState].crossFadeFrom(animations[currentAction], 0.5, true)
            animations[newState].play()

          }else{
           // console.log('WTF')

            //console.log(currentAction)
            //console.log(newState)

          }


        }
         

        setCurrentAction(newState)
  }else{
    //do nothing
  }


}


  function getState(isMoving){

    let newState = ''

     if(inAir){

        newState = 'Fall'

        return newState
      }

  
      if (isMoving){
        if(run){

          newState = 'Run'

        }else{

          newState = 'Walk'

        }
      }

      else{
        newState = 'Idle'
      }

      return newState
  }



  function checkCameraCollision(){



    if (rigidRef != null){

      const cameraPosition = camera.position.clone();
      const temp = rigidRef.current.translation()
      const playerPosition = new THREE.Vector3(temp.x, temp.y, temp.z)

      const direction = new THREE.Vector3()
      direction.subVectors(cameraPosition, playerPosition)
      direction.normalize()
      playerPosition.add(new THREE.Vector3(direction.x, direction.y, direction.z))
      const cameraDistance = cameraPosition.distanceTo(playerPosition);
      let ray = new rapier.Ray(playerPosition, direction);
      let maxToi = 15

      let filter = rapier.QueryFilter

      let filterFlags = null 
      let filterGroups = null 
      let filterExcludeRigidBody = null

      let filterPredicate = (collider) => collider._parent.userData !== 'camera_ignore' && collider._parent.userData !== 'character';


      let hit = rapierWorld.castRay(ray, maxToi, true,
        filterFlags, filterGroups, null, filterExcludeRigidBody, filterPredicate
      );

      

      let hit_userData = ''

      try{
        //console.log('1')
        let userData = hit.collider._parent.userData
        hit_userData = userData

        // if (typeof(hit_userData) === 'object' && hit_userData.hasOwnProperty('name')){
        //   hit_userData = userData['name']
        // }

        //console.log(hit_userData)

      }catch{

      }

      //setCameraRayStart(playerPosition)
      //console.log('DIRECTION')
      //console.log(direction)
      //setCameraRayEnd([cameraPosition.x - direction.x, cameraPosition.y - direction.y, cameraPosition.z - direction.z])

      if (hit != null && !hit.collider.isSensor()) {

          let hitPoint = ray.pointAt(hit.toi);

          const intersectionDistance =  playerPosition.distanceTo(hitPoint);

          if (intersectionDistance < cameraDistance) {

            // camera.position.x = hitPoint.x - direction.x * 0.25
            // camera.position.y = hitPoint.y - direction.y * 0.25
            // camera.position.z = hitPoint.z - direction.z * 0.25

            // if (intersectionDistance < 0){
            //   const startUnitVector = direction.clone()
            //   startUnitVector.normalize().multiplyScalar(1.2)

            //   const endPoint =  playerPosition.clone().add(startUnitVector)

            //   camera.position.x = endPoint.x 
            //   camera.position.y = endPoint.y 
            //   camera.position.z = endPoint.z 
               

            // }else{
              camera.position.x = hitPoint.x 
              camera.position.y = hitPoint.y 
              camera.position.z = hitPoint.z 
            //}


            


            //camera.updateProjectionMatrix()

            // if (cameraColliding === false){
            //   setCameraColliding(true)
            //   setCameraDistanceBefore(cameraDistance)
            // }

            return true

            //console.log('HIT')

          }else{

            
            return false
          }
      }

      // if (cameraColliding === true){
      //   setCameraColliding(false)

      //   const startUnitVector = direction.clone()
      //   startUnitVector.normalize().multiplyScalar(cameraDistanceBefore)

      //   const endPoint =  playerPosition.clone().add(startUnitVector)

      //   camera.position.x = endPoint.x 
      //   camera.position.y = endPoint.y 
      //   camera.position.z = endPoint.z 


      // }

      return false

    }

  }


 
  useFrame((state, delta)=>{

    mixer?.update(delta)

    //checkCameraCollision()

    // if (state.clock.elapsedTime < 3){
    //   return
    // }
   
    if(!isLoaded){
      //console.log('INPUT BLOCKED')
      return
    }


    if (inputBlocked && !inAir){
      return
    }


    if (! (typeof(characterRef) !== 'undefined' && typeof(characterRef.current) !== 'undefined' && typeof(characterRef.current.position) !== 'undefined')){
      return
    }

   
    if (rigidRef != null){


      let isMoving = (forward || backward || left || right)

      if ( (left && right && ! (backward && !forward || forward && !backward )) || (forward && backward && ! (left && !right || right && !left)) ){
        isMoving = false
      }


      let newState = getState(isMoving)
      
      let acceleration = 2.5

      if (newState == 'Run'){
        acceleration = 4.5
      }

      updateCharacterMovements(acceleration, delta, isMoving, state)
      updateAnimation(newState, isMoving)
      //console.log(mixer)
      
    }

  })



  function checkFallDown(){

    let correctedMovement = {
      x:characterRef.current.position.x,
      y:characterRef.current.position.y,
      z:characterRef.current.position.z
    }

    //console.log(correctedMovement)

    let corrected = false

    if (correctedMovement.y <= 1){

      //console.log(correctedMovement.y)



      if (correctedMovement.y < -10){

        correctedMovement.x = initialPosition[0]
        correctedMovement.y = initialPosition[1]
        correctedMovement.z = initialPosition[2]


        camera.position.x = initialPosition[0] + 1
        camera.position.y = initialPosition[1] + 0.5
        camera.position.z = initialPosition[2] + 1

        setSpawn(true)

        corrected = true
      
      }else if (new THREE.Vector3(correctedMovement.x, correctedMovement.y, correctedMovement.z).distanceTo(new THREE.Vector3(initialPosition[0], 1, initialPosition[2]) < 4 )){
        correctedMovement.y = 1
        corrected = true
      }

      else if (spawn){
        correctedMovement.y = 1
        corrected = true
      }

    }


    if (corrected){
      rigidRef.current.setTranslation(
        correctedMovement
      )

      characterRef.current.position.x = correctedMovement.x
      characterRef.current.position.y = correctedMovement.y
      characterRef.current.position.z = correctedMovement.z
    }
    


    //console.log('FALL DOWN')

  }



  function updateCharacterMovements(acceleration, delta, isMoving, state){

    if (!isMoving){
      acceleration = 0
      checkCameraCollision()
      
      // try{
      //   checkFallDown()
      // }catch{
      //   console.log('FAILED FALL DOWN CHECK')
      // }
      
    }

  let temp_activeObjects = []
  let temp_bottomActiveObjects = []

  let cur_position = rigidRef.current.translation()
  let angleYCameraDirection = Math.atan2(
           (camera.position.x - cur_position.x),
           (camera.position.z - cur_position.z) 
          )

  let directionOffset = getDirectionOffset()
  let quaternion = new THREE.Quaternion()
  let rotateAngle = new THREE.Vector3( 0, 1, 0 )


  
   quaternion.setFromAxisAngle(rotateAngle, angleYCameraDirection + directionOffset)


    let rot_quat = new THREE.Quaternion(
      rigidRef.current.rotation().x,
      rigidRef.current.rotation().y,
      rigidRef.current.rotation().z,
      rigidRef.current.rotation().w
      )


    let slerp_value = Math.min(delta * 10, 1)

    rot_quat.slerp(quaternion, slerp_value)



    rigidRef.current.setRotation(rot_quat)


    let temp_euler_rotation = new THREE.Euler()
    temp_euler_rotation.setFromQuaternion(rot_quat)

   

    let walkDirection = new THREE.Vector3()
    camera.getWorldDirection(walkDirection)
    walkDirection.y = 0
    walkDirection.normalize()
    walkDirection.applyAxisAngle(rotateAngle, directionOffset)


    setCharacterDirection(walkDirection)


    const temp_characterPosition = new THREE.Vector3(
        rigidRef.current.translation().x,
        rigidRef.current.translation().y,
        rigidRef.current.translation().z
    )

    //console.log(temp_characterPosition)


    let moveX = 0 
    let moveZ = 0 
    let moveY = 0 


    if (movementCorrection[0] !== 0 || movementCorrection[1] !== 0 || movementCorrection[2] !== 0){

      moveX = -movementCorrection[0] 
      moveY = movementCorrection[1]
      moveZ = movementCorrection[2] 

      setMovementCorrection([0,0,0])

    }else{
       moveX = -walkDirection.x * acceleration * delta 
       moveZ = walkDirection.z * acceleration * delta 
    }
   

  //SHAPECAST TO CHECK IF GROUNDED

  let shape = new rapier.Cuboid(0.1, 0.1, 0.1);

  let shapePos = { x: rigidRef.current.translation().x, y: rigidRef.current.translation().y - 1.1, z: rigidRef.current.translation().z};
  //let shapePos = { x: rigidRef.current.translation().x, y: rigidRef.current.translation().y - 1, z: rigidRef.current.translation().z};


  let shapeRot = { w: 1.0, x: 0.0, y: 0.0, z: 0.0 };
  let shapeVel = { x: 0.0, y: -0.1, z: 0.0 };
  let maxToi = 3.2;


  let filterFlags = null
  let filterGroups = null
  let filterExcludeRigidBody = null 
  let filterPredicate = (collider) => collider._parent.userData !== 'character' && !collider.isSensor();


  // let hit = rapierWorld.castRay(ray, maxToi, true,
  //       filterFlags, filterGroups, null, filterExcludeRigidBody, filterPredicate
  //       );



  let hit = rapierWorld.castShape(shapePos, shapeRot, shapeVel, shape, maxToi, true, 
    filterFlags, filterGroups, null, filterExcludeRigidBody, filterPredicate
    );

  //console.log(inAir)

  let temp_lastPoint = [rigidRef.current.translation().x, rigidRef.current.translation().y, rigidRef.current.translation().z]

  let blockJump = false

  let local_inAir = true

  //let local_bounce = bounce

  let temp_speedY = speedY


  if (hit != null && !hit.collider.isSensor()) {

      try{
        //console.log('1')
        let userData = hit.collider._parent.userData
        temp_bottomActiveObjects.push(userData)
        //console.log(userData)
        //console.log('2')

      }catch{

      }

      let toi = hit.toi 

      if (toi > 2){
        moveY = lerp(moveY, moveY - 9.8 * delta, 0.2)
      }else if( toi > 1.5){
        moveY = lerp(moveY, moveY - 9.8 * delta, 0.15)
      }else if (toi > 1){
        moveY = lerp(moveY, moveY - 9.8 * delta, 0.1)
      }else if (toi > 0.5){
        moveY = lerp(moveY, moveY - 9.8 * delta, 0.05)
      }


      else{

        let horizontal = new THREE.Vector3( 0, 1, 0 )
        let normal2 = hit.normal1
        let normal2_vec = new THREE.Vector3(normal2.x, normal2.y, normal2.z)
        let surface_angle = horizontal.angleTo(normal2_vec) * 180 / Math.PI

        if(surface_angle <= MAX_SLOPE_CLIMB_ANGLE && !hit.collider.isSensor()){
          //let temp_moveDir = new THREE.Vector3(walkDirection.x, walkDirection.y, walkDirection.z)
          //temp_moveDir.projectOnPlane(normal2_vec).normalize()
          //let temp_moveDir = new THREE.Vector3(walkDirection.x, walkDirection.y, walkDirection.z)
          
          let temp_moveDir = new THREE.Vector3(-moveX, moveY, moveZ)

          temp_moveDir.projectOnPlane(normal2_vec).normalize()

          moveX = - temp_moveDir.x * acceleration * delta 
          moveZ = temp_moveDir.z * acceleration * delta 

          if (temp_moveDir.y <= 0 && isMoving){
            //moveY = temp_moveDir.y * delta * (-acceleration * 1)

            moveY = temp_moveDir.y * delta * (-acceleration * 1)
          }else if (temp_moveDir.y > 0 && isMoving){
            //moveY = temp_moveDir.y * delta * (-acceleration * 1)

            moveY = temp_moveDir.y * delta * (-acceleration * 1)
          }

        }

      }

      local_inAir = false
      setInAir(false)

  }else{

    moveY -= 9.8 * delta * 2

    local_inAir = true
    //console.log('FALLING')

    if (!inAir){
      setLastGrounded(state.clock.elapsedTime)
    }

    setInAir(true)




    try{

        let userData = hit.collider._parent.userData
        temp_bottomActiveObjects.push(userData)

      }catch{

      }

  }

  

    //console.log(local_inAir)

    //CHECK IF COLLIDES WITH SOMETHING ON THE WAY

    let dir_shape = new rapier.Capsule(0.5, 0.5);
    let dir_shapePos = { x: rigidRef.current.translation().x, y: rigidRef.current.translation().y , z: rigidRef.current.translation().z };
    let dir_shapeRot = { w: 1.0, x: 0.0, y: 0.0, z: 0.0 };
    //let dir_shapeVel = { x: moveX, y: moveY, z: - moveZ };    

    let dir_shapeVel = { x: moveX, y: moveY, z: - moveZ };    
    let dir_maxToi = 2;

    filterExcludeRigidBody = rapierWorld.getRigidBody(rigidRef.current.handle)
    filterFlags = rapier.QueryFilterFlags.EXCLUDE_DYNAMIC
    let filterExcludeCollider = rapierWorld.getCollider(colliderRef.current.handle)
    

    let dir_hit = rapierWorld.castShape(dir_shapePos, dir_shapeRot, dir_shapeVel, dir_shape, dir_maxToi, false, null, null, null, filterExcludeRigidBody, filterPredicate);
    

    //console.log(rapierWorld.castShape)

    let slopeFactor = 1

    let bumpedHead = false

    if (dir_hit != null) {
      let toi = dir_hit.toi

      try{

        let userData = dir_hit.collider._parent.userData
        temp_activeObjects.push(userData)
        
      }catch{
        //ignore
      }
      
     
      if (dir_hit.collider.isSensor()){

        //console.log('SENSOR')
        //this is a sensor
      }else if(true){//surface_angle > MAX_SLOPE_CLIMB_ANGLE){
      
        if (toi < 1){
         if (dir_hit.collider.mass() > 1 || true){

             //console.log(dir_hit)


            let canStep = false
            let obstacle_height = 1 + OFFSET + dir_hit.witness2.y

            // console.log('OBSTACLE HEIGHT')
            // console.log(obstacle_height)
            // console.log(dir_hit.witness2.y)
            
            if (obstacle_height <= MAX_OBSTACLE_HEIGHT && obstacle_height > 0){
              canStep = true
            }

            //console.log(1 + OFFSET + dir_hit.witness2.y)
           

            /*TEST WITH AND WITHOUT STEPPING*/
            if (!canStep){

              //console.log('slide...')

              bumpedHead = true

              let dp = new THREE.Vector2( moveX, - moveZ )
              let normal2 = dir_hit.normal2
              let N = new THREE.Vector2( normal2.x, normal2.z )
              let dot_dp_N = dp.dot(N)
              dp = dp.sub(N.multiplyScalar(dot_dp_N))

              let new_moveX = dp.x 
              let new_moveZ = - dp.y 

  
              let new_dir_shape = new rapier.Capsule(0.5, 0.5);
              let new_dir_shapePos = { x: rigidRef.current.translation().x, y: rigidRef.current.translation().y + OFFSET, z: rigidRef.current.translation().z };
              let new_dir_shapeRot = { w: 1.0, x: 0.0, y: 0.0, z: 0.0 };
              //let dir_shapeVel = { x: moveX, y: moveY, z: - moveZ };    

              let new_dir_shapeVel = { x: new_moveX, y: moveY, z: - new_moveZ };    
              let new_dir_maxToi = 0;

              //let filterExcludeRigidBody = rapierWorld.getRigidBody(rigidRef.current.handle)
              //let filterFlags = rapier.QueryFilterFlags.EXCLUDE_DYNAMIC
              //let filterExcludeCollider = rapierWorld.getCollider(colliderRef.current.handle)
              let new_dir_hit = rapierWorld.castShape(new_dir_shapePos, new_dir_shapeRot, new_dir_shapeVel, new_dir_shape, new_dir_maxToi, false, null, null, null, filterExcludeRigidBody, filterPredicate);
      

              if (new_dir_hit === null || new_dir_hit.collider.isSensor()){
                //console.log(new_dir_hit)
                moveX = new_moveX;
                moveZ = new_moveZ;

              }else{
                  
                // console.log('HERE STOP')

                moveX = 0;
                moveZ = 0;

              }
              


            }else{



                //console.log('CAN I STEP?')
                bumpedHead = true


                let new_dirVect = new THREE.Vector3(moveX, 0, -moveZ)
               

                new_dirVect.normalize()

                new_dirVect.multiplyScalar(0.25)

                let new_moveX = new_dirVect.x
                let new_moveZ = -new_dirVect.z
                let new_moveY = obstacle_height + 0.25


                //if (new_dirVect.length() > 0 && !local_inAir){
                if (new_dirVect.length() > 0){
                 

                let new_dir_shape = new rapier.Capsule(0.5, 0.5);
                let new_dir_shapePos = { x: rigidRef.current.translation().x, y: rigidRef.current.translation().y + OFFSET, z: rigidRef.current.translation().z };
                let new_dir_shapeRot = { w: 1.0, x: 0.0, y: 0.0, z: 0.0 };
                //let dir_shapeVel = { x: moveX, y: moveY, z: - moveZ };    

                let new_dir_shapeVel = { x: new_moveX, y: new_moveY, z: - new_moveZ };    
                let new_dir_maxToi = 0;

                //let filterExcludeRigidBody = rapierWorld.getRigidBody(rigidRef.current.handle)
                //let filterFlags = rapier.QueryFilterFlags.EXCLUDE_DYNAMIC
                //let filterExcludeCollider = rapierWorld.getCollider(colliderRef.current.handle)
                let new_dir_hit = rapierWorld.castShape(new_dir_shapePos, new_dir_shapeRot, new_dir_shapeVel, new_dir_shape, new_dir_maxToi, false, null, null, null, filterExcludeRigidBody, filterPredicate);
        

                  if (new_dir_hit === null || new_dir_hit.collider.isSensor()){

                      //console.log('stepping...')


                      temp_characterPosition.x += new_dirVect.x
                      temp_characterPosition.y += obstacle_height + 0.25
                      temp_characterPosition.z += new_dirVect.z 

                      moveX = 0
                      moveZ = 0
                      moveY = 0

                  }else{
                    //console.log('not stepping 1')
                    moveX = 0
                    moveZ = 0
                    moveY = 0
                  }

                }else{
                  //console.log('not stepping 2')
                  moveX = 0
                  moveZ = 0
                  moveY = 0
              }
            } 
          }
        }
      }
    }


    //setSpeedY( - state.clock.elapsedTime / 10)

    if (!local_inAir){
      temp_speedY = 0
      setAllowedCayote(true)
    }


    if (-temp_speedY > maxFallSpeed ){
      temp_speedY = -maxFallSpeed
    }


    let temp_lastGrounded = lastGrounded
    let coyoteJump = state.clock.elapsedTime - lastGrounded < 0.4

   
    if (jump && !bumpedHead){

      let temp = moveY

      moveY = (temp_speedY) * delta 
      temp_speedY += -9.81 * delta * 1.2

      if ((!local_inAir && allowedJump) || (local_inAir && allowedCayote && coyoteJump)){
        temp_speedY += 7.5
        moveY += 0.4

        
        if (local_inAir && allowedCayote){
          //temp_speedY += 2
        }
        
        setAllowedJump(false)
        setAllowedCayote(false)
      }


      if (!local_inAir && !allowedJump){
        moveY = temp
      }

    }else{
      if (local_inAir){
        setAllowedJump(false)
        moveY = (temp_speedY) * delta 
        temp_speedY += -9.81 * delta * 3
      }
    }


    if (!jump){
      setAllowedJump(true)
    }


    if (bumpedHead && moveY > 0){
      moveY = 0
      temp_speedY = 0

      // console.log('BUMPED HEAD')
    }
    

    setSpeedY(temp_speedY)


    //DETECT INTERACTION SENSORS
    const directionVector = new THREE.Vector3( 0, 0, 1 );
    directionVector.applyQuaternion( rigidRef.current.rotation() );
    directionVector.normalize()
    const directionOrigin = {...cur_position}
    directionOrigin.x += 0.51 * directionVector.x
    directionOrigin.z += 0.51 * directionVector.z
    directionOrigin.y += 0
    //Direction Ray
    const front = { x:0, y: 0, z: -1 }
    const directionRay = new rapier.Ray(directionOrigin, directionVector)


    let filterPredicate_2 = (collider) => collider._parent.userData !== 'camera_ignore' && collider._parent.userData !== 'character' && collider.isSensor();

    //let directionHit = rapierWorld.castShape(dir_shapePos, dir_shapeRot, dir_shapeVel, dir_shape, dir_maxToi, false, null, null, null, filterExcludeRigidBody, filterPredicate);
    let interaction_toi = 1.4

    const directionHit = rapierWorld.castRayAndGetNormal(directionRay, interaction_toi, true, filterPredicate_2)
 
    if (directionHit != null){

      try{
          
          let userData = directionHit.collider._parent.userData

          if (typeof(userData) != 'undefined'){

            if (typeof(userData) == 'object' && 'name' in userData){

              if (userData.name.endsWith('location')){
              
                let location_name = userData.name.split('_location')[0]

                if (location !== location_name){
                  setPrevLocation(location)
                  setLocation(location_name)
                }

              }

              let hitPoint = directionRay.pointAt(directionHit.toi);
              temp_activeObjects.push({name:userData.name, position:hitPoint})
            }else if (typeof(userData) == 'string'){
              let hitPoint = directionRay.pointAt(directionHit.toi);
              temp_activeObjects.push({name:userData, position:hitPoint})
            }
            //temp_activeObjects.push(userData)
          }
          
        }catch{
          //ignore
        }
    }


    //DETECT INTERACTION SENSORS
    const directionVector_2 = new THREE.Vector3( 0, -1, 0 );
    directionVector_2.normalize()
    const directionOrigin_2 = {...cur_position}
    directionOrigin_2.x += 0.51 * directionVector.x
    directionOrigin_2.z += 0.51 * directionVector.z
    const directionRay_2 = new rapier.Ray(directionOrigin_2, directionVector_2)
    let interaction_toi_2 = 1.4

    const directionHit_2 = rapierWorld.castRayAndGetNormal(directionRay_2, interaction_toi_2, true, filterPredicate_2)
 
    if (directionHit_2 != null){

      try{
          
          let userData = directionHit_2.collider._parent.userData

          if (typeof(userData) != 'undefined'){

            if (typeof(userData) == 'object' && 'name' in userData){

              if (userData.name.endsWith('location')){
              
                let location_name = userData.name.split('_location')[0]

                if (location !== location_name){
                  setPrevLocation(location)
                  setLocation(location_name)
                }

              }

              let hitPoint = directionRay_2.pointAt(directionHit_2.toi);
              temp_activeObjects.push({name:userData.name, position:hitPoint})
            }else if (typeof(userData) == 'string'){
              let hitPoint = directionRay_2.pointAt(directionHit_2.toi);
              temp_activeObjects.push({name:userData, position:hitPoint})
            }
            //temp_activeObjects.push(userData)
          }
          
        }catch{
          //ignore
        }
    }



    // console.log('Active Objects:')
    // console.log(temp_activeObjects)
  

    if (temp_activeObjects.length == 0 && activeObjects.length == 0){
      //do nothing
    }else{

      if (temp_activeObjects.toString() != activeObjects.toString()){
        setActiveObjects(temp_activeObjects)
      }  

    }


    if (temp_bottomActiveObjects.length == 0 && bottomActiveObjects.length == 0){
      //do nothing
    }else{

      if (temp_bottomActiveObjects.toString() != bottomActiveObjects.toString()){
        setBottomActiveObjects(temp_bottomActiveObjects)
      }  

    }

    //console.log(delta)
    if (delta > 0.1 && inAir){
      //moveX = 0
      moveY = 0
      //moveZ = 0
    }else if (delta > 0.2 && inAir){
      moveX = 0
      moveY = 0
      moveZ = 0
    }


    let desiredMovement = {
        x: temp_characterPosition.x + moveX,
        y: temp_characterPosition.y + moveY,
        z: temp_characterPosition.z - moveZ
    }
   

    let correctedMovement = desiredMovement


    const delta_x = correctedMovement.x - characterRef.current.position.x
    const delta_y = correctedMovement.y - characterRef.current.position.y
    const delta_z = correctedMovement.z - characterRef.current.position.z


    if (
      correctedMovement.x == characterPosition[0] &&
      correctedMovement.y == characterPosition[1] &&
      correctedMovement.z == characterPosition[2]
    ){
      return
    }


    //console.log(correctedMovement.y)

    if (correctedMovement.y < 0){

      //console.log(correctedMovement.y)



      if (correctedMovement.y < -5){

        correctedMovement.x = initialPosition[0]
        correctedMovement.y = initialPosition[1]
        correctedMovement.z = initialPosition[2]


        camera.position.x = initialPosition[0] + 1
        camera.position.y = initialPosition[1] + 0.5
        camera.position.z = initialPosition[2] + 1

        setSpawn(true)
      
      }else if (new THREE.Vector3(correctedMovement.x, correctedMovement.y, correctedMovement.z).distanceTo(new THREE.Vector3(initialPosition[0], 1, initialPosition[2]) < 4 )){
        correctedMovement.y = 1
      }

      else if (spawn){
        correctedMovement.y = 1
      }

    }


    // if (spawn && correctedMovement.y < -0.5){
    //    correctedMovement.y = 1
    // }


    //console.log(correctedMovement)

    setCharacterRotation(rot_quat)

    setEulerRotation([

      temp_euler_rotation._x,
      temp_euler_rotation._y,
      temp_euler_rotation._z

    ])

    characterRef.current.setRotationFromEuler(temp_euler_rotation)

     
     setCharacterPosition([

        correctedMovement.x,
        correctedMovement.y,
        correctedMovement.z

    ])


    setPrevCharacterPosition(
      characterPosition[0], 
      characterPosition[1], 
      characterPosition[2]
      )

       
    rigidRef.current.setTranslation(
      correctedMovement
    )


    characterRef.current.position.x = correctedMovement.x
    characterRef.current.position.y = correctedMovement.y
    characterRef.current.position.z = correctedMovement.z

    //console.log(correctedMovement)



    updateCameraTarget(characterRef.current.position.x, characterRef.current.position.y, characterRef.current.position.z, delta)
    updateCameraPosition(delta_x, delta_y, delta_z)
    checkCameraCollision()

   
}




function updateCameraPosition(x,y,z){

        camera.position.x += x
        //controls.update();
        camera.position.y += y
        //controls.update();
        camera.position.z += z

        controls.update();


        setCameraTargetPosition([

          camera.position.x,
          camera.position.y,
          camera.position.z

        ])   
}




 function updateCameraTarget(x, y, z, delta) {

   controls.target.x = x
   controls.target.y = y
   controls.target.z = z

  }


  

  return(

    <>

    
    <group>

      <RigidBody 
        ref={rigidRef} 
        type={'kinematicPosition'} 
        position={initialPosition}
        userData={"character"}
      >

        <CapsuleCollider ref={colliderRef} args={[0.5, 0.5]} userData={"character_collider"} />
        </RigidBody>
        </group>

      <group ref={characterRef}>

         {(typeof(characterRef) !== 'undefined' && typeof(characterRef.current) !== 'undefined') ?
          <LocalPlayer 
            offsetY={OFFSET_Y - 0.1}
            characterRef={characterRef}
            characterDirection={characterDirection}
            characterPosition={characterPosition}
            //characterPosition={[characterRef?.current?.position?.x, characterRef?.current?.position?.y + OFFSET_Y, characterRef?.current?.position?.z]} 
            //characterPosition={[characterPosition[0], characterPosition[1] + OFFSET_Y, characterPosition[2]]}
            characterRotation={characterRotation} 
            currentAction={currentAction} 
            meta={meta}
            isLoaded={isLoaded}
            setIsLoaded={setIsLoaded}
          />

          : null}


        <group position={[0, 0 + OFFSET_Y - 0.1, 0]}>

              <mesh visible={false}>
                <sphereGeometry args={[0.5,16,16]} />
                <meshStandardMaterial color={"blue"} />
              </mesh>

              <Suspense>
                <Avatar 
                  identifier={'player'}
                  meta={meta}
                  charGroupRef={charGroupRef} 
                  animationDict={animationDict} 
                  setAnimationDict={setAnimationDict}
                  mixer={mixer}
                  setMixer={setMixer}
                  setAnimations={setAnimations}
                  isLoaded={isLoaded}
                  setIsLoaded={setIsLoaded}
                />
              </Suspense>

          </group>
        </group>


       { typeof(characterRef) !== 'undefined' && typeof(characterRef.current) !== 'undefined' && typeof(characterRef.current.position) !== 'undefined'  ?
         <group position={[0, 0 + OFFSET_Y, 0]}>
          <MovementParticles characterPosition={characterPosition} currentAction={currentAction} />
         </group>
        : null}


    </>

  )
}
export default SimpleController;


