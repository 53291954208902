import { useEffect, useState, useRef, useMemo, useContext, useCallback } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { ErrorBoundary } from "react-error-boundary";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import ValidPairs from './ValidPairs'

import ChosenPair from './ChosenPair'

import ModelAttributes from './ModelAttributes'

import ClayAnimations from './ClayAnimations'

import BodyTextures from './BodyTextures'

import { MainPlayerContext } from 'components/Context/PlayerContext'


import AttributeLoader from './AttributeLoader'


import adjustments from './attribute_adjustments.json'

//import {Sparkles} from '@react-three/drei'

import {MainQualityContext} from 'components/Context/QualityContext'


import {useProgress, Html } from '@react-three/drei'




function useHookWithRefCallback() {
  const ref = useRef(null)
  const setRef = useCallback(node => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }
    
    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
    }
    
    // Save a reference to the node
    ref.current = node
  }, [])
  
  return [setRef]
}




function Loader(props) {

  const setReady = props.setReady

  const { progress } = useProgress()
  
  // console.log(progress)

  if (progress == 100){
    setReady(true)
  }
}



function SkippedAttribute(props){

  const {loadedAttributes, setLoadedAttributes, attribute, clayColor, bodyTextures} = props

  useEffect(() => {

    setLoadedAttributes((prev) => [...prev, attribute] )

  },[attribute])

  return(
    <>

    
    </>
  )

}




function DisplayAvatar(props){

  //console.log('DisplayAvatar')

  //const test = props.test
  //const setTest = props.setTest

 

  //const {avatarMeta, setAvatarMeta, avatarNeedsUpdate, setAvatarNeedsUpdate} = useContext(MainPlayerContext)

  const animationPaths = props.animationPaths

  const isLoaded = props.isLoaded
  const setIsLoaded = props.setIsLoaded

  const clayColor = props.clayColor

  const identifier = props.identifier
  
  const groupRef = props.groupRef

  const meta = props.meta  

  const needsUpdate = props.needsUpdate
  const setNeedsUpdate = props.setNeedsUpdate
  
  const animationDict = props.animationDict  
  const setAnimationDict = props.setAnimationDict
  const mixer = props.mixer 
  const setMixer = props.setMixer
  const setAnimations = props.setAnimations

  const [models, setModels] = useState({})
  const [currentModel, setCurrentModel] = useState()
  const [attributes, setAttributes] = useState([])
  const [loadedAttributes, setLoadedAttributes] = useState([])
  const [assembledModel, setAssembledModel] = useState()
  const [time, setTime] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);
  const [curIndex, setCurIndex] = useState(0)
  const [currentAction, setCurrentAction] = useState()
  //const [needsUpdate, setNeedsUpdate] = useState(false)

  const [bodyTextures, setBodyTextures] = useState({})


  //const [clayColor, setClayColor] = useState(meta.clayColor)
  //const [clayColor, setClayColor] = useState()

  const [ready, setReady] = useState(false)
  const [prevAttributes, setPrevAttributes] = useState([])

  //const [isLoaded, setIsLoaded] = useState(false)

  const [specialRef] = useHookWithRefCallback()


  const [displaySparkles, setDisplaySparkles] = useState(false);


  const {quality} = useContext(MainQualityContext)
  
  const [meshQuality, setMeshQuality] = useState(quality)

  useEffect(() => {
    setMeshQuality(quality)
  },[quality])



  useEffect(() => {

    //console.log('Models')
    //groupRef.current = undefined
    setMixer()
    setCurrentModel()
    setCurrentModel(models)
    

  },[models])



  useEffect(() => {

    // console.log('BODY TEXTUER CHANGE')
    // console.log(bodyTextures)

  },[bodyTextures])



  useEffect(() => {
    //setBodyTextures({})
    //setClayColor()
    setIsLoaded(false)

    if (loadedAttributes.length !== 0){
      setLoadedAttributes([])
    }

    // setTimeout(() => {
    //   if (!isLoaded){
    //     setIsLoaded(true)
    //     setLoadedAttributes([])
    //   }
     
    // }, 2000);

    let toImport = []

    let pairs_dict = meta['model']
    
    if (typeof(pairs_dict) != 'object'){
      return
    }


    /*
    if (!'head' in pairs_dict){
      pairs_dict['head'] = 'Head'
    }

    if (!'trousers' in pairs_dict){
      pairs_dict['trousers'] = 'None'
    }


    console.log('PAIRS DICT')
    console.log(pairs_dict)
    */
    
    let attribute_dict = {}


    let folder_name;

    if (meshQuality === 'High'){
      folder_name = 'GLB2'
    }else{
      folder_name = 'GLB2'
    }
    
    

    Object.keys(pairs_dict).forEach((key) => {

      //console.log('GLB/' + key + '/' + pairs_dict[key] + '/' +  pairs_dict[key] + '.glb')

      if (key === 'hat_and_hair'){
        

        let hat_and_hair = pairs_dict[key]

        let hat = hat_and_hair.split('_and_')[0]
        let hair = hat_and_hair.split('_and_')[1]


        let folder = pairs_dict[key]
        let attribute =  pairs_dict[key]
        //hair.split('&')

        
        if (hair.includes('&')){
          folder = pairs_dict[key].split('&')[0]
          hair = hair.split('&')[1] + '_' + hair.split('&')[0]
        }


        if (hat.includes('&')){
          folder = pairs_dict[key].split('&')[0]
          hat = pairs_dict[key].split('&')[1] + '_' + pairs_dict[key].split('&')[0]
        }
        
        attribute_dict['hat'] = {url: folder_name +'/' + key + '/' + folder + '/hat/' +  hat + '.glb'}

        attribute_dict['hair'] = {url: folder_name +'/' + key + '/' + folder + '/hair/' +  hair + '.glb'}

      }else{

        //pairs_dict[key].split('&')

        let folder = pairs_dict[key]

        let attribute =  pairs_dict[key]

        if (attribute.includes('&')){
          folder = pairs_dict[key].split('&')[0]
          attribute = pairs_dict[key].split('&')[1] + '_' + pairs_dict[key].split('&')[0]
        }

        attribute_dict[key] = {url: folder_name + '/' + key + '/' + folder + '/' +  attribute + '.glb'}

        //console.log('here')
        
      }  

  });


      //check if any adjustments need to be made
      let traits = Object.keys(pairs_dict)

    
      traits.forEach((key) => {

        if (key in adjustments){
          // console.log('adjustments needed')
          // console.log(key)

          let eligible_traits = Object.keys(adjustments[key])

          // console.log(eligible_traits)

          if (eligible_traits.includes(pairs_dict[key])){
            // console.log(pairs_dict[key])

            let temp_traits_to_adjust = adjustments[key][pairs_dict[key]]


            for (let key2 in temp_traits_to_adjust){
              if (traits.includes(key2)){
                 // console.log(temp_traits_to_adjust[key2])


                 let eligible_traits_2 = Object.keys(temp_traits_to_adjust[key2])

                 // console.log(eligible_traits_2)

                 if (eligible_traits_2.includes(pairs_dict[key2])){
                  let cur_adjust_trait = pairs_dict[key2]
                  let cur_adjustment = temp_traits_to_adjust[key2][pairs_dict[key2]]

                  attribute_dict[key2].adjustments = cur_adjustment
                 }

              }
            }

          }

        }

      })


    //console.log(toImport)

    // console.log(attribute_dict)

    //setClayColor(meta.clayColor)

    setModels(attribute_dict)

    

    setNeedsUpdate(false)
    //setAvatarNeedsUpdate(false)

  },[meta, needsUpdate])



  /*
  useEffect(() => {

    console.log('CLAY COLOR')

  },[clayColor])
  */


  
  useEffect(() => {
    

    if (typeof(currentModel) != 'undefined'){
     
      let temp_attributes = []
      for (let key in currentModel){
       
        if (currentModel[key] != 'None'){
          temp_attributes.push(currentModel[key])
        }
        
      }
      
      // console.log(temp_attributes)
      setAttributes(temp_attributes)


      // console.log('ATTRIBUTES')
      // console.log(temp_attributes)
    }

  }, [currentModel])




  function prepareMixer(){

    if (!(groupRef !== undefined && groupRef.current !== undefined && groupRef.current.children !== undefined)){
      return
    }

    // console.log('PREPARE')
  
  //if (typeof(groupRef) != 'undefined' && typeof(groupRef.current) != 'undefined' && groupRef.current.children.length == Object.keys(models).length && typeof(animationDict) != 'undefined'){
     
     //if (typeof(groupRef) != 'undefined' && typeof(groupRef.current) != 'undefined' && groupRef.current.children.length == Object.keys(models).length && typeof(animationDict) != 'undefined'){
        


      try{
        //let required_attributes = avatarMeta.model

        //console.log(currentModel)

        let loaded_urls = []

        Object.keys(currentModel).forEach((key) => {
          loaded_urls.push(currentModel[key].url)
        })

        //console.log(loaded_urls)


      }catch{
        //console.log('ERROR')
      }



      const animGroup = new THREE.AnimationObjectGroup()
    
      for (let i = 0; i < groupRef.current.children.length; i++ ){
        animGroup.add(groupRef.current.children[i])
      }

      const tempMixer = new THREE.AnimationMixer(animGroup)

      let temp_animations = {}

      for (let key in animationDict){

        let cur_animation = animationDict[key] 

        let temp_action = tempMixer.clipAction(cur_animation)

        temp_animations[key] = temp_action

        if (key == 'Idle'){

          temp_action.play()

        }
        
      }

      setAnimations(temp_animations)
      setMixer(tempMixer)


    //}
}




  // Check if all attributes have been loaded
  useEffect(() => {

    prepareMixer()

    // console.log('COMPARE LOADED ATTRIBUTES')
    // console.log(loadedAttributes)
    // console.log(attributes)
    
    if (loadedAttributes.length === attributes.length) {
      // console.log('YES')
      setLoadedAttributes([])
      setIsLoaded(true);
    }else{

      if (loadedAttributes.length > attributes.length){
        let attributeFound = false
        for (let i = 0; i < loadedAttributes.length; i++){
         
          if (attributes.some(e => e.url === loadedAttributes[i].url)) {
            attributeFound = true
          }

        }

        if (attributeFound){
          // console.log('YES')
          setLoadedAttributes([])
          setIsLoaded(true);
        }else{
          // console.log('NO')
        }
        


      }else{
        // console.log('NO')
      }

    }
  }, [loadedAttributes]);

  // Apply animation to all attributes
  useEffect(() => {
     // console.log('IS LOADED?')
     // console.log(isLoaded)


    if (isLoaded) {
      // Apply animation to all attributes


       //setLoadedAttributes([])
      prepareMixer()
    }
  }, [isLoaded]);







  useEffect(() => {

    if (isLoaded){
      setDisplaySparkles(true)
    }

    const interval = setInterval(() => {
      setDisplaySparkles(false);
    }, 3000);

    return () => clearInterval(interval);
  }, [isLoaded]);



return(

    <>

    <Suspense fallback={null}>
   
    {typeof(clayColor) != 'undefined' && clayColor != 'hidden' ?

      <ErrorBoundary fallback={<></>}>

        <BodyTextures identifier={identifier} bodyTextures={bodyTextures} setBodyTextures={setBodyTextures} clayColor={clayColor}/>
     
      </ErrorBoundary>

     : null}

     
      {bodyTextures || clayColor == 'hidden' ?

        <group ref={groupRef} visible={true || isLoaded}> 

          {
            attributes.map((attribute, i) => {
              return(
                <Suspense key={attribute.url} fallback={null}>

                  <ErrorBoundary fallback={
                    <SkippedAttribute 
                      key={attribute.url}
                      attribute={attribute} 
                      loadedAttributes={loadedAttributes} 
                      setLoadedAttributes={setLoadedAttributes} 
                      bodyTextures={bodyTextures}
                      clayColor={clayColor}
                     
                    />
                  }>
                  
                  <AttributeLoader 
                    attribute={attribute} 
                    loadedAttributes={loadedAttributes} 
                    setLoadedAttributes={setLoadedAttributes} 
                    bodyTextures={bodyTextures}
                    clayColor={clayColor}
                  />

                  </ErrorBoundary>

                </Suspense>
              )
            }) 
          }

        </group>

        : null}

       
      <ErrorBoundary fallback={<></>}>
        <ClayAnimations animationPaths={animationPaths}  animationDict={animationDict} setAnimationDict={setAnimationDict} mixer={mixer} setMixer={setMixer} />
      </ErrorBoundary>

      </Suspense>

      {/*
      {displaySparkles  ?

       <Sparkles position={[0,1,0]} count={50} scale={ 1 } size={5} speed={0.3} color='lime' opacity={0.7} />
      
      :null}
      */}
 
    </>
  
  )
}

export default DisplayAvatar;
