import { useEffect, useState, useRef, useMemo, useContext, Suspense } from 'react';
import { Html, ContactShadows, Float, Environment, useGLTF, OrbitControls, PresentationControls, useBoxProjectedEnv, CubeCamera } from '@react-three/drei'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'

import ClayTablet from './ClayTablet'

import ClayPayUI from './ClayPayUI'

import { MainShoppingContext } from 'components/Context/ShoppingContext'
import { MainInterfacesContext } from 'components/Context/InterfacesContext'
import { MainPlayerContext } from 'components/Context/PlayerContext'
import { MainExperienceContext } from 'components/Context/ExperienceContext'

import trousersInfo from '../Trousers/json/trousers_info.json'

import {MainQualityContext} from 'components/Context/QualityContext'

function ClayPay(props){

    const {stake_address, activeWallet,
    clayPayOpen, setClayPayOpen, cartItems, setCartItems, claimCode} = props

    //const {location, setLocation, prevLocation, setPrevLocation} = useContext(MainExperienceContext)
    
    const tablet = useRef()
    const tablet2 = useRef()


    // const {cartItems, setCartItems, reset} = useContext(MainShoppingContext)
    // const {activeInterfaces, setActiveInterfaces} = useContext(MainInterfacesContext)
    // const {inputBlocked, setInputBlocked} = useContext(MainPlayerContext)
    // const {quality} = useContext(MainQualityContext)


    //const [cartItems, setCartItems] = useState([])
    const [quality] = useState('Medium')


    const [subtotal, setSubtotal] = useState(0)
    const {camera, gl, scene} = useThree()
    const [meshes_root_url] = useState('https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStandOpt/Meshes/')
    const [meshQuality, setMeshQuality] = useState(quality)


    useEffect(() => {

      setMeshQuality(quality)

    },[quality])


    useEffect(() => {

      let amount = 0

      for (let i = 0; i < cartItems.length; i++){
        let cur_item = cartItems[i]

        if (cur_item in trousersInfo){
          let cur_info = trousersInfo[cur_item]
          let cur_price = cur_info['price']
          let cur_discount_price = cur_info['discountPrice']   
          
          let isDiscount = false
          if (isDiscount){
            amount += cur_discount_price
          }else{
            amount += cur_price
          }

        }
      }

      setSubtotal(amount)

     }, [cartItems])

    

  return(
    <>
      <PresentationControls
        global
        rotation={[0, 0, 0]}
        speed={2} 
        polar={[-Infinity,  Infinity]} // Vertical limits
        azimuth={[-Infinity, Infinity]} // Horizontal limits
        // snap={{mass: 2, tension: 10}}
      >

      <Float rotationIntensity={ 0.05 }>

      <spotLight angle={1} position={[-10, 20, 5]} intensity={1} />

        <Suspense fallback={null}>
          
          <ClayTablet root_url={`${meshes_root_url}Medium/`} />

          <Html 
            occlude
            //occlude="blending"
            transform
            wrapperClass='htmlUI'
            distanceFactor={1.09}
            position ={[ 0.01, 0.07, 0.01]}
            rotation-x={0}
            rotation-y={-0.0}
            rotation-z={0}
          >

            <ClayPayUI 
              subtotal={subtotal} 
              cartItems={cartItems} 
              setCartItems={setCartItems} 
              stake_address={stake_address}
              activeWallet={activeWallet}


              clayPayOpen={clayPayOpen} 
              setClayPayOpen={setClayPayOpen}

              claimCode={claimCode}

            />

          </Html>

        </Suspense>

        <ContactShadows 
          position-y={ -2 }
          opacity= {0.4}
          blur= {2}
          scale={5}
        />

      </Float>
    </PresentationControls>
    </>
  )
}

export default ClayPay;