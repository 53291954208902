import { useState, useEffect, useContext, useRef } from 'react'
import { css, jsx } from '@emotion/react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AvatarGroup from '@mui/material/AvatarGroup'

import IconButton from '@mui/material/IconButton';

import CircularProgress from '@mui/material/CircularProgress';

import InsightsIcon from '@mui/icons-material/Insights';

import Skeleton from '@mui/material/Skeleton'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Grid from '@mui/material/Grid'

import cn from '../../cn_meta.json'
import gc from '../../gc_meta.json'

import {Buffer} from 'buffer'
import AvatarStack from './AvatarStack'
import {MainClaimContext} from './ClaimContext'

import Loading from './Loading'

import styled from 'styled-components'

import './Leaderboard.css'

import './SearchBar.css'

import './ClaimClay.css'

import './Analytics.css'

const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`



const LeaderBoardTable = styled.table`
        table-layout: fixed;
        width: 100%;
        align-items: center;
        text-align: center;
        z-index: 100;
        border: 5px solid transparent;
        border-radius: 17px;
        border-collapse: separate;
        border-spacing:0 30px;
        padding-top: 25px;
        padding-bottom: 50px;
        overflow: scroll;
        padding-left: 5%;
        padding-right: 5%;

`

const RecordRow = styled.tr`
        color: bisque;
        background-color: rgba(67, 89, 111, 0.9);
        border-collapse: separate;
        border-spacing:0 15px;
        border-radius: 10px;
        align-items: center;
        box-shadow: 0px 0px 24px 3px rgba(125, 249, 226, 0.5);
        backdrop-filter: blur(28px);
`


const RecordCell = styled.td`
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 10px;
        border-radius: 10px;
        align-items: center;
        text-align: center;
    
`



export function RevealOwner(props){

  const asset_id = props.asset_id

  const [currentOwner, setCurrentOwner] = useState('')





  async function viewOwner(asset_id){
  //console.log(asset_id)

  //https://cnholders.com/access/listing/


  let asset_listing_info = await fetch('https://cnholders.com/access/listing/' + asset_id + '/').then(response => response.json())
                                                                  .then(data => data)
  //console.log(asset_listing_info)

  if (asset_listing_info['listing'] == 'no'){
    setCurrentOwner(asset_listing_info['owner'])
  }else{
    setCurrentOwner('JPG.STORE')
  }

}


async function getOwner(){
  //console.log('Getting owner...')


  if (currentOwner == 'JPG.STORE'){

  }
}   


  return(

    <div>
     {currentOwner ? 
    <button className="viewaddress-btn" onClick={() => {window.open(currentOwner == 'JPG.STORE' ? 'https://www.jpg.store/asset/' + asset_id : 'https://pool.pm/' + currentOwner)}}>{currentOwner == 'JPG.STORE' ? 'JPG.STORE' : currentOwner.slice(0,12) +'...'+currentOwner.slice(-12,)}</button>
       :
     <button className="viewaddress-btn" onClick={()=>{viewOwner(asset_id)}}>View Owner</button>
   }
   </div>
  )
}



export function AvatarDisplay(props) {

  const asset = props.asset




  const [imageLoaded, setImageLoaded] = useState(false)



  return (
    <div>
   {!imageLoaded ? <Skeleton variant='circular' style={{ 
          position: 'absolute',
          left: '100px',
          top: '-10px',
          width: '70px',
          height: 'auto'}}> <Avatar style={{width:'70px',height:'70px'}} /> </Skeleton> : null }
    <Avatar style={imageLoaded ? { 
          position: 'absolute', 
          top: '-10px',
          left: '100px',
          width: '70px',
          height: 'auto',
          opacity: '90%'} : { display: 'none' }} onLoad={()=>{setImageLoaded(true)}} alt="clay" src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/240x240/" + asset+'.png'} />
    </div>
  );
}





export function AvatarDisplay2(props) {

  const asset = props.asset




  const [imageLoaded, setImageLoaded] = useState(false)



  return (


<div style={{position:'relative'}}>
    <div style={{position: 'absolute', display:'flex',left: '50px',flexDirection: 'row', justifyContent:'center'}}>
     

   {!imageLoaded ? <Skeleton variant='circular' style={{ 
          position: 'relative',
          top: '-35px',
          width: '70px',
          height: 'auto',
          opacity: '90%'
      }}> <Avatar style={{width:'70px',height:'70px'}} /> </Skeleton> : null }
    <Avatar style={imageLoaded ? { 
          position: 'relative', 
          top: '-35px',
          width: '70px',
          height: 'auto', 
          opacity: '90%'} : { display: 'none' }} onLoad={()=>{setImageLoaded(true)}} alt="clay" src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/240x240/" + asset+'.png'} />
    
    </div>

    </div>
  );
}





export function AvatarDisplayS(props) {

  const asset = props.asset




  const [imageLoaded, setImageLoaded] = useState(false)



  return (


<div style={{position:'relative'}}>
    <div style={{position: 'absolute', display:'flex',left: '50px',flexDirection: 'row', justifyContent:'center'}}>
     

   {!imageLoaded ? <Skeleton variant='circular' style={{ 
          position: 'relative',
          top: '-35px',
          width: '70px',
          height: 'auto',
          opacity: '90%'
      }}> <Avatar style={{width:'70px',height:'70px'}} /> </Skeleton> : null }
    <Avatar style={imageLoaded ? { 
          position: 'relative', 
          top: '-35px',
          width: '70px',
          height: 'auto',
          opacity: '90%'} : { display: 'none' }} onLoad={()=>{setImageLoaded(true)}} alt="clay" src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/240x240/" + asset+'.png'} />
    
    </div>

    </div>
  );
}







function Leaderboard(props) {

 const notify = (message) => toast(message);
 const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
 const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
 const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'5px',width:'42px', height:'42px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
 const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)



  const ada_handle_policy = 'f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a'

  const inputRef = useRef()

  const [clearSearch, setClearSearch] = useState(false)

  const [searchClassName, setSearchClassName] = useState('searchField')

  const [searchVal, setSearchVal] = useState('')

  const [searchPlaceholder, setSearchPlaceholder] = useState('Search')

  const PER_PAGE = 10

  const MAX_RECORD_PAGES = 10

  const [revealedOwners, setRevealedOwners] = useState({})

  const leaderboardData = props.leaderboardData
  const currentSlot = props.currentSlot

  const recordsPage = props.recordsPage
  const setRecordsPage = props.setRecordsPage

  const setLeaderboardData = props.setLeaderboardData

  const setSearchBarMode = props.setSearchBarMode
  const searchBarMode = props.searchBarMode

  const fetchingFilteredAssets = props.fetchingFilteredAssets
  const setFetchingFilteredAssets = props.setFetchingFilteredAssets

  const isAnalytics = props.isAnalytics
  const setIsAnalytics = props.setIsAnalytics

  const isSideBarOpen = props.isSideBarOpen

  const matchesXL = props.matchesXL

  const [recordsToDisplay, setRecordsToDisplay] = useState([])

  const [loadingMoreRecords, setLoadingMoreRecords] = useState(false)
  const [loadingLessRecords, setLoadingLessRecords] = useState(false)
  
  const [isSearching, setIsSearching] = useState(false)



  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const clay_info = {'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728':{collection:'cn',base_name:'ClayNation'}, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768':{collection:'gc',base_name:'ClayCharlotte'}}
  

  const clays_by_collection = {'cn':{policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name:'ClayNation'},'gc':{policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768',base_name:'ClayCharlotte'}}

  const leaderboardPage = props.leaderboardPage
  const setLeaderboardPage = props.setLeaderboardPage


  const matchesS = props.matchesS
  //src={"https://claymates.mypinata.cloud/ipfs/" + asset}

  const testRows = [
    {rank:'1',delta:'up',avatar:'QmRUAWzEAvVNGNqS1pJDFBkPERqcR9QZ22baM4u4CK1PC3', serial:'cn6935'}, 
    {rank:'2',delta:'up',avatar:'QmRUAWzEAvVNGNqS1pJDFBkPERqcR9QZ22baM4u4CK1PC3', serial:'cn6935'}, 
    {rank:'3',delta:'up',avatar:'QmRUAWzEAvVNGNqS1pJDFBkPERqcR9QZ22baM4u4CK1PC3', serial:'cn6935'}, 
    {rank:'4',delta:'up',avatar:'QmRUAWzEAvVNGNqS1pJDFBkPERqcR9QZ22baM4u4CK1PC3', serial:'cn6935'}, 
   
  ]


  const [rows, setRows] = useState(testRows)

  const columns = [
    { id: 'rank', label: '#', minWidth: 10 },
    { id: 'asset', label: 'asset', minWidth: 10 },
    { id: 'serial', label: 'serial', minWidth: 10 },
    { id: 'address', label: 'address', minWidth: 10 },
    { id: 'amount', label: 'amount', minWidth: 10 },
    { id: 'baked', label: 'times baked', minWidth: 10 },
    
  ];

  const rowStyle = { color: 'bisque',  backgroundColor: 'rgba(67, 89, 111, 0.9)',  borderCollapse: 'separate', borderSpacing: '0 15px', bordeRadius: '10px', alignItems:'center',boxShadow:'0px 0px 24px 3px rgba(125, 249, 226, 0.5)', backdropFilter:'blur(28px)'}

  const cellStyle = {
        paddingTop: '7px',
        paddingBottom: '7px',
        
        paddingLeft: '30px',
        paddingLeft: '40px',
        borderRadius: '10px',
        paddingLeft: '30px',
        paddingLeft: '40px',
        borderRadius: '10px',

        alignItems: 'center',
        textAlign: 'center'
  }


  let cellStyle_1 = {...cellStyle, width: '1%', color:'bisque'}
  let cellStyle_2 = {...cellStyle, width: '10%'}
  let cellStyle_3 = {...cellStyle, width: '1%'}
  let cellStyle_4 = {...cellStyle, width: '30%'} 
  let cellStyle_5 = {...cellStyle, width: '20%'}   

  const tableStyle = {
    tableLayout: 'fixed',
    width: '80%',
    alignItems: 'center',
    textAlign: 'center',
    zIndex: '100',
    border: '5px solid transparent',
    borderRadius: '17px',
    borderCollapse: 'separate',
    borderSpacing:'0 30px',
    paddingTop: '50px',
    paddingBottom: '50px'
  }




  const continerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        background: 'rgba(67, 89, 111, 1)',
        border: '2px solid pink',
        borderImage: 'linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%)',
        borderImageSlice: '1',
        boxShadow: '0px 0px 24px 3px rgba(191, 205, 202, 0.3)',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'auto',
        backdropFilter: 'blur(28px)'
  }






const leaderboardStyle = { width: '100%', overflow: 'auto', background: 'rgba(67, 89, 111, 1)', border: '2px solid pink', borderImage: 'linear-gradient(90deg, rgb(146, 204, 236) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 80%)',  borderImageSlice: '1', boxShadow: '0px 0px 24px 3px rgba(191, 205, 202, 0.3)',backdropFilter: 'blur(28px)'}


function prepareRecords(records, slot){

  let preparedRecords = []

  for (let i = 0; i < records.length; i++){
     //console.log(records[i])

     let cur_asset_id = records[i]['asset_id']

     //console.log(cur_asset_id)

     let cur_policy = cur_asset_id.slice(0,56)

     let cur_hex_name = cur_asset_id.slice(56,)

     let cur_name = Buffer.from(cur_hex_name,'hex').toString('utf-8')

     if (cur_policy in clay_info){
        let cur_info = clay_info[cur_policy]

        let cur_collection = cur_info['collection']
        let cur_base_name = cur_info['base_name']

        let cur_serial_num = String(cur_name.slice(cur_base_name.length,))



        let cur_serial = cur_collection + '#' + '0'.repeat( Math.max((4-String(cur_serial_num).length), 0 )) + cur_serial_num

        //console.log(cur_serial)

        let cur_image = ''
        if (cur_collection == 'cn'){
          cur_image = cn[cur_serial_num]
        }else if(cur_collection == 'gc'){
          cur_image = gc[cur_serial_num]
        }

        let cur_daily_rate = records[i]['rate']
        let last_slot = records[i]['slot']

        let cur_processing = records[i]['processing_tx']

        let cur_amount = 0

        if (cur_processing == null){



        let tempSlot = slot


        if (cur_collection == 'cn'){
          if (slot > 134345140){
            tempSlot = 134345140 
          }
        }else if (cur_collection == 'gc'){
          if (slot > 138920740){
            tempSlot = 138920740
          }
        }


        cur_amount = (parseInt(tempSlot) - parseInt(last_slot) - 1209600) * (parseFloat(cur_daily_rate) / 86400)
        cur_amount = cur_amount.toFixed(2)

        if (cur_amount < 0){
          cur_amount = 'baking...'
        }
      }else{
        cur_amount = 'pending...'
      }
        
        let rank_change = ''
        if ('old_rank' in records[i]){
           let old_rank = parseInt(records[i]['old_rank'])
           let cur_rank = parseInt(records[i]['rank'])

           if (old_rank > cur_rank){
            rank_change = 'up'
           }else if(old_rank < cur_rank){
            rank_change = 'down'
           }else{
            rank_change = 'same'
           }
        }

        preparedRecords.push({rank:records[i]['rank'], rank_change:rank_change,serial:cur_serial, image:cur_image, amount:cur_amount, asset_id:cur_asset_id})

     }



     
  }


  /*if (preparedRecords.length < 10){
    for (let i = prepareRecords.length; i <=10; i++){
      preparedRecords.push({rank:"",serial:"", image:"", amount:"", asset_id:""})
    }
  }*/


  //setLoadingRecords(false)
  return preparedRecords

}




function loadMore(){

     setLoadingMoreRecords(true)

     if (leaderboardData && currentSlot){
        //console.log(recordsPage)
        let temp_records = prepareRecords(leaderboardData.slice(recordsPage*PER_PAGE,(recordsPage + 1)*PER_PAGE), currentSlot)
        
        if (temp_records.length > 0){

          //console.log(temp_records)

          setRecordsPage(recordsPage + 1)
          setRecordsToDisplay(temp_records)
        }else{
          setLeaderboardPage(leaderboardPage + 1)
          setRecordsPage(1)
        }
     }

}

function loadLess(){

     setLoadingLessRecords(true)

     if (leaderboardData && currentSlot && (recordsPage >= 2 || leaderboardPage >= 2) ){
        //console.log(recordsPage)
        let temp_records = prepareRecords(leaderboardData.slice((recordsPage - 2)*PER_PAGE,(recordsPage-1)*PER_PAGE), currentSlot)
        


        if (temp_records.length > 0){
          setRecordsPage(recordsPage-1)
          setRecordsToDisplay(temp_records)

        }else{
          if (leaderboardPage > 1){
            setLeaderboardPage(leaderboardPage - 1)
            setRecordsPage(MAX_RECORD_PAGES)
          }

        }

     }

}


function addressToStake(address){
    let temp = serLib.Address.from_bech32(address)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()

    return cur_stake_address
}



useEffect(()=>{
  //console.log('CURRENT SLOT')
  //console.log(currentSlot)
  if (leaderboardData && currentSlot){
    let temp_records = prepareRecords(leaderboardData.slice((recordsPage-1) * PER_PAGE,(recordsPage) * PER_PAGE), currentSlot)

    if (temp_records.length == 0 && recordsPage == MAX_RECORD_PAGES){
      let cur_max_page = parseInt(leaderboardData.length/PER_PAGE)
      temp_records = prepareRecords(leaderboardData.slice((cur_max_page-1) * PER_PAGE,(cur_max_page) * PER_PAGE), currentSlot)
      setRecordsPage(cur_max_page)
      setRecordsToDisplay(temp_records)
    }else{
       setRecordsToDisplay(temp_records)
    }

   
  }

},[leaderboardData])

function getAssetToFilter(asset_str){
  //console.log('parsing asset string')

  let cur_asset_id = ''
  let temp = ''
  let cur_serial = ''
  let cur_collection = ''
  if (asset_str.slice(0,2) == 'cn' || asset_str.slice(0,2) == 'gc'){
    cur_collection = asset_str.slice(0,2)

    if (asset_str.slice(0,3) == 'cn#' || asset_str.slice(0,3) == 'gc#'){
      temp = asset_str.slice(3,)
    }else{
      temp = asset_str.slice(2,)
    }

    //console.log(temp)

    cur_serial = parseInt(temp, 10)

    //console.log(cur_serial)
    //console.log(cur_collection)

    if (cur_collection in clays_by_collection){
      let cur_clay_info = clays_by_collection[cur_collection]
      let cur_base_name = cur_clay_info['base_name']
      let cur_policy_id = cur_clay_info['policy_id']

      cur_asset_id = cur_policy_id + Buffer.from( (cur_clay_info['base_name'] + String(cur_serial)), 'utf-8' ).toString('hex')

      return cur_asset_id


    }else{
      notifyError('Wrong asset input')
    }


  }else if(asset_str.slice(0,1) == '#' || /^-?\d+$/.test(asset_str) ){

      notifyWarn('Please add a prefix \'cn\' or \'gc\' to your search...' )

      return
  }


}

async function makeSearch(searchString){
    setIsSearching(true)

    await performSearch(searchString)

    setIsSearching(false)

}


useEffect(() => {

  setLoadingMoreRecords(false)
  setLoadingLessRecords(false)

}, [recordsToDisplay])


async function performSearch(searchString){

  searchString = searchString.toLowerCase()

  if (searchString.slice(0,1) == '$'){
    let handle = searchString.slice(1,)
      //console.log(handle)

      let hex_handle = Buffer.from(handle).toString('hex')

      //console.log(hex_handle)

      let handle_asset_id = String(ada_handle_policy) + String(hex_handle)

      //console.log(handle_asset_id)

      //find owner address first

      //http://198.199.79.4/filtering/asset/owner/

      let handle_owner = await fetch('https://clayapi.com/filtering/asset/owner/'+handle_asset_id + '/')
      .then((response) => response.json())
      .then((data) => data);


      if ('error' in handle_owner){

        notifyError('Failed to fetch $handle owner')

      }else{
          handle_owner = handle_owner['address']
          //console.log(handle_owner)
          let handle_account;
          try{
            handle_account = addressToStake(handle_owner)
          }catch{
            notifyError('Invalid address')
            return
          }
          //console.log(handle_account)

          //http://198.199.79.4/filtering/account/
          //console.log(currentSlot)


           let fetched_records = await fetch('https://clayapi.com/filtering/account/?page=1&slot='+currentSlot+'&stake_addr='+handle_account + '/')
            .then((response) => response.json())
            .then((data) => data);

          //console.log(fetched_records)

          if ('assets' in fetched_records){
            let assets_owned = fetched_records['assets']

            //setLeaderboardData(assets_owned)
            setSearchBarMode(true)
            let cur_preparedRecords = prepareRecords(assets_owned, currentSlot)
            setRecordsToDisplay(cur_preparedRecords)
          }else{
             notifyError('Have not found what you are looking for...')
          }

      }
      

  } else if (searchString.slice(0,5)=='addr1'){

      let account;
      try{
        account = addressToStake(searchString)
      }catch{
        notifyError('Invalid address')
        return
      }

  let fetched_records = await fetch('https://clayapi.com/filtering/account/?page=1&slot=' + currentSlot + '&stake_addr=' + account + '/')
            .then((response) => response.json())
            .then((data) => data);

          //console.log(fetched_records)

          if ('assets' in fetched_records){
            let assets_owned = fetched_records['assets']

            //setLeaderboardData(assets_owned)
            setSearchBarMode(true)
            let cur_preparedRecords = prepareRecords(assets_owned, currentSlot)
            setRecordsToDisplay(cur_preparedRecords)
          }else{
             notifyError('Have not found what you were looking for...')
          }



  }else if (searchString.slice(0,6)=='stake1'){
    let account = searchString

    let fetched_records = await fetch('https://clayapi.com/filtering/account/?page=1&slot=' + currentSlot + '&stake_addr=' + account + '/')
            .then((response) => response.json())
            .then((data) => data);

          //console.log(fetched_records)

          if ('assets' in fetched_records){
            let assets_owned = fetched_records['assets']

            //setLeaderboardData(assets_owned)
            setSearchBarMode(true)
            let cur_preparedRecords = prepareRecords(assets_owned, currentSlot)
            setRecordsToDisplay(cur_preparedRecords)
          }else{
             notifyError('Have not found what you are looking for...')
          }

    
  }else if(searchString.slice(0,2) == 'cn' || searchString.slice(0,2) == 'gc' || /^-?\d+$/.test(searchString) || searchString.slice(0,1) == '#'){
     let asset_to_fetch = getAssetToFilter(searchString)

     if (typeof(asset_to_fetch) == 'undefined'){
      return
     }

     //198.199.79.4/filtering/asset/
    let fetched_records = await fetch('https://clayapi.com/filtering/asset/?page=1&slot='+currentSlot+'&asset_id='+asset_to_fetch)
      .then((response) => response.json())
      .then((data) => data);

    if ('assets' in fetched_records){
       
         let assets_owned = fetched_records['assets']
         setSearchBarMode(true)
         let cur_preparedRecords = prepareRecords(assets_owned, currentSlot)
         setRecordsToDisplay(cur_preparedRecords)
    }else{
        notifyError('No asset records found...')
       
    }


  }else{
   notifyError('Invalid input!')
  }

}




return (





<div style={{width:'100%'}}>


{/*
<div style={{position:'absolute',top:'50px', right:'-10vw'}}>
*/}

{/*   <Grid container alignItems="center" justifyContent="center">
 

  <Grid item xs={0}>

  </Grid>

  <Grid item xs={12}>

  <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'120px'}}>
    <img className="leaderboard-img" src="/Leaderboard.png" />
   </div>


</Grid>

</Grid>*/}
  


  {/*<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'120px'}}>*/}

  {/*
<div style={{width:'100%',height:'120px'}}>
   <img className="leaderboard-img" src="/Leaderboard.png" />
  </div>


</div>
*/}



<div className={matchesS ? searchClassName : searchClassName.split(' ')[0] + 'Small' + ' ' +searchClassName.split(' ')[1]} style={{marginTop:'150px', marginLeft:'2vw'}}>
    <SearchInput marginLeft={searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? '0px' : '40px'}>
        <input
          id="searching"
          type="text"
          value={searchVal}
          placeholder={searchPlaceholder}
          onChange={(event) => { 
            
            let cur_value = event.target.value
            setSearchVal(cur_value)

            if (cur_value == ''){
              //do nothing
            }else{
               setSearchClassName('searchFieldPressed')
             }

          }}

          onKeyPress={(event) => {

              //console.log(searchVal)

              if (event.key == 'Enter'){
                 makeSearch(searchVal)
              }

            }}

   
          onFocus={() => {

          if (searchVal == ''){
              setSearchClassName('searchField focussed')
          }else{
            //if (clearSearch){
              //setSearchVal('')
              //setClearSearch(false)
           // }else{
              setSearchClassName('searchFieldPressed')
           //}
          }


          }}


          onBlur={() => {

            if (searchVal == ''){

               setSearchClassName('searchField')

            }else{

              setSearchClassName('searchFieldPressed')}

          }}
 
       />
</SearchInput>

<img className="searchicon"  src="/search.png" style={{animation: searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? 'swipeRight 0.4s ease-out forwards' : 'none' }} />

{!isSearching ?
<button onClick={() => {



 
  //console.log(searchVal)

  makeSearch(searchVal)


  //setClearSearch(true)

}} 







style={{position:'relative', zIndex:'250', cursor:'pointer', height:'55px', width:'55px', backgroundColor:'transparent', border:'none', borderRadius:'17px', display: searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? 'block' : 'none'}}>
  <img src="/search.png" style={{height:'50px', width:'auto', marginTop: '2px', animation:'btnAppear 0.4s ease-out forwards'}} />
</button>

: <div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center',height:'50px', width:'60px',zIndex:'250'}}> <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} /> </div>}

</div>



{/*

{recordsToDisplay.length == 0 && !isSearching ?

<div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute',height:'100%', top:'0px',width:'100%', backdropFilter: 'blur(10px)', zIndex:'300'}}>
  <div style={{display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', borderRadius: '18px',width:'80%',  backdropFilter: 'blur(20px)',height:'40%', zIndex:'350'}}>
    <span style={{fontSize: '28px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', color: 'rgba(255, 228, 196, 0.832)'}}>
       Nothing Found
    </span>
  </div>
</div>

: 


isSearching || true ?

<div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute',height:'100%', top:'0px',width:'100%', backdropFilter: 'blur(10px)', zIndex:'300'}}>
  <div style={{position:'sticky',display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', borderRadius: '18px',width:'80%',  backdropFilter: 'blur(20px)',height:'40%', zIndex:'350'}}>
    <Loading isSearching={true} />
  </div>
</div>



:


null}

*/}




<div style={{position:'relative', top:'65px', display:'inline-block', justifyContent:'center', alignItems:'center', marginTop:'-10px'}} >

{(recordsPage >= 2 || leaderboardPage >= 2) && !searchBarMode ?
<button style={{zIndex:'250'}} className="load-more" onClick={() => {

if (!loadingMoreRecords && !loadingLessRecords && !fetchingFilteredAssets){
  loadLess()
}

}}> 


{!loadingLessRecords ?
<span className="load-txt">Previous</span>
: <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} /> 
}

</button>
: <div style={{height:'40px'}}></div>}
</div>



{matchesXL ?

<div style={{width:'100%', height:'0px',position:'relative', top:'50px',display:'flex', zIndex:'250',justifyContent:'right', alignItems:'center', marginLeft:'-20px', marginTop:'-10px'}}>

  <div className={isAnalytics ? "Leaderboard-link" : "Leaderboard-link-selected"}><a onClick={()=>{setIsAnalytics(false)}}> LEADERBOARD </a></div>

  <div className={isAnalytics ? "Analytics-link-selected" : "Analytics-link"}><a onClick={()=>{setIsAnalytics(true)}}> ANALYTICS  </a></div>

</div>

: 


<div style={{width:'100%', height:'0px',position:'relative', top:'50px',display:'flex', zIndex:'250',justifyContent:'right', alignItems:'center', marginLeft:'-5%', marginTop:'-10px'}}>


<div>
  <IconButton onClick={()=>{setIsAnalytics(true)}}>
    <InsightsIcon fontSize="large" style={{ color: '#B3A6FC' }} />
  </IconButton>
</div>

</div>


}







<div className="leaderboard" style={{overflow: isSearching || recordsToDisplay.length == 0 || fetchingFilteredAssets ? 'hidden':'auto'}}>
                



{/*

<LeaderBoardTable>

<thead>

 

</thead>

<tbody>



{recordsToDisplay ? recordsToDisplay.map((record) => {
  return(
  <RecordRow key={record.rank + record.serial}>


    <RecordCell key={'rank' + record.rank + record.serial}>
      {record.rank}
    </RecordCell>



    
    <RecordCell key={'img' + record.rank + record.serial}>
      {record.image != '' ? <AvatarDisplay asset={record.image} /> : null}
    </RecordCell>
    

    <RecordCell key={'serial' + record.rank + record.serial}>
     <div style={{marginLeft:'-130px'}}>
        {record.serial}
      </div>
    </RecordCell>
    

     <RecordCell key={'owner' + record.rank + record.serial}>
     
       {record.asset_id != '' ? <RevealOwner asset_id={record.asset_id} /> : null}
    
      
    </RecordCell>

    <RecordCell key={'amount' + record.rank + record.serial}>
      {record.amount == 'baking...' || record.amount == 'pending...' ?

      record.amount

      : 

      record.amount == '' ? null :

      <Stack
  direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={0.5}
>
               <Avatar
            alt="Clay Image"
            src='/CLAY_TOKEN_220x220.png'
            sx={{ width: 22, height: 22, marginBottom: '1px', marginRight: '0px' }}
             />

              <div>{record.amount}</div>
     </Stack>


    }
    </RecordCell>


  </RecordRow>


  )

})


: null

}


</tbody>



</LeaderBoardTable>



*/}




{recordsToDisplay.length == 0 && !isSearching && !fetchingFilteredAssets ?

<div className="blured-background" style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute',height:'100%', top:'0px',width:'100%', backdropFilter: 'blur(10px)', zIndex:'300'}}>
  <div style={{display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', borderRadius: '18px',width:'80%',  backdropFilter: 'blur(20px)',height:'40%', zIndex:'350'}}>
    <span style={{fontSize: '28px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', color: 'rgba(255, 228, 196, 0.832)'}}>
       Nothing Found
    </span>
  </div>
</div>

: 


isSearching ?

<div className="blured-background" style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute',height:'100%', top:'0px',width:'100%', backdropFilter: 'blur(10px)', zIndex:'300'}}>
  <div style={{position:'relative',display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', borderRadius: '18px',width:'80%',  backdropFilter: 'blur(20px)',height:'40%', zIndex:'350'}}>
    <Loading isSearching={true} />
  </div>


</div>


:

fetchingFilteredAssets ?


<div className="blured-background" style={{display:'flex', justifyContent:'center', alignItems:'center', position:'absolute',height:'100%', top:'0px',width:'100%', backdropFilter: 'blur(10px)', zIndex:'300'}}>
  <div style={{position:'relative',display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', borderRadius: '18px',width:'80%',  backdropFilter: 'blur(20px)',height:'40%', zIndex:'350'}}>
    <Loading isFetching={true} />
  </div>
</div>


:

null}



<LeaderBoardTable>

<thead>

{matchesS ?
  <tr style={{justifyContent:'center', alignItems:'center'}}>
    <th style={{ width:'55px'}}></th>
    <th style={{width:'5px'}}></th>
    <th style={{width:'150px'}}></th>
    <th style={{ width:'150px'}}></th>
    <th style={{width:'150px'}}></th>
    <th style={{width:'250px'}}></th>
  </tr>
 :

   <tr style={{justifyContent:'center', alignItems:'center'}}>
    <th style={{width:'55px'}}></th>
    <th style={{width:'5px'}}></th>
    <th style={{width:'150px'}}></th>
    <th style={{ width:'150px'}}></th>
    <th style={{width:'150px'}}></th>
    <th style={{width:'250px'}}></th>
  </tr>


}

</thead>

<tbody>



{recordsToDisplay ? recordsToDisplay.map((record) => {
  return(
  <RecordRow key={record.rank + record.serial}>


    <RecordCell key={'rank' + record.rank + record.serial}>
      {record.rank}
    </RecordCell>

    
    <RecordCell key={'rank_change' + record.rank + record.serial}>
      {record.rank_change == '' ? null :

      <img src={'/'+record.rank_change +'.svg'} style={{height:'10px',  width:'10px'}} /> 
    }
    </RecordCell>
  


    
    <RecordCell key={'img' + record.rank + record.serial}>
      {record.image != '' && matchesS ? <AvatarDisplay2 asset={record.image} /> : record.image != '' && !matchesS ? <AvatarDisplayS asset={record.image} />   : null}
    </RecordCell>
    

    <RecordCell key={'serial' + record.rank + record.serial}>
     <div style={{}}>
        {record.serial}
      </div>
    </RecordCell>
    

     <RecordCell key={'owner' + record.rank + record.serial}>
     
       {record.asset_id != '' ? <RevealOwner asset_id={record.asset_id} /> : null}
    
      
    </RecordCell>

    <RecordCell key={'amount' + record.rank + record.serial}>
      {record.amount == 'baking...' || record.amount == 'pending...' ?

      record.amount

      : 

      record.amount == '' ? null :

      <Stack
  direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={0.5}
>
               <Avatar
            alt="Clay Image"
            src='/CLAY_TOKEN_220x220.png'
            sx={{ width: 22, height: 22, marginBottom: '1px', marginRight: '0px' }}
             />

              <div>{record.amount}</div>
     </Stack>


    }
    </RecordCell>


  </RecordRow>


  )

})


: null

}


</tbody>


</LeaderBoardTable>



{/*
<table style={{width:'100%', marginTop:'80px', marginBottom:'80px'}}>

<thead>



</thead>

<tbody>



{recordsToDisplay ? recordsToDisplay.map((record) => {
  return(
  <tr key={record.rank + record.serial}>


    <td key={'rank' + record.rank + record.serial}>
      {record.rank}
    </td>



    
    <td key={'img' + record.rank + record.serial}>

      <img src={"https://claymates.mypinata.cloud/ipfs/" + record.image+'?img-width=240&img-height=240&img-fit=scale-down'} style={{width:'60px',height:'60px'}} />
  
    </td>
    

    <td key={'serial' + record.rank + record.serial}>
     <div style={{}}>
        {record.serial}
      </div>
    </td>
    

     <td key={'owner' + record.rank + record.serial}>
     
       {record.asset_id != '' ? <RevealOwner asset_id={record.asset_id} /> : null}
    
      
    </td>

    <td key={'amount' + record.rank + record.serial}>
      {record.amount == 'baking...' || record.amount == 'pending...' ?

      record.amount

      : 

      record.amount == '' ? null :

      <Stack
  direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={0.5}
>
               <Avatar
            alt="Clay Image"
            src='/CLAY_TOKEN_220x220.png'
            sx={{ width: 22, height: 22, marginBottom: '1px', marginRight: '0px' }}
             />

              <div>{record.amount}</div>
     </Stack>


    }
    </td>


  </tr>


  )

})


: null

}


</tbody>



</table>


*/}


</div>

{recordsToDisplay.length == PER_PAGE && !searchBarMode ? 


/*<button className="load-more" style={{position:'absolute', bottom:'20px', left:'45%', cursor:'pointer', zIndex:'200'}}  onClick={() => {loadMore()}}><span className="load-txt">Load More</span></button>
*/

<div style={{position:'relative', bottom:'65px', display:'inline-block', justifyContent:'center', alignItems:'center'}} >
  <button style={{position:'sticky', zIndex:'250'}} className="load-more" onClick={() => {
    if(!loadingMoreRecords && !loadingLessRecords && !fetchingFilteredAssets){
      loadMore()
    }
  }}>

{!loadingMoreRecords ?
  <span className="load-txt">Load More</span>
: <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} /> }

  </button>
</div>

: null}

</div>

  );
}

export default Leaderboard;