import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';


import {MainClaimContext, Provider} from 'components/ClaimContext'

import * as THREE from "three";

import { MainInterfacesContext } from 'components/Context/InterfacesContext'

//import {MainSceneContext} from '../../Context/SceneContext'

import HDRLoader from 'components/Virtual/Loaders/HDRLoader'

import ClayPay from './ClayPay'

function ClayPayScene(props){

  const {clayPayOpen, setClayPayOpen, cartItems, setCartItems} = props

  //const {activeInterfaces, setActiveInterfaces} = useContext(MainInterfacesContext)

  const {stake_address, activeWallet} = useContext(MainClaimContext)


  return(
    <>
    

     <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0)',
        display:'flex', justifyContent:'center', alignItems:'center', zIndex:'1002'
      }}>


        <div style={{width:'100%', height:'100%', backgroundColor:'rgba(1,1,1,0.5)', zIndex:'100'}}>
          
          <Canvas 
            shadows
            camera={{ 
              fov: 50,
              near: 0.1,
              far: 1000,

              position: [0,0,3.2],
            }}


            style={{touchAction: 'none'}}
          >


           <HDRLoader url={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/HDRI/TestHDRI.HDR'} hideBackground={true} /> 

          <Suspense>
            <ClayPay stake_address={stake_address} activeWallet={activeWallet} 
              clayPayOpen={clayPayOpen} setClayPayOpen={setClayPayOpen}
              cartItems={cartItems} setCartItems={setCartItems}
            /> 
          </Suspense>
         
         </Canvas>

       </div>
     </div>


    </>
  )
}

export default ClayPayScene;