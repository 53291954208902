
import styled from "styled-components";
import twitter from "./Images/socials/twitter.png";
import discord from "./Images/socials/discord.png";
import instagram from "./Images/socials/instagram.png";
import youtube from "./Images/socials/youtube.png";
import spotify from "./Images/socials/spotify.png";
import apple from "./Images/socials/apple.png";



const FooterContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
position: relative;
text-align: center;
justify-content: center;
font-family: 'ClayFontJika';
`;

const LinksContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 2rem;
`;


const Link = styled.a`
color: var(--claytext-primary);
text-decoration: none;
font-size: 16px;
cursor: pointer;
transition: 0.3s;
&:hover {
    color: var(--clayblue-primary);
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
  }
`;

const Social= styled.img`
max-width: 3rem;
width: 3vw;
margin-top: 1rem;
padding-bottom: 10rem;
cursor: pointer;
filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
&:hover {
    color: var(--claypink);
    filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.9));
  }
`;

const H2 =  styled.h1 `
font-size: clamp(0.8vw, 2vw, 1.8rem);
color: var(--claytext-primary);
font-family: 'ClayFontJika';
white-space: nowrap;
font-weight: 100;
letter-spacing: 0.2rem;
text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.4);
`;

const Footer = () => {
    return (
        <FooterContainer>
            <H2 style={{fontSize:'24px'}}>POWERED BY C SPACE LIMITED</H2>
            <LinksContainer>
                <Link href="/privacypolicy">PRIVACY POLICY</Link>
                <Link href="/termsandconditions">TERMS & CONDITIONS</Link>
                <Link href="/cookiepolicy">COOKIES</Link>
            </LinksContainer>
            <LinksContainer>
                <Link href="https://twitter.com/claymates" target="_blank" >
                    <Social src={twitter} />
                </Link>
                <Link href="https://discord.gg/claymates" target="_blank" >
                    <Social src={discord}/>
                </Link>
                <Link href="https://www.instagram.com/the_claymates/" target="_blank" >
                    <Social src={instagram}/>
                </Link>
                <Link href="https://open.spotify.com/artist/1K0eNqY4OYa1WkxdBabO0k?si=lS-A_YzQSq2O-N1OKN2-Gw&nd=1" target="_blank" >
                    <Social src={spotify}/>
                 </Link>
                <Link href="https://www.youtube.com/channel/UCE2hX-yGE7ejMcjdUXxAIJQ" target="_blank" >
                    <Social src={youtube}/>
                </Link>
                <Link href="https://music.apple.com/be/album/clay-nation-tapes-vol-i-single/1645776274" target="_blank" >
                    <Social src={apple}/>
                </Link>
            </LinksContainer>
        </FooterContainer>
    );
}

export default Footer;