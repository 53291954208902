import {useState, useContext} from 'react'
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs'
import {Buffer} from 'buffer'

import '../WalletLink.css'
import './Spinner.css'

import * as web3 from 'web3';

import {MainLinkingContext} from './Context/LinkingContext'

const NETWORK_ID = "137"

//const NETWORK_ID = "80001"

const MetamaskIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/metamask.png'

function PolygonConnect(props) {

  const {setActiveStep, toastMessages} = props

  const {polygonAddress, setPolygonAddress, setPolygonSecret_1, setPolygonSecret_2} = useContext(MainLinkingContext)


  const [activeWallet, setActiveWallet] = useState('metamask')
  const [activeAddress, setActiveAddress] = useState('')

  const [isVerifying, setIsVerifying] = useState(false)

  const hexToDecimal = hex => parseInt(hex, 16);


  async function signMessage(polygon, address, message){
      const from = address
      const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
      const sign = await polygon.request({
        method: 'personal_sign',
        params: [msg, from],
      });

      return sign
  }



  async function fetchSecret(address){

    let response = await fetch(`https://clayapis.com/polygon/secret/${address}/`).then(response => response.json())
                                                                  .then(data => data)
    return response                                                             
  }



  async function postSignature(signature, message){

    const data = {'signature':signature, 'message': message}
      const response = await fetch('https://clayapis.com/polygon/link/', {
          method: 'POST',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      })

      return response.json()

  }



  async function verifyOwnership(polygon, address){
    let secretResponse = await fetchSecret(address)
    
    if (secretResponse.hasOwnProperty('secret') && secretResponse.hasOwnProperty('time')){
      let secret_1 = secretResponse.secret
      let time_1 = secretResponse.time 

      let message = 'Proving ownership of the wallet by signing this message: ' + secret_1
      //let message = secret_1

      

      let signature = await signMessage(polygon, address, message)

      setIsVerifying(true)

      //message = `0x${Buffer.from(message, 'utf8').toString('hex')}`;

      let signedResponse = await postSignature(signature, message)

      if (signedResponse.hasOwnProperty('secret') && signedResponse.hasOwnProperty('time')){
        
        let secret_2 = signedResponse.secret
        let time_2 = signedResponse.time 

        //if we are here then success!
        setPolygonSecret_1(secret_1)
        setPolygonSecret_2(secret_2)

        setActiveStep(2)

      }else{
        //handle error here 2
        toastMessages.notifyError('Could not verify ownership')
        setIsVerifying(false)
      }


    }else{
      //handle error here 1
      toastMessages.notifyError('Could not verify ownership')
      setIsVerifying(false)
    }     
  }



  async function enableWallet(wallet){

    try{
      let polygon = window.ethereum
      let requestedAcoounts = await polygon.request({ method: "eth_requestAccounts" });
      let cur_networkVersion = polygon.networkVersion

      if (cur_networkVersion !== NETWORK_ID){
        toastMessages.notifyWarn('Wrong Network!')
        return
      }

      let walletAddress = polygon.selectedAddress

      setPolygonAddress(walletAddress)

      try{
        await verifyOwnership(polygon, walletAddress)
      }catch{
        //handle error
        toastMessages.notifyError('Could not verify ownership')
        setIsVerifying(false)
      }

    }catch{
      //handle error
      toastMessages.notifyWarn('Could not connect')

    }
    
  }


  async function walletConnect(wallet){

    enableWallet(wallet)

  }


  return (
    <>

      {/*<h2 className="connect-heading heading-cardano-wallets">
          <div style={{width:'80%'}}>
            Connect your Polygon wallet
          </div>
        </h2>*/}

     <h2 className="connect-heading heading-cardano-wallets">
           <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
              <div style={{width:'80%', fontSize:'30px'}}>
                 CONNECT YOUR POLYGON WALLET
              </div>
            </div>
      </h2>
      
      <div style={{ width: "100%", height: "180px", backgroundColor: "", display:'flex', justifyContent:'center', alignItems:'center' }}>
        

       {!isVerifying ?

          <button onClick={() => {
            walletConnect('metamask')
          }} className='Polygonbtn'><img src={MetamaskIcon} style={{width:'40px', height:'auto', marginRight:'0px', marginTop:'0px', marginLeft:'10px'}}/>CONNECT METAMASK</button>
       
        :

        <div style={{height:'200px', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                
                  <div style={{backgroundColor:'', height:'150px', width:'120px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <div 
                      id="spinner" 
                      className="spinnerCardano"
                      style={{backgroundColor:''}}
                    >  
                    </div>
                  </div>

                </div>

      }

      </div>

    </>
  );
}

export default PolygonConnect;
