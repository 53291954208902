import { useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import './Staking.css'
import './StakingView.css'

import './SearchBar.css'

import './StakingSideBar.css'

import ReactEcharts from "echarts-for-react"

import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {MainClaimContext} from './ClaimContext'


const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`

function Staking(props) {

  const clay_pool_id = 'pool1qgmwcxs8d8qmr0vtvsqd9u8gugrventh5dcqukmu79e2sgfuu8t'
  const ada_handle_policy = 'f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a'
  const clay_asset_id = '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535434c4159'

  const cn_policy = '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728'
  const gc_policy = '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768'

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'5px',width:'42px', height:'42px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

  const stakedWithClay = props.stakedWithClay
  const stakeADAAmount = props.stakeADAAmount
  const formattedClayAmount = props.formattedClayAmount
  const totalUnbaked = props.totalUnbaked
  const assetsOwned = props.assetsOwned

  const currentSlot = props.currentSlot

  const matchesM = props.matchesM
  const matchesS = props.matchesS

  const isSideBarOpen = props.isSideBarOpen

  const claimStake = props.claimStake
  const setClaimStake = props.setClaimStake


  const [userInfo, setUserInfo] = useState({})
  const [stakingInfo, setStakingInfo] = useState({})
  //const [poolInfo, setPoolInfo] = useState({})


  const [adaShare, setAdaShare] = useState()
  const [clayShare, setClayShare] = useState()
  const [unbakedClayShare, setUnbakedClayShare] = useState()


  const [stakingViewInfo, setStakingViewInfo] = useState() 

  const [fromAda, setFromAda] = useState()
  const [fromClay, setFromClay] = useState()
  const [fromUnbakedClay, setFromUnbakedClay] = useState()
  const [totalRewards, setTotalRewards] = useState()

  const [timesClaimed, setTimesClaimed] = useState()
  const [epochsStaked, setEpochsStaked] = useState()

  const [liveStake, setLiveStake] = useState()
  const [pledge, setPledge] = useState()
  const [margin, setMargin] = useState()
  const [delegations, setDelegations] = useState()

  const [poolClay, setPoolClay] = useState()
  const [poolUnbakedClay, setPoolUnbakedClay] = useState()

  const [adaStaked, setAdaStaked] = useState()
  const [unbakedClayStaked, setUnbakedClayStaked] = useState()
  const [clayStaked, setClayStaked] = useState()

  const [adaRatio, setAdaRatio] = useState()
  const [clayRatio, setClayRatio] = useState()
  const [unbakedClayRatio, setUnbakedClayRatio] = useState()

  const [maxRewards, setMaxRewards] = useState()

  const [epoch, setEpoch] = useState()
  const [epochEnd, setEpochEnd] = useState()

  const [viewWidth, setViewWidth] = useState(0)
  const viewRef = useRef(null)

  const [compactView, setCompactView] = useState(false)




  const [clearSearch, setClearSearch] = useState(false)

  const [searchClassName, setSearchClassName] = useState('searchField')

  const [searchVal, setSearchVal] = useState('')

  const [searchPlaceholder, setSearchPlaceholder] = useState('Search')

  const [isSearching, setIsSearching] = useState(false)

  const [searchedStakeAddr, setSearchedStakeAddr] = useState(false)

  const [searchedStaked, setSearchedStaked] = useState(false)

  const [searchedBalance, setSearchedBalance] = useState({lovelace:0, clay:0, unbaked_clay:0})

  useEffect(() => {
    //setViewWidth(viewRef.current.clientWidth)
    setViewWidth(viewRef.current.clientWidth)
    setCompactView(viewRef.current.clientWidth < 800)
  })


async function getStakingInfo (stake_address) {
      let staking_info = await fetch('https://clayapi.com/staking/' + stake_address + '/')
        .then(response => response.json())
        .then(data => data);

    //console.log(staking_info)
    return staking_info
}


useEffect(() => {

async function updateStakingInfo(){
  if (searchedStakeAddr){
    //await getAmountsStaked(searchedStakeAddr)
    let stake_info = await getStakingInfo(searchedStakeAddr)
    
    if ('pool_id' in stake_info){
      let pool_id = stake_info['pool_id']
      
      if (pool_id == clay_pool_id){
        setSearchedStaked(true)
      }else{
        setSearchedStaked(false)
      }
    }
  }
}

updateStakingInfo()

}, [searchedStakeAddr, searchedBalance])





async function getAmountsStaked(cur_stake_addr){


  let fetched_records = await fetch('https://clayapi.com/owned/'+cur_stake_addr + '/')
            .then((response) => response.json())
            .then((data) => data);


  let assetsInfo = await fetch('https://clayapi.com/baking/wallet/'+cur_stake_addr + '/')
            .then((response) => response.json())
            .then((data) => data);


  /*
  console.log(fetched_records)
  console.log(assetsInfo)
  */

  let temp_lovelace = 0 
  let temp_clay = 0
  let temp_unbaked_clay = 0

  if ("error" in fetched_records){
    //do nothing
  }else{


  let cur_assets = fetched_records['assets']



  let temp_clay_assets = []

  for (let i = 0; i < cur_assets.length; i++){

    let cur_asset = cur_assets[i]

    if (cur_asset["unit"] == 'lovelace'){
      temp_lovelace += parseInt(cur_asset['quantity'], 10)
    }else if (cur_asset["unit"] == clay_asset_id){
      temp_clay += parseInt(cur_asset['quantity'], 10)
    }

  }


    temp_clay = Math.round(temp_clay / 10000)

    /*
    console.log('LOVELACE')
    console.log(temp_lovelace)

    console.log('CLAY')
    console.log(temp_clay)
    */
  }



  if ("error" in assetsInfo){
    //do nothing
  }else{

  let cur_assets_info = assetsInfo['assets']
  for (let key in cur_assets_info){
    let cur_asset_info = cur_assets_info[key]
    let cur_bake_slot = parseInt(cur_asset_info['slot'], 10)
    let cur_bake_rate = parseFloat(cur_asset_info['rate']) / 86400
    let cur_slot_diff = (currentSlot - cur_bake_slot) - 1209600

    if (cur_slot_diff < 0){
      continue
    }

    let cur_rewards = cur_slot_diff * cur_bake_rate 

    cur_rewards = cur_rewards.toFixed(0)

    /*
    console.log('REWARD')
    console.log(cur_rewards)
    */

    temp_unbaked_clay += parseInt(cur_rewards)

  }

  /*
  console.log('UNBAKED CLAY')
  console.log(temp_unbaked_clay)
  */

  }

  setSearchedBalance({lovelace:temp_lovelace, clay:temp_clay, unbaked_clay:temp_unbaked_clay})

  return fetched_records
}


function addressToStake(address){
    let temp = serLib.Address.from_bech32(address)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()

    return cur_stake_address
}


async function performSearch(searchString){
  //console.log(searchString)

  searchString = searchString.toLowerCase()

  let cur_stake_addr = ''

  if (searchString.slice(0,1) == '$'){
    let handle = searchString.slice(1,)
      let hex_handle = Buffer.from(handle).toString('hex')
      let handle_asset_id = String(ada_handle_policy) + String(hex_handle)
      let handle_owner = await fetch('https://clayapi.com/filtering/asset/owner/'+handle_asset_id + '/')
      .then((response) => response.json())
      .then((data) => data);

      if ('error' in handle_owner){
        notifyError('Failed to fetch $handle owner')
      }else{
          handle_owner = handle_owner['address']
          //console.log(handle_owner)
          let handle_account;
          try{
            cur_stake_addr = addressToStake(handle_owner)
            //setSearchedStakeAddr(cur_stake_addr)
            //console.log(cur_stake_addr)
          }catch{
            notifyError('Invalid address')
            return
          }
      }
    }else if(searchString.slice(0,5) == 'addr1'){
      //console.log('ADDR')

      try{
          cur_stake_addr = addressToStake(searchString)
          //setSearchedStakeAddr(cur_stake_addr)
          //console.log(cur_stake_addr)
      }catch{
          notifyError('Invalid address')
          return
      }

    }else if (searchString.slice(0,6) == 'stake1'){
      //console.log('STAKE')
      cur_stake_addr = searchString
      
    }else{
      return
    }



    await getAmountsStaked(cur_stake_addr)
    setSearchedStakeAddr(cur_stake_addr)

}

async function makeSearch(searchString){
    setIsSearching(true)

    await performSearch(searchString)

    setIsSearching(false)

}





 function getEpochEnd(time_end){


    if (!time_end){
      return
    }

    let now = Date.now()
    let time_dif = time_end - (now/1000)
    if (time_dif <= 60*60){
      if (time_dif <= 60){
        if (time_dif > 0){
           return '<1 minute left'
         }else{
          return 'epoch ended'
         }
       
      }else{
       return String(Math.floor(time_dif/60+1))+' minutes left'
      }
    }else if (time_dif <= 60*60*24){
      if (time_dif <= 60*60){
        return '<1 hour left'
      }else{
        let full_hours = Math.floor(time_dif/60/60)
        return String(full_hours) +'h ' + String(Math.floor((time_dif-full_hours*60*60)/60+1))+'m left'
      }
    }else{
      let full_days = Math.floor(time_dif/60/60/24)
      let full_hours = Math.floor((time_dif - full_days * 60*60*24)/60/60)
      let full_minutes = Math.floor((time_dif - full_days * 60*60*24 - full_hours * 60 * 60)/60+1)
      return String(full_days) + 'd ' + String(full_hours) + 'h ' + String(full_minutes) + 'm left'
    }

  }


  async function getStakingViewInfo(){
        

        let temp_stake_addr;

        if (searchedStakeAddr){
          temp_stake_addr = searchedStakeAddr
        }else{
          temp_stake_addr = stake_address
        }

        if (temp_stake_addr==''){
          temp_stake_addr = 'test'
        }

        let temp_stakingViewInfo;

        try{

          temp_stakingViewInfo = await fetch('https://clayapi.com/staking/view/'+temp_stake_addr+'/').then(response => response.json())
                                                                .then(data => data)

          //console.log(temp_stakingViewInfo)
        }catch{
          console.log('STAKE UPDATE ERROR')
          return
        }
        //console.log(temp_stakingViewInfo)                                                          

        setStakingViewInfo(temp_stakingViewInfo)
        //setUserInfo(stakingViewInfo.user_info)
        //setStakingInfo(stakingViewInfo.staking_info)
        //setPoolInfo(temp_stakingViewInfo.pool_info)
        setFromAda(temp_stakingViewInfo.user_info['from_ada'])
        setFromClay(temp_stakingViewInfo.user_info['from_clay'])
        setFromUnbakedClay(temp_stakingViewInfo.user_info['from_unbaked_clay'])
        setTotalRewards(temp_stakingViewInfo.user_info['total_rewards'])
        setEpoch(temp_stakingViewInfo.user_info['epoch'])
        setEpochEnd(temp_stakingViewInfo.user_info['epoch_end'])
        setTimesClaimed(temp_stakingViewInfo.user_info['times_claimed'])
        setEpochsStaked(temp_stakingViewInfo.user_info['epochs_staked'])
        //console.log(stakingViewInfo)
 }


async function setPoolInfo(){
  setLiveStake(displayNumber(Math.round(stakingViewInfo.pool_info['live_stake']/1000000)))
  setPledge(stakingViewInfo.pool_info['declared_pledge']/1000000/1000)
  setMargin(stakingViewInfo.pool_info['margin_cost']*100)
  setDelegations(stakingViewInfo.pool_info['live_delegators'])
}



 useEffect(() => {
    //console.log(stakingViewInfo)

    try{
      if (stakingViewInfo){

        setPoolInfo()

        setPoolClay(stakingViewInfo.staking_info['clay'])
        setPoolUnbakedClay(stakingViewInfo.staking_info['unbaked_clay'])


        let cur_lovelace = lovelace
        let cur_clay = parseInt(assetsOwned['clayToken'] / 10000)
        let cur_unbaked_clay = totalUnbaked

        if (searchedStakeAddr){
            cur_lovelace = parseInt(searchedBalance.lovelace)
            cur_clay = parseInt(searchedBalance.clay)
            cur_unbaked_clay = parseInt(searchedBalance.unbaked_clay)
        }

        let temp_adaShare;
        if (stakedWithClay){
          /*
          console.log(stakingViewInfo.pool_info['live_stake'])
          console.log(lovelace)
          */

          temp_adaShare = (cur_lovelace / (parseInt(stakingViewInfo.pool_info['live_stake'])+0.00001) * 100).toFixed(5)
        }else{
          temp_adaShare = 0
        }
        setAdaShare(temp_adaShare)
        setAdaStaked(displayNumber(Math.round(cur_lovelace/1000000)))


        if (assetsOwned){
          let temp_clayShare;

          if (stakedWithClay){
            temp_clayShare = (cur_clay / (parseInt(stakingViewInfo.staking_info['clay']) + 0.00001) * 100).toFixed(5)
          }else{
            temp_clayShare = 0
          }
          setClayShare(temp_clayShare)
          setClayStaked(displayNumber(cur_clay))
          
        }


        if (totalUnbaked){
          let temp_unbakedClayShare;

          if (stakedWithClay){
            temp_unbakedClayShare = (cur_unbaked_clay / (parseInt(stakingViewInfo.staking_info['unbaked_clay'])+0.00001) * 100).toFixed(5)
          }else{
            temp_unbakedClayShare = 0
          }
          setUnbakedClayShare(temp_unbakedClayShare)
          setUnbakedClayStaked(displayNumber(cur_unbaked_clay))
          
        }

        if (typeof(stakingViewInfo.staking_info) != 'undefined'){
          setAdaRatio(stakingViewInfo.staking_info['ada_rate']*100)
          setClayRatio(stakingViewInfo.staking_info['clay_rate']*100)
          setUnbakedClayRatio(stakingViewInfo.staking_info['unbaked_clay_rate']*100)
          setMaxRewards(stakingViewInfo.staking_info['max_clay_rewards'])
        }

      }
    }catch{
      //
    }

 }, [stakingViewInfo, assetsOwned, totalUnbaked])


     useEffect(() => {



       //if (stake_address){

          try{
           getStakingViewInfo()
          }catch{
            //
          }
       
       

    }, [stake_address, searchedStakeAddr])


const option = {


  grid: { containLabel: true },


  color: [
    {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [{
          offset: 0, color: '#FD845E' // color at 0%
      }, {
          offset: 1, color: '#fac524' // color at 100%
      }],
      global: false // default is false
    }
  ],

  tooltip: {

    formatter: function (param) {
      

      return [
        'POOL SATURATION & REWARD ALLOCATION <br/>',
        Math.round(param.value/100*maxRewards/1000) + 'K: TOTAL $CLAY<br/>',
        adaRatio+'%: ADA  ' + '<br/>',
        clayRatio+'%: $CLAY  '+ '<br/>',
        unbakedClayRatio+'%: unbaked $CLAY ' + '<br/>',
    
      ].join('');
    },
    
    trigger: 'item',
    position: 'top',
    backgroundColor: 'rgba(45, 56, 75, 0.9)',
    shadowBlur: 10,
    shadowColor: 'transparent',
    borderColor: 'transparent',
    borderRadius: 10,

    textStyle: {
      color: '#ccc',
    fontFamily: 'ShortStack'
    }
  },
  

  textStyle:  {

    color: '#ccc',
    fontFamily: 'ShortStack',
    fontSize: '5',
  },

  xAxis: 

    
    {
    type: 'category',
    data: ['0M', '8M', '16M', '24M', '32M', '40M', '48M', '56M', '64M', '72M'],
    nameTextStyle: {
      fontFamily: 'ShortStack',
     
    }
    
    },



  yAxis: {

    axisLabel: {
      fontSize: 10,
      interval: 20,
      formatter: '{value} %'
    },
    type: 'value',


    splitLine: {
      lineStyle: {
        
          color: ['#ffffff26']
      }
    },
  },
  

  splitLine: {
    lineStyle: {
       
        color: ['#ffffff26']
    }
  },


  series: [
    {
      data: [0.0, 8.8, 18.6, 29.4, 41.3, 54.3, 68.4, 83.6, 100.0, 100],
      type: 'line',
      smooth: false,

    }
  ],

};




const option2 = {


  grid: { containLabel: true },

  color: [

    '#00a7ff',
    '#7597bf'
    
  ],


  tooltip: {

    backgroundColor: 'rgba(45, 56, 75, 0.9)',
    shadowBlur: 10,
    shadowColor: 'transparent',
    borderColor: 'transparent',
    borderRadius: 10,

    textStyle: {
      color: '#ccc',
    fontFamily: 'ShortStack'

  },


  },

  textStyle:  {

    color: '#ccc',
    fontFamily: 'ShortStack',
    fontSize: '12',
  },  
  xAxis: [{


    type: 'category',
    data: ['$CLAY', 'Unbaked CLAY'],
  
    axisLabel: {
      fontSize: 12
    },

    barWidth: 42,

 




  grid: { containLabel: true },
  

  


  }],
  yAxis: {
    type: 'value',

    splitLine: {
      lineStyle: {
        
          color: ['#ffffff26']
      }
    },

    nameTextStyle: {
      width: '100',
    },

    axisLabel: {
      fontSize: 10,
      interval:60
      
    },

    


    grid: { containLabel: true },




  },

  series: [
    {
      data: [
        poolClay,
        {
          value: poolUnbakedClay,
          itemStyle: {
            color: '#7597bf'
          }
        },
        
      ],
      type: 'bar',
      barCategoryGap: "50%"

   
  

    }
  ]
};



const option3 = {


  color: [
    '#7597bf'
    
    


  ],

  textStyle:  {

    color: '#ccc',
    fontFamily: 'ShortStack',
    fontSize: '12',
  },


  legend: {

    
},
  tooltip: {

    backgroundColor: 'rgba(37, 37, 37, 0.7)',
    shadowBlur: 30,
    shadowColor: 'rgba(145, 186, 231, 0.4)',
    borderColor: 'transparent',

    textStyle: {
      color: '#ccc',
    fontFamily: 'ShortStack'

  },


  },


  

  dataset: {
    source: [
      ['product',1000],
      ['$CLAY',1200 ],
      ['Unbaked $CLAY',50],
      
    ],
   
   
  },
  xAxis: [
    { type: 'category', gridIndex: 0 },
   
  ],
  yAxis: [{ gridIndex: 0 }],
  grid: [{ bottom: '55%' }],
  series: [
    {
      itemStyle: { 
        borderRadius: 0,
        borderColor: 'transparent',
        borderWidth: 2,
       
      
      },
    },

    // These series are in the first grid.
    { type: 'bar', seriesLayoutBy: 'row' },
    { type: 'bar', seriesLayoutBy: 'row' },

   
  ]
};



  function displayNumber(num){
    try{
      let temp = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return temp
    }
    catch{
      return ''
    }

  }




  return (

<div style={{marginTop: matchesS ? '100px' : '120px'}}>




{searchedStakeAddr ? 

<div style={{marginBottom:'20px', width:'420px'}}>

   <div className="stakingAddress" 
   style={{height:'50px', width:'100%'}}> 

   <span className="tokensNumber" style={{color:'#e7e6d2'}}>
     
   </span><span className="tokensText" style={{color:'#e7e6d2', inlineSize:'300px', overflowWrap:'break-word'}}>{searchedStakeAddr}</span> 

   <IconButton onClick={()=>{
  setSearchVal('')
  setSearchedStakeAddr('')
}}>
    <CloseIcon style={{color:'bisque'}} />
   </IconButton>

   </div>
</div>

: 


  <div className={matchesS ? searchClassName : searchClassName.split(' ')[0] + 'Small' + ' ' + searchClassName.split(' ')[1]} style={{marginTop:'0px', marginBottom:'20px', marginLeft: matchesS ? '0px' :'3vw', width:'340px'}}>
    <SearchInput marginLeft={searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? '0px' : '40px'} style={{width:'340px'}}>
        <input
          id="searching"
          type="text"
          value={searchVal}
          placeholder={searchPlaceholder}


          onChange={(event) => { 
            
            let cur_value = event.target.value
            setSearchVal(cur_value)

            if (cur_value == ''){
              //do nothing
            }else{
               setSearchClassName('searchFieldPressed')
             }

          }}

          onKeyPress={(event) => {

              //console.log(searchVal)

              if (event.key == 'Enter'){
                 makeSearch(searchVal)
              }

            }}

   
          onFocus={() => {

          if (searchVal == ''){
              setSearchClassName('searchField focussed')
          }else{
            //if (clearSearch){
              //setSearchVal('')
              //setClearSearch(false)
           // }else{
              setSearchClassName('searchFieldPressed')
           //}
          }


          }}


          onBlur={() => {

            if (searchVal == ''){

               setSearchClassName('searchField')

            }else{

              setSearchClassName('searchFieldPressed')}

          }}
 
       />
</SearchInput>

<img className="searchicon"  src="/search.png" style={{animation: searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? 'swipeRight 0.4s ease-out forwards' : 'none' }} />

{!isSearching ?
<button onClick={() => {



 
  //console.log(searchVal)

  makeSearch(searchVal)


  //setClearSearch(true)

}} 





style={{position:'relative', zIndex:'250', cursor:'pointer', height:'55px', width:'55px', backgroundColor:'transparent', border:'none', borderRadius:'17px', display: searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? 'block' : 'none'}}>
  <img src="/search.png" style={{height:'50px', width:'auto', marginTop: '2px', animation:'btnAppear 0.4s ease-out forwards'}} />
</button>

: <div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center',height:'50px', width:'60px',zIndex:'250'}}> <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} /> </div>}

</div>


}




<div className="staking" style={{minWidth:'350px'}}>
  

<div ref={viewRef} style={{backgroundColor:'',zoom:1,height:'100%', width:'100%', overflow: compactView ? 'scroll' : 'visible'}}>
<div style={{backgroundColor:'', height: compactView ? '240px' : '40px', width:'100%', display:'flex', justifyContent:'center'}}>
   
   <div className='staking-pool' style={{top:compactView ? '20px':'-45px', transform: !compactView && viewWidth < 1030 ? 'scale(0.95)' : 'scale(1)'}}>
        
    <div>

    <Tooltip title="Pool ID: 0236ec1a0769c1b1bd8b6400d2f0e8e206cccd77a3700e5b7cf172a8"
   placement="top-start" followCursor

  componentsProps={{
    tooltip: {
      sx: {
        TransitionComponent: 'Zoom',
        borderRadius: '15',
        padding: 1,
        bgcolor: 'rgba(45, 56, 75, 0.9)',
        fontFamily: 'ShortStack',
        color: '#ccc',
        border: "solid transparent 1px",
        '& .MuiTooltip-arrow': {
          color: '#2D3141',
         
        },
      },
    },
  }}
>

   <img  className='clay-pool-img' src={'CLAY_POOL.png'} />



</Tooltip>
   </div>

    <div style={{height:'140px', top: '20px', width:'100%',position:'relative', display:'block', testAlign:'center',justifyContent:'center'}}>
       
       <div className='ADA-delegated'>{liveStake}&nbsp;₳ </div>
       <div className="pool-pledge-txt">PLEDGE</div>
       <div className="pool-pledge">{pledge}K ₳</div>
       <div className="pool-margin-txt">MARGIN</div>
       <div className="pool-margin">{margin}%</div>
       <div className="delegations-txt">DELEGATIONS</div>
       <div className="delegations">{delegations}</div>

      </div>
      </div>

</div>


<div style={{transform: !compactView && viewWidth < 1030 ? 'scale(0.85)' : 'scale(1)'}}>


<div style={{width:'100%', display:'flex', justifyContent:'center'}}>

<div style={{backgroundColor:'', width: !compactView && viewWidth < 1030 ? '100%' :'88%', height:'', display:'flex', flexDirection: compactView ? 'column' : 'row', justifyContent: compactView ? 'start' : 'center', alignItems:'center', marginTop: compactView ? '10px':'0px', 
}}>
  <div className='staking-info' style={{order: compactView ? 2 : 1, margin:'10px'}}><ReactEcharts option={option2} /></div>
  <div style={{backgroundColor:'', width:'400px', height:'100px', order: compactView ? 3 : 2, display:'flex', marginTop: compactView ? '0px' : '165px', justifyContent:'center'}}>
      

      <div className='user-share-percentages-1' style={{margin:'5px'}}>


        <span className="ADA-share-percentage"> {stakedWithClay && adaShare != 0 ? adaShare : '0.0000'}% </span>


        <span className="ADA-share"> SHARE OF <br></br>ADA<br></br> STAKED  </span>



        </div>


        <div className='user-share-percentages-2' style={{margin:'5px'}}>


        <span className="CLAY-share-percentage"> {stakedWithClay && clayShare != 0 ? clayShare : '0.0000'}% </span>


        <span className="CLAY-share"> SHARE OF <br></br>$CLAY<br></br> STAKED  </span>



        </div>


        <div className='user-share-percentages-3' style={{margin:'5px'}}>


        <span className="CLAY-share-percentage"> {stakedWithClay && unbakedClayShare != 0 ? unbakedClayShare : '0.0000'} %  </span>


        <span className="CLAY-share"> SHARE OF <br></br>UNBAKED $CLAY<br></br>   </span>



        </div>


  </div>



  <div className='rewards' style={{order: compactView ? 1 : 3, margin:'10px'}}>


  <div style={{width:'300px', height:'0px', position:'relative', display:'flex', justifyContent:'center'}}>
    
    <div className='rewards-tag'>
      <span className="total-staked"> EPOCH {epoch} </span>
      <span className="current-epoch-days-left"> ({getEpochEnd(epochEnd)}) </span>
    </div>

  </div>


  <ReactEcharts option={option} /></div>

</div>

</div>

<div style={{backgroundColor:'',minHeight:'310px',width:'100%', display:'flex', flexDirection: compactView ? 'column' : 'row', justifyContent: compactView ? 'center' : 'center', alignItems: compactView ? 'center':'center'}}>



  { (stakedWithClay && !searchedStakeAddr) || (searchedStakeAddr && searchedStaked) ?

  <div className='user-data' style={{ backgroundColor:'',flexDirection: compactView ? 'column' : 'row', alignItems:'center', justifyContent:'space-between',width: compactView ? '320px': viewWidth < 1030 ? '100%' : '85%', paddingBottom:'25px', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', marginTop: compactView ? '10px' : '0px', marginBottom: compactView ? '30px' : '0px'}}>

    <div style={{order: compactView ? 2 : 1}}>

    <div style={{marginTop:'10px',backgroundColor:'', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
       <span className="staked-with-pool"> STAKED WITH CLAY POOL   </span>
     

      <div style={{margin:'0px', backgroundColor:'', width:'320px', height:'160px', display:'flex', justifyContent:'center', marginTop:'10px'}}>


       <div style={{backgroundColor:'', display:'flex', flexDirection:'row', width:'300px', justifyContent:'center', alignItems:'center'}}>


      <div  style={{display:'flex', flexDirection:'column', justifyContent:'space-between', margin:'5px'}}>

       <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <div className="ada-number">{displayNumber(adaStaked)} </div>
        <div className="ada-stk-txt"> ADA </div>
      </div>


       <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>


        <div className="epochs-number"> {epochsStaked} </div>

        <div className="epochs-txt"> EPOCHS </div>

      </div>

    </div>


    <div  style={{display:'flex', flexDirection:'column', justifyContent:'space-between', margin:'5px'}}>

     
       <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>

        <div className="baked-CLAY-number"> {clayStaked} </div>

        <div className="baked-CLAY-txt"> $CLAY </div>

      </div>


      <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>


        <div className="unbaked-CLAY-number"> {unbakedClayStaked} </div>

        <div className="unbaked-CLAY-txt"> UNBAKED $CLAY </div>

      </div>

    </div>


    </div>
</div>



    </div>

  </div>


  <div style={{order: compactView ? 3 : 2}}>
  
      <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', marginTop:'20px', backgroundColor:''}}>

      <img className='CLAYTOKEN' src={'CLAY_TOKEN_220x220.png'} />

      <div className='total-CLAY-rewards' style={{marginTop:'5px', marginBottom:'5px'}}>

          <Tooltip title="Total $CLAY rewards accumulated"
          arrow placement="bottom" followCursor

          componentsProps={{
            tooltip: {
              sx: {
                TransitionComponent: 'Zoom',
                borderRadius: '20',
                padding: 1,
                bgcolor: '#2D3141',
                fontFamily: 'ShortStack',
                color: 'white',
                border: "solid black 1px",
                '& .MuiTooltip-arrow': {
                  color: '#2D3141',
                 
                },
              },
            },
          }}
        >


        <span className="total-rewards">{totalRewards}</span>

        </Tooltip>

        </div>

       <button className='claim-staking-btn' 


        onClick={()=>{
          console.log('CLAIM')


          setClaimStake({totalRewards:totalRewards, stakeAddress: searchedStakeAddr ? searchedStakeAddr : stake_address})
        
        }}

        >


        <h1>CLAIM</h1></button> 


        </div>



  </div>




  <div style={{order: compactView ? 2 : 3}}>



    <div style={{marginTop: compactView ? '30px':'10px',backgroundColor:'', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
       <span className="staked-with-pool">REWARDS ACCUMULATED</span>
     




      <div style={{margin:'0px', backgroundColor:'', width:'320px', height:'160px', display:'flex', justifyContent:'center', marginTop:'10px'}}>


       <div style={{backgroundColor:'', display:'flex', flexDirection:'row', width:'300px', justifyContent:'center', alignItems:'center'}}>

 


      <div  style={{display:'flex', flexDirection:'column', justifyContent:'space-between', margin:'5px'}}>

     <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <div className="ada-received-number"> {fromAda} </div>
        <div className="user-CLAY-txt">from ADA </div>
      </div>


    <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
      <div className="times-claimed-number"> {timesClaimed} </div>
      <div className="user-times-txt"> TIMES CLAIMED </div>
    </div>

    </div>


    <div  style={{display:'flex', flexDirection:'column', justifyContent:'space-between', margin:'5px'}}>

     <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <div className="CLAY-received-number"> {fromClay} </div>
      <div className="user-CLAY-txt"> from $CLAY </div>
    </div>


      <div className='ADA-staked' style={{width:'95px', height:'55px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
        <div className="unbaked-received-number"> {fromUnbakedClay} </div>
        <div className="rewards-CLAY-unbaked-txt">from UNBAKED $CLAY </div>
      </div>

  </div>


    </div>

    </div>

    </div>
  </div>

</div>

:


<div className="user-data" style={compactView ? {width:'300px', height:'200px', alignItems:'center'} : {width:'950px', height:'200px', alignItems:'center'}}>

     <span style={{fontSize: '38px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', color: 'rgba(255, 228, 196, 0.832)'}}>
       Not staked with CLAY pool
    </span>


</div>}



</div>


</div>

</div>

     
    </div>
</div>
/*
<div className="placeholder-comingsoon">

        <div className="coming-soon"> COMING SOON </div>

        <div className="progress-GC">
   <div className="track-GC">
      <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
       <div className="day"></div>
    </div>
    </div>
</div>
*/

  );
}

export default Staking;
