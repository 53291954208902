import React from 'react';
import styled, { keyframes } from 'styled-components';

const drawCross = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 91.1;
  }
`;

const AnimatedCrossWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSvg = styled.svg`
  width: 100px;
  height: 100px;
`;

const StyledLine = styled.line`
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: ${drawCross} 1s ease-in-out 0.5s forwards;
`;

const StyledCircle = styled.circle`
  fill: transparent;
  stroke-width: 6;
  stroke-dasharray: 91.1;
  stroke-dashoffset: 91.1;
  animation: ${drawCross} 1s ease-in-out forwards;
`;

const AnimatedCross = () => {
  return (
    <AnimatedCrossWrapper>
      <StyledSvg>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#FF3B30" />
            <stop offset="97.92%" stopColor="#FF9500" />
          </linearGradient>
        </defs>
        <StyledLine x1="30" y1="30" x2="70" y2="70" stroke="url(#gradient)" />
        <StyledLine x1="30" y1="70" x2="70" y2="30" stroke="url(#gradient)" />
        <StyledCircle cx="50" cy="50" r="40" stroke="url(#gradient)" />
      </StyledSvg>
    </AnimatedCrossWrapper>
  );
};

export default AnimatedCross;
