import {useState, useEffect, useContext} from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs'
import {Buffer} from 'buffer'
import * as msgSign from '@emurgo/cardano-message-signing-asmjs'

import {MainLinkingContext} from './Context/LinkingContext'

import Stack from '@mui/material/Stack';

import Switch from '@mui/material/Switch';

import AnimatedSpinner from './AnimatedSpinner'

import { styled } from '@mui/material/styles';

import './Spinner.css'
import '../WalletLink.css'
import '../WalletLink.css'


const NamiIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/nami.svg';
const EternlIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/eternl.png';
const YoroiIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/yoroi.svg';
const FlintIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/flint.svg';
const GeroIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/gerowallet.svg';
const LaceIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/lace.svg';

const TyphonIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/typhon.svg';
const VesprIcon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/vespr.png';

const NETWORK_ID = 1




const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const HWSwitch = styled((props) => (
   
  <Switch focusVisibleClassName=".Mui-focusVisible" {...props} /> ))(({ theme }) => ({
    


  }))


function CardanoConnect(props) {

  const {setActiveStep, toastMessages} = props

  const {cardanoAddress, setCardanoAddress, setCardanoStakeAddress, setCardanoSecret_1, setCardanoSecret_2} = useContext(MainLinkingContext)
  const [activeWallet, setActiveWallet] = useState('nami')
  const [activeAddress, setActiveAddress] = useState('')

  const [isVerifying, setIsVerifying] = useState(false)

  const [hwMode, setHwMode] = useState(false)


  // useEffect(() => {

  //   console.log('address changed')
  //   console.log(activeAddress)

  // },[activeAddress])

  useEffect(() => {
    checkSignatureTest()
  },[])


  async function checkSignatureTest(){
    let checkedSign = await checkSignature('845846a2012767616464726573735839012de81250832e6b3240b6dcaa140f46493fdb2b7df77cf63ff9deec9826517c0bfae34e7d14bb6207f783b76ae6795bcb4585afc756fcaf8ba166686173686564f458d87b2266756c6c4e616d65223a224a6f737565204e6f656c205572696265222c22656d61696c223a226172636865726669677340676d61696c2e636f6d222c2273747265657441646472657373223a2236313320572057696c6c69616d73205374222c22636f756e747279223a22756e6974656420737461746573206f6620616d6572696361222c2270726f76696e6365223a226361222c22706f7374436f6465223a223933343538222c2263697479223a2253616e7461204d61726961222c2270686f6e65223a222838303529203331342d38343731227d5840559ee5e377ea9fff784d75741bf03678e3cc19c34c59af191a86ac87bae0e8630ef29571722daf956e3ea43eb4f09c1df5c26c3aa04825560e5dbebc3c78e200')
    //console.log(checkedSign)
  }
 


  async function  signMessage(cardano, address, message){
        let encoded_message_string = Buffer.from(message).toString('hex')
        let addr_ptr = serLib.Address.from_bech32(address)
        let address_encoded = Buffer.from(addr_ptr.to_bytes()).toString('hex')
        let access_signature = await cardano.signData(address_encoded, encoded_message_string)
        return access_signature
  }


  async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}



  async function getUtxos(cardano){
  const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
  const utxos = (await cardano.getUtxos()).map((utxo) =>
    txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
  );
  return txUnspentOutputs
}

  
async function getParameters(){
  const protocolParams = await fetch('https://claymarket.io/minting/protocolparams/mainnet/', {
        method: 'GET',
       })
  const json_params = await protocolParams.json()
  return json_params
}



async function getCurrentSlot(){
    let slot = await fetch('https://claymarket.io/minting/currentslot/mainnet/', {
        method: 'GET',
        }).then(response => response.json()).then(data => data)

    slot = parseInt(slot.slot, 10)

    return slot
}



  async function signTransaction(cardano, address, message){

    const address_encoded = serLib.Address.from_bech32(address)
    const protocolParams = await getParameters()
    const utxos = await getUtxos(cardano)

    let txBuilder = await get_txBuilder(protocolParams)

    let lovelacePayment = 2000000

    let payment_output = serLib.TransactionOutput.new(
              address_encoded,
              serLib.Value.new(
                serLib.BigNum.from_str(String(lovelacePayment)),
              )
    )


    let required_signer = Buffer.from(serLib.BaseAddress.from_address(address_encoded).payment_cred().to_keyhash().to_bytes()).toString('hex')
    txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signer,'hex')))
    //txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signer_2,'hex')))

    txBuilder.add_output(payment_output);

    let before_message = 'Proving ownership of the wallet by signing this message: '

    try{
      txBuilder.add_json_metadatum(
                  serLib.BigNum.from_str("6679"),
                  JSON.stringify({
                    "message": [before_message, message]
                  })
                 );
    }catch(err){
      //console.log(err)
    }

    
    try{
          txBuilder.add_inputs_from(utxos, 2) 
        }catch (err){
          // console.log('err')
          // console.log(err)

          // notifyWarn('balance is not sufficient')
          return
        }

      

      try{
        txBuilder.add_change_if_needed(address_encoded)
      }catch(err){
        //console.log(err)
        // setTransactionStatus('')
        // notifyWarn('could not add change')
        return
      }


      let unsignedTx;

      try{
         unsignedTx = txBuilder.build_tx()
      }catch(err){
        unsignedTx = ''
        // console.log(err)
        return
      }
      

      // console.log(unsignedTx.to_json())

      const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')

      let tx_witness;

      try{
        // setTransactionStatus('Signing Transaction...')
        tx_witness = await cardano.signTx(unsignedTx_hash, true)
      }catch{
        return
      }


      //let witness_set = serLib.TransactionWitnessSet.from_bytes(Buffer.from(tx_witness,"hex"))

      //console.log(tx_witness)
     
      return {"tx":unsignedTx_hash, "witness":tx_witness}

  }





  async function checkSignature(signature){
    const message = msgSign.COSESign1.from_bytes(Buffer.from(Buffer.from(signature, 'hex'), 'hex'));
    let headermap = message.headers().protected().deserialized_headers();
    let signer_address = serLib.Address.from_bytes(headermap.header(msgSign.Label.new_text('address')).as_bytes());
    signer_address = signer_address.to_bech32()
    let payload = message.payload();
    payload = Buffer.from(payload, 'hex').toString("utf8")
    const data = {'signer':signer_address, 'message':payload}

    return data
  }


  async function enableWallet(wallet){

    try{
        let cardano = window.cardano[wallet]
        
          let walletAPI = await cardano.enable()
          return walletAPI
        
    }catch{
      //could not connect
      toastMessages.notifyWarn('Could not connect')
    }

  }


  async function getStakeAddress(address){
    let temp = serLib.Address.from_bech32(address)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()

    return cur_stake_address
  }


  async function fetchSecret(address){

    let response = await fetch(`https://clayapis.com/cardano/secret/${address}/`).then(response => response.json())
                                                                  .then(data => data)
    //console.log(response)
    return response                                                             
  }


 async function postSignature(signature){

  const data = {'signature':signature}
    const response = await fetch('https://clayapis.com/cardano/link/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()

 }

  async function postTransaction(data){

  //const data = {'tx':tx, 'witness':witness}

    const response = await fetch('https://clayapis.com/cardano/link-hw/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()

 }


  async function verifyOwnership(cardano, address){

    let secretResponse = await fetchSecret(address)
    
    if (secretResponse.hasOwnProperty('secret') && secretResponse.hasOwnProperty('time')){
      let secret_1 = secretResponse.secret
      let time_1 = secretResponse.time   


      let message = 'Proving ownership of the wallet by signing this message: ' + secret_1                                                     
      

      /*
      HERE DEPENDING ON THE SIGNATURE TYPE
      */

      let signedResponse = {}


      if (!hwMode){
        let signedMsg = await signMessage(cardano, address, message)
        let signature = signedMsg.signature
      
        setIsVerifying(true)

        signedResponse = await postSignature(signature)

      }else{
        let signedTx_dict = await signTransaction(cardano, address, secret_1)

        setIsVerifying(true)

        try{
          //console.log(signedTx_dict)
          signedResponse = await postTransaction(signedTx_dict)
          //console.log(signedResponse)

          //console.log('try')
        }catch{
          //console.log('catch')
          signedResponse = {}
        }
      }

      //console.log(signedResponse)

      

      if (signedResponse.hasOwnProperty('secret') && signedResponse.hasOwnProperty('time')){
        let secret_2 = signedResponse.secret
        let time_2 = signedResponse.time 
        
        //if we are here then success!
        setCardanoSecret_1(secret_1)
        setCardanoSecret_2(secret_2)

        setActiveStep(1)

        //spinner off
        setIsVerifying(false)

      }else{
        //handle error 2
        toastMessages.notifyError('Could not verify ownership')
        //spinner off
        setIsVerifying(false)
      }

    }else{
      //handle error 1
       toastMessages.notifyError('Could not verify ownership')
       //spinner off
       setIsVerifying(false)
    }

  }

 
  async function walletConnect(wallet){

    try{

      let cardano = await enableWallet(wallet)

      if (typeof(cardano) === 'undefined'){
        return
      }

      //check network
      let cur_network_id = await cardano.getNetworkId()

      // console.log('CUR NETWORK_ID')
      // console.log(cur_network_id)

      if (cur_network_id !== NETWORK_ID){
        //handle wrong network
        toastMessages.notifyWarn('Wrong Network!')
        return
      }

      let address_encoded;

      try{
       //console.log('here 1')
       address_encoded  = await cardano.getUsedAddresses()
       address_encoded = address_encoded[0]

       if (address_encoded === undefined){
        throw 'error'
       }
      }catch{

        try{
          //console.log('here 2')
          address_encoded  = await cardano.getUnusedAddresses()
          address_encoded = address_encoded[0]
          if (address_encoded === undefined){
            throw 'error'
          }
        }catch{
          //console.log('here 3')
          address_encoded = await cardano.getRewardAddress()
          //console.log(address_encoded)
        }
      }
         

      //console.log(address_encoded)

      let address_decoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()
      setCardanoAddress(address_decoded)

      //console.log(address_decoded)

      let stake_address = await getStakeAddress(address_decoded)
      setCardanoStakeAddress(stake_address)

      //console.log(stake_address)

      try{
        let signature = await verifyOwnership(cardano, address_decoded)
      }catch{
        //handle error
        toastMessages.notifyError('Could not verify ownership')
        setIsVerifying(false)
      }
    }catch{
      //hanle error
      toastMessages.notifyWarn('Could not connect')
    }
  }


  return (
   <>


   <h2 className="connect-heading heading-cardano-wallets">
           <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
              

              <div style={{width:'80%', fontSize:'30px'}}>
                 CONNECT YOUR CARDANO WALLET
              </div>

              

            </div>
      </h2>

   {/*<div style={{marginTop:'40px', marginBottom:'20px'}}>
    Connect your Cardano wallet
   </div>*/}

   {!isVerifying ?

   <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', marginTop:'-20px'}}>

    <div style={{width:'80%', height:'50px', backgroundColor:'', fontSize:'15px', display:'flex', justifyContent:'center', alignItems:'center'}}>
        
        <div style={{width:'50px', height:'50px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <HWSwitch
            checked={hwMode}
            onChange={()=>{
              setHwMode(!hwMode)
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>

        <div style={{marginLeft:'10px'}}>
          Sign transaction (hardware wallets)
        </div>

    </div>

   <div style={{overflow:'scroll', height:'250px', display:'flex', backgroundColor:'', justifyContent:'center', alignItems:'start'}}>

    

    <div className='CardanoWallets'>
                  <div class="row">
                  <div class="column">
                
                <button className='walletbtn' onClick={() => {
              walletConnect('nami')
            }}><img src={NamiIcon} style={{width:'30px', height:'auto', paddingRight: '30px', marginLeft:'-25px'}}/>NAMI</button>
                
                <button className='walletbtn' onClick={() => {
              walletConnect('eternl')
            }}><img src={EternlIcon} style={{width:'35px', height:'auto', paddingRight: '30px'}}/>ETERNL</button>
                
                <button className='walletbtn' onClick={() => {
              walletConnect('yoroi')
            }}><img src={YoroiIcon} style={{width:'35px', height:'auto', paddingRight: '30px', marginLeft:'-15px'}}/>YOROI</button>

            <button className='walletbtn' onClick={() => {
              walletConnect('typhoncip30')
            }}><img src={TyphonIcon} style={{width:'35px', height:'auto', paddingRight: '30px', marginLeft:'5px'}}/>TYPHON</button>
                
                </div>
                
                <div class="column">
                
                <button className='walletbtn' onClick={() => {
              walletConnect('gero')
            }}><img src={GeroIcon} style={{width:'35px', height:'auto', paddingRight: '30px', marginLeft:'-25px'}}/>GERO</button>
                
                <button className='walletbtn' onClick={() => {
              walletConnect('flint')
            }}><img src={FlintIcon} style={{width:'25px', height:'auto', paddingRight: '30px', marginLeft:'-15px'}}/>FLINT</button>
                
                <button className='walletbtn' onClick={() => {
              walletConnect('lace')
            }}><img src={LaceIcon} style={{width:'35px', marginLeft:'-15px', height:'auto', paddingRight: '30px', marginLeft:'-25px'}}/>LACE</button>


            <button className='walletbtn' onClick={() => {
              walletConnect('vespr')
            }}><img src={VesprIcon} style={{width:'30px', height:'auto', paddingRight: '30px', marginLeft:'-5px'}}/>VESPR</button>
                
                </div>
                </div>
                </div>

            </div>

            </div>


                : 

                 <div style={{height:'200px', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                
                  <div style={{backgroundColor:'', height:'150px', width:'120px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                     <div 
                      id="spinner" 
                      className="spinnerCardano"
                      style={{backgroundColor:''}}
                    >  
                    </div>
                  </div>

                </div>
            }

        
        {/*<h2 className="connect-heading heading-cardano-wallets">
          <div style={{width:'80%'}}>
            Connect your Cardano wallet
          </div>
        </h2>


        <div style={{ width: "100%", height: "300px", backgroundColor: "" }}>
           <div className='CardanoWallets'>

           {!isVerifying ?

            <>
              <button className='Namibtn' onClick={() => {
              walletConnect('nami')
            }}><img src={NamiIcon} style={{width:'40px', height:'auto', marginRight:'20px'}}/>NAMI</button>
              
              <button className='Namibtn' onClick={() => {
              walletConnect('eternl')
            }}><img src={EternlIcon} style={{width:'40px', height:'auto', marginRight:'20px'}}/>ETERNL</button>

            <button className='Namibtn' onClick={() => {
              walletConnect('lace')
            }}><img src={''} style={{width:'40px', height:'auto', marginRight:'20px'}}/>LACE</button>

            <button className='Namibtn' onClick={() => {
              walletConnect('gero')
            }}><img src={''} style={{width:'40px', height:'auto', marginRight:'20px'}}/>GERO</button>

            <button className='Namibtn' onClick={() => {
              walletConnect('flint')
            }}><img src={''} style={{width:'40px', height:'auto', marginRight:'20px'}}/>FLINT</button>

            <button className='Namibtn' onClick={() => {
              walletConnect('typhoncip30')
            }}><img src={''} style={{width:'40px', height:'auto', marginRight:'20px'}}/>TYPHON</button>

            <button className='Namibtn' onClick={() => {
              walletConnect('yoroi')
            }}><img src={''} style={{width:'40px', height:'auto', marginRight:'20px'}}/>YOROI</button>

            <button className='Namibtn' onClick={() => {
              walletConnect('vespr')
            }}><img src={''} style={{width:'40px', height:'auto', marginRight:'20px'}}/>VESPR</button>
       
            </>

          : 

            <div style={{height:'250px', width:'80%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
                
              <div style={{backgroundColor:'', height:'100px', width:'100px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                 <div 
                  id="spinner" 
                  className="spinnerCardano"
                  style={{backgroundColor:''}}
                >  
                </div>
              </div>

            </div>


          }

           </div>

            


        </div>*/}


         


   </>
  );
}

export default CardanoConnect;
