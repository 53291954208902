

import { Link } from "react-router-dom";
import GlobalStyle from '../GlobalStyles';
import MainGrid from "../Components/MainGrid.jsx";
import logo from '../Images/logo_purple.png';
import { motion } from "framer-motion";

import {Buffer} from 'buffer'

import { Grid } from '@mui/material';

import transition from "../Components/Transition";

import { useState, useContext, useEffect } from 'react';
import Wallet from "../Components/WalletDisplay";
import { WalletMobile } from "../Components/WalletDisplay.styled.jsx";
import walleticon from '../Images/wallet.png';
import TransactionPannel from "../Components/TransactionPannel";
import { UnlockXPWrapper, TransactionButton, SkipButton, Logo } from './UnlockXP.styled';

import ConnectPopUp from '../ConnectPopUp'
import Connect from '../Connect'

import cursorvoxelised from '../Images/cursor32.png';

import {Provider} from 'components/ClaimContext'

import {MainClaimContext} from 'components/ClaimContext'

import CompletedPopup from "../Components/CompletedPopup";

import Mint from './Mint'

import cn_meta from 'cn_meta.json'

import Tooltip from '@mui/material/Tooltip';
import { CardMedia } from '@mui/material';
import { StyledTooltip, Pin, MainPannel, GridContainer, ClayCard, TypographyID} from '../Components/MainGrid.styled';

import unlockedpin from '../Images/unlocked_pin.png';
import lockedpin from '../Images/locked_pin.png';


import NoAssets from '../Components/NoAssets'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styled from "styled-components";

import AlertPopUp from '../AlertPopUp'

export const MainGridPannel = styled.div`
background: var(--background-pannel);
padding: 2em 2.5em;
border-radius: 1em;
backdrop-filter: blur(22px);
contain: content;
max-width: 60vw;
max-height: 80vh;
overflow-x: auto;
    @media screen and (max-width: 768px) {
        background: var(--background-progress);
        margin-top: 5em;
        overflow-y: auto;
        max-height: 70vh;
        padding: 2em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        max-height: 50vh;
        overflow-y: hidden;
        
    }

`;


function ProcessEligibleNFTS(props){

  const {tokens} = useContext(MainClaimContext)


  const {eligibleNfts, setEligibleNfts, setSelectedNfts} = props


  useEffect(() => {

    let temp_eligible = []

    //console.log(tokens)

    
      tokens.map((token) => {
        //console.log(token)

        if (token.slice(0,56) === '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728'){
          temp_eligible.push(token)
        }

      })
    

    // console.log('eligible Nfts')
    // console.log(temp_eligible)

    setEligibleNfts(temp_eligible)
    setSelectedNfts([])

  },[tokens])

  return(null)
}


function ProcessUpgradedNfts(props){

  const {stake_address} = useContext(MainClaimContext)

  const {upgradedNfts, setUpgradedNfts, notUpgradedNfts, setNotUpgradedNfts, eligibleNfts} = props


  //https://claymarket.io/minting/vox-status/

  async function get_vox_status(){
    let json_vox_status = await fetch('https://claymarket.io/minting/vox-status/'+stake_address + '/', {
        method: 'GET',
       })
    json_vox_status = await json_vox_status.json()

    console.log(json_vox_status)

    let temp_unlocked = []
    let temp_locked = []

    try{
      temp_unlocked = json_vox_status.unlocked
      temp_locked = json_vox_status.locked
    }catch{
      //ignore
    }

    setUpgradedNfts(temp_unlocked)
    setNotUpgradedNfts(temp_locked)

    // setUpgradedNfts([])
    // setNotUpgradedNfts(eligibleNfts)

  }


  useEffect(() => {
    get_vox_status()
    const interval = setInterval(() => {
            get_vox_status()
        }, 20000);
  
    return () => clearInterval(interval);


  },[eligibleNfts])

  return(null)
}



function UnlockXP() {

  const [buttonPopup, setButtonPopup] = useState(true);
  const Toggle = () => setButtonPopup(!buttonPopup);

  const [isWalletLoading,setIsWalletLoading] = useState(false)

  const [isPopUpOpen, setIsPopUpOpen] = useState(false)

  const [activeWallet, setActiveWallet] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const [walletToEnable, setWalletToEnable] = useState('')
  const [updateWallet, setUpdateWallet] = useState(false)
 

  const [selectedNfts, setSelectedNfts] = useState([])
  const [eligibleNfts, setEligibleNfts] = useState([])

  const [notUpgradedNfts, setNotUpgradedNfts] = useState([])

  const [upgradedNfts, setUpgradedNfts] = useState([])


  const [upgradeFee, setUpgradeFee] = useState(0)


  const [isAlertOpen, setIsAlertOpen] = useState(false) 
  const [submittedTx,setSubmittedTx] = useState('')


    return (
      <UnlockXPWrapper>

      <Provider>

        <ProcessEligibleNFTS eligibleNfts={eligibleNfts} setEligibleNfts={setEligibleNfts} 
          selectedNfts={selectedNfts} setSelectedNfts={setSelectedNfts}
        />

        <ProcessUpgradedNfts 
          upgradedNfts={upgradedNfts}
          setUpgradedNfts={setUpgradedNfts} 
          notUpgradedNfts={notUpgradedNfts} 
          setNotUpgradedNfts={setNotUpgradedNfts}
          eligibleNfts={eligibleNfts}
        />
        
       
          {/*<Wallet as={motion.button}
               initial="hidden"
               whileInView="visible"
               viewport={{ once: true }}
               transition={{ duration: 1.7, delay: 2 }}
               variants={{
                 visible: { opacity: 1},
                 hidden: { opacity: 0 }
               }} />

          <WalletMobile />*/}
        

        <div style={{position:'absolute', top:'35px', right:'40px'}}>
        <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}
                />

        </div>


        <a href="/" target="_blank">
        <Logo as={motion.img} 
          src={logo}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1.7, delay: 2 }}
          variants={{
            visible: { opacity: 1},
            hidden: { opacity: 0 }
          }}
           ></Logo></a>
        <GlobalStyle />


          {/*<MainGrid 
            eligibleNfts={eligibleNfts} 
            selectedNfts={selectedNfts} 
            setSelectedNfts={setSelectedNfts} 
          />*/}


        {
         eligibleNfts.length > 0 ? 

      <MainGridPannel style={{position:'relative', top:'50px', width:'60%', 
            height:'50%', overflow:'scroll', backgroundColor:'',
            display: 'flex', justifyContent:'center', alignItems:'center', minHeight:'500px', minWidth:'320px',

            marginLeft: buttonPopup ? '0px' : '0px'
        }}>



         <div style={{width:'90%', display:'flex', visibility: false ? 'hidden' : 'visible', alignItems:'center', height:'90%'}}>
            
                <div style={{display:'flex', alignItems:'center', height:'80%', 
                width:'100%',flexDirection:'column'}}>

         

         <Grid container spacing={3} 
                          alignItems="center"
                          justifyContent="center" style={{backgroundColor:'', marginLeft:'0px', marginTop:'0px'}}
                    

                as={motion.div}
                initial={{ x:1500, opacity:0 }} 
                whileInView="visible"
                viewport={{ once: true }}
                animate={{ opacity:1, x:0, type: "spring" }}
                transition={{ duration: 1.2, delay: 1.5}}
          >

        

         {eligibleNfts.map((eligibleNft)=>{
            let serial = (Buffer.from(eligibleNft.slice(56,), 'hex').toString('utf8')).slice('ClayNation'.length,)
            return(
              
              <div key={eligibleNft} style={{width:'240px', height:'320px', backgroundColor:'', marginRight:'10px', marginBottom:'10px', marginLeft:'20px'}} 
                onClick={()=>{
                  if (selectedNfts.includes(eligibleNft)){

                    if (notUpgradedNfts.includes(eligibleNft)){
                      let temp_selectedNtfs = selectedNfts.filter( item => item != eligibleNft);
                      setSelectedNfts([...temp_selectedNtfs])
                    }

                  }else{

                    if (notUpgradedNfts.includes(eligibleNft)){
                      setSelectedNfts([...selectedNfts, eligibleNft])
                    }

                  }
                }}
              >

                {/*<img 
                  src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" 
                  + cn_meta[(Buffer.from(eligibleNft.slice(56,), 'hex').toString('utf8')).slice('ClayNation'.length,)] 
                  + '.png'} style={{width:'100%', height:'100%'}} />*/}

                <ClayCard elevation={2} sx={{ padding: "1.5em 1em",backgroundColor: "var(--background-pannel)", borderRadius:"1rem"}}
                  selected={selectedNfts.includes(eligibleNft)}


                >
                  <CardMedia
                    component="img"
                    width="70%"
                    height="auto"

                    minWidth="320px"

                    image={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/720x720/" 
                        + cn_meta[serial] 
                        + '.png'}
                    alt="NFT1"
                    />
              <div style={{padding: "1em 0", width: "100%"}}>
              <TypographyID>CN#{serial}</TypographyID>

              {upgradedNfts.includes(eligibleNft) || notUpgradedNfts.includes(eligibleNft) ?
              
              <>
              <StyledTooltip title={<span style={{ fontFamily: "ShortStack" }}>{upgradedNfts.includes(eligibleNft) ? 'NFT UPGRADED' : 'NOT UPGRADED'}</span>} placement="top">
    
                <Pin 
                  src={
                    upgradedNfts.includes(eligibleNft) ? unlockedpin
                    : lockedpin
                  }

                  alt="token"/>

            
              </StyledTooltip>
              </>

              : null}

              </div>
              </ClayCard>


              </div>
            )
          })}
        

        

        </Grid>
        

        </div>
        </div>
        </MainGridPannel>


        : 

        <NoAssets />
      }


        {isPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', backdropFilter: 'blur(10px)', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>

       : null}


      <TransactionButton as={motion.button} 
        initial="hidden"
        whileInView={(buttonPopup && !isAlertOpen) ?'hidden':'visible'}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0 }}
        variants={{
          visible: { opacity: 1},
          hidden: { opacity: 0 }
        }}
        type="button" 
        onClick={() => {
          if (isAlertOpen){
            setIsAlertOpen(false)
          }else{
            Toggle()
          }
        }}

        

        >></TransactionButton>

        


        <TransactionPannel trigger={(buttonPopup && !isAlertOpen)} setTrigger={setButtonPopup}
          eligibleNfts={eligibleNfts}
          selectedNfts={selectedNfts}
          setSelectedNfts={setSelectedNfts}

          upgradeFee={upgradeFee}
          setUpgradeFee={setUpgradeFee}

          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}

          submittedTx={submittedTx}
          setSubmittedTx={setSubmittedTx}


        />

      <Link to="/sandbox-unlock/info"><SkipButton type="button" style={{cursor: `url(${cursorvoxelised}), auto`}}>Back</SkipButton></Link>

      {/*<div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
      </div>*/}

      {isAlertOpen ?
      <CompletedPopup setIsAlertOpen={setIsAlertOpen} />
      // <div style={{position:'absolute', width:'100%', height:'100%', backdropFilter:'blur(10px)', display:'flex', justifyContent:'center', alignItems:'center'}}>
      //   <CompletedPopup setTrigger={setButtonPopup} />
      // </div>
      : null}

      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />

      </Provider>
      </UnlockXPWrapper>


    );
  }
  
  export default transition(UnlockXP); 
  