import styled from "styled-components";
import { Tooltip } from '@mui/material';


export const PfpWrapper = styled.div`
border-radius: 50%;
outline: 7px solid rgba(0, 0, 0, 0.18);
backdrop-filter: blur(4px);
width: 8vw;
height: 8vw;
overflow: hidden;
position: absolute;
left: 3%;
top: 18%;
z-index: 3;
box-sizing: border-box;
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
&:hover {
    outline: none;
    border: 7px solid  var(--clayblue-primary);
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    width: 22vw;
    height: 22vw;
    left: 4%;
    top: 18%;
    outline: 7px solid rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 13vw;
    height: 13vw;
}
`;


export const StyledTooltip = styled(props => (
    <Tooltip classes={{ popper: props.className }} {...props} />
  ))`
    & .MuiTooltip-tooltip {
      background-color: var(--clayblue-primary);
      font-family: 'ClayFont';
      font-size: clamp(0.5rem, 1vw, 3rem);
      font-weight: 400;
      padding: 0.3rem 0.6rem;
      border-radius: 0.5rem;
    }
  `;
