// components/CardGrid.js

import {useState, useEffect} from 'react'

import React from 'react';
import Card from './Card';

import { Grid, Container, Box } from '@mui/material';

import Carousel from './Carousel'

import CarouselMobile from './CarouselMobile'

import meta from './json/meta.json'


const cardData = [
  { title: 'Card 1', content: 'Content for Card 1' },
  { title: 'Card 2', content: 'Content for Card 2' },
  { title: 'Card 3', content: 'Content for Card 3' },

  { title: 'Card 4', content: 'Content for Card 4' },
  { title: 'Card 5', content: 'Content for Card 5' },
  { title: 'Card 6', content: 'Content for Card 6' },
  // Add more cards as needed
];

const Cards = (props) => {

	const {setMintAsset, isAlertOpen,
           setIsAlertOpen, submittedTx,
           setSubmittedTx, cartItems,
           setCartItems, matchingPairs
         } = props

   const [smallScreen, setSmallScreen] = useState(false)
   const [mediumScreen, setMediumScreen] = useState(false)

   const [largeScreen, setLargeScreen] = useState(false)


   function handleResize(){
      //console.log(window.innerWidth)

      let cur_width = window.innerWidth

      if (cur_width > 992){
         setLargeScreen(true)
         setMediumScreen(false)
         setSmallScreen(false)
      }else if (cur_width > 600){
         setLargeScreen(false)
         setMediumScreen(true)
         setSmallScreen(false)
      }else{
         setLargeScreen(false)
         setMediumScreen(false)
         setSmallScreen(true)
      }
   }


   useEffect(() => {
     window.addEventListener("resize", handleResize)

     return () => {
      window.removeEventListener("resize", handleResize)
     }
   })


   useEffect(() => {

      handleResize()

   },[])



  return (

  	<>



     {true ?

     	Object.keys(meta).map((card, i) => {

     		return(

            <div key={i} style={
               smallScreen ?
               {transform:'scale(0.8)', marginTop:'-50px', marginBottom:'-50px'}
               : {transform:'scale(0.8)', marginTop:'-50px', marginBottom:'-25px', marginRight:'-50px'}
            }>

        			<Card 
      	  			key={meta[card].name} setMintAsset={setMintAsset} img_url={meta[card].ipfs} 
      	  			title={meta[card].title} price={meta[card].extra.price} 
      	  			token_name={meta[card].name} 

      	  			isAlertOpen={isAlertOpen} 
                  setIsAlertOpen={setIsAlertOpen} 
                  submittedTx={submittedTx} 
                  setSubmittedTx={setSubmittedTx}

                  cartItems={cartItems}
                  setCartItems={setCartItems}

                  matchingPairs={matchingPairs}
        			/>

            </div>

     		)

     	})
     
     : false ?

      <div style={{height:'800px', width:'100%', backgroundColor:'blue', marginLeft:'20px'}}>

      </div>

     : false ?

        <div style={{height:'500px', width:'100%', backgroundColor:'', marginLeft:'20px', display:'flex',
                     justifyContent:'center', alignItems:'center'
                    }}>
         <CarouselMobile />
        </div>

     : null

     }

   </>

    
  );
};

export default Cards;