import { useEffect, useState, useContext } from 'react'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import './StakingSideBar.css'
import {MainClaimContext} from './ClaimContext'


function StakingSideBar(props) {

  const clay_pool_id = 'pool1qgmwcxs8d8qmr0vtvsqd9u8gugrventh5dcqukmu79e2sgfuu8t'

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  

  const [isActive, setIsActive] = useState()

  const totalUnbaked = props.totalUnbaked
  const assetsOwned = props.assetsOwned
  const formattedClayAmount = props.formattedClayAmount 
  const setFormattedClayAmount = props.setFormattedClayAmount
  const stakeADAAmount = props.stakeADAAmount 
  const setStakeADAAmount = props.setStakeADAAmount

  const stakedWithClay = props.stakedWithClay
  const setStakedWithClay = props.setStakedWithClay



async function getStakingInfo (stake_address) {
      let staking_info = await fetch('https://clayapi.com/staking/' + stake_address + '/')
        .then(response => response.json())
        .then(data => data);

    //console.log(staking_info)
    return staking_info
}

const getFormattedBalance = (coin_balance, decimals, fixed_dec) => {
      let denominator = Math.pow(10,decimals)
      let whole_coins = coin_balance / denominator
      let formatted_amount = ''
      if (whole_coins < 1000){
        formatted_amount = (parseInt(coin_balance) / denominator).toFixed(fixed_dec);
      }else if(whole_coins < 1000000){
        formatted_amount = (parseInt(coin_balance) / (denominator*1000)).toFixed(fixed_dec) + 'K';
      }else{
        formatted_amount = (parseInt(coin_balance) / (denominator * 1000000)).toFixed(fixed_dec) + 'M';
      }

      return formatted_amount
}


useEffect(() => {

async function updateStakingInfo(){
  if (stake_address){
    let stake_info = await getStakingInfo(stake_address)

    //console.log(stake_info)

    
    if ('pool_id' in stake_info){
      let pool_id = stake_info['pool_id']

      let temp_isActive = stake_info['active']

      let controlled_amount = parseInt(stake_info['controlled_amount'])

      let ada_balance = getFormattedBalance(controlled_amount,6,2)


      let totalClay = 0

      //console.log('ASSETS OWNED')
      //console.log(assetsOwned)

      if(assetsOwned){
        totalClay = assetsOwned['clayToken']
      }
      let clay_balance = 0

      clay_balance = getFormattedBalance(totalClay, 4,2)

      setFormattedClayAmount(clay_balance)


      setStakeADAAmount(ada_balance)


      if (temp_isActive == true){
        setIsActive('Yes')
      }else{
        setIsActive('No')
      }
      
      if (pool_id == clay_pool_id){
        setStakedWithClay(true)
      }else{
        setStakedWithClay(false)
      }
    }
  }
}

updateStakingInfo()

}, [stake_address, assetsOwned])





  return (
        <Grid container justifyContent="center" alignItems="center" spacing={2} style={{fontFamily:'shortstack'}}>

            <Grid item xs={12}>
                    <div className={stakedWithClay ? "staked-frame" : "unstaked-frame"} style={{width:'95%'}} > 

                    <img src="/frame.png" className="frame-img"  />

                    {stakedWithClay ?
                    <div className="staked-txt">Staked</div>
                    :
                    <div className="staked-txt">Unstaked</div>
                    }

                    </div>
                </Grid>


            <Grid container justifyContent="center" textAlign="center" style={{width:'85%', marginBottom:'10px'}}>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >


            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'start'}}>    
                <div className="tokensText" style={{width:'80%',color:'#a8afa6', fontSize:'16px'}}>Stake Address</div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="stakingAddress" style={{height:'50px', width:'100%'}}><Stack spacing={0} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'#e7e6d2'}}></span><span className="tokensText" style={{color:'#e7e6d2'}}>{stake_address}</span> </Stack> </div>
            </Grid>

            </Stack>

           



            {stakedWithClay ?

             <Grid container justifyContent="center" textAlign="center" style={{width:'100%', marginTop:'10px', marginBottom:'10px'}}>

             <Grid item xs={4}>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'center',marginBottom:'5px'}}>    
                <div className="tokensText" style={{width:'100%',color:'#a8afa6', fontSize:'16px'}}>Pool</div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="stakingAddress" style={{height:'40px', width:'40px'}}><Stack spacing={0} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'#e7e6d2'}}></span><span className="tokensText" style={{color:'#fdfdfc'}}>CLAY</span> </Stack> </div>
            </Grid>
            </Stack>

            </Grid>



            <Grid item xs={4}>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'center',marginBottom:'5px'}}>    
                <div className="tokensText" style={{width:'100%',color:'#a8afa6', fontSize:'16px'}}>Amount</div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', marginBottom:'10px'}}>                
                  <div className="stakingAddress" style={{minHeight:'40px', minWidth:'90px'}}>


                  <Stack spacing={0.5} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'white'}}></span>
                  

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"

                    spacing={0.5}

                    style={{width:'100%'}}
                  >

                  <div>
                  </div>
                  <span className="tokensText" style={{color:'#fdfdfc'}}>

                  {stakeADAAmount}

                   </span>

                  <img src="/Cardano.svg" style={{height:'25px', width:'25px', filter: 'contrast(200%)'}} />
                  </Stack>


                 <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"

                    spacing={0.5}

                    style={{width:'100%'}}
                  > 

                  <div></div>
                  
                  <span className="tokensText" style={{color:'#fdfdfc'}}>

                  {formattedClayAmount}

                  </span>

                  <img src="/CLAY_TOKEN_220x220.png" style={{height:'25px', width:'25px'}} />


                  </Stack>



                 <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"

                    spacing={0.5}

                    style={{width:'100%'}}
                  > 

                  <div></div>
                  
                  <span className="tokensText" style={{color:'#fdfdfc'}}>

                  {totalUnbaked}

                  </span>
                   <img src="/UNBAKED_CLAY_TOKEN_220x220.png" style={{height:'25px', width:'25px'}} />


                  </Stack>




                   </Stack> </div>
            </Grid>
            </Stack>

            </Grid>



            <Grid item xs={4}>

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', textAlign:'center', marginBottom:'5px'}}>    
                <div className="tokensText" style={{width:'100%',color:'#a8afa6',fontSize:'16px'}}>Active</div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="stakingAddress" style={{height:'40px',width:'40px'}}><Stack spacing={0} style={{overflowWrap:'anywhere'}}> <span className="tokensNumber" style={{color:'#e7e6d2'}}></span><span className="tokensText" style={{color:'white'}}>{isActive}</span> </Stack> </div>
            </Grid>
            </Stack>

            </Grid>


            </Grid>

            :

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', marginTop:'10%', marginBottom:'100px'}}>
                <button className="join-btn" onClick={() => { window.open('https://pool.pm/0236ec1a0769c1b1bd8b6400d2f0e8e206cccd77a3700e5b7cf172a8') }}><span className="join-txt">JOIN</span></button>
            </Grid>

            }

             </Grid>

            
            </Grid>
  );
}

export default StakingSideBar;
