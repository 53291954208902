import styled from "styled-components";


export const MainContainer = styled.div`
    display: flex;  
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50vw;
    height: 50vh;
    background: var(--background-pannel);
    padding: 2.5em 1em;
    margin-top: 5em;
    border-radius: 1em;
    backdrop-filter: blur(22px);
    contain: content;
    max-width: 60vw;
    max-height: 80vh;
    overflow-x: auto;
    @media screen and (max-width: 768px) {
        background: var(--background-progress);
        margin-top: 5em;
        overflow-y: auto;
        max-height: 70vh;
        padding: 2em;
        width: 70vw;
        margin-top: 2em;
        padding: 2.5em 2em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        max-height: 40vh;
        overflow-y: hidden;
        
    }
    `;

export const GIF = styled.img`
    width: 22%;
    height: auto;
    position: relative;
    top: -10%;
    @media screen and (max-width: 768px) {
        width: auto;
        height: 60%;
        top: -5%;
    }

    `;


    export const H1= styled.h1`
    font-family: PixelFont;
    font-size: clamp(1.5rem, 2.2vw, 3rem);
    color: var(--clayblue-secondary);
    text-align: center;
    text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.1rem;
    line-height: 0.5em;
    @media screen and (max-width: 768px) {
        font-size: clamp(1rem, 6vw, 3rem);
        line-height: 1em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        font-size: clamp(0.5rem, 3vw, 3rem);
    }
    `;