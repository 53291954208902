import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box, useProgress } from '@react-three/drei'

import { Html, ContactShadows, Float, useGLTF,  PresentationControls } from '@react-three/drei'

import CameraController from 'components/Virtual/Controllers/CameraController'
import Lights from 'components/Virtual/Lights/Lights'

import {Perf} from 'r3f-perf'

import Fog from 'components/Virtual/Fog/Fog'

import { Debug, Physics, RigidBody, BallCollider, CapsuleCollider, CuboidCollider, useRapier } from '@react-three/rapier'

import { EffectComposer, DepthOfField, Bloom, Noise, Vignette, GodRays, ToneMapping, SSAO, SMAA, Outline, SSR, BrightnessContrast, HueSaturation } from '@react-three/postprocessing'

import { BlurPass, Resizer, KernelSize, BlendFunction } from 'postprocessing'

import * as THREE from "three";

import UIAvatarSelect from  './UI/UIAvatarSelect.jsx'

import SceneAvatarSelect from './Scene/Experience.jsx'

import { OrbitControls, Sparkles, Stars } from '@react-three/drei'

import { MainExperienceContext } from 'components/Context/ExperienceContext'
import { MainInterfacesContext } from 'components/Context/InterfacesContext'

import {ClaimContext} from 'components/Context/ClaimContext'

import Island from './Scene/Island'

import World from 'components/Virtual/World'




function Effects(props){

  const {sunRef} = props


 
  return(
    <>


    <EffectComposer autoClear={true} multisampling={20}>
       
       <DepthOfField 
          focusDistance={0.01} 
          focalLength={0.4} 
          bokehScale={3} 
        />


     {/* {typeof(sunRef.current) != 'undefined' ?


       <GodRays
          sun={sunRef.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}*/}


{/*
      {typeof(streetLightRef1.current) != 'undefined' ?

       <GodRays
          sun={streetLightRef1.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}*/}




     {/* {typeof(streetLightRef2.current) != 'undefined' ?

       <GodRays
          sun={streetLightRef2.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}
*/}


       {/* <HueSaturation
          blendFunction={BlendFunction.NORMAL} // blend mode
          hue={0.0} // hue in radians
          saturation={0.05} // saturation in radians
        />*/}

        {/* <SMAA /> */}

      {/* <Bloom
          intensity={1.0} // The bloom intensity.
          blurPass={undefined} // A blur pass.
          width={Resizer.AUTO_SIZE} // render width
          height={Resizer.AUTO_SIZE} // render height
          kernelSize={KernelSize.LARGE} // blur kernel size
          luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.5} // smoothness of the luminance threshold. Range is [0, 1]
        />*/}

     </EffectComposer>
     </>
  )
}




function Loader() {
  const { progress } = useProgress()

  // console.log('\n\n\nLOADING...\n\n\n')
  // console.log(progress)

  //return <Html center>{progress} % loaded</Html>
}


function SelectAvatarDisplay(props){

  const {meta} = props
  
  const [newMeta, setNewMeta] = useState({})
  const [prevMeta, setPrevMeta] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPrevLoaded, setIsPrevLoaded] = useState(false)
  const [displaySparkles, setDisplaySparkles] = useState(false)
  const [displayAvatar, setDisplayAvatar] = useState(false)



  useEffect(() => {

    if (isLoaded){
      setDisplaySparkles(true)
    }

    const interval = setInterval(() => {
      setDisplaySparkles(false);
    }, 2000);

    return () => clearInterval(interval);
  }, [isLoaded]);


  useEffect(() => {

    setDisplayAvatar(false)
    setIsLoaded(false)

    setNewMeta({...meta})

    // console.log('\n\n\nMETA CHANGED\n\n\n')

    // console.log('META')
    // console.log(meta)
    // console.log('CURRENT META')
    // console.log(newMeta)
    // console.log('PREV META')
    // console.log(prevMeta)


  },[meta])


  useEffect(() => {

    if (isLoaded){
      setPrevMeta({...meta})
      setIsPrevLoaded(false)
    }
    
  },[isLoaded])



  useEffect(() => {

    if (isLoaded){
      const interval = setInterval(() => {
        setDisplayAvatar(true);
      }, 1000);
      return () => clearInterval(interval);
  }

  }, [isLoaded]);




  return(
    <>


          <Suspense fallback={


           null

            
            // <Suspense fallback={null}>
            //   <SceneAvatarSelect key={'previousAvatar'} isVisible={isPrevLoaded} meta={prevMeta} isLoaded={isPrevLoaded} setIsLoaded={setIsPrevLoaded} />
            // </Suspense>
            

          }>
            <SceneAvatarSelect key={'currentAvatar'} isVisible={displayAvatar && isLoaded} meta={newMeta} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
          </Suspense>

      {/*
        <Suspense fallback={null}>
            <SceneAvatarSelect key={'previousAvatar'} isVisible={!displayAvatar && isPrevLoaded} meta={prevMeta} isLoaded={isPrevLoaded} setIsLoaded={setIsPrevLoaded} />
        </Suspense> 
      */}


          {displaySparkles && isLoaded ?
            <Sparkles count={50} scale={ 0.5 } size={5} speed={0.3} color='lime' opacity={0.7} position={[0,1,0]} />
          : null}

      </>
  )
}



function SelectAvatar(){

  const {activeScene, setActiveScene} = useContext(MainExperienceContext)
  //const {activeInterfaces, setActiveInterfaces} = useContext(MainInterfacesContext)
    
  const [meta, setMeta] = useState({})

  const streetLightRef1 = useRef()
  const streetLightRef2 = useRef()


  const [initialPlayerRotation] = useState([0,0,2])
  const [initialPlayerPosition] = useState([17.25,10,10])


  useEffect(() => {

    setActiveScene('SelectAvatar')

  },[])


  // console.log('\n\n\nHere SelectAvatar\n\n\n')

  return (


   <>



   <div style={{height:'100%', width:'100%', backgroundColor:'', cursor:'none'}}>



   {activeScene === 'SelectAvatar' ?

   <>



   <UIAvatarSelect meta={meta} setMeta={setMeta} />

   
      <Canvas 
        shadows

        camera={{ 
          fov: 50,
          near: 0.1,
            far: 1000,
        }}
      >
      
      {/*<Perf />*/}

      {/*<Environment files="HDRI/AvatarSelection/test7.hdr" background blur={0.4} />*/}

      <Environment files="https://claynation.nyc3.cdn.digitaloceanspaces.com/HDRI/AvatarSelection/HDRI1.HDR" background />

        <ambientLight color={0x404040} intensity={10} />
        <pointLight shadow-bias={-0.005}  color="#ff9bc9" position={[10, 10, 0]} intensity={0.8} distance={12} />
        <pointLight shadow-bias={-0.005}  color="#b4d8e7" position={[0, 10, 10]} intensity={0.8} distance={12} />
        <pointLight shadow-bias={-0.005}  color="#ff9bc9" position={[0, 10, 0]} intensity={0.8} distance={12} />

       <directionalLight 
          color={'#fcf2d9'}
          castShadow
          position={[1.8, 7, 10.5]} 
          intensity={1.3}
          shadow-mapSize={ [2048*2, 2048*2] }
          shadow-camera-near={ 0.01 }
          shadow-camera-far={ 20 }

          shadow-camera-top={ 5 }
          shadow-camera-right={ 5 }
          shadow-camera-bottom={ -5 }
          shadow-camera-left={ -5 }
          shadow-bias={-0.001} 
        />


        <Fog color={"#FFCBA4"} density={0.01} />

         <group position={[0,0,0]}>


    <OrbitControls 
      maxDistance={5+20} 
      minDistance={2.6} 
      enableDamping={true}  
      dampingFactor={0.05} 
      enablePan={false} 
      target={[0,1,0]} 
      minPolarAngle={0.5}
      maxPolarAngle={1.7}
    />
     
    <Float rotationIntensity={ 0.05 }>

    <group>
      
      <SelectAvatarDisplay meta={meta} />

      <Island streetLightRef1={streetLightRef1} streetLightRef2={streetLightRef2} />

     
    </group>

    
      </Float>


      </group>

      {/* <Effects /> */}
         
     </Canvas>

     </>

     : activeScene === 'StoreScene' || activeScene === 'ClayPay' ?


      <World initialPlayerPosition={initialPlayerPosition} initialPlayerRotation={initialPlayerRotation} />


     : null}

    </div>


    </>


  );
}

export default SelectAvatar;
