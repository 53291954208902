import { useState, useEffect, useContext } from 'react'

import { MainShoppingContext } from 'components/Context/ShoppingContext'
import { MainInterfacesContext } from 'components/Context/InterfacesContext'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import './Cart.css'

function Cart(props) {

  const {cartItems,
         clayPayOpen, 
         setClayPayOpen} = props

  const [items, setItems] = useState(cartItems)
  
  useEffect(() => {

    setItems(cartItems)

  },[cartItems])




  const theme = useTheme();

const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
const matchesM = useMediaQuery(theme.breakpoints.up('md'));
const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


return(
  
  <>

    <div style={{position:'absolute', zIndex:'100', top:'110px', right:matchesS ? '9%' : '50px', width:'100px', height:'100px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}
     
      onClick={()=>{

        setClayPayOpen(!clayPayOpen)

        // //console.log('CART')
        // let temp_activeInterfaces = {...activeInterfaces}
        // delete temp_activeInterfaces['cashierInterface']
        // temp_activeInterfaces['clayPay'] = {}
        // setActiveInterfaces(temp_activeInterfaces)

      }}

    >

      {/*<img className='cartImg' src={items.length > 0 ? "Interfaces/Cart/cart.svg" : "Interfaces/Cart/cart_empty.svg"} />*/}

      <img className='cartImg' src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/cart.svg"} />

      {items.length >= 0 ?

        <div className='itemsNumber'>
          {items.length}
        </div>

        : null}

    </div>

  </>
  
  )
}

export default Cart;
