import { useState } from 'react'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import cn_rarities from '../cn_rarities.json'
import gc_rarities from '../gc_rarities.json'
import pitches_rarities from '../pitches_rarities.json'
import pants_rarities from '../pants_rarities.json'

import './Filters.css'

export function Attribute(props){

  const checkedAttributes = props.checkedAttributes
  const setCheckedAttributes = props.setCheckedAttributes
  const attribute = props.attributeName
  const rarity = props.attributeRarity
  const collection = props.collection
  const category = props.category

  const setSearchBarMode = props.setSearchBarMode

  const [isChecked, setIsChecked] = useState(false)

  return(
       <Grid container style={{
            background: 'linear-gradient(0deg, #364B5D, #364B5D), #000000',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.13)',
            alignItems: 'center',
            width: '100%'
           

       }}>


        <Grid item xs={12}>
          <button onClick={() => {

            setSearchBarMode(false)

            let cur_attribute = collection + '_' + category + '_' + attribute.split(' ').join('_')

            //console.log(cur_attribute)

            let temp_checked_attributes = [...checkedAttributes]

            if (checkedAttributes.includes(cur_attribute)){
              //console.log(checkedAttributes)
              //console.log('includes')

              

              let indexToRemove = temp_checked_attributes.indexOf(cur_attribute)

              temp_checked_attributes.splice(indexToRemove,1)

              //console.log(temp_checked_attributes)

            }else{

               //console.log(checkedAttributes)

               temp_checked_attributes.push(cur_attribute)

              

            }

             setCheckedAttributes(temp_checked_attributes)


            }} 



          style={{backgroundColor: 'transparent', width:'100%', minHeight:'60px', cursor:'pointer'}} className="dropdown-atr"> 





          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >



          <div style={{textAlign:'start', display:'flex', width:'100%', marginLeft:'10px', overflowWrap: 'break-word'}}>



          <span className="filter-txt1" style={{fontSize:'16px', fontFamily:'shortstack',color: 

          checkedAttributes.includes( (collection + '_' + category + '_' + attribute.split(' ').join('_')) ) && collection == 'cn' ? '#f97453' 

          : 

          checkedAttributes.includes( (collection + '_' + category + '_' + attribute.split(' ').join('_')) ) && collection == 'gc' ? '#15a5d1' 



          : 'bisque'}}


          >



          {attribute}</span> 
          </div>

          <span className="filter-txt1" style={{fontSize:'16px', color:

           checkedAttributes.includes( (collection + '_' + category + '_' + attribute.split(' ').join('_')) ) && collection == 'cn' ? '#f97453' 

          : 

          checkedAttributes.includes( (collection + '_' + category + '_' + attribute.split(' ').join('_')) ) && collection == 'gc' ? '#15a5d1' 



          : 'bisque'
          }}>{rarity !== '' ? '('+rarity+')' : null}</span>
          


          <div style={{marginRight:'10px', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/checkbox.svg" style={{width:'20px',height:'20px'}} />

          {
            checkedAttributes.includes( (collection + '_' + category + '_' + attribute.split(' ').join('_')) ) ? 

             <img src={collection === 'cn' || collection === 'gc' || collection === 'pitches' ? "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/check_"+collection+".svg" : "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/check_cn.svg"} style={{position:'absolute', marginLeft:'0px', marginTop:'0px',width:'18px',height:'18px'
}} />

             : null

          }
          </div>

         
         </Stack>

          </button>
        </Grid>
       </Grid>
      
    )
}



export function CollectionAttributes(props){
  const isOpen = props.isOpen
  const collection = props.collection
  const category = props.category

  const rarity = collection == 'cn' ? cn_rarities : collection == 'gc' ? gc_rarities : collection == 'pants' ? pants_rarities : collection == 'pitches' ? pitches_rarities : {}

  const checkedAttributes = props.checkedAttributes
  const setCheckedAttributes = props.setCheckedAttributes

  const setSearchBarMode = props.setSearchBarMode

  return(

   <div>

  
    {isOpen ? 
      rarity[category].map((attribute) =>{
        return(

      
        <Attribute setSearchBarMode={setSearchBarMode} collection={collection} category={category} checkedAttributes={checkedAttributes} setCheckedAttributes={setCheckedAttributes} attributeRarity={attribute[1]} attributeName={attribute[0]} key={collection + category +  attribute[0]} />
 
        )
      })

   
    : null}

   </div> 

    )
} 




function Attributes(props) {

  const openCategories = props.openCategories
  const category = props.category
  const checkedAttributes = props.checkedAttributes
  const setCheckedAttributes = props.setCheckedAttributes

  const openCategoryTabs = props.openCategoryTabs
  const setOpenCategoryTabs = props.setOpenCategoryTabs

  const setSearchBarMode = props.setSearchBarMode

  const collection = props.collection



  return (
    <div>
      {openCategories && openCategories.includes(category) ?
      <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginLeft:'10px'}}>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >

        <div style={{width:'230px'}}>
            <CollectionAttributes setSearchBarMode={setSearchBarMode} isOpen={true} collection={collection} category={category} checkedAttributes={checkedAttributes} setCheckedAttributes={setCheckedAttributes} />
        </div>


      </Stack>


      </div>


      : null}
      </div>
  );
}

export default Attributes;
