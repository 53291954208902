import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box, useProgress, MeshReflectorMaterial } from '@react-three/drei'

import * as THREE from "three";

import {vertexShader, fragmentShader} from './shaders/shader'

function Clouds(props){

  const cloudRef = useRef()



 const [clouds, setClouds] = useState([])

  
  function getClouds(){

    const rotationSpeed = 0.1
    const segmentsNum = 20
    const cloudOpacity = 0.3

    let tempClouds = []
    for (let i = 0; i < 1; i++){

      let rand_factor = 1 + Math.random() 
      const scaleValue = 1 * rand_factor

      const minX = -20
      const minY = 8
      const minZ = -20

      const maxX = 20
      const maxY = 8
      const maxZ = 20

      let x_rand = Math.floor(minX + Math.random() * (maxX - minX + 1))
      let y_rand = Math.floor(minY + Math.random() * (maxY - minY + 1))
      let z_rand = Math.floor(minZ + Math.random() * (maxZ - minZ + 1))

      

      tempClouds.push(<Cloud position={[x_rand, y_rand, z_rand]} scale={scaleValue} speed={rotationSpeed} segments={segmentsNum} opacity={cloudOpacity} />)
      tempClouds.push(<Cloud position={[-x_rand, y_rand, z_rand]} scale={scaleValue} speed={rotationSpeed} segments={segmentsNum} opacity={cloudOpacity} />)
      tempClouds.push(<Cloud position={[x_rand, y_rand, -z_rand]} scale={scaleValue} speed={rotationSpeed} segments={segmentsNum} opacity={cloudOpacity} />)
      tempClouds.push(<Cloud position={[-x_rand, y_rand, -z_rand]} scale={scaleValue} speed={rotationSpeed} segments={segmentsNum} opacity={cloudOpacity} />)

      /*
      tempClouds.push(<Cloud position={[100+40*i, 20, 0]} />)
      tempClouds.push(<Cloud position={[-(100+40*i), 20, 0]} />)
      tempClouds.push(<Cloud position={[0, 20, 100 +40*i]} />)
      tempClouds.push(<Cloud position={[0, 20, -( 100 + 40*i)]} />)
      */

    }

    setClouds(tempClouds)

  }



   useEffect(()=>{
    getClouds()
  },[])



  return(

    <>

      <group position={[20,40,-80]}>
      
      {
        clouds.map((cloud) => {
          return(cloud)
        })
      }

    </group>

    </>


    // <group position={[0,2,0]}>

    //   <mesh>

    //     <boxGeometry args={[2,2,2]} />
    //     {/*<meshStandardMaterial color={'white'} />*/}
    //     <shaderMaterial

    //       uniforms={{
           
    //       }}

    //       vertexShader={vertexShader}
    //       fragmentShader={fragmentShader}

    //     />

    //   </mesh>

    // </group>


  )
}

export default Clouds;