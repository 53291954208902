import styled from "styled-components";


export const MessageWrapper = styled.div`
width: fit-content;
height: auto;
background-color: var(--claybutton-secondary);
color: var(--claytext-primary);
font-family: 'ShortStack';
border: none;
border-radius: 0.2em;
box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
contain: content;
padding: 2rem;
display: flex;
flex-direction: column;
align-items: center;
@media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0.5rem;
    width: 80%;
    height: auto;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    padding: 0.5rem;
    width: 70%;
} 
`;

export const H1 = styled.h1`
font-size: clamp(0.5rem, 2.2vw, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';

@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 4.5vw, 3rem);
} 
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.5vw, 3rem);
    padding: 0;
}  
`;

export const H2 = styled(H1)`
font-size: clamp(0.5rem, 1.4vw, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
padding-bottom: 1rem;
text-shadow: 0px 0.1rem 0px rgba(0, 0, 0, 0.3);
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3.5vw, 3rem);
    padding: 1rem 1rem; 
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.2vw, 3rem);
    padding: 0;
}     
`;

export const H3 = styled(H2)`
color: var(--claygreen);
letter-spacing: 0.1rem;
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 3rem);
} 
`;


export const P = styled.p`
font-size: clamp(0.5rem, 0.8vw, 3rem);
letter-spacing: 0.05rem;
opacity: 0.9;
overflow-wrap: break-word;
padding: 1rem;
min-width:0;
font-family: 'ShortStack';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2vw, 3rem);   
    width: 90%; 
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1vw, 3rem); 
    padding: 0.2rem;
}  
`;

export const ModalDefault = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0.5rem;
    @media screen and (max-width: 768px) and (orientation: landscape) {
        padding: 0;
    } 
    @media screen and (max-width: 768px) and (orientation: landscape) {
        height: 100%;
        padding: 0rem;
    }  
`;




