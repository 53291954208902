import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';


function Fog(props) {

  const fogColor = props.color

  const density = props.density //0.035

  const fog = useRef()

return(
    <>  
      <fogExp2 attach="fog" color={fogColor} density={density} />
    </>
  )
}

export default Fog;
