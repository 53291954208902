import { Canvas } from '@react-three/fiber';
import {sRGBEncoding, AxesHelper, GridHelper} from 'three';
import { useThree } from '@react-three/fiber';
import React from "react";
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useState, useRef, useEffect } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery';

import pixel_to_plot from "./pixel_to_plot";

const MapPlane = (props) => {
 
  const colorMap = useLoader(TextureLoader, 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CN_OFFICIAL_LAYOUT.png')
  //const colorMap = useLoader(TextureLoader, 'ClayMap2.png')
  //const colorMap = useLoader(TextureLoader,   'CLAY_MAP_COLOR_GRADE.png')
  //const colorMap = useLoader(TextureLoader, 'CN_OFFICIAL_LAYOUT_T_B.png')

  colorMap.encoding = sRGBEncoding;


  const [clickDistance, setClickDistance] = useState(null)
  
  const onPlotClick = props.onPlotClick
  const setSelectedPlot = props.setSelectedPlot
  const setRightSideBarOpen = props.setRightSideBarOpen
  const setPlot = props.setPlot

  const opacityFilter = props.opacityFilter


  const mapCenter = {'x':662,'y':442}


  return (


   <mesh rotation={[0, 0, 0]} position={[0, 0, 0]}

    onPointerDown = {(event) => {
            //setInterruptSelect(false)
            setClickDistance(event.distance)
        }}


     onPointerUp = {(event) => {

            //console.log(event)

            const release_distance = event.distance

            if (Math.abs(release_distance-clickDistance) <= 0.002){

            const res = 8192
            let pixelX = Math.round(event.uv.x*res)
            let pixelY = (res - Math.round(event.uv.y*res));


            let pixelCoordX = Math.floor(pixelX / (res / 1024))
            let pixelCoordY = Math.floor((pixelY / (res / 1024)))

            //console.log('Pixel Coord X: ' + pixelCoordX + '\nPixel Coord Y: ' + pixelCoordY)
            const pixelKey = "("+String(pixelCoordX)+","+String(pixelCoordY)+")"
            //console.log(pixelKey)
            //console.log(pixel_to_plot)

            try{

              const plotInfo = pixel_to_plot[pixelKey]

              //console.log(plotInfo)


              let coordX = plotInfo.start[0] - mapCenter.x
              let coordY = - (plotInfo.start[1] - mapCenter.y)

              //console.log('pixelX: ' + pixelX+'\npixelY: ' + pixelY)
              //console.log('X: ' + coordX)
              //console.log('Y: ' + coordY)


              const plotSizes = {1:[0.0465,0.0465,0.01], 4:[0.115,0.115,0.01], 9:[0.175,0.175,0.01]}

              let plotSize = plotSizes[plotInfo.length]


              let positionX = (plotInfo.start[0])*(res/1024)/res * 64 - 64/2 + plotSize[0] / 2 + 0.0075
              let positionY = 64/2 - plotInfo.start[1]*(res/1024)/res * 64 - plotSize[1] / 2 - 0.0075
              const positionZ = 0



              //if (heightPointer){
              //  plotSize = [plotSize[0], plotSize[1], plotSize[2] + heightPointer]
              //}

              //console.log('Position X: ' + positionX + '\nPosition Y: ' + positionY)
              
              onPlotClick([positionX, positionY, positionZ],plotSize)

              setSelectedPlot('('+String(coordX)+','+String(coordY)+')')
              setRightSideBarOpen(true)




              //console.log('Position X: ' + positionX + '\nPosition Y: ' + positionY)

              //onPlotClick([positionX, positionY, positionZ],plotSize)
          }
          catch{
            //console.log('No plots here...')
            setSelectedPlot(null)
            //onPlotClick([0, 0, 0],[0,0,0])
            setPlot(null)
          }
          }

          }}


   >

    <planeBufferGeometry attach="geometry" args={[64, 64, 8, 8]} />


    <meshBasicMaterial map={colorMap}
      opacity={opacityFilter ? 0.4 : 1}
      transparent={true} />




  {/*
	<mesh rotation={[0, 0, 0]} position={[0, 0, 0]}
	
	   onPointerDown = {(event) => {
            //setInterruptSelect(false)
            setClickDistance(event.distance)
        }}

            onPointerUp = {(event) => {

            //console.log(event)

            const release_distance = event.distance

            if (Math.abs(release_distance-clickDistance) <= 0.002){

            const res = 8192
            let pixelX = Math.round(event.uv.x*res)
            let pixelY = (res - Math.round(event.uv.y*res));


            let pixelCoordX = Math.floor(pixelX / (res / 1024))
            let pixelCoordY = Math.floor((pixelY / (res / 1024)))

            //console.log('Pixel Coord X: ' + pixelCoordX + '\nPixel Coord Y: ' + pixelCoordY)
            const pixelKey = "("+String(pixelCoordX)+","+String(pixelCoordY)+")"
            //console.log(pixelKey)
            //console.log(pixel_to_plot)

            try{

              const plotInfo = pixel_to_plot[pixelKey]

              //console.log(plotInfo)


              let coordX = plotInfo.start[0] - mapCenter.x
              let coordY = - (plotInfo.start[1] - mapCenter.y)

              //console.log('pixelX: ' + pixelX+'\npixelY: ' + pixelY)
              //console.log('X: ' + coordX)
              //console.log('Y: ' + coordY)


              const plotSizes = {1:[0.0465,0.0465,0.01], 4:[0.115,0.115,0.01], 9:[0.175,0.175,0.01]}

              let plotSize = plotSizes[plotInfo.length]





              let positionX = (plotInfo.start[0])*(res/1024)/res * 64 - 64/2 + plotSize[0] / 2 + 0.0075
              let positionY = 64/2 - plotInfo.start[1]*(res/1024)/res * 64 - plotSize[1] / 2 - 0.0075
              const positionZ = 0



              //if (heightPointer){
              //  plotSize = [plotSize[0], plotSize[1], plotSize[2] + heightPointer]
              //}

              //console.log('Position X: ' + positionX + '\nPosition Y: ' + positionY)
              
	      onPlotClick([positionX, positionY, positionZ],plotSize)

              setSelectedPlot('('+String(coordX)+','+String(coordY)+')')
              setRightSideBarOpen(true)




              //console.log('Position X: ' + positionX + '\nPosition Y: ' + positionY)

              //onPlotClick([positionX, positionY, positionZ],plotSize)
          }
          catch{
            console.log('No plots here...')
            setSelectedPlot(null)
            //onPlotClick([0, 0, 0],[0,0,0])

            setPlot(null)
          }
          }

          }}

	  >

	  <planeBufferGeometry attach="geometry" args={[64, 64, 8, 8]} />

transparent={true} />
<meshBasicMaterial map={colorMap}
transparent={true} />
        </mesh>


      */}

      </mesh>
  );
};

export default MapPlane;
