import React from 'react'
import { useState, useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import './VotePopUp.css'
import './AlertPopUp.css'



function AlertPopUp(props) {

    const isAlertOpen = props.isAlertOpen 
    const setIsAlertOpen = props.setIsAlertOpen
    const submittedTx = props.submittedTx

    const isSuccessful = true 



  return (

  


   
<div>

 
{isAlertOpen ?

<Grid container justifyContent="center" alignItems="center">


    <div className='popup' style={{width:'100%', height:'100%', zIndex:'1350', overflow:'scroll'}}  > 
 <Grid container justifyContent="center" alignItems="center" style={{display:'flex',position:'absolute', top:'25%', height:'40%'}}>
   
        <div className='popup-inner'>

        <button className='close-btn' onClick={() => {
           
           setIsAlertOpen(false)

           }}>X</button> 

        <Grid container justifyContent="center" alignItems="center" style={{height:'100%', width:'100%'}}>

            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}

              style={{height:'60%', width:'80%'}}
            >


         {isSuccessful ?
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', objectFit: 'cover', minWidth:'200px', width:'40%', height:'40%'}}>
             <img src="/success.png" style={{width:'100%', height:'auto', minWidth:'60px', maxWidth:'15vh'}}/>
            </div>
         : 

         null

        }
        
          {/*
      
                <div className='Question' style={{color:'bisque'}}><h3>{voteProposal.proposal}</h3></div>
          */}

            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}

              style={{width:'80%'}}
            >


            <h1>Transaction has been succesfully submitted!</h1>

            <h3>Once confirmed on-chain you can view it <br/><button className="here" 
            onClick={()=>{
                window.open("https://cardanoscan.io/transaction/" + submittedTx)

            }}>
            <span className="view-here">here</span></button></h3>


            {/*
         
           {voteProposal.options.map(option => (

           <button className={(Object.keys(option)[0]) == selectedButton ? 'selectedButton' : 'black-btn'} key={Object.keys(option)[0]} onClick={() => {
            setSelectedButton(Object.keys(option)[0])
            setTempOption({option:option,proposal_id:voteProposal.proposal_id})}}>{option[Object.keys(option)[0]]}</button>
           ))}

       */}



            </Stack>



            </Stack>




        </Grid>



        </div>


 </Grid>
    </div>
   

</Grid>


:
null}





   </div>





  

  )
}

export default AlertPopUp




