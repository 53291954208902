import { useState, useEffect, useContext } from 'react'

import Drawer from '@mui/material/Drawer';

import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { IconButton } from '@mui/material';


import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';


import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';

import ClaimClay from './ClaimClay'

import Attributes from './Attributes'
import StakingSideBar from './StakingSideBar'

import './SideBar.css'
import './StakingSideBar.css'
import './KilnTransition.css'

import './BakingSideBar.css'
import './ResetAllButton.css'
import './Filters.css'
import './TotalBaked.css'
import './SearchBar.css'

import {MainClaimContext} from './ClaimContext'

import styled from "styled-components";



const KilnTransition = styled.div`

    position: absolute;
    
    animation: ${props => props.animation};



    @keyframes appear {
        0%   { opacity: 1}
        100% { opacity: 0}
    }

    @keyframes disappear {
        0%   { opacity: 0}
        100% { opacity: 1}
    }

    @keyframes off {
        0%   { opacity: 1}
        100% { opacity: 1}
    }

    @keyframes on {
        0%   { opacity: 0}
        100% { opacity: 0}
    }

`

const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});


function SideBar(props) {

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const [totalClay, setTotalClay] = useState(0) 

  const isOpen = props.isOpen
  const setIsOpen = props.setIsOpen

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [activeTab, setActiveTab] = useState(props.sideBarTab)


   const txsBaking = props.txsBaking
   const txsCompleted = props.txsCompleted
   const totalBaked = props.totalBaked
   const totalUnbaked = props.totalUnbaked
   const setTotalUnbaked = props.setTotalUnbaked
   const stakeADAAmount = props.stakeADAAmount
   const setStakeADAAmount = props.setStakeADAAmount

   const stakedWithClay = props.stakedWithClay
   const setStakedWithClay = props.setStakedWithClay

   const formattedClayAmount = props.formattedClayAmount
   const setFormattedClayAmount = props.setFormattedClayAmount

   const setCurrentSlot = props.setCurrentSlot


   const fetchingFilteredAssets = props.fetchingFilteredAssets
   const setFetchingFilteredAssets = props.setFetchingFilteredAssets


  const setSideBarTab = props.setSideBarTab

  const sideBarTab = props.sideBarTab

  const assetsOwned = props.assetsOwned

  const currentSlot = props.currentSlot

  const isAccountView = props.isAccountView

  const hasBaking = props.hasBaking


  const isAlertOpen = props.isAlertOpen 
  const setIsAlertOpen = props.setIsAlertOpen

  const submittedTx = props.submittedTx
  const setSubmittedTx = props.setSubmittedTx

  const searchBarMode = props.searchBarMode
  const setSearchBarMode = props.setSearchBarMode

  const matchesXS = props.matchesXS
  const matchesS = props.matchesS
  const matchesM = props.matchesM
  const matchesL = props.matchesL

  const setLeaderboardData = props.setLeaderboardData

  const isAnalytics = props.isAnalytics


  const [selectedToBake, setSelectedToBake] = useState("0")

  const [selectedCategory, setSelectedCategory] = useState()
  const [openCategories, setOpenCategories] = useState([])

  const [checkedAttributes, setCheckedAttributes] = useState([])

  const recordsPage = props.recordsPage
  const setRecordsPage = props.setRecordsPage

  const [includeCn, setIncludeCn] = useState(true)
  const [includeGc, setIncludeGc] = useState(true)

  const [openCategoryTabs, setOpenCategoryTabs] = useState([])

  const leaderboardPage = props.leaderboardPage
  const setLeaderboardPage = props.setLeaderboardPage

  const [tempFilteredAssets, setTempFilteredAssets] = useState({"assets":[],"filters":[]})

  const [lastUpdateSlot, setLastUpdateSlot] = useState(0)


  const [kilnAnimation, setKilnAnimation] = useState('flames 2s linear infinite alternate-reverse both 0.2s')

  const [flamesVisible, setFlamesVisible] = useState(false)


  const [neverBakedCN, setNeverBakedCN] = useState('-')
  const [neverBakedGC, setNeverBakedGC] = useState('-')






 
  const selectedStyle = {
    width:"7vw",
    minWidth: "75px",
    backgroundColor: "#f28a80",
    boxShadow: "3px 0px 2px rgba(0, 0, 0, 0.11), inset -6px 4px 6px rgba(255, 156, 131, 0.71), inset -6px -5px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: "200px",
    overflow: "hidden"
  }


  const notSelectedStyle = {
    width:"7vw",
    minWidth: "75px",
    height: "5vh",
    minHeight:'40px',
    background: "rgba(201, 76, 76, 0)",
    borderRadius: "200px"
  }


  /*

  const categories = [
    {body:[]},
    {clothes:[]},
    {hat:[]},
    {hair:[]},
    {eyes:[]},
    {eyebrows:[]},
    {mouth:[]},
    {accessories:[]},
    {background:[]}
    {wings:[]},
    {extra:[]}
  ]

*/


  const categories = {
    body: {cn:[],gc:[]},
    clothes: {cn:[],gc:[]},
    hat:{cn:[],gc:[]},
    hair:{cn:[],gc:[]},
    eyes:{cn:[],gc:[]},
    eyebrows:{cn:[],gc:[]},
    mouth:{cn:[],gc:[]},
    accessories:{cn:[],gc:[]},
    background:{cn:[],gc:[]},
    wings:{cn:[],gc:[]},
    extra:{cn:[],gc:[]}
  }
  
/*

     function updateSideBarTab(sideBarTabName){
        //console.log('update sideBarTab')
        //console.log(sideBarTabName)
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('sideBarTab') + " = " + String(sideBarTabName) + '; ' + expires + "; path=/"
  }
*/


  async function fetchBakedAssetsInfo(){
    //https://claymarket.io/api/baked-assets-info/

    const baked_assets_info = await fetch('https://claymarket.io/api/baked-assets-info/', {
        method: 'GET',
    })

    const baked_assets_info_json = await baked_assets_info.json()
    return baked_assets_info_json
  }


  async function updateBakedAssetsInfo(){

    try{
        let bakedInfo = await fetchBakedAssetsInfo()
        
        if (bakedInfo.hasOwnProperty("cn_baked")){
            let cn_baked_num = parseInt(bakedInfo["cn_baked"], 10)
            setNeverBakedCN(10000 - cn_baked_num)
        }

        if (bakedInfo.hasOwnProperty("gc_baked")){
            let gc_baked_num = parseInt(bakedInfo["gc_baked"], 10)
            setNeverBakedGC(9427-gc_baked_num)
        }

    }catch{
        //ignore...
    }

  }


  useEffect(() => {
    updateBakedAssetsInfo()
  },[])





  const getAmount = (clayInfo, cur_asset) => {
  if (clayInfo && currentSlot){
    if (clayInfo.processing_tx == null){
        let rate_per_slot = clayInfo.rate / 86400


        let tempSlot = currentSlot
        if (cur_asset.startsWith('cn')){
              if (currentSlot > 134345140){
                tempSlot = 134345140
              }
            }else if (cur_asset.startsWith('gc')){
              if (currentSlot > 138920740){
                tempSlot = 138920740
              }
            }


        let slots_passed = parseInt(tempSlot) - parseInt(clayInfo.slot) - 1209600
        let total = slots_passed * rate_per_slot
        total = total.toFixed(0)

        if (total < 0){
          total = 0
        }

        return total
    }

    else{
      return 0
    }
  }else{
    return 0
  }
 }


  useEffect(() => {
    props.setSideBarTab(activeTab);
  }, [activeTab]);


     function updateSideBarTab(sideBarTabName){

        //console.log('update sideBarTab')
        //console.log(sideBarTabName)
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('sideBarTab') + " = " + String(sideBarTabName) + '; ' + expires + "; path=/"

  }


   function checkSideBarTab(){
        let cookie = document.cookie
        let cookies = cookie.split(';')
        //console.log('SIDE BAR COOKIES')
        //console.log(cookies)
        let cookies_dict = {}
        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')
            cookies_dict[entry[0]] = entry[1]
        }
       return cookies_dict['sideBarTab']
  }




  useEffect(() => {
    let sideBarTabName = activeTab
    //console.log('SIDE BAR TAB')
    if (typeof(sideBarTabName) != 'undefined'){
        //console.log('UPDATING')
        //console.log(sideBarTabName)
        updateSideBarTab(sideBarTabName)
    }else{
        let cur_sideTab = checkSideBarTab()

        //console.log('undefined')
        //console.log(cur_sideTab)

        if (typeof(cur_sideTab) == 'string'){
                setActiveTab(cur_sideTab)
            }else{
                updateSideBarTab('Baking')
                setActiveTab('Baking')
        }
    }

  }, [activeTab])



   useEffect(() => {

    if (currentSlot && assetsOwned){
      let tempTotalUnbaked = 0
      let clays_info = assetsOwned['clays_info']
      for (let i = 0; i < assetsOwned['clays'].length; i++){

        let cur_asset = assetsOwned['clays'][i]
        //console.log(cur_asset)
        let clay_info = clays_info[cur_asset]
        let cur_asset_amount = getAmount(clay_info, cur_asset)
        tempTotalUnbaked = tempTotalUnbaked + parseInt(cur_asset_amount)

      }

      setTotalClay(assetsOwned['clayToken'])
      //console.log(assetsOwned['clayToken'])

      setTotalUnbaked(String(tempTotalUnbaked))
    }

  }, [assetsOwned])



  useEffect(() => {
     if (currentSlot && assetsOwned){

      let tempTotalToBake = 0

      let clays_info = assetsOwned['clays_info']

      for (let i = 0; i < selectedTokens.length; i++){
        let cur_asset = selectedTokens[i]
        let clay_info = clays_info[cur_asset]

         let cur_asset_amount = getAmount(clay_info, cur_asset)

         tempTotalToBake = tempTotalToBake + parseInt(cur_asset_amount)

      }

      setSelectedToBake(String(tempTotalToBake))

    }

  }, [selectedTokens])



useEffect(() => {
  if (isOpen == false && matchesM){
    setIsOpen(true)
  }
},[matchesM, matchesL])


function resetFilters() {
    setSearchBarMode(false)
    setRecordsPage(1)
    setLeaderboardPage(1)
    setCheckedAttributes([])
    setOpenCategories([])
    setIncludeCn(true)
    setIncludeGc(true)
    setOpenCategoryTabs([])
}


async function getCurrentSlot(){
        let slot = await fetch('https://clayapi.com/baking/slot/').then(response => response.json())
                                                                  .then(data => data)
        slot = parseInt(slot.slot)
        setCurrentSlot(slot) 
}


useEffect(() => {

    setRecordsPage(1)
    setLeaderboardPage(1)

}, [checkedAttributes])




async function performFetch(){
    setFetchingFilteredAssets(true)
    await fetchFilteredAssets(checkedAttributes, leaderboardPage)
    setFetchingFilteredAssets(false)
}


useEffect(() => {
    if(currentSlot){
        if (currentSlot - lastUpdateSlot >= 90){
        
         if (!searchBarMode){
            setLastUpdateSlot(currentSlot)
            performFetch()
         }
     }else{
        //do nothing...
     }

    }else{
        getCurrentSlot()
    }


}, [currentSlot])



useEffect(() => {

    if(currentSlot){
        if (!searchBarMode){
            performFetch()
         }
    }else{
        
        getCurrentSlot()
    }

},[checkedAttributes, leaderboardPage, includeGc, includeCn, isAccountView])



useEffect(() => {
    setRecordsPage(1)
    setLeaderboardPage(1)

},[includeGc, includeCn, checkedAttributes])




  async function fetchFilteredAssets(filter_list, page){
    if (isAccountView == true){
        return
    }

    let filtered_results;

    let initial_string = '?slot=' + currentSlot + '&page=' + page

    let query_string = ''


    for (let i = 0; i < filter_list.length; i++){
        if (filter_list[i].split('_')[0] == 'cn' && includeCn || filter_list[i].split('_')[0] == 'gc' && includeGc){
            query_string = query_string + '&f' + String(i) +'=' + filter_list[i]
        }
    }



    if (filter_list.length == 0 || query_string == ''){
        if (includeCn){
            query_string = query_string + '&cn=cn'
        }

        if (includeGc){
            query_string = query_string + '&gc=gc'
        }
    }

    query_string = initial_string + query_string

    let url_string = 'https://clayapi.com/filtering/' + query_string
    //let url_string = 'http://198.199.79.4/filtering/leaderboard/' + query_string

    //console.log(url_string)


    filtered_results = await fetch(url_string).then(response => response.json())
                                                             .then(data => data)

    if (!('error' in filtered_results)){
         let filtered_assets = filtered_results['assets']
         //console.log(filtered_assets)

         setTempFilteredAssets({"assets":filtered_assets, "filters":filter_list})
         //setLeaderboardData(filtered_assets)
         //return filtered_results
     }else{
        //console.log('ERROR')

        setTempFilteredAssets([])
        //setLeaderboardData([])

        //return []
     }
  }


  useEffect(() => {
    if (tempFilteredAssets['filters'] == checkedAttributes){
        setLeaderboardData(tempFilteredAssets['assets'])
    }
  },[tempFilteredAssets])





  return (

       <ThemeProvider theme={theme}>
        <Drawer 
            anchor='left'
            variant={matchesS ? 'persistent' : 'temporary'}
            open={isOpen}
            onClose={()=>{
              if (!matchesM){
                setIsOpen(false)
              }
            }}

            className='drawer'

            PaperProps={{
            sx: {
              width:'20vw', 
              height: '99%',
              minWidth:'340px',
              borderRadius: '0px 74px 74px 0px',
              backdropFilter: 'blur(85px)',
              border: 'double 3px transparent',
              borderLeft: '0px',
              backgroundImage: `linear-gradient(180deg, rgba(69, 62, 112, 0.95) 0%, #1D3A3D 100%), 
                    linear-gradient(rgba(239, 132, 99, 0.78) 4.03%, #CCA7B5 44.47%, rgba(96, 157, 183, 0.82) 100%)`,
              backgroundOrigin: `border-box`,
              backgroundClip: `content-box, border-box`
            }
          }} >

          {!matchesL ?
          <div style={{position: 'absolute', top:'30px', right:'20px', zIndex:'5'}}>
            <IconButton onClick={()=>{setIsOpen(false)}} style={{color:'white', height:'50px',width:'50px', fontFamily:'shortstack'}}>X</IconButton>
          </div>

          :null}

          <div  style={{position:'relative', display:'flex',justifyContent:'center',marginTop:'35px'}}>
              <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png' style={{height:'24vh', minHeight:'150px',width:'auto',zIndex:'1'}}/>
          </div>


           <Grid container spacing={2} style={{position:'relative',top:'1vh'}} >

 <Grid item xs={12} style={{position:'relative', width:'100%', display:'flex',justifyContent:'center', marginBottom:'5vh'}}>
        {isAccountView ?   
           <div className="triStateToggle">
              <Button style={activeTab=='Baking' ? selectedStyle : notSelectedStyle} onClick={() => setActiveTab('Baking')}><Typography variant="p" style={{color:'white'}}>Baking</Typography></Button>
              <Button style={activeTab=='Activity' ? selectedStyle : notSelectedStyle} onClick={() => setActiveTab('Activity')}><Typography variant="p" style={{color:'white'}}>Activity</Typography></Button>
              <Button style={activeTab=='Staking' ? selectedStyle : notSelectedStyle} onClick={() => setActiveTab('Staking')}><Typography variant="p" style={{color:'white'}}>Staking</Typography></Button>
          </div>
          : null}

   </Grid>
     
     {isAccountView && activeTab == 'Baking' ?

    <Grid container spacing={2}>
     

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>
                  <div className="totalTokens2"><Stack spacing={0}> <span className="totalNumber">{totalUnbaked}</span> <span className="totalText">TOTAL UNBAKED TOKENS</span> </Stack> </div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="tokens2"><Stack spacing={0}> <span className="tokensNumber">{selectedToBake}</span> <span className="tokensText">SELECTED TO BAKE</span> </Stack> </div>
            </Grid>

            <Grid item xs={12} style={{position:'relative', width:'100%', display:'flex',justifyContent:'center'}}> 
                  <div className="assets2"><Stack spacing={0}> <span className="assetsNumber">{selectedTokens.length}</span> <span className="assetsText">ASSETS SELECTED</span> </Stack> </div>  
            </Grid>
          

             <Grid item xs={12} style={{position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>
                    
                 
          
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10px'}}>
                        <img src="/kiln_off.png" style={{height:'180px'}} />
                        


                        <KilnTransition animation={kilnAnimation}>
                            <div className={flamesVisible || hasBaking ? "flamesVisible" : "flamesHidden"}>
                                 <img src="/flames.png" style={{height:'180px', marginTop:'4px'}} />
                            </div>
                        </KilnTransition>
                         

                    </div>


                  

        


                    {/*
                    
                    <Card style = {{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        width:'30vh',
                        minWidth: '180px'
                      }}>
                      <CardMedia
                        component="img"
                        height="100%"
                        image="/kiln2.png"
                        alt="kiln"
                      />
                    </Card>  
                */}
                    


            </Grid>

            <Grid item xs={12} style={{position:'relative', width:'100%', display:'flex',justifyContent:'center',marginBottom:'25px'}}>
                  <div className="toggleClass">
                     <ClaimClay hasBaking={hasBaking} flamesVisible={flamesVisible} setFlamesVisible={setFlamesVisible} setKilnAnimation={setKilnAnimation} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} /> 
                  </div>
            </Grid>

            </Grid>
            : 



            isAccountView && activeTab == 'Activity' ?

            <Grid container justifyContent="center" alignItems="center" spacing={0} style={{width:'100%', height:'90%', marginLeft:'17px'}}>


            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
                

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                  <div className="txsComplete"><Stack spacing={0}> <span className="tokensNumber" style={{color:'#00e600'}}>{txsCompleted}</span> <span className="tokensText" style={{color:'#00e600'}}>TRANSACTIONS COMPLETED</span> </Stack> </div>
            </Grid>

            <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>       
                  <div className="totalTokens2"><Stack spacing={0}> <span className="totalNumber" style={{color:'#00a7ff'}}>{txsBaking}</span> <span className="totalText" style={{color:'#00a7ff'}}>TRANSACTIONS BAKING</span> </Stack> </div>
            </Grid>



                <Grid item xs={12}>
                    <div className="totalBaked" style={{width:'98%'}}> 
                    <img src="/total_baked.png" className="tokenBakedImg" />
                    
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0}

                      style={{marginTop:'-43%'}}
                    >
                    
                    <div className="totalBakedTxt">TOTAL AMOUNT BAKED</div>
                    <div className="bakedBackground">
                        <div className="totalBakedNumber">{totalBaked}</div>
                    </div>

                    </Stack>

                    </div>
                </Grid>


                </Stack>



                {/*
                <Grid item xs={12}>
                 <Card style = {{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        width:'40vh',
                        minWidth: '180px',
                        backgroundColor:'green'
                      }} className="totalBaked">
                      <CardMedia
                        component="img"
                        height="100%"
                        image="/total_baked.png"
                        alt="tray"
                      />

                    <CardContent>

                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={0}
                      style={{marginTop:'-52%'}}
                      
                    >
                    
                    <div className="totalBakedTxt">TOTAL AMOUNT BAKED</div>
                    <div className="bakedBackground">
                        <div className="totalBakedNumber">{totalBaked}</div>
                    </div>

                    </Stack>



                    </CardContent>
                    </Card>  

                </Grid>
            */}


                
            </Grid>

            :

            isAccountView && activeTab == 'Staking' ?

 
      
           <div style={{marginLeft: '17px'}}>
                <StakingSideBar stakedWithClay={stakedWithClay} setStakedWithClay={setStakedWithClay} stakeADAAmount={stakeADAAmount} setStakeADAAmount={setStakeADAAmount} formattedClayAmount={formattedClayAmount} setFormattedClayAmount={setFormattedClayAmount} totalUnbaked={totalUnbaked} assetsOwned={assetsOwned} />
            </div>



            :





            !isAccountView && !isAnalytics ? 

                     <Grid container spacing={2}   
                         direction="row"
                          justifyContent="center"
                          alignItems="center"
                          style={{width:'100%'}}
                          > 





                <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  spacing={2}

                  style={{width:'80%', marginLeft:'70px'}}
                >


                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}

                  style={{width:'90%', textAlign:'start'}}

                >

                
                <div style={{color:'bisque',fontSize:'16px', fontFamily:'shortstack'}}>Clay Nation</div>
               

                <div>
                <div className={includeCn ? "cn-toggle-on" : "cn-toggle-off"} onClick={()=>{
                     
                    setSearchBarMode(false)

                    let cur_state = includeCn 

                    if (cur_state == true && includeGc == false){
                        setIncludeGc(true)
                    }

                    setIncludeCn(!includeCn)
                }}>
                     <div className={includeCn ? "notch-CN-on" : "notch-CN-off"}></div>
                </div>
                </div>



                

                </Stack>


              

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}

                  style={{width:'90%',textAlign:'start'}}

                >

               
                <div style={{color:'bisque',fontSize:'16px',fontFamily:'shortstack'}}>Clay Nation x<br/>Good Charlotte</div>
                
                <div>

                <div className={includeGc ? "gc-toggle-on" : "cn-toggle-off"} onClick={()=>{
                    
                    setSearchBarMode(false)
                    let cur_state = includeGc

                    if (cur_state == true && includeCn == false){
                        setIncludeCn(true)
                    }

                    setIncludeGc(!includeGc)
                }}>
                     <div className={includeGc ? "notch-GC-on" : "notch-CN-off"}></div>
                </div>

                </div>


                </Stack>


                </Stack>



                {Object.keys(categories).map((category)=>{
                    return(
                   

                    <Grid item xs={9} key={category} style={{marginBottom:'5px'}}>

                         <button className="filter-btn" style={{animationDelay:String((String(Object.keys(categories).indexOf(category))*0.15))+ "s"}}  onClick={() => {
                            let temp;
                            if (!openCategories.includes(category)){
                                temp = [...openCategories, category]
                                setOpenCategories(temp)
                            }else{
                                let indexToRemove = openCategories.indexOf(category)
                                temp = openCategories.splice(indexToRemove, 1)
                                setSelectedCategory(temp)
                            }
                        }

                        }>
                         <Stack direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}>
                             <span className="filter-txt" style={{fontFamily:'shortstack'}}>{category}</span>
                             <span style={{marginRight:'10px'}}><img src='/arrowdown.svg' style={{ transform: openCategories.includes(category) ?  'rotate(0deg)' : 'rotate(90deg)'}}/></span>
                         </Stack>

                         </button>

                         <Attributes key={"drop"+category} setSearchBarMode={setSearchBarMode} setOpenCategoryTabs={setOpenCategoryTabs} openCategoryTabs={openCategoryTabs} openCategories={openCategories} category={category} checkedAttributes={checkedAttributes} setCheckedAttributes={setCheckedAttributes} />

                    </Grid>
                 
                    )
                })

                }

                    <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center', marginLeft:'20px', marginTop:'10px', marginBottom:'40px'}}>
                        <button className="clear-btn" onClick={()=>{
                            resetFilters()
                        }}><span className="clear-txt" style={{fontFamily:'shortstack'}}>Reset all</span></button>
                    </Grid>
                </Grid>


            : !isAccountView && isAnalytics ? 

                <>

                {/*
                    <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>
                          <div className="totalTokens2"><Stack spacing={0}> <span className="totalNumber">{totalUnbaked}</span> <span className="totalText">TOTAL UNBAKED TOKENS</span> </Stack> </div>
                    </Grid>

                    <Grid item xs={12} style={{ position:'relative', width:'100%', display:'flex',justifyContent:'center'}}>                
                          <div className="tokens2"><Stack spacing={0}> <span className="tokensNumber">{selectedToBake}</span> <span className="tokensText">SELECTED TO BAKE</span> </Stack> </div>
                    </Grid>

                    <Grid item xs={12} style={{position:'relative', width:'100%', display:'flex',justifyContent:'center'}}> 
                          <div className="assets2"><Stack spacing={0}> <span className="assetsNumber">{selectedTokens.length}</span> <span className="assetsText">ASSETS SELECTED</span> </Stack> </div>  
                    </Grid>
                */}


                    <div style={{height:'500px', width:'100%', backgroundColor:'', 
                    display:'flex', flexDirection:'column', justifyContent:'start', alignItems:'center'}}>

                        <div style={{height:'255px', width:'100%', backgroundColor:'', 
                        display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>

                            <div className="tokensNumber" style={{fontSize:'25px', fontFamily:'ShortStack'}}>
                                NEVER Baked Assets
                            </div>

                            
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', 
                            alignItems:'center', height:'90px'}}>
                                <div className="assetsText" style={{fontSize:'24px', fontWeight:'', fontFamily:'shortstack'}}>
                                    Clay Nation: 
                                </div>

                                <div className="stakingAddress" style={{fontSize:'18px', fontFamily:'ShortStack', color:'bisque', width:'100px', height:'40px'}}>
                                    {neverBakedCN}
                                </div>
                            </div>

                            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', 
                            alignItems:'center', height:'90px'}}>
                                <div className="totalText" style={{fontSize:'24px', fontFamily:'ShortStack'}}>
                                    Good Charlotte: 
                                </div>

                                <div className="stakingAddress" style={{fontSize:'18px', fontFamily:'ShortStack', color:'bisque', width:'100px', height:'40px'}}>
                                    {neverBakedGC}
                                </div>
                            </div>

                        </div>

                    </div>

                </>

            : null

            }

           </Grid>          

          </Drawer>

             </ThemeProvider>
  )
}

export default SideBar