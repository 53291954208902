import { useState, useContext, useEffect } from 'react'


//import ClayCard from './ClayCard'
import AssetCard from './AssetCard'


import cn_meta from '../cn_db.json'
import gc_meta from '../gc_db.json'
import pitches_meta from '../pitches_db.json'
import pants_meta from '../pants_db.json'

import cn_rarity from '../cn_rarity.json'
import gc_rarity from '../gc_rarity.json'

import {Buffer} from 'buffer'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {MainClaimContext} from './ClaimContext'
import EmptyWallet from './EmptyWallet'
import Loading from './Loading'


import BuildTransaction from './BuildTransaction'

function WalletListings(props) {

    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

    const [listings, setListings] = useState({})

    const clay_token_id = '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535434c4159'


    const eligiblePolicies = {
                            "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"Clay Nation",
                            "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"CN x Good Charlotte",
                            "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"Pitches at Clay Nation",
                            "90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771":"Pants"
                         }



    const collections_meta = {
                            "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":cn_meta,
                            "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":gc_meta,
                            "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":pitches_meta,
                            "90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771":pants_meta
                        }


    const setSellAsset = props.setSellAsset
    const sellAsset = props.sellAsset

async function get_wallet_listings(){
    const max_listing_lovelace = 5000000
    const max_listing_clay_token = 0

    let asset_listing_info = await fetch('http://claymarkets.com/api/listings/owner/' + stake_address + '/').then(response => response.json())
                                                                  .then(data => data)
    //console.log(asset_listing_info)

    let temp_listings = []

    if (!('listings' in  asset_listing_info) || ('error' in asset_listing_info) ){
        //handle error here 
    }else{
        temp_listings= asset_listing_info['listings']
    }

    let listings_info = {}

    for (let i = 0; i < temp_listings.length; i++){
        //console.log(i)

        let cur_listing = temp_listings[i]

        //console.log(cur_listing)
        let cur_tx_id = cur_listing['tx_id']
        let cur_tx_index = cur_listing['tx_index']
        let cur_amount = cur_listing['amount']
        let cur_payout = cur_listing['payout']

        let isAmountCorrect;
        let amount_list = []

        for (let j = 0; j < cur_amount.length; j++){
            isAmountCorrect = false
            //console.log(cur_amount[j])
            let cur_unit = cur_amount[j]['unit']
            let cur_quantity = cur_amount[j]['quantity']

            if (cur_unit == 'lovelace'){
                if (parseInt(cur_quantity,10) > max_listing_lovelace){
                    //console.log('BP1')
                    break
                }
            }

            else if (cur_unit == clay_token_id){
                if (parseInt(cur_quantity,10) > max_listing_clay_token){
                    //console.log('BP2')
                    break
                }
            }


            else if (!(cur_unit.slice(0,56) in eligiblePolicies)){
                //console.log(cur_unit)
                //console.log('BP3')
                break
            }else{
                if (parseInt(cur_quantity,10) != 1){
                    // console.log('BP4')
                     break
                }else{
                    amount_list.push({unit:cur_unit, quantity:cur_quantity})
                }
            }
            
            //console.log('VALID LISTING')
            isAmountCorrect = true
            //VALID LISTING
        }

        if (isAmountCorrect){
            //console.log(amount_list)
        }else{
            //console.log("OH NO")
            continue
        }

        //console.log(cur_payout)

        let total_lovelace = 0
        let total_clay = 0

        let isPayoutCorrect;

        for (let k = 0; k < cur_payout.length; k++){
            isPayoutCorrect = false
            //console.log(cur_payout[k])

            let cur_payout_amount = cur_payout[k]

            if (cur_payout_amount['unit'] == "lovelace"){
                total_lovelace += parseInt(cur_payout_amount['quantity'], 10)

                if (total_lovelace > max_listing_lovelace){
                    //console.log('BP5')
                    break
                }

            }else if (cur_payout_amount['unit'] == clay_token_id){
                total_clay += parseInt(cur_payout_amount['quantity'], 10)
            }else{
                //console.log(cur_payout_amount['unit'])
                //console.log('BP6')
                break
            }

            isPayoutCorrect = true
        }

        if (isPayoutCorrect){
            //console.log(total_lovelace)
            //console.log(total_clay)
        }else{
            //console.log("OH NO 2")
            continue
        }


    
        let amount_list_info = []

        //let temp_eligibleListings = {}

        for (let h = 0; h < amount_list.length; h++){
            //console.log(amount_list[h])

            let cur_asset = amount_list[h]

            let cur_unit = cur_asset['unit']
            let cur_quantity = cur_asset['quantity']

            let cur_policy = cur_unit.slice(0,56)

           

            amount_list_info.push({unit:cur_unit, quantity:cur_quantity, meta:collections_meta[cur_policy][cur_unit]})



            /*
            temp_eligibleAssets[key + cur_value['name']] = {meta:collections_meta[key][key + cur_value['name']], 
                                                                accumulatedToken:accumulatedToken,
                                                                assetPrice:assetPrice}
            */       
        }

        //console.log(amount_list_info)

        listings_info[cur_tx_id+"#"+String(cur_tx_index)] = {price:total_clay, payout_lovelace:total_lovelace, payout_clay:total_clay, assets:amount_list_info}
 

        //listings_info[cur_tx_id+"#"+String(cur_tx_index)] = {payout_lovelace:total_lovelace, payout_clay:total_clay, assets:amount_list}
    }

    //console.log(listings_info)

    setListings(listings_info)


}   


useState(()=>{

    if (stake_address){
        get_wallet_listings()
    }

},[stake_address])


return (

    <div>

        <Container>

            <Grid container spacing={6}>
            {listings ?
                Object.keys(listings).map(tx_key => 
                    
                   tx_key in listings && listings[tx_key]['assets'].length == 1 ?

                    <Grid item xs={12} key={tx_key}>


                        <AssetCard 
                            asset_id = {listings[tx_key]['assets'][0]['unit']}
                            asset_info = {{meta:listings[tx_key]['assets'][0]['meta'], assetPrice:listings[tx_key]['price']}}
                            sellAsset = {sellAsset}
                            setSellAsset = {setSellAsset}
                            assetStatus = {"updatable"}

                            tx_hash = {tx_key}
                        />

                    </Grid>


                    : <div>{/*NOT SUPPORTED*/}</div>

                    )

            : null}

            </Grid>

        </Container>


    </div>
 
  )
}

export default WalletListings



