import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Cloud, Sphere, Box, useProgress } from '@react-three/drei'

import { Html, ContactShadows, Float, useGLTF,  PresentationControls } from '@react-three/drei'

import { OrbitControls, Sparkles, Stars } from '@react-three/drei'


import Clouds from '../Clouds/Clouds'


import {Sky} from '../Sky/Sky'


import * as THREE from 'three'



function Atmosphere(props){
  
  const {scene} = useThree()

  const {effectController, sunPosition, skyColors} = props


  useEffect(() => {

    const sky = new Sky();
    sky.scale.setScalar( 450000 );
    scene.add( sky );

    const sun = new THREE.Vector3(sunPosition[0], sunPosition[1], sunPosition[2]);


    // const effectController = {
    //       turbidity: 1,
    //       rayleigh: 0.16,
    //       mieCoefficient: 0.0216,
    //       mieDirectionalG: 0.018,
    //       sunSize: 0
    //     };


    const uniforms = sky.material.uniforms;
          uniforms[ 'turbidity' ].value = effectController.turbidity;
          uniforms[ 'rayleigh' ].value = effectController.rayleigh;
          uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
          uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

          // const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
          // const theta = THREE.MathUtils.degToRad( effectController.azimuth );

          // sun.setFromSphericalCoords( 1, phi, theta );

          uniforms[ 'sunPosition' ].value.copy( sun );

          uniforms[ 'sunSize' ].value = effectController.sunSize;

          // const skyColors = 
          //   {
          //     skyBottom: new THREE.Vector3(0.78, 0.66, 0.9),
          //     skyTop: new THREE.Vector3(0.58, 0.72, 0.85)
          //   }

           // const skyColors = 
           //  {
           //    skyBottom: new THREE.Vector3(1.0, 0.6, 0.6),
           //    skyTop: new THREE.Vector3(0.8, 0.6, 0.9)
           //  }


          uniforms[ 'skyBottom' ].value = skyColors.skyBottom;
          uniforms[ 'skyTop' ].value = skyColors.skyTop;



    return () => {

      try{
        sky.geometry.dispose()
      }catch{

      }

      scene.remove( sky );

    } 

  }, [effectController, sunPosition])

  return(
    <>

    </>
  )
}
export default Atmosphere;

