import { useState, useEffect } from "react";
import { FormWrapper, FormLabel, FormInput, Alert, PromptContainer, Prompt, PromptPasswordReset, H1, H2, H3, H4} from "./LogInForm.styled";

import { MessageWrapper }  from './SignMessage.styled.jsx'

import check from '../Images/check.png';

import { Button, ButtonSecondary } from '../GlobalStyles.jsx';

import {  PromptLogIn } from './Modal.styled.jsx';

import { useNavigate } from "react-router-dom";

function LogInForm({ handleSignUp, handleConnectWallet, mainAction, setMainAction, handleDefault, handleSignMessage, toastMessages, isLoggedIn, setIsLoggedIn, accessToken, setAccessToken }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    
    const [error, setError] = useState("");

    const navigate = useNavigate();


    useEffect(() => {
        setIsLoggedIn(false)
    },[])

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleLogIn = (e) => {
        // if (username === "cyb3rclay" && password === "clay22") {
        //     setIsLoggedIn(true);
        //     e.preventDefault();

        //     setAuthToken('test')

        // } else {
        //     setError('Credentials do not match');
        // }

        processLogin(e)

    };

    async function processLogin(e){
        let login_status_json = {}

        try{
            login_status_json = await postLoginUser({username:username, password: password})

            let access_token = login_status_json["access_token"]
            let login_status = login_status_json['status']

            setAccessToken(access_token)

            if (login_status == 'success'){
                setIsLoggedIn(true);
                e.preventDefault();
            }else{
                toastMessages.notifyError('Could not log in')
            }

           

            //handleSignMessage()
            
        }catch{
            toastMessages.notifyError('Could not log in')
        }

        console.log(login_status_json)
    }


    async function postLoginUser(data){
       const response = await fetch('https://claymarket.io/claydash/login-id-connect/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }

    return (
        <FormWrapper>
            {!isLoggedIn &&
                <>
                <H1>Log in to your Clay id account</H1>
                {error ? <Alert>{error}</Alert> : null}
             <FormLabel>
                USERNAME
                <br />
                <FormInput type="text" value={username} onChange={handleUsernameChange} />
            </FormLabel>
            <FormLabel>
                PASSWORD
                <br />
                <FormInput type="password" value={password} onChange={handlePasswordChange} />
            </FormLabel>    
            <Button theme="blue" type="button" onClick={handleLogIn} disabled={!username || !password}>LOG IN</Button>
            <PromptContainer>

            
            <PromptPasswordReset>

                {/*
                <H4>FORGOT PASSWORD?</H4>
                <ButtonSecondary theme="pinkInactive" type="button" onClick={() => {
                    setMainAction('resetPassword')
                    handleConnectWallet()
                }}>RESET</ButtonSecondary>
                */}

                {/*
                <div style={{marginBottom:'-70px'}}>
                        <PromptLogIn>
                            <ButtonSecondary theme="pinkInactive" type="button" onClick={handleDefault} >CANCEL</ButtonSecondary>
                        </PromptLogIn>
                </div>
                */}

            </PromptPasswordReset>


            <div>
                <Prompt>
                    <H4>DON'T HAVE CLAY ID?</H4>
                    <ButtonSecondary theme="pinkInactive" type="button" onClick={() => {
                        setMainAction('signUp')
                        handleSignUp()
                    }}>SIGN UP</ButtonSecondary>
                    
                    
                </Prompt>

                {/*
                 <PromptLogIn>
                    <ButtonSecondary theme="pinkInactive" type="button" onClick={handleDefault} >CANCEL</ButtonSecondary>
                </PromptLogIn>
                */}
            </div>

            </PromptContainer>
            </>
          }
            {isLoggedIn && 
            <>
                <H1>Welcome back, {username}!</H1>
                <H2></H2>

               <MessageWrapper>
                    <H3>Success! You’re all set!</H3>
                    <img src={check} alt="check" style={{paddingBottom:'1.5rem'}} />
                        <Button theme="blue" type="button" onClick={()=>{
                           
                            navigate('/dashboard', {'state':{'access_token':accessToken}});
                            
                        }}>DASHBOARD</Button>
                </MessageWrapper>


                  <PromptLogIn>
                    <ButtonSecondary theme="pinkInactive" type="button" onClick={handleDefault}>CANCEL</ButtonSecondary>
                </PromptLogIn>


            </>}


           
        </FormWrapper>
    );
}

export default LogInForm;

