import { useCallback, useEffect, useRef, useState } from "react";
import './Carousel.css'


import BoltIcon from '@mui/icons-material/Bolt';

import RaffleButton from './RaffleButton'


import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import './Raffles.css'

import styled,  { keyframes } from 'styled-components';

import cursorvoxelised from './SandboxUnlock/Images/cursor32.png';


  const ButtonText = styled.h1`
    position: relative;
    font-size: 15px;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `




  export const VoxeliseButton = styled.button`
background: var(--background);
font-family: PixelFont;
border: none;
outline: 2px solid var(--clayblue-primary);
padding: 1rem 2rem;
font-size: clamp(0.5rem, 2vw, 3rem);
letter-spacing: 0.2rem;
text-align: center;
color: var(--clayblue-primary);
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
border-radius: 20px;
cursor: url(${cursorvoxelised}), auto;
transition: 0.2s ease-in-out;

min-height: 60px;
min-width: 160px;

&:hover {
    background: var(--clayblue-primary);
    color: var(--claygreen-primary);
}

@media screen and (max-width: 1024px) {
    font-size: clamp(0.8em, 3.5vw, 10em);

    min-height: 60px;
    min-width: 160px;
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 7vw, 10rem);
    bottom: 2em;

    min-height: 60px;
    min-width: 160px;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.8rem, 4vw, 10rem);
    position: relative;
    border-radius: 1em;
    bottom: 0;
    padding: 1em 2em;
    margin-top: 1em;

    min-height: 60px;
    min-width: 160px;
}

z-index: 3;

`;




function Carousel(props) {

  const featuredRaffle = props.featuredRaffle

  const timerRef = useRef(null);
  const[currentIndex, setCurrentIndex] = useState(0);

  const [carouselCounter, setCarouselCounter] = useState(0)



  const updates = [

    {url: 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/announcements/Clay-Space-video_compressed.gif'},

    {url: 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay-Nation_Twitter_1200x675px.png'},

    {url: 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/announcements/Underworld_Preview.png'}

  ]


  const containerStyles = {

    width: '500px',
    height: '300px'
  };

  
  const updatesStyles = {

    backgroundImage: `url(${updates[currentIndex].url})`,

  };
    

  const dotsContainerStyles = {

    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '-30px',
    left: '44%',
    cursor: 'pointer'
  
  };



  const previous = () => {
    const firstupdate = currentIndex === 0; 
    const newIndex = firstupdate ? updates.length - 1 : currentIndex -1;
    setCurrentIndex(newIndex);
  };


  const next = useCallback(() => {
    const lastupdate = currentIndex === updates.length -1;
    const newIndex = lastupdate ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  },  [currentIndex, updates]  );


  let counter = 0
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)  
    }

    //console.log('use Effect');
     

    timerRef.current = setTimeout(() => {

      //next();
      if (currentIndex !== 0){
         if (carouselCounter >= 5){
           next();
           setCarouselCounter(0)
         }else{
          
           setCarouselCounter(carouselCounter + 1)
         }
      }else{
        console.log('first')
       if (carouselCounter >= 10){
         next();
         setCarouselCounter(0)
       }else{
        
         setCarouselCounter(carouselCounter + 1)
       }

      }

     

  }, 1075);


return () => clearTimeout(timerRef.current);

    }, [next, currentIndex, carouselCounter]
    
    );
    

const goToSlide = updateIndex => {

  setCurrentIndex(updateIndex);

};



const displayButton = () =>  {

    if (currentIndex === -1) {
    
      return (
    
      <button type='button' className="button0">VIEW ASSET PACK</button>

    )
    
    } 




    if (currentIndex === 0 ) {

      return (


    
          <div style={{width:'100%', height:'100%', borderRadius:'20px'}}>



          {featuredRaffle ? 


           <div style={{color: 'rgba(1, 1, 1, 0.8)', position:'absolute', top:'0px',zIndex:'100', display:'flex', justifyContent:'center', 
                    width:'100%', backgroundColor: "rgba(250, 250, 250, 0.5)", backdropFilter:'blur(10px)', borderTopLeftRadius:'15px',borderTopRightRadius:'15px'}}>
        
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', fontFamily:'ShortStack', color:'#3c005a'}}>
                        <BoltIcon />
                        <div>
                          {featuredRaffle.timeStatus}
                        </div>
                        <BoltIcon />
                      </div>


            </div>

           : null}





            {featuredRaffle ? 




            <div style={{marginRight:'10px', position:'absolute', bottom:'10px',right: '10px'}}>
        
                  <div style={{display:'flex', justifyContent:'end', alignItems:'center'}}>

                    <div style={{marginRight:'5px', color: 'rgba(44, 179, 255, 1)'}}>
                      {featuredRaffle.yourTickets}
                    </div>

                    <ConfirmationNumberIcon style={{ color: 'rgba(44, 179, 255, 1)' }} />

                  </div>


                  <div style={{display:'flex', justifyContent:'end', alignItems:'center'}}>

                    <div style={{marginRight:'5px', color: 'rgba(196, 185, 255, 0.8)'}}>
                      {featuredRaffle.purchasedTickets}/{featuredRaffle.totalTickets}
                    </div>

                    <LocalActivityIcon style={{ color: 'rgba(196, 185, 255, 0.8)' }} />

                  </div>

                </div>

                : null}



                {featuredRaffle ? 

              <div style={{display:'flex', justifyContent:'center', alignItems:'end', height:'95%', width:'100%'}}>


              
                <RaffleButton

                  started={featuredRaffle.started}
                  ended={featuredRaffle.ended}
                  totalTickets={featuredRaffle.totalTickets}
                  purchasedTickets={featuredRaffle.purchasedTickets}
                  ticketPrice={featuredRaffle.ticketPrice}
                  yourTickets={featuredRaffle.yourTickets}
                  endTime={featuredRaffle.endTime}
                  timeStatus={featuredRaffle.timeStatus}
                  winner={featuredRaffle.winner}
                  stake_address={featuredRaffle.stake_address}
                  setRaffleAsset={featuredRaffle.setRaffleAsset}
                  
                  raffleInfo={featuredRaffle.raffleInfo}

                /> 
                
              </div> : null}




          </div>

      )
    
    }


    if (currentIndex === 1){

      return(
        <div style={{width:'100%', height:'100%', backgroundColor:'', display:'flex', 
          flexDirection:'column', alignItems:'center', justifyContent:'center',
          cursor:'pointer'
        }}
          onClick={() => {
               window.open("https://www.clayspace.io/sandbox-unlock/info")
            }}
        >

          {/*<div style={{width:'200px', height:'100px', backgroundColor:'', marginTop:'250px', cursor:'pointer'}}>

            <VoxeliseButton type="button"
            onClick={() => {
               window.open("https://www.clayspace.io/sandbox-unlock/info")
            }} >VOXELISE</VoxeliseButton>
 
          </div>*/}

        </div>
      )

    } 




    if (currentIndex === -2) {
    
        return (
          <button type='button' className="button1">COMING SOON</button>
        )
      
      } 

    else {
    
      return (
      null
    
      )
    }
    
  }



  return (

    <div style={containerStyles}>

    <div className="carouselwrapper" >

      <div className="leftArrow" onClick={previous} >&#8249;</div>

      <div className="rightArrow" onClick={next}>&#8250;</div>

      <div className="updatesSlide" style={updatesStyles}>

      {displayButton()}
          
    <div>
      
  </div>

    <div style={dotsContainerStyles}>

      

      {updates.map((update, updateIndex) => (

        <div className="dots" style={{opacity: currentIndex == updateIndex ? '1' : '' }}



          key={updateIndex}  onClick={() => goToSlide(updateIndex)}   >&#9679;</div>

          )  )}

          </div>
        </div>
      </div>
    </div>

  );
}

export default Carousel;

