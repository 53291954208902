import {useEffect, useState, useContext} from 'react';

import { Suspense } from 'react'

import { OrbitControls } from "./AlteredOrbitControls2";
import { useThree } from "@react-three/fiber";

import * as THREE from "three";


import { MainPlayerContext } from 'components/Context/PlayerContext'


//import Model from './Model'

function CameraController(props){

  const {inputBlocked} = useContext(MainPlayerContext)

  const controls = props.controls 
  const setControls = props.setControls
  
  const { camera, gl } = useThree();
  const target = props.target
  const enablePan = props.enablePan

  const minZoom = props.minZoom ? props.minZoom : 1

  const maxZoom = props.maxZoom ? props.maxZoom : 10.001

  

  //console.log('CAMERA CONTROLLER')


  useEffect(
    () => {

      /*
      if (inputBlocked){
        return
      }
      */

      //console.log("NEW CONTROLS")
      const new_controls = new OrbitControls(camera, gl.domElement);

      new_controls.enablePan = enablePan

      new_controls.minDistance = minZoom;
      //new_controls.maxDistance = maxZoom

      // new_controls.minDistance = 0
       new_controls.maxDistance = maxZoom

      //controls.target = [0,0,0]
      
      new_controls.target.x = target.x
      new_controls.target.y = target.y
      new_controls.target.z = target.z

      
      new_controls.enableDamping = true
      new_controls.dampingFactor = 0.06


      new_controls.minPolarAngle = (20/180*Math.PI)
      new_controls.maxPolarAngle = (100/180*Math.PI)
      
      setControls(new_controls)

      return () => {
        new_controls.dispose();
        setControls()
      };
    },
    []
  );



  useEffect(()=>{


    /*
    if (controls){

      //console.log("CONTROLS UPDATE")
      controls.target.x = target.x
      controls.target.y = target.y
      controls.target.z = target.z

      controls.update()

    }

    */

    

  },[])




  return null;
};

export default CameraController;
