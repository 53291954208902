import styled from "styled-components";


export const MainContainer = styled.div`
    display: flex;  
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50vw;
    height: 50vh;
    
    padding: 2.5em 1em;
    margin-top: 5em;
    border-radius: 1em;
    backdrop-filter: blur(22px);
    contain: content;
    max-width: 60vw;
    max-height: 80vh;
    overflow-x: auto;
    @media screen and (max-width: 768px) {
       
        margin-top: 5em;
        overflow-y: auto;
        max-height: 70vh;
        padding: 2em;
        width: 70vw;
        margin-top: 2em;
        padding: 2.5em 2em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        max-height: 40vh;
        overflow-y: hidden;
        
    }
    `;

export const GIF = styled.img`
    width: 22%;
    height: auto;
    position: relative;
    top: -10%;
    max-width: 800px;
    @media screen and (max-width: 768px) {
        width: auto;
        height: 60%;
        top: -5%;
         max-height: 60vh;
    }

    object-fit: contain; /* Ensures the image scales without distortion */
    aspect-ratio: auto; 

    `;


    export const H1= styled.h1`

   
    width: 353px;
    height: 39px;


    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */
    text-transform: uppercase;

    color: rgba(229, 229, 229, 0.5);


    @media screen and (max-width: 768px) {
        font-size: clamp(1rem, 6vw, 3rem);
        line-height: 1em;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        font-size: clamp(0.5rem, 3vw, 3rem);
    }
    `;





    