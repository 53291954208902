import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {
    
    cartItems: [],
   
}


const actions = {
    SET_CART_ITEMS: "SET_CART_ITEMS", 
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_CART_ITEMS:
            return {...state, cartItems: action.value}

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainShoppingContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {

        cartItems: state.cartItems,

      
        setCartItems: value => {
             dispatch({type: actions.SET_CART_ITEMS, value})
        },

        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainShoppingContext.Provider value={value}>
            {children}
        </MainShoppingContext.Provider>
        )
}


function ShoppingContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default ShoppingContext;


