import {useState, useEffect} from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';



function CollectionInfo(props) {

  const policy_ids = props.policy_ids
  const collection = props.collection 
  
  const collection_names = props.collection_names
  const collection_descriptions = props.collection_descriptions

  const [shortDesc, setShortDesc] = useState(true)

  const [totalAssets, setTotalAssets] = useState('-')

  const [owners, setOwners] = useState('-')

  const [floor, setFloor] = useState('-')

  const [listed, setListed] = useState('-')

  const [totalVolume, setTotalVolume] = useState('-')

  const asset_numbers = {
    cn: 10000,
    gc: 9427,
    pitches: 32027
  }


  function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
  }


  //https://api.opencnft.io/1/policy/40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728
  async function get_collection_stats(policy){

    let clay_token_price = await getClayTokenPrice()

    fetch('https://claymarkets.com/api/stats/collection/'+policy+'/')
    .then((response) => response.json())
    .then((result) => {
      //console.log(result)

      setTotalAssets(result['total_assets'])
      setOwners(result['unique_holders'])

      setTotalVolume(displayAmount( (parseInt(result['all_time_volume'])/1000000), 1 ))

      setListed(result['assets_listed'])

      
      let ada_floor = parseInt(result['ada_floor'], 10) ? parseInt(result['ada_floor'], 10) : 0
      let clay_floor = parseInt(result['clay_floor'], 10) ? parseInt(result['clay_floor'], 10) : 0

      ada_floor = Math.round(ada_floor / 1000000)
      clay_floor = Math.round(clay_floor/10000 * clay_token_price)


      let temp_floor;

      if (clay_floor > 0){
        if (ada_floor > 5){
          temp_floor = Math.min(ada_floor, clay_floor)
        }else{
          temp_floor = clay_floor
        }
        
      }else{
        temp_floor = ada_floor
      }

      setFloor(temp_floor)

    })
    .catch((error) => {
      //console.log(error)
    })


  }

  useEffect(() => {

    if (collection){
      get_collection_stats(policy_ids[collection])
    }

  },[collection])



  function trim(pString){
    try{
      return pString.slice(0,6)+'...' + pString.slice(-6,)
    }catch{
      return ''
    }
    
  }

  function trimDescription(pString){
    try{
      return pString.slice(0,144) + '...'
    }catch{
      return ''
    }
  }



  async function getClayTokenPrice(){

    let query_url = 'https://claymarkets.com/api/claytoken/price/'

    let temp = await fetch(query_url).then(response => response.json())
                                   .then(data => data)

    let temp_exchange_rate = 0                           
    if ('clay_price' in temp){
        temp_exchange_rate = parseFloat(temp['clay_price'])
    }

    return temp_exchange_rate
  }



async function getCollectionStats(){
    let collection_stats = await fetch('https://claymarkets.com/api/stats/collection/' + collection + '/').then(response => response.json())
                                                                  .then(data => data)

    return collection_stats
}

  

  return (

   <div>

      <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
        <div style={{fontSize:'32px', fontWeight:'bold', color:'bisque', maxWidth:'300px'}}>
          {collection ? collection_names[collection].toUpperCase() : null}
        </div>
      </div>


        <div style={{display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer', color:'rgb(168, 175, 166)'}}
          onClick = {()=>{
            let policy_url = 'https://cardanoscan.io/tokenPolicy/'+policy_ids[collection] + '/'
             window.open(policy_url, '_blank').focus();
          }}
        >
             {collection ?
              <div>
             <span style={{fontSize:'14px'}}>{trim(policy_ids[collection])}</span>
             </div>
             :null}
            <OpenInNewIcon />
        </div>
       
        <div style={{marginTop:'15px' ,display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'space-betweeb'}}>
          
          <div style={{width:'90px'}}>
            <div style={{fontSize:'22px', color:'bisque', minHeight:'27px'}}>
              {displayAmount(totalAssets)}
            </div>

            <div style={{fontSize:'14px', color:'rgb(168, 175, 166)'}}>
              ITEMS
            </div>
          </div> 
  
          <div style={{width:'90px', borderLeft:'2px solid bisque'}}>
            <div style={{fontSize:'22px', color:'bisque', minHeight:'27px'}}>
              {owners}
            </div>
            
            <div style={{fontSize:'14px', color:'rgb(168, 175, 166)'}}>
              OWNERS 
            </div>
          </div>

          <div style={{width:'90px', borderLeft:'2px solid bisque'}}>
            <div style={{fontSize:'22px', color:'bisque', minHeight:'27px'}}>
             <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Cardano_light.svg' style={{width:'18px', zIndex:'0'}} />
              {floor}
            </div>
            
            <div style={{fontSize:'14px', color:'rgb(168, 175, 166)'}}>
              FLOOR
            </div>
          </div>

          <div style={{width:'90px', borderLeft:'2px solid bisque'}}>
            <div style={{fontSize:'22px', color:'bisque', minHeight:'27px'}}>
              {listed}
            </div>
            
            <div style={{fontSize:'14px', color:'rgb(168, 175, 166)'}}>
              LISTED 
            </div>
          </div>
           
          {/*<div style={{width:'90px', borderLeft:'2px solid bisque'}}>
            <div style={{fontSize:'22px', color:'bisque', minHeight:'27px'}}>
              {totalVolume}
            </div>
            
            <div style={{fontSize:'14px', color:'rgb(168, 175, 166)'}}>
              VOLUME<span>&#42;</span>
            </div>
          </div> */}

        </div>


      <div style={{marginTop:'20px', color:'rgb(168, 175, 166)'}}>

          <div style={{fontSize:'12px', margin:'5px', color:'bisque'}}>
            {'Total volume information is obtained from'.toUpperCase()} <div href="https://opencnft.io/"><div style={{display:'inline', fontWeight:'bold', cursor:'pointer'}}
            onClick={()=>{
              let opencnft_url = 'https://opencnft.io/'
             window.open(opencnft_url, '_blank').focus();
            }}
            >opencnft.io</div> <span>&#42;</span></div>
          </div>

        {collection ?
        <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'60%', minWidth:'400px', maxWidth:'600px', fontSize:'16px'}}>
            {shortDesc ? trimDescription(collection_descriptions[collection]) : collection_descriptions[collection]}
            <div style={{display:'inline', whiteSpace: 'nowrap', cursor:'pointer', color:'bisque'}}
              onClick={()=>{
                setShortDesc(!shortDesc)
              }}
            >
              {shortDesc ? "  show more".toUpperCase() : "  show less".toUpperCase()}
            </div> 
          </div>

        </div>
        :null}
      </div>

   </div>
  
  );
}

export default CollectionInfo;