import { useState, useEffect, useContext } from 'react'

import Banner from './Banner'
import MarketListings from '../MarketListings'

import ClayMap from '../Map/ClayMap'
import PlotListings from '../Map/PlotListings'

import '../ZoomButton.css'


import AssetCard from '../AssetCard'

import PlotCard from './PlotCard'


import '../Staking.css'
import '../StakingView.css'


import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';


import detailed_plot_info from '../../detailed_plot_info.json'

import {MainClaimContext} from '../ClaimContext'

function MainCollection(props) {

 const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)



const collection = props.collection
const sellAsset = props.sellAsset 
const setSellAsset = props.setSellAsset

const matchesXS = props.matchesXS
const matchesS = props.matchesS
const matchesM = props.matchesM
const matchesL = props.matchesL

const isSideBarOpen = props.isSideBarOpen
const appliedFilters = props.appliedFilters
const setAppliedFilters = props.setAppliedFilters
const currentSlot = props.currentSlot
const pitchesFilters = props.pitchesFilters


const [listingsFilters, setListingsFilters] = useState({})

const [listingsPage, setListingsPage] = useState(1)
const selectedPlot = props.selectedPlot 
const setSelectedPlot = props.setSelectedPlot
const [plotCardOpen, setPlotCardOpen] = useState(true)

const sortOrder = props.sortOrder 
const setSortOrder = props.setSortOrder


const yourAssetsFilters = props.yourAssetsFilters


const searchQuery = props.searchQuery
const setSearchQuery = props.setSearchQuery


const [pitchesFound, setPitchesFound] = useState(0)


const [isMapView, setIsMapView] = useState(true)


  return (

   <div style={{height:'100vh'}}>

   <div style={{backgroundColor:''}}>

    <Banner 
      matchesL={matchesL}
      matchesM={matchesM}
      matchesS={matchesS} isSideBarOpen={isSideBarOpen} 
      collection={collection} 
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}


      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}

      pitchesFound={pitchesFound}
      setPitchesFound={setPitchesFound}

      />
  </div>

<div style={{width:'100%', display:'flex', justifyContent:'center'}}>
    

     {collection != 'pitches' ?

     <div style={{position:'relative', backgroundColor:'', display:'flex', justifyContent:'center', width:'80%', 
     marginLeft: isSideBarOpen && matchesL ? '325px' : isSideBarOpen && matchesM ? '33vw' 
     : !isSideBarOpen && matchesM ? '0vw' : isSideBarOpen && matchesS ? '48vw' : !isSideBarOpen && !matchesS ? '0px'  : '0px'
     }}>

      <MarketListings 
        collection={collection} sellAsset={sellAsset} 
        setSellAsset={setSellAsset} listingsFilters={listingsFilters}
        listingsPage={listingsPage} setListingsPage={setListingsPage}
        currentSlot={currentSlot}
        appliedFilters={appliedFilters}
        setSellAsset={setSellAsset} sellAsset={sellAsset}

        sortOrder={sortOrder}

        yourAssetsFilters={yourAssetsFilters}


        matchesL={matchesL}
        matchesM={matchesM}
        matchesS={matchesS}
        matchesXS={matchesXS}

        isSideBarOpen={isSideBarOpen}


        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}

       />


       </div>


       : 
       



    <div style={{position:'relative', backgroundColor:'', display:'flex', justifyContent:'center', width:'80%', 
     marginLeft: isSideBarOpen && matchesL ? '325px' : isSideBarOpen && matchesM ? '33vw' 
     : !isSideBarOpen && matchesM ? '10vw' : isSideBarOpen && matchesS ? '44vw' : '0px'
     }}>


     {false ?
     <div style={{display:'flex', flexDirection:'row', justifyContent:'end', width:'75%', position:'absolute'}}>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', backgroundColor:'', height:'50px'}}>
              
              <div style={

                isMapView ?

                {width:'50px', height:'50px', borderTopLeftRadius:'50%', backgroundColor:'bisque', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', backgroundColor: true ? 'rgba(1,1,1,0.25)' : 'rgba(45, 40, 74, 0.6)', 
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'}
                :
                {width:'50px', height:'50px', borderTopLeftRadius:'50%', backgroundColor:'bisque', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', backgroundColor: true ? 'rgba(1,1,1,0.08)' : 'rgba(53, 73, 91, 0.5)', 
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )', filter: 'opacity(50%)'}

              }

                onClick={()=>{
                    setIsMapView(true)
                }}
              >
                <MapIcon style={{color:'bisque'}} />
              </div>

               <div style={

                !isMapView ?

                {width:'50px', height:'50px', borderTopRightRadius:'50%', backgroundColor:'bisque', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', backgroundColor: true ? 'rgba(1,1,1,0.25)' : 'rgba(53, 73, 91, 0.5)', 
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )'}
                :
                {width:'50px', height:'50px', borderTopRightRadius:'50%', backgroundColor:'bisque', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', backgroundColor: true ? 'rgba(1,1,1,0.08)' : 'rgba(53, 73, 91, 0.5)', 
            boxShadow:  'inset 4px 4px 8px rgba(117, 151, 191, 0.3 ), inset -4px -4px 6px rgba(0, 0, 0, 0.3 )', filter: 'opacity(50%)'}

              }

               onClick={()=>{
                    setIsMapView(false)
                }}
               >
                <ViewListIcon style={{color:'bisque'}} />
              </div>

        </div>
      </div>

      :null}



   
       {isMapView ?

       <div className="staking" style={{width:'80%', minWidth:'250px',height:'75vh', marginBottom:'60px', marginTop:'0px'}}>
       {/*<div style={{backgroundColor:'red', width:'80%', height:'75vh', marginBottom:'60px'}}>*/}

       {selectedPlot != null ?

         
             <PlotCard setSelectedPlot={setSelectedPlot} selectedPlot={selectedPlot} stake_address={stake_address} 

             setSellAsset={setSellAsset} sellAsset={sellAsset}
             />

          :

          null

        }


       

        <ClayMap pitchesFilters={pitchesFilters} 
                 selectedPlot={selectedPlot}
                 setSelectedPlot={setSelectedPlot}
                 appliedFilters={appliedFilters}

                 yourAssetsFilters={yourAssetsFilters}

                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery}


                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery}


                 pitchesFound={pitchesFound}
                 setPitchesFound={setPitchesFound}

        />



       

      </div>

      :


      <div style={{marginTop:'100px', marginBottom:'100px', minHeight:'70vh'}}>

         <PlotListings pitchesFilters={pitchesFilters} 
                 selectedPlot={selectedPlot}
                 setSelectedPlot={setSelectedPlot}
                 appliedFilters={appliedFilters}

                 yourAssetsFilters={yourAssetsFilters}

                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery}


                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery}


                pitchesFound={pitchesFound}
                setPitchesFound={setPitchesFound}


                setSellAsset={setSellAsset} sellAsset={sellAsset}

        />

      </div>



      }


      </div>

     }
      
    </div>

   </div>
  
  );
}

export default MainCollection;