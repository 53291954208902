import './Card.css';


const trousersNameCorrections = {
  "Green_Shirt_Trousers":"Shirt_Trousers&Green",
  "Red_Shirt_Trousers":"Shirt_Trousers&Red",
  "Grey_Shirt_Trousers":"Shirt_Trousers&Grey"
}

function Card(props) {
  const {meta} = props
  const image_url = props.image_url
  const serial = props.serial
  const selected = props.selected
  const setSelected = props.setSelected

  const setMeta = props.setMeta
  const asset = props.asset

  const {setAssetToBuy} = props

  return (
    <div>
      <div className={`Card  ${serial===selected ? "selected" : ""}`}

        onClick={()=>{
          console.log(serial)
          console.log(asset)
          setSelected(serial)


          if (serial.startsWith('CN')){
            let temp_meta = {...asset}
            console.log('old meta')
            console.log(meta)

            if (meta.hasOwnProperty('model') && meta.model.hasOwnProperty('trousers')){
              temp_meta.model.trousers = meta.model.trousers
            }


            /*
            
            META UPDATE IF OMEN

            delete temp_meta.model.mouth
            delete temp_meta.model.eyes
            delete temp_meta.model.hair
            delete temp_meta.model.hat
            delete temp_meta.model.hat_and_hair
            delete temp_meta.model.brows
            delete temp_meta.model.eye_accessories
            temp_meta.model.hat = 'Omen'
            */




            // temp_meta.model.shoes = 'Frogs'

            //temp_meta.model.shoes = 'Clay_Trainers_Black'
            



            setMeta(temp_meta)

          }else if (serial.startsWith('SHOES')){


            if (meta.hasOwnProperty('model') && meta.model.hasOwnProperty('trousers')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)

              let temp_trousers_name = asset.type.replaceAll(' ','_') 

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }

              if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
                temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              }
              
              temp_meta.model.shoes = temp_trousers_name

              console.log('update meta')
              console.log(temp_meta)

              setMeta(temp_meta)
            }


          }



          else if (serial.startsWith('OMEN')){


            if (meta.hasOwnProperty('model') && meta.model.hasOwnProperty('trousers')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)

              let temp_trousers_name = asset.type.replaceAll(' ','_') 

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }

              if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
                temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              }

              delete temp_meta.model.mouth
              delete temp_meta.model.eyes
              delete temp_meta.model.hair
              delete temp_meta.model.hat
              delete temp_meta.model.hat_and_hair
              delete temp_meta.model.brows
              delete temp_meta.model.eye_accessories
              delete temp_meta.model.accessories
              temp_meta.model.hat = 'Omen'
              
              
              console.log('update meta')
              console.log(temp_meta)

              setMeta(temp_meta)
            }


          }




          else if (serial.startsWith('PANTS')){
            if (meta.hasOwnProperty('model') && meta.model.hasOwnProperty('trousers')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)


              // console.log('\n\n\nASSET\n\n\n')

              let temp_trousers_name;
              // console.log(asset.type)
              
              if (asset.type.endsWith(' Pants')){
               temp_trousers_name = asset.type.replaceAll(' ','_').replaceAll('_Pants','') + '_Trousers'
              

              }else{
                temp_trousers_name = asset.type.replaceAll(' ','_') + '_Trousers'
              }

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }

              if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
                temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              }
              
              temp_meta.model.trousers = temp_trousers_name

              console.log('update meta')
              console.log(temp_meta)

              setMeta(temp_meta)
            }
          } else if (serial.startsWith('HAT')){
            if (meta.hasOwnProperty('model')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)

              let temp_trousers_name = asset.type.replaceAll(' ','_')

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }


              if (asset.type === 'Snek Head'){
                delete temp_meta.model.mouth
                delete temp_meta.model.eyes
                delete temp_meta.model.hair
                delete temp_meta.model.hat
                delete temp_meta.model.hat_and_hair
                delete temp_meta.model.brows
                delete temp_meta.model.eye_accessories
                delete temp_meta.model.accessories
              }



              // if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
              //   temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              // }

              if (temp_trousers_name === 'Santa_Hat'){
                
                if (temp_meta.model.accessories === 'Parrot'){
                  temp_meta.model.accessories = 'Santa_Parrot'
                  setMeta(temp_meta)
                }else if (temp_meta.model.accessories === 'Frog'){
                  temp_meta.model.accessories = 'Santa_Frog'
                  setMeta(temp_meta)
                }else if (temp_meta.model.accessories === 'Spider'){
                  temp_meta.model.accessories = 'Santa_Spider'
                  setMeta(temp_meta)
                }

              }else{

                temp_meta.model.hat = temp_trousers_name

                console.log('update meta')
                console.log(temp_meta)

                setMeta(temp_meta)

              }
              
              
            }
          }

          else if (serial.startsWith('ACCS')){
            if (meta.hasOwnProperty('model')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)

              let temp_trousers_name = asset.type.replaceAll(' ','_')

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }

              // if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
              //   temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              // }
              
              temp_meta.model.accessories = temp_trousers_name

              console.log('update meta')
              console.log(temp_meta)

              setMeta(temp_meta)
            }
          }



          else if (serial.startsWith('TOP')){
            if (meta.hasOwnProperty('model')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)

              let temp_trousers_name = asset.type.replaceAll(' ','_')

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }

              // if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
              //   temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              // }
              
              temp_meta.model.clothes = temp_trousers_name

              console.log('update meta')
              console.log(temp_meta)

              setMeta(temp_meta)
            }
          }
          

          // console.log(asset)

          if (asset.hasOwnProperty('assetPrice')){
            let asset_price = asset['assetPrice']

            if (asset_price.hasOwnProperty('ada_price') || asset_price.hasOwnProperty('clay_price')){

              let temp_asset = {...asset}
              temp_asset.image = image_url

              setAssetToBuy(temp_asset)
            }else{
              setAssetToBuy()
            }
          }else{
            setAssetToBuy()
          }

        }}




      >
        <img src={image_url} className="Avatar" />

        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
          <div className="Serial">
            {serial}
          </div>
        </div>


      </div>
    </div>
  );

}

export default Card;