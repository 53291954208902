
import styled from "styled-components";
import cursorvoxelised from '../Images/cursor32.png';


export const SidePannel = styled.div`
box-sizing: border-box;
background: var(--background);
border-radius: 00px 3em 3em 0px;
max-height: 92vh;
min-height: 100px;
max-width: 22vw;
min-width: 100px;
width: 100%;
height: 100%;
left: 0;
top: 6%;
z-index: 2;
backdrop-filter: blur(22px);
position: absolute;
padding: 2em 2em;

min-width: 300px;

@media screen and (max-width: 768px) {
    min-width: 200px;

    max-width: 70vw;
    max-height: 92vh;
    background: var(--background-progress);
}

@media screen and (max-width: 768px) and (orientation: landscape) {
    max-width: 70vw;
    max-height: 80vh;
    border-radius: 2em;
    contain: content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 15%;
    top: 20%;
    padding: 0;
}

z-index: 100

`;



export const H1 = styled.h1`
font-family: PixelFont;
font-size: clamp(1rem, 4vw, 7rem);
font-weight: 50;
letter-spacing: 0.2rem;
color: var(--clayblue-primary);
text-align: center;
margin: 0;
padding-top: 0.5em;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 8vw, 10rem);
    padding: 0.5em 0.7em;
    border-radius: 0.5em;
    left: 5%;
    position: absolute;
    top: 10%;
    display: inline-block;
    background: var(--background);
    
}

`;

export const H2 = styled.h1`
font-family: ShortStack;
font-size: clamp(1rem, 1.2vw, 7rem);
font-weight: 50;
color: var(--claytext-white);
text-align: center;
margin: 0;
padding: 0;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 1.2vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 7rem);
}

`;

export const H3 = styled(H2)`
font-size: clamp(0.5rem, 1vw, 7rem);
color: var(--claygreen-primary);   
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 3vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1vw, 7rem);
}

`;

export const Number = styled.div`
font-family: ShortStack;
font-size: clamp(1rem, 1.5vw, 7rem);
font-weight: 50;
color: var(--claytext-white);
text-align: center;
margin: 0;
padding: 0.5em;
width: fit-content;
background: rgba(0, 0, 0, 0.25);
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
border-radius: 0.5em;
@media screen and (max-width: 1024px) {
    font-size: clamp(0.5em, 1.2vw, 3em);
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 5vw, 7rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 7rem);
}

`;

export const CloseButton = styled.button`
background: transparent;
border: none;
color: var(--claytext-white);
font-size: clamp(1rem, 4vw, 3rem);
font-weight: 100;
font-family: PixelFont;
position: absolute;
right: 0.6em;
top: 0.5em;
margin: 0;
padding: 0;

cursor: pointer;

z-index: 2;

@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 8vw, 10rem);
    right: 1em;
    top: 1em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(1rem, 4vw, 10rem);
    right: 1em;
    top: 0.5em;
}

cursor: pointer;

`;

export const Line = styled.hr`
border: 1px solid var(--clayblue-primary);
width: 60%;
@media screen and (max-width: 768px) and (orientation: landscape) {
    display: none;
}
`;


export const Separator = styled.hr`
border: 1px solid rgba(0, 0, 0, 0.22);
width: 90%;
margin: 0 auto;
`;

export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 2em 0;
gap: 1em;
@media screen and (max-width: 768px) {
    padding: 1.5em 0;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    padding: 0.3em 0;
}
`;

export const VoxeliseButton = styled.button`
background: var(--background);
font-family: PixelFont;
border: none;
outline: 2px solid var(--clayblue-primary);
padding: 1rem 2rem;
font-size: clamp(0.5rem, 2vw, 3rem);
letter-spacing: 0.2rem;
text-align: center;
color: var(--clayblue-primary);
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
border-radius: 20px;
cursor: url(${cursorvoxelised}), auto;
transition: 0.2s ease-in-out;

min-height: 60px;
min-width: 160px;

&:hover {
    background: var(--clayblue-primary);
    color: var(--claygreen-primary);
}

@media screen and (max-width: 1024px) {
    font-size: clamp(0.8em, 3.5vw, 10em);

    min-height: 60px;
    min-width: 160px;
}
@media screen and (max-width: 768px) {
    font-size: clamp(1rem, 7vw, 10rem);
    bottom: 2em;

    min-height: 60px;
    min-width: 160px;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.8rem, 4vw, 10rem);
    position: relative;
    border-radius: 1em;
    bottom: 0;
    padding: 1em 2em;
    margin-top: 1em;

    min-height: 60px;
    min-width: 160px;
}

z-index: 3;

`;