import { useState, useContext, useEffect } from 'react'


//import ClayCard from './ClayCard'
import AssetCard from './AssetCard'

import cn from '../cn_meta.json'
import gc from '../gc_meta.json'

import cn_meta from '../cn_db.json'
import gc_meta from '../gc_db.json'
import pitches_meta from '../pitches_db.json'

import cn_rarity from '../cn_rarity.json'
import gc_rarity from '../gc_rarity.json'

import {Buffer} from 'buffer'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {MainClaimContext} from './ClaimContext'
import EmptyWallet from './EmptyWallet'
import Loading from './Loading'


import BuildTransaction from './BuildTransaction'

function Wallet(props) {

const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)
const [refreshInterval, setRefreshInterval] = useState()

const clay_dict = {cn:cn, gc:gc}

const collection_styles = {cn:{borderColor:'#FF9C83', serialColor:'#FFB5B5'}, gc:{borderColor:'#53C9EE', serialColor:'#AEE8FB'}}

const cn_info = {policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name: 'ClayNation'}
const gc_info = {policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', base_name: "ClayCharlotte"}

const clayInfo = {cn:cn_info, gc:gc_info}

const metaInfo = {cn:cn_meta, gc:gc_meta}

const rarities = {cn:cn_rarity, gc:gc_rarity}

const raritiesTotal = {cn:10000, gc:9427}

const clay_token_id = '38ad9dc3aec6a2f38e220142b9aa6ade63ebe71f65e7cc2b7d8a8535434c4159'

const startColor = {'cn':'rgba(255, 156, 131, 1)', 'gc':'rgba(127, 212, 239, 1)'}

const [selectedAssets, setSelectedAssets] = useState([])

const isCompact = props.isCompact
const currentSlot = props.currentSlot


const assetsOwned = props.assetsOwned


const isWalletLoading = props.isWalletLoading

const setIsWalletLoading = props.setIsWalletLoading

const setHasBaking = props.setHasBaking

const setSellAsset = props.setSellAsset
const sellAsset = props.sellAsset


const [eligibleAssets, setEligibleAssets] = useState([])


const eligiblePolicies = {
                            "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":"Clay Nation",
                            "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":"CN x Good Charlotte",
                            "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":"Pitches at Clay Nation"
                         }

const collections_meta = {
                            "40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728":cn_meta,
                            "94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768":gc_meta,
                            "13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e":pitches_meta
                        }


useEffect(() => {

    let all_assets;

    if (assetsOwned){
        all_assets = assetsOwned.all_assets
    }else{
        return
    }

    //console.log(assetsOwned)

    let temp_eligibleAssets = []



    for (let key in all_assets){
        //console.log(key)
        if (key in eligiblePolicies){
            //console.log(all_assets[key])

            let cur_assets = all_assets[key]
            for (let i = 0; i < cur_assets.length; i++){

                let cur_value = cur_assets[i]

                let cur_asset_id = key+cur_value['name']



                let accumulatedToken = 0

                let assetPrice = 0



                temp_eligibleAssets[key + cur_value['name']] = {meta:collections_meta[key][key + cur_value['name']], 
                                                                accumulatedToken:accumulatedToken,
                                                                assetPrice:assetPrice}
                
            }
        }
    }  

    //console.log(temp_eligibleAssets)
    setEligibleAssets(temp_eligibleAssets)

}, [assetsOwned])





useEffect(() => {

    
    if (stake_address){
        //console.log('STAKE KEY HASH')

        //console.log(stake_address)

        let reward_address = serLib.RewardAddress.from_address(serLib.Address.from_bech32(stake_address))

        //console.log(reward_address)

        //console.log(reward_address.payment_cred().to_keyhash().to_hex())

    }

}, [stake_address])




const cardClick = (clay) => {
    //console.log(clay)
}


function get_asset_id(clay){
   const asset_id = clayInfo[clay.slice(0,2)].policy_id + Buffer.from(clayInfo[clay.slice(0,2)].base_name+String(clay.slice(2)),'utf8').toString('hex') 

   return asset_id
}


const asset_id = (clay) => {
     const asset_id = clayInfo[clay.slice(0,2)].policy_id + Buffer.from(clayInfo[clay.slice(0,2)].base_name+String(clay.slice(2)),'utf8').toString('hex') 

   return asset_id
}



return (

 <div style={{maxHeight:'100vh'}}> {/*style={{width:"60vw",marginLeft:'200px'}}>*/}


    {/*TEST CANCEL
    <BuildTransaction activeWallet={activeWallet} 
                      txInfo={{action:'cancel', 
                      listing_utxo: 'e58684e298497353a527f6cf77865b3e13bc260d3e11adf51bb7a06787da0dab'}} />
    */}


    {/*TEST UPDATE
    <BuildTransaction   activeWallet={activeWallet}
                        txInfo={{action:'update', 
                        listing_utxo:'1d984fe900447c18057c4eb957ac622e9a64102a1ebeec85a388076be9115f5b',
                        price:{lovelace: 2000000, clay: 250000*10000}}} />

    */}


    {/*TEST BUY
     <BuildTransaction activeWallet={activeWallet} 
                       txInfo={{action:'buy', 
                       listing_utxo: '06c559993d16444b401ca6e6c461ca1ab651e5338d1678c09deeb88683134e59'}} />
    */}

   
    

{/*TEST MAKE OFFER
    <BuildTransaction activeWallet={activeWallet}
                      txInfo={{
                        action:'make offer',
                        assets: [{unit:"lovelace", quantity:2000000}, 
                                {unit:"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728436c61794e6174696f6e31393739", quantity:1}],
                        price:{lovelace: 2000000, clay: 50000*10000}
                      }} />
*/}



{/*
TEST ACCEPT OFFER LISTED
     <BuildTransaction activeWallet={activeWallet} 
                      txInfo={{action:'accept offer', 
                      listing_utxo: '1f22e5306fb887917e03edf69c150fccb5853231c914327fcd2b5d916900542c',
                      offer_utxo: 'c2aa4967b1d3bce94a581e778737b17830f524270712d1a0b7efaa1fa14e5531'}} 
                      />
*/}


{/*
TEST ACCEPT OFFER
 <BuildTransaction activeWallet={activeWallet} 
                      txInfo={{action:'accept offer', 
                      offer_utxo: '087f81136f762dc655567e679817e64498d79a9fc499a282a04716515b18a8d8'}} 
                      />
*/}

        {/*
        <BuildTransaction address={address} activeWallet={activeWallet} />
        */}

    {/*
    <div style={{display:'flex', justifyContent:'center', backgroundColor:'blue', width:'1000px', height:'600px'}}>
        <ClayCard />
    </div>
    */}


{
    eligibleAssets ?

    (
        <Container>

            <Grid container spacing={6}>

                {
                    Object.keys(eligibleAssets).map(asset_id => 
                      <Grid item xs={12} key={asset_id}>
                        <AssetCard 
                            asset_id = {asset_id}
                            asset_info = {eligibleAssets[asset_id]}
                            sellAsset = {sellAsset}
                            setSellAsset = {setSellAsset}
                            assetStatus = {"sellable"}
                        />

                      </Grid>

                )}

            </Grid>

        </Container>
    )

    :null
}






{/*
{assetsOwned && assetsOwned.clays.length != 0 ? (
     <Container>
      <Grid container spacing={6}>
        {assetsOwned.clays.map(clay => (

        <Grid item xs={12} sm={isCompact ? 12 : 6} md={6} lg={assetsOwned.clays.length % 3 == 0 ? 4 : assetsOwned.clays.length % 2 == 0 ? 6 : 4} xl={assetsOwned.clays.length % 4 == 0 ? 3 : assetsOwned.clays.length % 3 == 0 ? 4 : assetsOwned.clays.length % 2 == 0 ? 4 : assetsOwned.clays.length == 1 ? 12 :  3} key={clay}> 
                                                   
        <ClayCard 
            activeWallet={activeWallet} 
            asset_id={get_asset_id(clay)} 

            assetMeta={metaInfo[clay.slice(0,2)][get_asset_id(clay)]}
            sellAsset={sellAsset}
            rarities={rarities}
            raritiesTotal={raritiesTotal}
            assetStatus={"sellable"}
            assetPrice={"125000"}
            setSellAsset={setSellAsset} 

            image={clay_dict[clay.slice(0,2)][clay.slice(2)]} 
            collection_abbr={clay.slice(0,2)} 
            serial={clay.slice(2)} 
        />
        </Grid>
          ))}
        </Grid>
     </Container>

     ) 
: null}

*/}



 {/*
    {assetsOwned && assetsOwned.clays.length != 0 ? (
     <Container>
      <Grid container spacing={6}>
        {assetsOwned.clays.map(clay => (

        <Grid item xs={12} sm={isCompact ? 12 : 6} md={6} lg={assetsOwned.clays.length % 3 == 0 ? 4 : assetsOwned.clays.length % 2 == 0 ? 6 : 4} xl={assetsOwned.clays.length % 4 == 0 ? 3 : assetsOwned.clays.length % 3 == 0 ? 4 : assetsOwned.clays.length % 2 == 0 ? 4 : assetsOwned.clays.length == 1 ? 12 :  3} key={clay}> 
                                                   
        <ClayCard setHasBaking={setHasBaking} image={'https://claynation.nyc3.digitaloceanspaces.com/IPFS/360x360/'+clay_dict[clay.slice(0,2)][clay.slice(2)]+'.png'} 
        serial={clay.slice(2)} clayInfo={assetsOwned.clays_info[clay]} currentSlot={currentSlot} amount={0} borderColor={collection_styles[clay.slice(0,2)].borderColor}  startColor={startColor[clay.slice(0,2)]}
        serialColor={collection_styles[clay.slice(0,2)].serialColor} clay_id={clay} selectedAssets={selectedAssets} setSelectedAssets={setSelectedAssets} collection={clay.slice(0,2)} slot={currentSlot} />
        </Grid>
          ))}
        </Grid>
     </Container>
     )

    :

    assetsOwned && assetsOwned.clays.length == 0 ?

    <Grid container spacing={6} justifyContent='center' alignItems='center' >
        <Grid item xs={12}>
            <EmptyWallet />
        </Grid>
    </Grid>

    : 
    <Grid container spacing={6} justifyContent='center' alignItems='center' >
        <Grid item xs={12}>
            <EmptyWallet />
        </Grid>
    </Grid>
     

    }

*/}

  </div>
  )
}

export default Wallet