import {useState, useEffect, useContext} from 'react'

import './Ledger.css'

import styled from 'styled-components';

import { motion } from "framer-motion";

import Connect from './Connect'

import Footer from './Footer'

import Raffle from './Raffle'

import Winner from './Winner'

import ConnectPopUp from './ConnectPopUp'

import {Provider} from 'components/ClaimContext'

import {MainClaimContext} from 'components/ClaimContext'

import Cards from './Cards'

import AlertPopUp from './AlertPopUp'

import Carousel from './Carousel'

import { Grid } from '@mui/material';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createGlobalStyle } from "styled-components";


const Title = styled.div`
  color: rgba(255, 255, 255, 0.93);
  font-family: 'ShortStack';
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);


  @media screen and (max-width: 680px) {
    font-size: 34px; 
  }
   
`;

const Subtitle = styled.div`
  color: #adffd9;
  font-family: 'ShortStack';
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  word-wrap: break-word;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(174, 245, 184, 0.25);

  @media screen and (max-width: 680px) {
    font-size: 30px; 
  }

`;


const NavBar = styled.div`
    position: absolute;
    top: 10px;
    right: 0px; 
    z-index: 10000;
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center;
    align-items: center;
`


const NavBarContents = styled.div`
    width: 96%; 
    height: 90%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-radius: 30px; 
    margin-top: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
    backdrop-filter: blur(6px);
    transition: ease-in-out all 0.3s;  
`


const Container = styled.div`
  width: 100%; 
  height: 100%;
  background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clayversebg.png');
  background-size: cover;
  background-repeat: no-repeat;

  overflow: hidden;
`;


const Backdrop = styled.div`
    background-color: rgba(10,10,10, 0.2);
    backdrop-filter: blur(5px);
`


const CNLogoContainer = styled.div`
    width:'200px', 
    height:'90%', 
    backgroundColor:'', 
    marginLeft:'35px', 
    display:'flex', 
    alignItems:'center'
`


const CNLogo = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 140px; 
    }
    @media screen and (min-width: 680px) {
        width: 180px; 
    }
    
` 


const NavBarUpdated = styled.div`
  width: 90%;
  height: 12%;
  background:  var(--background);
  background-blend-mode: overlay;
  position: fixed;
  top: 7%;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`

function WinnerChecker(props){

    const {isWinner, setIsWinner, winners, winnersStakeAddr, setIsClaiming} = props

    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)

    useEffect(() => {

        console.log(address)
        console.log(winners)

        setIsClaiming(false)

        if (winners.includes(String(address)) || (winnersStakeAddr.includes(stake_address))){
            setIsWinner(true)
        }else{
            setIsWinner(false)
        }

    },[address])

    return(
        null
    )
}



function Ledger(){

    const [isWalletLoading, setIsWalletLoading] = useState(false)
    const [mintAsset, setMintAsset] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [submittedTx, setSubmittedTx] = useState('')


    const [buttonPopup, setButtonPopup] = useState(false);


    const GlobalStyle = createGlobalStyle`
        :root {
           

              font-family: ButtonFont;



              --background:  rgba(0, 0, 0, 0.5);
              --background-hover:  #272e2ed6;
              --clayblue-primary: #00AEEF;
              --clayblue-secondary: #1d4ff2;
              --clayyellow-secondary:  #FFEB6C;
              --claygreen-primary: #B2FE80;
              --claycoral-primary: #DA836F;
              --claycream-primary: #FFE589;

        }
        `
const [isPopUpOpen, setIsPopUpOpen] = useState(false)

const [activeWallet, setActiveWallet] = useState('')

const [isBusy, setIsBusy] = useState(false)
const [updateWallet, setUpdateWallet] = useState(false)
const [walletToEnable, setWalletToEnable] = useState('')


const [isWinner, setIsWinner] = useState(false)

const [isClaiming, setIsClaiming] = useState(false)


const [winners, setWinners] = useState([
    'addr1q86qxhat7m80ljxhhfk7lv48d0mhut7k73rlxlysxu6qny8e8k2fjdqwz9qxdhhfmc0cahtk2ex0udr34k8gcq2wd3qqzkvqcv', 
    '0x12d8fa7a399d5d05649fe52bfbe5f0e9e852e181', 
    '0xd32ca373c732e620988fc8e70e1b57d8a33ac74d', 
    '0x04cc5aa9a2061cfdce46901161a35c57959d0297', 
    'addr1qyk7syjssvhxkvjqkmw259q0geynlket0hmhea3ll80wexpx297qh7hrfe73fwmzqlmc8dm2ueu4hj69skhuw4hu479s70ym7g', 
    '0xd856c2e8e3303fcaadb1d7fdfba698ef668c09b3', 
    '0x02d5cbbc65f6ef3eca13ac1124c232f46b3a8476',
    '0xeb8fd1efa77d9d825632a0699ee3fae1060410be'
])

const [winnersStakeAddr, setWinnersStakeAddr] = useState([
   'stake1u8unm9yexs8pzsrxmm5au8uwm4m9vn87x3c6mr5vq98xcsqpsh75z',
   'stake1uyn9zlqtlt35ulg5hd3q0aurka4wv72medzctt782m72lzcu920fg'
])


const [winnersStakeToAddr, setWinnersStakeToAddr] = useState({
    'stake1u8unm9yexs8pzsrxmm5au8uwm4m9vn87x3c6mr5vq98xcsqpsh75z': 'addr1q86qxhat7m80ljxhhfk7lv48d0mhut7k73rlxlysxu6qny8e8k2fjdqwz9qxdhhfmc0cahtk2ex0udr34k8gcq2wd3qqzkvqcv',
    'stake1uyn9zlqtlt35ulg5hd3q0aurka4wv72medzctt782m72lzcu920fg': 'addr1qyk7syjssvhxkvjqkmw259q0geynlket0hmhea3ll80wexpx297qh7hrfe73fwmzqlmc8dm2ueu4hj69skhuw4hu479s70ym7g'
})



return(

    <Container>
     <Provider>

        <GlobalStyle />

        {/*<Backdrop>*/}

        <WinnerChecker isWinner={isWinner} setIsWinner={setIsWinner} winners={winners} winnersStakeAddr={winnersStakeAddr} setIsClaiming={setIsClaiming} />

        
        <NavBar>

          <NavBarContents>

            <CNLogoContainer>
                <CNLogo src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/OFFICIAL%20Clay%20nation%20logo%20min.png" />
            </CNLogoContainer>

            <div style={{marginRight:'35px'}}>
                <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}
                />
            </div>

         </NavBarContents>

        </NavBar>

        

        <div style={{position:'relative', top:'0px', width:'100%', 
            height:'100vh', overflow:'scroll', backgroundColor:'',
            display: 'flex', justifyContent:'center', alignItems:'center', minHeight:'800px'
        }}>
             

            <div style={{width:'100%', display:'flex', visibility: isPopUpOpen ? 'hidden' : 'visible', alignItems:'center', height:'90%'}}>
            
                <div style={{display:'flex', alignItems:'center', height:'80%', 
                width:'99%',flexDirection:'column'}}>
                   
                    <div style={{marginBottom: '80px', fontSize:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'
                    }}>
                        
                    <div
                        style={{}}
                    >
                       {/* <Title>CLAY NATION</Title>
                        <Subtitle>HANDLE BACKGROUNDS</Subtitle>*/}

                    </div>


                    </div>



                    {/*<Carousel />*/}

                    <Grid container spacing={3} 
                          alignItems="center"
                          justifyContent="center" style={{backgroundColor:'', marginLeft:'0px'}}
                    >

                    {/*<Cards 
                        setMintAsset={setMintAsset} 
                        isAlertOpen={isAlertOpen} 
                        setIsAlertOpen={setIsAlertOpen} 
                        submittedTx={submittedTx} 
                        setSubmittedTx={setSubmittedTx}
                    />*/}






                  <div style={{height:'75%', width:'100%', display:'flex', backgroundColor:'', color: 'rgba(255, 255, 255, 0.8)'}}>
                    

                    {!isWinner || !isClaiming  ?
                     <Raffle setIsClaiming={setIsClaiming} isWinner={isWinner} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
                     :
                     <Winner isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} 

                        winners={winners} winnersStakeAddr={winnersStakeAddr} winnersStakeToAddr={winnersStakeToAddr}
                     />
                    }

                    {/*<motion.img
    transition={{ duration: 0.5, delay: 3 }}
    initial={{ y:100, opacity:0 }}
    animate={{ opacity:0.95, y:0 }}
    src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/argyhalf.png'} alt="logo" className="logo" style={{width:'10%', height: 'auto', position: 'relative', left: '18%'}} /> */}


                    {/*<div style={{display:'flex', justifyContent:'row', height:'100%'}}>
                        <div className="raffle-pannel">
                            <h1 style={{color: 'var(  --clayblue-primary)'}}>[LEDGER] RAFFLE</h1>
                            <div className="raffle-ticket-display">
                              <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/raffleticket.png'} style={{width: '88%', height: 'auto', marginTop: '10%',filter: 'drop-shadow(0px 0px 45px rgba(69, 152, 255, 0.4))'}} alt="ticket" />
                              <p style={{marginTop: '10%', fontFamily: 'ShortStack', fontSize: 'calc(2px + 2vmin)', color: 'var(--claygreen-primary)'}}>ENDS IN: 4d 6h 30s</p>
                            </div>
                            <button className="raffle-ticket-button">15 ADA</button> 
                        </div>

                        <div className="form-images">
                            <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Ledger/ledgers.gif'} alt="deathrow" />
                        </div>
                    </div>*/}


                    </div>








                    <div style={{position:'relative', marginTop:'60px', marginBottom:'50px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
                      <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
                        <Footer />
                      </div>
                    </div>


                    </Grid>
                    
                </div>

            </div>



        </div>


        {isPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>

       : null}

        
      {/* <div style={{position:'relative', bottom:'20px', width:'100%', height:'200px', backgroundColor:'blue'}}>
        <Footer />
       </div>*/}


       {/*</Backdrop>*/}




       <div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
       </div>

       <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />


     </Provider>
    </Container>
)
}
export default Ledger;




