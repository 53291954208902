import styled from "styled-components";


export const H1 = styled.h1`
font-size: clamp(0.5rem, 1.5rem, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
margin-top: 2rem;
margin-bottom: 2rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 5vw, 4rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.7vw, 3rem);
}   
`;

export const H2 = styled.h2`
font-size: clamp(0.5rem, 0.8rem, 3rem);
font-family: ShortStack;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
line-height: 0.5;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 1vw, 4rem);
    line-height: 1;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2.7vw, 3rem);
}   
`;

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;
flex-direction: column;
width: 80%;
margin: 0 auto;
`;


export const ImageCard = styled.div`
outline: 4px solid var(--panel-background);
border-radius: 0.5rem;
background-color: rgba(0, 0, 0, 0.2);
color: var(--claytext-primary);
text-shadow: 0px 1.2px 0px rgba(0, 0, 0, 0.5);
font-family: 'ClayFont';
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem; 
text-transform: uppercase;
margin: 0rem 1rem 0rem 2rem;
padding: 0.5rem 0rem 0.5rem 0rem;
display: flex;
flex-direction: column;
cursor: pointer;
transition: 0.2s ease-in-out;
&:hover {
    outline: 4px solid var(--clayblue-primary);
}
@media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
@media screen and (max-width: 768px) { /* mobile */
    padding: 2rem 0rem 2rem 0rem;
}
}
`;

