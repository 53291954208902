import { useState, useEffect, useContext } from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import Box from '@mui/material/Box';

import UnbakePopUp from './UnbakePopUp'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {MainClaimContext} from './ClaimContext'




function Unbake(props) {

  const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const clay_info = {'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728':{collection:'cn',base_name:'ClayNation'}, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768':{collection:'gc',base_name:'ClayCharlotte'}}
  const eligible_info = {cn:{policy_id:'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728', base_name: 'ClayNation'},gc:{policy_id:'94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768', base_name: "ClayCharlotte"}}


  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  const permError = (message) => toast.error(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  const [enterUnbake, setEnterUnbake] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [slashedAmount, setSlashedAmount] = useState(0)

  const [assetsToUnbake, setAssetsToUnbake] = useState([])

  const [currentSlot, setCurrentSlot] = useState()

  const txid = props.txid
  const amount = props.amount

  function slashing_fn(day, amountToSlash){

    let slashedAmount;

    if (day >= 0 && day <= 14){
      slashedAmount = (((Math.pow(2,0.1 * day) - 1) / 1.64) * amountToSlash).toFixed(0)
    }else{
      slashedAmount = 0
    }
    return slashedAmount
  }



async function getParameters(){
  const protocolParams = await fetch("https://clayapi.com/baking/parameters/")
  const json_params = await protocolParams.json()
  return json_params
}

async function getCurrentSlot(){
    let slot = await fetch('https://clayapi.com/baking/slot/').then(response => response.json())
                                                                  .then(data => data)

    slot = parseInt(slot.slot)

    return slot
}


async function getUtxos(cardano){
      const txUnspentOutputs = serLib.TransactionUnspentOutputs.new()
        const utxos = (await cardano.getUtxos()).map((utxo) =>
            txUnspentOutputs.add(serLib.TransactionUnspentOutput.from_bytes(Buffer.from(utxo, "hex")))
         );
        return txUnspentOutputs
}

function get_required_signers(utxos){
    let required_keys = []

    for (let i = 0; i < utxos.length; i ++){
        let utxo = utxos[i]
        let utxo_output_address = utxo.output().address()
        //console.log(utxo_output_address.to_bech32())

        let utxo_key_hash = Buffer.from(serLib.BaseAddress.from_address(utxo_output_address).payment_cred().to_keyhash().to_bytes()).toString('hex')

        if (!required_keys.includes(utxo_key_hash)){
            required_keys.push(utxo_key_hash)
        }

    }
    return required_keys
}

function compare_inputs(input_1, input_2){
    let tx_hash_1 = Buffer.from(input_1.transaction_id().to_bytes()).toString('hex')
    let tx_index_1 = input_1.index()

    let tx_hash_2 = Buffer.from(input_2.transaction_id().to_bytes()).toString('hex')
    let tx_index_2 = input_2.index()

    //console.log(tx_hash_1 + '#' + tx_index_1)
    //console.log(tx_hash_2 + '#' + tx_index_2)

    return (tx_hash_1 == tx_hash_2 && tx_index_1 == tx_index_2)
}

function parse_inputs(utxo_inputs){
    let inputs_list = []
    for (let i = 0; i < utxo_inputs.len(); i++){
        let utxo_input = utxo_inputs.get(i)
        let tx_hash = Buffer.from(utxo_input.transaction_id().to_bytes()).toString('hex')
        let tx_index = String(utxo_input.index())

        inputs_list.push({'tx_hash':tx_hash,'tx_index':tx_index})
    }
    return inputs_list
}


function display_utxos(utxos){
    let utxo_list = []
    for (let i = 0; i < utxos.len(); i++){
        let utxo = utxos.get(i)

        let utxo_input = utxo.input()
        let utxo_output = utxo.output()

        let utxo_amount = utxo_output.amount()

        let utxo_lovelace = utxo_amount.coin().to_str()

        let utxo_multiasset = utxo_amount.multiasset()

        let cur_utxo = []

        cur_utxo.push({"unit":"lovelace","quantity":utxo_lovelace})

        if (typeof(utxo_multiasset) != 'undefined'){
           
            for (let j = 0; j < utxo_multiasset.len(); j++){
                let policy_script = utxo_multiasset.keys().get(j) 
                let assets = utxo_multiasset.get(policy_script)

                for (let k = 0; k < assets.len(); k++){
                    let asset_amount = assets.get(assets.keys().get(k))
                    //console.log('ASSET NAME')
                    //console.log(assets.keys().get(k))

                    let asset_name = Buffer.from(assets.keys().get(k).name()).toString('hex')

                    //console.log(asset_name)

                    let policy_hash = Buffer.from(policy_script.to_bytes()).toString('hex')

                    let asset_id = policy_hash + asset_name

                    cur_utxo.push({"unit":asset_id,"quantity":asset_amount.to_str()})

                    //console.log(cur_utxo)
                }
            }
        }

        let tx_hash = Buffer.from(utxo_input.transaction_id().to_bytes()).toString('hex')
        let output_index = utxo_input.index()
        utxo_list.push({"tx_hash":tx_hash,"tx_index":String(output_index), "amount":cur_utxo})
    }

    return utxo_list
}


async function get_txBuilder(protocolParams){
  
   //console.log(protocolParams)

   const min_fee_a = String(protocolParams.min_fee_a)
   const min_fee_b = String(protocolParams.min_fee_b)
   const pool_deposit = String(protocolParams.pool_deposit)
   const key_deposit = String(protocolParams.key_deposit)
   const max_value_size = protocolParams.max_val_size
   const max_tx_size = protocolParams.max_tx_size
   const coins_per_utxo_word = String(protocolParams.coins_per_utxo_word)

   const linearFee = serLib.LinearFee.new(
       serLib.BigNum.from_str(min_fee_a),
       serLib.BigNum.from_str(min_fee_b)
   );


   const txBuilderCfg = serLib.TransactionBuilderConfigBuilder.new()
       .fee_algo(linearFee)
       .pool_deposit(serLib.BigNum.from_str(pool_deposit))
       .key_deposit(serLib.BigNum.from_str(key_deposit))
       .max_value_size(max_value_size)
       .max_tx_size(max_tx_size)
       .coins_per_utxo_word(serLib.BigNum.from_str(coins_per_utxo_word))
       .prefer_pure_change(false)
       .build();

   const txBuilder = serLib.TransactionBuilder.new(txBuilderCfg);
   return txBuilder
}


async function getAssetRewards(asset, asset_info, slot){
   
    let tempSlot = slot
    if (asset.startsWith('40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728')){
          if (slot > 134345140){
            tempSlot = 134345140
          }
        }else if (asset.startsWith('94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768')){
          if (slot > 138920740){
            tempSlot = 138920740
          }
        }

    let slot_difference = parseInt(tempSlot) - parseInt(asset_info.slot) - 1209600
    let per_slot_rate = asset_info.rate / 86400

    let rewards = per_slot_rate * slot_difference

    rewards = rewards.toFixed(0)

    return rewards
}


async function getTotalRewards(assets, assets_info, slot){
  let total = 0 

  for (let i = 0; i < assets.length; i++){
      let asset_rewards = await getAssetRewards(assets[i], assets_info[assets[i]],slot)
      total += parseInt(asset_rewards)
  }

  return total
}


async function setInitialFunction(protocolParams, receiver_encoded){

  let txBuilder_1 = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder_1.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+500000)))
      ),
  );




  return txBuilder_1


}

function isBalanceSufficient(assets_to_bake, assets_found) {
  if (assets_to_bake.length === assets_found.length) {
    return assets_to_bake.every(element => {
      if (assets_found.includes(element)) {
        return true;
      }

      return false;
    });
  }
  return false;
}


function get_utxo_hashes_for_baking(assets_to_bake, utxos){
    let required_for_baking = []

    let required_for_baking_check = []

    //console.log(assets_to_bake)

    //console.log(utxos)

    let utxo_list = display_utxos(utxos)

    //console.log(utxo_list)

    for (let i = 0; i < utxo_list.length; i++){
        let cur_utxo = utxo_list[i]
        let cur_amount = utxo_list[i]['amount']
        
        for (let j = 0; j < cur_amount.length; j++){
            
            //let utxo = {'tx_hash':cur_utxo['tx_hash'],'tx_index':String(cur_utxo['tx_index'])}

            let utxo = cur_utxo['tx_hash'] + '#' + String(cur_utxo['tx_index'])

            if (assets_to_bake.includes(cur_amount[j]['unit'])){
               required_for_baking_check.push(cur_amount[j]['unit'])

                if (!required_for_baking.includes(utxo)){
                    required_for_baking.push(utxo)
                    
                }
            }
        }
    }

    //console.log("CHECKING SUFFICIENT BALANCE")

    //console.log(assets_to_bake)
    //console.log(required_for_baking_check)

    let isSufficient = isBalanceSufficient(assets_to_bake, required_for_baking_check)

    //console.log(isSufficient)

    if (isSufficient){
        return required_for_baking
    }else{
      throw 'Balance of assets is not SUFFICIENT!'
    }
}


function get_asset_ids_for_baking(assets){

  let assets_to_bake = []
  for (let i = 0; i < assets.length; i++){
    let cur_asset = assets[i]
    let cur_collection = cur_asset.slice(0,2)
    let cur_serial = cur_asset.slice(2,)

    let cur_policy_id = eligible_info[cur_collection]['policy_id']
    let cur_base_name = eligible_info[cur_collection]['base_name']

    let cur_name = cur_base_name + String(cur_serial)

    cur_name = Buffer.from(cur_name).toString('hex')

    let cur_asset_id = cur_policy_id + cur_name

    assets_to_bake.push(cur_asset_id)

  }

  return assets_to_bake
}


function get_required_utxos_for_baking(baking_utxo_hashes, utxos){
    let required_utxos = []
    for (let i = 0; i < utxos.len(); i ++){
        let utxo = utxos.get(i)
        let utxo_input = utxo.input()
        let utxo_output = utxo.output()

        let utxo_amount = utxo_output.amount()

        let utxo_lovelace = utxo_amount.coin().to_str()

        let utxo_multiasset = utxo_amount.multiasset()

        let tx_hash = Buffer.from(utxo_input.transaction_id().to_bytes()).toString('hex')
        let output_index = utxo_input.index()

        let utxo_hash = tx_hash + '#' +String(output_index)

        //console.log('UTXO HASH')

        //console.log(utxo_hash)

        if (baking_utxo_hashes.includes(utxo_hash)){
           //console.log('HEY')
            //console.log(utxo_hash)

            required_utxos.push(utxo)
        }
    }

    return required_utxos
}


async function submitTransaction(transaction, witness){
    const data = {'tx':transaction,'witness':witness}
    const response = await fetch('https://clayapi.com/unbaking/', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    return response.json()
}


async function BuildUnbakeTx(){

  notifyInfo('Building Unbaking transaction...')

  /*
  console.log(slashedAmount)

  console.log(txid)

  console.log(assetsToUnbake)
  */


  /*
  const asset_name = "TestUnbake"

  const policy_signer_keyhash = "d1cc41520fde1060908ce5ce94fa67cb250e981aa249672c7814a240"

  let receiver = "addr1z9x57f8sd6wz37nm2cdkfzmfhcgjs98zpl5dul5js80gwsqrxpgqxqan3e2nnldfpmmw2xtvrahmt9n4tv2yqns20mpqfq6uhu"

  let receiver_encoded = serLib.Address.from_bech32(receiver)

  const policyScriptHash = "8201828200581cd1cc41520fde1060908ce5ce94fa67cb250e981aa249672c7814a24082051a0f4beeca"
  */

  const asset_name = "UnbakeClay"
  const policy_signer_keyhash = "e94ba89183b611d3aed14e818c8421a5286608f22ff41e27330e3985"
  let receiver = "addr1z8tlml0x8spf2qyzse7qwh0hh4qp50fhx6m2kmr3wsvez340fm4l4jflstl7hz7ucs5793gzr297g67psdx8dssdf68ssh59m2"
  let receiver_encoded = serLib.Address.from_bech32(receiver)
  const policyScriptHash = "8201828200581ce94ba89183b611d3aed14e818c8421a5286608f22ff41e27330e398582051a0f4beeca"





  const policyScript = serLib.NativeScript.from_bytes(Buffer.from(policyScriptHash,'hex'))
  const policyId = Buffer.from(
          serLib.ScriptHash.from_bytes(
                policyScript.hash().to_bytes()
            ).to_bytes(),
            "hex"
        ).toString("hex");

  let cardano = await window.cardano[activeWallet]
  cardano = await cardano.enable()


  let address_encoded = await cardano.getUsedAddresses()

  address_encoded = address_encoded[0]

  address_encoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex"))



  const metadata = {"C":assetsToUnbake,"U":[txid]}


  //console.log(`METADATA: ${JSON.stringify(metadata, null, 4)}`);


  //PROTOCOL PARAMS
  let protocolParams = await getParameters()


  const utxos = await getUtxos(cardano)

  //console.log(utxos)


  let txBuilder = await get_txBuilder(protocolParams)

  const lovelaceToSend = 2500000

  txBuilder.add_output(
      serLib.TransactionOutput.new(
          receiver_encoded,
          serLib.Value.new(serLib.BigNum.from_str(String(lovelaceToSend+2000000)))
      ),
  );

  const ttl = parseInt(currentSlot) + 7200

  const asset_ids_to_unbake = get_asset_ids_for_baking(assetsToUnbake)

  //console.log('ASSET IDS TO UNBAKE!')
  //console.log(asset_ids_to_unbake)




///////

 let attempts = 0
  let isSuccessful = false
   while (true){
      try{
          //console.log('I AM HERE')

        
         let test_inputs;

         let currentError = ''


    try{

        let txBuilder_2 = await setInitialFunction(protocolParams, address_encoded)

        txBuilder_2.add_inputs_from(utxos, 1)

        txBuilder_2.add_change_if_needed(address_encoded)

        test_inputs = txBuilder_2.build_tx().body().inputs()
    }catch (error){
        //console.log('\n\n\nCAUGHT 1\n\n\n')
        currentError = error
    }


    try{

        if (!test_inputs){

            let txBuilder_3 = await setInitialFunction(protocolParams, address_encoded)

            txBuilder_3.add_inputs_from(utxos, 0)

            txBuilder_3.add_change_if_needed(address_encoded)

            test_inputs = txBuilder_3.build_tx().body().inputs()
        }
    }catch (error){
       // console.log('\n\n\nCAUGHT 2\n\n\n')
        currentError = error
    }


        try{

        if (!test_inputs){

            let txBuilder_4 = await setInitialFunction(protocolParams, address_encoded)

            txBuilder_4.add_inputs_from(utxos, 3)

            txBuilder_4.add_change_if_needed(address_encoded)

            test_inputs = txBuilder_4.build_tx().body().inputs()
        }
    }catch (error){
        //console.log('\n\n\nCAUGHT 3\n\n\n')
        currentError = error
    }



    try{

        if (!test_inputs){

            let txBuilder_5 = await setInitialFunction(protocolParams, address_encoded)

            txBuilder_5.add_inputs_from(utxos, 2)

            txBuilder_5.add_change_if_needed(address_encoded)

            test_inputs = txBuilder_5.build_tx().body().inputs()
        }
    }catch (error){
        //console.log('\n\n\nCAUGHT 4\n\n\n')
        currentError = error
    }



    if (!test_inputs){
        throw currentError
    }


    ////


    let utxo_hashes;

    try{
        utxo_hashes = get_utxo_hashes_for_baking(asset_ids_to_unbake, utxos)
    }catch (error){
        notifyError(error)
        break
    }
    let required_utxos = get_required_utxos_for_baking(utxo_hashes, utxos)
    

    //console.log('REQUIRED UTXOS')
    //console.log(required_utxos)


    let transaction_inputs = []

        for (let i = 0; i < test_inputs.len(); i++){
                for (let j = 0; j < utxos.len(); j ++){

                    /*
                    console.log('UTXOS')
                    console.log(utxos.get(j))
                    console.log(utxos.get(j).input())
                    console.log('COMPARE')
                    */
                    


                    if (compare_inputs(utxos.get(j).input(),test_inputs.get(i))){
                        //console.log(utxos.get(j))
                        transaction_inputs.push(utxos.get(j))
                    }
                }
            }


            //console.log(transaction_inputs)
            //console.log(required_utxos)

            let new_inputs = []


            let isEnoughLovelace = false
            for (let i = 0; i < required_utxos.length; i++){
                //console.log(required_utxos[i].input())

                new_inputs.push(required_utxos[i])

                if (parseInt(required_utxos[i].output().amount().coin().to_str()) > 6000000){
                    isEnoughLovelace = true
                }
            }


             for (let i = 0; i < transaction_inputs.length; i++){
                    let isIncluded = false 

                    for (let j = 0; j < new_inputs.length; j++){
                        let cur_input1 = Buffer.from(new_inputs[j].input().transaction_id().to_bytes()).toString('hex')+'#'+String(new_inputs[j].input().index())
                        let cur_input2 = Buffer.from(transaction_inputs[i].input().transaction_id().to_bytes()).toString('hex')+'#'+String(transaction_inputs[i].input().index())

                        //console.log(cur_input1)
                        //console.log(cur_input2)

                        if (cur_input1 == cur_input2){
                            isIncluded = true
                        }
                    }

                    if (!isIncluded){
                        new_inputs.push(transaction_inputs[i])
                    }
                }



            let required_signers = get_required_signers(new_inputs)
            required_signers.push(policy_signer_keyhash)


            let txInputs = serLib.TxInputsBuilder.new()


            for (let i = 0; i < new_inputs.length; i++){
                //console.log(new_inputs[i])

                //console.log(new_inputs[i].output().amount())

                txInputs.add_regular_input(address_encoded,new_inputs[i].input(), new_inputs[i].output().amount())
            }


            txBuilder = await get_txBuilder(protocolParams)


            //console.log(txBuilder)


            let unbake_slashedAmount = slashedAmount

            if (slashedAmount == 0){
              //TEST
              unbake_slashedAmount = 1
            }



            txBuilder.add_mint_asset_and_output(
                  policyScript,
                  serLib.AssetName.new(Buffer.from(asset_name)),
                  serLib.Int.new_i32(unbake_slashedAmount),
                  serLib.TransactionOutputBuilder.new().with_address(receiver_encoded).next(),
                  serLib.BigNum.from_str(String(lovelaceToSend))
                  );

                txBuilder.add_json_metadatum(
                serLib.BigNum.from_str("4159"),
                JSON.stringify(metadata)
               );


            txBuilder.set_inputs(txInputs)
            txBuilder.set_ttl_bignum(serLib.BigNum.from_str(String(ttl)));
                        

            //ADDING REQUIRED SIGNERS
            for (let i = 0; i < required_signers.length; i++){
               txBuilder.add_required_signer(serLib.Ed25519KeyHash.from_bytes(Buffer.from(required_signers[i],'hex')))
            }



            txBuilder.add_change_if_needed(address_encoded)


            isSuccessful = true
            break





 
}catch (error){
         //console.log(error)
            //console.log('Attempts ' + attempts)
            if (attempts < 10){
                attempts += 1
            }else{
                break;
            }
        }   
    }
  


    if (isSuccessful){
        try{




         const unsignedTx = txBuilder.build_tx()
         const unsignedTx_hash = Buffer.from(unsignedTx.to_bytes()).toString('hex')
         let tx_witness = await cardano.signTx(unsignedTx_hash,true)


         
         console.log('TO VALIDATE!!!')
         console.log(unsignedTx_hash)
         console.log(tx_witness)
         


         //TO GET TRANSACTION ID
         const txBody = unsignedTx.body()
         const txBodyHash = serLib.hash_transaction(txBody)
         const txId = Buffer.from(txBodyHash.to_bytes()).toString('hex');
         //console.log(txId)
         
         const unbaking_status = await submitTransaction(unsignedTx_hash, tx_witness)
         
         //console.log(unbaking_status)

         if ('error' in unbaking_status){
            permError(unbaking_status['error'])
         }else if ('TXID' in unbaking_status){
            notifySuccess('Transaction has been submitted!')
            //permSuccess('Transaction has been submitted!')/*+ baking_status['TXID'])*/

            //setIsAlertOpen(true)
            //setSubmittedTx(baking_status['TXID'])

            //setSelectedTokens([])
         }else{
            permError('Transaction failed')
            
         }

     }catch (error){
        notifyError(error.info)
        
     }
      }else{
        notifyError('Failed to initiate unbaking!')
      }
  }



/////





async function getBakeTxInfo(bakeTxID){
    //console.log(bakeTxID)

    let tx_info = await fetch('https://clayapi.com/unbaking/'+bakeTxID+'/').then(response => response.json())
                                                                  .then(data => data)
    //console.log(tx_info)

    //console.log(tokens)

    let slot = await getCurrentSlot()

    slot = parseInt(slot)

    //console.log(slot)

    setCurrentSlot(slot)

    let baking_assets = Object.keys(tx_info)

    let eligible_assets = []

    let assets_matched = []

    for (let i = 0; i < tokens.length; i++){
      let cur_asset = tokens[i]['unit']

      if (cur_asset.slice(0,56) in clay_info){
        eligible_assets.push(cur_asset)
      }
    }


    for (let i = 0; i < baking_assets.length; i++){
      
      if(eligible_assets.includes(baking_assets[i])){
        assets_matched.push(baking_assets[i])
      }

    }



    let rewards_available = 0

    let latest_slots = []

    let assets = []

    for (let i = 0; i < assets_matched.length; i++){

       let cur_asset_info = tx_info[assets_matched[i]]

       let asset_latest_slot = parseInt(cur_asset_info['latest_slot'])

       let asset_prev_slot = parseInt(cur_asset_info['prev_slot'])

       let asset_rate = parseFloat(cur_asset_info['rate'])

       let rate_per_slot = asset_rate / 86400

       latest_slots.push(asset_latest_slot)

       rewards_available = rewards_available + (asset_latest_slot - asset_prev_slot - 1209600) * rate_per_slot

       let cur_asset_policy = assets_matched[i].slice(0,56)
       let cur_asset_name_hex = assets_matched[i].slice(56,)

       let cur_collection = clay_info[cur_asset_policy]['collection']
       let cur_base_name = clay_info[cur_asset_policy]['base_name']

       let cur_asset_name = Buffer.from(cur_asset_name_hex,'hex').toString('utf8')

       let cur_serial = cur_asset_name.slice(cur_base_name.length,)

       let cur_asset = cur_collection + String(cur_serial)

       assets.push(cur_asset)

    }

    setAssetsToUnbake(assets)

    rewards_available = rewards_available.toFixed(0)

    if (rewards_available < 0){
      rewards_available = 0
    }

    let latest_slot = 0
    if ((new Set(latest_slots)).size == 1){
      latest_slot = parseInt(latest_slots[0])
    }else{
      notifyError('No rewards to unbake!')
      setIsOpen(true)
      return
    }

    //console.log(rewards_available)
    let days_passed = Math.floor((slot - latest_slot) / 86400)
    let slashedRewards = slashing_fn(days_passed, rewards_available)
    setSlashedAmount(slashedRewards)
    setIsOpen(true)

}





useEffect(()=>{

  if (isSubmitted){
    BuildUnbakeTx()
  }

},[isSubmitted])



return (

 <div 

 onMouseEnter={()=>{
  setEnterUnbake(true)
}} 

  onMouseLeave={()=>{
    if (!isOpen){
     setEnterUnbake(false)
    }
  }} 

  style={{minHeight:'45px'}}
  >


  {enterUnbake && !isSubmitted ? 

    <Box component="img" sx={{height:'100%', width:'100px', cursor:'pointer'}} src={"/unbake.svg"} 
      onClick={()=>{
        //console.log('Unbake')
      
        getBakeTxInfo(txid)
       

      }}

    /> 
    
    :
    
    <Box component="img" sx={{height:'100%', width:'100px', cursor:'pointer'}} src={"/Baking.svg"} /> 

  }


{isOpen ?

  <UnbakePopUp setIsSubmitted={setIsSubmitted} setIsOpen={setIsOpen} setEnterUnbake={setEnterUnbake} fullAmount={amount} slashedAmount={slashedAmount} />
  
  :

  null

}


</div>

  );
}

export default Unbake;


