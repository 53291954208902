import {useState, useEffect, us} from 'react'

import * as THREE from 'three'


function PositionalSoundLoader(props){

  const {audioListener, soundsToLoad, loadedSounds, setLoadedSounds} = props

  const [audioLoader] = useState(() => new THREE.AudioLoader())


    useEffect(() => {

      if (audioListener === null){
        return
      }

      let temp_loadedSounds = {}
      for (let key in soundsToLoad){
        let cur_sound = soundsToLoad[key]
          
          let cur_path = cur_sound.url
          const sound = new THREE.PositionalAudio( audioListener );
          const audioLoader = new THREE.AudioLoader();
            audioLoader.load(cur_path, function( buffer ) {
              sound.setBuffer( buffer );
              

               if ('loop' in cur_sound){
                sound.setLoop( true );
               }else{
                sound.setLoop( false );
               }
              
              sound.setRefDistance( 1 );

              if ('volume' in cur_sound){
                sound.setVolume( cur_sound['volume'] );
              }else{
                sound.setVolume( 0.5 );
              }

             temp_loadedSounds[key] = sound 
              
            });
            
    }

    //setLoadedSounds({...loadedSounds, ...temp_loadedSounds})


    setLoadedSounds(temp_loadedSounds)



   },[audioListener])


  return(
    null
  )
}

export default PositionalSoundLoader;