import { useState, useContext, useEffect } from 'react';



import Grid from '@mui/material/Grid';

import './Raffles.css'

import './InputStyle.css'

import styled from 'styled-components'


import BuyTicket from './BuyTicket'



import { ToastContainer, toast } from 'react-toastify';


import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import BoltIcon from '@mui/icons-material/Bolt';






const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`




  const ButtonText = styled.h1`
    position: relative;
    font-size: 15px;
    font-family: 'shortstack';
    letter-spacing: 0.1em;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    color: #fff;
    z-index: 4;
  `



function RafflePopUp(props) {


    const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)



    const raffleAsset = props.raffleAsset
    const setRaffleAsset = props.setRaffleAsset



    const [searchClassName, setSearchClassName] = useState('inputField')

    const [numTickets, setNumTickets] = useState('')


    const [totalAda, setTotalAda] = useState(0)


    function ClosePopUp(){


    return(

            <button className='close-btn' 

            onClick={() => {
                            setRaffleAsset(null)
                            //setClayPrice('')
             }}>X</button> 
        )


    }

    useEffect(() => {

        //console.log(raffleAsset)

    }, [raffleAsset])






    function AssetThumbnail(){


    return(
        <div style={{height:'100%', width:'100%', backgroundColor:'', marginTop:'10px'}}>
            <div style={{display:'flex', justifyContent:'center', color:'bisque', fontFamily:'ShortStack', fontSize:'22px', fontWeight:'bold'}}>
                
                <div style={{marginTop:'10px', marginBottom:'10px', fontSize:'20px'}}>{raffleAsset.name}</div>
                
            </div>                       
            <img src={raffleAsset.image} style={{width:'80%', minWidth:'180px', maxWidth:'300px',height:'auto', borderRadius:'10px', objectFit: 'cover'}} />                       
        </div>
    )
}



function TicketsSold(props){


    //const prop_accumulatedToken = props.accumulatedToken


    return(

       
    <div style={{width:'70%'}}>

       
        <div>

             <div style={{minHeight:'60px', width:'100%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'center', alignItems:'center', 
                                                    backgroundColor:'rgba(255,255,255,0.05)',
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'15px'}}>
                
                <div style={{color:'bisque', fontWeight:'bold', fontFamily:'ShortStack', fontSize:'18px'}}>
                    Tickets:
                </div>

                <div style={{marginRight:'10px'}}>
                  
                  <div style={{display:'flex', minWidth:'220px', width:'100%', justifyContent:'space-between', alignItems:'center'}}>

                      <div style={{color:'bisque', fontFamily:'ShortStack', marginRight:'5px'}}>
                        Your tickets:
                     </div>


                     <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{marginRight:'5px', color: 'rgba(44, 179, 255, 1)'}}>
                          {raffleAsset.yourTickets}
                        </div>

                        <ConfirmationNumberIcon style={{ color: 'rgba(44, 179, 255, 1)' }} />

                    </div>

                  </div>


                  <div style={{display:'flex', minWidth:'220px', width:'100%', justifyContent:'space-between', alignItems:'center'}}>

                    <div style={{color:'bisque', fontFamily:'ShortStack', marginRight:'5px'}}>
                        Sold tickets:
                    </div>


                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    
                        <div style={{marginRight:'5px', color: 'rgba(196, 185, 255, 0.8)'}}>
                          {raffleAsset.purchasedTickets}/{raffleAsset.totalTickets}
                        </div>

                        <LocalActivityIcon style={{ color: 'rgba(196, 185, 255, 0.8)' }} />

                    </div>

                  </div>

                </div>

                



            </div>
            </div>


    </div>
            
    )
}






function RaffleChances(props){


    //const prop_accumulatedToken = props.accumulatedToken


    const [currentChances, setCurrentChances] = useState(0.0)
    const [soldOutChances, setSoldOutChances] = useState(0.0)


    useEffect(() => {

        let yourTickets = raffleAsset.yourTickets
        let purchasedTickets = raffleAsset.purchasedTickets
        let totalTickets = raffleAsset.totalTickets

        let cur_numTickets = parseInt(numTickets, 10)

        if (isNaN(cur_numTickets)){
            cur_numTickets = 0
        }

        yourTickets = parseInt(yourTickets, 10) 

        purchasedTickets = parseInt(purchasedTickets, 10) 

        totalTickets = parseInt(totalTickets, 10)


        let temp_currentChances = 0 
        let temp_soldOutChances = 0 

        if (cur_numTickets + purchasedTickets > 0){
            temp_currentChances = (cur_numTickets + yourTickets) / (cur_numTickets + purchasedTickets) * 100
            setCurrentChances(temp_currentChances.toFixed(2))
        }else{
            temp_currentChances = 0
        }


        if (totalTickets > 0){
            temp_soldOutChances = (cur_numTickets + yourTickets) / (totalTickets) * 100
            setSoldOutChances(temp_soldOutChances.toFixed(2))
        }else{
            temp_soldOutChances = 0
        }
        

        //console.log(temp_currentChances)
        //console.log(temp_soldOutChances)


        /*
        console.log(yourTickets)
        console.log(purchasedTickets)
        console.log(totalTickets)
        console.log(cur_numTickets)
        */



    }, [raffleAsset, numTickets])



    return(

       
    <div style={{width:'70%'}}>

       
        <div>

             <div style={{minHeight:'60px', width:'100%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'15px'}}>
                
                <div style={{color:'bisque', fontWeight:'bold', fontFamily:'ShortStack', fontSize:'18px'}}>
                    Chances:
                </div>

                <div style={{marginRight:'10px'}}>
                  
                  <div style={{display:'flex', width:'220px', justifyContent:'space-between', alignItems:'center'}}>

                      <div style={{color:'bisque', fontFamily:'ShortStack'}}>
                        Current:
                     </div>


                     <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{marginRight:'5px', color: 'bisque', fontWeight:'bold'}}>
                          {currentChances} %
                        </div>

                    </div>

                  </div>


                  <div style={{display:'flex', width:'220px', justifyContent:'space-between', alignItems:'center'}}>

                    <div style={{color:'bisque', fontFamily:'ShortStack'}}>
                        If sold out:
                    </div>


                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    
                        <div style={{marginRight:'5px', color: 'bisque', fontWeight:'bold'}}>
                          {soldOutChances}%
                        </div>

                       
                    </div>

                  </div>

                </div>

                



            </div>
            </div>


    </div>
            
    )
}











function AssetProperties(props){

    let collection = props.collection
    let meta = props.meta


    function displayProp(category, trait){

       

        return(

                <span>
                    {trait} 
                </span>

        )

    }


    return(
            <div style={{maxHeight:'250px', width:'70%', backgroundColor:'', color:'bisque', fontFamily:'ShortStack',
                                             backgroundColor:'rgba(255,255,255,0.05)', borderRadius:'20px', marginTop:'10px', paddingTop:'10px'}}>
                
            <div style={{paddingBottom:'2px', fontWeight:'bold', fontSize:'18px'}}>
                Properties:
            </div>

                <div className={"CN-properties"} style={{overflow:'scroll',  maxHeight:'180px', marginTop:'0px',backgroundColor:'', paddingTop:'6px', paddingBottom:'6px', backgroundColor:''}}>
                                    
                        {Object.keys(meta).map(element => (
                            element == 'image' || element == 'collection' ? null :

                            <div key={raffleAsset.asset_id + element + raffleAsset.meta[element]} style={{marginTop:'2px', marginBottom:'2px'}}>
                                <span className={"CN-clay-properties"}>{element}</span> 
                                <span className={"CN-clay-percentage"}>{displayProp(element, meta[element] ? meta[element] : 'None')}</span>
                            </div>
                                        
                           ))}
                                             
                </div>
            </div>
    )

}
    
  

    async function getCurrentSlot(){

        let slot = await fetch('https://claymarkets.com/api/current/slot/').then(response => response.json())
                                                                      .then(data => data)
        slot = parseInt(slot.slot, 10)

        return slot
    }




    function checkTicketNum(inputValue){

    //console.log(inputValue)

    const temp_numTickets = parseInt(inputValue, 10)
    //console.log(temp_clayPrice)

    if (inputValue == ''){
        

        setTotalAda(0)
        return ''
    }

    if (!isNaN(inputValue) && 
         parseInt(Number(inputValue)) == inputValue && 
         !isNaN(parseInt(inputValue, 10))){
         

         let purchaseLimit =  parseInt(raffleAsset.purchaseLimit, 10)

         let ticketsLeft = parseInt(raffleAsset.totalTickets, 10) - parseInt(raffleAsset.purchasedTickets, 10)

         ticketsLeft = Math.max(ticketsLeft, 0)

         
         if (temp_numTickets <= purchaseLimit){

            let adjustedAmount = Math.min(temp_numTickets, ticketsLeft)

            if (temp_numTickets > adjustedAmount){
                notifyWarn('Only ' + String(ticketsLeft) + ' tickets left!')
            }


            setTotalAda(adjustedAmount * raffleAsset.ticketPrice)
            
            return String(adjustedAmount)
         
         }else{
            
            notifyWarn(String(purchaseLimit) + ' ticket limit reached!')
            
            return numTickets

         }
    }

    return '0'

  }




function AdaEquivalent(props){

    const pValue = props.pValue

    

    return(
        <div style={{color:'bisque', fontFamily:'ShortStack', marginTop:'5px', marginBottom:'5px'}}>
           (<span style={{fontWeight:'bold'}}>{Math.round(numTickets * pValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> ADA)
        </div>

    )

  }






  return (
    
    <>


   
    {raffleAsset ? 

    <div>
        <Grid container justifyContent="center" alignItems="center">
            <div className='popup' style={{width:'100%', height:'100%', zIndex:'1350', backgroundColor:'',  backdropFilter: 'blur(25px)'}}  > 
                <Grid container justifyContent="center" alignItems="center" 
                style={{display:'flex',position:'absolute', top:'50px', height:'100%'}}>


                    <div className='popup-inner' style={{backgroundColor:'', maxWidth:'500px', maxHeight:'800px'}}>

                        
                            <ClosePopUp />


                            <div style={{width:'100%', height:'100%', backgroundColor:'', display:'flex', 
                                        flexDirection:'column', justifyContent:'space-between', 
                                        alignItems:'center', overflow:'scroll'}}>



                                <div style={{display:'flex', justifyContent:'center', color:'bisque', fontFamily:'ShortStack', fontSize:'22px', fontWeight:'bold'}}>
                                    RAFFLE
                               </div>







                                <div style={{height:'100%', width:'70%', backgroundColor:'', display:'flex', flexDirection:'column',
                                    justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.05)', 
                                    borderRadius:'20px', marginTop:'10px', paddingBottom:'30px'}}>
                                   

                                    <AssetThumbnail />


                                    


                                    <div style={{display:'flex', color:'bisque', justifyContent:'center', alignItems:'center', fontFamily:'ShortStack', marginTop:'10px'}}>
                                        <BoltIcon style={{color:'#FFA500'}} />
                                            <div style={{color:'bisque'}}>
                                                {raffleAsset.timeStatus}
                                            </div>
                                        <BoltIcon style={{color:'#FFA500'}} />
                                      </div>


                                </div>

                                {raffleAsset.meta ?
                                 <AssetProperties meta={raffleAsset.meta} />
                                :null}




                                <TicketsSold />


                                <RaffleChances />

                            




                                 <div style={{minHeight:'150px', width:'70%', backgroundColor:'', display:'flex', flexDirection:'column',
                                                    justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', marginTop:'10px', paddingTop:'10px', paddingBottom:'10px'}}>

                                            {/* UPDATE */}
                                            <div style={{height:'100%', width:'200px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                        
                                                <AdaEquivalent  pValue={raffleAsset.ticketPrice} />

                                                    {/*<PriceInput />*/}

                                                            <form>

                                                        <div className={searchClassName} style={{marginTop:'0px', marginBottom:'20px', marginLeft: '0px', width:'200px'}}>
 
                                                            <SearchInput>
                                                            <input 
                                                                type="text" 
                                                                placeholder={0} 
                                                                value={numTickets.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}

                                                                onChange={(e) => {
                                                                    let cur_value = e.target.value
                                                                    cur_value = cur_value.replaceAll(",", "")

                                                                    //console.log('CUR VALUE')
                                                                    //console.log(cur_value)
                                                                    setNumTickets(checkTicketNum(cur_value))
                                                                }}

                                                             />


                                                             </SearchInput>


                                                             <ConfirmationNumberIcon  style={{width:'40px', color:'bisque', fontSize:'30px', position:'absolute', zIndex:'3000', right:'10px'}} />

                                                            </div>
                                                            </form>

                                                        </div>



                                                
                                                    <BuyTicket totalAda={totalAda} raffleAddress={raffleAsset.raffleAddress} />
                                                


                                                    {/*
                                                     <div className="raffleBtn" style={{marginBottom:'5px'}}

                                                        onClick={() => {
                                                          
                                                        }}

                                                      >

                                                     <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                            
                                                            <ButtonText style={{fontSize:'20px'}}>
                                                              BUY
                                                            </ButtonText>

                                                      </div>
                                                    </div>



                                                    

                                                    */}

                                                    </div>





                                                {/*
                                                    <div>
                                                        OR BUY MANUALLY
                                                    </div>  
                                                */}






                                                    


                            </div>





                    </div>
                </Grid>
            </div>
        </Grid>
    </div>

    : null}
    </>
   
  )
}

export default RafflePopUp;


