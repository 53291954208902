import styled from 'styled-components';
import {motion} from 'framer-motion';



export const AssetContainer = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--panel-background);
    padding: 1rem 4rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-top: 1rem;
    width: fit-content;
    gap: 2rem;
    @media (max-width: 768px) {
        padding: 1rem;
        flex-direction: column;
        position: absolute;
        left: 5%;
        margin-top: 0;
    }
    @media (max-width: 768px) and (orientation: landscape) {
        display: flex;
        flex-direction: row;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`;


export const ImageContainer = styled.div`
posiiton: relative;
box-sizing: border-box;
background-color: purple;
height: fit-content;
@media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 12%;    
}
@media (max-width: 768px) and (orientation: landscape) {
    position: absolute;
    margin-top: 5rem;
    left: 50%;
    transform: translateX(-50%);

`;





