import {useState} from 'react'

import styled from 'styled-components';

import Connect from './Connect'

import {Provider} from 'components/ClaimContext'

import Cards from './Cards'

import AlertPopUp from './AlertPopUp'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// const Container = styled.div`
// width: 100vw; /* 100% of viewport width */
//   /*  100% of viewport height  */
//   height: 100vh;

//   background: radial-gradient(
//       77.66% 65.96% at 44.86% 48.39%,
//       rgba(199, 0, 167, 0.2) 0%,
//       rgba(0, 0, 0, 0) 100%
//     ),
//     linear-gradient(145deg, #565f86 0%, #1b2126 100%);
// `


const Title = styled.div`
  color: rgba(255, 255, 255, 0.93);
  font-family: 'ShortStack';
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);
`;

const Subtitle = styled.div`
  color: #adffd9;
  font-family: 'ShortStack';
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  word-wrap: break-word;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(174, 245, 184, 0.25);


`;


const NavBar = styled.div`
    position: absolute;
    top: 0px;
    right: 0px; 
    z-index: 10000;
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center;
    align-items: center;
`


const NavBarContents = styled.div`
    width: 90%; 
    height: 90%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-radius: 40px; 
    margin-top: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
    backdrop-filter: blur(6px);
    transition: ease-in-out all 0.3s;  
`



const Container = styled.div`
  width: 100vw; 
  height: 100vh;
  background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ls_sonicvillage_land.png');
  background-size: cover;
  background-repeat: no-repeat;
`;


// const Container = styled.div`
//   width: 100vw; 
//   height: 100vh;
//   background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/IPFS/720x720/Qmek6SHrTPYuSxWZ95NmmaFkrnDxusxEpGUYCismPUXCgL.png');
//   background-size: cover;
//   background-repeat: no-repeat;
// `;


const Backdrop = styled.div`
    backdrop-filter: blur(10px);
`





function HandlesPreProd(){


    const [isWalletLoading, setIsWalletLoading] = useState(false)


    const [mintAsset, setMintAsset] = useState({})



    const [isAlertOpen, setIsAlertOpen] = useState(false)


    const [submittedTx, setSubmittedTx] = useState('')



return(
    <Container>
     <Provider>

        <Backdrop>

        <NavBar>

          <NavBarContents>

            <div style={{width:'200px', height:'90%', backgroundColor:'', marginLeft:'35px'}}>
                <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/OFFICIAL%20Clay%20nation%20logo%20min.png" 
                    style={{width:'180px', height:'auto'}}
                />
            </div>

            <div style={{marginRight:'35px'}}>
                <Connect setIsWalletLoading={setIsWalletLoading} pushDown={false} />
            </div>

         </NavBarContents>

        </NavBar>


        <div style={{position:'relative', top:'0px', width:'100%', 
        height:'100vh', overflow:'scroll', backgroundColor:'',
        display:'flex', justifyContent:'center', alignItems:'center', minHeight:'800px'


        }}>
             
          
            <div style={{width:'80%', display:'flex', alignItems:'center', height:'90%'}}>
            
                <div style={{display:'flex', alignItems:'center', height:'80%', 
                width:'100%',flexDirection:'column'}}>
                   
                    <div style={{marginBottom:'80px', fontSize:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',
                        marginTop:'40px'
                    }}>
                        {/*<h1>CLAY NATION</h1>
                        <h1>ADA HANDLE BACKGROUNDS</h1>*/}
                        {/*<h1>ADA HANDLE BACKGROUNDS</h1>*/}


                    <div
                        style={{

                            // background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)`,
                            // backdropFilter: 'blur(6px)',
                            // borderRadius: '50px',
                            // width: '80%',
                            // height: '200px'
                        }}
                    >
                        <Title>CLAY NATION</Title>
                        <Subtitle>ADA HANDLE BACKGROUNDS</Subtitle>
                    </div>


                    </div>

                    <Cards 
                        setMintAsset={setMintAsset} 
                        isAlertOpen={isAlertOpen} 
                        setIsAlertOpen={setIsAlertOpen} 
                        submittedTx={submittedTx} 
                        setSubmittedTx={setSubmittedTx}
                    />
                    
                </div>

            </div>

    

        </div>


       {/* <div style={{position:'relative', width:'100%', backgroundColor:'', top:'120px', display:'flex', justifyContent:'center', alignItems:'center'}}>

            <Cards />

        </div>*/}


       </Backdrop>


       <div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
       </div>

       <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />


     </Provider>
    </Container>
)
}
export default HandlesPreProd;