import { createGlobalStyle } from "styled-components";
import styled from "styled-components";



const GlobalStyle = createGlobalStyle`

@font-face {
        font-family : ShortStack;
        src: local("ShortStack"),
        url("./Fonts/ShortStack.ttf");
        }
        
        @font-face {
          font-family : ClayFont;
          src: local("NewFont"),
          url("./Fonts/NewFont.otf");
          }

    body {
        margin: 0;
        padding: 0;

        background: radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%);
        background-blend-mode: overlay, normal;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        height: 100svh;
 
        font-family: 'ClayFont', 'ShortStack';
        font-size: 3rem;
        font-weight: 200;
        color: var(--claytext-primary);

        --clayblue-primary: #5870F6;
        --clayblue-secondary: #4FC8EF;
        --claypink: #ED1A70;
        --claypurple-primary: #593895;
        --claypurple-secondary: #7155A4;
        --clayyellow: #FEBD12;
        --claygreen: #ABCD59;   
        --clayorange: #F15822;
        --claytext-primary: rgb(255,255,240);
        --claytext-secondary: rgba(0, 0, 0, 0.42);
        --claybutton-secondary: rgba(0, 0, 0, 0.3);
        --background: rgba(26, 47, 73, 0.7);
        --panel-background: rgba(0, 0, 0, 0.25);



    }
`;

export default GlobalStyle;


export const Logo = styled.img`
width: 10%;
height: auto;
position: absolute;
top: 7%;
left: 50%;
transform: translate(-50%, 0);
z-index: 1;

@media screen and (max-width: 768px) {
    width: 30%;
    top: 5%;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 13%;
    top: 10%;
    left: 10%;
}
`;


const theme = {
    blue: {
        default: 'var(--clayblue-primary)',
        hover: 'var(--clayblue-primary)',
    }, 
    pink: {
        default: 'var(--claypink)',
        hover: "#ED1A70",
    },
    blueInactive: {
        default: 'var(--claybutton-secondary)',
        hover: 'var(--clayblue-primary)',
    },
    pinkInactive: {
        default: 'var(--claybutton-secondary)',
        hover: 'var(--claypink)',
    }
};

export const Button = styled.button`
    background-color: ${props => theme[props.theme].default};
    color: var(--claytext-primary);
    border: none;
    border-radius: 0.7rem;
    font-family: 'ClayFont';
    letter-transform: uppercase;
    padding: 0.7rem 2.7rem;
    font-size: clamp(0.5rem, 1.5vw, 3rem);
    letter-spacing: 0.1rem;
    text-wrap: nowrap;
    text-align: center;
    cursor: pointer;
    font-weight: 200;
    margin: 0 1rem;
    text-shadow: 0px 1.2px 0px rgba(0, 0, 0, 0.5);
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: ${(props) => theme[props.theme].hover};
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);      
    }
    &:disabled {
        background-color: rgba(0, 0, 0, 0.2);
    }
    @media screen and (max-width: 768px) {
        font-size: clamp(0.5rem, 3.5vw, 4rem);
        margin: 0.2rem 1rem;
        border-radius: 0.5rem;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        font-size: clamp(0.5rem, 2vw, 4rem);
    }
    `;

    Button.defaultProps = {
        theme: "blue"
      };

export const ButtonSecondary = styled(Button)`
font-size: clamp(0.5rem, 1vw, 3rem);
padding: 0.7rem 2rem;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 2.8vw, 4rem);
    margin: 0.2rem 1rem; 
} 
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.8vw, 4rem);
}
 `;

 export const ButtonWallet = styled(Button)`
 width: 80%;
 padding: 0.4rem 1rem;
 display: flex;
 align-items: center;
 font-size: clamp(0.5rem, 1.3rem, 3rem);
 border-radius: 1rem;
 font-family: 'ClayFont';
 justify-content: center;
 @media (min-width: 768px) and (max-width: 1023px) {
    border-radius: 0.5rem;
 }
 @media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 0.8rem, 4rem);
    padding: 0.5rem 2rem;
    width: 80%;
    border-radius: 0.5rem; 
    margin: 0 auto;
    text-align: center;
    justify-content: center;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.8vw, 4rem);
    padding: 0.5rem 2rem;
    width: 70%;
   
}
`;
