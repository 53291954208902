
import {useContext, useState, useEffect} from 'react'

import './DotRing.css'
import { MainExperienceContext } from 'components/Context/ExperienceContext'
import MouseContextProvider from "components/Context/MouseContext";
import { MouseContext } from "components/Context/MouseContext";



import SelectAvatar from './AvatarSelection/SelectAvatar'
import SelectAvatar_GC from './AvatarSelection_GC/SelectAvatar'



import PlayerContext from 'components/Context/PlayerContext'

import QualityContext from 'components/Context/QualityContext'

import ExperienceContext from 'components/Context/ExperienceContext'
import {MainClaimContext, Provider} from 'components/ClaimContext'
import InterfacesContext  from 'components/Context/InterfacesContext'
import MultiplayerContext  from 'components/Context/MultiplayerContext'
import WorldContext from 'components/Context/WorldContext'
import ClaimContext  from 'components/Context/ClaimContext'
import ShoppingContext  from 'components/Context/ShoppingContext'
import NPCContext  from 'components/Context/NPCContext'
import ChatContext  from 'components/Context/ChatContext'
import {MainInterfacesContext} from 'components/Context/InterfacesContext'
import {MainContext} from 'components/Context/Context'
import SelectAvatarPlay from 'components/Virtual/AvatarSelectionPlay/SelectAvatarPlay'
import Interfaces from './Interfaces'



function CustomCursors(props){

  const {cursorType, setCursorType} = useContext(MainInterfacesContext)

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [cursorClass, setCursorClass] = useState('custom-cursor')

  const [hideCursor, setHideCurosor] = useState(false)

  const [availableCursors] = useState([

    'custom-cursor'

    ])



  useEffect(() => {

    setCursorType('custom-cursor')

  }, [])



  useEffect(() => {

    if (availableCursors.includes(cursorType)){
      setCursorClass(cursorType)
    }else{
      if (cursorType === ''){
        //setHideCurosor(true)
      }
    }

  },[cursorType])



  useEffect(() => {
    function handleMouseMove(event) {
      setCursorPosition({ x: event.clientX , y: event.clientY});
    }

    function handleMouseEnter() {
      setIsHovered(true);
    }

    function handleMouseLeave() {
      setIsHovered(false);
    }

    function handleClick() {
      
      setIsClicked(true);

      setTimeout(() => {
        setIsClicked(false);
      }, 100)
    }


    function handleDown() {
      
      setIsClicked(true);

    }


    function handleUp() {
      
      setIsClicked(false);
      
    }



    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseenter', handleMouseEnter);
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('mousedown', handleDown);
    window.addEventListener('mouseup', handleUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseenter', handleMouseEnter);
      window.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('mousedown', handleDown);
      window.removeEventListener('mouseup', handleUp);
    };
  }, [isClicked]);



  return(

  <>
  
  {!hideCursor ?
    <div className={`custom-cursor + ${isClicked ? ' clicked' : ''}` } 
      style={{
        left: cursorPosition.x, 
        top: cursorPosition.y 
      }}>  
    
    </div>

    : null}

    </>

  ) 
}




function Virtual(props){


  const {currentApp, setCurrentApp} = useContext(MainContext)

  const {activeScene, setActiveScene} = useContext(MainExperienceContext)

  const [curActiveScene] = useState(activeScene)


  useEffect(() => {

    // console.log('VIRTUAL: ACTIVE SCENE')
    // console.log(curActiveScene)
    //

  },[curActiveScene])



   const DotRing = () => {

    const { cursorType, cursorChangeHandler } = useContext(MouseContext);

    const { x, y } = useMousePosition();
    

    function useMousePosition() {
      const [mousePosition, setMousePosition] = useState({ x: null, y: null });

      useEffect(() => {
        const mouseMoveHandler = (event) => {
          const { clientX, clientY } = event;
          setMousePosition({ x: clientX, y: clientY });
        };
        document.addEventListener("mousemove", mouseMoveHandler);

        return () => {
          document.removeEventListener("mousemove", mouseMoveHandler);
        };
      }, []);

      return mousePosition;
    }

  return (
    <>

      <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={"ring " + cursorType}
      ></div>
      
    </>
  );
}



  return(
    <>


    <MouseContextProvider>

    <DotRing />
      <ExperienceContext>
        <MultiplayerContext>
          <WorldContext>
            <PlayerContext>
              <ShoppingContext>
                <ChatContext>
                  <InterfacesContext>
                    <NPCContext>
                      <QualityContext>
                      <ClaimContext>

                         {/* <CustomCursors />*/}
                          {
                            currentApp === 'avatars3D' ?

                            <>
                              <Provider>
                                <SelectAvatar />
                              </Provider>
                            </>

                            :  currentApp === 'avatars3D_gc' ?

                              <>
                                <Provider>
                                  <SelectAvatar_GC />
                                </Provider>
                              </>

                            :

                            currentApp === 'theStand' ?

                            <>
                            
                              <>
                                <Provider>
                                  <SelectAvatarPlay />
                                  <Interfaces />
                                </Provider>
                              </>

                            </>

                            : 

                            null

                          }

                        </ClaimContext>
                        </QualityContext>

                      </NPCContext>
                    </InterfacesContext>
                  </ChatContext>
                </ShoppingContext>
              </PlayerContext>
            </WorldContext>
          </MultiplayerContext>
        </ExperienceContext>


        </MouseContextProvider>

    </>
  )
}
export default Virtual;