import {useEffect, useState} from 'react'

import './BuildTransaction.css'

import './Spinner.css'

import JSConfetti from 'js-confetti'

import AnimatedCheckmark from './AnimatedCheckmark'
import AnimatedCross from './AnimatedCross'

export default function TransactionProgress(props){

	const {txStatus, statusMessage} = props
	const [dots, setDots] = useState('');

	const jsConfetti = new JSConfetti()

	useEffect(() => {


		if (txStatus === 'success'){
			jsConfetti.addConfetti({
				confettiColors: [
			    '#3BC9DB',
			    '#66D9E8',
			    '#99E9F2',
			    '#FF8787',
			    '#FA5252',
			    '#E03131'
			  ],
				confettiRadius: 6,
		  	confettiNumber: 500,
			})


			const timer = setTimeout(() => {
		    jsConfetti.clearCanvas()
		  }, 3000);
		  return () => clearTimeout(timer);

		}

	},[txStatus])


	function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


 useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

	return(

		<>
			{/*<div className="popup">
		      <div style={{width:'600px'}}><h2 className="popup-title">Building Transaction{dots}</h2></div>
		      <div className="spinner"></div>
		    </div>*/}
		
		<div style={{width:'80%', height:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
			
			<div style={{width:'80%', height:'80%', backgroundColor: 'rgba(233,233,233,0.1)', color: '#82cfe3', borderRadius:'50px'}}>

			{txStatus == 'building' ?
				
				<div>
					<div style={{width:'100%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
						
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
			      <div style={{ textAlign: 'left', fontSize: '35px', minWidth: '300px' }}>
			        Building Transaction
			        	<span style={{ visibility: dots.length > 0 ? 'visible' : 'hidden' }}>.</span>
				        <span style={{ visibility: dots.length > 1 ? 'visible' : 'hidden' }}>.</span>
				        <span style={{ visibility: dots.length > 2 ? 'visible' : 'hidden' }}>.</span>
			      </div>
			    </div>

					</div>

					<div>
						<div id="spinner" className="spinnerCenter"></div>
          </div>
				
				</div>

			: txStatus == 'signing' ?


				<div>
					<div style={{width:'100%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
						
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
			      <div style={{ textAlign: 'left', fontSize: '35px', minWidth: '300px' }}>
			        Signing Transaction
			        	<span style={{ visibility: dots.length > 0 ? 'visible' : 'hidden' }}>.</span>
				        <span style={{ visibility: dots.length > 1 ? 'visible' : 'hidden' }}>.</span>
				        <span style={{ visibility: dots.length > 2 ? 'visible' : 'hidden' }}>.</span>
			      </div>
			    </div>

					</div>

					<div>
						<div id="spinner" className="spinnerCenter"></div>
          </div>
				
				</div>

			: txStatus == 'submitting' ?


				<div>
					<div style={{width:'100%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
						
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
			      <div style={{ textAlign: 'left', fontSize: '35px', minWidth: '300px' }}>
			        Submitting Transaction
			        	<span style={{ visibility: dots.length > 0 ? 'visible' : 'hidden' }}>.</span>
				        <span style={{ visibility: dots.length > 1 ? 'visible' : 'hidden' }}>.</span>
				        <span style={{ visibility: dots.length > 2 ? 'visible' : 'hidden' }}>.</span>
			      </div>
			    </div>

					</div>

					<div>
						<div id="spinner" className="spinnerCenter"></div>
          </div>
				
				</div>

			: txStatus == 'success' ?

			  	<div style={{height:'100%', width:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
				      <div style={{ textAlign: 'center', fontSize: '35px'}}>
				        Transaction Submitted!
						 </div>

						  <div>
				        <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{width:'180px', height:'auto'}} />
				      </div>

				 </div>

			:  txStatus == 'error' ?


						<div style={{height:'100%', width:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
				      <div style={{ textAlign: 'center', fontSize: '35px'}}>
				        Transaction Error!
						 </div>

						 <div style={{marginTop:'10px', fontSize:'25px'}}>
						 	{capitalizeFirstLetter(statusMessage)}
						 </div>

						 <div>
						 		<img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{width:'140px', height:'auto'}} />
						 </div>


				 </div>


			: txStatus == 'fail' ?

					<div style={{height:'100%', width:'100%', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
				      
				      <div style={{ textAlign: 'center', fontSize: '35px'}}>
				        Transaction Submitted!
						 </div>

						 <div style={{marginTop:'10px', fontSize:'25px'}}>
						 	{capitalizeFirstLetter(statusMessage)}
						 </div>

						 <div>
						 		<img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{width:'140px', height:'auto'}} />
						 </div>



				 </div>

			: null}

			</div>
		</div>
		</>

	)
}