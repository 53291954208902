import styled from "styled-components";
import {motion} from 'framer-motion';
import next from '../Images/next.png';
import cursorvoxelised from '../Images/cursor32.png';


export const Heading = styled.h1`
font-size: clamp(2em, 3.3vw, 6rem);
color: var(--clayblue-primary);
font-weight: 200;
letter-spacing: 0.08em;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);

@media screen and (max-width: 1024px) {
    font-size: clamp(2em, 3.3vw, 6rem);
}

@media screen and (max-width: 768px) {
    margin: 0em 0em;
    font-size: clamp(1.8em, 3.3vw, 5rem);
}
@media screen and (max-width: 768px) and (orientation: landscape){
    font-size: clamp(1em, 3vw, 10em);
}

`;

export const HeadingSlideTwo = styled(Heading)`
font-size: clamp(1.8em, 3vw, 6rem);
color: var(--claygreen-primary);
margin: 1em 2em;
@media screen and (max-width: 768px) {
    margin: 0.5em 0em;
}
@media screen and (max-width: 768px) and (orientation: landscape){
    font-size: clamp(1em, 3vw, 10em);
}
`;

export const HeadingSlideThree = styled(Heading)`
font-size: clamp(1.6em, 3vw, 6rem);
color: var(--clayblue-secondary);
margin: 0;
padding: 0;
padding-top: 0.2em;
@media screen and (max-width: 768px) {
    margin: 0.5em 0em;
}
@media screen and (max-width: 768px) and (orientation: landscape){
    font-size: clamp(1em, 2vw, 10em);
    padding: 0;
}
`;

export const HeadingSlideFour = styled(Heading)`
font-size: clamp(1.8em, 3vw, 6rem);
color: var(--claycoral-primary);
padding: 0;
@media screen and (max-width: 768px) {
    margin-top: 1em;
}
@media screen and (max-width: 768px) and (orientation: landscape){
    font-size: clamp(1em, 2.5vw, 10em);
}
`;

export const Text = styled.p`
font-size: clamp(1rem, 1.5vw, 3rem);
color: var(--claytext-white);
font-weight: 100;
letter-spacing: 0.08em;
text-transform: uppercase;
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
@media screen and (max-width: 768px) and (orientation: landscape){
    font-size: clamp(0.5em, 1.7vw, 10em);
}

`;

export const WalletContainer = styled.div`
width: 100%;
margin: 0 auto;
@media screen and (max-width: 768px) {
   padding-bottom: 2em;
`;

export const WalletButton = styled.button`
background: var(--background);
width: 100%;
font-family: PixelFont;
font-weight: 100;
border: none;
outline: 2px solid var(--clayblue-secondary);
padding: 0.7rem 2rem;
letter-spacing: 0.2rem;
margin: 0.5em 0em;
font-size: clamp(0.5rem, 4vw, 2rem);
letter-spacing: 0.2rem;
color: white;
border-radius: 1rem;
cursor: pointer;
transition: 0.2s ease-in-out;
display: flex;
contain: content;
flex-direction: row;
justyfy-content: center;
align-items: center;
&:hover {
    box-shadow: 0px 0px 8px 0px rgba(30, 132, 252, 0.85);
    background: linear-gradient(90deg, #324949 0%, rgba(0, 0, 0, 0.00) 100%);
}
    @media screen and (max-width: 768px) {
        padding: 0.5rem 2rem;
        margin: 1em 0em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5vw, 2rem);
    overflow-y: auto;
    padding: 0.3rem 2rem;
}
`;

export const SlidesWrapper = styled(motion.div)`
border-radius: 50px;
background: var(--background-mobile);
max-width: 80vw;
width: 55vw;
contain: content;
display: flex;
align-items: center;
justify-content: center;
padding: 2rem 4rem;
position: absolute;
margin-top: 10em;
margin-bottom: 2em;
text-align: center;
backdrop-filter: blur(10px);

height: 70vh;

@media screen and (max-width: 768px) {
    margin-bottom: 6em;
    min-height: 50%;
    background: var(--background-mobile);
    height: 60vh;
}

@media screen and (max-width: 768px) and (orientation: landscape) {
    max-height: 60vh;
}
`;


export const Button = styled.button`
background: var(--clayblue-secondary);
font-family: PixelFont;
font-weight: 100;
border: none;
padding: 1rem 2rem;
font-size: clamp(0.5rem, 4vw, 2rem);
text-shadow: 2px 0px 1px rgba(9, 9, 9, 0.37);
letter-spacing: 0.2rem;
text-align: center;
color: white;
border-radius: 20px;
cursor: pointer;
transition: 0.2s ease-in-out;
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 2vw, 2rem);
}
&:hover {
    background: var(--clayblue-primary);
}
`;


export const NextButton = styled.button`
position: absolute;
right: 5%;
bottom: 5%;  
border: none;
width: 5vw;
height: 5vw;
display: inline-block;
background-color: transparent;
background-image: url(${next});
background-size: 100%;
background-repeat: no-repeat;
&:hover {
    cursor: url(${cursorvoxelised}), auto;
}
@media screen and (max-width: 768px) {
    width: 10vw;
    height: 10vw;
    right: 7%;
    bottom: 7%;  
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 5vw;
    height: 5vw;
}

`;
