import Button from '@mui/material/Button';
import { useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import cursorvoxelised from './Images/cursor32.png';


import {MainClaimContext} from 'components/ClaimContext'

//import {MainClayPayContext} from '../../Context/ClayPayContext'

import ConnectHandler from './ConnectHandler'

import CustomCircularProgress from './CustomCircularProgress';

// import cn_meta from './json/cn_db.json'

// import './Balance.css'

import { motion } from "framer-motion";

import { WalletButton } from './Components/WalletDisplay.styled';


import { WalletMobile, WalletMobileBusy } from "./Components/WalletDisplay.styled.jsx";


const theme = {
  anim: 'animate 7s linear infinite',
  op: 0.9
}

const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'

const PopUp = styled.div`
  background:  var(--background);
  border-radius: 32px;
  width: 40%;
  height: 60%;
  padding: 22px;
  position: fixed;
  left:28%;
  top: 22%;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  display: flex;
  z-index: 10;
  color: white;
  backdrop-filter: blur(20px);
  -webkit-animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`


const ConnectContainer = styled.div`

  display: flex;
  justify-content: center; 
  align-items: center;

  @media screen and (max-width: 680px) {
     width: 140px;
     height: 60px;   
  }
  @media screen and (min-width: 680px) {
    width: 180px;
    height: 80px; 
  }
`


// const ConnectButton = styled.button`

//   /* Btn-ConnectWallet-new */

//   box-sizing: border-box;

  
//   width: 329.16px;
//   height: 73px;


//   background: linear-gradient(265.24deg, #EB9191 -10.27%, #5A5AE1 119.18%);
//   border: 1px solid rgba(207, 207, 207, 0.5);
//   box-shadow: 7px 1px 21.1px 4px rgba(218, 139, 155, 0.5), -7px 1px 5.9px 5px rgba(123, 103, 208, 0.5);
//   border-radius: 13.27px;


// `

const ConnectButton = styled.button`
        background-color: var(--background);
        box-shadow: 0px 0px 10px var(--clayblue-secondary);
        
        width: 100%; 
        height: 100%;
        border-radius: 13px;
        outline: 2px solid #3E97FF;    
        border: none;
        
        font-family: ButtonFont;
        
        text-align: center;
        color:  var(--clayblue-primary);
        padding-top: 5px;
        text-shadow: 2px 2px 0px black, 0px 0px 7px var(--clayblue-secondary);
        cursor: pointer;
        
        z-index: 10;
        letter-spacing: 0.1rem;

        &:hover {
          background-color: var(--background-hover);
          box-shadow: 0px 0px 20px var(--clayblue-secondary);
        }


        @media screen and (max-width: 680px) {
          max-width: 125px;
          max-height: 55px; 
          font-size: 1.3rem;
        }
        @media screen and (min-width: 680px) {
          max-width: 155px;
          max-height: 65px;
          font-size: 1.5rem;
        }
`


const ConnectedWallet = styled.div`

/* Rectangle 24 */


width: 202px;
height: 89px;


background-color: #495569;
box-shadow: inset -3px -4px 8.3px #647186, inset 3px 4px 8.8px rgba(0, 0, 0, 0.25);
border-radius: 23px;





font-family: 'OpenSans';
font-style: normal;
font-weight: 300;
font-size: 30px;
line-height: 140%;
/* identical to box height, or 56px */
text-align: center;

color: #F3F7FF;

cursor: pointer;

transition: background 0.3s ease-out;

&:hover{
 
  background: linear-gradient(334.61deg, #A5B5CD -254.74%, #3B4656 51.45%), #404B5C;
  border-radius: 23px;

  box-shadow: none;



}

-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */


`


export function DisplayAccount(props){

  const standardStyle = {
    width:'25px',
    height:'auto', 
    zIndex:'4', 
    marginLeft:'0px',
    marginRight:'10px'
  }

  const walletLogoOffsets = {

    'test':{
    'nami':{
      marginLeft: '-35px',
      marginRight:'30px'
    },
    'eternl':{
      marginLeft: '-45px',
      marginRight:'35px'
    },
    'yoroi':{
      marginLeft: '-45px',
      marginRight:'35px'
    },
    'flint':{
      marginLeft: '-55px',
      marginRight:'35px'
    },
    'gerowallet':{
      marginLeft: '-55px',
      marginRight:'35px'
    },
    'typhoncip30':{
      marginLeft: '-55px',
      marginRight:'35px'
    },
    'vespr':{
      marginLeft: '-55px',
      marginRight:'35px'
    }
  }
  }


  let cur_lovelace = props.lovelace
  let activeWallet = props.activeWallet
  let walletLogos = props.walletLogos


  if (activeWallet in walletLogoOffsets){
    standardStyle.marginLeft = walletLogoOffsets[activeWallet].marginLeft
    standardStyle.marginRight = walletLogoOffsets[activeWallet].marginRight
  }

  cur_lovelace = parseInt(cur_lovelace, 10)

  let ada_balance = cur_lovelace / 1000000
  
  if (ada_balance < 1000){
    ada_balance = (parseInt(cur_lovelace) / 1000000).toFixed(2);
  }else if(ada_balance < 1000000){
    ada_balance = (parseInt(cur_lovelace) / 1000000000).toFixed(2) + 'K';
  }else{
    ada_balance = (parseInt(cur_lovelace) / 1000000000000).toFixed(2) + 'M';
  }

  let walletLogo = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/' + walletLogos[activeWallet]

  return(
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
      <img src={walletLogo} style={standardStyle} /> 
      {ada_balance}
    </div>
  )
}



export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}




function Connect(props) {

   const {walletToEnable, setWalletToEnable,isPopUpOpen, setIsPopUpOpen, isBusy, setIsBusy, updateWallet, setUpdateWallet} = props

   const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)

   const Toggle = () => setIsPopUpOpen(!isPopUpOpen);

   const [smallScreen, setSmallScreen] = useState(false)


   const walletLogos = {
    nami:'nami.svg',
    eternl:'eternl.png',
    yoroi:'yoroi.svg',
    flint:'flint.svg',
    gerowallet:'gerowallet.svg',
    lace: 'lace.svg',
    typhoncip30: 'typhon.svg',
    vespr: 'vespr.png'
  }



  const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
   };
    const handleMouseLeave = () => {
      setIsHover(false);
   };

   const [buttonPopup, setButtonPopup] = useState(false);
   //const Toggle = () => setButtonPopup(!buttonPopup);


   function handleResize(){
      //console.log(window.innerWidth)

      let cur_width = window.innerWidth

      if (cur_width < 680){
         setSmallScreen(true)
      }else{
        setSmallScreen(false)
      }
   }

   useEffect(() => {
     window.addEventListener("resize", handleResize)

     return () => {
      window.removeEventListener("resize", handleResize)
     }
   },[])


   useEffect(() => {

      handleResize()

   },[])


  return (
     <>

      <ConnectContainer>

        <ConnectHandler 
          isBusy={isBusy}
          setIsBusy={setIsBusy}
          updateWallet={updateWallet}
          setUpdateWallet={setUpdateWallet}

          walletToEnable={walletToEnable}
          setWalletToEnable={setWalletToEnable}
        />

        {!smallScreen ?

          <>

          { activeWallet !== '' && activeWallet !== undefined && walletLogos.hasOwnProperty(activeWallet) ?

          

            <ConnectedWallet
              onClick={() => Toggle()}
            >

               <DisplayAccount 
                  lovelace={lovelace}
                  activeWallet={activeWallet}
                  walletLogos={walletLogos}
                />

            </ConnectedWallet>


           :

          <>

            <WalletButton as={motion.button}
            initial={{ opacity: 0  }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => Toggle()}

            style={{minWidth:'180px', minHeight:'75px', display:'flex', justifyContent:'center', alignItems:'center'}}

            >

             {isBusy ?

              <div
                onClick={() => Toggle()}
              >
                <CircularProgress thickness={4} style={true ? {zIndex:'4', color: "#53c8ee"} : {zIndex:'4', color: "#53c8ee", width:'25px', height:'25px'}} />
              </div>
              : activeWallet !== '' && activeWallet !== undefined && walletLogos.hasOwnProperty(activeWallet) ?

                <DisplayAccount 
                  lovelace={lovelace}
                  activeWallet={activeWallet}
                  walletLogos={walletLogos}
                />
                // <div>
                //   <img src={root_url + walletLogos[activeWallet]} style={{width:'25px', height:'auto', padding: '15px'}}/>{lovelace} ADA
                // </div>
              :
                <div>
                  CONNECT 
                 
                </div>

              }

            </WalletButton>

            </>}

          </>



        : 

          <div 
             onClick={() => Toggle()}


              style={{cursor: `url(${cursorvoxelised}), auto`}}
          >

          {isBusy ?
            <div style={{cursor: `url(${cursorvoxelised}), auto`}}>

              <WalletMobileBusy>
              </WalletMobileBusy>

              <div style={{position: 'fixed',top: '3%', right: '3%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'', width:'80px', height:'80px'}}>
                <CircularProgress thickness={4} style={true ? {zIndex:'4', color: "#53c8ee"} : {zIndex:'4', color: "#53c8ee", width:'25px', height:'25px'}} />
              </div>

            </div>
            :

            <div style={{cursor: 'pointer'}}
              onClick={() => Toggle()}


              style={{cursor: `url(${cursorvoxelised}), auto`}}
            >
              <WalletMobile />
            </div>

          }

          </div>

        }



      </ConnectContainer>

    </>

  );
}

export default Connect;
