import { useState, useEffect, useRef, useMemo } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'

import * as Filter from 'bad-words'


import { Text } from '@react-three/drei';


import { TextureLoader } from 'three/src/loaders/TextureLoader'

function DisplayMessage(props){

	const {characterRef, characterDirection} = props
	const {camera} = useThree()

	const messageDisplay = props.messageDisplay

	const messageContent = props.messageContent

	const setMessageContent = props.setMessageContent

	const chatBubble = useLoader(TextureLoader, 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/Chat/SpeechBubble.svg')

	const [bubbleObject, setBubbleObject] = useState()

	const [timeStart, setTimeStart] = useState(-1)

	const formattedMessage = useMemo(() => formatMessage(messageContent), [messageContent])

	//const [filter] = useState(() => new Filter())


	useEffect(() => {

		setTimeStart(-1)

	},[messageContent])


	useFrame((state) => {

		if (timeStart > 0 && state.clock.elapsedTime > timeStart + 5){
			setMessageContent('')
		}


		if (timeStart === -1){
			setTimeStart(state.clock.elapsedTime)
		}

	},[])



	useEffect(() => {

		const planeGeometry = new THREE.PlaneGeometry(0.9, 0.6)
		const planeMaterial = new THREE.MeshBasicMaterial()

		const bubble = new THREE.Mesh(planeGeometry, planeMaterial)

		bubble.material.map = chatBubble

		//bubble.material.depthTest = false;
        bubble.material.depthWrite = true;
		bubble.material.alphaTest = 0.5

		bubble.material.transparent = true;
		bubble.material.needsUpdate = true; 

		setBubbleObject(bubble)

		

	},[])


	function formatMessage(message){



		const filter = new Filter()

		//let shortened = message.replace(/^(.{11}[^\s]*).*/, "$1"); 

		//message = 'hey, we are    going to the moon baby!'

		
	
		const maxBubbleLength = 40;
		const maxLineLength = 10
		message = message.replace(/\s+/g, " ");

		const shortenedMessage = message.slice(0, maxBubbleLength);


		const lines = [];
		let currentLine = "";
		shortenedMessage.split(" ").forEach(word => {
		  if ((currentLine + word).length <= maxLineLength) {
		    currentLine += word + " ";
		  } else {
		    lines.push(currentLine.trim());
		    currentLine = word + " ";
		  }
		});
		lines.push(currentLine.trim());

		let lines_str = ''
		lines.forEach((line) => {
			lines_str += line + '\n'
		})


		// console.log(lines)


		let clean_line;

		try{
			clean_line = filter.clean(lines_str)
		}catch{
			clean_line = lines_str
		}
		


		return clean_line.toUpperCase()

	}


	useFrame(() => {

		try{
			if (bubbleObject){
				messageDisplay.current.lookAt(camera.position);
			}
    	}catch{

    	}

	})



	return(

		<>

		<group position={[0,1.55,0]} ref={messageDisplay}>
			{bubbleObject  ?


			<group position={[0.7,-0.4,0]}>


				<primitive

					object={bubbleObject}

				/>
				

			<Text
				font={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Interfaces/Chat/SingleDay.ttf'}
		        fontSize={0.07}
		        color="#B1F2B7"
		        anchorX="center"
		        anchorY="middle"

		        position={[0.05,0,0.1]}
		      >

		        {formattedMessage}

		      </Text>

		      </group>

		     : null}

      </group>
		
	</>

	)
}

export default DisplayMessage;
