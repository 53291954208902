import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import ClayMap from './Map/ClayMap'
import MainCollection from './Collection/MainCollection'


function CollectionHandler(props) {

  const chosenCollection = props.chosenCollection

  const sellAsset = props.sellAsset 
  const setSellAsset = props.setSellAsset

  const matchesXS = props.matchesXS
  const matchesS = props.matchesS
  const matchesM = props.matchesM
  const matchesL = props.matchesL
  const isSideBarOpen = props.isSideBarOpen

  const currentSlot = props.currentSlot

  const appliedFilters = props.appliedFilters
  const setAppliedFilters = props.setAppliedFilters

  const pitchesFilters = props.pitchesFilters

  const selectedPlot = props.selectedPlot 
  const setSelectedPlot = props.setSelectedPlot



  const sortOrder = props.sortOrder 
  const setSortOrder = props.setSortOrder


  const yourAssetsFilters = props.yourAssetsFilters


  const searchQuery = props.searchQuery
  const setSearchQuery = props.setSearchQuery



  
  return (

    <div>
     
       {

       chosenCollection == 'pitches' ?

        <div>
          <MainCollection
            matchesM={matchesM}
            matchesL={matchesL}
            matchesS={matchesS}
            matchesXS={matchesXS} 

            isSideBarOpen={isSideBarOpen} 
           collection={chosenCollection} 
           currentSlot={currentSlot}

           appliedFilters={appliedFilters}
           setAppliedFilters={setAppliedFilters}

          pitchesFilters={pitchesFilters}

          selectedPlot={selectedPlot}
          setSelectedPlot={setSelectedPlot}

          setSellAsset={setSellAsset} sellAsset={sellAsset}

          sortOrder={sortOrder} setSortOrder={setSortOrder}

          yourAssetsFilters={yourAssetsFilters}


          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          />
       
        </div>

        :

        <div>
          <MainCollection
            matchesM={matchesM}
            matchesL={matchesL}
            matchesS={matchesS} 
            matchesXS={matchesXS} 

            isSideBarOpen={isSideBarOpen} 
           collection={chosenCollection}
           currentSlot={currentSlot}

           appliedFilters={appliedFilters}
           setAppliedFilters={setAppliedFilters}


           setSellAsset={setSellAsset} sellAsset={sellAsset}

           sortOrder={sortOrder} setSortOrder={setSortOrder}

           yourAssetsFilters={yourAssetsFilters}

           searchQuery={searchQuery}
           setSearchQuery={setSearchQuery}
          />
        </div>

        }

    </div>
  
  );
}

export default CollectionHandler;