import { useState, useEffect, useContext } from 'react'
import { css, jsx } from '@emotion/react'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AvatarGroup from '@mui/material/AvatarGroup'

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import cn from '../cn_meta.json'
import gc from '../gc_meta.json'

import {Buffer} from 'buffer'
import AvatarStack from './AvatarStack'
import {MainClaimContext} from './ClaimContext'


const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});

function Activity(props) {

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)

  const clay_info = {'40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728':{collection:'cn',base_name:'ClayNation'}, '94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768':{collection:'gc',base_name:'ClayCharlotte'}}
  

  const setTxsBaking = props.setTxsBaking
  const setTxsCompleted = props.setTxsCompleted
  const setTotalBaked = props.setTotalBaked


  const [accountTransactions, setAccountTransactions] = useState({})
  const [txsAssets, setTxsAssets] = useState([])
  const [activityInfo, setActivityInfo] = useState({})
  const [currentSlot, setCurrentSlot] = useState()
  const [rows, setRows] = useState()

  const [unbakingTxs, setUnbakingTxs] = useState({})




  const columns = [
    { id: 'txid', label: 'Transaction ID', minWidth: 10 },
    { id: 'action', label: 'Action', minWidth: 10 },
    { id: 'asset', label: 'Asset', minWidth: 10 },
    { id: 'price', label: 'Price', minWidth: 10 },
    { id: 'date', label: 'Date', minWidth: 10 },
  ];




  function getAssetImage(asset_id){
    let policy_id = asset_id.slice(0,56)
    let asset_name = asset_id.slice(56,)
    asset_name = Buffer.from(asset_name,'hex').toString('utf-8')
    let cur_collection = clay_info[policy_id].collection
    let cur_base_name = clay_info[policy_id].base_name
    let cur_serial = asset_name.slice(cur_base_name.length,)
    let cur_image = ''
    if (cur_collection == 'cn'){
      cur_image = cn[cur_serial]
    }else if (cur_collection == 'gc'){
      cur_image = gc[cur_serial]
    }
    return cur_image
  }



  function displayProgress(tx_status, tx_progress){
    if (tx_status == '?' && parseInt(tx_progress) == -1){
      return '?'
    }
    
    if (typeof(currentSlot) == 'undefined'){
      return ''
    }

    let slotDiff = parseInt(currentSlot) - parseInt(tx_progress)

    
    
    let baking_prefix = ''
    if (tx_status == 'baking' || tx_status == 'completed' ){
      if (slotDiff >= 1209600){
         if (slotDiff >= (1209600 + 21600) ){
         
          slotDiff = slotDiff - (1209600 + 21600)
          baking_prefix = 'finished '



        }else{
          return 'completing...'
        }
      }      else{
        return String(Math.floor(slotDiff / 86400)) + ' / 14 days' 
      }
    }

    if (tx_status == 'invalid' || tx_status == 'failed' || tx_status == 'processing' || tx_status == 'baking' || tx_status == 'completed' || tx_status == 'unbaked' || tx_status == 'unbaking'){

      if (slotDiff < 60){
        return baking_prefix + '< 1 minute ago'
      }

      else if (slotDiff < 3600){
        if (slotDiff < 120){
          return baking_prefix + String(Math.floor(slotDiff / 60)) + ' minute ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 60)) + ' minutes ago'
        }
      }

      else if (slotDiff < 86400){
        if (slotDiff < 7200){
          return baking_prefix + String(Math.floor(slotDiff / 3600)) + ' hour ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 3600)) + ' hours ago'
        }
      }

      else if (slotDiff < 604800){
        if (slotDiff < 172800){
          return baking_prefix + String(Math.floor(slotDiff / 86400)) + ' day ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 86400)) + ' days ago'
        }
      }

      else if (slotDiff < 2419200){
        if (slotDiff < 1209600){
          return baking_prefix + String(Math.floor(slotDiff / 604800)) + ' week ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 604800)) + ' weeks ago'
        }
      }

      else if (slotDiff < 31536000){
        if (slotDiff < 4838400){
          return baking_prefix + String(Math.floor(slotDiff / 2419200)) + ' month ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 2419200)) + ' months ago'
        }
      }

      else{
        if (slotDiff < 63072000){
         return baking_prefix + String(Math.floor(slotDiff / 31536000)) + ' year ago'
        }else{
          return baking_prefix + String(Math.floor(slotDiff / 31536000)) + ' years ago'
        }
      }
    }

    return tx_progress
  }

  return (
     <ThemeProvider theme={theme}>
     <Paper sx={{  width: '100%', overflow: 'hidden', background:'linear-gradient(0deg, rgba(0, 0, 0, 0.29), rgba(0, 0, 0, 0.29)), linear-gradient(333.44deg, rgba(0, 0, 0, 0.1184) 0.07%, rgba(64, 47, 85, 0.32) 100%)' }}>
      <TableContainer sx={{ maxHeight: 600, zIndex: 1 }}>
        <Table stickyHeader aria-label="sticky table">
           <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={'center'}
                  style={{ minWidth: column.minWidth, backgroundColor: '#182139', borderBottom: '2px solid #2e3142', color:'bisque', fontSize: '15px'}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

          {rows ? rows.map((row) => {
            return(
            <TableRow key={row.txid == "-" ? row.temp_id  : row.txid} >
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col1'+row.txid}> <Link style={{color:'bisque'}} target="_blank" underline="none" href={"https://cardanoscan.io/transaction/" + row.txid}>{row.txid == "-" ? null : row.txid.slice(0,10) +"....."+row.txid.slice(-10,)} </Link></TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col2'+row.txid}>
              {row.status == 'invalid' || row.status == 'failed' || row.status == 'processing' 
              || row.status == 'unbaking' || row.status == 'unbaked' ?  
              <Box component="img" sx={{height:'100%', width:'100px'}} src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/" + row.status[0].toUpperCase() + row.status.slice(1) + ".svg"} /> 
              : unbakingTxs && (row.status == 'completed' || row.status == 'baking') && (row.txid in unbakingTxs)  ? 

               <Box component="img" sx={{height:'100%', width:'100px'}} src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/" + unbakingTxs[row.txid].status + ".svg"} /> 

              :

              row.status == 'baking' ?


                     

                    /*
                    <Unbake txid={row.txid} amount={row.amount} />
                    */

                    null


                  

               : 

               row.status == 'completed' ? 

               <Box component="img" sx={{height:'100%', width:'100px'}} src={"https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/" + row.status[0].toUpperCase() + row.status.slice(1) + ".svg"} /> 

               :null }</TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col3'+row.txid}> 
              {row.amount == '' ? null : row.amount == '-' ? row.amount : <Stack justifyContent="center" alignItems="center" direction="row" spacing={2} > 
              {unbakingTxs && row.txid in unbakingTxs && unbakingTxs[row.txid].status == 'Unbaked' ? 

              <div> 
                <span style={{color:'red', fontSize:'18px'}}> {parseInt(unbakingTxs[row.txid].amount) == 1 ? 0 : unbakingTxs[row.txid].amount} </span> 
                <span>/</span>
                <span> {row.amount} </span> 
              </div>  


              : row.amount} 

              <Avatar sx={{width:'20px',height:'20px', marginLeft:'5px'}} src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png" /> </Stack> } </TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col4'+row.txid}>
                  <AvatarGroup max={6} key={'gr'+row.txid}>
                    {row.assets.map((asset)=>{
                      return(
    
                      <AvatarStack asset={asset} key={row.txid + asset} width={40} height={40} />
                  
                      )
                    })
                    
                    }
                  </AvatarGroup>

              </TableCell>
              <TableCell align={'center'} style={{borderBottom: '2px solid #2e3142', color:'bisque'}} key={'col5'+row.txid}>{displayProgress(row.status, row.progress)}</TableCell>
            </TableRow>)
          })
          :  null

          }
          </TableBody>
        </Table>
      </TableContainer>
     </Paper>
     </ThemeProvider>
  );
}

export default Activity;
