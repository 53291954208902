import {useState, useEffect, useRef, Suspense, createContext, useContext} from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Sparkles, Stars, Float, Environment, Sky, Cloud } from '@react-three/drei'
import * as THREE from 'three'


import Tree from './Tree'
import Snow from './Snow'

import styled from 'styled-components';

import Connect from './Connect'

import Footer from './FooterNew'


import {MainClaimContext} from 'components/ClaimContext'

import ConnectPopUp from './ConnectPopUp'

import {Provider} from 'components/ClaimContext'

import Cards from './Cards'

import AlertPopUp from './AlertPopUp'

import Carousel from './Carousel'

import { Grid } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createGlobalStyle } from "styled-components";


import Cart from './Cart/Cart'

import ClayPayScene from './ClayPay/ClayPayScene'


const Title = styled.div`
  color: rgba(255, 255, 255, 0.93);
  font-family: 'ShortStack';
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);


  @media screen and (max-width: 680px) {
    font-size: 34px; 
  }
   
`;

const Subtitle = styled.div`
  color: #adffd9;
  font-family: 'ShortStack';
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  word-wrap: break-word;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(174, 245, 184, 0.25);

  @media screen and (max-width: 680px) {
    font-size: 30px; 
  }

`;


const NavBar = styled.div`
    position: absolute;
    top: 10px;
    right: 0px; 
    z-index: 1001;
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center;
    align-items: center;
`


const NavBarContents = styled.div`
    width: 90%; 
    height: 90%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-radius: 30px; 
    margin-top: 20px;
    background: transparent;
    transition: ease-in-out all 0.3s;  
`


// const Container = styled.div`
//   width: 100%; 
//   height: 100%;
//   background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ls_sonicvillage_land.png');
//   background-size: cover;
//   background-repeat: no-repeat;

//   overflow: hidden;
// `;


const Container = styled.div`


background: radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%);
background-blend-mode: overlay, normal;
background-size: cover;
background-repeat: no-repeat;
background-position: center;
height: 100vh;
height: 100svh;


width: 100%; 
height: 100%;
overflow: hidden;




`


const Backdrop = styled.div`
    background-color: rgba(10,10,10, 0.2);
    backdrop-filter: blur(15px);
`


const CNLogoContainer = styled.div`
    width:'200px', 
    height:'90%', 
    backgroundColor:'', 
    marginLeft:'35px', 
    display:'flex', 
    alignItems:'center'
`


const CNLogo = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 180px; 
    }
    @media screen and (min-width: 680px) {
        width: 280px; 
    }
    
` 


const TreeImage = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 180px; 
    }
    @media screen and (min-width: 680px) {
        width: 280px; 
    }
    
`


const NavBarUpdated = styled.div`
  width: 90%;
  height: 12%;
  background:  var(--background);
  background-blend-mode: overlay;
  position: fixed;
  top: 7%;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`


function Experience3D(){


    return(
        <>
            <div style={{position:'absolute', top:'0px', width:'100%', height:'100%', display:'flex',
                    justifyContent:'center', alignItems:'center', zIndex:'0'
                }}>
             <Canvas 
                  shadows
                  camera={{ 
                    fov: 50,
                    near: 0.1,
                    far: 1000,
                  }} 

                  style={{cursor:'none', width:'100%', height:'100%',
                          backgroundColor: `rgba(10,10,10, 0.2)`,
                          backdropFilter: `blur(15px)`
                    }}

                  >


                  <ambientLight color={0x404040} intensity={10} />

                  <pointLight shadow-bias={-0.005}  color="#ff9bc9" position={[10, 10, 0]} intensity={0.8} distance={12} />
                  <pointLight shadow-bias={-0.005}  color="#b4d8e7" position={[0, 10, 10]} intensity={0.8} distance={12} />
                  <pointLight shadow-bias={-0.005}  color="#ff9bc9" position={[0, 10, 0]} intensity={0.8} distance={12} />


                  <directionalLight 
                      color={'#fcf2d9'}
                      castShadow
                      position={[1.8, 7, 10.5]} 
                      intensity={1.3}
                      shadow-mapSize={ [2048*2, 2048*2] }
                      shadow-camera-near={ 0.01 }
                      shadow-camera-far={ 20 }

                      shadow-camera-top={ 5 }
                      shadow-camera-right={ 5 }
                      shadow-camera-bottom={ -5 }
                      shadow-camera-left={ -5 }
                      shadow-bias={-0.001} 
                    />


                    <Tree />

                    <Snow />
                  
                </Canvas>

            </div>
        </>
    )
}





function HandlesPreProd(){


   
    const [isWalletLoading, setIsWalletLoading] = useState(false)
    const [mintAsset, setMintAsset] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [submittedTx, setSubmittedTx] = useState('')


    const [buttonPopup, setButtonPopup] = useState(false);





    const GlobalStyle = createGlobalStyle`
        :root {
            --background:  rgba(28, 37, 52, 0.5);

             --background-hover:  rgba(28, 37, 52, 0.9);
              --clayblue-primary: #1E84FC;
              --clayblue-secondary:  #5d96ffcd;
              --claymint-primary: #a0fac8;

              font-family: ButtonFont;

        }
        `
const [isPopUpOpen, setIsPopUpOpen] = useState(false)

const [activeWallet, setActiveWallet] = useState('')

const [stake_address, set_stake_address] = useState('')

const [isBusy, setIsBusy] = useState(false)
const [updateWallet, setUpdateWallet] = useState(false)
const [walletToEnable, setWalletToEnable] = useState('')


const [cartItems, setCartItems] = useState([])

const [clayPayOpen, setClayPayOpen] = useState(false)



const theme = useTheme();

const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
const matchesM = useMediaQuery(theme.breakpoints.up('md'));
const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


const [matchingPairs, setMatchingPairs] = useState()



useEffect(() => {
  const script = document.createElement('script');

  script.src = "../Snow_ThreeJS/script.js";
  script.async = true;

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []);




function formatClothes(clothes){
   if (clothes === '' || clothes === 'None' || clothes === 'Nude'){
      clothes = 'GC Nude'
    }
      
    

    clothes = clothes.replaceAll(' ', '_')


    return clothes
}



async function fetchMarketData(){

  let collection = 'gc'

  let all_queried_assets = []

  let page = 1
  
  let cur_stake_address = stake_address
  //let cur_stake_address = 'stake1u8y7rgpa0phufsnnuka0eue6wxne8rgty86a9nprerzfhwqdf2hgn'
  //let cur_stake_address = 'stake1u8k5lx90tc7030vvnxs3u9yvm7uwpqndca7jxgvexhc40eq5hd4tt'
  //let cur_stake_address = 'stake1u8spkj4az28qnxar0fqhvl2s9htpwytxdkpz07amgx56ues3xnzxh'

  let hasMore = true
  
  while (hasMore){
    let query_url = 'https://claymarket.io/api/filter/listings/?collection=' + collection + '&page=' + String(page)
    query_url += '&stake_address=' + cur_stake_address

    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                    .then(data => data)

    if (asset_listing_info.hasOwnProperty('listings')){
      let cur_assets = asset_listing_info['listings']

      if (cur_assets.length > 0){

        page = page + 1

        for (let cur_asset of cur_assets){
          all_queried_assets.push(cur_asset)
        }

      }else{
        hasMore = false
      }

    }else{
      hasMore = false
    }
  }

 // console.log(asset_listing_info)   

 return all_queried_assets                   
}


function getClothes(eligibleAssets){

  let clothes_list = []

  for (let eligibleAsset of eligibleAssets){
    //console.log(eligibleAsset)
    let cur_meta = eligibleAsset['meta']
    let clothes = cur_meta['clothes']
    clothes = formatClothes(clothes)
    //console.log(clothes)
    clothes_list.push(clothes)
  }

  return clothes_list

}


function getMatchingTrousers(clothes_list){

  let matching_pairs = []

  for (let clothes of clothes_list){
    let matching_pair = clothes + '_Trousers'
    matching_pairs.push(matching_pair)
  }

  return matching_pairs

}



async function determineDiscounts(){

  let fetchedAssets = await fetchMarketData()

  //console.log(fetchedAssets)

  //findMatchingPairs(fetchedAssets)

  let clothes_list = getClothes(fetchedAssets)

  let matching_pairs = getMatchingTrousers(clothes_list)

  console.log(clothes_list)
  console.log(matching_pairs)


  setMatchingPairs(matching_pairs)

}




useEffect(() => {

  console.log('\n\n\nFIND MATCHING PAIRS\n\n\n')

  

  determineDiscounts()


  //let matching_pairs = findMatchingPairs()

  //console.log(matching_pairs)

  console.log('\n\n\nEND\n\n\n')

},[stake_address])




useEffect(() => {

    console.log('matching pairs:')
    console.log(matchingPairs)

},[matchingPairs])



return(


    <>

    
    {/*<Experience3D />*/}



    <Container>
     <Provider>

        <GlobalStyle />

       
        <NavBar>

          <NavBarContents>

            <CNLogoContainer
                style={{cursor:'pointer'}}

                onClick={()=>{
                    console.log('click')
                    window.open('/','_self')
                }}
            >
                <CNLogo src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ClayNationLogo_Final.pdf%20(1).png" />
            </CNLogoContainer>

            <div style={{marginRight:'35px'}}>
                
                <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    set_stake_address={set_stake_address}
                />


                <div style={{cursor:'pointer', display:'flex', width:'100%', height:'100%',
                justifyContent:'end', alignItems:'end', backgroundColor:'blue'}}>
                    <Cart cartItems={cartItems} clayPayOpen={clayPayOpen} setClayPayOpen={setClayPayOpen} />
                </div>

            </div>

         </NavBarContents>

        </NavBar>

       


    <div style={

            matchesS ?

            {position: 'absolute', top:'0px', right:'0px',
            zIndex:'1000', pointerEvents: 'none', height:'100%', width:'70%'}

            :

            {position: 'absolute', top:'0px', left:'0px',
            zIndex:'1000', pointerEvents: 'none', height:'100%', width:'70%'}

            }>
      

    
      
          
    </div>


        <div style={{position:'relative', top:'0px', width:'100%', 
            height:'100vh', overflow:'scroll', backgroundColor:'',
            display: 'flex', justifyContent:'start', alignItems:'center', minHeight:'800px'
        }}>
  

            <div style={{width: matchesS ? '100%' : '100%', display:'flex', 
                        visibility: isPopUpOpen ? 'hidden' : 'visible', alignItems:'center', 
                        height:'90%', backgroundColor:''}}>
            
                <div style={{display:'flex', alignItems:'center', height:'80%', 
                width:'100%',flexDirection:'column'}}>
                   
                    <div style={{marginBottom: '80px', fontSize:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',
                        marginTop:'60px'
                    }}>
                        
                    <div>
                        

                    </div>


                    </div>

                    
                    <Cards 
                        setMintAsset={setMintAsset} 
                        isAlertOpen={isAlertOpen} 
                        setIsAlertOpen={setIsAlertOpen} 
                        submittedTx={submittedTx} 
                        setSubmittedTx={setSubmittedTx}


                        cartItems={cartItems}
                        setCartItems={setCartItems}

                        matchingPairs={matchingPairs}
                    />


                    <div style={{position:'relative', marginTop:'60px', marginBottom:'150px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
                      

                      
                      <div style={{width:'65%', minWidth:'310px', marginTop:'100px', 
                            
                            borderRadius:'50px', color:'white'
                            }}>
                        <Footer />
                      </div>


                    </div> 
                    
                </div>

            </div>

        </div>


        {isPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>

       : null}




       {
        clayPayOpen?

            <ClayPayScene 
                clayPayOpen={clayPayOpen} setClayPayOpen={setClayPayOpen} 
                cartItems={cartItems} setCartItems={setCartItems}
            />

            :

            null

       }


  

    

       <div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
       </div>

       <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />


     </Provider>
    </Container>

    </>
)
}
export default HandlesPreProd;