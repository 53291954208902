import styled from "styled-components";
import { Tooltip } from '@mui/material';


export const WalletsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: 80%;
`;


export const WalletCard = styled.div`

outline: 4px solid var(--panel-background);
border-radius: 0.5rem;
background-color: rgba(0, 0, 0, 0.2);
color: var(--claytext-primary);
text-shadow: 0px 1.2px 0px rgba(0, 0, 0, 0.5);
font-family: 'ClayFont';
font-size: clamp(0.5rem, 1vw, 3rem);
letter-spacing: 0.1rem; 
text-transform: uppercase;
padding-bottom: 3rem;
padding-top: 1rem;
display: flex;
flex-direction: column;
outline-offset: 0.3rem;
cursor: pointer;
transition: 0.2s ease-in-out;
&:hover {
    outline: 4px solid var(--clayblue-primary);
}

@media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
@media screen and (max-width: 768px) { /* mobile */
    padding: 2rem 0rem 2rem 0rem;
}
}

`;

export const WalletIcon = styled.div`
    border-radius: 0.5rem;
    background-color: var(--panel-background);
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3) inset;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 60%;
    padding: 1rem 0rem;
    align-self: center;
    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 0rem 0rem;
      }
    @media screen and (max-width: 768px) {
        padding: 0rem 0rem;
    }
    `;  

export const AddWalletButton = styled.button`
    background-color: transparent;
    color: var(--claytext-primary);
    border: none;
    border-radius: 0.7rem;
    outline: 0.2rem dashed var(--panel-background);
    font-family: 'ClayFont';
    font-size: clamp(0.5rem, 2rem, 3rem);
    text-align: center;
    cursor: pointer;
    font-weight: 200;
    text-shadow: 0px 1.2px 0px rgba(0, 0, 0, 0.5);
    transition: 0.2s ease-in-out;
    padding: 1.5rem 1.8rem;
    position: fixed;
    right: 3%;
    top: 65%;
    transform: translate(0, -50%);
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        outline: 0.2rem dashed var(--clayblue-primary);
        color: var(--clayblue-primary);
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        font-size: clamp(0.5rem, 1.5rem, 3rem);
        padding: 1rem 1.2rem;
      }
    @media screen and (max-width: 768px) {
        padding: 1rem 1.5rem;
        font-size: clamp(0.5rem, 1.5rem, 5rem);
        right: 10%;
        top: 45%;
    }
    @media screen and (max-width: 768px) and (orientation: landscape) {
        font-size: clamp(0.5rem, 1.7rem, 5rem);
        right: 10%;
        top: 55%;
    }
`;

    export const StyledTooltip = styled(props => (
        <Tooltip classes={{ popper: props.className }} {...props} />
      ))`
        & .MuiTooltip-tooltip {
          background-color: var(--clayblue-primary);
          font-family: 'ClayFont';
          font-size: clamp(0.5rem, 1vw, 3rem);
          font-weight: 400;
          padding: 0.3rem 0.6rem;
          border-radius: 0.5rem;
        }
      `;


export const ModalContainer = styled.div`
position: absolute;
top: 68%;
left: 52%;
transform: translate(-50%, -60%);
background: #1A2F49;
outline: 9px solid rgba(0, 0, 0, 0.2);
backdrop-filter: blur(7px);
border-radius: 1rem;
align-items: center;
padding: 3rem 6rem;
contain: content;
@media screen and (max-width: 768px) {
    padding: 2rem 3rem;
    top: 65%;
    left: 50%;
    width: 60%;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    padding: 1rem 1rem;
    top: 60%;
    left: 50%;
}
 
`;


export const WalletButtonsContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
gap: 0.8rem;
justify-items: center;
@media screen and (max-width: 768px) {
    grid-column-gap: 0; 
}
`;


export const ConnectWalletContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
@media screen and (max-width: 768px) {
    width: 100%;
}

`;


export const MessageWrapper = styled.div`
width: fit-content;
height: auto;
background-color: var(--claybutton-secondary);
color: var(--claytext-primary);
font-family: 'ShortStack';
border: none;
border-radius: 0.7em;
box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
contain: content;
padding: 1.5rem;
display: flex;
flex-direction: column;
align-items: center;
font-size: clamp(0.5rem, 0.8vw, 3rem);
letter-spacing: 0.05rem;
opacity: 0.9;
overflow-wrap: break-word;
line-height: 3rem;
@media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0rem auto;
    width: 80%;
    height: auto;
    border-radius: 0.7em;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    padding: 0.5rem;
    width: 70%;
} 
`;


export const H1 = styled.h1`
font-size: clamp(0.5rem, 1rem, 3rem);
letter-spacing: 0.2rem;
text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.3);
font-weight: 100;
text-transform: uppercase;
font-family: 'ClayFont';
@media screen and (max-width: 768px) {
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 0.7rem, 3rem);
}   
`;

export const H2 = styled(H1)`
font-size: clamp(0.5rem, 1rem, 3rem);
font-family: 'ShortStack';
text-shadow: none;
@media (min-width: 768px) and (max-width: 1023px) {
    font-size: clamp(0.5rem, 0.6rem, 3rem);
}
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 0.8rem, 4rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 0.6rem, 4rem);
}
`;

export const H3 = styled(H1)`
font-size: clamp(0.5rem, 2rem, 3rem);
padding-bottom: 1rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 1.3rem, 6rem);
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1.5rem, 6rem);
    padding-bottom: 0rem;
}
`;

export const H4 = styled(H1)`
font-size: clamp(0.5rem, 1.4rem, 3rem);
letter-spacing: 0rem;
color: var(--clayblue-primary);
padding-bottom: 1rem;
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 0.6rem, 6rem);
    text-align: center;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.5rem, 1rem, 6rem);
}
`;


export const P = styled.p`
font-size: clamp(0.5rem, 0.8rem, 3rem);
letter-spacing: 0.05rem;
opacity: 0.9;
overflow-wrap: break-word;
min-width:0;
font-family: 'ShortStack';
@media screen and (max-width: 768px) {
    font-size: clamp(0.5rem, 0.5rem, 3rem);   
    width: 90%; 
    line-height: 1rem;
    padding: 0.2rem;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    font-size: clamp(0.3rem, 0.6rem, 3rem); 
    padding: 0.2rem;
}  
`;



export const TabTag = styled.div`
background-color: var(--clayblue-primary);
color: var(--claytext-primary);
font-family: 'ClayFont';
font-size: clamp(0.5rem, 1rem, 3rem);
letter-spacing: 0.1rem;
text-transform: uppercase;
padding: 0.4rem 0.8rem;
border-radius: 0.5rem;
text-shadow: 0px 1.2px 0px rgba(0, 0, 0, 0.5);
position: absolute;
opacity: 0.9;
top: 1rem;
right: 1rem;
@media screen and (min-width: 768px) {
    display: none;
}

`;   


export const WalletBtnIcon = styled.img`
width: 15%;
margin: 0 1rem;
@media screen and (max-width: 768px) {
    width: 40%;
    margin: 0 0.6rem;
}
@media screen and (max-width: 768px) and (orientation: landscape) {
    width: 20%;
    margin: 0 0.6rem;
}
`;      

export const RemoveButton = styled.button`
    color: var(--claytext-primary);
    background-color: var(--claybutton-secondary);
    border: none;
    border-radius: 0.7rem;
    font-family: 'ClayFont';
    letter-transform: uppercase;
    padding: 0.5rem 2rem;
    font-size: clamp(0.5rem, 0.8rem, 3rem);
    letter-spacing: 0.1rem;
    text-wrap: nowrap;
    text-align: center;
    cursor: pointer;
    font-weight: 200;
    margin: 0 1rem;
    text-shadow: 0px 1.2px 0px rgba(0, 0, 0, 0.5);
    transition: 0.2s ease-in-out;
    &:hover {
        background-color: var(--claypink);
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);      
    }


`;