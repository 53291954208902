import { H1, Container, IMG } from "./ChooseBanner.styled.jsx";
import { Button } from '../../GlobalStyles.jsx';
import banner01 from '../../Images/Banners/banner01.png';
import banner02 from '../../Images/Banners/banner02.png';
import banner03 from '../../Images/Banners/banner03.png';


function ChooseBanner() {
    return (
        <Container>
            <H1>Choose Banner</H1>
            <IMG src={banner01} />
            <IMG src={banner02} />
            <IMG src={banner03} />
            <H1>Or</H1>
            <Button theme="blueInactive">UPLOAD</Button>
        </Container>
    );
}

export default ChooseBanner;