import './PageTitle.css';

function App() {



    return (
       
        <div className='titleContainer2'>
            <div className='title'>
                AVATARS 3D
            </div>
        </div>
       
        );

    }
    
    export default App;