import {useState, useEffect, useRef} from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';

import {Buffer} from 'buffer'

import { ToastContainer, toast } from 'react-toastify';

import Avatar from '@mui/material/Avatar';

import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


import CircularProgress from '@mui/material/CircularProgress';

import styled from 'styled-components'

import '../SearchBar.css'

import CollectionInfo from './CollectionInfo'


import pitches_info from '../../detailed_plot_info.json'





const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`


const policy_ids = {
                      cn:"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
                      gc:"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
                      pitches:"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e",
                      pants:"90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771"
                    }

const collection_base_names = {
      cn:"ClayNation",
      gc:"ClayCharlotte",
      pitches:"ClayNationPitch",
      pants:"ClayPants"
}

const collection_descriptions = {
  cn:`Clay Nation is a multidimensional ecosystem which includes a collection of 10,000 characters with algorithmically assembled, handcrafted clay traits. Each character is a 1-of-a-kind NFT, stored on the Cardano blockchain. 
  
  Join Clay Nation. Celebrate unbounded individuality, bask in positivity and party in peace.`,
  gc:"The Clay Nation halloween collection created in collaboration with US punk rock band, Good Charlotte. Each character is a one-of-a kind NFT, stored on the Cardano blockchain. Full body 3D avatars for each are downloadable, free, on our website - for use in Clay Nation's Clayverse.",
  pitches:"Virtual land pitches in Clay Nation - an expansive festival environment - with 3 pockets of community: Sonic Village, Underworld & Baked Nation. Baked Nation also combines claymation collectibles & new music - all written into the metadata of these limited edition NFTs x Snoop Dogg & Champ Medici.",
  pants:"Virtual Wearables for your Clay Avatar"
}

const collection_names = {

        cn: "Clay Nation",
        gc: "Clay Nation x Good Charlotte",
        pitches: "Pitches at Clay Nation",
        pants: "Clay Wearables"

}


const ada_handle_policy = 'f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a'

const StatsInfo = styled.div`
    min-width: 10%;
    max-width: 80%;
    height: 100%;
    background: rgba(217, 217, 217, 0.18);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(50px);
    border-radius: 18px;
    border-radius: 18px;
    padding-left: 0.4em;
    padding-right: 0.4em;
    display: flex;  
    text-align: center;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor: pointer;

    border: 3px solid rgba(255,255,255,0);

    &:hover{
      min-width: 10%;
      max-width: 80%;
      height: 100%;
      background: rgba(217, 217, 217, 0.18);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(50px);
      border-radius: 18px;
      border-radius: 18px;
      padding-left: 0.4em;
      padding-right: 0.4em;
      display: flex;  
      text-align: center;
      align-items: center;
      justify-content: center;
      position:relative;
      cursor: pointer;

      border: 3px solid rgba(255, 228, 196,0.6)
    }
`

function useOutsideAlerter(ref, setOrderDropdownOpen) {
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOrderDropdownOpen(false)
      }
    }
   
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}




function SortDropDown(props){

  const setOrderDropdownOpen = props.setOrderDropdownOpen
  const orderDropdownOpen = props.orderDropdownOpen

  const orderFilters = props.orderFilters
  const sortOrder = props.sortOrder

  const setSortOrder = props.setSortOrder

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOrderDropdownOpen);


  return(

   <div ref={orderDropdownOpen ? wrapperRef : null} style={{width:'260px', cursor:'pointer',height:'58px', backgroundColor: true ? '#e7f0ff' : '#f6f7f6', 
    backdropFilter:'blur(50px)', display:'flex', alignItems:'center', justifyContent:'center', marginLeft:'0px',
    borderRadius:'20px', zIndex:'100'
    }}

   onClick={()=>{
      setOrderDropdownOpen(!orderDropdownOpen)
    }}
  
  >


  <div style={{marginRight:'15px', marginLeft:'10px',display:'flex', width:'190px',alignItems:'center'}}>

    {orderFilters[sortOrder]} 

    {sortOrder == 'price_asc' ? 

      <ArrowUpwardIcon />

    : sortOrder == 'price_desc' ?

      <ArrowDownwardIcon />

    :null}
  </div>


  <div style={{height:'100%', contain:'content',display:'flex', flexDirection:'column', width:'20px', 
               marginLeft:'-10px',backgroundColor:'', alignItems:'center', justifyContent:'center'}}>
   <div style={{marginTop:'5px'}}>
      <ArrowDropUpIcon />
    </div>
    <div style={{marginTop:'-20px'}}>
      <ArrowDropDownIcon />
    </div>
  </div>




  {
  orderDropdownOpen ? 

  <div style={{position:'absolute', marginTop:'155px', backgroundColor:'', width:'100%', display:'flex', justifyContent:'center'}}>

      <div style={{backgroundColor: true ? '#e7f0ff' : '#f6f7f6', backdropFilter:'blur(50px)',width:'260px', borderRadius:'10px'}}>
        {
          Object.keys(orderFilters).map((key)=>{
             return( 
              <div key={key}
                style={{marginTop:'8px', marginBottom:'8px', fontWeight: key == sortOrder ? 'bold' : ''}}
                onClick={()=>{
                  //setOrderOption(key)

                  setSortOrder(key)

                  

                }}
              >{orderFilters[key]}</div>
              )
          })
        }
      </div>
    
  </div>

  : null
}

</div>

  )
}






function Banner(props) {

  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'5px',width:'42px', height:'42px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  const collection = props.collection
  const cn_image_url = "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay%20Nation_icon.png"
  const gc_image_url = "https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/GC_Tumbnail_2d.jpg"
  const pitches_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/pitches.jpg'

  const pants_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/trousers_pfp.jpg'

  const orderFilters = {
      price_asc:'Price: ascending',
      price_desc:'Price: descending',
      most_recent:'Most recent'
    }

  //const [orderOption, setOrderOption] = useState("price_asc")
  const matchesS = props.matchesS
  const matchesM = props.matchesM
  const matchesL = props.matchesL
  const isSideBarOpen = props.isSideBarOpen

  const appliedFilters = props.appliedFilters
  const setAppliedFilters = props.setAppliedFilters

  const sortOrder = props.sortOrder
  const setSortOrder = props.setSortOrder

  const [shortDesc, setShortDesc] = useState(true)
  const [searchClassName, setSearchClassName] = useState('searchField')
  const [searchVal, setSearchVal] = useState('')
  const [searchPlaceholder, setSearchPlaceholder] = useState(collection=='pitches' ? 'Search by (x,y), #, addr or $'  : 'Search by #, address or $handle')

  const [orderDropdownOpen, setOrderDropdownOpen] = useState(false)  
  const [isSearching, setIsSearching] = useState(false)
  
  
  const searchQuery = props.searchQuery
  const setSearchQuery = props.setSearchQuery

  const pitchesFound = props.pitchesFound
  const setPitchesFound = props.setPitchesFound



  function trim(pString){
    return pString.slice(0,6) + '...' + pString.slice(-6,)
  }

  function trimDescription(pString){
    return pString.slice(0,144) + '...'
  }


function addressToStake(address){
    let temp = serLib.Address.from_bech32(address)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()

    return cur_stake_address
}



function assetFromSerial(pValue){

  if (collection in policy_ids && collection in collection_base_names){
    let cur_policy_id = policy_ids[collection]
    let base_name = collection_base_names[collection]

    let cur_asset_id 
    if (collection === 'pants'){
      cur_asset_id = cur_policy_id + '000de140' + Buffer.from(base_name + String(pValue), 'utf8').toString('hex')
    }else{
      cur_asset_id = cur_policy_id + Buffer.from(base_name + String(pValue), 'utf8').toString('hex')
    }
    


    

    return cur_asset_id

  }else{
    return ''
  }

}


function assetFromCoordinates(pValue){

}


  async function prepareSearch(pValue){

    

    //search by address
    if (pValue.startsWith('addr1')){
      pValue = pValue.toLowerCase()
      //console.log('Addr1')
      //console.log(pValue)

      try{
        let temp_stake = addressToStake(pValue)
        //console.log(temp_stake)

        //FETCH RECORDS HERE
        setSearchQuery('&stake_address='+String(temp_stake))
        return
      }catch(err) {
        notifyError(err)
      }      

    }

    //search by stake
    else if (pValue.startsWith('stake1')){
      pValue = pValue.toLowerCase()
      //console.log('Stake1')
      //console.log(pValue)

      //FETCH RECORDS HERE
      setSearchQuery('&stake_address='+String(pValue))

      return

    }

    //search by handle
    else if (pValue.startsWith('$')){

      //console.log('$handle')
      //console.log(pValue)


      let handle = pValue.slice(1,)
      let hex_handle = Buffer.from(handle).toString('hex')

      let handle_asset_id = String(ada_handle_policy) + String(hex_handle)

      let handle_owner = await fetch('https://clayapi.com/filtering/asset/owner/'+handle_asset_id + '/')
      .then((response) => response.json())
      .then((data) => data);

      if ('error' in handle_owner){


        handle_asset_id = String('f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a') + String('000de140') + String(hex_handle)

        handle_owner = await fetch('https://clayapi.com/filtering/asset/owner/'+handle_asset_id + '/')
        .then((response) => response.json())
        .then((data) => data);



        if ('error' in handle_owner){
          notifyError('Failed to fetch $handle owner')
        }else{

          pValue = pValue.toLowerCase()
          handle_owner = handle_owner['address']
            //console.log(handle_owner)
            let handle_account;
            try{
              handle_account = addressToStake(handle_owner)
            }catch{
              notifyError('Invalid address')
              return
            }


            //console.log(handle_account)
            //FETCH RECORDS HERE


            setSearchQuery("&stake_address="+String(handle_account))

            return 

        }



      }else{
        pValue = pValue.toLowerCase()
        handle_owner = handle_owner['address']
          //console.log(handle_owner)
          let handle_account;
          try{
            handle_account = addressToStake(handle_owner)
          }catch{
            notifyError('Invalid address')
            return
          }


          //console.log(handle_account)
          //FETCH RECORDS HERE


          setSearchQuery("&stake_address="+String(handle_account))

          return 



      }

    }


    if (collection == 'pitches'){

      //coordinates
      if (pValue.includes('(') || 
          pValue.includes(')') ||
          pValue.includes(',') ||
          pValue.includes(' ') ){

        let temp = pValue.split('(')

        if (temp.length == 2){
          temp = temp[1]
        }else{
          temp = temp[0]
        }

        temp = temp.split(')')[0]
        temp = temp.split(',')

        if (temp.length == 1){
          temp = temp[0].split(' ')
        }


        let x = parseInt(temp[0],10)
        let y = parseInt(temp[1],10)

        //console.log(x)
        //console.log(y)

        if (isNaN(x) || isNaN(y)){
          notifyError('Incorrect Format')
          return 
        }else{
          //FETCH RECORDS HERE
          let cur_pitch_coord = '('+String(x) + ',' + String(y)+')'

          if (cur_pitch_coord in pitches_info){
            let cur_pitch_serial = pitches_info[cur_pitch_coord]['serial']

            let cur_pitch_asset_id = assetFromSerial(cur_pitch_serial)
            
            setSearchQuery('&asset_id='+String(cur_pitch_asset_id))

            return 

          }else{
            notifyError("Pitch with these coordinates does not exist")
          }
          
          //setSearchQuery('('+String(x) + ',' + String(y)+')')
          //return 
        }

         

      }
      //serial 
      else{
        pValue = pValue.toLowerCase()
        let serial = pValue.split('#')

        if (serial.length == 2){
          serial = serial[1]
        }else{
          serial = serial[0]
        }



        serial = parseInt(serial, 10)
        //console.log(serial)

        if (isNaN(serial)){
          notifyError('Incorrect Format')
          return
        }else{
          //FETCH RECORDS HERE

          
            if (serial < 0 || serial > 32027){
              notifyError('No such asset exists')
              return
            }

          setSearchQuery('&asset_id='+String(assetFromSerial(String(serial))))
          return
        }

        

      }

    //serial 
    }else{
      pValue = pValue.toLowerCase()
      let serial = pValue.split('#')

      if (serial.length == 2){
        serial = serial[1]
      }else{
        serial = serial[0]
      }

      serial = parseInt(serial, 10)
      //console.log(serial)

      
      if(isNaN(serial)){
          notifyError('Incorrect Format') 
          return
      }else{


        if(collection == 'gc'){
            if (serial < 0 || serial > 9427){
              notifyError('No such asset exists')
              return
            }

          }else{
            if (serial < 0 || serial > 10000){
              notifyError('No such asset exists')
              return
          }
        }

        //FETCH RECORDS HERE
        setSearchQuery('&asset_id='+String(assetFromSerial(String(serial))))
        return
      }
    
    }


  } 



  async function makeSearch(pValue){
    setIsSearching(true)

    await prepareSearch(pValue)

    setIsSearching(false)
  }



function displayLongValue(pValue){

  pValue = pValue.split('=')

  if (pValue.length > 1){
    pValue = pValue[1]
  }else{
    return
  }
  
  if (pValue.length > 20){
    return pValue.slice(0,10) + '...' + pValue.slice(-10,)
  }else{
    return pValue
  }
  
}




  
  return (

   <div style={{position:'relative', top:'0px', left:'0px', width:'100%', fontFamily:'ShortStack'}}>

      <img src={collection !== 'cn' ? 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'+collection.toUpperCase() + '_BANNER.png' : 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CN_BANNER.jpg'} style={{marginTop:'0px', objectFit:'cover',width:'100vw', height:'300px'}} />

    <div>




<div style={{width:'100vw', backgroundColor:''}}>

     
   
    <div style={!matchesS || !matchesM ? {display:'flex', width:'100%', justifyContent:'center', marginTop:'-150px'} 
                : !matchesL && isSideBarOpen ? {marginLeft:'25vw' ,display:'flex', justifyContent:'center', marginTop:'-150px'} 
                : !matchesL ? {marginLeft:'8vw' ,display:'flex', marginTop:'-150px'} 
                : {marginLeft:'30vw', display:'flex', marginTop:'-150px'}
    }>

      <div>
        <Avatar
          alt="CollectionIcon"
          src={collection == 'cn' ? cn_image_url : collection == 'gc' ? gc_image_url : collection == 'pants' ? pants_image_url : collection == 'pitches' ? pitches_image_url: 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png'}
          sx={{ width: 240, height: 240 }}

           style={{
               border: '10px solid transparent'
            }}
        />
       
        <div style={{position:'absolute', marginTop:'-267px', marginLeft:'-7px'}}>
          <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/gradient_circle_border.svg' style={{width:'275px'}}/>
        </div>

        </div>
      
      </div>


      </div>
    
     
    
 

    <div style={
       !matchesS || !matchesM ?
        {marginTop:'20px'}

        : !matchesL && isSideBarOpen ?

          {marginLeft:'28vw', marginTop:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}

        : !matchesL && !isSideBarOpen ?
         {marginTop:'-80px', display:'flex', flexDirection:'column', justifyContent:'center' ,alignItems:'center'}

         : {marginTop:'-80px', marginLeft:'300px', display:'flex',flexDirection:'column', justifyContent:'center' ,alignItems:'center'}
        

      }
    >


     <CollectionInfo 
        collection_names={collection_names}
        collection_descriptions={collection_descriptions}
        policy_ids={policy_ids}
        collection={collection}
      />

        

<div style={{marginTop:'40px', marginBottom:'40px', display:'flex', flexDirection: matchesM ? 'row' : 'column', justifyContent: 'center', alignItems: matchesM ? 'start' : 'center', 
            
            marginLeft: isSideBarOpen && matchesM ? '0px' : isSideBarOpen && matchesS ? '40vw' : '0px'}}>
  
  {searchQuery ?


   <div style={{marginBottom:'19px', width:'370px', backgroundColor:'', display:'flex', justifyContent:'center'}}>

   <div className="stakingAddress" 
   style={{height:'50px', width:'100%', backgroundColor:'', marginLeft:'-60px'}}> 

   <span className="tokensNumber" style={{color:'#e7e6d2'}}>
     
   </span><span className="tokensText" style={{color:'#e7e6d2', inlineSize:'300px', overflowWrap:'break-word'}}>{displayLongValue(searchQuery)}</span> 

   <IconButton onClick={()=>{
    setSearchQuery('')
    setSearchVal('')
    //setSearchedStakeAddr('')
   }}>
    <CloseIcon style={{color:'bisque'}} />
   </IconButton>

   </div>
</div>

:

  <div className={matchesS ? searchClassName : searchClassName.split(' ')[0] + 'Small' + ' ' + searchClassName.split(' ')[1]} style={{marginTop:'0px', marginBottom:'20px', marginLeft: matchesS ? '0px' :'3vw', width:'340px', marginRight:'30px'}}>
    <SearchInput marginLeft={searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? '0px' : '40px'} style={{width:'340px', overflow:'auto'}}>
        <input
          id="searching"
          type="text"
          value={searchVal}
          placeholder={searchPlaceholder}

          onChange={(event) => { 
            
            let cur_value = event.target.value
            setSearchVal(cur_value)

            if (cur_value == ''){
              //do nothing
            }else{
               setSearchClassName('searchFieldPressed')
             }

          }}

          onKeyPress={(event) => {

              

              if (event.key == 'Enter'){
                 makeSearch(searchVal)
              }

            }}

   
          onFocus={() => {

          if (searchVal == ''){

              setSearchClassName('searchField focussed')

          }else{

              setSearchClassName('searchFieldPressed')
          
          }


          }}


          onBlur={() => {

            if (searchVal == ''){

               setSearchClassName('searchField')

            }else{

              setSearchClassName('searchFieldPressed')

            }

          }}
       />

</SearchInput>

<img className="searchicon"  src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/search.png" style={{animation: searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? 'swipeRight 0.4s ease-out forwards' : 'none' }} />

{!isSearching ?
<button onClick={() => {

  makeSearch(searchVal)
 
}} 





style={{position:'relative', zIndex:'250', cursor:'pointer', height:'55px', width:'55px', backgroundColor:'transparent', border:'none', borderRadius:'17px', display: searchClassName == 'searchField focussed' || searchClassName == 'searchFieldPressed' ? 'block' : 'none'}}>
  <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/search.png" style={{height:'50px', width:'auto', marginTop: '2px', animation:'btnAppear 0.4s ease-out forwards'}} />
</button>

: <div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center',height:'50px', width:'60px',zIndex:'250'}}> <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} /> </div>}

</div>

}


{collection != 'pitches' ?


  <div style={{zIndex:'300'}}>

  

    <SortDropDown 

      setOrderDropdownOpen={setOrderDropdownOpen}
      orderDropdownOpen={orderDropdownOpen}
      orderFilters={orderFilters}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}

      />

</div>

:



<div style={{height:'100%', width:'200px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:''}}>
  

  <div>
  <div style={{backgroundColor: true ? '#e7f0ff' : '#f6f7f6', backdropFilter:'blur(50px)',width:'100%', 
              height:'55px',borderRadius:'10px', display:'flex', justifyContent:'center', alignItems:'center'}}>
    PITCHES FOUND: {pitchesFound}
  </div>


  <div style={{color:'bisque', fontSize:('11px'), fontStyle: 'italic', marginTop:'5px'}}>
    *shows individual listings only
  </div>

  </div>

</div>}


</div>

  
    </div>






    </div>
   </div>
  
  );
}

export default Banner;