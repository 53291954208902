import { io } from 'socket.io-client';


// "undefined" means the URL will be computed from the `window.location` object
//const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3008';

//const URL = process.env.NODE_ENV === 'production' ? 'http://claynation.live:8080' : 'http://claynation.live:8080';

const URL = 'https://claynation.live'

export const socket = io(URL);



