import './Loading.css'

function Loading(props) {

  const isSearching = props.isSearching
  const isFetching = props.isFetching

  const matchesS = props.matchesS

  return (
    <div>
    {isSearching ? 


    <div>
      <div className={matchesS ? "loading-txt-plain" : "loading-txt-plain-small"} style={{marginTop:'100px'}}>Searching...</div>
      <div className={matchesS ? "loading-spinner-plain" : "loading-spinner-plain-small"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    :

    isFetching ?

    <div>
      <div className={matchesS ? "loading-txt-plain" : "loading-txt-plain-small"} style={{marginTop:'100px'}}>Updating...</div>
      <div className={matchesS ? "loading-spinner-plain" : "loading-spinner-plain-small"}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>

    :


   

    <div>
      <div className={matchesS ? "loading-txt" : "loading-txt-small" } style={{marginTop:'100px'}}>LOADING</div>
      <div className={matchesS ? "loading-spinner" : "loading-spinner-small" }><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>


  }

    



  </div>


  );
}

export default Loading;