import { useState, useEffect, useRef } from 'react';

import './VoiceType.css'
// import './style.css';
// import mic from './microphone.png'
// import mouth from './mouthclay.png'

function useOutsideAlerter(ref, setOrderDropdownOpen) {
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOrderDropdownOpen(false)
      }
    }
   
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const Voice = (props) => {

    const {voiceType, setVoiceType} = props

    const [expand, setExpand] = useState(false);

    const toggleExpand = () => setExpand(prevExpand => !prevExpand);

    // console.log(expand);
    const [isHovering, setIsHovering] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setExpand);

    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    return (
     <> 

     <div ref={expand ? wrapperRef : null}>

        <button 
          className='voicebtn'
          onMouseOver={handleMouseOver} 
          onMouseOut={handleMouseOut} 
          onClick={toggleExpand}
        >
          
          <img src={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/voiceicon.png'} className='micimg' /> 

        </button>


        {isHovering &&
          <div className='tooltipVoice'>SELECT VOICE</div>
        }

        {expand  && 
          <div className='voiceOptions'>
            <button id={voiceType == 'type1' ? 'selectedVoiceOption' : 'voiceOption'}
              onClick={() => {
                setVoiceType('type1')
              }}
            >1</button>
            <button id={voiceType == 'type2' ? 'selectedVoiceOption' : 'voiceOption'}
              onClick={() => {
                setVoiceType('type2')
              }}
            > 2</button>
          </div>
        }

     </div>


    {/*<div className='voiceSelectionTool'>        
      <button className='voicebtn' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={toggleExpand}><img src={mouth} className='micimg' /> </button>
      {isHovering &&
      <div className='tooltipVoice'>SELECT VOICE</div>
      }
      {expand && 
      <div className='voiceOptions'>
      <button id='voiceOption'>1</button>
      <button id='voiceOption'> 2</button>
    </div>
     }
     
    </div>*/}

    </>

    );
};

export default Voice;