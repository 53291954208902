import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import AnimationLoader from './AnimationLoader'

function ClayAnimations(props){

  const mixer = props.mixer
  const setMixer = props.setMixer
 

  const animationDict = props.animationDict
  const setAnimationDict = props.setAnimationDict

  const animationPaths = props.animationPaths


 /*
 const animations = [

    {
      name: "Idle",
      url: "pairs/animations/glb/Idle.glb"
    },

    {
      name: "Walk",
      url: "pairs/animations/glb/Walking.glb"
    },

    {
      name: "Run",
      url: "pairs/animations/glb/Running.glb"
    },

    {
      name: "Jump",
      url: "pairs/animations/glb/Jumping.glb"
    },

    {
      name: "Fall",
      url: "pairs/animations/glb/Falling.glb"
    }
   
  ] 
  */

return(
  
   <>

    {typeof(animationPaths) !== 'undefined' ?
      animationPaths.map((animation, i) => {
        return(
         
            <AnimationLoader key={i}  

              animation={animation}
              
              animationDict={animationDict} 
              setAnimationDict={setAnimationDict}
              mixer={mixer}
              setMixer={setMixer}
            />
        
        )
      }) 
    :null}

   </>
  
  )
}

export default ClayAnimations;