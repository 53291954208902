import {useState, useEffect} from 'react'

import styled from 'styled-components';

import Connect from './Connect'

import Footer from './Footer'


import ConnectPopUp from './ConnectPopUp'

import {Provider} from 'components/ClaimContext'

import Cards from './Cards'

import AlertPopUp from './AlertPopUp'

import Carousel from './Carousel'

import { Grid } from '@mui/material';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createGlobalStyle } from "styled-components";


const Title = styled.div`
  color: rgba(255, 255, 255, 0.93);
  font-family: 'ShortStack';
  font-size: 44px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.43);


  @media screen and (max-width: 680px) {
    font-size: 34px; 
  }
   
`;

const Subtitle = styled.div`
  color: #adffd9;
  font-family: 'ShortStack';
  font-size: 40px;
  text-align: center;
  letter-spacing: 1px;
  word-wrap: break-word;

  font-weight: bold;

  text-shadow: 0px 0px 4px rgba(174, 245, 184, 0.25);

  @media screen and (max-width: 680px) {
    font-size: 30px; 
  }

`;


const NavBar = styled.div`
    position: absolute;
    top: 10px;
    right: 0px; 
    z-index: 10000;
    width: 100%; 
    height: 100px; 
    display: flex; 
    justify-content: center;
    align-items: center;
`


const NavBarContents = styled.div`
    width: 90%; 
    height: 90%; 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    border-radius: 30px; 
    margin-top: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);
    backdrop-filter: blur(6px);
    transition: ease-in-out all 0.3s;  
`


const Container = styled.div`
  width: 100%; 
  height: 100%;
  background-image: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/ls_sonicvillage_land.png');
  background-size: cover;
  background-repeat: no-repeat;

  overflow: hidden;
`;


const Backdrop = styled.div`
    background-color: rgba(10,10,10, 0.2);
    backdrop-filter: blur(15px);
`


const CNLogoContainer = styled.div`
    width:'200px', 
    height:'90%', 
    backgroundColor:'', 
    marginLeft:'35px', 
    display:'flex', 
    alignItems:'center'
`


const CNLogo = styled.img`

    height: auto;   

    @media screen and (max-width: 680px) {
        width: 140px; 
    }
    @media screen and (min-width: 680px) {
        width: 180px; 
    }
    
` 


const NavBarUpdated = styled.div`
  width: 90%;
  height: 12%;
  background:  var(--background);
  background-blend-mode: overlay;
  position: fixed;
  top: 7%;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
`



function HandlesPreProd(){

    const [isWalletLoading, setIsWalletLoading] = useState(false)
    const [mintAsset, setMintAsset] = useState({})
    const [isAlertOpen, setIsAlertOpen] = useState(false)
    const [submittedTx, setSubmittedTx] = useState('')


    const [buttonPopup, setButtonPopup] = useState(false);


    const GlobalStyle = createGlobalStyle`
        :root {
            --background:  rgba(28, 37, 52, 0.5);

             --background-hover:  rgba(28, 37, 52, 0.9);
              --clayblue-primary: #1E84FC;
              --clayblue-secondary:  #5d96ffcd;
              --claymint-primary: #a0fac8;

              font-family: ButtonFont;

        }
        `
const [isPopUpOpen, setIsPopUpOpen] = useState(false)

const [activeWallet, setActiveWallet] = useState('')

const [isBusy, setIsBusy] = useState(false)
const [updateWallet, setUpdateWallet] = useState(false)
const [walletToEnable, setWalletToEnable] = useState('')




return(

    <Container>
     <Provider>

        <GlobalStyle />

        <Backdrop>

        
        <NavBar>

          <NavBarContents>

            <CNLogoContainer>
                <CNLogo src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/OFFICIAL%20Clay%20nation%20logo%20min.png" />
            </CNLogoContainer>

            <div style={{marginRight:'35px'}}>
                <Connect 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    isBusy={isBusy}
                    setIsBusy={setIsBusy}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}
                />
            </div>

         </NavBarContents>

        </NavBar>

        

        <div style={{position:'relative', top:'0px', width:'100%', 
            height:'100vh', overflow:'scroll', backgroundColor:'',
            display: 'flex', justifyContent:'center', alignItems:'center', minHeight:'800px'
        }}>
             

            <div style={{width:'80%', display:'flex', visibility: isPopUpOpen ? 'hidden' : 'visible', alignItems:'center', height:'90%'}}>
            
                <div style={{display:'flex', alignItems:'center', height:'80%', 
                width:'100%',flexDirection:'column'}}>
                   
                    <div style={{marginBottom: '80px', fontSize:'20px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',
                        marginTop:'60px'
                    }}>
                        
                    <div
                        style={{}}
                    >
                        <Title>CLAY NATION</Title>
                        <Subtitle>HANDLE BACKGROUNDS</Subtitle>

                    </div>


                    </div>



                    {/*<Carousel />*/}

                    <Grid container spacing={3} 
                          alignItems="center"
                          justifyContent="center" style={{backgroundColor:'', marginLeft:'0px'}}
                    >

                    <Cards 
                        setMintAsset={setMintAsset} 
                        isAlertOpen={isAlertOpen} 
                        setIsAlertOpen={setIsAlertOpen} 
                        submittedTx={submittedTx} 
                        setSubmittedTx={setSubmittedTx}
                    />


                    <div style={{position:'relative', marginTop:'60px', marginBottom:'50px', width:'100%', 
                                 height:'200px', backgroundColor:'', display:'flex',
                                 justifyContent:'center', alignItems:'center', marginLeft:'0px'
                                }}>
                      <div style={{width:'65%', minWidth:'310px', marginTop:'20px'}}>
                        <Footer />
                      </div>
                    </div>


                    </Grid>
                    
                </div>

            </div>



        </div>


        {isPopUpOpen ?

            <div style={{position:'absolute', top:'0px', left:'0px', width:'100%', 
               height:'100%', backgroundColor:'', display:'flex', 
               justifyContent:'center', alignItems:'center', zIndex: '2000', overflow:'scroll'
           }}>
            
            
               <ConnectPopUp 
                    setIsWalletLoading={setIsWalletLoading} 
                    pushDown={false} 

                    isPopUpOpen={isPopUpOpen}
                    setIsPopUpOpen={setIsPopUpOpen}

                    activeWallet={activeWallet}
                    setActiveWallet={setActiveWallet}


                    isBusy={isBusy} 
                    setIsBusy={setIsBusy}

                    walletToEnable={walletToEnable}
                    setWalletToEnable={setWalletToEnable}

                    updateWallet={updateWallet} 
                    setUpdateWallet={setUpdateWallet}
                />

           </div>

       : null}

        
      {/* <div style={{position:'relative', bottom:'20px', width:'100%', height:'200px', backgroundColor:'blue'}}>
        <Footer />
       </div>*/}


       </Backdrop>





      


      {/* <div style={{position:'absolute'}}>
         <div>

         </div>
       </div>*/}


        


       <div style={{textAlign:'center'}}>
        <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />
       </div>

       <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque", fontFamily:'ShortStack' }} />


     </Provider>
    </Container>
)
}
export default HandlesPreProd;