import {useEffect, useState, useRef, useContext} from 'react'

import {MainClaimContext} from '../../../ClaimContext'

import Connect from '../../../Connect'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { ToastContainer, toast } from 'react-toastify';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CircularProgress from '@mui/material/CircularProgress';

import styled from 'styled-components'

import './HorizontalDivider.css'


import './Nickname.css'
import './NicknameInput.css'


import './SearchBar.css'

import './Spinner.css'

import SellPopUp from '../../../SellPopUp'


import {MainContext} from '../../../Context/Context'

import './SelectionPanel.css'
import './UIAvatarSelect.css'

import './PlayButton.css'

import './Nickname.css'

import Card from './Card'

import './BuyButton.css'

import './ConnectButton.css'

import './NonSelectable.css'

import trousers from './json/trousers'
import clothes from './json/clothes'

import AttributeCard from './AttributeCard'

import PageTitle from './PageTitle'

import VoiceType from './VoiceType'

import { MainPlayerContext } from 'components/Context/PlayerContext'
import { MainExperienceContext } from 'components/Context/ExperienceContext'

import meta_db from './meta.json'
const CN_POLICY = '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728'
const CN_BASE_NAME = 'ClayNation'
const PANTS_BASE_NAME = 'ClayPants'
const IPFS_PATH = 'https://nyc3.digitaloceanspaces.com/claynation/IPFS/360x360/'
const CARDS_PER_PAGE = 25



const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`


const policy_ids = {
                      cn:"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
                      gc:"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
                      pants:"90a650d9ba123e2c31fa9c7ae22d1cb9308f6d609603bcfa52497771"
                    }


const collection_base_names = {
      cn:"ClayNation",
      gc:"ClayCharlotte",
      pants:"ClayPants"
}


const collection_names = {
        cn: "Clay Nation",
        gc: "Clay Nation x Good Charlotte",
        pants: "Clay Pants"
}

const ada_handle_policy = 'f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a'

const theme = createTheme({

  palette: {
    primary: {main: '#b7ffc1'},
    secondary: {main: '#b7ffc1'}
  },

  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});


function checkIpfs(image){

  try{
    return image.split('ipfs://').join('')
  }catch{
    return ''
  }
  
}

function useOutsideAlerter(ref, setOrderDropdownOpen) {
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOrderDropdownOpen(false)
      }
    }
   
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


function NothingFound(props){

  const {sourceValue} = props

  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  return(
    <>
    {sourceValue === 'market' ?
    <div className="nonSelectable" style={{display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', 
                        borderRadius: '18px', width:'50vw', minWidth:'350px', backdropFilter: 'blur(20px)',height:'100%', 
                        zIndex:'0', marginBottom:'100px', marginTop:'50px', flexWrap:'wrap', 

                        maxWidth: matchesXL ? '520px' : '400px'

                      }}>

                <div style={{fontSize: '28px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', 
                        color: 'rgba(255, 228, 196, 0.832)', position:'absolute', top:'20px'}}>
                Nothing Found...
            </div>
            
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-60px'}}>
               <img className="nonSelectable" src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay_Searching_small.gif" style={{maxHeight:'500px'}}/>
            </div>

            </div>

                : 


             <div style={{display:'flex', justifyContent:'center', alignItems:'center', background: 'rgba(217, 217, 217, 0.49)', 
                        borderRadius: '18px',width:'50vw', minWidth:'350px', backdropFilter: 'blur(20px)',height:'100%', 
                        zIndex:'0', marginBottom:'100px', marginTop:'50px', flexWrap:'wrap', 

                        maxWidth: matchesXL ? '520px' : '400px'

                      }}>

                <div style={{fontSize: '28px', letterSpacing: '0.1rem', textShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', 
                        color: 'rgba(255, 228, 196, 0.832)', position:'absolute', top:'20px'}}>
                Looking empty...
            </div>
            
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-60px'}}>
               <img className="nonSelectable" src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Clay_Shrugging_small.gif" style={{maxHeight:'500px'}}/>
            </div>

            </div>




            }
  
  </>
  )
}


function SortDropDown(props){

  const setOrderDropdownOpen = props.setOrderDropdownOpen
  const orderDropdownOpen = props.orderDropdownOpen

  const orderFilters = props.orderFilters
  const sortOrder = props.sortOrder

  const setSortOrder = props.setSortOrder

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOrderDropdownOpen);


  return(

   <div className="nonSelectable" ref={orderDropdownOpen ? wrapperRef : null} style={{width: '200px', cursor:'pointer', height:'58px', backgroundColor: true ? '#e7f0ff' : '#f6f7f6', 
    backdropFilter:'blur(50px)', display:'flex', alignItems:'center', justifyContent:'center', marginLeft:'0px',
    borderRadius:'20px', cursor:'none'
    }}

   onClick={()=>{
      setOrderDropdownOpen(!orderDropdownOpen)
    }}
  
  >


  <div style={{marginRight:'15px', marginLeft:'10px', display:'flex', width:'130px', alignItems:'center'}}>

    {orderFilters[sortOrder]} 

    {sortOrder == 'price_asc' ? 

      <ArrowUpwardIcon />

    : sortOrder == 'price_desc' ?

      <ArrowDownwardIcon />

    :null}
  </div>


  <div style={{height:'100%', contain:'content',display:'flex', flexDirection:'column', width:'20px', 
               marginLeft:'-10px',backgroundColor:'', alignItems:'center', justifyContent:'center'}}>
   <div style={{marginTop:'5px'}}>
      <ArrowDropUpIcon />
    </div>
    <div style={{marginTop:'-20px'}}>
      <ArrowDropDownIcon />
    </div>
  </div>


  {orderDropdownOpen ? 

    <div style={{position:'absolute', marginTop:'155px', backgroundColor:'', width:'100%',
                 display:'flex', justifyContent:'center', zIndex:'100000'

               }}>

        <div style={{backgroundColor: true ? '#e7f0ff' : '#f6f7f6', backdropFilter:'blur(50px)', width:'260px', borderRadius:'10px'}}>
          
          {
            Object.keys(orderFilters).map((key)=>{
               return( 
                <div key={key}
                  style={{marginTop:'8px', marginBottom:'8px', fontWeight: key == sortOrder ? 'bold' : ''}}
                  onClick={()=>{
                    //setOrderOption(key)
                    setSortOrder(key)
                  }}

                >

                {orderFilters[key]}

                </div>

                )
            })
          }

        </div>
      
    </div>

    : null
  }

</div>

  )
}




function AttributeTabs(props){

  const {attributeValue, setAttributeValue} = props

  const avataricon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/UI/AvatarSelection/avatartabicon.png'
  const trousersicon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/UI/AvatarSelection/trouserstabicon.png'
  const sweatshirticon = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/UI/AvatarSelection/sweatshirt.png'


  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


  const handleChange = (event, newValue) => {
    setAttributeValue(newValue);
  };
  
  return(
    <>
      <div className="UITabs" style={{backgroundColor:'', width:matchesL ? '120px' : '80px', height:'0px'}}>

          <Box sx={{ width: '100%' }}>

            <Tabs 
              selectionFollowsFocus
              orientation="vertical"
              variant="scrollable"
              onChange={handleChange}
              value={attributeValue}  
              aria-label="material tabs"
              sx={{ width: matchesL ? 120 : 80, color: 'white', borderTopLeftRadius: '15px',
              '& .MuiTabs-indicator': {
                  backgroundColor: '#b7ffc1',
                 
              },

              '& .MuiTab-root':{
              fontFamily: 'ShortStack',

              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)',
              //background: 'linear-gradient(180deg, rgba(227, 227, 227, 0.16) 1000%, rgba(217, 217, 217, 0) 100%)',
           
           },
           '& .Mui-selected': {
              color: '#b7ffc1',
          },

          }}
            >
              <Tab value="avatar"
                style={{cursor:'none'}}
                icon={<img className='avatarIcon'  src={avataricon} 
                style={{height: matchesL ? '70px' : '50px',
                       marginLeft: matchesL ? '0px' : '-10px'
                }}>
                
              </img>}
              />
              
              <Tab value="clothes"
                style={{cursor:'none'}}
                icon={<img className='sweatshirtIcon'  src={sweatshirticon} 
                style={{height: matchesL ? '70px' : '50px',
                        marginLeft: matchesL ? '0px' : '-10px'
              }}></img>} 
              />

              <Tab value="pants" 
                style={{cursor:'none'}}
                icon={<img className='trousersIcon'  src={trousersicon} 
                style={{height: matchesL ? '70px' : '50px',
                        marginLeft: matchesL ? '0px' : '-10px'
              }}></img>}   
              />



            </Tabs>

          </Box>

        </div>
    </>
  )
}



function SourceTabs(props){

  const {sourceValue, setSourceValue} = props

  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  const handleChange = (event, newValue) => {
    setSourceValue(newValue);
  };

  return(

    <div className="UITabs">
    <Box sx={{ width: '245px'}}>

           <Tabs 
              selectionFollowsFocus
              orientation="horizontal"
              variant="scrollable"
              onChange={handleChange}
              value={sourceValue}  
              aria-label="material tabs"
              sx={{ width: 245, color: 'white', borderTopLeftRadius: '15px',

              '& .MuiTabs-indicator': {
                  backgroundColor: '#b7ffc1'
              },

              '& .MuiTab-root':{
              fontFamily: 'ShortStack',
              color:'rgba(235,235,235,0.75)',

              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)',
              //background: 'linear-gradient(180deg, rgba(227, 227, 227, 0.16) 100%, rgba(217, 217, 217, 0) 100%)'
           
           },

           '& .Mui-selected': {
              color: '#b7ffc1',
            },

          }}
            >

              <Tab 
                style={{cursor:'none'}} 
                value="holding" 
                label='Holding' 
              />
              
              
              <Tab 
                style={{cursor:'none'}} 
                value="market" 
                label='MarketPlace' 
                sx={{borderTopRightRadius:'15px'}} 
              />
             
            </Tabs>

        </Box>

        </div>
  )
}



function AttributeCardsDisplay(props){

  const {attributeValue, sourceValue} = props

  return(
    <>
      {attributeValue}
      {sourceValue}
    </>
  )
}



function AvatarCardsDisplay(props){

  const {currentAssets, setCurrentAssets, selected, setSelected, setMeta, loader} = props



  const [pageNumber, setPageNumber] = useState(1);
  

  const [assets, setAssets] = useState([])

  


  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);



  useEffect(() => {

    let temp_assets = []
    for (let key in meta_db){
      if (key.slice(0,56) === CN_POLICY){
        let asset_hex = key.slice(56,)
        let asset_name = Buffer.from(asset_hex, 'hex').toString('utf8')
        let asset_num = asset_name.slice(CN_BASE_NAME.length,)
        let serial = 'CN' + '#' + String(asset_num)

        let value = meta_db[key]

        //FOR TESTING ONLY
        //let myAssets = ['1618', '1841', '5150', '7004', '7481', '7939', '8599', '9671']
        //if (myAssets.includes(String(asset_num))){
          temp_assets.push({serial: serial, ...value})
        //}


        //temp_assets.push({serial: serial, ...value})
      }
    }

    setAssets(temp_assets)


    setCurrentAssets(temp_assets.slice(pageNumber - 1, CARDS_PER_PAGE))

  }, [])







  useEffect(() => {
    
    let newAssets = assets.slice(CARDS_PER_PAGE * pageNumber, CARDS_PER_PAGE * (pageNumber + 1) )
    setCurrentAssets((prevImages) => [...prevImages, ...newAssets]);
     
  }, [pageNumber]);



const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };


  return(             
                 <div className="Grid">

                    {
                      currentAssets.map((asset) => {

                        return(
                          <Card 
                            key={asset.image}
                            image_url={IPFS_PATH + checkIpfs(asset.image) + '.png'} 
                            serial={asset.serial} 
                            selected={selected} 
                            setSelected={setSelected} 

                            asset={asset}
                            setMeta={setMeta}
                          />
                        )

                      })
                    }
                    <div ref={loader}></div>
                  </div>
  )
}


function Divider(){

  return(
    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
      <div className="horizontal-divider" style={{width:'80%'}}>

      </div>
    </div>
  )
}




function HoldingFilters(props){

  const {listedOnly, setListedOnly, notListedOnly, setNotListedOnly} = props

  return(
    <>
    <div className="nonSelectable" style={{width:'100%', height:'100%', display:'flex', 
                          backgroundColor:'', color: 'rgba(255,255,255,0.95)', 
                          justifyContent:'center', alignItems:'end'}}>


                      <div style={{width:'220px', height:'50px', 
                                  backgroundColor:'', display:'flex', 
                                  justifyContent:'center', alignItems:'center'
                                }}>

                        <div style={{marginRight:''}}>
                            Listed
                        </div>

                        <div style={{transform:'scale(0.65)'}}>
                            <div className={listedOnly ? "cn-toggle-on" : "cn-toggle-off"} style={{cursor:'none'}} onClick={()=>{
                                    setListedOnly(!listedOnly)
                            }}>
                                 <div className={listedOnly ? "notch-CN-on" : "notch-CN-off"} 
                                      style={{cursor:'none'}}></div>
                                </div>
                        </div>
                      </div>

                      

                      <div style={{width:'220px', height:'50px', 
                                  backgroundColor:'', display:'flex', 
                                  justifyContent:'center', alignItems:'center'}}>

                        <div style={{marginRight:''}}>
                            Not Listed
                        </div>

                        <div style={{transform:'scale(0.65)'}}>
                            <div className={notListedOnly ? "cn-toggle-on" : "cn-toggle-off"} style={{cursor:'none'}} onClick={()=>{
                                
                               
                                    setNotListedOnly(!notListedOnly)
                               

                                
                            }}>
                                 <div className={notListedOnly ? "notch-CN-on" : "notch-CN-off"} style={{cursor:'none'}}></div>
                                </div>
                        </div>
                      </div>
                
              </div>

    </>
  )
}



function MarketFilters(props){

  const {searchQuery, setSearchQuery, searchVal, 
         setSearchVal, makeSearch, orderFilters,
         orderDropdownOpen, setOrderDropdownOpen,
         sortOrder, setSortOrder, displayLongValue,
         isSearching, sourceValue,
         attributeValue
        } = props

  const [searchClassName, setSearchClassName] = useState('searchField2')
  const [searchPlaceholder, setSearchPlaceholder] = useState('Search')


  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


  return(
    <>
    <div className="nonSelectable" style={{width: matchesXL ? '300px' : matchesL ? '220px' : '180px', height:'50px', 
                          backgroundColor:'', display:'flex',
                          alignItems:'center', justifyContent:'center'
                    }}>




            {searchQuery ?

               <div style={{marginBottom:'0px', width: matchesXL ? '320px' : '220px', backgroundColor:'', 
               display:'flex', justifyContent:'center', transform: !matchesM ? 'scale(0.8)' : !matchesL ? 'scale(0.9)' : 'scale(1)'}}>

               <div className="stakingAddress" 

               style={{height:'50px', width:'100%', backgroundColor:'', marginLeft:'0px', cursor:'none'}}> 

               <span className="tokensNumber" style={{color:'#e7e6d2'}}>
                 
               </span><span className="tokensText" style={{color:'#e7e6d2', inlineSize: matchesXL ? '320px' : '200px', overflowWrap:'break-word', marginLeft:'10px'}}>{displayLongValue(searchQuery)}</span> 

               <IconButton 

                style={{cursor:'none'}}

                onClick={()=>{
                setSearchQuery('')
                setSearchVal('')
                //setSearchedStakeAddr('')
               }}>
                <CloseIcon style={{color:'bisque'}} />
               </IconButton>

               </div>
            </div>


            :


              <div className={true || matchesS ? searchClassName : searchClassName.split(' ')[0] + 'Small' + ' ' + searchClassName.split(' ')[1]} 
              style={{marginTop:'0px', marginBottom:'0px', marginLeft: matchesS ? '0px' :'0px', 

              width: matchesXL ? '280px' : '200px', 

              marginRight:'0px',

              transform: !matchesM ? 'scale(0.8)' : !matchesL ? 'scale(0.9)' : 'scale(1)'

            }}>

                 <SearchInput marginLeft={searchClassName == 'searchField2 focussed' || searchClassName == 'searchFieldPressed2' ? '0px' : (matchesXL ? '50px' : '30px')} 

                   style={{width: matchesXL ? '240px' : '160px'}}

                 >
                    
                    <input
                      id="searching"
                      type="text"
                      value={searchVal}
                      placeholder={searchPlaceholder}

                      style={{width: matchesXL ? '255px' : '170px', cursor: 'none'}}

        
                      onChange={(event) => { 
                        
                        let cur_value = event.target.value
                        setSearchVal(cur_value)

                        if (cur_value == ''){
                          //do nothing
                        }else{
                           setSearchClassName('searchFieldPressed2')
                         }

                      }}

                      onKeyPress={(event) => {

                          if (event.key == 'Enter'){
                             makeSearch(searchVal)
                          }

                        }}

               
                      onFocus={() => {

                      if (searchVal == ''){

                          setSearchClassName('searchField2 focussed')

                      }else{

                          setSearchClassName('searchFieldPressed2')
                      
                      }

                      }}


                      onBlur={() => {

                        if (searchVal == ''){

                           setSearchClassName('searchField2')

                        }else{

                          setSearchClassName('searchFieldPressed2')

                        }

                      }}
                   />

            </SearchInput>


            <img className="searchicon"  src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/search.png" style={{height:matchesXL ? '50px' : '40px', width:'auto', animation: searchClassName == 'searchField2 focussed' || searchClassName == 'searchFieldPressed2' ? (matchesXL ? 'swipeRight3 0.4s ease-out forwards' : 'swipeRight2 0.4s ease-out forwards') : 'none' }} />


            {!isSearching ?
 

            <button onClick={() => {

              makeSearch(searchVal)
             
            }} 

            style={{position:'relative', zIndex:'250', cursor:'none', height:'55px', width:'55px', backgroundColor:'transparent', border:'none', borderRadius:'17px', display: searchClassName == 'searchField2 focussed' || searchClassName == 'searchFieldPressed2' ? 'block' : 'none'}}>
              <img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/search.png" style={{height:matchesXL ? '50px' : '40px', width:'auto', marginTop: '2px', animation:'btnAppear 0.4s ease-out forwards'}} />
            </button>

            : <div style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'center', height:'50px', width:'60px', zIndex:'250', backgroundColor:''}}> <CircularProgress style={{zIndex:'200', width:'30px', height:'30px', color: "#53c8ee"}} /> </div>
          
          }


          </div>

        }


          </div>


              <div style={{width: '220px', height:'50px', 
                    backgroundColor:'', display:'flex',
                    alignItems:'center', justifyContent:'center'
                  }}>



              <div style={{position:'absolute', zIndex:'100000'}}>

              {sourceValue === 'market' || true ?
                <div style={{transform: !matchesM ? 'scale(0.8)' : !matchesL ? 'scale(0.9)' : 'scale(1)'}}>

                      <SortDropDown 

                        setOrderDropdownOpen={setOrderDropdownOpen}
                        orderDropdownOpen={orderDropdownOpen}
                        orderFilters={orderFilters}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}

                        />

                  </div>

                : null}

            </div>
              
              </div>
    </>
  )
}



function DisplayAssets(props){

  const {marketAssets, selected, setSelected, meta, setMeta, setAssetToBuy, isFetching, loader, sourceValue, attributeValue} = props


  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));


  return(
    <>
    <div style={{width:'100%', height:'450px', backgroundColor:'', overflow:'auto'}}>
                <div className="Inner"  >
                  {marketAssets.length > 0 ?
                  <div className="Grid" style={{marginLeft: matchesXL ? '0px' : '15px'}}>

                  {
                    attributeValue === 'pants' ?

                    <Card 
                            key={'PANTS_NONE'}
                            image_url={'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/green_bg.png'} 
                            serial={'PANTS_NONE'} 
                            selected={selected} 
                            setSelected={setSelected} 
                            asset={{
                              type:'None'
                            }}
                            meta={meta}
                            setMeta={setMeta}
                            setAssetToBuy={setAssetToBuy}
                          />

                          : null

                  }

                    {
                      marketAssets.map((asset) => {
                        return(
                          <Card 
                            key={asset.image}
                            image_url={IPFS_PATH + checkIpfs(asset.image) + '.png'} 
                            serial={asset.serial} 
                            selected={selected} 
                            setSelected={setSelected} 
                            asset={asset}
                            meta={meta}
                            setMeta={setMeta}
                            setAssetToBuy={setAssetToBuy}
                          />
                        )
                      })
                    }
                    <div ref={loader} style={{position:'relative', backgroundColor:''}}></div>
                  </div>
          
        : !isFetching && marketAssets.length == 0 ?
          <NothingFound sourceValue={sourceValue} />
        : null}

        <div style={{width:'100%', height:'50px'}}></div>
        
        {isFetching && marketAssets.length == 0 ?
                    <div>
                      <div id="spinner" className="spinnerCenter"></div>
                    </div>
                    : null}

         {isFetching && marketAssets.length > 0 ?

                    <div style={{position:'relative',width:'100%', height:'50px', backgroundColor:'', 
                                 display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <div id="spinner" className="spinner" style={{marginLeft:'25px'}}></div>
                    </div>
                    : null}

                </div>
             </div>
    </>
  )
}



function UIAvatarSelect(props) {

  // console.log('\n\n\nMAIN CLAIM CONTEXT')
  const {address} = useContext(MainClaimContext)
  //const {cursorChangeHandler} = props
  const {activeScene, setActiveScene} = useContext(MainExperienceContext)
  const {avatarMeta, setAvatarMeta, nickname, setNickname, voiceType, setVoiceType} = useContext(MainPlayerContext)
  const {currentApp, setCurrentApp} = useContext(MainContext)
  const meta = props.meta
  const setMeta = props.setMeta
  const [selected, setSelected] = useState()
  const [assets, setAssets] = useState([])
  const [currentAssets, setCurrentAssets] = useState([])
  const [marketAssets, setMarketAssets] = useState([])
  const [pageNumber, setPageNumber] = useState(1);
  const loader = useRef(null);
  const [chosenNickname, setChosenNickname] = useState()
  const [wrongNickname, setWrongNickname] = useState(false)
  const [attributeValue, setAttributeValue] = useState('avatar')
  const [sourceValue, setSourceValue] = useState('holding')
  const [clayPrice, setClayPrice] = useState()
  const [currentSlot, setCurrentSlot] = useState()
  const [collection, setCollection] = useState('cn')
  const [listingsDict, setListingsDict] = useState({})
  const trousersImagePath = "https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/images/trousers/"
  const [selectedTrousers, setSelectedTrousers] = useState('None')

  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'5px',width:'42px', height:'42px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)

  const orderFilters = {
      price_asc:'Price: ascending',
      price_desc:'Price: descending',
      most_recent:'Most recent'
    }

  const [shortDesc, setShortDesc] = useState(true)
  const [searchVal, setSearchVal] = useState('')
  const [orderDropdownOpen, setOrderDropdownOpen] = useState(false)  
  const [isSearching, setIsSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOrder, setSortOrder] = useState('price_asc')
  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));
  const [sellAsset, setSellAsset] = useState()
  const [curActiveWallet, setCurActiveWallet] = useState('')
  const [submittedTx, setSubmittedTx] = useState('')
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [currentActiveWallet, setCurrentActiveWallet] = useState('')
  const [assetsOwned, setAssetsOwned] = useState([])
  const [isWalletLoading, setIsWalletLoading] = useState(false)
  const [listedOnly, setListedOnly] = useState(true)
  const [notListedOnly, setNotListedOnly] = useState(true)
  const [stakeAddress, setStakeAddress] = useState('')
  const [assetToBuy, setAssetToBuy] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [isPressed, setIsPressed] = useState(false);
  const [pressBuy, setPressBuy] = useState(0);
  const [pressDuration, setPressDuration] = useState(0);


  const [readyStart, setReadyStart] = useState(false)

  const [startBlocked, setStartBlocked] = useState(true)


  useEffect(() => {

    setStartBlocked(!(chosenNickname !== '' && typeof(meta) != 'undefined' && 'serial' in meta && 'model' in meta && 'image' in meta))

  }, [chosenNickname, meta])



  useEffect(() => {

    // console.log(address)

    try{
      let temp_stake_address = addressToStake(address)
      setStakeAddress(temp_stake_address)

      //FOR TESTING ONLY
      //setStakeAddress('stake1u8hksgd2etj3wexftx60gxmlqnevwrfhtsj0fv89nh8k63qtc2ss2')


    }catch{

    }

  },[address])



  useEffect(() => {

    let temp_assets = []
    for (let key in meta_db){
      if (key.slice(0,56) === CN_POLICY){
        let asset_hex = key.slice(56,)
        let asset_name = Buffer.from(asset_hex, 'hex').toString('utf8')
        let asset_num = asset_name.slice(CN_BASE_NAME.length,)
        let serial = 'CN' + '#' + String(asset_num)

        let value = meta_db[key]
        temp_assets.push({serial: serial, ...value})
      }
    }

    setAssets(temp_assets)
    setCurrentAssets(temp_assets.slice(0, CARDS_PER_PAGE))

  },[])



  async function getCurrentSlot(){
        let slot = await fetch('https://claymarket.io/api/current/slot/').then(response => response.json())
                                                                      .then(data => data)
        slot = parseInt(slot.slot, 10)

        return slot
    }


  async function get_clay_price(){

     let fetched_current_stats = await fetch('https://clayapi.com/claystats/current/').then(response => response.json())
                                                                  .then(data => data)

     let temp_clay_price = fetched_current_stats['clay_price'].toFixed(6)
     //console.log(temp_clay_price)
     setClayPrice(temp_clay_price)

  } 


function addressToStake(cur_address){
    let temp = serLib.Address.from_bech32(cur_address)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()

    return cur_stake_address
}


function assetFromSerial(pValue){

  if (collection in policy_ids && collection in collection_base_names){
    let cur_policy_id = policy_ids[collection]
    let base_name = collection_base_names[collection]

    let cur_asset_id = cur_policy_id + Buffer.from(base_name + String(pValue), 'utf8').toString('hex')

    return cur_asset_id

  }else{
    return ''
  }

}


  async function get_market_listings(prevAssets, page){

    if (!isFetching){
      setIsFetching(true)
    }

    if (sourceValue === 'holding' && !stakeAddress){
      setMarketAssets([])
      setIsFetching(false)
      return 
    }
    
    let temp_currentSlot = currentSlot

    if (temp_currentSlot == 0){

        //console.log('NEED FETCHING SLOT')

        temp_currentSlot = await getCurrentSlot()

        setCurrentSlot(temp_currentSlot)

    }
      
    let query_url = 'https://claymarket.io/api/filter/listings/?collection=' + collection + '&page=' + String(page)
    
        /*
        for (let key in appliedFilters){

            if (Array.isArray(appliedFilters[key])){
                for (let i = 0;  i < appliedFilters[key].length; i++){
                    query_url += '&' + key + '=' +  appliedFilters[key][i].replaceAll(" ","_")
                 }
            }else{
                 query_url += '&' + key + '=' +  appliedFilters[key]
            }
        }
        */

        
        if (sortOrder){
            query_url += '&order=' + sortOrder
        }
        
        if (sourceValue === 'holding' && stakeAddress){
            query_url += '&stake_address=' + stakeAddress
        }

        if (clayPrice){
            query_url += '&clay_price=' + clayPrice
        }
       

    if (sourceValue === 'market'){
      query_url += searchQuery
    }
    
        
    let asset_listing_info = await fetch(query_url).then(response => response.json())
                                   .then(data => data)

    // console.log('QUERY AND RESULT')
    // console.log(query_url)                              
    // console.log(asset_listing_info)

    
    if (asset_listing_info.hasOwnProperty('listings')){
      asset_listing_info = asset_listing_info['listings']
    }else{
      setMarketAssets([...prevAssets])
      setIsFetching(false)
      setHasMore(false)
      return
    } 

    

    let temp_assets = []

    for (let i = 0; i < asset_listing_info.length; i++){

      let cur_asset_listing = asset_listing_info[i] 
      let ada_price = cur_asset_listing['ada_price']
      let clay_price = cur_asset_listing['clay_price']
      let assetStatus = ''
      let includeAsset = false

      if (ada_price > 0 || clay_price > 0){

            if (cur_asset_listing['owner_stake_address'] == stakeAddress){
                assetStatus = 'updatable'
            }else{
                assetStatus = 'listed'
            }
         
         if (sourceValue === 'holding' || (sourceValue === 'holding' && listedOnly) ){
            includeAsset = true
         } else if(sourceValue === 'market' && cur_asset_listing['owner_stake_address'] !== stakeAddress){
            includeAsset = true
         }
         
        }else{
            
            if (cur_asset_listing['stake_address'] == stakeAddress){
                assetStatus = 'sellable'
            }else{
                assetStatus = 'not listed'
            }
            

            if (sourceValue === 'market' || (sourceValue === 'holding' && notListedOnly) ){
              includeAsset = true
            }else if (sourceValue === 'market' && cur_asset_listing['stake_address'] !== stakeAddress){
              includeAsset = true
            }
        }

      if (includeAsset){
        let cur_asset = asset_listing_info[i]
        let cur_asset_id = cur_asset['asset_id']

        let asset_hex = cur_asset_id.slice(56,)
        

        let asset_name;
        

        //console.log(cur_asset)
        let asset_num
        let serial; 

        let value;

        if (cur_asset_id.slice(0,56) === policy_ids.cn){
          asset_name = Buffer.from(asset_hex, 'hex').toString('utf8')
          value = meta_db[cur_asset_id]
          asset_num = asset_name.slice(CN_BASE_NAME.length,)
          serial = 'CN' + '#' + String(asset_num)
        }else{
          asset_name = Buffer.from(asset_hex.slice(8,), 'hex').toString('utf8')
          value = cur_asset['meta']
          //console.log(asset_name)
          asset_num = asset_name.slice(PANTS_BASE_NAME.length,)
          serial = 'PANTS' + '#' + String(asset_num)
        }

        //let value = meta_db[cur_asset_id]



        if (assetStatus === 'listed'){

          let asset_id = cur_asset_listing['asset_id']
          let tx_id = cur_asset_listing['tx_id']

          let tx_index = cur_asset_listing['tx_index']

          let tx_hash = tx_id + '#' + String(tx_index)
         
          let cur_address = cur_asset_listing['address']

          let cur_meta = cur_asset_listing['meta']

          if (ada_price > 5000000 && clay_price === 0){
            temp_assets.push({
              serial: serial,

              assetPrice: {
                ada_price: ada_price, 
                clay_price: clay_price
              },
               

              asset_id: asset_id,
              tx_hash: tx_hash,
              collection: collection,
              address: cur_address,

              meta: cur_meta,

              assetStatus: assetStatus,

              ...value
            })
          }else if (ada_price <= 5000000 && clay_price > 0){
            temp_assets.push({
              serial: serial, 

              assetPrice: {
                ada_price: ada_price, 
                clay_price: clay_price
              },

              asset_id: asset_id,
              tx_hash: tx_hash,
              collection: collection,
              address: cur_address,

              meta: cur_meta,

              assetStatus: assetStatus,

              ...value})
          }
          
        }else{
          temp_assets.push({serial: serial, ...value})
        }
        
      }


    }


    if (temp_assets.length > 0){
      // console.log(prevAssets)
      // console.log(temp_assets)
      // console.log('TEMP ASSETS')
      // console.log(temp_assets)
      setMarketAssets([...prevAssets,...temp_assets])
      setHasMore(true)
    }else{
      setHasMore(false)
    }

    setIsFetching(false)

  }



  useEffect(() => {

    if (attributeValue === 'avatar'){
      setCollection('cn')
    }else if (attributeValue === 'pants'){
      setCollection('pants')
    }else if (attributeValue === 'clothes'){
      setCollection('clothes')
    }


  },[attributeValue])



  useEffect(() => {

      setMarketAssets([])
      setPageNumber(1)
      get_market_listings([], 1)

  }, [sortOrder, sourceValue, collection, listedOnly, notListedOnly, searchQuery])


  useEffect(() => {

    if (sourceValue === 'holding'){
      setMarketAssets([])
      setPageNumber(1)
      get_market_listings([], 1)
    }

  },[stakeAddress])



  useEffect(() => {

    if (pageNumber > 1 && !isFetching){
      get_market_listings([...marketAssets], pageNumber)
    }

  }, [pageNumber])




  async function prepareSearch(pValue){

    pValue = pValue.toLowerCase()

    if (pValue.startsWith('addr1')){

      try{
        let temp_stake = addressToStake(pValue)
        setSearchQuery('&stake_address='+String(temp_stake))
        return
      }catch(err) {
        notifyError(err)
      }      

    }

    else if (pValue.startsWith('stake1')){
      setSearchQuery('&stake_address='+String(pValue))
      return
    }

    //search by handle
    else if (pValue.startsWith('$')){

      let handle = pValue.slice(1,)
      let hex_handle = Buffer.from(handle).toString('hex')

      let handle_asset_id = String(ada_handle_policy) + String(hex_handle)

      let handle_owner = await fetch('https://clayapi.com/filtering/asset/owner/'+handle_asset_id + '/')
      .then((response) => response.json())
      .then((data) => data);

      if ('error' in handle_owner){

        notifyError('Failed to fetch $handle owner')

      }else{

        handle_owner = handle_owner['address']
          //console.log(handle_owner)
          let handle_account;
          try{
            handle_account = addressToStake(handle_owner)
          }catch{
            notifyError('Invalid address')
            return
          }

          setSearchQuery("&stake_address="+String(handle_account))
          return 
      }

    }


    if (collection == 'pitches'){

      //coordinates
      if (pValue.includes('(') || 
          pValue.includes(')') ||
          pValue.includes(',') ||
          pValue.includes(' ') ){

        let temp = pValue.split('(')

        if (temp.length == 2){
          temp = temp[1]
        }else{
          temp = temp[0]
        }

        temp = temp.split(')')[0]
        temp = temp.split(',')

        if (temp.length == 1){
          temp = temp[0].split(' ')
        }

        let x = parseInt(temp[0],10)
        let y = parseInt(temp[1],10)

        if (isNaN(x) || isNaN(y)){
          notifyError('Incorrect Format')
          return 
        }else{
          //FETCH RECORDS HERE
          let cur_pitch_coord = '('+String(x) + ',' + String(y)+')'

          if (false){ //cur_pitch_coord in pitches_info){
            return 
          }else{
            notifyError("Pitch with these coordinates does not exist")
          }
        }

         

      }
      //serial 
      else{

        let serial = pValue.split('#')

        if (serial.length == 2){
          serial = serial[1]
        }else{
          serial = serial[0]
        }



        serial = parseInt(serial, 10)
        //console.log(serial)

        if (isNaN(serial)){
          notifyError('Incorrect Format')
          return
        }else{
          //FETCH RECORDS HERE

          
            if (serial < 0 || serial > 32027){
              notifyError('No such asset exists')
              return
            }

          setSearchQuery('&asset_id='+String(assetFromSerial(String(serial))))
          return
        }

        

      }

    //serial 
    }else{
      
      let serial = pValue.split('#')

      if (serial.length == 2){
        serial = serial[1]
      }else{
        serial = serial[0]
      }

      serial = parseInt(serial, 10)
      //console.log(serial)

      
      if(isNaN(serial)){
          notifyError('Incorrect Format') 
          return
      }else{


        if(collection == 'gc'){
            if (serial < 0 || serial > 9427){
              notifyError('No such asset exists')
              return
            }

          }else{
            if (serial < 0 || serial > 10000){
              notifyError('No such asset exists')
              return
          }
        }

        //FETCH RECORDS HERE
        setSearchQuery('&asset_id='+String(assetFromSerial(String(serial))))
        return
      }
    
    }

  } 


  async function makeSearch(pValue){
    setIsSearching(true)

    await prepareSearch(pValue)

    setIsSearching(false)
  }



function displayLongValue(pValue){

  pValue = pValue.split('=')

  if (pValue.length > 1){
    pValue = pValue[1]
  }else{
    return
  }
  
  if (pValue.length > 20){
    return pValue.slice(0,7) + '...' + pValue.slice(-7,)
  }else{
    return pValue
  }
  
}


  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    });

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, 
    [

      loader, loader.current, marketAssets

    ]);


  useEffect(() => {
    
    let newAssets = assets.slice(CARDS_PER_PAGE * pageNumber, CARDS_PER_PAGE * (pageNumber + 1) )
    setCurrentAssets((prevImages) => [...prevImages, ...newAssets]);
     
  }, [pageNumber]);



const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      // console.log('intersecting')
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };


  useEffect(() => {

    let temp_meta = {...meta}
    let madeChanges = false
    if (selectedTrousers){
      // console.log('META')
      // console.log(meta)

      if (temp_meta.hasOwnProperty('model')){
        if (temp_meta.model.trousers !== selectedTrousers){
          //temp_meta.model.trousers = selectedTrousers
          madeChanges = true
        }
        
      }

    }

    if (madeChanges){
      //setMeta(temp_meta)
    }


    // let temp_meta = {...meta}
    // let madeChanges = false

    // if (temp_meta.hasOwnProperty('model')){
    //   madeChanges = true
    // }

    // if (madeChanges){
    //   setMeta(temp_meta)
    // }
    

  },[meta, selectedTrousers])








  useEffect(() => {

    // console.log('SELECTED')
    // console.log(selected)

    // console.log(assetToBuy)

  },[selected])



function displayPrice(price){
  let ada_price = price['ada_price']
  let clay_price = price['clay_price']

  if (ada_price > 5000000){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
      <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/Cardano_white.svg' style={{width:'25px', zIndex:'0'}} />
        {displayAmount((ada_price/1000000), 2) + ' ADA'}
      </div>
    )
  }else if(clay_price > 0){
    return(
      <div style={{display:'flex', alignItems:'center'}}>
        <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CLAY_TOKEN_220x220.png' style={{width:'25px', zIndex:'0'}} />
        {displayAmount((clay_price/10000), 2) + ' $CLAY' }
      </div>
      )
  }
}

function displayAmount(amount, precision){
    if (amount < 10000){
      return String(amount)
    }else if (amount < 1000000){
      return String((amount / 1000).toFixed(precision)) + 'K'
    }else if (amount < 1000000000){
      return String((amount / 1000000).toFixed(precision)) + 'M'
    }
}

async function updateCookiesNickname(chosenNickname){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('nickname') + " = " + String(chosenNickname) + '; ' + expires + "; path=/"
   }




    function checkCookiesNickname(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }

       return cookies_dict['nickname']
  }



  useEffect(() => {

    try{
      let cookiesNickname = checkCookiesNickname()
      if (cookiesNickname !== undefined && cookiesNickname !== ''){
        let temp_nickname = cookiesNickname.slice(0,10)
        setNickname(temp_nickname)
        setChosenNickname(temp_nickname)
      }
      
    }catch{

    }
        
  },[])




  return (
    <>


    {!matchesM ?
    

    <>


    <div style={{position:'absolute', zIndex:'100', top:'10px'}}>

    <div style={{width:'100%', height:'100%'}}>

    <div style={{display:'flex', justifyContent:'center', alignItems:'start', flexDirection:'column'}}>

      <div style={

        matchesL ?

        {position:'', backgroundColor:'', top:'0px', transform: 'scale(1)'}

        : 

        {position:'', backgroundColor:'', top:'0px', transform:'scale(0.75)', marginLeft:'-35px', marginTop:'-15px'}

      }>

        <div style={{width:'100%', height:'80px', width:'80px', backgroundColor:'', display:'flex', justifyContent:'start', marginBottom:'20px'}}>
            <IconButton
                  onClick={() => {
                    setCurrentApp('market')
                  }}

                  style={{marginLeft:'5px', cursor:'none'}}
                  
                 >
                  <ArrowBackIcon sx={{fontSize: "60px", color: "#b7ffc1df"}} />

            </IconButton>

          </div>

        <PageTitle />

        {/*<div className="nonSelectable" style={{color:'white'}}>
          *linked to original NFTs
        </div>*/}

      </div>

      </div>

      </div>

      </div>




      <div style={{position:'absolute', bottom:'0px', width:'100%', 
                   height:'395px', backgroundColor:'',
                   zIndex: '100', overflow:'auto'}}>

         <div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>


         <div style={{display:'flex', justifyContent:'center', 
                      alignItems:'end', backgroundColor: '',
                      marginLeft: '80px'
                    }}>


          <div style={{marginLeft:'220px'}}>
              
            </div>


      <button className={!startBlocked ? "buy-button" : "buy-button-blocked"}
      
        onMouseDown={() => {
          // console.log('mouse down')

           if ( chosenNickname !== '' && typeof(meta) != 'undefined' && 'serial' in meta && 'model' in meta && 'image' in meta){
    
             setIsPressed(true);
             setPressBuy(Date.now());
          }else{
            if (chosenNickname === ''){
                setWrongNickname(true)
              }
          }
        }}

        onMouseUp={() => {
          // console.log('mouse up')

          setIsPressed(false);
          const duration = Date.now() - pressBuy;

          // console.log(duration)

         

          if (duration > 1400 || true){
             setReadyStart(true)

             if (chosenNickname !== '' && typeof(meta) != 'undefined' && 'serial' in meta && 'model' in meta && 'image' in meta){
              // console.log('ALL GOOD')
              setAvatarMeta(meta)

              updateCookiesNickname(chosenNickname)

              // console.log(activeScene)
              setActiveScene('StoreScene')


            }else{
              if (chosenNickname === ''){
                setWrongNickname(true)
              }
            }
          }

        }}
        
         style={{cursor:'none', 
                  textShadow: '0px 0px 2px rgba(255, 255, 255, 0.43)',
                  transform: 'scale(0.6)',
                  position:'relative',
                  width:'210px',

                  height:'100px',

                  backdropFilter:'blur(5px)'
                }}
        >

          <div>
            <div

              onMouseEnter={() => {
                // console.log('Mouse Enter')
                //cursorChangeHandler("hovered")
              }}
              onMouseLeave={() => {//cursorChangeHandler("")
              }
            }

            >
             START
            </div>

            
          </div>
      </button>








        </div>   
           
          <div style={{display:'flex', justifyContent:'center'}}>
           
           <ThemeProvider theme={theme}>
             <AttributeTabs 
              attributeValue={attributeValue} 
              setAttributeValue={setAttributeValue} 
            />
           </ThemeProvider>
          


        <div style={{
                        width: '450px', 
                        height:'300px', backgroundColor:'',
                        borderTopRightRadius: '15px', borderBottomRightRadius: '15px',
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)',
                        boxShadow: `inset 1px 8px 10px rgba(255, 255, 255, 0.04)`,
                        backdropFilter: 'blur(5px)', fontFamily:'ShortStack'
                      }}>
          
          <div style={{width:'100%', height:'50px', backgroundColor:'', display:'flex', 
                      justifyContent:'center', alignItems:'end', marginTop:'10px', marginBottom:'-10px'
        }}>

          {sourceValue === 'market' ?

               <MarketFilters 
                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery} 
                 searchVal={searchVal} 
                 setSearchVal={setSearchVal}
                 makeSearch={makeSearch} 
                 orderFilters={orderFilters}
                 orderDropdownOpen={orderDropdownOpen} 
                 setOrderDropdownOpen={setOrderDropdownOpen}
                 sortOrder={sortOrder} 
                 setSortOrder={setSortOrder} 
                 displayLongValue={displayLongValue}
                 isSearching={isSearching} 
                 sourceValue={sourceValue}
                 attributeValue={attributeValue}
               />

              : 

                <HoldingFilters 
                  listedOnly={listedOnly} 
                  setListedOnly={setListedOnly} 
                  notListedOnly={notListedOnly} 
                  setNotListedOnly={setNotListedOnly} 
                />
             
            }

            </div>



         
        <div style={{marginBottom:'0px', overflow:'hidden'}}>
            <Divider />
        </div>

            <div style={{transform:'scale(0.7)', marginTop:'-50px',height:'280px', overflow:'auto', zIndex:'1'}}>
              <DisplayAssets 
                marketAssets={marketAssets} 
                selected={selected} 
                setSelected={setSelected} 
                meta={meta}
                setMeta={setMeta} 
                setAssetToBuy={setAssetToBuy} 
                isFetching={isFetching} 
                loader={loader} 
                sourceValue={sourceValue} 
                attributeValue={attributeValue}
              />
            </div>
        
        </div>
        </div>
        </div>
        </div>

      </>


    : !matchesL ?


    <>


    <div style={{position:'absolute', zIndex:'100', top:'10px'}}>

    <div style={{width:'100%', height:'100%'}}>

    <div style={{display:'flex', justifyContent:'center', alignItems:'start', flexDirection:'column'}}>

      <div style={

        matchesL ?

        {position:'', backgroundColor:'', top:'0px', transform: 'scale(1)'}

        : 

        {position:'', backgroundColor:'', top:'0px', transform:'scale(0.75)', marginLeft:'-35px', marginTop:'-15px'}

      }>

        <div style={{width:'100%', height:'80px', width:'80px', backgroundColor:'', display:'flex', justifyContent:'start', marginBottom:'20px'}}>
            <IconButton
                  onClick={() => {
                    setCurrentApp('market')
                  }}

                  style={{marginLeft:'5px', cursor:'none'}}
                  
                 >
                  <ArrowBackIcon sx={{fontSize: "60px", color: "#b7ffc1df"}} />

            </IconButton>

          </div>

        <PageTitle />

        {/*<div className="nonSelectable" style={{color:'white'}}>
          *linked to original NFTs
        </div>*/}

      </div>

      </div>

      </div>

      </div>




      <div style={{position:'absolute', top:'250px', width:'550px', 
                   height:'500px', backgroundColor:'',
                   zIndex: '100', overflow:'auto',
                   marginLeft:'10px'
                 }}>

         <div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>


         <div style={{display:'flex', justifyContent:'center', 
                      alignItems:'end', backgroundColor: '',
                      marginLeft: '80px'
                    }}>

          {/*
          <div style={{marginLeft:'0px'}}>
              <ThemeProvider theme={theme}>
                <SourceTabs 
                  sourceValue={sourceValue}
                  setSourceValue={setSourceValue}
                />
              </ThemeProvider>
            </div>
            */}

          </div>
           
           
          <div style={{display:'flex', justifyContent:'center'}}>
           
           <ThemeProvider theme={theme}>
             <AttributeTabs 
              attributeValue={attributeValue} 
              setAttributeValue={setAttributeValue} 
            />
           </ThemeProvider>
          


        <div style={{
                        width: '450px', 
                        height:'640px', backgroundColor:'',
                        borderTopRightRadius: '15px', borderBottomRightRadius: '15px',
                        
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)',
                        //background: `linear-gradient(180deg, rgba(227, 227, 227, 0.16) 0%, rgba(217, 217, 217, 0) 100%)`,
                        
                        boxShadow: `inset 1px 8px 10px rgba(255, 255, 255, 0.04)`,
                        backdropFilter: 'blur(5px)'
                      }}>
          
          <div style={{width:'100%', height:'50px', backgroundColor:'', display:'flex', 
                      justifyContent:'center', alignItems:'end', marginTop:'5px',
                      marginTop:'15px', fontFamily:'ShortStack'
        }}>

          {sourceValue === 'market' ?

               <MarketFilters 
                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery} 
                 searchVal={searchVal} 
                 setSearchVal={setSearchVal}
                 makeSearch={makeSearch} 
                 orderFilters={orderFilters}
                 orderDropdownOpen={orderDropdownOpen} 
                 setOrderDropdownOpen={setOrderDropdownOpen}
                 sortOrder={sortOrder} 
                 setSortOrder={setSortOrder} 
                 displayLongValue={displayLongValue}
                 isSearching={isSearching} 
                 sourceValue={sourceValue}
                 attributeValue={attributeValue}
               />

              : 

                <HoldingFilters 
                  listedOnly={listedOnly} 
                  setListedOnly={setListedOnly} 
                  notListedOnly={notListedOnly} 
                  setNotListedOnly={setNotListedOnly} 
                />
             

            }

            </div>



          <div style={{display:'flex', flexDirection:'column', backgroundColor:'', marginTop:'-5px', overflow:''}}>
        
            <Divider />

            <div style={{transform:'scale(0.7)', display:'flex', marginTop:'-80px', height:'500px', overflow:'auto'}}>

              <DisplayAssets 
                marketAssets={marketAssets} 
                selected={selected} 
                setSelected={setSelected} 
                setMeta={setMeta} 
                setAssetToBuy={setAssetToBuy} 
                isFetching={isFetching} 
                meta={meta}
                loader={loader} 
                sourceValue={sourceValue} 
                attributeValue={attributeValue}
              />

            </div>

        </div>
        </div>

        </div>
        </div>
        </div>

      </>


    :


    <div className="Outer" 
      style={matchesXL ?

        {backgroundColor: '', width:'850px', overflow:'auto'}

        : matchesL ?

        {backgroundColor:'', width:'700px', overflow:'auto'}

        : matchesM ?

        {backgroundColor:'', width:'500px', overflow:'auto'}

        : 

        {backgroundColor:'', width:'100%', overflow:'auto'}

      }
    >

    <div style={{width:'100%', height:'100%'}}>

    <div style={{display:'flex', justifyContent:'center', alignItems:'start', flexDirection:'column'}}>

      <div style={

        matchesL ?

        {position:'', backgroundColor:'', top:'0px', transform: 'scale(1)'}

        : 

        {position:'', backgroundColor:'', top:'0px', transform:'scale(0.75)', marginLeft:'-35px', marginTop:'-15px'}

      }>

        <div style={{width:'100%', height:'80px', width:'80px', backgroundColor:'', display:'flex', justifyContent:'start', marginBottom:'20px'}}>
            
            <IconButton
                  onClick={() => {
                    setCurrentApp('market')
                  }}

                  style={{marginLeft:'5px', cursor:'none'}}
                  
                 >
                  <ArrowBackIcon sx={{fontSize: "60px", color: "#b7ffc1df"}} />

            </IconButton>

          </div>

        <PageTitle />

        {/*<div className="nonSelectable" style={{color:'white'}}>
          *linked to original NFTs
        </div>*/}

      </div>


      <div style={{width:'100%', backgroundColor:'', display:'flex', 
                  transform: !matchesL ? 'scale(0.75)' : 'scale(1)',
                  marginTop: !matchesL ? '-20px' : '0px',
                  flexDirection:'column', justifyContent:'center', height:'100%'}}>

        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', width:'100%'}}>

          <div style={{width:'120px', height:'100px', backgroundColor:''}}>
            {/*offset*/}
          </div>

           <div style={{width:'500px', height:'100px', backgroundColor:'',
                        display:'flex', justifyContent:'center', alignItems:'end'}}>

            <div style={{height:'80px', width:'245px', backgroundColor:'', 
                        display:'flex', justifyContent:'center', alignItems:'end'}}>
              
              {/*<ThemeProvider theme={theme}>

                <SourceTabs 
                  sourceValue={sourceValue}
                  setSourceValue={setSourceValue}
                />

              </ThemeProvider>*/}

            </div>
          </div>

        </div>


        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', width:'100%'}}>

          <div style={{width:'120px', height:'400px', backgroundColor:'', display:'flex', justifyContent:'end'}}>
           
           <ThemeProvider theme={theme}>
           <AttributeTabs 
            attributeValue={attributeValue} 
            setAttributeValue={setAttributeValue} 
          />
           </ThemeProvider>
          </div>

           <div style={{width: matchesXL ? '625px' : '500px', 
                        height:'600px', backgroundColor:'',
                        borderTopRightRadius: '15px', borderBottomRightRadius: '15px',

                        //background: `linear-gradient(180deg, rgba(0, 227, 227, 0.16) 0%, rgba(0, 217, 217, 0) 100%)`,
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36)',

                        boxShadow: `inset 1px 8px 10px rgba(255, 255, 255, 0.04)`,
                        backdropFilter: 'blur(5px)'}}>

           <div style={{borderTopRightRadius: '15px', borderBottomRightRadius: '15px', 
                        width: matchesXL ? '625px' : '500px', 
                        height:'600px', backgroundColor:''}}>


            <div style={{width:'100%', height:'75px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'end'}}>

             {sourceValue === 'market' ?
              
               <MarketFilters 
                 searchQuery={searchQuery}
                 setSearchQuery={setSearchQuery} 
                 searchVal={searchVal} 
                 setSearchVal={setSearchVal}
                 makeSearch={makeSearch} 
                 orderFilters={orderFilters}
                 orderDropdownOpen={orderDropdownOpen} 
                 setOrderDropdownOpen={setOrderDropdownOpen}
                 sortOrder={sortOrder} 
                 setSortOrder={setSortOrder} 
                 displayLongValue={displayLongValue}
                 isSearching={isSearching} 
                 sourceValue={sourceValue}
                 attributeValue={attributeValue}
               />
              
              : 

              <HoldingFilters 
                listedOnly={listedOnly} 
                setListedOnly={setListedOnly} 
                notListedOnly={notListedOnly} 
                setNotListedOnly={setNotListedOnly} 
              />

            }

            </div>

             
            <Divider />

            <DisplayAssets 
              marketAssets={marketAssets} 
              selected={selected} 
              setSelected={setSelected} 
              setMeta={setMeta} 
              setAssetToBuy={setAssetToBuy} 
              isFetching={isFetching} 
              meta={meta}
              loader={loader} 
              sourceValue={sourceValue} 
              attributeValue={attributeValue}
            />

             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


}

  



     <div style={{
  position:'absolute', zIndex:'1000',
  top: '10px', right:'50px',
  transform: matchesL ? 'scale(1)' : matchesM ? 'scale(0.8) translateX(25%)' : 'scale(0.8) translateX(25%)',
  backgroundColor:'blue'

}}>

  <div className="nonSelectable" style={{width:'220px', height:'100px',
              zIndex:'1001', position:'absolute',
              left:'-220px'
              }}>


    <Connect  
      setCurActiveWallet={setCurActiveWallet} 
      assetsOwned={assetsOwned} 
      setIsWalletLoading={setIsWalletLoading} 
    />


  </div>

  <div className="nonSelectable" style={{position:'absolute', 
               zIndex:'100', right: '10px', 
               top: '100px'}}>

    <input 
      //className={"nickname-input" + `${wrongNickname ? ' wrong' : ''}`}
      className={'nicknametextinput' +  `${wrongNickname ? ' wrong' : ''}`}
      type="text" 
      name="name"  
      placeholder="TYPE NAME" 

      value={chosenNickname}

      onChange={(event) => {
        setWrongNickname(false)
        let temp_nickname = event.target.value.slice(0,10)
        setChosenNickname(temp_nickname)
        setNickname(temp_nickname)
      }}

      onKeyPress={(event) => {
        if (event.key=== 'Enter'){
          event.preventDefault()
          setNickname(chosenNickname)
        }
      }}
    />
   
  </div>




  <div className="nonSelectable" style={{position:'absolute', 
               zIndex:'100', right: '10px', 
               top: '180px'}}>
    
    <VoiceType 
      voiceType={voiceType} 
      setVoiceType={setVoiceType} 
    />

  </div>

</div>









    {matchesM ?

      <button className={!startBlocked ? "buy-button" : "buy-button-blocked"}

      
        onMouseDown={() => {
          // console.log('mouse down')

           if ( chosenNickname !== '' && typeof(meta) != 'undefined' && 'serial' in meta && 'model' in meta && 'image' in meta){
    
             setIsPressed(true);
             setPressBuy(Date.now());
          }else{
            if (chosenNickname === ''){
                setWrongNickname(true)
              }
          }
        }}

        onMouseUp={() => {
          // console.log('mouse up')

          setIsPressed(false);
          const duration = Date.now() - pressBuy;

          // console.log(duration)


          if (duration > 1400 || true){
             setReadyStart(true)

             if (chosenNickname !== '' && typeof(meta) != 'undefined' && 'serial' in meta && 'model' in meta && 'image' in meta){
              // console.log('ALL GOOD')
              setAvatarMeta(meta)
              updateCookiesNickname(chosenNickname)
              // console.log(activeScene)
              setActiveScene('StoreScene')
            }else{
              if (chosenNickname === ''){
                setWrongNickname(true)
              }
            }
          }

        }}
        
        style={{right: matchesL? '50px' : '20px', 
                textShadow: '0px 0px 2px rgba(255, 255, 255, 0.43)',
                transform: matchesL ? 'scale(1)' : matchesM ? 'scale(0.9) translateX(-5%)' : 'scale(0.8)',
                bottom: matchesM ? '' : '300px',
                backdropFilter:'blur(5px)'

              }}
        >

          <div>
            <div

              onMouseEnter={() => {
                // console.log('Mouse Enter')
                //cursorChangeHandler("hovered")
              }}
              onMouseLeave={() => {//cursorChangeHandler("")
              }
            }

            >
             START
            </div>

            
          </div>
      </button>

    :null}

    




        {/*{sellAsset ?
          <SellPopUp curActiveWallet={curActiveWallet} setSellAsset={setSellAsset} sellAsset={sellAsset}  setIsAlertOpen={setIsAlertOpen} setSubmittedTx={setSubmittedTx} /> 
              : null
        }*/}

      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />

</>

    );

}

export default UIAvatarSelect;