import { OrbitControls, Sparkles, Stars } from '@react-three/drei'
import { useLoader, useFrame, useThree } from '@react-three/fiber'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { Html, ContactShadows, Float, Environment, useGLTF,  PresentationControls, Sky, Cloud } from '@react-three/drei'
import Plinth from './Plinth.jsx'
import { useEffect, useState, useRef, Suspense } from 'react'

import Sun from '../../Sun/Sun'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import DisplayAvatar from '../../Avatar/DisplayAvatar'


export default function Experience(props){

  const isLoaded = props.isLoaded
  const setIsLoaded = props.setIsLoaded

  const isVisible = props.isVisible

  const meta = props.meta
  //const meta = props.meta
  const [animationDict, setAnimationDict] = useState({})
  const [mixer, setMixer] = useState()
  const [animations, setAnimations] = useState()
  const groupRef = useRef()

  const [needsUpdate, setNeedsUpdate] = useState(false)

  const theme = useTheme();

  const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesM = useMediaQuery(theme.breakpoints.up('md'));
  const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

  const anim_path = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/CN/animations/glb/'

   const animationPaths = [

    {
      name: "Idle",
      url: anim_path + "Idle.glb"
    },

    {
      name: "Walk",
      url: anim_path + "Walking.glb"
    },

    {
      name: "Run",
      url: anim_path + "Running.glb"
    },

    {
      name: "Jump",
      url: anim_path + "Jumping.glb"
    },

    {
      name: "Fall",
      url: anim_path + "Falling.glb"
    }
   
  ] 

  const {
    gl, 
    scene,                       
    camera,                       
  } = useThree();

 
  useEffect(() => {

    const w = window.innerWidth;
    const h = window.innerHeight;
    const fullWidth = w * 1;
    const fullHeight = h * 1;


    if (!matchesM){
      camera.setViewOffset( fullWidth, fullHeight, 0 * w,  0.1 * h, w, h );
    }else{
      camera.setViewOffset( fullWidth, fullHeight, -0.2 * w,  0.035 * h, w, h );      
    }


    camera.position.x = 0
    camera.position.y = 1.5
    camera.position.z = 5
    

    // if (matchesXL){
    //   camera.setViewOffset( fullWidth, fullHeight, -0.2 * w,  0.025 * h, w, h );
    // }

    // else if (matchesL){
    //   camera.setViewOffset( fullWidth, fullHeight, 0 * w, 0.125 * h, w, h );
    // }

    // else if (matchesM){
    //   camera.setViewOffset( fullWidth, fullHeight, 0 * w, 0.125 * h, w, h );
    // }

    // else if (matchesS){
    //   camera.setViewOffset( fullWidth, fullHeight, 0 * w, 0.125 * h, w, h );

    // }else{
    //   camera.setViewOffset( fullWidth, fullHeight, -0.225*w, 0.025 * h, w, h );
    // }

    
   
    
       
  },[matchesM])
  

  useFrame((state,delta) => {
    mixer?.update(delta)
  })


useEffect(() => {

  console.log('changed meta')
  console.log(meta)

},[meta])



 return( 

    <>  

    
    {typeof(meta) === 'object' && 'model' in meta ?

    <group visible={isVisible}>

     <DisplayAvatar 

          identifier={'player'}

          meta={meta}
          clayColor={meta.clayColor}
          
          groupRef={groupRef} 
          animationDict={animationDict} 
          setAnimationDict={setAnimationDict} 

          mixer={mixer}
          setMixer={setMixer}

          setAnimations={setAnimations}

          needsUpdate={needsUpdate}
          setNeedsUpdate={setNeedsUpdate}

          animationPaths={animationPaths}
          
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}

    />

    </group>

    :null}


    </>
    )
}