import {useReducer, useState, createContext, useEffect} from 'react'
import Wallet from './Wallet'
import EmptyWallet from './EmptyWallet'
import Activity from './Activity'
import Connect from './Connect'
import SideBar from './SideBar'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';

import AppBar from '@mui/material/AppBar';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';



import './CnButton.css'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Leaderboard from './Leaderboard'

import Analytics from './Analytics'

import Staking from './Staking'

import Balance from './Balance'

import Switch from '@mui/material/Switch';

import VotePopUp from './VotePopUp'

import UnbakePopUp from './UnbakePopUp'

import AlertPopUp from './AlertPopUp'

import Loading from './Loading'

import StakingClaim from './StakingClaim'

import './ViewSwitch.css'

import './SideBar.css'

import './Analytics.css'





const theme = createTheme({
  typography: {
    fontFamily: [
      'shortstack',
      'Roboto',
      'sans-serif'
    ].join(','),
  }
});



const initialState = {
    activeWallet: '',
    address: '',
    stake_address: '',
    lovelace: null,
    tokens: [],
    selectedTokens: [],
    isVoteOpen: false,
    voteOption: null,
    voteProposal: null,
}


const actions = {
    SET_ACTIVE_WALLET: "SET_ACTIVE_WALLET",
    SET_ADDRESS: "SET_ADDRESS",
    SET_STAKE_ADDRESS: "SET_STAKE_ADDRESS",
    SET_LOVELACE: "SET_LOVELACE",
    SET_TOKENS: "SET_TOKENS",
    SET_SELECTED_TOKENS: "SET_SELECTED_TOKENS",
    SET_IS_VOTE_OPEN: "SET_IS_VOTE_OPEN",
    SET_VOTE_OPTION: "SET_VOTE_OPTION",
    SET_VOTE_PROPOSAL: "SET_VOTE_PROPOSAL",
    RESET: "RESET"
}

function reducer(state, action){
    switch (action.type){
        case actions.SET_ACTIVE_WALLET:
            return {...state, activeWallet: action.value};        
        case actions.SET_ADDRESS:
            return {...state, address: action.value};
        case actions.SET_STAKE_ADDRESS:
            return {...state, stake_address: action.value};
        case actions.SET_LOVELACE:
            return {...state, lovelace: action.value};
        case actions.SET_TOKENS:
            return {...state, tokens: action.value};
        case actions.SET_SELECTED_TOKENS:
            return {...state, selectedTokens: action.value};
        case actions.SET_IS_VOTE_OPEN:
            return {...state, isVoteOpen: action.value};
        case actions.SET_VOTE_OPTION:
            return {...state, voteOption: action.value};
        case actions.SET_VOTE_PROPOSAL:
            return {...state, voteProposal: action.value};
        case actions.RESET:
            return {...state, ...initialState}
        default:
            return state;
    }
}

export const MainClaimContext = createContext()

function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {
        activeWallet: state.activeWallet,
        address: state.address,
        stake_address: state.stake_address,
        lovelace: state.lovelace,
        tokens: state.tokens,
        selectedTokens: state.selectedTokens,
        isVoteOpen: state.isVoteOpen,
        voteOption: state.voteOption,
        voteProposal: state.voteProposal,
        
        setActiveWallet: value => {
            dispatch({type: actions.SET_ACTIVE_WALLET, value})
        },
        setAddress: value => {
            dispatch({type: actions.SET_ADDRESS, value})
        },
        setStakeAddress: value => {
            dispatch({type: actions.SET_STAKE_ADDRESS, value})
        },
        setLovelace: value => {
            dispatch({type: actions.SET_LOVELACE, value})
        },
        setTokens: value => {
            dispatch({type: actions.SET_TOKENS, value})
        },
        setSelectedTokens: value => {
            dispatch({type: actions.SET_SELECTED_TOKENS, value})
        },
        setIsVoteOpen: value => {
            dispatch({type: actions.SET_IS_VOTE_OPEN, value})
        },
        setVoteOption: value => {
            dispatch({type: actions.SET_VOTE_OPTION, value})
        },
        setVoteProposal: value => {
            dispatch({type: actions.SET_VOTE_PROPOSAL, value})
        },
        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainClaimContext.Provider value={value}>
            {children}
        </MainClaimContext.Provider>
        )
}


export default function ClaimContext(){

    const [txsBaking, setTxsBaking] = useState(0)
    const [txsCompleted, setTxsCompleted] = useState(0)

    const [totalBaked, setTotalBaked] = useState(0)
    const [totalUnbaked, setTotalUnbaked] = useState("0")
    const [formattedClayAmount, setFormattedClayAmount] = useState()
    const [stakeADAAmount, setStakeADAAmount] = useState()
    const [stakedWithClay, setStakedWithClay] = useState()


    const [sideBarTab, setSideBarTab] = useState()

    const [isConnected, setIsConnected] = useState(false)

    const [isAccountView, setIsAccountView] = useState(false)

    const [assetsOwned, setAssetsOwned] = useState()

    const [currentSlot, setCurrentSlot] = useState()

    const [isSideBarOpen, setIsSideBarOpen] = useState(true)

    const [sideBarVariant, setSideBarVariant] = useState()

    const [searchBarMode, setSearchBarMode] = useState(false)

    const [leaderboardPage, setLeaderboardPage] = useState(1)

    const [assetsLoaded, setAssetsLoaded] = useState(false)

     const [isAlertOpen, setIsAlertOpen] = useState(false)
     const [submittedTx, setSubmittedTx] = useState('')

     const [hasBaking, setHasBaking] = useState(false)

    const theme = useTheme();

    const [isWalletLoading, setIsWalletLoading] = useState(false)

    const matchesXS = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesS = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesM = useMediaQuery(theme.breakpoints.up('md'));
    const matchesL = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));

    const [leaderboardData, setLeaderboardData] = useState([])

    const [recordsPage, setRecordsPage] = useState(1)

    const [fetchingFilteredAssets, setFetchingFilteredAssets] = useState(true)


    const [isAnalytics, setIsAnalytics] = useState(false)



    const [claimStake, setClaimStake] = useState({})







    useEffect(() => {
        if (isSideBarOpen && !matchesM){
            setIsSideBarOpen(false)
        }

    }, [matchesM])


      async function updateView(viewName){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('view') + " = " + String(viewName) + '; ' + expires + "; path=/"
  }




    function checkView(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }

       return cookies_dict['view']
  }



  useEffect(() => {

        setTimeout(function checkViewInCookies(){
     
            let currentView = checkView()

            //console.log('CURRENT View')

            //console.log(currentView)

            if (typeof(currentView) == 'string'){
                    
                //console.log(currentView == 'accountView')

                setIsAccountView(currentView == 'accountView')
                
            }

          }, 100)


  },[])



    return(
        <Provider>

        <Balance assetsLoaded={assetsLoaded} setAssetsLoaded={setAssetsLoaded} assetsOwned = {assetsOwned} setAssetsOwned = {setAssetsOwned} currentSlot = {currentSlot} setCurrentSlot = {setCurrentSlot} isCompact={isSideBarOpen} isWalletLoading={isWalletLoading} /> 
    
        <Grid container style={{position:'absolute', overflow:'auto'}} >

          <Grid item xs={12}>




            {matchesXS ?
            <AppBar style={{backgroundColor:'transparent', boxShadow:'transparent',boxShadow: 'none', backdropFilter:'blur(1px)'}}>

             <Stack direction="row" spacing={2} style={{display:'flex', justifyContent:'flex-end', backgroundColor:'transparent'}}>


                <div className='explorerToggle' onClick={()=>{

                     //console.log('View changed')
                            if (isAccountView){
                                updateView('explorerView')
                                setSearchBarMode(false)
                            }else{
                                updateView('accountView')
                            }
                            setIsAccountView(!isAccountView)

                }}>

                  {isAccountView ? 
                  <div className='notch2'>

                  <img className='accountImg' src='/account.png' />

                  </div>
                  :

                   <div className='notch'>

                  <img className='explorerImg' src='/explorer.png' />

                  </div>

              }

                    
             </div>


               
                <div style={{marginRight:'25px'}}>
                    <Connect assetsOwned={assetsOwned} setIsWalletLoading={setIsWalletLoading} />
                </div>

            </Stack>
              
            </AppBar>


            : 



           <AppBar style={{backgroundColor:'transparent', boxShadow:'transparent',boxShadow: 'none', backdropFilter:'blur(1px)', display:'flex', justifyContent:'center', alignItems:'flex-end'}}>

             <Stack direction="column" spacing={2} style={{display:'flex', justifyContent:'flex-center', backgroundColor:'transparent'}}>


                <div className='explorerToggle' onClick={()=>{

                     //console.log('View changed')
                            if (isAccountView){
                                updateView('explorerView')
                                setSearchBarMode(false)
                            }else{
                                updateView('accountView')
                            }
                            setIsAccountView(!isAccountView)

                }}>

                  {isAccountView ? 
                  <div className='notch2'>

                  <img className='accountImg' src='/account.png' />

                  </div>
                  :

                   <div className='notch'>

                  <img className='explorerImg' src='/explorer.png' />

                  </div>

              }
   
             </div>


               
                <div style={{marginRight:'25px'}}>
                    <Connect matchesS={matchesS} assetsOwned={assetsOwned} setIsWalletLoading={setIsWalletLoading} pushDown={true} />
                </div>

            </Stack>
              
            </AppBar>



        }


           
          </Grid>

          <Grid container justifyContent='center' alignItems='center' style={{height:'100vh'}} >



         {isAccountView && (isWalletLoading || !assetsLoaded) && sideBarTab  == 'Baking' || isAccountView && (isWalletLoading || !assetsLoaded) && sideBarTab == 'Activity' ? 


                <Grid container justifyContent='center' alignItems='center' style={{height:'70%'}}>
                <Grid item sm={isSideBarOpen ? 5 : 0} md={isSideBarOpen ? 3 : 1} lg={2} >
                      
                  </Grid>

                     <Grid item xs={6} sm={isSideBarOpen ? 5 : 9} md={isSideBarOpen ? 7 : 10} lg={8} xl={9} style={{display:'flex', justifyContent:'center', alignItems:'center', minWidth: matchesS && !isSideBarOpen ? '550px' : '220px'}}>
                   
                        <div>
                            <Loading matchesS={matchesS} />
                    </div>
            </Grid>

            </Grid>


            :



            sideBarTab && isAccountView  && sideBarTab == 'Baking' ?

          <Grid container justifyContent='center' alignItems='center' style={{height:'70%'}}>
        <Grid item sm={isSideBarOpen ? 5 : 0} md={isSideBarOpen ? 3 : 1} lg={2} >
              
          </Grid>

             <Grid item xs={6} sm={isSideBarOpen ? 5 : 9} md={isSideBarOpen ? 7 : 10} lg={7} xl={9} style={{display:'flex', justifyContent:'center', alignItems:'center', minWidth: matchesS && !isSideBarOpen ? '550px' : '220px'}}>
                
           
                <div>
                    <Wallet setHasBaking={setHasBaking} assetsOwned = {assetsOwned} setAssetsOwned = {setAssetsOwned} currentSlot = {currentSlot} setCurrentSlot = {setCurrentSlot} isCompact={isSideBarOpen} isWalletLoading={isWalletLoading} setIsWalletLoading={setIsWalletLoading} /> 
            </div>
    </Grid>

    </Grid>

        

    : sideBarTab && isAccountView  && sideBarTab  == 'Activity' ? 

     <Grid container justifyContent='center' alignItems='center' style={{height:'100%'}}>
     
      <Grid item xs={0} sm={isSideBarOpen ? 5 : 0} md={isSideBarOpen ? 4 : 0} lg={2} xl={0} >
      </Grid>

         <Grid item xs={11} sm={isSideBarOpen ? 6 : 9} md={isSideBarOpen ? 7 : 10} lg={7} xl={8} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
           
           
            <Activity setTxsBaking={setTxsBaking} setTxsCompleted={setTxsCompleted} setTotalBaked={setTotalBaked} />
          
    </Grid>
    </Grid>

            : sideBarTab && isAccountView && sideBarTab  == 'Staking' ? 


                    <Grid container justifyContent='center' alignItems='center' style={{height:'100%'}}>


                        <Grid item xs={0} sm={isSideBarOpen ? 4 : 0} md={isSideBarOpen ? 3 : 0} lg={3} xl={2} >
                        </Grid>

                        <Grid item xs={9} sm={isSideBarOpen ? 6 : 9} md={isSideBarOpen ? 7 : 10} lg={8} xl={8}>
                            <Staking claimStake={claimStake} setClaimStake={setClaimStake} currentSlot={currentSlot} isSideBarOpen={isSideBarOpen} matchesM={matchesM} matchesS={matchesS} assetsOwned = {assetsOwned}  stakedWithClay={stakedWithClay} stakeADAAmount={stakeADAAmount} formattedClayAmount={formattedClayAmount} totalUnbaked={totalUnbaked} />
                        </Grid>
                    </Grid>
            

            : !isAccountView ? 

            <div style={{width:'100%'}}>

            {isAnalytics ? null :
           
            <div style={{position:'relative', marginBottom: matchesL || (matchesM && !isSideBarOpen)  ? '0px' : '70px'}}>
                    <div style={{position:'absolute', display:'flex',justifyContent:'flex-end', marginTop:'50px', width:'100%'}}>
                        <img src="/leaderboard.png" className="leaderboard-img" style={{width: !matchesM ? '100px' : matchesXL ? '320px' : matchesL || !isSideBarOpen  ? '300px' : '100px', marginRight:  !matchesM ? '120px' :  matchesM && !isSideBarOpen || matchesL ? '60px' :'120px'}} />
                    </div>


                    


            </div>
                    
            }





            <Grid container justifyContent='center' alignItems='start' style={{height:'70%'}}>


                
                <Grid item xs={0} sm={isSideBarOpen ? 4 : 0} md={isSideBarOpen ? 3 : 0} lg={2}>

                </Grid>    


                <Grid item xs={10} sm={isSideBarOpen ? 7 : 10} md={ isSideBarOpen ? 7 : 10} lg={7} >


                    {isAnalytics ? 

                        <Analytics matchesM={matchesM} matchesS={matchesS} matchesXL={matchesXL} isAnalytics={isAnalytics} setIsAnalytics={setIsAnalytics} />

                    : 

                    <Leaderboard matchesXL={matchesXL} isSideBarOpen={isSideBarOpen} isAnalytics={isAnalytics} setIsAnalytics={setIsAnalytics} matchesS={matchesS} fetchingFilteredAssets={fetchingFilteredAssets} setFetchingFilteredAssets={setFetchingFilteredAssets} searchBarMode={searchBarMode} setSearchBarMode={setSearchBarMode} setLeaderboardData={setLeaderboardData} leaderboardData={leaderboardData} currentSlot={currentSlot} leaderboardPage={leaderboardPage} setLeaderboardPage={setLeaderboardPage} recordsPage={recordsPage} setRecordsPage={setRecordsPage} /> 
                    }

                </Grid>

            </Grid>

            </div>

            : null
        }
           

          </Grid>



           <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />

           <div className="hideScrollBar">
            <SideBar stakedWithClay={stakedWithClay} setStakedWithClay={setStakedWithClay} stakeADAAmount={stakeADAAmount} setStakeADAAmount={setStakeADAAmount} totalUnbaked={totalUnbaked} setTotalUnbaked={setTotalUnbaked} formattedClayAmount={formattedClayAmount} setFormattedClayAmount={setFormattedClayAmount} isAnalytics={isAnalytics} hasBaking={hasBaking} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} fetchingFilteredAssets={fetchingFilteredAssets} setFetchingFilteredAssets={setFetchingFilteredAssets} setSearchBarMode={setSearchBarMode} searchBarMode={searchBarMode} setCurrentSlot={setCurrentSlot} isAccountView={isAccountView} setLeaderboardData={setLeaderboardData} txsBaking={txsBaking} txsCompleted={txsCompleted} totalBaked={totalBaked} isAccountView={isAccountView} matchesXS={matchesXS} matchesS={matchesS} matchesM={matchesM} matchesL={matchesL} isOpen={isSideBarOpen} setIsOpen={setIsSideBarOpen} sideBarTab={sideBarTab} setSideBarTab={setSideBarTab}  assetsOwned={assetsOwned} currentSlot={currentSlot} leaderboardPage={leaderboardPage} setLeaderboardPage={setLeaderboardPage} recordsPage={recordsPage} setRecordsPage={setRecordsPage} />
          </div>

        </Grid>



        {!matchesL ?
        <Grid container style={{position:'sticky', top:'90%', width:'25vw'}} >
            <Grid item xs={1}>
            </Grid>

            <Grid item xs={11} sm={8} md={8} style={{display:'flex', justifyContent:'center'}}>
                <button className='CN-btn' onClick={()=>{setIsSideBarOpen(true)}}><span className="CN-text" style={{fontFamily:'shortstack'}}>></span></button>
            </Grid>

        </Grid>

        : null}

        <Grid container justifyContent='center' alignItems='center' style={{height:'70%'}}>
          <VotePopUp />


          <AlertPopUp isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen} submittedTx={submittedTx} setSubmittedTx={setSubmittedTx} />


          <StakingClaim claimStake={claimStake} setClaimStake={setClaimStake} />

        </Grid>



        </Provider>

        )
}