import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box, useProgress, MeshReflectorMaterial } from '@react-three/drei'
import CameraController from './Controllers/CameraController'
import Lights from './Lights/Lights'
import SimpleController from './AvatarController/SimpleController'




import {Perf} from 'r3f-perf'
import { Debug, Physics, RigidBody, BallCollider, CapsuleCollider, CuboidCollider, useRapier } from '@react-three/rapier'
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette, GodRays, ToneMapping, SSAO, SMAA, Outline, SSR, BrightnessContrast, HueSaturation } from '@react-three/postprocessing'
import { BlurPass, Resizer, KernelSize, BlendFunction } from 'postprocessing'
import * as THREE from "three";


import Fog from './Fog/Fog'
import Sun from './Sun/Sun'
import Avatar from './Avatar/DisplayPairs'
import AudioListener from './Sound/AudioListener'


// import WorldHandler from './World/WorldHandler'
// import WorldLoader from './World/WorldLoader'


import HDRLoader from './Loaders/HDRLoader'
import RemotePlayers from './RemotePlayers/RemotePlayers'
import Audio from './Audio'
import LoadingDisplay from './LoadingDisplay/LoadingDisplay'


import Clouds from './Clouds/Clouds'
import Atmosphere from './Atmosphere/Atmosphere'

import WorldHandler from './World/WorldHandler'

import CloudDome from './Sky/CloudDome'

import {MainQualityContext} from 'components/Context/QualityContext'

function Effects(props){

  const {quality} = useContext(MainQualityContext)
  const [effectsQuality, setEffectsQuality] = useState(quality)

  const {sunRef} = props
  const cubeRef = useRef()
  const [outlineSelection, setOutlineSelection] = useState([])

  useEffect(() => {

    if (typeof(cubeRef) !== 'undefined' && typeof(cubeRef.current) !== 'undefined'){
      setOutlineSelection((prev) => [...prev, cubeRef])
    }

  },[cubeRef])


  useEffect(() => {

    setEffectsQuality(quality)

  },[quality])


  return(
    <>

   
    

    <mesh visible={false} ref={cubeRef} position={[0,2,0]} onClick={() => {console.log('Cube click')}}>
      <boxGeometry args={[1,1,1]} />
      <meshStandardMaterial color="black" />
    </mesh>


    
    <EffectComposer autoClear={false} multisampling={8}>
       
      {/* <DepthOfField 
                focusDistance={0.01} 
                focalLength={0.4} 
                bokehScale={2} 
              />*/}

   {typeof(sunRef.current) != 'undefined' ?


       <GodRays
          sun={sunRef.current}
          blendFunction={BlendFunction.Screen}
          samples={50}
          density={0.98}
          decay={0.95}
          weight={0.94}
          exposure={0.5}
          clampMax={0.8}
          width={Resizer.AUTO_SIZE}
          height={Resizer.AUTO_SIZE}
          kernelSize={KernelSize.SMALL}
          blur={true}
        />
        :null}
        
     
       

      {/*  <BrightnessContrast
          brightness={0} // brightness. min: -1, max: 1
          contrast={0.05} // contrast: min -1, max: 1
        />
*/}
        {effectsQuality !== 'Low' ?
        <HueSaturation
          blendFunction={BlendFunction.NORMAL} // blend mode
          hue={0.0} // hue in radians
          saturation={0.05} // saturation in radians
        />
        : null}


        {effectsQuality === 'High' ?
          <SMAA />
        :null}



        {effectsQuality !== 'Low' ?
       <Bloom
          intensity={0.05} // The bloom intensity.
          blurPass={undefined} // A blur pass.
          width={Resizer.AUTO_SIZE} // render width
          height={Resizer.AUTO_SIZE} // render height
          kernelSize={KernelSize.LARGE} // blur kernel size
          luminanceThreshold={0.9} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.5} // smoothness of the luminance threshold. Range is [0, 1]
        />
       
        : null}

        


        {/*<SSAO
          blendFunction={BlendFunction.MULTIPLY} // blend mode
          samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
          rings={4} // amount of rings in the occlusion sampling pattern
          distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
          distanceFalloff={0.0} // distance falloff. min: 0, max: 1
          rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
          rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
          luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
          radius={20} // occlusion sampling radius
          scale={0.5} // scale of the ambient occlusion
          bias={0.5} // occlusion bias
        />*/}


        
        {outlineSelection && effectsQuality !== 'Low' ?
         <Outline
          selection={outlineSelection} // selection of objects that will be outlined
          selectionLayer={10} // selection layer
          blendFunction={BlendFunction.SCREEN} // set this to BlendFunction.ALPHA for dark outlines
          patternTexture={null} // a pattern texture
          edgeStrength={20} // the edge strength
          pulseSpeed={0.0} // a pulse speed. A value of zero disables the pulse effect
          visibleEdgeColor={0xe69816} // the color of visible edges
          hiddenEdgeColor={0xdb710e} // the color of hidden edges
          width={Resizer.AUTO_SIZE} // render width
          height={Resizer.AUTO_SIZE} // render height
          kernelSize={KernelSize.LARGE} // blur kernel size
          blur={true} // whether the outline should be blurred
          xRay={false} // indicates whether X-Ray outlines are enabled
        />

        : null}

     </EffectComposer>

    


     </>
  )
}


function LoadingDisplayComponent(props){

  const {readyPlayer, ready, readyProgress} = props

  return(
    <>
       { (!readyPlayer || !ready) ?
            <LoadingDisplay readyProgress={readyProgress} ready={ready} readyPlayer={readyPlayer} />
         : null}
    </>
  )
}



function World(props){

  const {initialPlayerPosition, initialPlayerRotation} = props


  const {quality} = useContext(MainQualityContext)


  const [screenPixelRatio, setScreenPixelRatio] = useState('Medium')


  const [initialPosition, setInitialPosition] = useState([16,10,11.5])
  const [initialRotation, setInitialRotation] = useState([0,0,2])

  const [controls, setControls] = useState();
  const sunRef = useRef()
  const [cameraTarget, setCameraTarget] = useState( {x:0, y:0, z:0} )
  const characterRigidRef = useRef()
  const [sunPosition, setSunPosition] = useState([10, 10, -50])
  const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/'
  const [ready, setReady] = useState(false)
  const [readyPlayer, setReadyPlayer] = useState(false)
  const [readyProgress, setReadyProgress] = useState(0)
  const [checkReady, setCheckReady] = useState(false)
  const [outlineSelection, setOutlineSelection] = useState([])

  const [testReady, setTestReady] = useState(false)

  const cubeRef = useRef()


  useEffect(() => {

    setScreenPixelRatio(quality)

  },[quality])



  useEffect(() => { 
    if (typeof(initialPlayerPosition) !== 'undefined'){
      setInitialPosition(initialPlayerPosition)
    }
  },[initialPlayerPosition])

  useEffect(() => {
    if (typeof(initialPlayerRotation) !== 'undefined'){
      setInitialRotation(initialPlayerRotation)
    }
  },[initialPlayerRotation])

  
  useEffect(() => {

    if (ready){

      const timer = setTimeout(() => {
        setCheckReady(true)
      }, 4250);
      return () => clearTimeout(timer);

    }

  },[ready])


  useEffect(() => {

    const timer = setTimeout(() => {
      setTestReady(true)
    }, 6250);
    return () => clearTimeout(timer);

  },[])


  return(
    <>
      <div style={{height:'100%', width:'100%'}}>

       
        <LoadingDisplayComponent readyProgress={readyProgress} ready={ready} readyPlayer={readyPlayer} />

         <video id={"video"} style={{display:"none"}} autoPlay playsInline></video>


         <Canvas 
          shadows
          camera={{ 
            fov: 50,
            near: 0.1,
            far: 250,
            position: initialPlayerPosition
          }}

         //  pixelratio={
         //   screenPixelRatio == 'Low' ?

         //   [0.5,1] :
         //   [1, 2]
         // }
        >

        {/*<Perf />*/}

       {/* <AudioListener />
        <Audio />*/}

        <AudioListener />

        <CameraController controls={controls} setControls={setControls} target={cameraTarget} enablePan={false} minZoom={3} maxZoom={5} />
        <Fog color={"#FFCBA4"} density={0.006} />
        <Lights playerRef={characterRigidRef} /> 



        <Sun sunRef={sunRef} position={sunPosition} size={5} /> 

        <Physics>

          

          <Atmosphere 
            effectController={{
              turbidity: 1,
              rayleigh: 0.16,
              mieCoefficient: 0.0216,
              mieDirectionalG: 0.018,
              sunSize: 0
            }}


            sunPosition={[15, 10, -80]} 

            skyColors={{
                 skyBottom: new THREE.Vector3(0.78, 0.66, 0.9),
                 skyTop: new THREE.Vector3(0.58, 0.72, 0.85)
            }}

          />


          <HDRLoader url={root_url+'https://claynation.nyc3.cdn.digitaloceanspaces.com/clayverse/TheStand/hdri/TheStand_Outside_Blurred.HDR'} hideBackground={true} />

          <WorldHandler characterRigidRef={characterRigidRef} setReady={setReady} setReadyProgress={setReadyProgress} />

          <CloudDome playerRef={characterRigidRef} />

          {/*<RigidBody type={"fixed"}>

            <mesh>
              <boxGeometry args={[1,1,1]} />
              <meshStandardMaterial color={"red"} />
            </mesh>

          </RigidBody>*/}


          {testReady ?

          <Suspense fallback={null}>

            <SimpleController 
              controls={controls} 
              rigidRef={characterRigidRef} 
              cameraTarget={cameraTarget} 
              setCameraTarget={setCameraTarget} 

              setReadyPlayer={setReadyPlayer}

              initialPosition={[17.25,2,4.5]}

              //initialPosition={initialPosition}

              initialRotation={initialRotation}
             
            />

          </Suspense>

          : null}




        {/* <RigidBody type={"fixed"}>
            <mesh position={[0,-2,0]} receiveShadow={true}>
              <boxGeometry args={[100,1,100]} />
              <meshStandardMaterial color={"limegreen"} />
            </mesh>
          </RigidBody>*/}
          


        <Suspense fallback={null}>  
          <RemotePlayers localRef={characterRigidRef} />
        </Suspense>


        </Physics>
            
          <Effects sunRef={sunRef} outlineSelection={outlineSelection} />
         
        </Canvas>

      </div>
    </>
  )
}
export default World;


