
import { useState, useEffect, useContext } from 'react';
import TransactionPopUp from './TransactionProgress';
import { motion } from "framer-motion";
import Countdown from "react-countdown";
import { SidePannel, H1, H2, H3, Line, CloseButton, VoxeliseButton, Row, Number, Separator } from './TransactionPannel.styled';
import { CompletedPannel, P1, P2, INFO, GIF, InfoWindow, Button } from "./CompletedPopup.styled";

import {MainClaimContext} from 'components/ClaimContext'

import cursorvoxelised from '../Images/cursor32.png';

import Mint from '../Pages/Mint'

const cn_policy = '40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728'

function TransactionPannel(props) {

    const {selectedNfts, setSelectedNfts, eligibleNfts, upgradeFee, setUpgradeFee, isAlertOpen, setIsAlertOpen, submittedTx, setSubmittedTx} = props

    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, reset} = useContext(MainClaimContext)

    // const [selectedNfts, setSelectedNfts] = useState([])
    // const [eligibleNfts, setEligibleNfts] = useState([])

    const [priceToUpgrade, setPriceToUpgrade] = useState(-1)

    const [transactionStatus, setTransactionStatus] = useState('')

    

    const [timeUntiPriceIncrease, setTimeUntiPriceIncrease] = useState(-1)

    const [buttonPopup, setButtonPopup] = useState(false);
    const Toggle = () => setButtonPopup(!buttonPopup);

    const Completionist = () => <span style={{color:'white'}}>Early bird access has ended!</span>;
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <H3>{days}d:{hours}h:{minutes}m:{seconds}s</H3>;
    }
  };


  useEffect(() => {
    
    let date_now = Date.now()/1000
    
    if (date_now < 1696348800){
        setPriceToUpgrade(85)
    }else{
        setPriceToUpgrade(120)
    }
    
  })


  useEffect(() => {
    //get total upgrade fee here
    if (priceToUpgrade > 0){
        setUpgradeFee(priceToUpgrade * selectedNfts.length)
    }
  },[priceToUpgrade, selectedNfts])



  return (props.trigger) ? (
    <SidePannel as={motion.div}
    transition={{ duration: 1 }}
    initial={{ x:-1000}}
    whileInView="visible"
    viewport={{ once: true }}
    animate={{ opacity:0.95, x:0 }}
    >   
    

    <div style={{height:'100%', width:'100%', backgroundColor:'', display:'flex', flexDirection:'column', justifyContent:'space-between', overflow:'scroll'}}>

    <div style={{display:'flex', flexDirection:'column', justifyContent:'start', backgroundColor:''}}>


        <H1>UPGRADE</H1>

        <CloseButton type="button" onClick={() => props.setTrigger(false)}>X</CloseButton> 


        <Line />
        <P2 style={{fontFamily:'ShortStack', fontSize:'15px'}}>view and use your VOX-CLAY avatar in  <a href="https://www.sandbox.game/en/me/avatar/avatars/my/" style={{color:'var(--clayblue-secondary)', textDecoration:'none', fontSize: 'clamp(1rem, 1.3vw, 3rem)', zIndex:'1000', cursor: `url(${cursorvoxelised}), auto`}}>The Sandbox</a>.</P2>


        <div>
        <Row>
        <H2 style={{fontSize:'20px'}}>ELIGIBLE NFTS</H2>
        <Number style={{fontSize:'20px'}}>{eligibleNfts.length}</Number>
        </Row>
        <Separator />
        <Row>
        <H2 style={{fontSize:'20px'}}>SELECTED NFTS</H2>
        <Number style={{fontSize:'20px'}}>{selectedNfts.length}</Number>
        </Row>
        <Separator />
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1em 0'}}>
        
        
        <>
        <H3 style={{padding: '1em 0', fontSize:'20px'}}>TIME UNTIL PRICE INCREASE:</H3>
        
       
        <Countdown 
            date={1696348800000}
            renderer={renderer}
            style={{fontSize:'20px'}}
            >
        <Completionist />
        </Countdown>
        
        </>
        

        <Row>
        <H2 style={{fontSize:'20px'}}>UPGRADE FEE</H2>
        <Number style={{fontSize:'20px'}}>{upgradeFee}</Number>
        </Row>
        <Separator />

        {/*<VoxeliseButton type="button"
            onClick={() => Toggle()} >VOXELISE</VoxeliseButton>
        </div>*/}

        <div style={{width:'100%', height:'20%', minHeight:'100px', minWidth:'200px',
        display:'flex', justifyContent:'center', alignItems:'center',
        position:'absolute', bottom:'2%'
        }}>

        </div>

        </div>


        <div style={{height:'200px', backgroundColor:'', 
                display:'flex', justifyContent:'center', alignItems:'start'
        }}>

        <div style={{display:'flex', justifyContent:'cetner', alignItems:'center', flexDirection:'column'}}>
            <Mint 
                price={upgradeFee} selectedNfts={selectedNfts} 
                isAlertOpen={isAlertOpen} setIsAlertOpen={setIsAlertOpen}
                submittedTx={submittedTx} setSubmittedTx={setSubmittedTx}
                selectedTokens={selectedNfts}
                setSelectedTokens={setSelectedNfts}
                setTransactionStatus={setTransactionStatus}
                setButtonPopup={props.setButtonPopup}
               />

           </div>

        </div>


        


        </div>

        </div>
        
        </div>

    <TransactionPopUp
         trigger={transactionStatus !== ''} 
         setTrigger={setButtonPopup} 
         transactionStatus={transactionStatus}
     />

    </SidePannel>
  ): "";
}

export default TransactionPannel;