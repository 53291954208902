import { useState, useEffect, useContext } from 'react'

import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { MainPlayerContext } from 'components/Context/PlayerContext'


import UIAvatarSelectSimple from 'components/Virtual/AvatarSelectionPlay/UI/UIAvatarSelectSimple'


import './Account.css'

import './Menu.css'

const IPFS_PATH = 'https://nyc3.digitaloceanspaces.com/claynation/IPFS/240x240/'





function AccountInterface(props) {


  const {chatOpen, setChatOpen} = props

  const {nickname, avatarMeta, setAvatarMeta} = useContext(MainPlayerContext)

  const [image, setImage] = useState('')

  const [avatarSelectionOpen, setAvatarSelectionOpen] = useState(false)


  const [meta, setMeta] = useState(avatarMeta)

  useEffect(() => {

    if (typeof(avatarMeta) === 'object' && 'image' in avatarMeta){
      setImage(IPFS_PATH + avatarMeta['image'] + '.png')
    }   

  },[avatarMeta])


  useEffect(() => {

    console.log(meta)

    setAvatarMeta({...meta})

  },[meta])

return(
 <>

  <div className='avatarDisplay'
    onClick={() => {

      setChatOpen(!chatOpen)
      setAvatarSelectionOpen(!avatarSelectionOpen)
    }}
  >
    <img src={image} className="avatarimg" />
    <div className='nicknameBar'>
      <div className='nicknametext'>{nickname}</div>
    </div>
    </div>




    {avatarSelectionOpen ?


       <UIAvatarSelectSimple meta={meta} setMeta={setMeta} />


    /*<div style={{position:'absolute', top:'0px',width:'100%', height:'100%', 
                     backgroundColor:'', zIndex:'40000', display:'flex', 
                     justifyContent:'center', alignItems:'center',
                     backdropFilter:'blur(10px)', 
                   }}>





    </div>*/
    :null}


 </>
  
  )
}

export default AccountInterface;
