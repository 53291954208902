import {useLocation} from 'react-router-dom';

import { useNavigate } from "react-router-dom";

import {useState, useEffect} from 'react'

import GlobalStyle from '../GlobalStyles.jsx';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import transition from "../Pages/Transition";
import { Button } from '../GlobalStyles.jsx';
import { PageContainer, DashboardWrapper, InnerWrapper } from './Dashboard.styled.jsx';
import Banner from '../Components/Banner.jsx';
import Menu  from '../Components/Menu.jsx';
import MainPanel from '../Components/MainPanel.jsx';
import { Grid, Box } from '@mui/material';
import Pfp from '../Components/Pfp.jsx';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ClaimClay.css'

function Dashboard() {

    const [activeLink, setActiveLink] = useState("wallets");

    const location = useLocation();
    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useState('')


    const [accountId, setAccountId] = useState('')
    const [userName, setUserName] = useState('')

    const [pfpImage, setPfpImage] = useState('')
    const [bannerImage, setBannerImage] = useState('')

    const [wallets, setWallets] = useState([])


    const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  const toastMessages = {
    notify:notify,
    notifySuccess:notifySuccess,
    notifyWarn:notifyWarn,
    notifyError:notifyError,
    notifyInfo:notifyInfo
  }


    async function updateAccessToken(viewName){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('accessToken') + " = " + String(accessToken) + '; ' + expires + "; path=/"
   }




    function checkAccessToken(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }

       return cookies_dict['accessToken']
    }


    async function getAccountId(data){
       const response = await fetch('https://claymarket.io/claydash/get-account-id/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }

    async function fetchWallets(data){
        const response = await fetch('https://claymarket.io/claydash/get-account-wallets/', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

        return response.json()
    }


    async function getWallets(){
       let fetched_wallets = await fetchWallets({"authToken_account":accessToken})

       console.log(fetched_wallets)

       let temp_wallets = fetched_wallets["wallets"]

       if (Array.isArray(temp_wallets)){
         setWallets(temp_wallets)
       }
       
    }


    async function checkIfValid(){
        let authToken_status = await getAccountId({"authToken_account": accessToken})

        if (authToken_status.hasOwnProperty("account_id")){
            //valid

            let temp_account_id = authToken_status["account_id"]
            setAccountId(temp_account_id)

            //get userName, pfp and banner, wallets
            try{
                getWallets()
            }catch{

            }
            

        }else{
            //not valid
            navigate('/login');
        }
    }


    useEffect(() => {

        if (accessToken === ''){
            return
        }

        if (accessToken === undefined){
            navigate('/login');
        }else{
            //check if valid
            try{
                checkIfValid()
            }catch{
                navigate('/login');
            }
            
        }


    },[accessToken])




    useEffect(() => {
        console.log('DASHBOARD')
        try{
            let temp_access_token = location.state.access_token

            if (temp_access_token !== undefined && temp_access_token !== '' && temp_access_token.length == 64){
                
                console.log(temp_access_token)

                //check that it is valid

                //if valid, save in cookies

                setAccessToken(temp_access_token)

                //else go to login
            }else{
                //check cookies first
                try{
                    let temp_access_token = checkAccessToken()

                    console.log(temp_access_token)
                    

                    //check that it is valid
                    setAccessToken(temp_access_token)

                }catch{
                    
                }

                

                //navigate('/login');
            }

            
        }catch{
            //check cookies first
            try{
                let temp_access_token = checkAccessToken()

                console.log(temp_access_token)
                

                //check that it is valid
                setAccessToken(temp_access_token)
            }catch{
                navigate('/login');
            }
            

            
        }
    },[])

    const handleMenuLink = (link) => {

        //setActiveLink(link);

        let activeLinks = ['wallets','banner','pfp']

        if (activeLinks.includes(link)){
            setActiveLink(link);
        }   

    }


    return (

    <PageContainer>
        <DashboardWrapper
        layout
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.3, delay: 2 }}
        variants={{
            visible: { opacity: 1},
            hidden: { opacity: 0 }
        }}>
            <GlobalStyle />
            <InnerWrapper>
               <Banner handleMenuLink={handleMenuLink} />

                {/*<Pfp handleMenuLink={handleMenuLink}/>*/}

                <Grid container spacing={2} style={{ height: '67%' }}>
                    <Grid item xs={2} >
                        <Menu handleMenuLink={handleMenuLink} 

                        />
                    </Grid>

                    <Grid item xs={10} >
                        <MainPanel activeLink={activeLink} 
                            wallets={wallets} setWallets={setWallets}
                            accessToken={accessToken} setAccessToken={setAccessToken}

                            toastMessages={toastMessages}
                        />   
                    </Grid>

                </Grid>
            </InnerWrapper>
            
        </DashboardWrapper>
         {/*<Link to="/login"><Button theme="pink" type="button" style={{position:'absolute', top: '2%', right: '1%'}}>Back</Button></Link>*/}

         <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />

    </PageContainer>

    );


}

export default transition(Dashboard);