// components/CardGrid.js
import React from 'react';
import Card from './Card';

import { Grid, Container, Box } from '@mui/material';


import meta from './json/meta.json'


const cardData = [
  { title: 'Card 1', content: 'Content for Card 1' },
  { title: 'Card 2', content: 'Content for Card 2' },
  { title: 'Card 3', content: 'Content for Card 3' },

  { title: 'Card 4', content: 'Content for Card 4' },
  { title: 'Card 5', content: 'Content for Card 5' },
  { title: 'Card 6', content: 'Content for Card 6' },
  // Add more cards as needed
];

const Cards = (props) => {

	const {setMintAsset, isAlertOpen,
           setIsAlertOpen, submittedTx,
           setSubmittedTx} = props

  return (

  	
     <Grid container spacing={3} 
  alignItems="center"
  justifyContent="center" style={{backgroundColor:''}}>


  {
  	Object.keys(meta).map((card) => {

  		return(
  			<Card 
	  			key={meta[card].token_name} setMintAsset={setMintAsset} img_url={meta[card].ipfs} 
	  			title={meta[card].title} price={meta[card].extra.price} 
	  			token_name={meta[card].token_name} 

	  			isAlertOpen={isAlertOpen} 
                setIsAlertOpen={setIsAlertOpen} 
                submittedTx={submittedTx} 
                setSubmittedTx={setSubmittedTx}

  			/>
  		)

  	})
  }

{/*
     {cardData.map((card, index) => (
        <Grid item key={index} xs={10} sm={6} md={4}>
        	
          <Card title={card.title} content={card.content} />
        </Grid>
      ))}*/}

    	{/*<div style={{width:'100%', height:'100%', backgroundColor:'red', display:'flex', justifyContent:'center', alignItems:'center'}}>

    		<div style={{width:'500px', height:'500px', backgroundColor:'red'}}>

    		</div>

    	</div>*/}

     {/* {cardData.map((card, index) => (
        <Card key={index} title={card.title} content={card.content} />
      ))}*/}
    </Grid>

    
  );
};

export default Cards;