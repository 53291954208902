import { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three'


function DustParticles(props){
   //const position = props.position

   const {position} = props

   const particlesRef = useRef()
   const [particles, setParticles] = useState()

   const [particleCount] = useState(50)


   useEffect(()=>{

   	// Create a custom texture
	let textureLoader = new THREE.TextureLoader();
	let customTexture = textureLoader.load( 'particles/DALLE/dust.png' );

	// Create a custom material with color changes
	let customMaterial = new THREE.PointsMaterial({
	    color: 0xffffff,  // Default color
	    size: 1,
	    map: customTexture,
	    transparent: true,
	    depthTest: true,
	    depthWrite: false,
	    blending: THREE.AdditiveBlending
	   
	});

	let temp_particles = new THREE.BufferGeometry();

	let particlePositions = []
	let particleScales = [];
	let particleColors = []

	for ( let i = 0; i < particleCount; i ++ ) {
	    let x = Math.random() - 0.5;
	    let y = Math.random()/3 - 1;
	    let z = Math.random() - 0.5;
	    //temp_particles.vertices.push( new THREE.Vector3( x, y, z ) );

	    particlePositions.push(x,y,z)
	    particleScales.push(Math.random() * 10 + 0.5);
	    particleColors.push(new THREE.Color(Math.random(), Math.random(), Math.random()))
	
	}

	temp_particles.setAttribute(
	    'position',
	    new THREE.Float32BufferAttribute(particlePositions, 3)
	  );	

	temp_particles.setAttribute(
	    'size',
	    new THREE.Float32BufferAttribute(particleScales, 1)
	  );

	temp_particles.setAttribute(
	    'color',
	    new THREE.Float32BufferAttribute(particleColors, 3)
	  );

	temp_particles.attributes.position.needsUpdate = true 
	temp_particles.attributes.size.needsUpdate = true


	let particleSystem = new THREE.Points( temp_particles, customMaterial );

	setParticles(particleSystem)

   },[particleCount])



   useFrame(() => {


   	if (!particles || particlesRef === null && typeof(particlesRef.current) === 'undefined'){
   		return
   	}

   	const particlePositions = particlesRef.current.geometry.attributes.position
   	const positionsArray = particlePositions.array
  
   	
   	for (let i = 0; i < particlePositions.count; i++){

   		let x = particlePositions.array[3*i]
   		let y = particlePositions.array[3*i+1]
   		let z = particlePositions.array[3*i+2]

   		x = x + (Math.random() - 0.5)/100
   		y = y + (Math.random() - 0.5)/100
   		z = z + (Math.random() - 0.5)/100

   		particlePositions.setXYZ(i,x,y,z)
   	}
   	


   	particlePositions.needsUpdate = true

   })



return(
   	<>
   		{
   			particles ?

   			<primitive
   				position={position}
   				ref={particlesRef}
   				object={particles}
   			/>

   			: null
   		}
   	</>
   	)
}

export default DustParticles;