function CookiesPolicy(){


 const containerStyles = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f2f2f2",
    borderRadius: "10px",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333",
  };

  const headingStyles = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color:'black'
  };

  const paragraphStyles = {
    marginBottom: "10px",
  };




	return(

		<div style={{background:`radial-gradient(157.24% 130.63% at 97.77% 97.43%, rgba(199, 54, 101, 0.49) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(111.09% 107.38% at 4.36% 92.22%, rgba(79, 200, 239, 0.49) 0%, rgba(14, 14, 14, 0.12) 100%), linear-gradient(180deg, #634F8B 3.48%, #121C37 99.91%)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', height: '100vh', overflow:'scroll'}}>
  	
		<div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%'}}>

			    <div style={containerStyles}>
		      <h1 style={headingStyles}>Clay Space Limited: Cookie Policy</h1>
		      <p style={paragraphStyles}>
		        Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.
		      </p>
		      <p style={paragraphStyles}>
		        Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service.
		      </p>
		      <p style={paragraphStyles}>
		        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
		      </p>
		      <p style={paragraphStyles}>
		        Examples of Cookies we use:
		      </p>
		      <ul style={paragraphStyles}>
		        <li>Session Cookies. We use Session Cookies to operate our Service.</li>
		        <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
		        <li>Security Cookies. We use Security Cookies for security purposes.</li>
		      </ul>
		    </div>
		   </div>
		</div>

	)
	
}

export default CookiesPolicy
