import React from 'react'
import { useState, useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import './VotePopUp.css'

import './InputStyle.css'

import './Staking.css'
import './StakingView.css'

import styled from 'styled-components'

import {MainClaimContext} from './ClaimContext'

import ClaimRewards from './ClaimRewards'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SearchInput = styled.span`
  
    display:inline-block;
    width: 100%;
    margin-left: ${props => props.marginLeft};
    background: transparent;
  
`


const CLAIM_SCRIPT_ADDRESS = 'addr1z9uxzlm9zf98kgf27sslzyl2d0j50ssdxlh6du2jt5r7vma0fm4l4jflstl7hz7ucs5793gzr297g67psdx8dssdf68sguhkf9'


function StakingClaim(props) {

  const {address, stake_address, lovelace, tokens, selectedTokens, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens} = useContext(MainClaimContext)


  const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


    const claimStake = props.claimStake
    const setClaimStake = props.setClaimStake



    const [copied, setCopied] = useState(false)


    const [walletConnected, setWalletConnected] = useState(false)


    useEffect(() => {

      if (copied == true){
        const interval = setInterval(() => setCopied(false), 1000)
        return () => {
            clearInterval(interval);
        };
      }
      
    }, [copied]);


    useEffect(() => {

        console.log(stake_address)
        console.log(claimStake)

        if (stake_address == claimStake.stakeAddress){
            setWalletConnected(true)
        }else{
            setWalletConnected(false)
        }

    }, [stake_address, claimStake])


  return (


    <>

    {JSON.stringify(claimStake) !== '{}' ?
        
     <div className='popup' style={{width:'100%', height:'100%', zIndex:'1350', backdropFilter: 'blur(25px)', overflow:'scroll'}}  > 
        


        <div className='popup-inner' style={{backgroundColor:'', width:'40%', minWidth:'350px', fontFamily:'ShortStack'}}>
           
            <button className='close-btn' onClick={() => {

                setClaimStake({})
            }}>X</button> 



 <div style={{marginTop:'0px', height:'100%', display:'flex', justifyContent:'space-between', flexDirection:'column', overflow:'scroll'}}>
            
        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
            <div style={{fontFamily:'ShortStack', fontSize:'24px', color:'bisque', width:'70%'}}>
               <h1 style={{color:'bisque'}}> Claim $CLAY staking rewards</h1>
            </div>
        </div>

       
            <div style={{color:'bisque', display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center'}}>

                <div style={{ backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', paddingTop:'10px', paddingBottom:'10px', width:'70%'}}>

                <div>

         
                    <h2>
                    INSTRUCTIONS:
                    </h2>

                </div>

                 <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                <div style={{width:'70%', minWidth:'220px'}}>
                    In order to initiate claiming of $CLAY staking rewards, a deposit of
                </div> 
                </div>



                <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'15px', marginBottom:'15px'}}>

                    <div style={{fontSize:'32px', fontFamily:'ShortStack', color:'bisque'}}>
                        5 ADA
                    </div>

                    {/*
                    <div style={{marginTop:'2px'}}>
                        <img alt="ADA" src='ada_light.svg' style={{width:'30px', backgroundColor:''}} />
                    </div>
                */}
                
                {/*
                <div className="inputField" style={{marginTop:'20px', marginBottom:'20px', marginLeft: '0px', width:'120px'}}>


                                                           <SearchInput>
                                                            <input 
                                                                type="text" 
                                                                
                                                                value={'5'}

                                                                style={{textAlign:'start', fontSize:'30px', fontFamily:'ShortStack'}}

                                                                
                                                             />


                                                             </SearchInput>


                                                              <img alt="ADA" src='ada_light.svg' style={{width:'30px', color:'bisque', fontSize:'12px', position:'absolute', zIndex:'3000', right:'12px'}} />

                </div>
                */}
                


                </div>


                <div>
                    needs to be sent to
                </div>


                <div style={{width:'100%', display:'flex', justifyContent:'center'}}
                >
                    <div style={{marginBottom:'20px', marginTop:'20px', width:'100%', backgroundColor:'', display:'flex', justifyContent:'center'}}>

                           <div className="stakingAddress" 
                           style={{height:'100%', minHeight:'60px', width:'100%', backgroundColor:''}}

                            onClick={()=>{
                                
                                if (copied){
                                    return
                                }else{
                                    setCopied(true)
                                }

                                    if ('clipboard' in navigator) {
                                         navigator.clipboard.writeText(CLAIM_SCRIPT_ADDRESS);
                                      } else {
                                        return document.execCommand('copy', true, CLAIM_SCRIPT_ADDRESS);
                                      }

                            }}

                           > 

                           <span className="tokensNumber" style={{color:'#e7e6d2'}}>
                             
                           </span><span className="tokensText" style={{color:'#e7e6d2', inlineSize:'300px', overflowWrap:'break-word', width:'90%', marginLeft:'4px'}}>{ copied ? <span style={{fontSize:'15px', fontWeight:'bold'}}>copied!</span> :CLAIM_SCRIPT_ADDRESS}</span> 
                            <ContentCopyIcon style={{marginLeft:'4px'}} />
                           </div>

                        </div>
                </div>  

                
                </div>


<div style={{color:'bisque', display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', marginTop:'20px'}}>
    
                <div style={{ backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', paddingTop:'10px', paddingBottom:'15px', width:'70%'}}>

                <div style={{}}>
                    <img src='info.png' style={{width:'80px'}} />
                </div>
                {/*                                    
               
                
                <div style={{fontSize:'20px', fontWeight:'bold'}}>
                    Information:
                </div>
                */}


                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                <div style={{width:'70%', minWidth:'220px'}}>
                    Deposit amount minus the transaction fees
                    will be sent back together with the
                    claimed rewards in <span style={{fontSize:'20px', fontWeight:'bold'}}>2</span> full epochs (<span style={{fontSize:'20px', fontWeight:'bold'}}>10+</span> days)

                    to the first payment address associated with the staking account
                </div>
                </div>
        </div>
    </div>


        
            {!walletConnected ?
            
            <div style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
             <div style={{width:'70%', display:'flex', justifyContent:'center',color:'bisque',backgroundColor:'rgba(255,255,255,0.05)', 
                                                    borderRadius:'20px', paddingBottom:'20px'}}>


            <div style={{width:'70%', minWidth:'220px'}}>

                <div style={{}}>
                    <img src='warning.png' style={{width:'80px'}} />
                </div>



           <div>

                <span style={{fontSize:'20px', fontWeight:'bold'}}>WARNING!</span><br/>
                Since the wallet for which rewards are being claimed is not connected,
                please send the deposit amount from that wallet to the provided address manually
                </div>
            </div>
            </div>

            </div>
            :null}


           <div style={{ backgroundColor:'rgba(255,255,255,0.05)', marginTop:'20px',
                                                    borderRadius:'20px', paddingTop:'10px', paddingBottom:'10px', width:'70%'}}>

                
                <div style={{fontSize:'20px', fontWeight:'bold', marginBottom:'5px', marginTop:'5px'}}>
                    Expected $CLAY Rewards:
                </div>


                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                  <div className='total-CLAY-rewards' style={{marginTop:'5px', marginBottom:'5px', animation:'text-appear 0s'}}>


                    <span className="total-rewards">{claimStake.totalRewards ? claimStake.totalRewards.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0'}</span>
                    
                    </div>
                </div>


            <ClaimRewards walletConnected={walletConnected} CLAIM_SCRIPT_ADDRESS={CLAIM_SCRIPT_ADDRESS} setClaimStake={setClaimStake} />

                    </div>
                </div>
            </div>
        </div>
    </div>

    :null}

    </>
  )
}

export default StakingClaim