import './Card.css';


const trousersNameCorrections = {
  "Green_Shirt_Trousers":"Shirt_Trousers&Green",
  "Red_Shirt_Trousers":"Shirt_Trousers&Red",
  "Grey_Shirt_Trousers":"Shirt_Trousers&Grey"
}

function Card(props) {
  const {meta} = props
  const image_url = props.image_url
  const serial = props.serial
  const selected = props.selected
  const setSelected = props.setSelected

  const setMeta = props.setMeta
  const asset = props.asset

  const {setAssetToBuy} = props

  return (
    <div>
      <div className={`Card  ${serial===selected ? "selected" : ""}`}

        onClick={()=>{
          console.log(serial)
          console.log(asset)
          setSelected(serial)


          if (serial.startsWith('CN')){
            let temp_meta = {...asset}
            console.log('old meta')
            console.log(meta)

            if (meta.hasOwnProperty('model') && meta.model.hasOwnProperty('trousers')){
              temp_meta.model.trousers = meta.model.trousers
            }
            setMeta(temp_meta)
          }else if (serial.startsWith('PANTS')){
            if (meta.hasOwnProperty('model') && meta.model.hasOwnProperty('trousers')
                && asset.hasOwnProperty('type')
            ){
              let temp_meta = {...meta}
              console.log(temp_meta)

              let temp_trousers_name = asset.type.replaceAll(' ','_') + '_Trousers'

              if (asset.type === 'None'){
                temp_trousers_name = 'None'
              }

              if (trousersNameCorrections.hasOwnProperty(temp_trousers_name)){
                temp_trousers_name = trousersNameCorrections[temp_trousers_name]
              }
              
              temp_meta.model.trousers = temp_trousers_name

              console.log('update meta')
              console.log(temp_meta)

              setMeta(temp_meta)
            }
          }
          

          // console.log(asset)

          if (asset.hasOwnProperty('assetPrice')){
            let asset_price = asset['assetPrice']

            if (asset_price.hasOwnProperty('ada_price') || asset_price.hasOwnProperty('clay_price')){

              let temp_asset = {...asset}
              temp_asset.image = image_url

              setAssetToBuy(temp_asset)
            }else{
              setAssetToBuy()
            }
          }else{
            setAssetToBuy()
          }

        }}




      >
        <img src={image_url} className="Avatar" />

        <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
          <div className="Serial">
            {serial}
          </div>
        </div>


      </div>
    </div>
  );

}

export default Card;