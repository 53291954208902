import {useReducer , useState, createContext, useEffect} from 'react'



const initialState = {
    
    activeScene: '',
    audioListener: null,
    location: 'Outside',
    prevLocation: '',
   
}


const actions = {
    SET_ACTIVE_SCENE: "SET_ACTIVE_SCENE", 
    SET_AUDIO_LISTENER: "SET_AUDIO_LISTENER", 
    SET_LOCATION: "SET_LOCATION", 
    SET_PREV_LOCATION: "SET_PREV_LOCATION", 
}

function reducer(state, action){
    switch (action.type){

        case actions.SET_ACTIVE_SCENE:
            return {...state, activeScene: action.value};

        case actions.SET_AUDIO_LISTENER:
            return {...state, audioListener: action.value};

        case actions.SET_LOCATION:
            return {...state, location: action.value};

        case actions.SET_PREV_LOCATION:
            return {...state, prevLocation: action.value};

        case actions.RESET:
            return {...state, ...initialState};

        default:
            //console.log('default')
            return state;

    }
}

export const MainExperienceContext = createContext()



function Provider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const value = {

        activeScene: state.activeScene,

        audioListener: state.audioListener,

        location: state.location,

        prevLocation: state.prevLocation,

        setActiveScene: value => {
             dispatch({type: actions.SET_ACTIVE_SCENE, value})
        },

        setAudioListener: value => {
             dispatch({type: actions.SET_AUDIO_LISTENER, value})
        },

        setLocation: value => {
             dispatch({type: actions.SET_LOCATION, value})
        },

        setPrevLocation: value => {
             dispatch({type: actions.SET_PREV_LOCATION, value})
        },

        reset: () => {
            dispatch({type: actions.RESET})
        }
    }

    return (
        <MainExperienceContext.Provider value={value}>
            {children}
        </MainExperienceContext.Provider>
        )
}


function ExperienceContext({children}){


    return(

        <Provider>
            {children}
        </Provider>

    )
}

export default ExperienceContext;


