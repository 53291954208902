import { useState } from "react";

import "../WalletLink.css";

import CardanoConnect from "./CardanoConnect";
import PolygonConnect from "./PolygonConnect";
import Linking from "./Linking";
import LinkingContext from "./Context/LinkingContext";
import DisplaySteps from "./DisplaySteps";
import Success from './Success';

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const steps = ["Connect Cardano", "Connect Polygon", "Link!"];

function WalletLink() {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  const toastMessages = {
    notify:notify,
    notifySuccess:notifySuccess,
    notifyWarn:notifyWarn,
    notifyError:notifyError,
    notifyInfo:notifyInfo
  }


  return (
    <>
   
   <Box
      sx={{
        width: "65%",
        minWidth:'450px',
        maxWidth:'800px',
        height: "600px",
        borderRadius: 10,
        background: 'rgba(29, 36, 54, 1)',
        backdropFilter: 'blur(10px)',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
      
      }}>

       

      <Stack sx={{ width: '100%' }} spacing={4}>
           
            <DisplaySteps
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              completedSteps={completedSteps}
              setCompletedSteps={setCompletedSteps}
            />
         
            

         <div style={{marginTop:'10px'}}>

            <LinkingContext>
              {activeStep === 0 ? (
                <CardanoConnect 
                  setActiveStep={setActiveStep} 
                  toastMessages={toastMessages}
                />
              ) : activeStep === 1 ? (
                <PolygonConnect 
                  setActiveStep={setActiveStep} 
                  toastMessages={toastMessages}
                />
              ) : activeStep === 2 ? (
                <Linking 
                  setActiveStep={setActiveStep} 
                  toastMessages={toastMessages}
                />
              ) : activeStep === 3 ? (
                <Success />
              ) : null

            }
            </LinkingContext>
          
          </div>



      </Stack>

      </Box>  

      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />

    
    </>
  );
}

export default WalletLink;
