import {lazy, useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';
import { Environment, useHelper, softShadows, Sky, Cloud, Sphere, Box, useProgress } from '@react-three/drei'
import { ErrorBoundary } from "react-error-boundary";
import { RigidBody, CapsuleCollider, BallCollider, useRapier, interactionGroups } from '@react-three/rapier'
import * as THREE from 'three'

import extraLargeQuadrants from './Quadrants/extraLarge.json'
import largeQuadrants from './Quadrants/large.json'
import mediumQuadrants from './Quadrants/medium.json'
import smallQuadrants from './Quadrants/small.json'

import {MainWorldContext} from 'components/Context/WorldContext'

import {MainClaimContext, Provider} from 'components/ClaimContext'

function SceneFallBack(props){

	const {size, setSceneLoaded} = props

	const { active, progress, errors, item, loaded, total } = useProgress()

	//console.log(loaded)

	useEffect(() => {
		
		if (loaded === total){
			//setSceneLoaded(true)
		}
		

	},[loaded])


	return(

		<RigidBody type="fixed" includeInvisible>

			<mesh visible={false}>
				<boxGeometry args={size} />
				<meshStandardMaterial color={'green'} />
			</mesh>

		</RigidBody>
	)
}



function SceneLoader(props){


	const {stake_address, activeWallet} = useContext(MainClaimContext)

	const {name, import_str, position, lod, size, setLoadedScenes} = props

	
	const [NewScene] = useState(lazy(() => import(`${import_str}`)))


	const [sceneLoaded, setSceneLoaded] = useState(false)

	useEffect(() => {

		if (sceneLoaded){
			setLoadedScenes((prev) => [...prev, name])
		}

	},[sceneLoaded])

	// }, [])
	

	return(
		<>
			
			<Suspense fallback={<SceneFallBack size={size} setSceneLoaded={setSceneLoaded} />} >
				<group position={position}>

					{/*<NewScene key={name} name={name} position={position} lod={lod} onLoad={() => setSceneLoaded(true)} />*/}

					<ErrorBoundary fallback={<></>}>
						<NewScene key={name} name={name} position={position} lod={lod} onLoad={() => setSceneLoaded(true)} />
					</ErrorBoundary>

				</group>
			</Suspense>
		
		</>
	)
}



function SceneParser(props){

	const {activeQuads} = props

	const {setReady, setReadyProgress} = props

	//const [activeScenes, setActiveScenes] = useState([])

	const {activeScenes, setActiveScenes} = useContext(MainWorldContext)

	const {currentUnitQuadrant, closestScene, setClosestScene} = useContext(MainWorldContext)

	const [loadedScenes, setLoadedScenes] = useState([])

	//console.log('SCENE')

	useEffect(() => {

		let temp_activeScenes = {}

		//let temp_scenes = []
		let foundCurrentScene = false
		for (let i = 0; i < activeQuads.length; i++){
			//console.log(activeQuads[i])

			let quad = activeQuads[i]
			let quad_key = quad.split('_')[0]
			let quad_type = quad.split('_')[1]

			//console.log(quad_key, quad_type)

			// console.log(smallQuadrants)

			// console.log(smallQuadrants.hasOwnProperty('(0,0)'))

			if (quad_type === 'Medium'){
				//console.log(quad_key)
			}

			//for now let's only focus on small quadrants


			
			if (quad_type === 'Small' && smallQuadrants.hasOwnProperty(quad_key)){
				
				let quad_scenes = smallQuadrants[quad_key].scenes
				//let scene_position = smallQuadrants[quad_key].position

				
				for (let j = 0; j < quad_scenes.length; j++){

					 let scene_name =  quad_scenes[j].name
					 let scene_position = quad_scenes[j].position
					 let scene_size = quad_scenes[j].size

					 let import_str = './Scenes/' + scene_name + '/' + scene_name

					 //const NewScene = React.lazy(() => import(import_str));

					 //temp_scenes.push({name: scene_name, url: import_str, position: scene_position})

					 //console.log(quad_scenes[j])

					 temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 0, size: scene_size}


					 //currentUnitQuadrant

					 if (quad === currentUnitQuadrant){

					 	let temp_closestScene = {}

					 	temp_closestScene[scene_name] = {
					 		url: import_str, 
					 		position: scene_position,
					 		size: scene_size,
					 		name: scene_name,
					 		lod: 0
					 	}

					 	//setClosestScene(scene_name)
					 	foundCurrentScene = true
					 	setClosestScene({...temp_closestScene})
					}

				}

			}


			else if (quad_type === 'Medium' && mediumQuadrants.hasOwnProperty(quad_key)){

				let quad_scenes = mediumQuadrants[quad_key].scenes

				for (let j = 0; j < quad_scenes.length; j++){

					 let scene_name =  quad_scenes[j].name
					 let scene_position = quad_scenes[j].position
					 let scene_size = quad_scenes[j].size

					 let import_str = './Scenes/' + scene_name + '/' + scene_name

					 if (temp_activeScenes.hasOwnProperty(scene_name)){

					 	if (temp_activeScenes[scene_name].lod > 1){
					 		temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 1, size: scene_size}
					 	}

					 }else{
					 	temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 1, size: scene_size}
					 }

					 //console.log(import_str)

				}

			}


			else if (quad_type === 'Large' && largeQuadrants.hasOwnProperty(quad_key)){

				let quad_scenes = largeQuadrants[quad_key].scenes

				for (let j = 0; j < quad_scenes.length; j++){

					 let scene_name =  quad_scenes[j].name
					 let scene_position = quad_scenes[j].position
					 let scene_size = quad_scenes[j].size

					 let import_str = './Scenes/' + scene_name + '/' + scene_name

					 if (temp_activeScenes.hasOwnProperty(scene_name)){

					 	if (temp_activeScenes[scene_name].lod > 2){
					 		temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 2, size: scene_size}
					 	}

					 }else{
					 	temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 2, size: scene_size}
					 }

					 //console.log(import_str)

				}

			}else if (quad_type === 'ExtraLarge' && extraLargeQuadrants.hasOwnProperty(quad_key)){

				let quad_scenes = extraLargeQuadrants[quad_key].scenes

				for (let j = 0; j < quad_scenes.length; j++){

					 let scene_name =  quad_scenes[j].name
					 let scene_position = quad_scenes[j].position
					 let scene_size = quad_scenes[j].size

					 let import_str = './Scenes/' + scene_name + '/' + scene_name

					 if (temp_activeScenes.hasOwnProperty(scene_name)){

					 	if (temp_activeScenes[scene_name].lod > 3){
					 		temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 3, size: scene_size}
					 	}

					 }else{
					 	temp_activeScenes[scene_name] = {url: import_str, position: scene_position, lod: 3, size: scene_size}
					 }

					 //console.log(import_str)

				}

			}
			
		}

				if (!foundCurrentScene){
					setClosestScene({})
				}

		//console.log(temp_activeScenes)

		setActiveScenes({...temp_activeScenes})


	},[activeQuads, currentUnitQuadrant])


	useEffect(() => {

	}, [activeScenes])


	useEffect(() => {

		//console.log(loadedScenes)

		if (Object.keys(activeScenes).length === 0){
			setReady(true)
			return
		}else{

			if (Object.keys(activeScenes).length === loadedScenes.length){

				let allLoaded = true

				for (let key in activeScenes){
					if (!loadedScenes.includes(key)){
						allLoaded = false
						break
					}
				}

				if (allLoaded){

					setReady(true)
				}

			}else{

			}
		}

		setReadyProgress(loadedScenes.length / Object.keys(activeScenes).length)


	},[loadedScenes])


	return(
		<>
			{
				Object.keys(activeScenes)?.map((key) => {
					
					return(
						<SceneLoader 
							key={key} 
							name={key} 
							import_str={activeScenes[key].url} 
							position={activeScenes[key].position} 
							lod={activeScenes[key].lod}
							size={activeScenes[key].size}

							setLoadedScenes={setLoadedScenes}
						/>
					)
					
					
				})
			}

		</>
	)
}

export default SceneParser;