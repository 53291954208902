import './Card.css';


function AttributeCard(props) {

  const image_url = props.image_url
  const serial = props.serial
  const selected = props.selected
  const setSelected = props.setSelected

  const setMeta = props.setMeta
  const asset = props.asset

  const setSelectedAttribute = props.setSelectedAttribute

  return (
    <div>
      <div className={`Card  ${serial===selected ? "selected" : ""}`}

        onClick={()=>{


          setSelected(serial)
          //setMeta(asset)
          //console.log(asset)

          setSelectedAttribute(asset)


        }}

      >
        <img src={image_url} className="Avatar" />
        <div className="Serial">{serial}</div>
      </div>
    </div>
  );

}

export default AttributeCard;