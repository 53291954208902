import {useState, useEffect} from 'react'
import { OrbitControls, Sparkles, Stars } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { ContactShadows, Float,  PresentationControls } from '@react-three/drei'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


export default function Experience(props)
{

    const {position, rotation, scale} = props

      const file_url = "https://claynation.nyc3.cdn.digitaloceanspaces.com/CN_ASSETPACKS/AP_1/models/StreetLight/StreetLight.glb"

      const gltf = useLoader(GLTFLoader, file_url)
    
      const [scene, setScene] = useState()


useEffect(() => {

    let model = gltf.scene

    model.traverse(function (object){
      
      if (object.isMesh){
        object.receiveShadow = true
        object.castShadow = true

        if (object.material.name === "Bulb_base_color_Mat_Inst"){
          object.material.toneMapped = false
        }

        //console.log(object.material)
      }

    })

    //setLoaded(true)
    setScene(model.clone())

},[gltf])

    return(
    <>
        {scene ?
        
          <primitive
            object={gltf.scene}

            position={position}
            rotation={rotation}
            scale={scale}
          
          />
      
       :null}
   </>
    )
}