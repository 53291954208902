import React from 'react';
import styled, { keyframes } from 'styled-components';

const drawCheckmark = keyframes`
  0% {
    stroke-dashoffset: 251.2;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const AnimatedCheckmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 
`;

const StyledSvg = styled.svg`
  width: 100px;
  height: 100px;
`;

const StyledPolyline = styled.polyline`
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: ${drawCheckmark} 1s ease-in-out 0.5s forwards;
`;

const StyledCircle = styled.circle`
  fill: transparent;
  stroke-width: 6;
  stroke-dasharray: 251.2;
  stroke-dashoffset: 251.2;
  animation: ${drawCheckmark} 1s ease-in-out forwards;
`;

const AnimatedCheckmark = () => {
  return (
    <AnimatedCheckmarkWrapper>
      <StyledSvg>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#2A71D0" />
            <stop offset="97.92%" stopColor="#9630CF" />
          </linearGradient>
        </defs>
        <StyledPolyline
          points="25,50 40,65 75,30"
          stroke="url(#gradient)"
        />
        <StyledCircle
          cx="50"
          cy="50"
          r="40"
          stroke="url(#gradient)"
        />
      </StyledSvg>
    </AnimatedCheckmarkWrapper>
  );
};

export default AnimatedCheckmark;
