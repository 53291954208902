import Button from '@mui/material/Button';
import { useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ClaimClay.css'

import {MainClaimContext} from 'components/ClaimContext'

//import {MainClayPayContext} from '../../Context/ClayPayContext'

import CustomCircularProgress from './CustomCircularProgress';

import cn_meta from './json/cn_db.json'

import trousers_info from './json/trousers_info.json'

import './Balance.css'



const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'

  const ConnectButton = styled.button`
    position: relative;
    width: ${props => props.matchesS ? '220px' : '150px'};
    height: ${props => props.matchesS ? '70px' : '50px' };
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;

    font-family: shortstack;

    cursor: pointer;

    margin-bottom: 5px;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: ${props => props.matchesS ? '220px' : '150px'};
    height: ${props => props.matchesS ? '70px' : '50px' };
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: ${props => props.matchesS ? '250px' : '180px'};
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
  `
const theme = {
  anim: 'animate 7s linear infinite',
  op: 0.9
}

const DropDown = styled.div`
  

` 

const WalletConnected = styled.button`

 position: relative;
    width: 220px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/wallet_connected.svg');
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border: 0px;
    cursor: pointer;

    background-repeat: no-repeat;
    background-size: cover;

    margin-bottom: 5px;

`

const ConnectedAcoount = styled.button`
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;

    cursor: pointer;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
` 

const DropDownItem = styled.div`
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.25);
  overflow: hidden;
  border-radius: 0px;
  opacity: 0.92;
  cursor: pointer;

  z-index: 5;

  backdrop-filter: 'blur(80px)';



:hover
{
  content: '';
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(132,211,255,1) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 100%);
  opacity: 0.92;

}


:after
{
  content: '';
  position: absolute;
  inset : 2px;
  background: linear-gradient(138deg, rgba(53,61,104,1) 0%, rgba(51,57,77,1) 44%, rgba(29,58,61,1) 100%);
  border-radius: 0px;
  z-index: 1;
  backdrop-filter: 'blur(80px)';

}
`


const ButtonText = styled.h1`
  position: relative;
  font-size: 1.8em;
  font-family: 'shortstack';
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
  color: #fff;
  z-index: 4;
`

//background:  linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), rgba(151, 218, 247, 0.36);

const PopUp = styled.div`

  background: rgba(1,1,1,0.3);
  backdrop-filter: blur(6px);

  border-radius: 32px;
  
  width: 40%;
  height: 65%;

  margin-top: 100px;

  max-width: 600px;
  max-height: 900px;

  padding: 22px;

  
  align-items: center;
  justify-content: center;
  font-size: 25px;
  display: flex;
  z-index: 10;
  color: white;
  backdrop-filter: blur(20px);
  -webkit-animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;



  @media screen and (max-width: 680px) {
    min-width: 300px;
    min-height: 450px;

    width: 60%;
    height: 65%;
  }

  @media screen and (min-width: 680px) {
    min-width: 400px;
    min-height: 550px;

    width: 38%;
  }

`



const PopUpInner = styled.div`

  margin-top: 30px;

  width: 95%;
  height: 95%;

  overflow: auto;

  background-color: transparent;
  border: none;
  color: var(--clayblue-primary);

  font-size: 1.5em;
  opacity: 0.9;   
  cursor: pointer;
  font-family: ShortStack;

  padding-bottom: 20px;
  
`


const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 26px;
  background-color: transparent;
  border: none;
  color: var(--clayblue-primary);

  font-size: 1.3em;
  opacity: 0.9;   
  cursor: pointer;
  font-family: ShortStack;

  &:hover{
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.462);
  }


  @media screen and (max-width: 680px) {
    font-size: 1.1em;
  }

  @media screen and (min-width: 680px) {
    font-size: 1.3em;
  }
`

const WalletOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`


//background: linear-gradient(90deg, rgba(28, 37, 52, 1) 0%, rgba(28, 37, 52, 0.6) 100%);

const WalletButton = styled.button`
  font-family: ButtonFont;  
  width: 100%;

  max-width: 320px;

  height: 20%;
  cursor: pointer;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  border-radius: 22px;
  border: none;
  margin-top: 10px;
  font-size: 1.2rem;
  
  background: rgba(1,1,1,0.3);
  
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;

  &:hover{
    box-shadow: 0px 0px 20px #F15822;
    outline: 2px solid #F15822;
  }

  @media screen and (max-width: 680px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 680px) {
    
    font-size: 1.5rem;
  }

`


const WalletLogo = styled.img`
  width: 30px; 
  height: auto; 
  padding-right: 30px;
`





function Connect(props) {

  const {isPopUpOpen, setIsPopUpOpen, isBusy, setIsBusy, walletToEnable, setWalletToEnable, updateWallet, setUpdateWallet} = props

  const walletLogos = {
    nami:'nami.svg',
    eternl:'eternl.png',
    yoroi:'yoroi.svg',
    flint:'flint.svg',
    gerowallet:'gerowallet.svg',
    lace: 'lace.svg',
    vespr: 'vespr.png',
    typhon: 'typhon.svg',
    tokeo: 'tokeo.svg'
  }







  return (
     <>



      {/*<div style={{width:'200px', height:'80px', backgroundColor:'', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <button className="connect-wallet-btn" 
          style={buttonStyle} 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        onClick={() => Toggle()}>CONNECT<br></br>WALLET</button>
      </div>*/}

      {/*<div className='connect-container'>
        <button className="connect-wallet-btn" 
          style={buttonStyle} 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        onClick={() => Toggle()}>CONNECT<br></br>WALLET</button>

        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}/>

     </div>*/}



    {
      true ?

        <PopUp>
          <PopUpInner>

            <CloseButton onClick={() => setIsPopUpOpen(false)} style={{color:'#F15822'}} >X</CloseButton>

            <WalletOptions>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('nami')}
                }
              >

              {isBusy && walletToEnable == 'nami' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.nami} />
              }
                CONNECT NAMI
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('eternl')}
                }
              >

              {isBusy && walletToEnable == 'eternl' ? <div style={{marginLeft:'20px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.eternl} style={{marginLeft:'20px'}} />
              }
                CONNECT ETERNL
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('gerowallet')}
                }
              >
              {isBusy && walletToEnable == 'gerowallet' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.gerowallet} />
              }
                CONNECT GERO
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('lace')}
                }
              >
              {isBusy && walletToEnable == 'lace' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.lace} />
              }
                CONNECT LACE
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('yoroi')}
                }
              >

              {isBusy && walletToEnable == 'yoroi' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.yoroi} />

              }

                CONNECT YOROI
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('typhoncip30')}
                }
               >

               {isBusy && walletToEnable == 'typhoncip30' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.typhon} style={{width:'30px', marginLeft:'20px'}} />

              }
                CONNECT TYPHON
              </WalletButton>


              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('vespr')}
                }

               >

               {isBusy && walletToEnable == 'vespr' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.vespr} style={{width:'30px', marginLeft:'5px'}} />

              }
                CONNECT VESPR
              </WalletButton>





               <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('tokeo')}
                }

               >

               {isBusy && walletToEnable == 'tokeo' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.tokeo} style={{width:'30px', marginLeft:'5px'}} />

              }
                CONNECT TOKEO
              </WalletButton>

            </WalletOptions>

          </PopUpInner>
        </PopUp>

      : null
    }



     {/*

      buttonPopup ?

      <div style={{height:'100%', width:'100%', position:'fixed', top:'0%', left:'0%', backgroundColor:'blue',
              display:'flex', justifyContent:'center'
      }}>

        <div style={{height:'500px', width:'500px', backgroundColor:'red', marginTop:'0px'}}>

        </div>

      </div>

      : null

     */}



     {/* <ConnectButton theme={!buttonActive && !isBusy ? theme : {anim:'none', op: 1}} matchesS={matchesS}>
      {isBusy ? (<CircularProgress thickness={4} style={matchesS ? {zIndex:'4', color: "#53c8ee"} : {zIndex:'4', color: "#53c8ee", width:'25px',height:'25px'}} />) : (
      <ButtonText style={{fontSize:matchesS ? '1.8em' :'15px'}}>Connect</ButtonText>
      )}
      </ConnectButton>*/}







     {/*<div onMouseEnter={()=>{setButtonActive(true)}} onMouseLeave={()=>{setButtonActive(false)}} style={{marginTop:'5px'}}>
      
      {activeWallet && lovelace && !isBusy ? (
      <DisplayAccount walletLogos={walletLogos} activeWallet={activeWallet} lovelace={lovelace} matchesS={matchesS} />)
      : (

      <ConnectButton theme={!buttonActive && !isBusy ? theme : {anim:'none',op: 1}} matchesS={matchesS}>
      {isBusy ? (<CircularProgress thickness={4} style={matchesS ? {zIndex:'4', color: "#53c8ee"} : {zIndex:'4', color: "#53c8ee", width:'25px',height:'25px'}} />) : (
      <ButtonText style={{fontSize:matchesS ? '1.8em' :'15px'}}>Connect</ButtonText>
      )}
      </ConnectButton>
     )}

      {buttonActive ? 
      <div style={{position:'absolute',top: pushDown ? '165px' : '75px'}}>

      <DropDownItem onClick={() => {
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('nami')}}>
         {isBusy && activeWallet == 'nami' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
        <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/nami.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-20px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >NAMI</ButtonText>
      </DropDownItem>

      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('eternl')}}>
        {isBusy && activeWallet == 'eternl' ? <div style={{marginLeft:'5px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/eternl.png' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'10px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Eternl</ButtonText>
      </DropDownItem>


      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('gerowallet')}}>
        {isBusy && activeWallet == 'gerowallet' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/gerowallet.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-10px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Gero</ButtonText>
      </DropDownItem>


      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('lace')

        if (activeWallet === 'lace'){
          getWalletBalance()
        }
      }}>
        {isBusy && activeWallet == 'lace' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/lace.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Lace</ButtonText>
      </DropDownItem>


      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('yoroi')}}>
        {isBusy && activeWallet == 'yoroi' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/yoroi.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'5px', marginRight:'20px'}} / >}
        }
        <ButtonText style={{fontSize:'1.1em'}} >Yoroi</ButtonText>
      </DropDownItem>

      
      <DropDownItem onClick={()=>{
        setButtonActive(false)
        setIsBusy(true)
        enableWallet('flint')}}>
        {isBusy && activeWallet == 'flint' ? <div style={{marginLeft:'-10px', marginRight:'10px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>: 
         <img src='https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/flint.svg' style={{width:'30px',height:'30px', zIndex:'4', marginLeft:'-5px', marginRight:'20px'}} / >}
        <ButtonText style={{fontSize:'1.1em'}} >Flint</ButtonText>
      </DropDownItem>

      </div>

      : 
      null
    }

    </div>*/}

    </>

  );
}

export default Connect;
