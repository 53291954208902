import { useState, useEffect, useContext } from 'react'

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import AvatarGroup from '@mui/material/AvatarGroup'
import Skeleton from '@mui/material/Skeleton';

function AvatarStack(props) {

  const asset = props.asset

  const w = props.width
  const h = props.height



  const [imageLoaded, setImageLoaded] = useState(false)



  return (
    <div>
   {!imageLoaded ? <Skeleton variant='circular'> <Avatar /> </Skeleton> : null }
    <Avatar style={imageLoaded ? {border:'2px solid #2e3142', height:h+'px', width: w+'px'} : { display: 'none' }} onLoad={()=>{setImageLoaded(true)}} alt="clay" src={"https://claynation.nyc3.digitaloceanspaces.com/IPFS/240x240/" + asset +'.png'} />
    </div>
  );
}

export default AvatarStack;
