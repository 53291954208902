import { useEffect, useState, useRef, useMemo } from 'react';
import { Suspense } from 'react'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { BufferGeometryUtils } from 'three/addons/utils/BufferGeometryUtils.js';

import * as THREE from 'three'

import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js';

import { TextureLoader } from 'three/src/loaders/TextureLoader'



function LoadTextures(){

}



function AttributeLoader(props){

  const clayColor = props.clayColor
  const attribute = props.attribute


  //let base_url = 'https://claynation.nyc3.digitaloceanspaces.com/CN/'
  let base_url = 'https://claynation.nyc3.digitaloceanspaces.com/GC/'
  //const base_url = ''

  if (attribute.url.includes('Trousers')){
    base_url = 'https://claynation.nyc3.digitaloceanspaces.com/CN/'
  }

  const gltf = useLoader(GLTFLoader, base_url + attribute.url)


  //const [model, setModel] = useState()
  const loadedAttributes = props.loadedAttributes 
  const setLoadedAttributes = props.setLoadedAttributes
  const bodyTextures = props.bodyTextures
  const [model, setModel] = useState()
  const [position, setPosition] = useState([0,0,0])
  const [scale, setScale] = useState([1,1,1])



  useEffect(() => {
    setLoadedAttributes((prev) => [...prev, attribute]);
  }, [attribute]);



  useEffect(()=>{

      let temp_model = gltf.scene

      //temp_model = SkeletonUtils.clone(temp_model)

      temp_model.traverse(function (object){
      
      if (object.isMesh){

              /*
              console.log('MESH')
              
              console.log(object.material.name)
              */
              
        

              // if (object.name.includes('Laser')){
              //   object.material.toneMapped = false
              // }

              
              if (object.material.name.endsWith('_Body') || object.name === 'Head'){

                // console.log('BODY NEED MATERIAL')
                // console.log(object.material.name)
                // console.log(clayColor)
                // console.log(bodyTextures)
                // console.log(object.material)

                object.material = new THREE.MeshStandardMaterial().copy( object.material )
                object.material.opacity = 1

                //object.material.shadowSide = THREE.DoubleSide
                //object.material.needsUpdate = true 

                let temp_clayColor = clayColor

                // if (object.name === 'Headless'){
                //   temp_clayColor += '_Headless'
                // }

                if (temp_clayColor in bodyTextures){

                  bodyTextures[temp_clayColor].forEach((texture) => {

                  // console.log(texture)

                  if (texture.name == 'baseColor'){

                    texture.texture.encoding = THREE.sRGBEncoding

                    texture.texture.flipY = false

                    texture.texture.needsUpdate = true

                    object.material.color = new THREE.Color("rgb(255, 255, 255)")
                    object.material.map = texture.texture

                    // console.log('BASE COLOR')
                    // console.log(texture.texture)
                  }

                  if (texture.name == 'normalMap'){
                    texture.texture.flipY = false
                    texture.texture.needsUpdate = true

                    object.material.normalMap = texture.texture
                  }

                  if (texture.name == 'metalnessMap'){
                    texture.texture.flipY = false
                    texture.texture.needsUpdate = true
                    object.material.metalnessMap = texture.texture
                  }

                  if (texture.name == 'roughnessMap'){
                    texture.texture.flipY = false
                    texture.texture.needsUpdate = true

                    object.material.roughnessMap = texture.texture
                  }

                  if (texture.name == 'displacementMap'){
                    texture.texture.flipY = false
                    texture.texture.needsUpdate = true

                    object.material.displacementMap = texture.texture
                  }

                })


                // object.material.specularIntensity = 0
                
                // object.material.metalness = 1
                object.material.roughness = 0.8

                //object.material.toneMapped = false
                //console.log(object.material.toneMapped)
                object.material.needsUpdate = true

                object.castShadow = true
                object.receiveShadow = true

                object.frustumCulled = false

              }else{
                //object = object.clone()
                //object.visible = false

                //object.material = new THREE.MeshStandardMaterial().copy( object.material )
                //object.material.color = new THREE.Color( 0xff0000 );
                
                object.material.transparent = true 
                object.material.opacity = 0 
                object.material.needsUpdate = true 
              }

          }else{
            object.castShadow = true
            object.receiveShadow = true
            
            object.frustumCulled = false
          }


        }


      })


     if ('adjustments' in attribute){

      let adjustments_str = attribute.adjustments

      // console.log('ADJUSTMENTS')
      // console.log(adjustments_str)

      let adjustments_list = adjustments_str.split(' ; ')

      // console.log(adjustments_list)

      let temp_position = position
      let temp_scale = scale

      adjustments_list.forEach((adjustment) => {
        // console.log(adjustment)

        if (adjustment.startsWith('g')){
          //move
          let move = parseFloat(adjustment.split(' ').slice(-1).pop())/3
          if (adjustment.startsWith('g x')){
            temp_position[0] = move
          }else if (adjustment.startsWith('g y')){
            temp_position[2] = move
          }else if (adjustment.startsWith('g z')){
            temp_position[1] = move
          }

        }else if(adjustment.startsWith('s')){
          //scale
          //move
          let scale = parseFloat(adjustment.split(' ').slice(-1).pop())/3

          if (adjustment.startsWith('s x')){
            temp_scale[0] = scale
          }else if (adjustment.startsWith('s y')){
            temp_scale[0] = scale
          }else if (adjustment.startsWith('s z')){
            temp_scale[0] = scale
          }

        }

      })


      setPosition(temp_position)
      setScale(temp_scale)

     }

     /*
     let temp_loadedAttributes = loadedAttributes
     temp_loadedAttributes[attribute.url] = temp_model
     setLoadedAttributes(temp_loadedAttributes)
     */
     

     setModel(SkeletonUtils.clone(temp_model))

     //setModel(temp_model)



     /*
     let temp_loadedAttributes = loadedAttributes
     temp_loadedAttributes[attribute.url] = SkeletonUtils.clone(temp_model)
     setLoadedAttributes(temp_loadedAttributes)


     console.log('LOADED ATTRIBUTES')
     console.log(loadedAttributes)
     */



     return () => {

      try{  

        //console.log('DISPOSING HERE')


        let temp_model = gltf.scene

        //temp_model = SkeletonUtils.clone(temp_model)

        temp_model.traverse(function (object){
        
        if (object.isMesh){

          // Dispose of geometry
          object.geometry.dispose();

          // Dispose of textures
          if (object.material.map) {
            object.material.map.dispose();
            //object.material.map = null
          }

          if (object.material.alphaMap) {
            object.material.alphaMap.dispose();
            //object.material.alphaMap = null
          }

          if (object.material.normalMap) {
            object.material.normalMap.dispose();
            //object.material.normalMap = null
          }

          if (object.material.roughnessMap) {
            object.material.roughnessMap.dispose();
            //object.material.roughnessMap = null
          }

          if (object.material.displacementMap) {
            object.material.displacementMap.dispose();
            //object.material.displacementMap = null
          }

          if (object.material.emissiveMap) {
            object.material.emissiveMap.dispose();
            //object.material.emissiveMap = null
          }


          if (object.material.metalnessMap) {
            object.material.metalnessMap.dispose();
            //object.material.metalnessMap = null
          }

          //object.material.needsUpdate = true

          // Dispose of materials
          object.material.dispose();

        }
      })

      // base_url + attribute
      useLoader.clear(GLTFLoader, base_url)

      //console.log('DISPOSING OVER')

      }catch{

      }

     }

   }, [gltf, clayColor, bodyTextures])


return(
  
   <> 


   {model ?

      <primitive

        object={model}
        position={position}
        scale={scale}

      />

    : null}

 
   </>
  
  )
}

export default AttributeLoader;