import logo from './Images/Logo.png';

import './PFP_Generator.css';
import GlobalStyle from  './GlobalStyles';
import { AssetContainer, ImageContainer } from './PFP_Generator.styled';
import { Button, LogoLink } from './GlobalStyles';

import { useState, useRef } from 'react';

import { Rnd } from 'react-rnd';

import html2canvas from 'html2canvas';
import santahat from './Images/santahat.png';
import beard from './Images/beard.png';
import frog from './Images/frog.png';
import slug from './Images/slug.png';
import ginger from './Images/ginger.png';
import jacket from './Images/jacket.png';



function App() {

  const [Addedimage, setAddedImage] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });


  const imageInputRef = useRef(null);

  const handleImageUpload = () => {
    imageInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        if (img.width !== 800|| img.height > 800) {
          const canvas = document.createElement("canvas");
          canvas.width = 800;
          canvas.height = 800;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, 800, 800);
          const resizedImage = canvas.toDataURL("image/jpeg");
          setAddedImage(resizedImage);
        } else {
          setAddedImage(e.target.result)
        }
      setSelectedAsset(null);
      setPosition({ x: 0, y: 0 });
    };
  };
    reader.readAsDataURL(file);
  };



  const handleAssetSelection = (asset) => {
    setSelectedAsset(asset);
    let size;
    switch (asset) {
      case santahat:
        size = "16vw";
        break;
      case beard:
        size = "11vw";
        break;
      case frog:
        size = "7vw";
        break;
      case slug:
        size = "7vw";
        break;
      case ginger:
        size = "7vw";
        break;
      case jacket:
        size = "21vw";
        break;
      default:
        size = 200;
    }
    
    setPosition((prevPosition) => ({...prevPosition, size}));
  };


    const targetRef = useRef(null);

  const handleDownload = async () => {
    try {
      const canvas = await html2canvas(targetRef.current);
      const dataUrl = canvas.toDataURL("image/jpeg");
      const a = document.createElement("a");
      a.href = dataUrl;
      a.download = "cn.jpg";
      a.click();
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className="PFPApp" >

        <GlobalStyle /> 



       
          
          <img src={logo} className="logo" alt="logo" /> 

          
    
    
    {Addedimage && (
      <ImageContainer ref={targetRef} >
      <img src={Addedimage} alt="uploaded image" className='image' />
      {selectedAsset && (
        <Rnd 
        size={{ width: position.size }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => setPosition((prevPosition) => ({...prevPosition, x: d.x, y: d.y}))}
        onResizeStop={(e, direction, ref, delta, position) => {
          setPosition((prevPosition) => ({...prevPosition, size: ref.style.width, ...position}));
        }}>
          <div style={{ width: '100%', height: '100%'}}>
          <img 
          src={selectedAsset} 
          alt="selected asset" 
          draggable 
          style={{ width: position.size, objectFit: 'contain', cursor: "move"}} />
          </div>
          </Rnd>
      )}
      </ImageContainer>
    )}
    


    
    <AssetContainer
     initial={{ opacity: 0, }}
     animate={{ opacity: 1, }}
     transition={{ duration: 0.5, delay: 0.5 }}>
      <img src={santahat} alt="hat" className='asset' onClick={() => handleAssetSelection(santahat)} />
      <img src={beard} alt="beard" className='asset' onClick={() => handleAssetSelection(beard)} />
      <img src={jacket} alt="jacket" className='asset' onClick={() => handleAssetSelection(jacket)} />
      <img src={frog} alt="frog" className='asset' onClick={() => handleAssetSelection(frog)} />
      <img src={slug} alt="slug" className='asset' onClick={() => handleAssetSelection(slug)} />
      <img src={ginger} alt="ginger" className='asset' onClick={() => handleAssetSelection(ginger)} />
     </AssetContainer>  
     


    
     <Button 
     initial={{ opacity: 0, }}
     animate={{ opacity: 1, }}
     transition={{ duration: 0.5 }}
      onClick={handleImageUpload} theme="blue" fontSize="clamp(0.5rem, 1.2rem, 3rem)" type="button">UPLOAD IMAGE</Button>
        <input 
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={imageInputRef}
          style={{display: "none"}}
        />
        <Button onClick={handleDownload} theme="pinkInactive" fontSize="clamp(0.5rem, 1.2rem, 3rem)" type="button">DOWNLOAD</Button>
      

     </div>
  );
}

export default App;
