import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'

import * as web3 from 'web3';

import { ethers } from "ethers";

import { useState, useEffect, useContext} from 'react'

import {MainClaimContext} from 'components/ClaimContext'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NETWORK_ID = 137

function ConnectHandler(props){
	const {walletToEnable, setWalletToEnable, isBusy, setIsBusy} = props


	const notify = (message) => toast(message);
    const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
    const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


    const {activeWallet, address, stake_address, lovelace, tokens, selectedTokens, setActiveWallet, setAddress, setStakeAddress, setLovelace, setTokens, setSelectedTokens, reset} = useContext(MainClaimContext)


    const [accountChange, setAccountChange] = useState()
 	  const [tempWalletInfo, setTempWalletInfo] = useState()


 	function addressToStake(cur_address){
	    let temp = serLib.Address.from_bech32(cur_address)
	    temp = serLib.BaseAddress.from_address(temp)
	    let stake_cred = temp.stake_cred()
	    let reward_addr_bytes = new Uint8Array(29)
	    reward_addr_bytes.set([0xe1], 0)
	    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
	    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
	    let cur_stake_address = reward_addr.to_address().to_bech32()

	    return cur_stake_address
	}


	async function getWalletBalance(){

  let cur_wallet = activeWallet

  if (cur_wallet == 'yoroi'){
    notifyInfo('This may take a while...')
  }

  if (cur_wallet === 'metamask'){

    let polygon = window.ethereum
    let requestedAcoounts = await polygon.request({ method: "eth_requestAccounts" });
    let cur_networkVersion = parseInt(polygon.networkVersion, 10)

    //console.log(cur_networkVersion)

    if (cur_networkVersion !== NETWORK_ID){
      notifyWarn('Wrong Network!')
      return
    }

    let walletAddress = polygon.selectedAddress

    // console.log(walletAddress)

    // const walletBalance = await web3.eth.getBalance(walletAddress)

    // console.log(walletBalance)

    //web3.eth.getBalance(walletAddress).then(console.log);

    

    // const provider = new ethers.providers.Web3Provider(window.ethereum)
    // const ethbalance = await provider.getBalance(address)

    // console.log(ethbalance)


    console.log(walletAddress)


    setTempWalletInfo({
      cur_wallet: cur_wallet,
      address: walletAddress,
      stake_address: '',
      lovelace_balance: 0,
      tokens_list: []
    })

    window.ethereum.on('accountsChanged', function (accounts) {
            console.log('account changed metamask')
            // Time to reload your interface with accounts[0]!
            getWalletBalance()
          })

    return
  }


  setSelectedTokens([])
  let cardano;
  cardano = await window.cardano[activeWallet]
  cardano = await cardano.enable()

  let address_encoded = await cardano.getUsedAddresses()
  address_encoded = address_encoded[0]

  let address_decoded = serLib.Address.from_bytes(Buffer.from(address_encoded,"hex")).to_bech32()

  //console.log(address_decoded)

  let encoded_balance = await cardano.getBalance()
  let balance_byte_str = serLib.Value.from_bytes(Buffer.from(encoded_balance, 'hex'))
  let lovelace_balance = balance_byte_str.coin().to_str()

  let current_ada_balance = (parseInt(lovelace_balance, 10) / 1000000).toFixed(2);

  //console.log(current_ada_balance + ' ADA')

  let multiasset_balance = balance_byte_str.multiasset()
    let assets = []
    assets.push({ unit: "lovelace", quantity: lovelace_balance})

     if (multiasset_balance) {
        const multiAssets = multiasset_balance.keys()
        for (let j = 0; j < multiasset_balance.len(); j++) {
            const policy = multiAssets.get(j);
              
            const policyAssets = multiasset_balance.get(policy);
            const assetNames = policyAssets.keys();
            for (let k = 0; k < assetNames.len(); k++) {
                const policyAsset = assetNames.get(k);
                const quantity = policyAssets.get(policyAsset).to_str();
                const asset_hash = Buffer.from(policy.to_bytes(), "hex").toString("hex") + Buffer.from(policyAsset.name(), "hex").toString("hex");
                assets.push({unit: asset_hash, quantity: quantity})
            }
          }
        }

      let tokens_list = assets.slice(1)

    /*
     let tokens_list = assets.slice(1)

     let cur_eligible_assets = []

     for (let i = 0; i < tokens_list.length; i++) {
      let temp_token = String(tokens_list[i].unit)
        
        let cur_policy = temp_token.slice(0,56)
        let cur_asset = temp_token.slice(56,)
        cur_asset = Buffer(cur_asset, 'hex').toString('utf8');

        if (eligible_policies.includes(cur_policy)){
          console.log(cur_policy)
          console.log(cur_asset)
          cur_eligible_assets.push(temp_token)
      } 
    }
    */

    let temp = serLib.Address.from_bech32(address_decoded)
    temp = serLib.BaseAddress.from_address(temp)
    let stake_cred = temp.stake_cred()
    let reward_addr_bytes = new Uint8Array(29)
    reward_addr_bytes.set([0xe1], 0)
    reward_addr_bytes.set(stake_cred.to_bytes().slice(4, 32), 1)
    let reward_addr = serLib.RewardAddress.from_address(serLib.Address.from_bytes(reward_addr_bytes))
    let cur_stake_address = reward_addr.to_address().to_bech32()


    //console.log(tokens_list)
    

    setTempWalletInfo({
      cur_wallet: cur_wallet,
      address: address_decoded,
      stake_address: cur_stake_address,
      lovelace_balance: lovelace_balance,
      tokens_list: tokens_list
    })


    /*
    setAddress(address_decoded)
    setStakeAddress(cur_stake_address)

    setLovelace(lovelace_balance)

    setTokens(tokens_list)
    */

    //setIsBusy(false)

  }



  useEffect(() => {

  if (tempWalletInfo){
    let cur_wallet = tempWalletInfo.cur_wallet

    if (activeWallet == cur_wallet){
      /*
        console.log(tempWalletInfo)
        console.log(tempWalletInfo.address)
        console.log(tempWalletInfo.stake_address)
        console.log(tempWalletInfo.lovelace_balance)
        console.log(tempWalletInfo.tokens_list)
      */
       setAddress(tempWalletInfo.address)
       setStakeAddress(tempWalletInfo.stake_address)
       setLovelace(tempWalletInfo.lovelace_balance)
       setTokens(tempWalletInfo.tokens_list)
       setIsBusy(false)
    }
}

}, [tempWalletInfo])


  async function updateWallet(wallet){
        let date = new Date();
        let expDays = 120
        let expTime = expDays * 24 * 60 * 60 * 1000
        date.setTime(date.getTime() + expTime);
        const expires = "expires=" + date.toUTCString();
        document.cookie = String('wallet') + " = " + String(wallet) + '; ' + expires + "; path=/"
  }


   function checkWallet(){
        let cookie = document.cookie
        let cookies = cookie.split(';')

        let cookies_dict = {}

        for (let i = 0; i < cookies.length; i = i + 1){
            let temp = cookies[i]
            while (temp.charAt(0) == ' ') {
                temp = temp.substring(1);
            }
            let entry = temp.split('=')

            cookies_dict[entry[0]] = entry[1]
        }
       return cookies_dict['wallet']
  }






useEffect(() => {
    async function onAccountChange() {
      try{
      let namiAPI = await window.cardano.nami
      let isNamiEnabled = await namiAPI.isEnabled()
      //console.log(isNamiEnabled)

      if(isNamiEnabled){
        namiAPI = await namiAPI.enable()
         namiAPI.experimental.on('accountChange',()=>{
          setAccountChange('nami')
          //console.log('Nami account changed')
         })
      }

    }catch{
      //notifyError('oh no...')
    }

  }
    setTimeout(onAccountChange, 100)
    setTimeout(function checkWalletInCookies(){
    //setIsBusy(true)
    let currentWallet = checkWallet()

    //console.log('CURRENT WALLET')
    //console.log(currentWallet)

    if (typeof(currentWallet) == 'string'){
        setIsBusy(true)
        setActiveWallet(currentWallet)
        enableWallet(currentWallet)
    }

  }, 100)
}, [])



useEffect(() => {
  if(activeWallet == accountChange){
    //console.log('Switching...')
    setAccountChange()
    getWalletBalance()
  }else{
    if(accountChange){
      //console.log('Business as usual')
      setAccountChange()
    }
  }

}, [accountChange])


// useEffect(() => {
//   setIsWalletLoading(isBusy)
// }, [isBusy])


useEffect(() => {

  if (activeWallet){
     getWalletBalance()
  }

}, [activeWallet])


  async function enableWallet(wallet){

      if (wallet == activeWallet){
          setIsBusy(false)
          return
      }

      /*
      console.log('NEW WALLET')
      console.log(wallet)

      console.log('OLD WALLET')
      console.log(activeWallet)
      */

      let cur_wallet = activeWallet
      setActiveWallet()

      if (wallet === 'metamask'){




        try{

          let polygon = window.ethereum
          let requestedAcoounts = await polygon.request({ method: "eth_requestAccounts" });
          let cur_networkVersion = parseInt(polygon.networkVersion, 10)

          //console.log(cur_networkVersion)

          if (cur_networkVersion !== NETWORK_ID){
            notifyWarn('Wrong Network!')
            return
          }

          let walletAddress = polygon.selectedAddress
          //console.log(walletAddress)
          setActiveWallet(wallet)
          updateWallet(wallet)


          window.ethereum.on('accountsChanged', function (accounts) {
            console.log('account changed metamask')
            // Time to reload your interface with accounts[0]!
            getWalletBalance()
          })

        }catch{
          setIsBusy(false)
          notifyError('Declined to connect to ' + wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet')
          setActiveWallet(cur_wallet)
        }

      }else{

        try{
          let cardano = window.cardano[wallet]
        try{
          let walletAPI = await cardano.enable()
          setActiveWallet(wallet)
          updateWallet(wallet)
        }catch{
          setIsBusy(false)
          notifyError('Declined to connect to ' + wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet')
          setActiveWallet(cur_wallet)
        }
      }catch{
        setIsBusy(false)
        notifyError(wallet.charAt(0).toUpperCase() + wallet.slice(1) + ' wallet is not installed')

        setActiveWallet(cur_wallet)
      }

    }
  } 


  useEffect(() => {

    const interval = setInterval(() => {
      getWalletBalance()
    }, 20000);
    return () => clearInterval(interval);

  },[])



  useEffect(() => {

  	if (isBusy && (walletToEnable !== '' && walletToEnable !== undefined)){
  		// console.log(walletToEnable)
  		enableWallet(walletToEnable)
  	}
  	

  },[walletToEnable, isBusy])

	
	return(
		<>

		</>
	)
}
export default ConnectHandler;