import GlobalStyle from '../GlobalStyles.jsx';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import transition from "../Pages/Transition";
import logo from "../Images/Logo.png";
import { Button, Logo } from '../GlobalStyles.jsx';
import Modal from '../Components/Modal.jsx';
import { Wrapper } from './LogInPortal.styled.jsx';

import {useState} from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ClaimClay.css'


function LogInPortal() {

  const notify = (message) => toast(message);
  const notifySuccess = (message) => toast.success(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/success.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyWarn = (message) => toast.warn(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/warning.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyError = (message) => toast.error(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/error.png" style={{position:'absolute', left:'3px',width:'45px', height:'45px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)
  const notifyInfo = (message) => toast.info(<div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', fontSize:'15px', fontFamily:'ClayFont'}}><img src="https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/info.png" style={{position:'absolute', left:'5px',width:'40px', height:'40px', opacity:'0.9'}} /> <span style={{color:'bisque'}}>{message}</span></div>)


  const toastMessages = {
    notify:notify,
    notifySuccess:notifySuccess,
    notifyWarn:notifyWarn,
    notifyError:notifyError,
    notifyInfo:notifyInfo
  }


  const [mainAction, setMainAction] = useState('')

    return (
    <Wrapper>
        <GlobalStyle />
        <a href="https://www.clayspace.io/" target="_blank">
            <Logo as={motion.img} 
            src={logo}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1.7, delay: 1 }}
            variants={{
                visible: { opacity: 1},
                hidden: { opacity: 0 }
            }}
             ></Logo></a>
        <Modal
            toastMessages={toastMessages}
            mainAction={mainAction}
            setMainAction={setMainAction}
        />
         <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} position="bottom-right" toastStyle={{ backgroundColor: "rgba(50, 54, 73, 0.5)", backdropFilter:'blur(50px)', color: "bisque" }} />
        {/*<Link to="/dashboard"><Button type="button" style={{position:'absolute', bottom: '2%', right: '2%'}}>DASHBOARD</Button></Link>*/}
    </Wrapper>

    )


}

export default transition(LogInPortal);