import { CompletedPannel, H1, P1, P2, INFO, GIF, InfoWindow, Button } from "./CompletedPopup.styled";
import eagle from "../Images/Eagle.gif";
import info from "../Images/info.png";

import { CloseButton } from './TransactionPannel.styled';

import { Link } from "react-router-dom";

function CompletedPopup(props) {


    return (
        <CompletedPannel>
            <CloseButton type="button" 
                onClick={() => {
                    props.setIsAlertOpen(false)

                }}
            >X</CloseButton> 
            <H1>Upgrade logged, congrats!</H1>
            <P1>Visit our interoperability platform to link your Cardano and Polygon wallets.</P1>
            <P2>Once this step is complete, view and use your VOX-CLAY avatar in  <a href="https://www.sandbox.game" style={{color:'var(--clayblue-secondary)', textDecoration:'none', fontSize: 'clamp(1rem, 1.3vw, 3rem)'}}>The Sandbox</a>.</P2>
            
            <Link to="/wallet-link">
                <Button> Let’s go!</Button>
            </Link>

            <GIF src={eagle}/>
            <INFO>🪄 Psst what’s the magic?</INFO>
            <InfoWindow>How did it work?<br></br><br></br>We’ve minted a reference token - the existence of which proves your voxel model has been unlocked.<br></br> The token is sent to an address we control and not to you.<br></br>We track eligible assets and if a reference token exists for a respective asset, we emit this information to Polygon making the respective voxel model accessible in The Sandbox.</InfoWindow>
            
        </CompletedPannel>
    );
}
export default CompletedPopup;