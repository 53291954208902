import './CollectionCard.css'

import { useState, useEffect, useContext } from 'react'

import { useNavigate } from "react-router-dom";
import './Assets.css'

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import styled, { keyframes } from 'styled-components';

import voxArgy from './Images/VoxArgy.png'


import {MainContext} from './Context/Context'

const Card = styled.div`
    position: absolute;
    text-align: center;
    border-radius: 10px;
    width: 272px;
    height: 384px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    color: black;
    display: block;
    background-color: transparent;
    text-align: center;

    &:hover {
      box-shadow: 0 0px 28px ${props => props.boxShadow || "#FF9C83"};
      cursor: pointer;
    }
  `

const CardText = styled.div`
    margin-top: 2px;
    position: relative;
    font-family: ShortStack;
    font-size: 1.2rem;
    color: ${props => props.color || "#FF9C83"};
    opacity: 0.9;
`

const cardCollections = {
    "cn":"40fa2aa67258b4ce7b5782f74831d46a84c59a0ff0c28262fab21728",
    "gc":"94da605878403d07c144fe96cd50fe20c16186dd8d171c78ed6a8768",
    "pitches":"13e3f9964fe386930ec178d12a43c96a7f5841270c2146fc509a9f3e"
  }


const validCollections = {
    
    claynation: 'cn',
    goodcharlotte: 'gc',
    pitches: 'pitches'

}


const validCollectionsAbr = {

    cn: 'claynation',
    gc: 'goodcharlotte',
    pitches: 'pitches'

}


function AvatarsCard(props){

	const {currentApp, setCurrentApp} = useContext(MainContext)

	const {collection} = props

	//const cn_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/collections/CN.svg'
	//https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/clay_avatar_test.png

	const cn_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/CN_Thumbnail_3.png'
  	const gc_image_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/GC_Thumbnail.png'

  	const cardThemes = {
	    "cn":{title: "Clay Nation", image: cn_image_url, boxShadow: "#FF9C83", color:"bisque"},
	    "gc":{title: "Good Charlotte", image: gc_image_url, boxShadow: "#53C9EE", color: "bisque"},
	  }

	return(
		<>
			<div className={collection.toUpperCase()}
				style={{fontFamily:'ShortStack', transform:'scale(1.8)'}}

				onClick={() => {

					if (collection == 'cn'){
						setCurrentApp('avatars3D')
					}else if (collection == 'gc'){
						setCurrentApp('avatars3D_gc')
					}
					
				}}
			>
				<div className={collection.toUpperCase() + '-background'}>
					<img className={collection.toUpperCase() + '-img'} src={cardThemes[collection].image} alt={collection.toUpperCase() + "-image"} style={{width:'150px', marginLeft:'0px'}} />
        			<div className={collection.toUpperCase() + '-title'}> {cardThemes[collection].title.toUpperCase()} </div>
        			<div className={collection.toUpperCase() + '-title'} style={{fontSize:'10px', color: true ? 'bisque' :'#b7ffc1'}}>
        				3D AVATARS
        			</div>
				</div>
			</div>
		</>
	)
}


function AssetCard(props){

	const {currentApp, setCurrentApp} = useContext(MainContext)

	const {collection, title, url, theme, disabled} = props

	
	
	return(
		<>


			<div className={theme.toUpperCase()} style={{transform: 'scale(1.8)'}}
				onClick={()=>{
					
				}}
			>
				<div className={theme.toUpperCase()+"-background"}>
				
					<img className={theme.toUpperCase()+"-img"} src={url} style={collection === '?' ? {opacity:'1', width:'95px', marginBottom:'-15px', marginLeft:'5px'} : {}} />

					<div className={theme.toUpperCase()+"-title"}>
						{title}
					</div>

				</div>
			</div>


			{/*<div className={collection.toUpperCase()}
				style={{fontFamily:'ShortStack', transform:'scale(1.8)'}}

				onClick={() => {
					setCurrentApp('avatars3D')
				}}
			>
				<div className={collection.toUpperCase() + '-background'}>

					<img className={collection.toUpperCase() + '-img'} src={cardThemes[collection].image} alt={collection.toUpperCase() + "-image"} />
        			<div className={collection.toUpperCase() + '-title'}> {cardThemes[collection].title.toUpperCase()} </div>

				</div>
			</div>*/}
		</>
	)
}




function Avatars3D(props){

	const {isSideBarOpen, matchesS, matchesL} = props

	const navigate = useNavigate();

	return(
		<>
			<div style={{display:'flex', justifyContent: isSideBarOpen && matchesS ? 'space-between' : 'center', minHeight:'500px',
		      width:'80%', flexWrap: 'wrap', backgroundColor:'', alignItems:'center', marginTop:'0px', backgroundColor: '', height:'100%',
		      marginLeft: isSideBarOpen && matchesS ? '320px' : '0px', marginTop:'', marginTop: matchesL ? '0px' : '40px'}}>

			     <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexWrap:'wrap', backgroundColor:'', width:'100%'}}>


			        <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
			                    display:'flex', justifyContent:'center', alignItems:'center'
			      }}>
							<AvatarsCard collection={'cn'} />

					</div>


					 <div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
			                    display:'flex', justifyContent:'center', alignItems:'center'
			      }}>
							<AvatarsCard collection={'gc'} />

					</div>






					<div style={{width:'275px', height:'390px', margin:'20px', marginLeft:'30px',backgroundColor:'', 
			                    display:'flex', justifyContent:'center', alignItems:'center'
			      }}
			      	onClick={()=>{
			      		navigate('/sandbox-unlock/info');
			      	}}
			      >
							
			   
			      <AssetCard 
								collection={'?'} 
								
								disabled={true}
								title={<div>VOX CLAY</div>} 
								url={voxArgy} 


								theme={'coming-soon-card'}

								
								
							/>

					</div>





				</div>



				



			</div>
		</>
	)
}

export default Avatars3D;