import {useContext} from 'react'

import './App.css';
import ClaimContext from './components/ClaimContext'

import Context from './components/Context/Context'

import ExperienceContext from 'components/Context/ExperienceContext'

import {MainContext} from './components/Context/Context'


import Virtual from './components/Virtual/Virtual'



function AppChoser(){

  const {currentApp, setCurrentApp} = useContext(MainContext)

  const virtualApps = [
    'avatars3D',
    'avatars3D_gc',
    'theStand'
  ]

  return(
    <>
      
      {currentApp === 'market' ?
       
       <ClaimContext />
      
        : virtualApps.includes(currentApp) ?

        <ExperienceContext>
          <Virtual />
        </ExperienceContext>

       : 


       <div>
          NONE
       </div>
      }

    </>
  )
}


function Claim() {



  return (
    <div className="App">



       

        <div style={{height: '100vh'}}>
        

        <Context>

          <AppChoser />

        </Context>

      </div>

     </div>
    
  );
}

export default Claim;